import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IDepartmentResponse } from "../../models/department.type";
import { PENDING_APPROVAL_URL } from "./endpoints/pendingApproval";
import { IPendingApprovalResponse } from "../../models/pendingApproval.type";

const getPendingApprovalList = (req: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .get(PENDING_APPROVAL_URL.GetPendingApproval, { params: req })
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getMakerCheckerApprovalById = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.GetMakerCheckerApprovalById, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getMakerCheckerStatus = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .get(PENDING_APPROVAL_URL.GetMakerCheckerStatus, { params: requestBody })
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApproveorReject = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.ApproveMakerChecker, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const updateApproveorRejectCompany = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.ApproveMakerCheckercompanymaster, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApproveorRejectVendor = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.ApproveMakerCheckerVendorMaster, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApproveorRejectforAQM = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.ApproveMakerCheckerAQMaster, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApproveorRejectforUser = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.UpdateApprovalStatusForUserMaster, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        console.log(response.data, "responseData");
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApproveorRejectforRole = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.UpdateApprovalStatusForRoleMaster, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        console.log(response.data, "responseData");
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    
const updateApproveorRejectforSC = (requestBody: any): Promise<IPendingApprovalResponse> =>
  axiosInstance
    .post(PENDING_APPROVAL_URL.UpdateApprovalStatusForServiceCatalogMaster, requestBody)
    .then((response: AxiosResponse<IPendingApprovalResponse>) => {
      if (response.status === 200) {
        console.log(response.data, "responseData");
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const pendingApprovalService = {
  getPendingApprovalList,
  getMakerCheckerApprovalById,
  getMakerCheckerStatus,
  updateApproveorReject,
  updateApproveorRejectCompany,
  updateApproveorRejectVendor,
  updateApproveorRejectforAQM,
  updateApproveorRejectforUser,
  updateApproveorRejectforRole,
  updateApproveorRejectforSC
};

export default pendingApprovalService;
