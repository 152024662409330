import React, { useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography, Menu, MenuItem, useTheme } from "@mui/material";
import CustomButton from "../../../dynamicButton/CustomButton";
import CustomSvg from "../../../CustomSvg";
import CustomCheckBox from "../../../dynamicInputs/CustomCheckBox";
import { useFormContext } from "react-hook-form";
import CustomDialog from "../../../dynamicDialog/CustomDialog";
import AddExistingClause from "../../../../components/contractManagement/ContractTemplate/AddExistingClause";
import { useSelector } from "react-redux";

interface SMEReviewMappingProps {
  SMEData: {
    SMEHeading: string;
    SMEClauses: { title: string; isSelected: boolean }[];
  }[];
}

const SMEReviewMapping: React.FC<SMEReviewMappingProps> = ({ SMEData }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogBox, setDialogBox] = useState<boolean>(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const [SME, setSME] = useState(SMEData);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  console.log(window, "test~log");
  const pluginData: any = window;
  const { setValue } = useFormContext();

  const theme = useTheme();

  // useEffect(() => {
  //   if (pluginData) {
  //     const documentApi = pluginData?.plugin?.GetDocument();
  //     getHeaders(documentApi)
  //       .then((headersList) => {
  //         setHeaders(headersList);
  //         setErrorMessage(null);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching headers:", error);
  //         setErrorMessage("Failed to fetch headers. Please try again.");
  //       });
  //   }
  // }, [pluginData]);

  const getHeaders = async (documentApi: any) => {
    return new Promise<string[]>((resolve, reject) => {
      try {
        const headerElements = documentApi?.GetHeaders();
        resolve(headerElements);
      } catch (error) {
        reject(error);
      }
    });
  };
  // const navigateToHeader = (headerId: string) => {
  //   if (pluginData) {
  //     pluginData?.plugin?.callCommand("navigateTo", headerId);
  //   }
  // };


  // // initaite plugin feature
  // useEffect(()=>{
  //   (function (window, undefined) {
  //     window.Asc.plugin.init = function () {
  //         this.callCommand(function() {
  //             var oDocument = Api.GetDocument();
  //             var oParagraph = Api.CreateParagraph();
  //             oParagraph.AddText("Hello world!");
  //             oDocument.InsertContent([oParagraph]);
  //         }, true);
  //     };
  //     window.Asc.plugin.button = function (id:any) {
  //       this.executeCommand("close", '');
  //     };
  // })(window, undefined);
  // },[])

  // Plugin Feature

  
  const keywordText =(pluginData:any,text:string)=>{
    return pluginData.Asc.scope.text
  }
  function insertTextAtCursor(text:string) {
    if (pluginData) {
        pluginData.Asc.scope.text = text;        
       pluginData.Asc.plugin.callCommand(function(){
          console.log("window", window);          
          let oDocument = Api.GetDocument();
          let oParagraph = Api.CreateParagraph();  
          oParagraph.AddText("Feeling good");    
          oDocument.InsertContent([oParagraph]);    
          oParagraph.AddText(`${text}`);
          oDocument.InsertContent([oParagraph]);
       }, false);
       //pluginData.Asc.plugin.init(text);            
    }
  };

  console.log(pluginData, "pluginData");

  const handleAddToEditor = () => {
    const selectedClauses = SME.flatMap((section) =>
      section.SMEClauses.filter((clause) => clause.isSelected).map((clause) => clause.title)
    );
    if (selectedClauses.length > 0) {
      insertTextAtCursor(selectedClauses.join("\n"));
    }
  };

  const handleSelectDeselectToggle = (sectionIndex: number) => {
    const allSelected = SME[sectionIndex].SMEClauses.every((clause) => clause.isSelected);
    const updatedSME = [...SME];
    updatedSME[sectionIndex].SMEClauses = updatedSME[sectionIndex].SMEClauses.map((clause) => ({
      ...clause,
      isSelected: !allSelected,
    }));
    setSME(updatedSME);
  };
  const handleToggleClause = (sectionIndex: number, clauseIndex: number) => {
    const updatedSME = [...SME];
    updatedSME[sectionIndex].SMEClauses[clauseIndex].isSelected =
      !updatedSME[sectionIndex].SMEClauses[clauseIndex].isSelected;
    setSME(updatedSME);
  };

  const openMenu = Boolean(anchorEl);

  return (
    <Box sx={{ maxHeight: "calc(100vh - 250px)", px: 2, py: 0 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          SME Review Mapping
        </Typography>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <CustomSvg name={"more-vertical"} color={"#321149"} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            onClick={() => {
              setDialogBox(true);
              setAnchorEl(null);
            }}
            sx={{ display: "flex", gap: "10px" }}
          >
            <CustomSvg color={`${theme.palette.text.secondary}`} name={"plus"} />
            Add Existing Clause
          </MenuItem>
        </Menu>
      </Box>

      <Stack direction={"column"} spacing={2}>
        {/* Existing SME Mapping Logic */}
        <Box>
          {SME.map((data, sectionIndex) => {
            const allSelected = data.SMEClauses.every((clause) => clause.isSelected);
            return (
              <Box key={data.SMEHeading} sx={{ mb: 3 }}>
                <Stack direction={"column"} spacing={2}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
                    <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>{data.SMEHeading}</Typography>
                    <Typography
                      sx={{ textAlign: "left", textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleSelectDeselectToggle(sectionIndex)}
                    >
                      {allSelected ? "Unselect All" : "Select All"}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {data.SMEClauses.map((clause, clauseIndex) => (
                      <Box
                        key={clause.title}
                        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                      >
                        <Typography
                          onClick={() => {
                            // navigateToHeader(clause.title);
                            insertTextAtCursor(clause.title);
                          }}
                          sx={{ textAlign: "left", cursor: "pointer" }}
                        >
                          {clause.title}
                        </Typography>

                        <CustomCheckBox
                          isStyle={true}
                          alignItems="center"
                          label=""
                          onChange={() => handleToggleClause(sectionIndex, clauseIndex)}
                          value={clause.isSelected}
                        />
                      </Box>
                    ))}
                  </Box>
                  {/* <button
                    onClick={() => {
                      handleAddToEditor();
                    }}
                  >
                    handleAddToEditor
                  </button> */}
                </Stack>
              </Box>
            );
          })}
        </Box>

        <CustomDialog
          show={dialogBox}
          onHide={() => setDialogBox(false)}
          maxWidth={"xl"}
          header={`Add Existing Clause`}
          contentNode={<AddExistingClause onClose={() => setDialogBox(false)} onAdd={() => {}} />}
        />
      </Stack>
    </Box>
  );
};

export default SMEReviewMapping;
