import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../models/user.type";
import defaultValueService from "../services/defaultService";
import { IRole } from "../../models/roles.type";

export interface IDefaultData {
  isLoading: boolean;
  DefaultData: string;
  DefaultId: number;
  isDefaultValuedAddedd: boolean;
  isDefaultValuedUpdated: boolean;

}

export const initialState: IDefaultData = {
  isLoading: false,
  DefaultData: "",
  DefaultId: 0,
  isDefaultValuedAddedd: false,
  isDefaultValuedUpdated: false
};

export const getDefaultValues = createAsyncThunk(
  "getDefaultList",
  async (requesrParams: { ModuleName: string; ScreenName: string }, { rejectWithValue }) => {
    try {
      const response = await defaultValueService.getDefaultValues(requesrParams);
      const { DefaultData, DefaultId } = response.data;
      return { DefaultData, DefaultId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addDefaultValue = createAsyncThunk("addDefaultValues", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await defaultValueService.addDefault(requesrParams);
    return response
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateDefaultValue = createAsyncThunk(
  "updateDefaultValues",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await defaultValueService.updateDefault(requesrParams);      
      return response
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deafultValuesSlice = createSlice({
  name: "DefaultData",
  initialState,
  reducers: {    
    updateDefualtAddAndEdit: (state, action) => {
      state.isDefaultValuedAddedd = false;
      state.isDefaultValuedUpdated = false;      
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDefaultValues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDefaultValues.fulfilled, (state, action: PayloadAction<any>) => {
        state.DefaultData = action.payload.DefaultData
        state.DefaultId = action.payload.DefaultId
        state.isLoading = false;
      })
      .addCase(getDefaultValues.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addDefaultValue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDefaultValue.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDefaultValuedAddedd = true
      })
      .addCase(addDefaultValue.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateDefaultValue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDefaultValue.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDefaultValuedUpdated = true;       
      })
      .addCase(updateDefaultValue.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })      
  },
});


export const { updateDefualtAddAndEdit } = deafultValuesSlice.actions;
export default deafultValuesSlice.reducer;
