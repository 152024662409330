export const initialData = [
  {
    ServiceCatalogId: 78,
    ServiceName: "testqwe",
    ServiceDescription: "test",
    ServiceType: 33,
    Engage: "multiple",
    IsMapped: false,
    ServiceOwnerId: 173,
    SubDepartmentId: 174,
    Remarks: "test",
    Status: 1,
    IsActive: "Active",
    Updated_By: "Karthick A",
    Updated_On: null,
    Regulator: 24,
    RegulatoryRef: "test",
    SumOfMaxCritical: "18",
    MaxOfMaxCritical: "6",
    ApplicableRisk: "[36,114,73,116,115,143]",
    ServiceOwnerName: "Information Technology",
    ServicetypeName: "Technology",
    Sub_Department: "Network Administration",
    Summary: "Low Critical",
    ServiceGroupId: 204,
    ServiceGroupName: "Financial Services",
    ServiceCriticality: "MAX OF MAX",
    Regulator_Name: "Duetsche",
    ServiceParams: [
      {
        ParameterId: 113,
        Parameter: "Data Leakage",
        Sum: 10,
        Max: 3,
        IsActive: true,
        UpdatedBy: "Karthick A",
        MaxScoring:
          '[{"RiskCategory_Id":143,"Score":1},{"RiskCategory_Id":116,"Score":2},{"RiskCategory_Id":115,"Score":1},{"RiskCategory_Id":114,"Score":2},{"RiskCategory_Id":73,"Score":1},{"RiskCategory_Id":36,"Score":3}]',
        Questions: [
          {
            Question: "what is DL?",
            IsActive: true,
            UpdatedBy: "Karthick A",
            QuestionsRiskScoringId: 165,
            Risks:
              '[{"RiskCategory_Id":143,"Score":1,"RiskId":28},{"RiskCategory_Id":116,"Score":2,"RiskId":34},{"RiskCategory_Id":115,"Score":1,"RiskId":28},{"RiskCategory_Id":114,"Score":2,"RiskId":34},{"RiskCategory_Id":73,"Score":1,"RiskId":28},{"RiskCategory_Id":36,"Score":3,"RiskId":35}]',
          },
        ],
      },
      {
        ParameterId: 114,
        Parameter: "Restoration Time",
        Sum: 18,
        Max: 3,
        IsActive: true,
        UpdatedBy: "Karthick A",
        MaxScoring:
          '[{"RiskCategory_Id":143,"Score":3},{"RiskCategory_Id":116,"Score":3},{"RiskCategory_Id":115,"Score":3},{"RiskCategory_Id":114,"Score":3},{"RiskCategory_Id":73,"Score":3},{"RiskCategory_Id":36,"Score":3}]',
        Questions: [
          {
            Question: "What is RT?",
            IsActive: true,
            UpdatedBy: "Karthick A",
            QuestionsRiskScoringId: 166,
            Risks:
              '[{"RiskCategory_Id":143,"Score":3,"RiskId":35},{"RiskCategory_Id":116,"Score":3,"RiskId":35},{"RiskCategory_Id":115,"Score":3,"RiskId":35},{"RiskCategory_Id":114,"Score":3,"RiskId":35},{"RiskCategory_Id":73,"Score":3,"RiskId":35},{"RiskCategory_Id":36,"Score":3,"RiskId":35}]',
          },
        ],
      },
    ],
  },
  {
    ServiceCatalogId: 75,
    ServiceName: "Catering and Hospitality",
    ServiceDescription: "Desc",
    ServiceType: 113,
    Engage: "single",
    IsMapped: true,
    ServiceOwnerId: 1,
    SubDepartmentId: 196,
    Remarks: "",
    Status: 1,
    IsActive: "Active",
    Updated_By: "Manoj S",
    Updated_On: null,
    Regulator: 7,
    RegulatoryRef: "Name",
    SumOfMaxCritical: "4",
    MaxOfMaxCritical: "3",
    ApplicableRisk: "[73,114]",
    ServiceOwnerName: "HR Department",
    ServicetypeName: "Supply",
    Sub_Department: "IT Dept 123456",
    Summary: "start testing",
    ServiceGroupId: 140,
    ServiceGroupName: "Human Resources and Payroll",
    ServiceCriticality: "SUM OF MAX",
    Regulator_Name: "Name 7",
    ServiceParams: [
      {
        ParameterId: 104,
        Parameter: "value1",
        Sum: 4,
        Max: 3,
        IsActive: true,
        UpdatedBy: "Manoj S",
        MaxScoring: '[{"RiskCategory_Id":114,"Score":1},{"RiskCategory_Id":73,"Score":3}]',
        Questions: [
          {
            Question: "Question",
            IsActive: true,
            UpdatedBy: "Manoj S",
            QuestionsRiskScoringId: 156,
            Risks: '[{"RiskCategory_Id":114,"Score":1,"RiskId":28},{"RiskCategory_Id":73,"Score":3,"RiskId":35}]',
          },
        ],
      },
    ],
  },
  {
    ServiceCatalogId: 69,
    ServiceName: "New edit 5",
    ServiceDescription: "ded",
    ServiceType: 112,
    Engage: "single",
    IsMapped: true,
    ServiceOwnerId: 154,
    SubDepartmentId: 156,
    Remarks: "efdds",
    Status: 1,
    IsActive: "Active",
    Updated_By: "Karthick A",
    Updated_On: null,
    Regulator: 65,
    RegulatoryRef: "test",
    SumOfMaxCritical: "18",
    MaxOfMaxCritical: "15",
    ApplicableRisk: "[36,114,116,115,143,73]",
    ServiceOwnerName: "Automobile",
    ServicetypeName: "Service",
    Sub_Department: "Electric Sub01",
    Summary: "Materials",
    ServiceGroupId: 119,
    ServiceGroupName: "Catering and Hospitality",
    ServiceCriticality: "SUM OF MAX",
    Regulator_Name: "HDFC Lite",
    ServiceParams: [
      {
        ParameterId: 93,
        Parameter: "new again check 1",
        Sum: 18,
        Max: 3,
        IsActive: true,
        UpdatedBy: "jino x",
        MaxScoring:
          '[{"RiskCategory_Id":143,"Score":3},{"RiskCategory_Id":116,"Score":3},{"RiskCategory_Id":115,"Score":3},{"RiskCategory_Id":114,"Score":3},{"RiskCategory_Id":73,"Score":3},{"RiskCategory_Id":36,"Score":3}]',
        Questions: [
          {
            Question: "new Q 4",
            IsActive: true,
            UpdatedBy: "jino x",
            QuestionsRiskScoringId: 139,
            Risks:
              '[{"RiskCategory_Id":143,"Score":3,"RiskId":35},{"RiskCategory_Id":116,"Score":3,"RiskId":35},{"RiskCategory_Id":115,"Score":3,"RiskId":35},{"RiskCategory_Id":114,"Score":3,"RiskId":35},{"RiskCategory_Id":73,"Score":3,"RiskId":35},{"RiskCategory_Id":36,"Score":3,"RiskId":35}]',
          },
        ],
      },
      {
        ParameterId: 108,
        Parameter: "data leakage",
        Sum: 18,
        Max: 3,
        IsActive: true,
        UpdatedBy: "Karthick A",
        MaxScoring:
          '[{"RiskCategory_Id":143,"Score":3},{"RiskCategory_Id":116,"Score":3},{"RiskCategory_Id":115,"Score":3},{"RiskCategory_Id":114,"Score":3},{"RiskCategory_Id":73,"Score":3},{"RiskCategory_Id":36,"Score":3}]',
        Questions: [
          {
            Question: "what is DL?",
            IsActive: true,
            UpdatedBy: "Karthick A",
            QuestionsRiskScoringId: 160,
            Risks:
              '[{"RiskCategory_Id":143,"Score":3,"RiskId":35},{"RiskCategory_Id":116,"Score":3,"RiskId":35},{"RiskCategory_Id":115,"Score":3,"RiskId":35},{"RiskCategory_Id":114,"Score":3,"RiskId":35},{"RiskCategory_Id":73,"Score":3,"RiskId":35},{"RiskCategory_Id":36,"Score":3,"RiskId":35}]',
          },
        ],
      },
      {
        ParameterId: 109,
        Parameter: "rt",
        Sum: 18,
        Max: 3,
        IsActive: true,
        UpdatedBy: "Karthick A",
        MaxScoring:
          '[{"RiskCategory_Id":143,"Score":3},{"RiskCategory_Id":116,"Score":3},{"RiskCategory_Id":115,"Score":3},{"RiskCategory_Id":114,"Score":3},{"RiskCategory_Id":73,"Score":3},{"RiskCategory_Id":36,"Score":3}]',
        Questions: [
          {
            Question: "what is rt?",
            IsActive: true,
            UpdatedBy: "Karthick A",
            QuestionsRiskScoringId: 161,
            Risks:
              '[{"RiskCategory_Id":143,"Score":3,"RiskId":35},{"RiskCategory_Id":116,"Score":3,"RiskId":35},{"RiskCategory_Id":115,"Score":3,"RiskId":35},{"RiskCategory_Id":114,"Score":3,"RiskId":35},{"RiskCategory_Id":73,"Score":3,"RiskId":35},{"RiskCategory_Id":36,"Score":3,"RiskId":35}]',
          },
        ],
      },
      {
        ParameterId: 110,
        Parameter: "test",
        Sum: 18,
        Max: 3,
        IsActive: true,
        UpdatedBy: "Karthick A",
        MaxScoring:
          '[{"RiskCategory_Id":143,"Score":3},{"RiskCategory_Id":116,"Score":3},{"RiskCategory_Id":115,"Score":3},{"RiskCategory_Id":114,"Score":3},{"RiskCategory_Id":73,"Score":3},{"RiskCategory_Id":36,"Score":3}]',
        Questions: [
          {
            Question: "test1",
            IsActive: true,
            UpdatedBy: "Karthick A",
            QuestionsRiskScoringId: 162,
            Risks:
              '[{"RiskCategory_Id":143,"Score":3,"RiskId":35},{"RiskCategory_Id":116,"Score":3,"RiskId":35},{"RiskCategory_Id":115,"Score":3,"RiskId":35},{"RiskCategory_Id":114,"Score":3,"RiskId":35},{"RiskCategory_Id":73,"Score":3,"RiskId":35},{"RiskCategory_Id":36,"Score":3,"RiskId":35}]',
          },
        ],
      },
      {
        ParameterId: 111,
        Parameter: "test2",
        Sum: 18,
        Max: 3,
        IsActive: true,
        UpdatedBy: "Karthick A",
        MaxScoring:
          '[{"RiskCategory_Id":143,"Score":3},{"RiskCategory_Id":116,"Score":3},{"RiskCategory_Id":115,"Score":3},{"RiskCategory_Id":114,"Score":3},{"RiskCategory_Id":73,"Score":3},{"RiskCategory_Id":36,"Score":3}]',
        Questions: [
          {
            Question: "test3",
            IsActive: true,
            UpdatedBy: "Karthick A",
            QuestionsRiskScoringId: 163,
            Risks:
              '[{"RiskCategory_Id":143,"Score":3,"RiskId":35},{"RiskCategory_Id":116,"Score":3,"RiskId":35},{"RiskCategory_Id":115,"Score":3,"RiskId":35},{"RiskCategory_Id":114,"Score":3,"RiskId":35},{"RiskCategory_Id":73,"Score":3,"RiskId":35},{"RiskCategory_Id":36,"Score":3,"RiskId":35}]',
          },
        ],
      },
    ],
  },
];
