import React, { FC, useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

interface IFileViewerProps {
  URL: any;
  type?: any;
  fileName?: any;
}

const FilteViewer: FC<IFileViewerProps> = ({ URL, type, fileName }) => {
  const [file, setFile] = useState<File | null>(null);
  const [dataUrl, setDataUrl] = useState<any>(null);

  useEffect(() => {
    if (URL && type == "preview") {
      const uploadedFile = URL;
      setFile(URL);
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileDataUrl = e.target?.result as string;
        setDataUrl(fileDataUrl);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      setDataUrl(URL);
    }
  }, [URL]);

  const call_viewer = () => {
    try {
      return <DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: dataUrl, fileName: fileName }]} />;
    } catch (error: any) {
      return <div>Failed to view : {error.message}</div>;
    }
  };

  return (
    dataUrl && (
      <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
        {call_viewer()}
        {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: URL }]} /> */}
      </div>
    )
  );
};

export default FilteViewer;
