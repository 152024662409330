import { AxiosError, AxiosResponse } from "axios";
import { Roles_URL } from "./endpoints/roles";
import {
  IAddRoleResponse,
  IAllRoleResponse,
  IGetRolesByIdResponse,
  IMENUSResponse,
  IVHColumnRes,
} from "../../models/roles.type";
import { axiosInstance } from "./config/axiosConfig";

const getRolesList = (requesrParams: any): Promise<IAllRoleResponse> =>
  axiosInstance
    .get(Roles_URL.GetRoles, { params: requesrParams })
    .then((response: AxiosResponse<IAllRoleResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addRole = (requestBody: any): Promise<IAddRoleResponse> =>
  axiosInstance
    .post(Roles_URL.AddRole, requestBody)
    .then((response: AxiosResponse<IAddRoleResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateRole = (requestBody: any): Promise<IAddRoleResponse> =>
  axiosInstance
    .post(Roles_URL.UpdateRole, requestBody)
    .then((response: AxiosResponse<IAddRoleResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteRole = (requestBody: any): Promise<IAddRoleResponse> =>
  axiosInstance
    .post(Roles_URL.DeleteRole, requestBody)
    .then((response: AxiosResponse<IAddRoleResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const allmenus = (): Promise<IMENUSResponse> =>
  axiosInstance
    .get(Roles_URL.GetALLMenus)
    .then((response: AxiosResponse<IMENUSResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getRosById = (req: any): Promise<IGetRolesByIdResponse> =>
  axiosInstance
    .get(Roles_URL.GetRolesById, { params: req })
    .then((response: AxiosResponse<IGetRolesByIdResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

  const GetRolesByMenuId = (req: any): Promise<IMENUSResponse> =>
    axiosInstance
      .get(Roles_URL.GetRolesByMenu, { params: req })
      .then((response: AxiosResponse<IMENUSResponse>) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Error getting Data" + response);
      })
      .catch((error: AxiosError | string) => {
        throw error;
  });
const getVersionHistoryRole = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(Roles_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const roleService = {
  getRolesList,
  addRole,
  updateRole,
  deleteRole,
  allmenus,
  getRosById,
  GetRolesByMenuId,
  getVersionHistoryRole,
};

export default roleService;
