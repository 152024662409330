import { Box, Divider, Typography } from "@mui/material";
import React from "react"
import PageInnerHeading from "../CommonHeadings/PageInnerHeading";
import CustomSvg from "../CustomSvg";


interface CustomBasicAccordionprops {
    expanded : boolean ;
    handleChange : any ;
   title : string ;
    secondaryText? : any 
    details: any ;
    ishidedivider? : boolean ;
  
}

export const CustomBasicAccordion : React.FC<CustomBasicAccordionprops> = (props :CustomBasicAccordionprops ) => {
    const {
        expanded,
        handleChange,
        title,
        secondaryText,
        details  ,
        ishidedivider
    } = props;

    return(
        <>
         <Box  onClick={() => handleChange(!expanded)} sx={{cursor: "pointer",  display: "flex", alignItems: "center" , justifyContent:'space-between' , marginTop:'8px !important'  }} >
             <Box sx={{ display: "flex", alignItems: "center"   }}>
            {" "}
            <Box
              sx={{ cursor: "pointer", display: "flex", position: "relative", top: "-2px", mr: "5px" }}
             
            >
              {expanded ? (
                <CustomSvg size={"16px"} name={"chevron-down"} />
              ) : (
                <CustomSvg size={"16px"} name={"chevron-up"} />
              )}
            </Box>
            <PageInnerHeading title={`${title}`} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
            {typeof secondaryText === 'string' ? (
            <Typography>
              {secondaryText}
            </Typography>
          ) : (
            secondaryText // Render the component directly
          )}
          </Box>
          </Box>
                      
          {expanded ? (
            <Box sx={{marginTop:'0px !important' , padding:'8px 16px'}}>
                   {typeof details === 'string' ? (
            <Typography>
              {details}
            </Typography>
          ) : (
            details // Render the component directly
          )}
            </Box>
          ) : null}
         {!ishidedivider ? <Divider sx={{marginTop:'8px !important'}}> </Divider> : null }
        </>
    )
}