import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import lovMasterService from "../services/lovService";
import { IAddLovResponse, ILov, ILovNamesResponse, ILovResponse,IVHColumnRes } from "../../models/lov.type";

export interface ILOVData {
    lovTable: any;
    lovTableMain: any;
    LovNames: any;
    isLoading: boolean;
    islovAdded: boolean;
      versionHistory:{LovRecords :ILov[] , lovVHeader : IVHColumnRes[]};
    islovHistory: boolean;
    islovDeleted: boolean;
    ToasterMsg: any;
}

export const initialState: ILOVData = {
    lovTable: [],
    lovTableMain: [],
    LovNames: [],
    isLoading: false,
    islovAdded: false,
      versionHistory:{LovRecords :[] , lovVHeader : []},
    islovHistory: false,
    islovDeleted: false,
    ToasterMsg: {Message: "", Status: ""},
};

export const getLovList = createAsyncThunk(
    "getLovList",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await lovMasterService.getLovList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLovNamesList = createAsyncThunk(
    "getLovNamesList",
    async (requesrParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await lovMasterService.getLovNamesList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const addEditLov = createAsyncThunk("addEditLov", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await lovMasterService.addEditLov(requesrParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteLov = createAsyncThunk(
    "deleteLov",
    async (requesrParams: any, { rejectWithValue }) => {
        console.log(requesrParams,"requesrParams");
        
        try {
            let reqbody = { ...requesrParams };
            console.log(reqbody,"requesrParams");
            const response: any = await lovMasterService.deleteLov(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLOVHistory = createAsyncThunk("getLOVHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await lovMasterService.getVersionHistoryLov(reqbody);
    const {ListOfValue , HeaderJson } =response.data;
    return {LovRecords:ListOfValue, HeaderJson : HeaderJson.ColumnHeaders}

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const lovMasterDataSlice = createSlice({
    name: "lovMasterData",
    initialState,
    reducers: {
        updateLovAddandEdit: (state, action) => {
            state.islovAdded = false;
            state.islovDeleted = false;
            state.ToasterMsg = {Message: "", Status: ""};
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.lovTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.lovTable];
                copyVendorData[indexVendor] = action.payload;
                state.lovTable = copyVendorData;
            }
        },
        updateVersionHistory:  (state, action)=>{            
          state.versionHistory.LovRecords = [];
          state.versionHistory.lovVHeader = [];
        }, 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLovList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLovList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.lovTable = action.payload.filter((user: any) => {
                
                        return user.IsActive;
                
                });
                state.lovTableMain = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });

                
                // state.lovTable = action.payload
                state.isLoading = false;
            })
            .addCase(getLovList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(getLovNamesList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLovNamesList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                // state.lovTable = action.payload.map((user: any) => {
                //     user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                //     return user;
                // });
                state.LovNames = action.payload
                state.isLoading = false;
            })
            .addCase(getLovNamesList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(addEditLov.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEditLov.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action.payload , 'action.payload1');
                //     let item = {...action.payload};
                //     let copyallUsers= [...state.dataTable];
                //     copyallUsers.push(item);
                //     state.dataTable = copyallUsers;
                state.islovAdded = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(addEditLov.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(deleteLov.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteLov.fulfilled, (state, action: PayloadAction<any>) => {
                state.islovDeleted = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(deleteLov.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
          .addCase(getLOVHistory.pending, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })
          .addCase(getLOVHistory.fulfilled, (state, action: PayloadAction<any>) => {
            state.versionHistory.lovVHeader = action.payload.HeaderJson
            state.versionHistory.LovRecords = action.payload.LovRecords.map((user: any) => {
                user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                return user;
            });
            state.isLoading = false;
            // state.ToasterMsg = "Department data deleted successfully";
          })
          .addCase(getLOVHistory.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })

    },
});

export const { updateLovAddandEdit, updateUser, updateVersionHistory} = lovMasterDataSlice.actions;
export default lovMasterDataSlice.reducer;
