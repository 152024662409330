import { Box, Dialog, DialogContent, FormControl, FormHelperText, IconButton, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import PreviewIcon from '@mui/icons-material/Preview';
import FilteViewer from '../fileViewer/FilteViewer';
import CustomDialog from '../dynamicDialog/CustomDialog';

interface CustomMultiFileUploaderProps {
    onFileUpload: (files: File[]) => void;
    existingFileUrls?: string[];
    acceptFormats: string[];
    maxFileSize: number;
}

const CustomMultiFileUploader: React.FC<CustomMultiFileUploaderProps> = ({ onFileUpload, existingFileUrls, acceptFormats, maxFileSize, }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [filePreviews, setFilePreviews] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [acceptFormat, setacceptFormat] = useState<string[]>([]);

    const [openPopUp, setOpenPopUp] =  useState<boolean>(false);
    const [fileObjectURL , setFileObjectURL] = useState<any>();
    // Function to handle file removal
    const removeFile = (index: number) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);

        const newPreviews = [...filePreviews];
        newPreviews.splice(index, 1);
        setFilePreviews(newPreviews);
    };

    const view_accept = ['.jpg' , '.jpeg' , '.gif' , '.tiff' ,'.pdf' , '.png' , '.xls' , '.xlsx' , '.docx' , '.doc' , '.ppt' , '.pptx' , '.csv']

    const getFileMIMEType = (fileExtension: string): string | undefined => {
      console.log(fileExtension.toLowerCase());
      
        switch (fileExtension.toLowerCase()) {
            case '.jpg':
            case '.jpeg':
                return 'image/jpeg';
            case '.png':
                return 'image/png';
            case '.gif':
                return 'image/gif';
            case '.bmp':
                return 'image/bmp';
            case '.tiff':
                return 'image/tiff';
            case '.webp':
                return 'image/webp';
            case '.pdf':
                return 'application/pdf';
            case '.doc':
            case '.docx':
                return 'application/msword';
            case '.xls':
            case '.xlsx':
                return 'application/vnd.ms-excel';
            case '.ppt':
            case '.pptx':
                return 'application/vnd.ms-powerpoint';
            case '.txt':
                return 'text/plain';
            case '.csv':
                return 'text/csv';
            case '.zip':
                return 'application/zip';
            case '.rar':
                return 'application/x-rar-compressed';
            default:
                return undefined;
        }
    };

    useEffect(() => {
        let con_acceptFormats: string[] = acceptFormats.map(getFileMIMEType).filter((mimeType): mimeType is string => !!mimeType);
        setacceptFormat(con_acceptFormats)
    }, [acceptFormats])




    const validateFile = (file: File): boolean => {
        // Check file format
        console.log(file);
        
        // const isValidFormat = acceptFormat.some(format => file.type.startsWith(format));
        const isValidFormat = acceptFormats.some(format => file.name.endsWith(format));
        if (!isValidFormat) {
            setError(`Invalid file format. Accepted formats: ${acceptFormats.join(', ')}`);
            return false;
        }

        // Check file size
        if (file.size > maxFileSize) {
            setError(`File size exceeds the maximum limit of ${maxFileSize / (1024 * 1024)} MB`);
            return false;
        }
        setError('')
        return true;
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles: File[] = [];
        const newPreviews: string[] = [];
        acceptedFiles.forEach(file => {
            if (validateFile(file)) {
                newFiles.push(file);
                newPreviews.push(URL.createObjectURL(file));
            }
        });
        setFiles([...files, ...newFiles]);
        setFilePreviews([...filePreviews, ...newPreviews]);
        onFileUpload([...files, ...newFiles]);
    }, [files, filePreviews, onFileUpload, validateFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
      <Box>
        <Box {...getRootProps()} style={dropzoneStyles}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the files here ...</Typography>
          ) : (
            <Box>
              <CloudUploadIcon />

              <Typography>Choose file or drag</Typography>
            </Box>
          )}
        </Box>

        <FormHelperText error={Boolean(error)} id="my-helper-text">
          {error}
        </FormHelperText>

        {existingFileUrls &&
          existingFileUrls.map((url, index) => (
            <Box key={index}>
              <img
                src={url}
                alt={`Existing file ${index}`}
                style={imageStyles}
              />
            </Box>
          ))}
        {files.map((file, index) => (
          <Box key={index}>
            <Paper
              elevation={0}
              sx={{
                paddingY: 1,
                paddingX: 2,
                marginY: 1,
                border: "1px solid rgb(233 233 233)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{display :'flex'}}>
                  <Typography sx={{mr:2 , margin :'auto'}}>
                    {file.name} - {formatBytes(file.size)}
                  </Typography>


                  <IconButton
                  disabled={!view_accept.some(format => file.name.endsWith(format))}
                 onClick={() => {
                  setOpenPopUp(true)
                  setFileObjectURL(file);
              }}
                  aria-label="delete"
                >
                  <PreviewIcon />
                </IconButton>
                  

                </Box>

                <IconButton
                  onClick={() => removeFile(index)}
                  aria-label="delete"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Paper>
          </Box>
        ))}

<CustomDialog onHide={() => {
            setOpenPopUp(false);
            setFileObjectURL(null);
          } } show={openPopUp} header={'File Preview'} contentNode={ <>
           
            <FilteViewer URL={fileObjectURL} type={'preview'}/>
          
            </>}/>
        {/* <Dialog
          open={openPopUp}
          onClose={() => {
            setOpenPopUp(false);
            setFileObjectURL(null);
          }            
        }
          maxWidth={"lg"}
          fullWidth
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent>
           
          </DialogContent>
        </Dialog> */}
      </Box>
    );
};

// Style for the dropzone
const dropzoneStyles: React.CSSProperties = {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '10px',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxHeight:'100px'
};

// Style for images
const imageStyles: React.CSSProperties = {
    maxWidth: '100%',
    maxHeight: '200px',
};

const formatBytes = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export default CustomMultiFileUploader;
