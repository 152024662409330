import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BulkUploadService from "../services/bulkuploadMasterService";

export interface IBulkUploadData {
    dataTablebulkupload: any;
  isLoading: boolean;
  isBulkUploadAdded: boolean;
  ToasterMsg: string;
}

export const initialState: IBulkUploadData = {
  dataTablebulkupload: [],
  isLoading: false,
  isBulkUploadAdded: false,
  ToasterMsg: "added BulkUpload data successfully",
};


export const addBulkUpload = createAsyncThunk("addBulkUpload", async (requesrParams: any , { rejectWithValue }) => {

    if(requesrParams.section === 'department'){
        try {
            const response: any = await BulkUploadService.addBulkUploadDepartment(requesrParams.data);
                return response.data;
          } catch (error) {
            return rejectWithValue(error);
          }

    }

    if(requesrParams.section === 'location'){
      try {
          const response: any = await BulkUploadService.addBulkUploadLocation(requesrParams.data);
              return response.data;
        } catch (error) {
          return rejectWithValue(error);
        }

  }

  if(requesrParams.section === 'thirdparty'){
    try {
        const response: any = await BulkUploadService.addBulkUploadThirdParty(requesrParams.data);
            return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }

}
if(requesrParams.section === 'regionalsetting'){
  try {
      const response: any = await BulkUploadService.addRegionalSetting(requesrParams.data);
          return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }

}

if(requesrParams.section === 'currency'){
  try {
      const response: any = await BulkUploadService.addCurrency(requesrParams.data);
          return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }

}

if(requesrParams.section === 'industory'){
  try {
      const response: any = await BulkUploadService.addIndustory(requesrParams.data);
          return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }

}

if(requesrParams.section === 'document'){
  try {
      const response: any = await BulkUploadService.addDocument(requesrParams.data);
          return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }

}

if(requesrParams.section === 'branch'){
  try {
      const response: any = await BulkUploadService.addBranch(requesrParams.data);
          return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }

}

if(requesrParams.section === 'assessment_Questions'){
  try {
    console.log(requesrParams.data);
    
      const response: any = await BulkUploadService.addAssessmentQuestions(requesrParams.data);
          return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }

}



});



export const BulkUploadDataSlice = createSlice({
  name: "bulkuploadMaster",
  initialState,
  reducers: {
    resetBulkuploadResponseData: (state, action) => {
        state.dataTablebulkupload = [];
        state.isBulkUploadAdded = false ;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBulkUpload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBulkUpload.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action , state);
        state.dataTablebulkupload = [...action.payload]
        state.isBulkUploadAdded = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        // state.ToasterMsg = action.payload.Message;
      })
      .addCase(addBulkUpload.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })


  },
});

export const { resetBulkuploadResponseData } = BulkUploadDataSlice.actions;
export default BulkUploadDataSlice.reducer;
