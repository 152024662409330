import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import masterEditEnableService from "../services/masterEditEnableService";
import { IMasterEditEnableResponse } from "../../models/masterEditEnable.type";

export interface IMasterEditEnableData {
    EditEnableData: IMasterEditEnableResponse[];
    isLoading: boolean;

}

export const initialState: IMasterEditEnableData = {
    EditEnableData: [],
    isLoading: false,

};



// export const GetMasterEditEnable = createAsyncThunk("GetMasterEditEnable", async (requesrParams: any, { rejectWithValue }) => {
//     try {
//         const response: any = await masterEditEnableService.GetMasterEditEnable(requesrParams);
//         const data = response;
//         console.log(response,"response");

//         return data;
//     } catch (error) {
//         return rejectWithValue(error);
//     }
// });

export const GetMasterEditEnable = createAsyncThunk(
    "GetMasterEditEnable",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await masterEditEnableService.GetMasterEditEnable(requesrParams);
            const data  = response;
            console.log(response, "response");
            
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const MasterEditEnableDataSlice = createSlice({
    name: "MasterEditEnableData",
    initialState,
    reducers: {
        updateMasterEditEnable: (state, action) => {
            state.EditEnableData = [];
        },

    },
    extraReducers: (builder) => {
        builder

            .addCase(GetMasterEditEnable.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetMasterEditEnable.fulfilled, (state, action: PayloadAction<any>) => {
                state.EditEnableData = action.payload;
                console.log(action.payload,"action.payload");
                
                state.isLoading = false;
            })
            .addCase(GetMasterEditEnable.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

    },
});

export const { updateMasterEditEnable, } = MasterEditEnableDataSlice.actions;
export default MasterEditEnableDataSlice.reducer;
