import { FC, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface IProgressProps{
  showProgress?: boolean
}

const ProgressBar:FC<IProgressProps> = ({showProgress=false}) => {
  const { isProgressLoading } = useSelector((state: RootState) => state.appLoader);
  const [open, setOpen] = useState<boolean>(false); 

  useEffect(() => {
    setOpen(isProgressLoading);
  }, [isProgressLoading]);

  useEffect(() => {
    setOpen(showProgress);
  }, [showProgress]);

  useEffect(() => {
    setOpen(isProgressLoading);
  }, [isProgressLoading]);

  return (
    // <Backdrop
    //   sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //   open={open}
    // >
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "50%",
        left: "48%",
        zIndex:1
      }}
    >
      <CircularProgress />
    </Box>
    // </Backdrop>
  );
};

export default ProgressBar;
