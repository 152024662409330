import { AxiosError, AxiosResponse } from "axios";
import { CHNAGE_PASWORD } from "./endpoints/changePassword";
// import { ICustomFields, IGetMasterResponse } from "../../models/customFields.type";
import { axiosInstance } from "./config/axiosConfig";

const postChangePassword = (req: any): Promise<any> => {
  return axiosInstance
    .post(CHNAGE_PASWORD.ChangePassword, req)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Getting Master Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const forgotPassword = (req: any): Promise<any> => {
  return axiosInstance
    .post(CHNAGE_PASWORD.ForgotPassword, req)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Getting Master Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};


const changePasswordService = {
    postChangePassword,
    forgotPassword
  };
  
  export default changePasswordService;