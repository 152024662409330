import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddServiceCatalogMaster } from "./AddServiceCatalog";
import {
  deleteServiceCatalog,
  getServiceCatalogList,
  updateServiceCatalogAddandEdit,
  updateServicecatalogVersionHistory,
  versionHistoryServiceCatalog,
} from "../../store/slices/serviceCatalog";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import {
  deleteSaveandDraft,
  getAllSaveandDraftList,
  updateSaveasDraftAddandEdit,
} from "../../store/slices/saveasdraft";
import { ToasterActivityConfig } from "../../config/config";
import Dashboard from "../dashboard/Dashboard";
import { getThirdPartyList } from "../../store/slices/thirdPartyData";
import { HandleRecordLockCheck, recordLockGet } from "../recordLock/RecordLock";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { getMetricsMasterList } from "../../store/slices/metricsMasters";

const columns: any = {
  table_name: "Service Catalog",
  isAction: true,
  columns: [
    {
      Field: "ServiceName",
      FieldType: "string",
      DisplayName: "Service Name",
    },
    {
      Field: "ServicetypeName",
      FieldType: "string",
      DisplayName: "Service Type",
    },
    {
      Field: "ServiceGroupName",
      FieldType: "string",
      DisplayName: "Service Group",
    },

    {
      Field: "ServiceDescription",
      FieldType: "string",
      DisplayName: "Service Description",
    },
    {
      Field: "ServiceOwnerName",
      FieldType: "string",
      DisplayName: "Service Owner",
    },
    // {
    //   Field: "Summary",
    //   FieldType: "string",
    //   DisplayName: "Summary",
    // },

    {
      Field: "Summary",
      FieldType: "string",
      DisplayName: "Service Criticality",
    },

    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "Updated_By",
      FieldType: "string",
      DisplayName: "Modified By",
    },
  ],
};
const columns_version: any = {
  table_name: "Service Catalog Version History",
  isAction: false,
  columns: [],
};

const ServiceCatalogMaster = () => {
  const dispatch = useAppDispatch();

  const {
    ServiceCatalogversionHistory,
    isServiceCatlogLoading,
    ServiceCatlogdata,
    isServiceCatalogAdded,
    isServiceCatalogUpdated,
    isServiceCatalogDeleted,
    servicecatlogtoasterMsg,
  } = useSelector((state: RootState) => state.serviceCatalog);
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [serviceCatalog, setserviceCatalog] = useState<any[]>([]);
  const [serviceCatalogDraft, setserviceCatalogDraft] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isDraftData, setIsDraftDate] = useState<any>(null);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  useEffect(() => {
    CallonpageLoad();
  }, []);

  useEffect(() => {
    if (
      ServiceCatalogversionHistory.ServiceCatalogVHeader &&
      ServiceCatalogversionHistory.ServiceCatalogVHeader.length > 0
    ) {
      setIsVersionHistory(true);
      let re_columns = {
        ...columns_version,
        columns: ServiceCatalogversionHistory.ServiceCatalogVHeader,
      };
      setVersionHistoryData(re_columns);
    }
  }, [ServiceCatalogversionHistory]);

  useEffect(() => {
    let modifiedData: any[] = [];
    if (draftdata && Array.isArray(draftdata)) {
      draftdata &&
        draftdata.forEach((item: any) => {
          let JsonData = JSON.parse(item.DraftData);
          JsonData.DraftStatus = item.DraftStatus;
          JsonData.DraftId = item.DraftId;
          modifiedData.push(JsonData);
        });
    }
    setserviceCatalogDraft(modifiedData);
  }, [draftdata]);

  const callDraft = () => {
    const dataObj = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "Service Catalog",
      ScreenName: "Service Catalog",
    };
    dispatch(getAllSaveandDraftList(dataObj));
  };

  const CallonpageLoad = () => {
    callDraft();
    dispatch(getServiceCatalogList());
    dispatch(getMetricsMasterList({}));
    dispatch(getThirdPartyList({ FormID: 0 }));
  };

  const deleteServiceCatalogList = () => {
    let formData = {
      ServiceCatalogId: isDeleteData?.ServiceCatalogId,
      Updated_By: user?.UserName,
      ModuleName: "Service Catalog",
      Activity: isDeleteData?.IsActive === "Active" ? ToasterActivityConfig.inactive : ToasterActivityConfig.active,
      isActive: isDeleteData?.IsActive === "Active" ? false : true,
      DataForDisplay: "",
    };
    dispatch(deleteServiceCatalog(formData));
  };

  const deleteDraftList = () => {
    let formData = {
      DraftId: isDeleteData?.DraftId,
      CreatedBy: user?.UserName,
    };

    dispatch(deleteSaveandDraft(formData));
  };

  useEffect(() => {
    if (isServiceCatalogAdded === true) {
      // dispatch(updateLoader(false));
      CallonpageLoad();
      setisShowDraft(1);
      dispatch(updateToaster({ isToaster: true, toasterMsg: servicecatlogtoasterMsg }));
      dispatch(updateServiceCatalogAddandEdit(false));
      callDraft();
    }

    if (isServiceCatalogUpdated === true) {
      // dispatch(updateLoader(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: servicecatlogtoasterMsg }));
      dispatch(updateServiceCatalogAddandEdit(false));
      CallonpageLoad();
    }
    if (isServiceCatalogDeleted === true) {
      // dispatch(updateLoader(false));
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: servicecatlogtoasterMsg }));
      dispatch(updateServiceCatalogAddandEdit(false));
      CallonpageLoad();
    }
  }, [isServiceCatalogAdded, isServiceCatalogUpdated, isServiceCatalogDeleted]);

  useEffect(() => {
    if (isDraftAdded) {
      CallonpageLoad();
      setisShowDraft(0);
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
    } else if (isDraftUpdated) {
      CallonpageLoad();
      setisShowDraft(0);
      // setUserUpdated(true);
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
    }
  }, [isDraftAdded, isDraftUpdated]);
  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName,
      MenuId: permssionPage?.MenuId,
      ModuleName: "Service Catalog",
      Activity: "edit",
    };
    return recordLockData;
  };
  useEffect(() => {
    setserviceCatalog(ServiceCatlogdata);
  }, [ServiceCatlogdata]);
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      const data = {
        RecordlockId: recordLock[0].RecordlockId,
        RecordId: recordLock[0].RecordId,
        IsActive: false,
      };
      dispatch(UpdateRecordLock(data));
    }
    return recordLock;
  };

  const handleOpen = async (data: any, option: string) => {
    if (option === "delete") {
      setIsDeleteData(data);
    } else if (option === "history") {
      dispatch(
        versionHistoryServiceCatalog({
          ServiceCatalog_Id: data.ServiceCatalogId,
          formId: 21,
        })
      );
    } else if (option == "edit") {
      if (isShowDraft === 0) {
        setIsAddorEdit(true);
        setOption("add");
        setIsDraftDate(data);
        setIsEditDate(null);
      } else if (isShowDraft !== 0) {
        dispatch(updateLoader(true));
        console.log(data, "Service Catalog data");

        const canEdit = await HandleRecordLockCheck(data?.ServiceCatalogId, permssionPage, "Service Catalog", dispatch);
   //     if (canEdit) {
          setIsAddorEdit(true);
          dispatch(updateLoader(false));
          setOption(option);
          setIsDraftDate(null);
          setIsEditDate(data);
          const dispatchData = handleAddRecordLock(data?.ServiceCatalogId);
          await dispatch(AddRecordLock(dispatchData));
          await dispatch(GetRecordLock(recordLockGet(data?.ServiceCatalogId, permssionPage, "Service Catalog")));
   //     }
      }
    } else if (option === "view") {
      setIsAddorEdit(true);
      setOption(option);
      setIsDraftDate(null);
      setIsEditDate(data);
    }
  };
  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    setIsDraftDate(null);
    setOption("");
  };

  return (
    <>
      {!isAddorEdit ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                {permssionPage?.MenuName}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={isShowDraft ? serviceCatalog : serviceCatalogDraft}
                handleAction={handleOpen}
                handleAdd={() => setAddFormcloseoropen(true)}
                enableZoomIcon={true}
                showhistory={isShowDraft ? true : false}
                isDraftView={true}
                disableselect={isShowDraft ? false : true}
                draftCount={serviceCatalogDraft.length}
                isEnableDraft={isShowDraft}
                handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={isShowDraft ? permssionPage?.IsAdd === 1 : false}
                showDelete={isShowDraft ? permssionPage?.IsDelete === 1 : false}
                showView={isShowDraft ? permssionPage?.IsView === 1 : false}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={isShowDraft ? permssionPage?.IsExport === 1 : false}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Service Catalog"
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <AddServiceCatalogMaster
            view={Option == "view" ? true : false}
            edit={Option == "edit" ? true : false}
            isEditData={isEditData}
            isDraftData={isDraftData}
            onClose={() => {
              if (Option == "edit" && isShowDraft !== 0) {
                recordLockUpdate();
              }
              setAddFormcloseoropen(false);
            }}
          />
        </Box>
      )}

      <ModalPopUp
        onDeleteMethod={() => deleteServiceCatalogList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
        header={"Confirm"}
        text={`Are you sure you want to ${
          isDeleteData?.IsActive === "Active" ? "deactivate" : "Activate"
        }  the selected Service Catalog ?`}
      />
      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          dispatch(updateServicecatalogVersionHistory([]));
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={"Service Catalog Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={ServiceCatalogversionHistory.ServiceCatalogversionHistory}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Service Catalog Version History"
            />
          </>
        }
      />
    </>
  );
};

export default ServiceCatalogMaster;
