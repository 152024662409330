import React, { useEffect, useState } from "react";
import { AddVendorDetails } from "../../vendorDetails/AddVendor";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { AQMMapping } from "../../vendorOnBoarding/aqmMapping/AQMMapping";
import {
  updaterolesandresponsAddandEdit,
  updaterolesandresponsresposeData,
} from "../../../store/slices/rolesAndResponsiblity";
import { updateVendorDetailsAddandEdit } from "../../../store/slices/vendorDetails";
import { updateAQMMappingAddandEdit } from "../../../store/slices/assesmentQuestionnaireMapping";
import workFlowService from "../../../store/services/workFlowService";
import { COBWorflowPages, VOBWorflowPages } from "../../../config/config";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { Box, Grid, Stack, Typography } from "@mui/material";
import AddCompanyMasterSteper from "../../companyMaster/AddCompanyMasterSteper";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { COBStepper } from "../COBStepper/COBStepper";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { CustomStatusStepper } from "../../../common/dynamicSteper/CustomStatusStepper";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getIndustryList, getParentIndustryList } from "../../../store/slices/industryMasterData";
import { getThirdPartyList } from "../../../store/slices/thirdPartyData";
import { getLocationList } from "../../../store/slices/locationMasterData";
import { getRegionalList } from "../../../store/slices/regionalSettingsData";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import { ApplicationParrameters } from "../../applicationParameters/ApplicationParameters";
import { RegionalSettings } from "../../master/regionalSettings/RegionalSettings";
import MakerChecker from "../../makerChecker/MakerChecker";
import WorkFlowExecution from "../../workFlowExecution/WorkFlowExecution";
import { useNavigate } from "react-router-dom";
import {
  getAllFormsConfiguration,
  getWorkFlowByCode,
  updateWorkFLowStep,
} from "../../../store/slices/workFlowExecutionslice";
import { updateApplicationParameter } from "../../../store/slices/applicationParameterData";
import { ACTIVITY } from "../../../constants/app.constants";
import { addEventLog } from "../../../store/slices/eventLog";
import { logoutUser } from "../../../store/slices/authorization";
import { showMenu } from "../../../store/slices/MenuShow";
import { ACTIVE } from "../../../utils/constants";
import MasterStepper from "../../companyMaster/masterStepper/MasterStepper";
export interface IVobWorflowProps {
  data?: any;
  isRenewal?: any;
  onClose?: () => void;
  renewalVendorId?: any;
}

export const COBMainWorkFlow: React.FC<IVobWorflowProps> = ({ data, onClose, isRenewal, renewalVendorId }) => {
  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    RolesAndResonsibilityInstanceId,
    isAddEditRolesAndResonsibilityLoading,
    RolesAndResonsibilitytoasterMsg,
  } = useSelector((state: RootState) => state.rolesAndResponsibilityData);
  const { applicationParameterData, isAPAdded, isAPDeleted, isAPUpdated, versionHistory, apToasterMsg } = useSelector(
    (state: RootState) => state.applicationParameter
  );
  const { CompanyTable, isCompanyAdded, isCompanyUpdated, ToasterMsg } = useSelector(
    (state: RootState) => state.CompanyMasterData
  );
  const [datasss, setDatasss] = useState(false);
  const { isAQMMappingAdded, isAddEditAQMMappingLoading, AQMMappingToasterMsg } = useSelector(
    (state: RootState) => state.aQMMappingData
  );
  const { user, userLoginResponse, isAuthenticated, currentPagePermissionData, menuData, menuOptions } = useSelector(
    (state: RootState) => state.loggerData
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [instanceValue, setInstanceValue] = useState<any>(null);
  const [workFlowData, setworkFlowData] = useState<any>();
  const [currentProcess, setcurrentProcess] = useState<any>("");
  const [instancedata, setinstanceNewFromrr] = useState<any>(null);
  const [currentProcessData, setCurrentProcessData] = useState<any>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [isloadingProcessCompleteCheck, setisloadingProcessCompleteCheck] = useState<any>(false);
  const steps = Object.values(COBWorflowPages);
  const [workFlowViewData, setWorkFlowViewData] = useState<any[]>([]);
  const [processList, setprocessList] = useState<any[]>([]);
  const [activestepCount, setactivestepCount] = useState(0);
  const { currentStep, currentStepWorkFlow } = useSelector((state: RootState) => state.workFlowExection);
  // useEffect(() => {
  //   if (data) {
  //     console.log(data, "data && ");
  //     try {
  //       console.log("data && instanceData 11");
  //       let instanceData = JSON.parse(data?.WorkflowProcessAsJson);
  //       console.log(instanceData,data?.WorkflowName, "data && instanceData");
  //       let firstInstanceData = instanceData.find(
  //         (item: any) => item?.FormName == COBWorflowPages.CompanyMaster || item?.ProcessId == 39
  //       );
  //       console.log(instanceData, "data && instanceData");
  //       if (firstInstanceData) {
  //         setcurrentProcess(COBWorflowPages.CompanyMaster);
  //         setworkFlowData(data);
  //         setprocessList([]);
  //       } else {
  //         setcurrentProcess("Not Found Process");
  //         setprocessList([]);
  //       }
  //     } catch {
  //       setcurrentProcess("Not Found Process");
  //       setprocessList([]);
  //     }
  //   }
  // }, [data]);
  const [updatedApplicationParameter, setUpdatedApplicationParameter] = useState<any>();
  useEffect(() => {
    if (data) {
      if (applicationParameterData && applicationParameterData.length > 0) {
        const getValue = applicationParameterData.filter((n) => n.Parameter_Value == data.Id && n.IsActive === ACTIVE);
        console.log(getValue, "getValue");
        setUpdatedApplicationParameter(getValue);
      }
      try {
        let instanceData = data?.WorkflowProcessAsJson;

        // Check if parsing is needed
        if (typeof instanceData === "string") {
          instanceData = JSON.parse(instanceData).filter((item: any) => item.IsActive === true);
        }

        // Create an array of instances for each item in WorkflowProcessAsJson
        const instances = instanceData.map((item: any) => ({
          ProcessName: item.FormName,
          InstanceId: item.ProcessId,
          WorkflowId: item.Id,
          updateWorkFlow: {
            Workflow_Id: item?.Id,
            Workflow_InstanceId: item.WorkflowId,
          },
        }));

        let firstInstanceData = instanceData.find(
          (item: any) => item?.FormName === COBWorflowPages.CompanyMaster || item?.ProcessId === 39
        );
        console.log(instanceData, firstInstanceData, firstInstanceData, instances, "data && instanceData");

        if (firstInstanceData) {
          setcurrentProcess(COBWorflowPages.CompanyMaster);
          setworkFlowData(data);
          setprocessList(instances);
        } else {
          setcurrentProcess("Not Found Process");
          setprocessList([]);
        }
      } catch (error) {
        console.error("Error parsing data:", error);
        setcurrentProcess("Not Found Process");
        setprocessList([]);
      }
    }
  }, [data]);
  console.log(updatedApplicationParameter, "applicationParameterData");
  if (updatedApplicationParameter) {
    const updateValue = {
      ...updatedApplicationParameter[updatedApplicationParameter?.length - 1],
      IsActive: false,
    };
    console.log(updateValue, "updateValueupdateValue");
  }
  const findMenuIdByMenuName = (menuData: any[], menuName: string): number | null => {
    let menuId: number | null = null;

    const searchMenu = (menus: any[]): void => {
      for (const menu of menus) {
        if (menu.MenuName === menuName) {
          menuId = menu.MenuId;
          return;
        }
        if (menu.SubMenus && menu.SubMenus.length > 0) {
          searchMenu(menu.SubMenus);
        }
        if (menuId) break;
      }
    };

    searchMenu(menuData);
    return menuId;
  };
  const menuName = "Application Parameter";
  const appParameter_moduleId = menuData ? findMenuIdByMenuName(menuData, menuName) : null;
  // findMenuNameByMenuId
  const checkProcessCompleteandNextProcessGo = async (createdData: any) => {
    setinstanceNewFromrr(createdData);
    setisloadingProcessCompleteCheck(true);
    // call api and take update of this instance data
    let checkOk = await checkFlowCompleted(createdData[createdData.length - 1]);
    setisloadingProcessCompleteCheck(false);
    // then data get go next
    if (checkOk) {
      let instanceData = JSON.parse(checkOk?.WorkflowProcessInstanceAsJson);
      let firstInstanceData = instanceData.find((item: any) => item.FormName == currentProcess);
      if (firstInstanceData && firstInstanceData.IsProcessCompleted == true) {
        let nextSeq = "";
        if (firstInstanceData?.ConditionTobeChecked != null && firstInstanceData?.ConditionTobeChecked != "") {
          nextSeq = firstInstanceData.IfYes_GoTo;
        } else {
          let currentSeqNo = firstInstanceData?.ProcessSeqno;
          const filteredData = instanceData?.filter((SEQitem: any) => SEQitem.ProcessSeqno > currentSeqNo);
          if (filteredData && filteredData.length > 0) {
            const nextSequence = filteredData?.reduce((prev: any, curr: any) => {
              return prev.ProcessSeqno < curr.ProcessSeqno ? prev : curr;
            });

            nextSeq = nextSequence?.ProcessSeqno;
          } else {
            console.warn("Filtered data is empty, cannot perform reduce operation");
          }
        }
        // find the next process , not find go to workflow page
        let nextProcess = instanceData.find((item: any) => item.ProcessSeqno == nextSeq);
        if (nextProcess) {
          let NextPageName = nextProcess?.FormName;
          if (NextPageName) {
            setCurrentProcessData(nextProcess);
            setcurrentProcess(NextPageName);
          }
        } else {
          if (updatedApplicationParameter) {
            setcurrentProcess("Not Found Process");
            // const updateValue = {
            //   ...updatedApplicationParameter[updatedApplicationParameter.length - 1],
            //   IsActive: false,
            // };
            const updateValue = {
              ...updatedApplicationParameter[updatedApplicationParameter?.length - 1],
              IsActive: false,
            };
            console.log(updateValue, "updateValueupdateValue");
            const formDatas: any = {
              Parameter_Id: updateValue?.Parameter_Id,
              Parameter_Group: updateValue?.Parameter_Group,
              Parameter_Name: updateValue?.Parameter_Name,
              Parameter_Description: updateValue?.Parameter_Description,
              Parameter_Options: "",
              Is_Muliple_Options: false,
              Parameter_Value: updateValue?.Parameter_Value?.toString(),
              Updated_By: user?.UserName,
              Updated_On: new Date().toISOString(),
              IsActive: false,
              ModuleName: "Application Parameter",
              MenuId: appParameter_moduleId,
              Form_Id: 18,
              UserId: userLoginResponse?.UserId,
              CreatedBy: user?.UserName,
              Activity: "Edit",
              Status: 1,
            };

            // Creating the display data object
            const JsonDataForDisplay: any = {
              "Parameter Group": updateValue?.Parameter_Group || "",
              "Parameter Name": updateValue?.Parameter_Name || "",
              "Parameter Description": updateValue?.Parameter_Description || "",
              "Parameter Value": updateValue?.Parameter_Value?.toString() || "",
              "Parameter Options": "",
              Status: "InActive",
            };

            // Assign the JSON data for display to formDatas
            formDatas.DataForDisplay = JSON.stringify(JsonDataForDisplay);
            dispatch(updateApplicationParameter(formDatas));
            dispatch(showMenu());
            closeWorflow();
            // const addEventLogPayload: any = {
            //   ModuleName: "Authentication",
            //   Action: ACTIVITY.LOGOUT,
            //   Description: "Logout",
            //   UserId: userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0,
            //   CreatedBy: user?.UserName || "",
            // };
            // setTimeout(() => {
            //   dispatch(addEventLog(addEventLogPayload));
            //   dispatch(logoutUser(false));
            //   dispatch({ type: "LOGOUT" });
            // }, 5000);
            if (isAuthenticated && menuData && menuData.length > 0) {
              dispatch(showMenu());
              if (menuData[0].Url) {
                navigate(menuData[0].Url);
                dispatch(showMenu());
              } else if (!menuData[0].Url && menuData[0].SubMenus && menuData[0].SubMenus.length > 0) {
                if (menuData[0].SubMenus[0].Url) {
                  navigate(menuData[0].SubMenus[0].Url);
                  dispatch(showMenu());
                } else if (
                  !menuData[0].SubMenus[0].Url &&
                  menuData[0].SubMenus[0].SubMenus &&
                  menuData[0].SubMenus[0].SubMenus.length > 0
                ) {
                  navigate(menuData[0].SubMenus[0].SubMenus[0].Url);
                  dispatch(showMenu());
                }
              } else {
                navigate("/pendingActions");
              }
              dispatch(updateLoader(false));
              dispatch(showMenu());
            }
          }
        }
      } else {
        closeWorflow();
      }
    } else {
      closeWorflow();
    }
  };

  useEffect(() => {
    const runEffect = async () => {
      if (isCompanyAdded && currentProcess === COBWorflowPages.CompanyMaster) {
        if (processList && processList.length > 0) {
          const getCorrectedData = processList.find((n) => n?.ProcessName === COBWorflowPages.CompanyMaster);

          if (getCorrectedData) {
            const updatedCorrectData = {
              Workflow_Id: getCorrectedData.WorkflowId,
              Workflow_InstanceId: getCorrectedData.InstanceId,
            };

            dispatch(updateLoader(true));

            const req = {
              Process_Id: updatedCorrectData.Workflow_Id,
              Updated_By: userLoginResponse?.UserName,
              ModuleName: "WorkFlow Execution",
              Activity: "Update",
            };
            dispatch(updateLoader(false));
            try {
              await dispatch(updateWorkFLowStep(req)).unwrap();
              const updatedprocessList: any[] = processList.map((item) => item?.updateWorkFlow);
              checkProcessCompleteandNextProcessGo(updatedprocessList);
              dispatch(updaterolesandresponsAddandEdit(false));
            } catch (error) {
              console.error("Failed to update workflow step:", error);
            } finally {
              dispatch(updateLoader(false));
              dispatch(
                updateToaster({
                  isToaster: true,
                  toasterMsg: RolesAndResonsibilitytoasterMsg,
                })
              );
            }
          }
        }
      }
    };

    runEffect();
  }, [isCompanyAdded, currentProcess, processList, userLoginResponse]);

  // useEffect(() => {
  //   setcurrentProcess(steps[currentStepIndex]);
  // }, [currentStepIndex]);

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(getIndustryList({ FormID: 2 }));
    dispatch(getParentIndustryList({ FormID: 2 }));
    dispatch(getThirdPartyList({ FormID: 0 }));
    dispatch(
      getLocationList({
        FormID: 2,
      })
    );
    dispatch(
      getRegionalList({
        FormID: 2,
      })
    );
    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
  }, []);
  const closeWorflow = () => {
    if (onClose) {
      onClose();
      dispatch(updateLoader(false));
    }
  };
  // const handlePrevious = () => {
  //   let currentStep = currentStepIndex;
  //   const updatedWorkflowData = [...workFlowViewData];
  //   updatedWorkflowData[currentStep] = {
  //     ...updatedWorkflowData[currentStep],
  //     Process_Name: steps[currentStep],
  //     Process_Status: "inProgress",
  //   };

  //   setWorkFlowViewData(updatedWorkflowData);
  //   setWorkFlowViewData(updatedWorkflowData);
  //   if (currentStepIndex > 0) {
  //     setCurrentStepIndex(currentStepIndex - 1);
  //   }
  //   if (currentStepIndex === 0) {
  //     setCurrentStepIndex(0);
  //   }
  // };
  const handlePrevious = () => {
    setactivestepCount((prevCount) => prevCount - 1);
  };
  const handleNext = () => {
    //setactivestepCount((prevCount) => prevCount + 1);
    dispatch(updateLoader(true));
    let req = {
      Process_Id: currentStep.Id,
      Updated_By: userLoginResponse?.UserName,
      ModuleName: "WorkFlow Execution",
      Activity: "Update",
    };
    dispatch(updateWorkFLowStep(req))
      .unwrap()
      .then(() => {
        dispatch(
          getWorkFlowByCode({
            //workflowCode: "WM003"
            workflowCode: "WM002",
          })
        );
      });
  };

  // const handleNext = () => {
  //   const updatedWorkflowData = [...workFlowViewData];
  //   updatedWorkflowData[currentStepIndex] = {
  //     ...updatedWorkflowData[currentStepIndex],
  //     Process_Name: steps[currentStepIndex],
  //     Process_Status: "Completed",
  //   };

  //   setWorkFlowViewData(updatedWorkflowData);
  //   setWorkFlowViewData(updatedWorkflowData);
  //   if (currentStepIndex < steps.length - 1) {
  //     setCurrentStepIndex(currentStepIndex + 1);
  //   } else {
  //     // handleSubmit(); // Final step to submit the workflow
  //   }
  // };
  const checkFlowCompleted = async (Instvalue: any) => {
    try {
      if (Instvalue) {
        const instanceValue = await workFlowService.getWorkflowInstanceById({
          workflowId: Instvalue?.Workflow_Id,
          InstanceId: Instvalue?.Workflow_InstanceId,
        });
        let resposseData = instanceValue?.data;
        setInstanceValue(resposseData);
        if (resposseData?.Id) {
          return resposseData;
        }
        return false;
      }
    } catch (error) {
      console.error("Error fetching workflow instance:", error);
      return false;
    }
  };
  const main_steps = Object.values(COBWorflowPages);

  const error_steps: number[] | undefined = [];

  const Complete_data = workFlowViewData.filter((item: any) => {
    return item.Process_Status === "Completed";
  });

  const Active_step_Count = Complete_data?.length - 1;
  const Formate_Complete_data = Complete_data.map((item: any) => {
    return item.Process_Name;
  });

  const Complete_steps = main_steps.reduce((acc, step, index) => {
    if (Formate_Complete_data.includes(step)) {
      acc.push(index);
    }
    return acc;
  }, [] as number[]);

  const [formDatas, setFormDatas] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  return (
    <>
      {/* <CustomDialog
        show={true}
        onHide={() => {}}
        header={"Customer Onboarding"}
        style={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflowY: "hidden",
              margin: "0px !important",
              minWidth: "100vw important",
              minHeight: "100vh !important",
            },
          },
        }}
        isCloseIconDisabled={true}
        contentNode={
          <>
            {false && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Grid>
                  <CustomButton
                    variant="contained"
                    name={"Previous"}
                    onClick={handlePrevious}
                    size="small"
                    disabled={currentStepIndex === -1}
                  />
                  <CustomButton
                    variant="contained"
                    name={currentStepIndex === steps.length - 1 ? "Submit" : "Next"}
                    onClick={handleNext}
                    size="small"
                  />
                </Grid>
                <Box>
                  {false && (
                    <Box
                      sx={{
                        position: "fixed",
                        width: "calc(100vw - 265px)",
                        zIndex: 1100,
                        background: "white",
                        top: 100,
                        left: 128,
                      }}
                    >
                      <COBStepper currentStepIndex={currentStepIndex} steps={steps} />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            <Stack spacing={3}>
              {true && (
                <Box>
                  <CustomStatusStepper
                    activeStep={currentStepIndex}
                    steps={main_steps}
                    errorSteps={error_steps}
                    CompleteSteps={Complete_steps}
                  />
                </Box>
              )}
              {false && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    gap: 1,
                  }}
                >
                  <CustomButton
                    variant="contained"
                    name={"Previous"}
                    onClick={handlePrevious}
                    size="small"
                    disabled={currentStepIndex === 0}
                  />
                  <CustomButton
                    variant="contained"
                    name={currentStepIndex === steps.length - 1 ? "Submit" : "Next"}
                    onClick={handleNext}
                    size="small"
                  />
                </Box>
              )}
            </Stack>
            <Box sx={{ position: "relative" }}>
              {workFlowData &&
                currentProcess == COBWorflowPages.ApplicationParameter &&
                COBWorflowPages.ApplicationParameter === steps[currentStepIndex] && (
                  <ApplicationParrameters isWorkFlow={true} />
                )}
              {workFlowData &&
                currentProcess == COBWorflowPages.RegionalSettings &&
                COBWorflowPages.RegionalSettings === steps[currentStepIndex] && <RegionalSettings isWorkFlow={true} />}
              {workFlowData &&
                currentProcess == COBWorflowPages.MakerChecker &&
                COBWorflowPages.MakerChecker === steps[currentStepIndex] && <MakerChecker isWorkFlow={true} />}
              {workFlowData &&
                currentProcess == COBWorflowPages.CompanyMaster &&
                COBWorflowPages.CompanyMaster === steps[currentStepIndex] && (
                  <AddCompanyMasterSteper
                    view={false}
                    isDraftDisable={true}
                    isWorkFlow={true}
                    closePopUp={() => {}}
                    setFormDatas={setFormDatas}
                    formDatas={formDatas}
                    setIsEditData={setIsEditData}
                  />
                )}
            </Box>
          </>
        }
      /> */}
      <>
        {false && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Grid>
              <CustomButton
                variant="contained"
                name={"Previous"}
                onClick={handlePrevious}
                size="small"
                disabled={currentStepIndex === -1}
              />
              <CustomButton
                variant="contained"
                name={currentStepIndex === steps.length - 1 ? "Submit" : "Next"}
                onClick={handleNext}
                size="small"
              />
            </Grid>
            <Box>
              {false && (
                <Box
                  sx={{
                    position: "fixed",
                    width: "calc(100vw - 265px)",
                    zIndex: 1100,
                    background: "white",
                    top: 100,
                    left: 128,
                  }}
                >
                  <COBStepper currentStepIndex={currentStepIndex} steps={steps} />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Stack spacing={3}>
          {false && (
            <Box>
              <CustomStatusStepper
                activeStep={currentStepIndex}
                steps={main_steps}
                errorSteps={error_steps}
                CompleteSteps={Complete_steps}
              />
            </Box>
          )}
          {false && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 1,
              }}
            >
              <CustomButton
                variant="contained"
                name={"Previous"}
                onClick={handlePrevious}
                size="small"
                disabled={currentStepIndex === 0}
              />
              <CustomButton
                variant="contained"
                name={currentStepIndex === steps.length - 1 ? "Submit" : "Next"}
                onClick={handleNext}
                size="small"
              />
            </Box>
          )}
        </Stack>
        <Box sx={{ position: "relative" }}>
          {workFlowData && currentProcess == COBWorflowPages.ApplicationParameter && (
            // COBWorflowPages.ApplicationParameter === steps[currentStepIndex] &&
            <ApplicationParrameters
              isWorkFlow={true}
              //handlePrevious={handlePrevious} handleNext={handleNext}
            />
          )}
          {workFlowData && currentProcess == COBWorflowPages.RegionalSettings && (
            // &&
            // COBWorflowPages.RegionalSettings === steps[currentStepIndex]
            <RegionalSettings isWorkFlow={true} />
          )}
          {workFlowData && currentProcess == COBWorflowPages.MakerChecker && (
            //  &&
            // COBWorflowPages.MakerChecker === steps[currentStepIndex]
            <MakerChecker isWorkFlow={true} />
          )}
          {workFlowData && currentProcess == COBWorflowPages.CompanyMaster && (
            // &&
            // COBWorflowPages.CompanyMaster === steps[currentStepIndex]
            <>
              {/* {navigate("/companymaster")} */}
              {/* <AddCompanyMasterSteper
                view={false}
                isDraftDisable={true}
                isWorkFlow={true}
                closePopUp={() => {}}
                setFormDatas={setFormDatas}
                formDatas={formDatas}
                setIsEditData={setIsEditData}
              /> */}
              <MasterStepper
                view={false}
                isDraftDisable={true}
                isWorkFlow={true}
                closePopUp={() => {}}
                setFormDatas={setFormDatas}
                formDatas={formDatas}
                setIsEditData={setIsEditData}
              />
            </>
          )}
        </Box>
      </>

      {currentProcess === "Not Found Process" ? (
        <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Box
            sx={{
              border: "1px solid gray",
              borderRadius: "10px",
              width: "500px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "18px !important" }}>
              Current Process Complete
              {/* {   <br></br> or <br></br> Something went wrong try again later} */}
            </Typography>

            {false && (
              <Typography
                onClick={() => {
                  const addEventLogPayload: any = {
                    ModuleName: "Authentication",
                    Action: ACTIVITY.LOGOUT,
                    Description: "Logout",
                    UserId: userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0,
                    CreatedBy: user?.UserName || "",
                  };
                  dispatch(addEventLog(addEventLogPayload));
                  dispatch(logoutUser(false));
                  dispatch({ type: "LOGOUT" });
                }}
                sx={{ fontSize: "14px", mt: 2, textDecoration: "underLine", cursor: "pointer" }}
              >
                Logout and Login Again
              </Typography>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
};
