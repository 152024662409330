import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IIndustry, IVHColumnRes } from "../../models/industry.type";
import industryMasterService from "../services/industryMasterServices";
import { chkDate } from "../../utils/constants";
export interface IIndustryData {
    industryTable: any;
    ParentindustryTable: any;
    isLoading: boolean;
    isindustryAdded: boolean;
    isindustryAddedFailed: boolean;
      versionHistory:{industryRecords :IIndustry[] , industryVHeader : IVHColumnRes[]};
    isindustryHistory: boolean;
    isindustryDeleted: boolean;
    isindustryDeletedFailed: boolean;
    ToasterMsg: any;

}

export const initialState: IIndustryData = {
    industryTable: [],
    ParentindustryTable: [],
    isLoading: false,
    isindustryAdded: false,
    isindustryAddedFailed: false,
      versionHistory:{industryRecords :[] , industryVHeader : []},
    isindustryHistory: false,
    isindustryDeleted: false,
    isindustryDeletedFailed: false,
    ToasterMsg: {Message: "", Status: ""},
};

export const getIndustryList = createAsyncThunk(
    "getIndustryList",
    async (requesrParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await industryMasterService.getIndustryList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getParentIndustryList = createAsyncThunk(
    "getParentIndustryList",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await industryMasterService.getParentIndustryList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const addEditIndustry = createAsyncThunk("addEditIndustry", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await industryMasterService.addEditIndustry(requesrParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteIndustry = createAsyncThunk(
    "deleteIndustry",
    async (requesrParams: any, { rejectWithValue }) => {
        console.log(requesrParams,"requesrParams");
        
        try {
            let reqbody = { ...requesrParams };
            console.log(reqbody,"requesrParams");
            const response: any = await industryMasterService.deleteIndustry(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getIndustryHistory = createAsyncThunk("getIndustryHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await industryMasterService.getVersionHistoryIndustry(reqbody);
    const {IndustryMaster , HeaderJson } =response.data;
    return {industryRecords:IndustryMaster, industryVHeader : HeaderJson.ColumnHeaders}

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const indutryMasterDataSlice = createSlice({
    name: "indutryMasterData",
    initialState,
    reducers: {
        updateIndustryAddandEdit: (state, action) => {
            state.isindustryAdded = false;
            state.isindustryAddedFailed = false;
            state.isindustryDeleted = false;
            state.isindustryDeletedFailed = false;
            state.ToasterMsg = {Message: "", Status: ""};
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.industryTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.industryTable];
                copyVendorData[indexVendor] = action.payload;
                state.industryTable = copyVendorData;
            }
        },
        updateVersionHistory:  (state, action)=>{            
          state.versionHistory.industryRecords = [];
          state.versionHistory.industryVHeader = [];
        }, 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getIndustryList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getIndustryList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.industryTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getIndustryList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(getParentIndustryList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getParentIndustryList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.ParentindustryTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getParentIndustryList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(addEditIndustry.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEditIndustry.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action.payload , 'action.payload1');
                //     let item = {...action.payload};
                //     let copyallUsers= [...state.dataTable];
                //     copyallUsers.push(item);
                //     state.dataTable = copyallUsers;
                state.isindustryAdded = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(addEditIndustry.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isindustryAddedFailed = true;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })

            .addCase(deleteIndustry.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteIndustry.fulfilled, (state, action: PayloadAction<any>) => {
                state.isindustryDeleted = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(deleteIndustry.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.isindustryDeletedFailed = true;
                // state.ToasterMsg = action.payload.response.data;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
          .addCase(getIndustryHistory.pending, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })
          .addCase(getIndustryHistory.fulfilled, (state, action: PayloadAction<any>) => {
            state.versionHistory.industryRecords = action.payload.industryRecords.map((user: any) => {
                user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                user.CreatedOn = chkDate(user.CreatedOn);

                return user;
            });
            state.versionHistory.industryVHeader = action.payload.industryVHeader
            state.isLoading = false;
            // state.ToasterMsg = "Department data deleted successfully";
          })
          .addCase(getIndustryHistory.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })

    },
});

export const { updateIndustryAddandEdit, updateUser, updateVersionHistory } = indutryMasterDataSlice.actions;
export default indutryMasterDataSlice.reducer;
