import {
  MenuItem,
  TextField,
  TextFieldVariants,
  Typography,
} from "@mui/material";
import React, { memo, useState, useEffect } from "react";
import CustomSvg from "../CustomSvg";
import { useTheme } from "@mui/material/styles";
import styles from "./common.module.scss";



interface InputProps {
  name: string;
  label: string;
  optionLabelKey: string;
  valueKey: string;
  type?: string;
  value: any;
  options: any[];
  variant?: TextFieldVariants;
  required?: boolean;
  readOnly?: boolean;
  error?: string | boolean;
  onChange?: (value: any, name: string) => void;
  onAddClick?: () => void;
  className?: string;
  disabled?: boolean;
  helperText?: string;
  inputProps?: object;
  Width?: any;
  cascadingKey?: string;
  cascadingvalue?: string;
  size?: 'small' | 'medium';
  addField?: boolean;
  labelAddNew?: string;
showAll?:any;
showAllOption?:boolean;
  optionText?:string | null;

}


const CustomSelect = memo((props: InputProps) => {
  const {
    name,
    label,
    type = "text",
    value,
    valueKey,
    optionLabelKey,
    variant = "outlined",
    required = false,
    readOnly = false,
    error,
    onChange,
    className,
    disabled = false,
    helperText,
    inputProps,
    Width = "100%",
    options = [],
    cascadingKey,
    cascadingvalue,
    size= "small",
    addField = false ,
    showAll ={},
    showAllOption=false,
    onAddClick ,
    labelAddNew,
    optionText=null
  } = props;
// const updatedShowAll = showAll.map((option)=>({
//   ...option,
//   isActive:true

// }) ) 

const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

  // useEffect(() =>{

  //   if(options){
  //     setFilteredOptions(options);
  //   }

  // }, [options]);

 

  useEffect(() => {   
    
    try{
    const filteredOptions_value = cascadingKey
      ? options.filter(
          (item: { [x: string]: any }) =>
            `${item[cascadingKey]}` === cascadingvalue
        )
      : options;
       // Sort the filtered options by `optionLabelKey` in A-Z order
       const sortedOptions = [...filteredOptions_value].sort((a: any, b: any) => {
        const labelA = a[optionLabelKey]?.toUpperCase(); // ignore upper and lowercase
        const labelB = b[optionLabelKey]?.toUpperCase(); // ignore upper and lowercase
       // return labelA < labelB ? 1 : 0;
       if(labelA > labelB){
        return 1;
       }
      if(labelA <labelB){
        return -1;
      }
        return 0 ;
      
      });
    
    setFilteredOptions(sortedOptions);
    }catch{
      setFilteredOptions([]);
    }
  }, [cascadingKey, cascadingvalue, options, value]);
  return (
    <>
      <TextField
        id="outlined-select-currency-native"
        select
        label={label}
        fullWidth
        variant={variant}
        required={required}
        error={!!error}
        helperText={helperText ? helperText : error}
        onChange={(e: any) => {
          if (onChange && !readOnly) {
            onChange(e.target?.value, name);
          }
        }}
        // sx={{ minWidth:'340px'}}
        value={value}
        className={className}
        disabled={disabled}
        InputProps={{
          ...inputProps,
          readOnly: readOnly || false,
        }}
        InputLabelProps={{
          style: {
            color: !!error ? "#1F1F1F" : undefined,
          },
        }}
        SelectProps={{
          style: { textAlign: "left" }, // Apply custom styles to the Select component
        }}
        size={size}
      >
        
        {addField && (
          <MenuItem
            onClick={() => {
              if (onAddClick) {
                onAddClick();
              }
            }}
          >
            <Typography variant="caption" className={styles.addNewClause}>
              {" "}
              + Add New {`${labelAddNew ? labelAddNew : label}`}
            </Typography>
          </MenuItem>
        )}
      
      {showAllOption &&
        <MenuItem key={showAll[valueKey]} value={showAll[valueKey]}>
          {`${showAll[optionLabelKey]}`}
        </MenuItem>
     }
 
        {filteredOptions.map((option: any) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {`${option[optionLabelKey]}`}
          </MenuItem>
        ))}


       
        {optionText && optionText !== null && (
          <MenuItem>
            <Typography variant="caption"> {optionText}</Typography>
          </MenuItem>
        )}
      </TextField>
    </>
  );
});
export default CustomSelect;
