import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, InputAdornment, IconButton } from '@mui/material';

import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { RootState, useAppDispatchThunk } from '../../../store/store';
import { textFieldWidth } from '../../../utils/constants';
import CustomTextField from '../../../common/dynamicInputs/CustomTextField';
import CustomSelect from '../../../common/dynamicInputs/CustomSelect';
import CustomToogleSwitch from '../../../common/dynamicInputs/CustomToogleSwitch';
import CustomButton from '../../../common/dynamicButton/CustomButton';
import { getLovList, updateLovAddandEdit } from '../../../store/slices/lovMasterData';
import { addThirdParty, updateThirdParty } from '../../../store/slices/thirdPartyData';
import ProgressBar from '../../../common/progressBar/progressBar';
import CustomMultilineTextField from '../../../common/dynamicInputs/CustomMultilineTextField';
import CustomNumberField from '../../../common/dynamicInputs/CustomNumberField';
import CustomAutoComplete from '../../../common/dynamicInputs/CustomAutoComplete';
import { getLocationList, getParentLocationList, updateLocationAddandEdit } from '../../../store/slices/locationMasterData';
import CustomDialog from '../../../common/dynamicDialog/CustomDialog';
import { AddLocation } from '../location/AddLocation';
import { updateLoader, updateToaster } from '../../../store/slices/loader';
import { AddListValues } from '../listofValues/AddListValues';
import { LocationConfig, LovConfig } from '../../../config/config';
import ModalPopUp from '../../../common/modalComponent/ModalPopUp';


interface FormData {
    ThirdPartyId?: number;
    ThirdPartyName: string;
    ThirdPartyType: string;
    ShortName: string;
    ThirdParty_Address: string;
    PinCode: string;
    City: string;
    ContactPerson: string;
    ContactNumber: string;
    EmailId: string;
    IsActive?: string;
    Remarks?: string;

}

export interface branchMasterProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
    ModuleId?: any;
}

export const AddThirdParty: React.FC<branchMasterProps> = ({
    view, closePopUp, edit, isEditData, ModuleId
}) => {
    const dispatch = useAppDispatchThunk();
    const { thirdPartyData, isThirdPartyUpdated, isThirdPartyAdded, isThirdPartyDeleted, ToasterMsg, isLoading } = useSelector((state: RootState) => state.thirdPartyData);
    const { lovTable, islovAdded } = useSelector((state: RootState) => state.lovMasterData);
    const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
    const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
    const [message, setMessage] = useState<boolean>(false);
    const [Citydisabled, setCitydisabled] = useState<boolean>(false);
    const [AddLocationForm, setAddLocationForm] = useState<boolean>(false);
    const [thirdpartyType_Data, setThirdpartyType_Data] = useState<any[]>([]);
    const [CityData, setCityData] = useState<any[]>([]);
    const [AddlovPopup, setAddlovPopup] = useState<boolean>(false);
    const [addPopupHeader, setAddPopupHeader] = useState<string>("");
    const [lovName, setLovName] = useState<string>("");
    const [IsCancel, setIsCancel] = useState<boolean>(false);

    const {
        ParentLocationTable,
        islocationAdded,
    } = useSelector((state: RootState) => state.locationMasterData);

    useEffect(() => {

        dispatch(getLovList({ Name: "" }));


    }, []);

    console.log(ParentLocationTable, "ParentLocationTable");


    useEffect(() => {

        setThirdpartyType_Data(lovTable.filter((item: any) => item.Name === LovConfig?.ThirdPartyType))

        let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type)
        if (dublocationType && dublocationType.length > 0) {
            let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_City)
            if (countryTypeId && countryTypeId.LovId) {
                dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
            }
        }

    }, [lovTable])

    useEffect(() => {

        setCityData(ParentLocationTable)
    }, [ParentLocationTable])

    let formData: any = {

        ThirdPartyId: isEditData ? isEditData?.ThirdPartyId : 0,
        ThirdPartyName: isEditData ? isEditData?.ThirdPartyName : "",
        ThirdPartyType: isEditData ? isEditData?.ThirdPartyType : "",
        ShortName: isEditData ? isEditData?.ShortName : "",
        ThirdParty_Address: isEditData ? isEditData?.ThirdParty_Address : "",
        EmailId: isEditData ? isEditData?.EmailId : "",
        City: isEditData ? isEditData?.City : "",
        ContactNumber: isEditData ? isEditData?.ContactNumber : "",
        ContactPerson: isEditData ? isEditData?.ContactPerson : "",
        PinCode: isEditData ? isEditData?.PinCode : "",
        IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
        Remarks: isEditData ? (isEditData?.Remarks ? isEditData?.Remarks : " ") : "",

    };

    console.log(isEditData, "isEditData");


    const {
        handleSubmit: handleThirdPartyForm,
        reset,
        setValue,
        formState: { errors: errorsloginform, isDirty },
        control,
        watch,
        getValues,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });

    const isActiveDisabled = watch("IsActive");
    const CityWatch = watch("City");
    const PincodeWatch = watch("PinCode");
    console.log(CityWatch, "CityWatch");

    // const pinWatchAsString = `${pinWatch}`;
    // console.log(pinWatchAsString, "pinWatchAsString");

    const setTheCityNameBasedonPin = (pinValue: any) => {
        let pinWatchAsStringValue = `${pinValue}`
        console.log(pinValue, "pinWatchAsStringValue");
        if (pinValue) {

            const filterdata = CityData.filter((item) => {
                return (pinWatchAsStringValue >= item.Start_Pin && pinWatchAsStringValue <= item.End_Pin);
            });
            console.log(filterdata, pinWatchAsStringValue, CityData, "pinWatchAsStringValue");


            if (filterdata.length >= 1) {
                let cityvalue = filterdata[0].Location_Name;
                console.log(cityvalue, "cityvalue");
                setValue("City", cityvalue);
                setCitydisabled(true);
            } else {
                setValue("City", "");
                setCitydisabled(false);
            }

            console.log(CityData, filterdata, pinWatchAsStringValue, "filterdata");

        } else {
            setValue("City", "");
            setCitydisabled(false);
        }
    }

    const handleSubmit = (data: any) => {
        let formData = data;
        dispatch(updateLoader(true));
        formData.ActionTaken = '';

        let JsonDataForDisplay: any = {
            "Associated Party Name": formData.ThirdPartyName,
            "Associated Party Type": thirdpartyType_Data.find((item) => item.LovId == formData?.ThirdPartyType)?.Options || '',
            "Short Name": formData.ShortName,
            "Email Id": formData.EmailId,
            "City": formData.City,
            "Contact Number": formData.ContactNumber,
            "Contact Person": formData.ContactPerson,
            "PinCode": formData.PinCode,
            "Address": formData.ThirdParty_Address,
            "Status": formData.IsActive ? "Active" : "InActive",
            "Modified By": user?.UserName,
        }

        if (edit) {
            formData.UpdatedBy = user?.UserName;
            formData.MenuId = currentPagePermissionData?.MenuId;
            formData.ModuleName = "Third Party Master";
            formData.Activity = "Edit";
            formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

            formData.Status = formData.Status ? 0 : 1;
            formData.UserId = userLoginResponse?.UserId;
            formData.Form_Id = 10;
            dispatch(updateThirdParty(formData));
        } else {
            formData.UpdatedBy = user?.UserName;
            formData.CreatedBy = user?.UserName;
            delete formData.DepartmentId;
            formData.Status = 1;
            formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

            formData.MenuId = currentPagePermissionData?.MenuId;
            formData.ModuleName = "Third Party Master";
            formData.Activity = "create";
            formData.UserId = userLoginResponse?.UserId;
            formData.Form_Id = 10;
            dispatch(addThirdParty(formData));
        }

    };

    useEffect(() => {
        if (isThirdPartyAdded && closePopUp) {
            reset(formData);
            closePopUp()
            dispatch(updateLoader(false));
        } else if (isThirdPartyUpdated && closePopUp) {
            reset(formData);
            closePopUp()
            dispatch(updateLoader(false));
        }
    }, [isThirdPartyUpdated, isThirdPartyAdded]);

    useEffect(() => {
        if (islocationAdded) {
            dispatch(updateLocationAddandEdit(false));
            let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type)
            if (dublocationType && dublocationType.length > 0) {
                let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_City)
                if (countryTypeId && countryTypeId.LovId) {
                    dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
                }
            }

        }
    }, [islocationAdded]);

    // const onAddType = (header: string, fieldName: any, LovName: any) => {
    //     setAddPopupHeader(header);
    //     setLovName(LovName);
    //     setAddlovPopup(true);
    // };

    useEffect(() => {
        if (islovAdded) {
            dispatch(getLovList({ Name: "Third Party Type" }));

            dispatch(updateLovAddandEdit(false));

        }
    }, [islovAdded]);

    const handleCancelPopup = () => {

        if (isDirty) {
          setIsCancel(true);
        } else {
          closePopUp && closePopUp();
        }
      };

    return (
        <>
            <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>

                {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
                <Grid
                    // item
                    // item xs={12} sm={12} md={6} lg={6} xl={6}
                    sx={{
                        backgroundImage: `url()`,
                        backgroundRepeat: "no-repeat",
                        // backgroundColor: (t) =>
                        //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                        // backgroundSize: "cover",
                        backgroundPosition: "center",
                        // height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        margin: "0",
                    }}
                >
                    {false && (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                margin: "0",
                            }}
                        >
                            {!view ? (
                                <>
                                    {!edit ? (
                                        <Typography variant="h6" sx={{ m: 1 }}>
                                            Add Third Party
                                        </Typography>
                                    ) : (
                                        <Typography variant="h6" sx={{ m: 1 }}>
                                            Edit Third Party
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <Typography variant="h6" sx={{ m: 1 }}>
                                    View Third Party
                                </Typography>
                            )}
                        </Box>
                    )}
                    <Grid
                        sx={{
                            height: "auto", width: "100%", overflow: "hidden", position: "relative"
                        }}
                    >
                        <Box
                            sx={{
                                px: {
                                    xs: 2,
                                    sm: 2,
                                    md: 2,
                                    lg: 2,
                                    xl: 2,
                                },

                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
                            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="ThirdPartyName"
                                            control={control}
                                            defaultValue={""}
                                            rules={{
                                                required: "Name is Required",
                                                validate: {
                                                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                                                    minLength: (value) => value.length >= 1 || "Minimal 1 characters requires",
                                                    maxLength: (value) => value.length <= 100 || "Maximum 100 characters requires",
                                                    ValueLimitaion: (value) => /^[a-zA-Z\s\-. ]+$/.test(value ?? "") || "Name can only contain letters, spaces, hyphens, and dots.",
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name={"Name"}
                                                    required={true}
                                                    disabled={view || !isActiveDisabled}
                                                    label={" Name"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.ThirdPartyName)}
                                                    helperText={
                                                        errorsloginform.ThirdPartyName &&
                                                        errorsloginform.ThirdPartyName.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="ShortName"
                                            control={control}
                                            defaultValue={""}
                                            rules={{
                                                required: "Short Name is Required",
                                                validate: {
                                                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                                                    minLength: (value) => value.length >= 1 || "Minimal 1 characters requires",
                                                    maxLength: (value) => value.length <= 100 || "Maximum 100 characters requires",
                                                    ValueLimitaion: (value) => /^[a-zA-Z\s\-. ]+$/.test(value ?? "") || "Short Name can only contain letters, spaces, hyphens, and dots.",
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name={"ShortName"}
                                                    required={true}
                                                    disabled={view || !isActiveDisabled}
                                                    label={"Short Name"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.ShortName)}
                                                    helperText={
                                                        errorsloginform.ShortName &&
                                                        errorsloginform.ShortName.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="ThirdPartyType"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Third Party Type is required" }}
                                            render={({ field }) => (
                                                <CustomSelect
                                                    name={"ThirdpartyType"}
                                                    valueKey={"LovId"}
                                                    optionLabelKey="Options"
                                                    options={thirdpartyType_Data}
                                                    // addField={true}
                                                    // onAddClick={() => {
                                                    //     onAddType("Add Third Party Type", "ThirdpartyType", "Third Party Type");
                                                    // }}
                                                    disabled={view || !isActiveDisabled}
                                                    required={true}
                                                    label={"Associated Party Type"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.ThirdPartyType)}
                                                    helperText={
                                                        errorsloginform.ThirdPartyType &&
                                                        errorsloginform.ThirdPartyType.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="ThirdParty_Address"
                                            control={control}
                                            defaultValue={""}
                                            rules={{
                                                // required: "About Company is Required",
                                                validate: (value) => {
                                                    if (value === "") {
                                                        return true;
                                                    }
                                                    if (/^\s/.test(value)) {
                                                        return "Leading spaces are not allowed";
                                                    }
                                                    if (value.length > 100) {
                                                        return "Maximum 100 characters required";
                                                    }
                                                    if (!/^[\s\S]*$/.test(value)) {
                                                        return "Address cannot contain special characters except spaces and ,.-";
                                                    }
                                                    return true;
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomMultilineTextField
                                                    label={"Address"}
                                                    value={field.value}
                                                    disabled={view || !isActiveDisabled}
                                                    rows={5}
                                                    required={false}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.ThirdParty_Address)}
                                                    helperText={errorsloginform.ThirdParty_Address && errorsloginform.ThirdParty_Address.message?.toString()}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="PinCode"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                validate: (value) => {
                                                    if (value === "") {
                                                        return true;
                                                    }
                                                    if (value.length < 6) {
                                                        return "Minimum 6 characters required";
                                                    }
                                                    if (value.length > 6) {
                                                        return "Maximum 6 characters required";
                                                    }
                                                    if (!/^\d+$/.test(value)) {  // Correct regex to allow only digits
                                                        return "PinCode should contain only numbers";
                                                    }
                                                    return true;
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomNumberField
                                                    name={"PinCode"}
                                                    required={false}
                                                    disabled={view || !isActiveDisabled}
                                                    label={"Pincode"}
                                                    value={field.value}
                                                    onChange={(value) => { field.onChange(value); setTheCityNameBasedonPin(value) }}
                                                    error={Boolean(errorsloginform.PinCode)}
                                                    helperText={errorsloginform.PinCode && errorsloginform.PinCode.message?.toString()}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >

                                        <Controller
                                            name="City"
                                            control={control}
                                            defaultValue={""}
                                            rules={{
                                                validate: (value) => {
                                                    if (PincodeWatch !== "" && value === "") {
                                                        return "City is required when Pincode is provided";
                                                    }
                                                    return true;
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name={"City"}
                                                    required={false}
                                                    // disabled={view || Citydisabled ? true : false}
                                                    disabled={true}
                                                    label={"City"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    inputProps={!Citydisabled && CityWatch === "" ? {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() => { setAddLocationForm(true) }}
                                                                    //   onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                    disabled={view || !isActiveDisabled}
                                                                >
                                                                    +
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    } : {}}
                                                    error={Boolean(errorsloginform.City)}
                                                    helperText={
                                                        errorsloginform.City &&
                                                        errorsloginform.City.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="ContactPerson"
                                            control={control}
                                            defaultValue={""}
                                            rules={{
                                                // required: "About Company is Required",
                                                validate: (value) => {
                                                    if (value === "") {
                                                        return true;
                                                    }
                                                    if (/^\s/.test(value)) {
                                                        return "Leading spaces are not allowed";
                                                    }
                                                    if (value.length > 100) {
                                                        return "Maximum 100 characters required";
                                                    }
                                                    if (!/^[a-zA-Z\s\-. ]+$/.test(value)) {
                                                        return "Contact person can only contain letters, spaces, hyphens, and dots.";
                                                    }
                                                    return true;
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name={"Contact Person"}
                                                    required={false}
                                                    disabled={view || !isActiveDisabled}
                                                    label={"Contact Person"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.ContactPerson)}
                                                    helperText={
                                                        errorsloginform.ContactPerson &&
                                                        errorsloginform.ContactPerson.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="ContactNumber"
                                            control={control}
                                            defaultValue={""}
                                            rules={{
                                                validate: (value) => {
                                                    if (value === "") {
                                                        return true;
                                                    }
                                                    if (value.length < 6) {
                                                        return "Minimum 6 characters required";
                                                    }
                                                    if (value.length > 15) {
                                                        return "Maximum 15 characters required";
                                                    }
                                                    if (!/^\d+$/.test(value)) {  // Correct regex to allow only digits
                                                        return "Contact Number should contain only numbers";
                                                    }
                                                    return true;
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomNumberField
                                                    name={"ContactNumber"}
                                                    required={false}
                                                    disabled={view || !isActiveDisabled}
                                                    label={"Contact Number"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.ContactNumber)}
                                                    helperText={
                                                        errorsloginform.ContactNumber &&
                                                        errorsloginform.ContactNumber.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="EmailId"
                                            control={control}
                                            defaultValue={""}
                                            rules={{
                                                // required: "Email Id is required.",
                                                minLength: {
                                                    value: 5,
                                                    message: "Email address must be at least 5 characters.",
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: "Email address must be at most 100 characters.",
                                                },
                                                pattern: {
                                                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                                                    message: "Invalid email address",
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomTextField
                                                    name={"EmailId"}
                                                    required={false}
                                                    disabled={view || !isActiveDisabled}
                                                    label={"Email Id"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.EmailId)}
                                                    helperText={
                                                        errorsloginform.EmailId &&
                                                        errorsloginform.EmailId.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>


                                    {/* {edit || view ? (<Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Box sx={{ mt: 1 }}>
                                            <Controller
                                                name="IsActive"
                                                control={control}
                                                render={({ field }) => (
                                                    <CustomToogleSwitch
                                                        label={"User Status"}
                                                        dynamicLabel={field.value ? 'ACTIVE' : 'INACTIVE'}

                                                        disabled={view}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                )}
                                            />
                                        </Box>

                                    </Grid>) : null} */}

                                </Grid>

                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "20px",
                                        justifyContent: "right",
                                        mt: 2,
                                    }}
                                >
                                    {view ? (
                                        <CustomButton
                                            type="reset"
                                            name={"Cancel"}
                                            onClick={() => {
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                    ) : edit ? (
                                        <React.Fragment>
                                            <CustomButton
                                                type="reset"
                                                variant="outlined"
                                                name={"Cancel"}
                                                onClick={() => {
                                                    reset(formData);
                                                    handleCancelPopup();
                                                }}
                                            />
                                            <CustomButton type="reset" disabled={!isDirty} name={"Update"} onClick={handleThirdPartyForm(handleSubmit)} />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <CustomButton
                                                type="reset"
                                                variant="outlined"
                                                name={"Cancel"}
                                                onClick={() => {
                                                    reset(formData);
                                                    handleCancelPopup();
                                                }}
                                            />
                                            <CustomButton type="reset" disabled={!isDirty} name={"Submit"} onClick={handleThirdPartyForm(handleSubmit)} />
                                        </React.Fragment>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
            </Grid>
            <CustomDialog
                show={AddLocationForm}
                onHide={() => setAddLocationForm(false)}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={"Add Location"}
                contentNode={
                    // <></>
                    <AddLocation
                        view={false}
                        closePopUp={() => {
                            setAddLocationForm(false);
                        }}
                    />
                }
            />

            {/* <CustomDialog
                show={AddlovPopup}
                onHide={() => {
                    setAddlovPopup(false);
                }}
                maxWidth={"md"}
                minHeight={"50vh"}
                header={addPopupHeader}
                contentNode={
                    <>
                        <AddListValues
                            closePopUp={() => {
                                setAddlovPopup(false);

                            }}
                            lovID={lovName}
                        />
                    </>
                }
            /> */}

            <ModalPopUp
                onDeleteMethod={() => closePopUp && closePopUp()}
                isDelete={true}
                onHide={() => setIsCancel(false)}
                show={IsCancel}
                header={"Confirm"}
                text={`Are you sure to cancel`}
                ActionName={"Yes"}
            />
        </>
    );
};
