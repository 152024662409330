import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RegionalSettingsService from "../services/regionalSettingsService";
import { IRegional, IVHColumnRes } from "../../models/regional.type";
import { chkDate } from "../../utils/constants";

export interface IRegionalData {
    RegionalTable: any;
    isLoading: boolean;
    isRegionalAdded: boolean;
      versionHistory:{RegionRecords :IRegional[] , RegionVHeader : IVHColumnRes[]};
    isRegionalHistory: boolean;
    isRegionalDeleted: boolean;
    ToasterMsg: string;
}

export const initialState: IRegionalData = {
    RegionalTable: [],
    isLoading: false,
    isRegionalAdded: false,
      versionHistory:{RegionRecords :[] , RegionVHeader : []},
    isRegionalHistory: false,
    isRegionalDeleted: false,
    ToasterMsg: "",
};


export const getRegionalList = createAsyncThunk(
    "getRegionalList",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await RegionalSettingsService.getRegionalList(requesrParams);
            const { data } = response;
            console.log(data)
            return data;

        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const addEditRegional = createAsyncThunk("addEditRegional", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await RegionalSettingsService.addEditRegional(requesrParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteRegional = createAsyncThunk(
    "deleteRegional",
    async (requesrParams: any, { rejectWithValue }) => {
        console.log(requesrParams,"requesrParams");
        
        try {
            let reqbody = { ...requesrParams };
            console.log(reqbody,"requesrParams");
            const response: any = await RegionalSettingsService.deleteRegional(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getRegionalHistory = createAsyncThunk("getRegionalHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await RegionalSettingsService.getVersionHistoryRegional(reqbody);
    const {RegionalSettingsMaster , HeaderJson } =response.data;
    return {RegionalSettingsMaster:RegionalSettingsMaster, HeaderJson : HeaderJson.ColumnHeaders}

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const RegionalSettingsDataSlice = createSlice({
    name: "RegionalSettingsData",
    initialState,
    reducers: {
        updateRegionalAddandEdit: (state, action) => {
            state.isRegionalAdded = false;
            state.isRegionalDeleted = false;
            state.ToasterMsg = "";
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.RegionalTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.RegionalTable];
                copyVendorData[indexVendor] = action.payload;
                state.RegionalTable = copyVendorData;
            }
        },
        updateVersionHistory:  (state, action)=>{            
          state.versionHistory.RegionRecords = [];
          state.versionHistory.RegionVHeader = [];
        }, 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRegionalList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRegionalList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.RegionalTable = action.payload.map((user: any) => {
                    user.IsMandatory = user.IsMandatory === true ? "Yes" : "No";
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getRegionalList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(addEditRegional.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEditRegional.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action.payload , 'action.payload1');
                //     let item = {...action.payload};
                //     let copyallUsers= [...state.dataTable];
                //     copyallUsers.push(item);
                //     state.dataTable = copyallUsers;
                state.isRegionalAdded = true;
                state.isLoading = false;
                state.ToasterMsg = action.payload.Message;
                // state.ToasterMsg = "Regional Settings Details Added SuccessFully";
            })
            .addCase(addEditRegional.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(deleteRegional.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteRegional.fulfilled, (state, action: PayloadAction<any>) => {
                state.isRegionalDeleted = true;
                state.isLoading = false;
                state.ToasterMsg = action.payload.Message;
                // state.ToasterMsg = "Regional Settings Details Deleted SuccessFully";

            })
            .addCase(deleteRegional.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
          .addCase(getRegionalHistory.pending, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })
          .addCase(getRegionalHistory.fulfilled, (state, action: PayloadAction<any>) => {
            state.versionHistory.RegionVHeader = action.payload.HeaderJson
            state.versionHistory.RegionRecords = action.payload.RegionalSettingsMaster.map((user: any) => {
                user.IsMandatory = user.IsMandatory === true ? "Yes" : "No";
                user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                user.Approved_On = chkDate(user.Approved_On);

                return user;
            });
            state.isLoading = false;
            // state.ToasterMsg = "Department data deleted successfully";
          })
          .addCase(getRegionalHistory.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })

    },
});

export const { updateRegionalAddandEdit, updateUser, updateVersionHistory} = RegionalSettingsDataSlice.actions;
export default RegionalSettingsDataSlice.reducer;
