import { VOBWorflowPages } from "../../../config/config";

const validateField = (index: number, Q_index: number, field: string, value: any, validationErrors: any) => {
  let errors: { [key: string]: string } = { ...validationErrors };

  switch (field) {
    case "ChoicesScore":
      if (!value || value <= 0) {
        errors[`${index}-${Q_index}-ChoicesScore`] = "Response is required";
      } else {
        delete errors[`${index}-${Q_index}-ChoicesScore`];
      }
      break;
    case "Response_Received":
      if (!value) {
        errors[`${index}-${Q_index}-Response_Received`] = "Response is required";
      } else {
        if (value && value.length > 500) {
          errors[`${index}-${Q_index}-Response_Received`] = "Maximum 500 characters are allowed";
        } else {
          delete errors[`${index}-${Q_index}-Response_Received`];
        }
      }
      break;
    case "Attachment":
      if (!value) {
        errors[`${index}-${Q_index}-Attachment`] = "Attachment is required";
      } else {
        delete errors[`${index}-${Q_index}-Attachment`];
      }
      break;
      case "SME_ReviewStatus":
      if (!value) {
        errors[`${index}-${Q_index}-SME_ReviewStatus`] = "Review Status is required";
      } else {
        delete errors[`${index}-${Q_index}-SME_ReviewStatus`];
      }
      break;
    case "SME_Remarks":
      if (value && value.length > 250) {
        errors[`${index}-${Q_index}-SME_Remarks`] = "Maximum 250 characters are allowed";
      } else {
        delete errors[`${index}-${Q_index}-SME_Remarks`];
      }
      break;
    case "SO_Remarks":
      if (value && value.length > 250) {
        errors[`${index}-${Q_index}-SO_Remarks`] = "Maximum 250 characters are allowed";
      } else {
        delete errors[`${index}-${Q_index}-SO_Remarks`];
      }
      break;
    case "DO_Remarks":
      if (value && value.length > 250) {
        errors[`${index}-${Q_index}-DO_Remarks`] = "Maximum 250 characters are allowed";
      } else {
        delete errors[`${index}-${Q_index}-DO_Remarks`];
      }
      break;
    case "DO_ReviewStatus":
        if (!value) {
          errors[`${index}-${Q_index}-DO_ReviewStatus`] = "Do Status is required";
        } else {
          delete errors[`${index}-${Q_index}-DO_ReviewStatus`];
        }
        break;
    default:
      break;
  }

  // setvalidationErrors(errors);
  return errors;
};

export default validateField;

export const validateAssessmentAll = (selectedCatagoryPreview: any, user: string) => {
  let allErrors: { [key: string]: string } = {};

  selectedCatagoryPreview.forEach((item: any, index: any) => {
    item.Questions &&
      item.Questions.forEach((q_item: any, Q_index: any) => {
        const scoreErrors = q_item.Answer_Type
          ? validateField(index, Q_index, "ChoicesScore", q_item.ChoicesScore, allErrors)
          : null;
        const answerErrors = !q_item.Answer_Type
          ? validateField(index, Q_index, "Response_Received", q_item.Response_Received, allErrors)
          : null;
        const attachmentErrors = q_item.EvidenceRequired
          ? validateField(index, Q_index, "Attachment", q_item.Attachment, allErrors)
          : null;
        let smeErrors = null;
        let smeStatusErrors = null;
        let soErrors = null;
        let doErrors = null;
        let doStatusErrors = null;
        if (user === "SME") {
          smeErrors = validateField(index, Q_index, "SME_Remarks", q_item.SME_Remarks, allErrors);
          smeStatusErrors = validateField(index, Q_index, "SME_ReviewStatus", q_item.SME_ReviewStatus, allErrors);
        }
       

        if (user === "SO") {
          soErrors = validateField(index, Q_index, "SO_Remarks", q_item.SO_Remarks, allErrors);
          
        }
        if (user === "DO") {
          doErrors = validateField(index, Q_index, "DO_Remarks", q_item.DO_Remarks, allErrors);
          doStatusErrors = validateField(index, Q_index, "DO_ReviewStatus", q_item.DO_ReviewStatus, allErrors);
        }

        allErrors = {
          ...allErrors,
          ...scoreErrors,
          ...answerErrors,
          ...attachmentErrors,
          ...smeErrors,
          ...smeStatusErrors,
          ...soErrors,
          ...doErrors,
          ...doStatusErrors
        };
      });
  });

  return allErrors;
};

const ConvertJson = (data: any) => {
  try {
    let parsedRiskScore = JSON.parse(data);
    return parsedRiskScore;
  } catch (error) {
    return data;
  }
};

const createRiskData = (risk: any, value: any) => {
  if (risk) {
    let final_data: any[] = [];
    risk.map((item: any) => {
      let obj = { RiskId: item, Score: value };
      final_data.push(obj);
    });
    return final_data;
  }
  return [];
};

export const ConvertGetData = (selectedCatagoryPreview: any) => {
  let catagory = selectedCatagoryPreview?.CategoryQuestions || [];
  let updatedCategory: any = catagory.map((item: any) => {
    let QuestionsList = item.Questions || [];
    let updatedQuestions = QuestionsList?.map((q_item: any) => {
      let riskScore = q_item?.ApplicableRisks || [];
      let updatedQuestion = { ...q_item }; // Create a copy of the question object
      let converted_risks = ConvertJson(riskScore);
      updatedQuestion.ApplicableRisks = converted_risks;

      let riskScoreList = createRiskData(converted_risks, q_item?.ChoicesScore);
      updatedQuestion.ApplicableRisksScore = riskScoreList;

      return updatedQuestion;
    });

    return { ...item, Questions: updatedQuestions }; // Return a new item object with updated questions
  });
  return updatedCategory || [];
};


export const checkstepAvailable = (columns : any , steps? : any  ) => {
          
     if(steps){
      if(columns == "Do"){
       let find =  steps.find((item : any) =>  {
       return item.FormName === VOBWorflowPages.DoReview  
        })      
           return find ? true : false
       }

       if(columns == "SME"){
        let find =  steps.find((item : any) =>  {
          return item.FormName === VOBWorflowPages.SMEReview
         })      
            return find ? true : false
        }

        if(columns == "Do Status"){
          let find =  steps.find((item : any) =>  {
          return item.FormName === VOBWorflowPages.DoReview  && item.Status == "Completed"
           })      
              return find ? true : false
          }
       return false
     }else{

      return false
     }
      
}
