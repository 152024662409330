import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import profilePictureService from "../services/profilePictureService";

export interface IRoleData {
  profilePicture: any;
  isLoading: boolean;
  isProfileAdded: boolean;
  isProfileUpdated: boolean;
  isProfileDeleted: boolean;
  ToasterMsg: string;
}

export const initialState: IRoleData = {
  profilePicture: "",
  isLoading: false,
  isProfileAdded: false,
  isProfileUpdated: false,
  isProfileDeleted: false,
  ToasterMsg: "",
};

export const addProfilePicture = createAsyncThunk(
  "addProfilePicture",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await profilePictureService.addProfilePicture(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateProfilePicture = createAsyncThunk(
  "updateProfilePicture",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await profilePictureService.updateProfilePicture(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProfilePicture = createAsyncThunk(
  "deleteProfilePicture",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response = await profilePictureService.deleteProfilePicture(reqbody);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const profilePicture = createSlice({
  name: "profilePicture",
  initialState,
  reducers: {
    updateProfileAddandEdit: (state, action) => {
      state.isProfileAdded = false;
      state.isProfileUpdated = false;
      state.isProfileDeleted = false;
      // state.ToasterMsg = "";
      // state.profilePicture = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addProfilePicture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProfilePicture.fulfilled, (state, action: PayloadAction<any>) => {
        state.isProfileAdded = true;
        state.isLoading = false;
        state.profilePicture = action.payload.ProfileUrl;
        console.log(action.payload, "Payload");
        state.ToasterMsg = action.payload.ToasterMessage.Message;
        sessionStorage.setItem("profilePicture", action.payload.ProfileUrl);
      })
      .addCase(addProfilePicture.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateProfilePicture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfilePicture.fulfilled, (state, action: PayloadAction<any>) => {
        state.isProfileUpdated = true;
        state.isLoading = false;
        state.profilePicture = action.payload.ProfileUrl;
        state.ToasterMsg = action.payload.ToasterMessage.Message;
        console.log(action.payload, "Payload");
        sessionStorage.setItem("profilePicture", action.payload.ProfileUrl);
      })
      .addCase(updateProfilePicture.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

      .addCase(deleteProfilePicture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProfilePicture.fulfilled, (state, action: PayloadAction<any>) => {
        state.isProfileDeleted = true;
        state.isLoading = false;
        console.log(action.payload, "PayloadDelete");
        state.ToasterMsg = action.payload.Message;
      })
      .addCase(deleteProfilePicture.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.ToasterMsg = action.payload.ToasterMessage.Message;
      });
  },
});

export const { updateProfileAddandEdit } = profilePicture.actions;
export default profilePicture.reducer;
