import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import departmentService from "../services/departmentService";
import { IAddDepartmentResponse, IDepartmentResponse, IVHColumnRes, Idepartment } from "../../models/department.type";
import saveAsDraftService from "../services/saveAsDraftService";
export interface IDepartmentData {
  isLoadingDraft: boolean;
  isAddEditLoadingDraft: boolean;
  isDraftAdded: boolean;
  isDraftUpdated: boolean;
  isDraftDeleted: boolean;
  DraftToasterMsg: string;
  draftdata: any;
}

export const initialState: IDepartmentData = {
  isLoadingDraft: false,
  isAddEditLoadingDraft: false,
  isDraftAdded: false,
  isDraftUpdated: false,
  isDraftDeleted: false,
  draftdata: null,
  DraftToasterMsg: "",
};

export const getAllSaveandDraftList = createAsyncThunk(
  "getSaveandDrafList",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response = await saveAsDraftService.getAllSaveAsDraftList(requestParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getSaveandDrafList = createAsyncThunk(
  "getAllSaveandDraftList",
  async (requestParams: { DraftId: number }, { rejectWithValue }) => {
    try {
      const response = await saveAsDraftService.getSaveAsDraftList(requestParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSaveandDraft = createAsyncThunk("addSaveandDraf", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await saveAsDraftService.addSaveAsDraft(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateSaveandDraft = createAsyncThunk(
  "updateSaveandDraft",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requestParams };
      const response: any = await saveAsDraftService.updateSaveAsDraft(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSaveandDraft = createAsyncThunk(
  "deleteSaveandDraft",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requestParams };
      const response: any = await saveAsDraftService.deleteSaveAsDraft(reqbody);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveAsDraftSlice = createSlice({
  name: "saveAsDraft",
  initialState,
  reducers: {
    updateSaveasDraftAddandEdit: (state, action) => {
      state.isDraftAdded = false;
      state.isDraftUpdated = false;
      state.isDraftDeleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSaveandDraftList.pending, (state) => {
        state.isLoadingDraft = true;
        state.isDraftAdded = false;
      })
      .addCase(getAllSaveandDraftList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");
        let changed_value = action.payload;
        // changed_value.DraftData = action.payload;
        state.draftdata = changed_value;
        state.isLoadingDraft = false;
        state.isDraftAdded = false;
      })
      .addCase(getAllSaveandDraftList.rejected, (state, action: PayloadAction<any>) => {
        state.isDraftAdded = false;
        state.isLoadingDraft = false;
        state.isDraftUpdated = false;
      })
      .addCase(getSaveandDrafList.pending, (state) => {
        state.isLoadingDraft = true;
        state.isDraftAdded = false;
        state.isDraftUpdated = false;
      })
      .addCase(getSaveandDrafList.fulfilled, (state, action: PayloadAction<any>) => {
        let changed_value = action.payload;
        // changed_value.DraftData = JSON.parse(action.payload.DraftData);
        state.draftdata = changed_value;
        state.isLoadingDraft = false;
        state.isDraftAdded = false;
        state.isDraftUpdated = false;
      })
      .addCase(getSaveandDrafList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoadingDraft = false;
        state.isDraftAdded = false;
      })
      .addCase(addSaveandDraft.pending, (state) => {
        state.isLoadingDraft = true;
        state.isAddEditLoadingDraft = true;
      })
      .addCase(addSaveandDraft.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDraftAdded = true;
        state.isLoadingDraft = false;
        state.isAddEditLoadingDraft = false;
        state.DraftToasterMsg = action.payload.Message;
      })
      .addCase(addSaveandDraft.rejected, (state, action: PayloadAction<any>) => {
        state.isLoadingDraft = false;
        state.isAddEditLoadingDraft = false;
      })
      .addCase(updateSaveandDraft.pending, (state) => {
        state.isLoadingDraft = true;
        state.isDraftUpdated = false;
        state.isAddEditLoadingDraft = true;
      })
      .addCase(updateSaveandDraft.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDraftUpdated = true;
        state.isLoadingDraft = false;
        state.isAddEditLoadingDraft = false;
        state.DraftToasterMsg = action.payload.Message;
      })
      .addCase(updateSaveandDraft.rejected, (state, action: PayloadAction<any>) => {
        state.isLoadingDraft = false;
        state.isDraftUpdated = false;
        state.isAddEditLoadingDraft = false;
      })
      .addCase(deleteSaveandDraft.pending, (state) => {
        state.isLoadingDraft = true;
        state.isDraftDeleted = false;
        state.isDraftAdded = false;
        state.isDraftUpdated = false;
      })
      .addCase(deleteSaveandDraft.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDraftDeleted = true;
        state.isLoadingDraft = false;
        state.isDraftAdded = false;
        state.isDraftUpdated = false;
        // state.DraftToasterMsg = action.payload.Message;
      })
      .addCase(deleteSaveandDraft.rejected, (state, action: PayloadAction<any>) => {
        state.isLoadingDraft = false;
        state.isDraftDeleted = false;
        state.isDraftAdded = false;
        state.isDraftUpdated = false;
      });
  },
});

export const { updateSaveasDraftAddandEdit } = saveAsDraftSlice.actions;
export default saveAsDraftSlice.reducer;
