import { AxiosError, AxiosResponse } from "axios";
import { METRICS_MASTERS_URL } from "./endpoints/metricsMaster";

import { axiosInstance } from "./config/axiosConfig";

const getMetricsList = (requesrParams: any): Promise<any> =>
  axiosInstance
    .get(METRICS_MASTERS_URL.getAllMetrics, { params: requesrParams })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addMetrics = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(METRICS_MASTERS_URL.addMetrics, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateMetrics = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(METRICS_MASTERS_URL.addMetrics, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });



const getMetricsByID = (req: any): Promise<any> =>
  axiosInstance
    .get(METRICS_MASTERS_URL.getMetricsByID, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getMetricsByName = (req: any): Promise<any> =>
  axiosInstance
    .get(METRICS_MASTERS_URL.getMetricsByName, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getMetricsHistoryByID = (req: any): Promise<any> =>
  axiosInstance
    .get(METRICS_MASTERS_URL.getMetricsHistoryByID, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const MetricsMasterService = {
  getMetricsList,
  addMetrics,
  updateMetrics,
  getMetricsByID,
  getMetricsByName,
  getMetricsHistoryByID,
};

export default MetricsMasterService;
