import { Box, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import React, { useEffect, useState } from "react";
import { getLovList } from "../../../store/slices/lovMasterData";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { getDepartmentList } from "../../../store/slices/departmentData";
import CustomTabs from "../../../common/dynamicTabs/CustomTabs";
import { getLocationList, getParentLocationList } from "../../../store/slices/locationMasterData";
import { getThirdPartyList } from "../../../store/slices/thirdPartyData";
import { getIndustryList } from "../../../store/slices/industryMasterData";
import { VendorDetails } from "./VendorDetails/index";
import { OtherDetails } from "./OtherDetails";
import { ServiceCatalogMapping } from "./ServiceCatalogMapping";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { Attachments } from "./Attachments";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { setTabs } from "../../../store/slices/tabsData";
import DocumentMasterService from "../../../store/services/documentMasterService";
import { ApplicationParameterName, Assessment_Type, LocationConfig, LovConfig, thirdPrtyType, ToasterActivityConfig, VendorMaster_Address_Type } from "../../../config/config";
import { addVendorDetails } from "../../../store/slices/vendorDetails";
import { addSaveandDraft, deleteSaveandDraft, updateSaveandDraft } from "../../../store/slices/saveasdraft";
import { getRegionalList } from "../../../store/slices/regionalSettingsData";
import dayjs from "dayjs";
import { getWorkList } from "../../../store/slices/workFlowSlice";
import { RolesandResponsibility } from "../../vendorOnBoarding/rolesAndResponsibility/RolesAndResponsibility";
import { updaterolesandresponsAddandEdit } from "../../../store/slices/rolesAndResponsiblity";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { VendorHistory } from "../../vendorHistory/VendorHistory";
import { updateProbeAddandEdit } from "../../../store/slices/ProbeData";
import { getDocumentList } from "../../../store/slices/documentMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { getAQMMappingByVendorID } from "../../../store/slices/assesmentQuestionnaireMapping";

export interface VendorDetailsProps {
  view?: boolean;
  isEditData?: any;
  SignzyData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: boolean;
  ModuleId?: number | undefined;
  isAqmResponse?: any;
  activestepCount?: any;
  handleNext?: any;
  handlePrevious?: any;
  isWorkFlow?: any;
  prePageName?: any;
  isDisableBreadCrums?: boolean;
}
interface FormData {
  Group_Name?: any;
  Legal_Name?: any;
  Brand_Name?: any;
  Logo?: any;
  WebSite?: any;
  Contact_Number?: any;
  EmailId?: any;
  PAN?: any;
  TAN?: any;
  CIN?: any;
  Jurisdiction?: any;
  Constitution?: any;
  IsListed?: any;
  IsRegulated?: any;
  Regulator_Id?: any;
  Others_Regulator?: any;
  Type_Of_Vendor1?: any;
  Type_Of_Vendor2?: any;
  Industry_Id?: any;
  SubIndustry_Id?: any;
  Service_Owner?: any;
  Scope_Of_Service?: any;
  Exclusion?: any;
  KeyCustomer?: any;
  Industries_Served?: any;
  VolumeHandled?: any;
  Competitor_Details?: any;
  EvaluationCriteria_Used?: any;
  Internal_VendorCode?: any;
  Incorporated_Date: any;

}

interface ServiceMappingFormData {
  Service_Owner?: any;
  Scope_Of_Service?: any;
  Exclusion?: any;
  Assessment_Type?: any;
  isTriggerWorkflow?: any;
  serviceMapping?: any;
}

export const AddVendorDetails: React.FC<VendorDetailsProps> = ({
  view,
  isEditData,
  edit,
  SignzyData,
  isDraftData,
  masterId,
  onClose,
  ModuleId,
  isAqmResponse,
  handleNext,
  handlePrevious,
  isWorkFlow,
  prePageName,
  isDisableBreadCrums,
}) => {
  const { vendorDetailsSavereturnData, vendorDetailsdata, isVendorDetailsAdded, isVendorDetailsUpdated, vendorDetailsToasterStatus, vendorDetailsToasterOncloseStatus } = useSelector(
    (state: RootState) => state.vendorDetails
  );
  const {
    isRolesAndResponsibilityAdded,
    isAddEditRolesAndResonsibilityLoading,
    RolesAndResonsibilitytoasterMsg,
  } = useSelector((state: RootState) => state.rolesAndResponsibilityData);
  const { draftdata, isAddEditLoadingDraft, isDraftAdded, isDraftUpdated } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [lovActiveStatusId, setLovActiveStatusId] = useState<any[]>([]);
  const [ProbeCompanyData, setProbeCompanyData] = useState<any>([]);
  const [SignzyDatas, setSignzyDatas] = useState<any>({});
  const [vendorInActiveStatusId, setVendorInActiveStatusId] = useState<any[]>([]);
  const [AttachmentTableData, setAttachmentTableData] = useState<any[]>([]);
  const [assessmentTypeMapping, setAssessmentTypeMapping] = useState<any>(null);
  const [selectedworkflowData, setselectedworkflowData] = useState<any>("");
  const [showRolesandresponsiblity, setshowRolesandresponsiblity] = useState<boolean>(false);
  const [IsCancel, setIsCancel] = useState<boolean>(false);
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { ServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { dataTable, versionHistory, isVendorAdded, isVendorUpdated, ToasterMsg, } = useSelector(
    (state: RootState) => state.vendorData
  );
  const { departmentTable } = useSelector((state: RootState) => state.departmentData);
  const { DocumentTable } = useSelector((state: RootState) => state.DocumentMasterData);
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);
  const { ParentLocationTable, islocationAdded } = useSelector((state: RootState) => state.locationMasterData);
  const { industryTable } = useSelector((state: RootState) => state.indutryMasterData);
  const { CompanyComprehensiveData, CompanyComprehensiveAdded, CompanyComprehensiveFailed, ProbeToasterMsg } = useSelector((state: RootState) => state.ProbeData);
  let AddressTypeLOV = lovTable.filter((item: any) => item.Name === "Address Type") || [];
  let CompanyConstitutionLOV = lovTable.filter((item: any) => item.Name === "Company Constitution") || [];
  let VendorTypeILOV = lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeI)
  let VendorTypeIILOV = lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeII)
  const filteredRegulatorData = thirdPartyData.filter((item: any) => item.IsActive === "Active" && item.ThirdPartyTypeValue === "Regulator");

  const dispatch = useAppDispatchThunk();
  const { active } = useSelector((state: RootState) => state.tabData);

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(getThirdPartyList({ FormID: 0 }));
    dispatch(getIndustryList({ FormID: 2 }));
    dispatch(getDepartmentList({ FormID: 2 }));
    dispatch(getLocationList({ FormID: 2 }));
    dispatch(getServiceCatalogList());
    dispatch(getRegionalList({ FormID: 2 }));
    dispatch(getDocumentList({ FormID: 2, }));
    // this used to call worflow list if workflow trigger from vendor master use workflowlist
    if (!isAqmResponse && !isWorkFlow) {
      dispatch(getWorkList());
    }
    return () => {
      dispatch(setTabs(0));
    };
  }, []);

  useEffect(() => {
    dispatch(getAQMMappingByVendorID({ VendorID: isEditData?.VendorDetails?.Vendor_Id }));
   
  }, [isEditData]);

  const fetchParentLocation = () => {
    let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type)
    if (dublocationType && dublocationType.length > 0) {
      let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_City)
      if (countryTypeId && countryTypeId.LovId) {
        dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
      }
    }
  }
  useEffect(() => {
    fetchParentLocation()
  }, [lovTable])


  let findRegiterAddressID: any = AddressTypeLOV.find(
    (item: any) => item.Options === VendorMaster_Address_Type.RegisteredAddress
  );

  let findBusinessAddressID: any = AddressTypeLOV.find(
    (item: any) => item.Options === VendorMaster_Address_Type.BusinessAddress
  );

  useEffect(() => {
    try {
      if (CompanyComprehensiveData) {
        setProbeCompanyData(CompanyComprehensiveData?.data);

      }
    } catch (error) {
      console.error("Error in setting SignzyDatas:", error);
    }
  }, [CompanyComprehensiveData]);

  useEffect(() => {
    try {
      console.log(ProbeCompanyData, "ProbeCompanyData");

      let FormateIncorporated_Date = ProbeCompanyData?.company?.incorporation_date
        ? dayjs(ProbeCompanyData?.company?.incorporation_date)
        : null;
      let ProbeindustryName = ProbeCompanyData?.industry_segments[0]?.industry;
      let industryid = industryTable.find((item: any) => item.IndustryName === ProbeindustryName)?.IndustryId || "";

      let ProbeSubindustryName = ProbeCompanyData?.industry_segments[0]?.segments;
      let SubindustryIds = ProbeSubindustryName.map((SubIndus: any) => (industryTable.find((item: any) => item.IndustryName === SubIndus)?.IndustryId || []));

      let ProbeConstitutionName = ProbeCompanyData?.company?.classification;
      let ConstitutionId = CompanyConstitutionLOV.find((item: any) => item.Description == ProbeConstitutionName)?.LovId || "";

      let ProbeJuridictionCityName = ProbeCompanyData?.company?.registered_address?.city;
      let JuridictionCityID = ParentLocationTable?.find((item: any) => item.Location_Name === ProbeJuridictionCityName) || "";
      console.log(JuridictionCityID, ParentLocationTable, "ProbeJuridictionCityID");

      let ProbeIsListed = ProbeCompanyData?.company?.status === "Listed" ? true : false;


      if (ProbeCompanyData != undefined) {
        vendorDetailsSetValue("Legal_Name", ProbeCompanyData?.company?.legal_name);
        vendorDetailsSetValue("Group_Name", ProbeCompanyData?.company?.legal_name?.split(' ')[0]);
        vendorDetailsSetValue("PAN", ProbeCompanyData?.company?.pan);
        vendorDetailsSetValue("CIN", ProbeCompanyData?.company?.cin);
        vendorDetailsSetValue("Industry_Id", industryid);
        vendorDetailsSetValue("SubIndustry_Id", SubindustryIds);
        vendorDetailsSetValue("Constitution", ConstitutionId);
        vendorDetailsSetValue("IsListed", ProbeIsListed);
        vendorDetailsSetValue("EmailId", ProbeCompanyData?.company?.email);
        vendorDetailsSetValue("WebSite", ProbeCompanyData?.company?.website);
        vendorDetailsSetValue("Contact_Number", ProbeCompanyData?.contact_details?.phone[0]?.phoneNumber);
        vendorDetailsSetValue("Incorporated_Date", FormateIncorporated_Date);
        vendorDetailsSetValue("Jurisdiction", JuridictionCityID?.Location_Id);

        const VendorAddress = [
          {
            AddressNumber: 1,
            AddressType: findRegiterAddressID.LovId,
            Address_Type_Value: VendorMaster_Address_Type.RegisteredAddress,
            Address: ProbeCompanyData?.company?.registered_address?.address_line1,
            Pin: ProbeCompanyData?.company?.registered_address?.pincode,
            City: ProbeCompanyData?.company?.registered_address?.city,
            State: ProbeCompanyData?.company?.registered_address?.state,
            // Country: ProbeCompanyData?.company?.registered_address?.pincode,
            Country: "India",
            Status: lovTable.find((item: any) => item.Name === LovConfig.Status && item.Options === "Active").LovId || 0,
          },
          {
            AddressNumber: 2,
            AddressType: findBusinessAddressID.LovId,
            Address_Type_Value: VendorMaster_Address_Type.BusinessAddress,
            Address: ProbeCompanyData?.company?.business_address?.address_line1,
            Pin: ProbeCompanyData?.company?.business_address?.pincode,
            City: ProbeCompanyData?.company?.business_address?.city,
            State: ProbeCompanyData?.company?.business_address?.state,
            Country: "India",
            Status: lovTable.find((item: any) => item.Name === LovConfig.Status && item.Options === "Active").LovId || 0,
          },
        ];
        otherDetailsSetValue("address", VendorAddress);
        console.log("inside set values");

      } else if (ProbeCompanyData === undefined) {
        vendorDetailReset();
        otherDetailsReset();
        otherDetailsSetValue("address", {});
      }
    } catch (error) {
      console.error("Error in updating form values:", error);
    }
  }, [ProbeCompanyData, CompanyComprehensiveData]);

  const MCDataForDisplay = (data: any) => {

    let industryName = industryTable?.find((item: any) => item.IndustryId === data?.VendorDetails?.Industry_Id)?.IndustryName || "";
    let SubindustryIds = data?.VendorDetails?.SubIndustry_Id.split(",")
    let Subindustryname = SubindustryIds.map((SubIndusID: string) =>
      industryTable.find((item: any) => item.IndustryId == SubIndusID)?.IndustryName
    ).join(", ");
    console.log(SubindustryIds, Subindustryname, "SubindustryIds");

    let AllienceTypeName = VendorTypeILOV.find((item: any) => item.LovId === data?.VendorDetails?.Type_Of_Vendor1)?.Options || "";

    let ThirdPartyTypeName = VendorTypeIILOV.find((item: any) => item.LovId === data?.VendorDetails?.Type_Of_Vendor2)?.Options || "";

    let ConstitutionName = CompanyConstitutionLOV.find((item: any) => item.LovId === data?.VendorDetails?.Constitution)?.Options || "";

    let JuridictionCityName = ParentLocationTable?.find((item: any) => item.Location_Id === data?.VendorDetails?.Jurisdiction)?.Location_Name || "";

    let ISRegulatorName = filteredRegulatorData?.find((item: any) => item.ThirdPartyId === data?.VendorDetails?.Regulator_Id)?.ThirdPartyName || "";
    const Assessment_Type = lovTable.find((item: any) => item.Name === LovConfig.Assessment_Type && item.LovId === data?.VendorDetails?.Assessment_TypeId)?.Options || "";
    let FilterSelectedServiceData = data?.VendorServiceMappings?.map((SCitem: any) =>
      ActiveServiceCatlogdata.find((item) => (SCitem?.ServiceCatalogId === item.ServiceCatalogId))

    );
    let FilterDocumentAttachData = data?.VendorAttachments?.map((Attachitem: any) =>
      DocumentTable.find((item: any) => (Attachitem?.DocumentId === item.DocumentId))

    );
    let OnboardingOwnerName = departmentTable?.find((item: any) => item.DepartmentId === data?.VendorDetails?.Service_Owner)?.DepartmentName || "";

    let BasicDetails: any = {
      ThirdPartyType: ThirdPartyTypeName,
      ThirdPartyName: data?.VendorDetails?.Legal_Name,
      ThirdPartyBrandName: data?.VendorDetails?.Brand_Name,
      ThirdPartyWepsite: data?.VendorDetails?.WebSite,
      ContactNumber: data?.VendorDetails?.Contact_Number,
      EmailID: data?.VendorDetails?.EmailId,
      ThirdPartyGroupName: data?.VendorDetails?.Group_Name,
      Headquater_Country: data?.VendorDetails?.Headquater_Country,
      AllienceType: AllienceTypeName,
      InCorporatedYear: data?.VendorDetails?.Incorporated_Date ? dayjs(data?.VendorDetails?.Incorporated_Date)?.format("DD-MMM-YYYY") : null,
      Industry: industryName,
      SubIndustry: Subindustryname,
    }

    let ConstitutionDetails: any = {
      PAN: data?.VendorDetails?.PAN,
      TAN: data?.VendorDetails?.TAN,
      CIN: data?.VendorDetails?.CIN,
      Jurisdiction: JuridictionCityName,
      Constitution: ConstitutionName,
    }

    let RegulatorDetails: any = {
      IsListed: data?.VendorDetails?.IsListed ? "Yes" : "No",
      ISRegulated: data?.VendorDetails?.IsRegulated ? "Yes" : "No",
      RegulatorName: ISRegulatorName,
      Others_Regulator: data?.VendorDetails?.Others_Regulator,
    }

    let ThirdPartyProfiling: any = {
      KeyCustomer: data?.VendorDetails?.KeyCustomer,
      IndustriesCatered: data?.VendorDetails?.Industries_Served,
      VolumeHandled: data?.VendorDetails?.VolumeHandled,
      CompetitorDetails: data?.VendorDetails?.Competitor_Details,
      InternalThirdPartyCode: data?.VendorDetails?.Internal_VendorCode,
    }

    let ServiceCatlog: any = {
      ScopeOfService: data?.VendorDetails?.Scope_Of_Service,
      Exclusion: data?.VendorDetails?.Exclusion,
      OnboardingOwner: OnboardingOwnerName,
      AssessmentType: Assessment_Type,
      ServiceCatlogMapping: FilterSelectedServiceData,
    }

    let FormateDataForDisplay: any = {

      BasicDetailsData: BasicDetails,
      ConstitutionDetailsData: ConstitutionDetails,
      RegulatorDetailsData: RegulatorDetails,
      ThirdPartyProfilingData: ThirdPartyProfiling,
      ThirdPartyAddressData: data?.VendorAddress,
      VendorSpocsData: data?.VendorSpocs,
      VendorEscalationsData: data?.VendorEscalations,
      ServiceCatlogMappingData: ServiceCatlog,
      VendorAttachmentsData: FilterDocumentAttachData,
    }

    console.log(data, FormateDataForDisplay, "FormateDataForDisplay");
    return FormateDataForDisplay
  }

  useEffect(() => {
    if (CompanyComprehensiveAdded) {
      dispatch(updateLoader(false));
      dispatch(updateProbeAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ProbeToasterMsg.Message, isTosterFailed: ProbeToasterMsg.Status === "false" ? true : false }));
    }
    else if (CompanyComprehensiveFailed) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ProbeToasterMsg.Message, isTosterFailed: true }));
      dispatch(updateProbeAddandEdit(false));
      dispatch(updateLoader(false));
    }

  }, [CompanyComprehensiveAdded, CompanyComprehensiveFailed]);

  useEffect(() => {
    if (lovTable) {
      setLovActiveStatusId(
        lovTable.find((item: any) => item.Name === LovConfig.Status && item.Options === "Active")?.LovId || 0
      );
      setVendorInActiveStatusId(
        lovTable.find((item: any) => item.Name === LovConfig.VendorStatus && item.Options === "Inactive")?.LovId || 0
      );
      if (isEditData) attachmentsSetValues("Assessment_TypeId", isEditData?.VendorDetails?.Assessment_TypeId);
      else {
        const id =
          lovTable.find((item: any) => item.Name === LovConfig.Assessment_Type && item.Options === "Basic")?.LovId || 0;
        attachmentsSetValues("Assessment_TypeId", id);
      }
    }
  }, [lovTable]);

  useEffect(() => {
    const runEffect = async () => {
      if (isRolesAndResponsibilityAdded) {

        dispatch(updaterolesandresponsAddandEdit(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: RolesAndResonsibilitytoasterMsg }));
        onClose()
      }
    };
    runEffect();
  }, [isRolesAndResponsibilityAdded]);

  useEffect(() => {
    dispatch(updateLoader(isAddEditRolesAndResonsibilityLoading));
  }, [isAddEditRolesAndResonsibilityLoading]);

  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      onClose();
    }
  };

  let vendorDetailsFormData = {
    Vendor_Id: isDraftData ? 0 : isEditData ? isEditData?.VendorDetails?.Vendor_Id : 0,
    Group_Name: isEditData ? isEditData?.VendorDetails?.Group_Name : "",

    Legal_Name: isEditData ? isEditData?.VendorDetails?.Legal_Name : "",
    Brand_Name: isEditData ? isEditData?.VendorDetails?.Brand_Name : "",
    WebSite: isEditData ? isEditData?.VendorDetails?.WebSite : "",
    Contact_Number: isEditData ? isEditData?.VendorDetails?.Contact_Number : "",
    EmailId: isEditData ? isEditData?.VendorDetails?.EmailId : "",
    Logo: isEditData ? isEditData?.VendorDetails?.Logo : "",
    Incorporated_Date: isEditData ? isEditData?.VendorDetails?.Incorporated_Date ? dayjs(isEditData?.VendorDetails?.Incorporated_Date) : null : null,
    Headquater_Country: isEditData ? isEditData?.VendorDetails?.Headquater_Country : "",
    Type_Of_Vendor1: isEditData ? isEditData?.VendorDetails?.Type_Of_Vendor1 : "",
    Type_Of_Vendor2: isEditData ? isEditData?.VendorDetails?.Type_Of_Vendor2 : "",
    Industry_Id: isEditData ? isEditData?.VendorDetails?.Industry_Id : "",
    SubIndustry_Id: isEditData ? isEditData?.VendorDetails?.SubIndustry_Id?.split(",").map(Number) : [],
    Status: isEditData ? isEditData?.VendorDetails?.Status : 145,

    PAN: isEditData ? isEditData?.VendorDetails?.PAN : "",
    TAN: isEditData ? isEditData?.VendorDetails?.TAN : "",
    CIN: isEditData ? isEditData?.VendorDetails?.CIN : "",
    Jurisdiction: isEditData ? isEditData?.VendorDetails?.Jurisdiction : "",
    Constitution: isEditData ? isEditData?.VendorDetails?.Constitution : "",

    IsListed: isEditData ? isEditData?.VendorDetails?.IsListed : false,
    IsRegulated: isEditData ? isEditData?.VendorDetails?.IsRegulated : false,
    Regulator_Id: isEditData ? isEditData?.VendorDetails?.Regulator_Id : "",
    Others_Regulator: isEditData ? isEditData?.VendorDetails?.Others_Regulator : "",

    KeyCustomer: isEditData ? isEditData?.VendorDetails?.KeyCustomer : "",
    Industries_Served: isEditData ? isEditData?.VendorDetails?.Industries_Served : "",
    VolumeHandled: isEditData ? isEditData?.VendorDetails?.VolumeHandled : "",
    Competitor_Details: isEditData ? isEditData?.VendorDetails?.Competitor_Details : "",
    // EvaluationCriteria_Used: isEditData ? isEditData?.VendorDetails?.EvaluationCriteria_Used : "",
    Internal_VendorCode: isEditData ? isEditData?.VendorDetails?.Internal_VendorCode : "",

  };

  const vendorDetailsFormMethods = useForm<FormData>({ defaultValues: vendorDetailsFormData, mode: "onChange" });

  const {
    handleSubmit: vendorDetailsHandleSubmit,
    formState: { errors: errorsVendorDetailsform, isDirty: vendorDetailsIsDirty },
    getValues: vendorDetailsGetValues,
    setValue: vendorDetailsSetValue,
    reset: vendorDetailReset,
  } = vendorDetailsFormMethods;

  let otherDetailsFormData = {
    address: isEditData ? isEditData?.VendorAddress : [],
    spocs: isEditData ? isEditData?.VendorSpocs : [],
    escalations: isEditData ? isEditData?.VendorEscalations : [],
  };
  const otherDetailsFormMethods = useForm({ defaultValues: otherDetailsFormData });

  const {
    handleSubmit: otherDetailsHandleSubmit,
    getValues: otherDetailsGetValues,
    formState: { isDirty: otherDetailsIsDirty },
    setValue: otherDetailsSetValue,
    reset: otherDetailsReset,
  } = otherDetailsFormMethods;

  let serviceMappingFormData = {
    serviceMapping: isEditData ? isEditData?.VendorServiceMappings : [],
    Service_Owner: isEditData ? isEditData?.VendorDetails?.Service_Owner : "",
    Scope_Of_Service: isEditData ? isEditData?.VendorDetails?.Scope_Of_Service : "",
    Exclusion: isEditData ? isEditData?.VendorDetails?.Exclusion : "",
    Assessment_Type: isEditData ? isEditData?.VendorDetails?.Assessment_TypeId : "",
    isTriggerWorkflow: false,
  };
  const serviceMappingFormMethods = useForm<ServiceMappingFormData>({ defaultValues: serviceMappingFormData });
  console.log(isEditData, "isEditData");

  const {
    handleSubmit: serviceMappingHandleSubmit,
    getValues: serviceMappingGetValues,
    reset: serviceMappingReset,
    setValue: serviceMappingSetValues,
    formState: { isDirty: serviceMappingIsDirty },
  } = serviceMappingFormMethods;

  useEffect(() => {
    if (isEditData?.VendorServiceMappings.length > 0) {
      let res = isEditData.VendorServiceMappings.map((item1: any) => {
        const item2 = ServiceCatlogdata.find((item2) => item2.ServiceCatalogId === item1.ServiceCatalogId);
        return { ...item1, ServiceName: item2?.ServiceName, Summary: item2?.Summary };
      });
      console.log(res, "res");
      serviceMappingSetValues("serviceMapping", res)
      // serviceMappingReset({ serviceMapping: res });

    }
  }, [ServiceCatlogdata]);



  const attachmentsFormMethods = useForm({ defaultValues: { attachments: [], Assessment_TypeId: "" } as any });

  const {
    handleSubmit: attachmentsHandleSubmit,
    getValues: attachmentsGetValues,
    reset: attachmentsReset,
    setValue: attachmentsSetValues,
    formState: { isDirty: attachmentsIsDirty },
  } = attachmentsFormMethods;

  useEffect(() => {
    if (isVendorDetailsAdded || isVendorDetailsUpdated) {
      dispatch(updateLoader(false));

      if (isDraftData && vendorDetailsToasterOncloseStatus != "false") deleteDraftList();
      if (!isWorkFlow && vendorDetailsToasterOncloseStatus != "false") {
        console.log(vendorDetailsToasterStatus, isWorkFlow, 'vendorDetailsToasterStatus');

        let isworkflowTrigged = serviceMappingGetValues("isTriggerWorkflow") || false;
        if (isworkflowTrigged) {
          callWorkflowDataandtrigger();
        } else {
          onClose();
          console.log(vendorDetailsToasterStatus, vendorDetailsToasterOncloseStatus, "vendorDetailsToasterStatus Closeaaaaa");

        }
      }
    }
  }, [isVendorDetailsAdded, isVendorDetailsUpdated]);

  const getDisplayValue = (type: string) => {
    let res = lovTable.filter((item: any) => item.Name === type) || [];
    return res.reduce((agg: any, i: any) => {
      return { ...agg, [i.LovId]: i["Options"] };
    }, {});
  };


  useEffect(() => {
    const filtered = lovTable.filter((item: any) => item.Name === LovConfig.Assessment_Type) || [];
    const formatted = filtered.map((item: any) => {
      return { value: item.LovId, lable: item.Options };
    });
    // setAssessmentTypes(formatted);
    setAssessmentTypeMapping(getDisplayValue(LovConfig.Assessment_Type));
  }, [lovTable]);


  const serviceMappingFieldValues: any = serviceMappingGetValues();
  console.log(serviceMappingFieldValues.Assessment_Type, "serviceMappingFieldValues");

  const selectedAssesment = serviceMappingFieldValues?.Assessment_Type;



  useEffect(() => {
    if (selectedAssesment && assessmentTypeMapping) fetchData(selectedAssesment);
    console.log(selectedAssesment, assessmentTypeMapping, "selectedAssesment");

  }, [selectedAssesment, assessmentTypeMapping, selectedAssesment]);

  async function fetchData(assesmentId: number) {
    let res;
    // replace([]);

    if (assessmentTypeMapping[assesmentId] == "Advanced") res = await DocumentMasterService.getDocumentList({});
    else res = await DocumentMasterService.getDocumentByAssessmentTypeId({ AssessmentTypeId: assesmentId });
    const result = res.data.filter((i) => i.IsActive);
    let normalizedData = result.map(
      ({ DocumentName, DocTypeExtnId, DocumentId, IsMandatory, UpdatedBy, IsPerpetual }: any) => {
        const filtered = isEditData?.VendorAttachments?.find((i: any) => i.DocumentId === DocumentId);
        if (isAqmResponse) {
          if (filtered && filtered.Status === lovActiveStatusId)
            return {
              selected: isEditData ? (filtered?.Status === lovActiveStatusId ? true : false) : true,
              DocType: JSON.parse(DocTypeExtnId).map((i: any) => i.Description || i.Options),
              DocumentName,
              DocumentId,
              UpdatedBy,
              IsMandatory: filtered ? filtered?.IsMandatory : IsMandatory,
              IsPerpetual: filtered ? filtered?.IsPerpetual : IsPerpetual,
              ExpiryDate: filtered ? (filtered?.ExpiryDate ? dayjs(filtered?.ExpiryDate) : null) : null,
              Url: filtered?.Url || null,
              AttachmentId: filtered?.AttachmentId || 0,
            };
        } else
          return {
            selected: isEditData ? (filtered?.Status === lovActiveStatusId ? true : false) : true,
            DocType: JSON.parse(DocTypeExtnId).map((i: any) => i.Description || i.Options),
            DocumentName,
            DocumentId,
            UpdatedBy,
            IsMandatory: filtered ? filtered?.IsMandatory : IsMandatory,
            IsPerpetual: filtered ? filtered?.IsPerpetual : IsPerpetual,
            ExpiryDate: filtered ? (filtered?.ExpiryDate ? dayjs(filtered?.ExpiryDate) : null) : null,
            Url: filtered?.Url || null,
            AttachmentId: filtered?.AttachmentId || 0,
          };
      }
    );
    normalizedData = normalizedData.filter((i) => i);
    console.log(normalizedData);
    setAttachmentTableData(normalizedData);
    // replace(normalizedData);
  }

  const callWorkflowDataandtrigger = () => {
    try {
      //  get selectet assessment type id
      let selectedAssessmentId = serviceMappingGetValues("Assessment_Type") || "";
      let selectedthirdpatytype = vendorDetailsGetValues("Type_Of_Vendor2") || "";
      //  find assement name based on selectet assessment type id

      let ThirdPartyTypeName = VendorTypeIILOV.find((item: any) => item.LovId === selectedthirdpatytype)?.Options || "";

      let finedAssessmentName: any =
        lovTable?.find(
          (lovItem: any) => lovItem.LovId == selectedAssessmentId && lovItem.Name === LovConfig.Assessment_Type
        )?.Options || "";
      //  set CurrentAdvanceWorkflow or basic work flow this is from application parameter => parameter Name
      const assessmenettypeWorkflow: any =
        finedAssessmentName == Assessment_Type.Advanced
          ? ThirdPartyTypeName === thirdPrtyType.vendor ? ApplicationParameterName.CurrentAdvanceWorkflowvob : ApplicationParameterName.CurrentAdvanceWorkflowcob
          : ThirdPartyTypeName === thirdPrtyType.vendor ? ApplicationParameterName.CurrentBasicWorkflowcob : ApplicationParameterName.CurrentBasicWorkflowcob ;

      if (finedAssessmentName && assessmenettypeWorkflow) {
        // find workflow code
        let finedWorkflowCode: any = applicationParameterData.find(
          (applicationParamitem) => applicationParamitem.Parameter_Name === assessmenettypeWorkflow
        )?.Parameter_Value;
        console.log(finedWorkflowCode , "finedWorkflowCode" , finedAssessmentName , ThirdPartyTypeName , assessmenettypeWorkflow);
        
        // find workflow used workflow code
        let finedWorkflow: any =
          workFlowsData && finedWorkflowCode
            ? workFlowsData?.find(
              (workflowitem) => workflowitem.WorkflowCode == finedWorkflowCode && workflowitem.IsActive == "Active"
            )
            : "";
        if (finedWorkflow && vendorDetailsSavereturnData) {
          let ModifedObjectWorkflow: any = {
            ...finedWorkflow,
            Vendor_Id: vendorDetailsSavereturnData?.VendorId,
            Vendor_Name: vendorDetailsSavereturnData?.VendorName
          }
          setselectedworkflowData(ModifedObjectWorkflow);
          setshowRolesandresponsiblity(true);
        } else {
          onClose();
        }
      } else {
        onClose();
      }
    } catch {
      onClose();
    }
  };

  const deleteDraftList = () => {
    let formData = {
      DraftId: isEditData?.DraftId,
      CreatedBy: user?.UserName,
    };
    dispatch(deleteSaveandDraft(formData));
  };

  useEffect(() => {
    if (isDraftAdded || isDraftUpdated) {
      onClose();
      dispatch(updateLoader(false));
    }
  }, [isDraftAdded, isDraftUpdated]);

  const moveToNextTab = () => {
    let vendorDetailsValues = vendorDetailsGetValues();
    let otherDetailsValues = otherDetailsGetValues();
    let serviceMappingValues = serviceMappingGetValues();
    dispatch(setTabs(active + 1));
  };

  const updateStatusOnFormData = (formData: any[]) => {
    return formData.map((i) => {
      return {
        ...i,
        Status: i.Status ? i.Status : lovActiveStatusId,
        UpdatedBy: user?.UserName,
        VendorId: isDraftData ? 0 : isEditData?.VendorDetails.Vendor_Id || 0,
      };
    });
  };

  const handleSubmitForm = (addToVendor = true) => {
    dispatch(updateLoader(true));

    const vendorDetailsValues: any = vendorDetailsGetValues();
    const attachmentsValues = attachmentsGetValues();
    const serviceMappingValues: any = serviceMappingGetValues();
    let otherDetailsValues = otherDetailsGetValues();

    const VendorDetails = {
      ...vendorDetailsValues,
      Assessment_TypeId: parseInt(serviceMappingValues?.Assessment_Type),
      Exclusion: serviceMappingValues?.Exclusion,
      Scope_Of_Service: serviceMappingValues?.Scope_Of_Service,
      Service_Owner: serviceMappingValues?.Service_Owner,
      isTriggerWorkflow: serviceMappingValues?.isTriggerWorkflow,
      SubIndustry_Id: vendorDetailsValues?.SubIndustry_Id?.toString(),
      Regulator_Id: vendorDetailsValues?.Regulator_Id || 0,
      Status: !edit ? vendorInActiveStatusId : isEditData?.VendorDetails?.Status,
      // UpdatedBy: user?.UserName,
      ApprovedBy: "",
    };

    const VendorAddress = updateStatusOnFormData(otherDetailsValues.address);
    const VendorSpocs = updateStatusOnFormData(otherDetailsValues.spocs);
    const VendorEscalations = updateStatusOnFormData(otherDetailsValues.escalations);


    console.log(vendorDetailsValues, "vendor", attachmentsValues, "attach", otherDetailsValues, "others", serviceMappingValues, "service", "handle submit");

    const VendorServiceMappings = serviceMappingValues.serviceMapping.map((i: any) => {
      return {
        ServiceMappingId: i.ServiceMappingId || 0,
        VendorId: isDraftData ? 0 : isEditData?.VendorDetails.Vendor_Id || 0,
        ServiceCatalogId: i.ServiceCatalogId,
        Status: lovActiveStatusId,
        UpdatedBy: user?.UserName,
      };
    });

    if (isEditData?.VendorServiceMappings.length) {
      const inActiveStatusId = lovTable.find(
        (item: any) => item.Name === LovConfig.Status && item.Description === "In Active"
      ).LovId;
      isEditData.VendorServiceMappings.map((item1: any) => {
        let result = VendorServiceMappings.find((i: any) => i.ServiceCatalogId === item1.ServiceCatalogId);
        if (!result) {
          {
            const { UpdatedOn, Status_Value, ...rest } = item1;
            VendorServiceMappings.push({ ...rest, UpdatedBy: user?.UserName, Status: inActiveStatusId });
          }
        }
      });
    }
    const selectedAttachments = attachmentsValues.attachments.filter((i: any) => i.selected);
    const VendorAttachments = selectedAttachments.map(
      ({ DocumentName, DocType, selected, ExpiryDate, ...keepAttrs }: any) => {
        return {
          VendorId: isDraftData ? 0 : isEditData?.VendorDetails.Vendor_Id || 0,
          Status: lovActiveStatusId,
          ExpiryDate: ExpiryDate?.$d || null,
          ...keepAttrs,
        };
      }
    );

    if (isEditData?.VendorAttachments.length) {
      const inActiveStatusId = lovTable.find(
        (item: any) => item.Name === LovConfig.Status && item.Options === "In Active"
      ).LovId;
      isEditData.VendorAttachments.map((item1: any) => {
        let result = VendorAttachments.find((i: any) => i.DocumentId === item1.DocumentId);
        if (!result) {
          let { AttachmentId, DocumentId, Url, IsMandatory, VendorId } = item1;
          VendorAttachments.push({
            AttachmentId,
            DocumentId,
            Url,
            IsMandatory,
            VendorId,
            UpdatedBy: user?.UserName,
            Status: inActiveStatusId,
          });
        }
      });
    }
    const formData = {
      VendorDetails,
      VendorAddress,
      VendorSpocs,
      VendorEscalations,
      VendorServiceMappings,
      VendorAttachments,
    };
    if (!addToVendor) {
      if (formData?.VendorDetails?.Jurisdiction) {
        const jurisdictionValue = locationTable.find(
          (item: any) => item.LocationTypeValue === "City" && item.Location_Id === formData.VendorDetails.Jurisdiction
        ).Location_Name;
        formData.VendorDetails.Jurisdiction_Value = jurisdictionValue;
      }
      if (formData?.VendorDetails?.Constitution) {
        const constitutionValue = lovTable.find(
          (item: any) => item.Name === "Company Constitution" && item.LovId === formData.VendorDetails.Constitution
        ).Options;
        formData.VendorDetails.Constitution_Value = constitutionValue;
      }
      delete formData.VendorDetails.UpdatedBy;
      formData.VendorDetails.Updated_By = user?.UserName;

      const draftData: any = {
        UserId: userLoginResponse?.UserId,
        ModuleName: "Vendor Master",
        Activity: isDraftData ? ToasterActivityConfig.editDraft : ToasterActivityConfig.createDraft,
        ScreenName: "Vendor Master",
        DraftData: JSON.stringify(formData),
        DraftStatus: true,
        CreatedBy: user?.UserName,
      };
      if (isDraftData) {
        draftData.DraftId = isEditData?.DraftId;
        draftData.UpdatedBy = user?.UserName;
        dispatch(updateSaveandDraft(draftData));
      } else {
        dispatch(addSaveandDraft(draftData));
      }
    } else {
      formData.VendorDetails.UpdatedBy = isAqmResponse ? formData.VendorDetails?.EmailId : user?.UserName;
      const reqObj = {
        ModuleName: isWorkFlow ? "Vendor Details" : isAqmResponse ? "Vendor Assessment" : "Vendor Master",
        Activity: isDraftData || !isEditData ? ToasterActivityConfig.create : ToasterActivityConfig.Edit,
        MenuId: currentPagePermissionData?.MenuId,
        Form_Id: 22,
        DataForDisplay: "",
        UserId: userLoginResponse?.UserId,
        UpdatedBy: user?.UserName,
        // ----this two field are using vendor add workflow or pending Action Screens -----
        Workflow_InstanceId: isWorkFlow ? isWorkFlow?.Workflow_InstanceId : 0,
        Process_InstanceId: isWorkFlow ? isWorkFlow?.Id : 0,
        ProjectId: isWorkFlow ? isWorkFlow?.ProjectId : 0,
        IsRenewal: isWorkFlow ? (isWorkFlow?.IsRenewal ? isWorkFlow?.IsRenewal : false) : false,

        ...formData,
      };

      let JsonDataForDisplay = JSON.stringify(MCDataForDisplay(reqObj));
      reqObj.DataForDisplay = JsonDataForDisplay;
      console.log(reqObj, "reqObj");

      if (isAqmResponse) {
        handleNext(formData);
      } else {
        dispatch(addVendorDetails(reqObj));
      }
    }
  };

  const handleTabNavigation = async (event: React.SyntheticEvent, targetTab?: number) => {
    if (targetTab !== undefined && targetTab < active) {
      dispatch(setTabs(targetTab));
    } else {
      if (targetTab === undefined || targetTab - active === 1) {
        if (active === 0) vendorDetailsHandleSubmit(moveToNextTab)(event);
        else if (active === 1) otherDetailsHandleSubmit(moveToNextTab)(event);
        else if (active === 2) serviceMappingHandleSubmit(moveToNextTab)(event);
      } else {
        let validTargetTab = 0;
        let breakLoop = false;
        for (let i = active; i < targetTab; i++) {
          if (breakLoop) break;
          else {
            validTargetTab = i;
            if (i === 0)
              await vendorDetailsHandleSubmit(
                () => { },
                () => {
                  breakLoop = true;
                }
              )();
            else if (i === 1)
              await otherDetailsHandleSubmit(
                () => { },
                () => {
                  breakLoop = true;
                }
              )();
            else if (i === 2)
              await serviceMappingHandleSubmit(
                () => { },
                () => {
                  breakLoop = true;
                }
              )();
            // else if (i === 3) await attachmentsHandleSubmit(()=>{},()=>{breakLoop=true})()
          }
        }
        if (!breakLoop) dispatch(setTabs(targetTab));
        else dispatch(setTabs(validTargetTab));
      }
    }
  };

  const tapSteps = [
    { name: "Third Party Details" },
    { name: "Other Details" },
    { name: "Service Catalog Mapping" },
    { name: "Documents" },
  ];
  const aqmResponseTapSteps = [{ name: "Vendor Details" }, { name: "Other Details" }, { name: "Documents" }];
  return (
    <>
      {!showRolesandresponsiblity ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            {!isDisableBreadCrums && (
              <CustomBreadcrumbs
                list={[
                  { value: 0, name: prePageName ? prePageName : "Third Party Master" },
                  { value: 1, name: `${edit ? "Edit" : view ? "View" : "Add New"}` },
                ]}
                onchange={closeForm}
              />
            )}
            {isWorkFlow && (
              <Box sx={{ width: "20", }}>
                <VendorHistory isEditData={isWorkFlow} />
              </Box>
            )}
          </Box>

          <Stack spacing={2}>
            {!isAqmResponse && (
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography variant="h6" color={"text.primary"}>
                  {edit ? "Update Third Party" : view ? "View Third Party" : "Add Third Party"}
                </Typography>
                {!isAqmResponse && !isWorkFlow && (isDraftData || !isEditData) && (
                  <CustomButton
                    variant="contained"
                    name={"Save as Draft"}
                    disabled={
                      !vendorDetailsIsDirty && !otherDetailsIsDirty && !serviceMappingIsDirty && !attachmentsIsDirty
                    }
                    onClick={() => {
                      handleSubmitForm(false);
                    }}
                    size="small"
                  />
                )}
              </Stack>
            )}

            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Box sx={{ width: "100%" }}>
                <CustomTabs
                  steps={!isAqmResponse ? tapSteps : aqmResponseTapSteps}
                handleTabNavigation={handleTabNavigation}
                />
              </Box>
            </Box>

            {active === 0 ? (
              <FormProvider {...vendorDetailsFormMethods}>
                <VendorDetails
                  view={view}
                  edit={edit}
                  isEditData={isEditData}
                  isDraftData={isDraftData}
                  SignzyDatas={SignzyDatas}
                  isAqmResponse={isAqmResponse}
                />
              </FormProvider>
            ) : null}

            {active === 1 ? (
              <FormProvider {...otherDetailsFormMethods}>
                <OtherDetails view={view} edit={edit} isEditData={isEditData} isAqmResponse={isAqmResponse} />
              </FormProvider>
            ) : null}

            {active === 2 && !isAqmResponse ? (
              <FormProvider {...serviceMappingFormMethods}>
                <ServiceCatalogMapping
                  view={view}
                  edit={edit}
                  isEditData={isEditData}
                  isWorkFlow={isWorkFlow}
                  isAqmResponse={isAqmResponse}
                />
              </FormProvider>
            ) : null}

            {active === 3 || (active === 2 && isAqmResponse) ? (
              <FormProvider {...attachmentsFormMethods}>
                <Attachments
                  view={view}
                  edit={edit}
                  isEditData={isEditData}
                  isAqmResponse={isAqmResponse}
                  lovActiveStatusId={lovActiveStatusId}
                  AttachmentTableValues={AttachmentTableData}
                />
              </FormProvider>
            ) : null}

            <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
              {!isAqmResponse && !view ? (
                <CustomButton type="reset" variant="outlined" name={"Cancel"} onClick={() => { setIsCancel(true) }} />
              ) : null}
              {active != 0 && (
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Previous"}
                  onClick={() => {
                    dispatch(setTabs(active - 1));
                  }}
                />
              )}
              {!(view && active === 3) && (
                <CustomButton
                  type="button"
                  variant="contained"
                  name={
                    !isAqmResponse && active === 3 ? "Submit" : isAqmResponse && active === 2 ? "Save and Next" : "Next"
                  }
                  onClick={
                    !isAqmResponse && active === 3
                      ? handleSubmitForm
                      : isAqmResponse && active === 2
                        ? attachmentsHandleSubmit(handleSubmitForm)
                        : handleTabNavigation
                  }
                />
              )}
            </Box>
          </Stack>
        </Box>
      ) : (
        <RolesandResponsibility
          prePageName={"Vendor Master"}
          isEditData={selectedworkflowData}
          onClose={() => onClose()}
          isFromVendormaster={true}
        />
      )}

      <ModalPopUp
        onDeleteMethod={() => onClose()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </>
  );
};
