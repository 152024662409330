import React, { useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  createMasterClause,
  updateClauseMasterDetailsAddandEdit,
  updateMasterClause,
} from "../../../store/contractManagement/slices/clauseMasterSlice";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { ACTIVE } from "../../../utils/constants";

export interface AddClauseTypeMaster {
  clauseType_Id?: number;
  clauseType_Name: string;
  isServiceMappingRequired: boolean;
  isSMEReviewApplicable: boolean;
  isActive: any;
  updated_By: string;
}

interface AddClauseTypeMasterProps {
  existingData?: AddClauseTypeMaster;
  isView?: boolean;
  isEdit?: boolean;
  onClose: () => void;
}

const AddClauseTypeMaster: React.FC<AddClauseTypeMasterProps> = ({ existingData, isEdit, isView, onClose }) => {
  const dispatch = useAppDispatchThunk();
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    clauseMasterDetailsData,
    isclauseMasterDetailsLoading,
    isclauseMasterDetailsAdded,
    isclauseMasterDetailsUpdated,
    isclauseMasterDetailsDeleted,
    ToasterMsg,
  }: any = contractManagementReducer.clauseMasterData;
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const initialData: AddClauseTypeMaster = {
    clauseType_Id: isEdit ? existingData?.clauseType_Id || 0 : 0,
    clauseType_Name: isEdit ? existingData?.clauseType_Name || "" : "",
    isServiceMappingRequired: isEdit ? existingData?.isServiceMappingRequired || false : false,
    isSMEReviewApplicable: isEdit ? existingData?.isSMEReviewApplicable || false : false,
    isActive: isEdit ? existingData?.isActive || ACTIVE : ACTIVE,
    updated_By: isEdit ? existingData?.updated_By || "" : "",
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    reset,
  } = useForm<AddClauseTypeMaster>({
    defaultValues: initialData,
    mode: "onChange",
  });

  const isClauseTypeActive = watch("isActive") === ACTIVE ? true : false;

  const handleSave = async (data: AddClauseTypeMaster) => {
    try {
      dispatch(updateLoader(true));

      let updatedFormData = {
        clauseType_Id: data?.clauseType_Id,
        clauseType_Name: data?.clauseType_Name,
        isActive: data?.isActive === ACTIVE ? true : false,
        isServiceMappingRequired: data?.isServiceMappingRequired,
        isSMEReviewApplicable: data?.isSMEReviewApplicable,
        updated_By: user?.UserName,
        Updated_On: new Date().toISOString(),
        actionTaken: "create",
        UserId: userLoginResponse?.UserId,
        moduleName: "Clause Type Master",
        activity: "create",
        createdBy: user?.UserName,
        // approved_By: user?.UserName,
        // approved_On: new Date().toISOString(),
        // remarks: "",
      };

      if (updatedFormData.clauseType_Id === 0) {
        await dispatch(createMasterClause(updatedFormData));
      } else {
        updatedFormData = { ...updatedFormData, actionTaken: "edit", activity: "edit" };
        await dispatch(updateMasterClause(updatedFormData));
      }
    } catch (error) {
      console.error("Error saving clause:", error);
      // dispatch(
      //   updateToaster({
      //     isToaster: true,
      //     toasterMsg: `Failed to save clause`,
      //     isTosterFailed: true,
      //   })
      // );
    } finally {
      dispatch(updateLoader(false));
    }
  };

  useEffect(() => {
    const handleFunc = () => {
      if (isclauseMasterDetailsAdded || isclauseMasterDetailsUpdated) {
        onClose();
      }
    };
    handleFunc();
  }, [isclauseMasterDetailsAdded, isclauseMasterDetailsUpdated]);

  return (
    <Box>
      <Grid container sx={{ display: "flex", gap: "10px" }}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="clauseType_Name"
            control={control}
            rules={{
              required: "Clause Type is required",
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value.trim()) || "Leading spaces are not allowed",
                minLength: (value) => value.trim().length >= 1 || "Minimum 1 character is required",
                maxLength: (value) => value.trim().length < 100 || "Maximum 100 characters allowed",
                ValueLimitation: (value) => {
                  const trimmedValue = value.trim();
                  if (!trimmedValue) return true;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(trimmedValue) || "Cannot contain special characters";
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                label={"Clause Type"}
                required
                readOnly={isView || !isClauseTypeActive}
                error={Boolean(errors.clauseType_Name)}
                helperText={errors.clauseType_Name?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="isServiceMappingRequired"
            control={control}
            render={({ field }) => (
              <Box sx={{ whiteSpace: "pre", marginTop: "10px" }}>
                <CustomToogleSwitch
                  {...field}
                  label={"Is Service Mapping Needed"}
                  readOnly={isView || !isClauseTypeActive}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errors.isServiceMappingRequired)}
                  helperText={errors.isServiceMappingRequired?.message?.toString()}
                />
              </Box>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="isSMEReviewApplicable"
            control={control}
            render={({ field }) => (
              <Box sx={{ whiteSpace: "pre", marginLeft: "10px", marginTop: "10px" }}>
                <CustomToogleSwitch
                  {...field}
                  label={"Is SME Review Applicable"}
                  required
                  readOnly={isView || !isClauseTypeActive}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errors.isSMEReviewApplicable)}
                  helperText={errors.isSMEReviewApplicable?.message?.toString()}
                />
              </Box>
            )}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} py={2}>
        <CustomButton
          variant="outlined"
          name={"Cancel"}
          size="small"
          onClick={() => {
            onClose();
          }}
        />
        <CustomButton
          variant="contained"
          name={isEdit ? "Update" : "Submit"}
          type="submit"
          size="small"
          disabled={isView || !isClauseTypeActive || !isDirty}
          onClick={handleSubmit(handleSave)}
        />
      </Stack>
    </Box>
  );
};

export default AddClauseTypeMaster;
