import { Box, Grid } from "@mui/material"
import CustomSvg from "../../../common/CustomSvg"
import PageInnerHeading from "../../../common/CommonHeadings/PageInnerHeading"
import { textFieldWidth } from "../../../utils/constants"
import LableandValuesPreview from "../../../common/CommonPreview/LableandValuesPreview"
import { useState } from "react"

interface CustomerDetailsSectionProps {
    isEditData:any
}
export const CustomerDetailsSection: React.FC<CustomerDetailsSectionProps> = ({
    isEditData,
  }) => {

    const [openCustomerDetails, setOpenCustomerDetails] = useState<boolean>(true);


    return(

        <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Box
              sx={{ cursor: "pointer", display: "flex", position: "relative", top: "-2px", mr: "5px" }}
              onClick={() => setOpenCustomerDetails((prev) => !prev)}
            >
              {openCustomerDetails ? (
                <CustomSvg size={"16px"} name={"chevron-down"} />
              ) : (
                <CustomSvg size={"16px"} name={"chevron-up"} />
              )}
            </Box>
            <PageInnerHeading title={`Customer Details`} />
          </Box>

          {openCustomerDetails ? (
            <Box>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid
                 item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <LableandValuesPreview lable="Assessment No" Value={`${isEditData?.Assessment_Number || '-'}`} />
                </Grid>
                <Grid
                      item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                      <LableandValuesPreview lable="Assessment Type I" Value={`${isEditData?.Assessment_Type_1 || '-'}`} />
                </Grid>
                <Grid
                      item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                           <LableandValuesPreview lable="Assessment Type II" Value={`${isEditData?.Assessment_Type_2 || '-'}`} />
                </Grid>
                <Grid
                      item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                                           <LableandValuesPreview lable="Workflow" Value={`${isEditData?.Workflow_Name || '-'}`} />

                </Grid>
                <Grid
                      item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                                           <LableandValuesPreview lable="Validity (In Month)" Value={`${isEditData?.Validity_in_Months || '-'}`} />

                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
    )
}