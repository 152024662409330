import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import CustomSvg from "../../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import { RootState } from "../../../store/store";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { UploadFiles } from "./UploadFiles";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import CustomRadioButton from "../../../common/dynamicInputs/CustomRadioButton";
import DocumentMasterService from "../../../store/services/documentMasterService";
import fileHandlerService from "../../../store/services/fileHandlerService";
import { useSelector } from "react-redux";
import { LovConfig } from "../../../config/config";
import * as mime from "react-native-mime-types";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import SelectDeselect from "../../../common/SelectDeselectComponent/SelectDeselect";

export const Attachments: React.FC<any> = ({ view, edit, isEditData, isAqmResponse, lovActiveStatusId, AttachmentTableValues }) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const [openUploadFileModel, setOpenUploadFileModel] = useState(false);
  const [openViewFileModel, setOpenViewFileModel] = useState(false);
  const [assessmentTypes, setAssessmentTypes] = useState<any[]>([]);
  const [assessmentTypeMapping, setAssessmentTypeMapping] = useState<any>(null);
  // const selectedAssesment = watch("Assessment_TypeId");
  // const selectedAssesment = serviceMappingFieldValues?.Assessment_Type;
  const attachments = watch("attachments");
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState<null | number>(null);
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const [fileName, setFileName] = useState(undefined);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [areAllSelected, setAreAllSelected] = useState<boolean | undefined>(false);
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);


  const handleAllSelected = (selected: boolean) => {
    setAreAllSelected(selected);
    fields.forEach((_: any, index: number) => {
      setValue(`attachments.${index}.selected`, selected);
    })
  };

  const { fields, replace }: any = useFieldArray({
    control,
    name: "attachments",
    rules: {
      validate: (i) => {
        if (!isAqmResponse) return undefined;
        const hasMandatoryDocSelected = i.some(
          (currentObject: any) => currentObject.IsMandatory && currentObject.selected && !currentObject.Url
        );
        if (!hasMandatoryDocSelected) {
          clearErrors("attachments");
          return undefined;
        } else return "File should be uploaded for mandatory documents";
      },
    },
  });

  const onButtonClick = async (icon: string, index: number) => {
    setSelectedAttachmentIndex(index);
    if (icon === "upload") setOpenUploadFileModel(true);
    else if (icon === "view") {
      let bloburl = getValues(`attachments.${index}.Url`);
      if (bloburl) {
        const fileName = bloburl.substring(bloburl.indexOf("_") + 1);
        const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
        const { data } = response;
        const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));
        setFileName(fileName);
        setFileUrl(`data:${mimeType};base64,${data}`);
        setOpenViewFileModel(true);
      }
    }
  };

  useEffect(() => {
    if (AttachmentTableValues) {
      replace(AttachmentTableValues);
    }
  }, [AttachmentTableValues]);


  useEffect(() => {
    if (fields.length > 0) {
      const allSelected = attachments.length > 0 && attachments.every((item: any) => item.selected);
      const anySelected = attachments.some((item: any) => item.selected);
      setIsIndeterminate(!allSelected && anySelected);
      setAreAllSelected(allSelected)
    }
  }, [JSON.stringify(attachments)]);

  return (
    <>
      <CustomDialog
        show={openUploadFileModel}
        onHide={() => setOpenUploadFileModel(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Upload File"}
        contentNode={
          <UploadFiles
            view={false}
            closePopUp={() => {
              setOpenUploadFileModel(false);
            }}
            edit={false}
            isEditData={{}}
            fileFormats={fields[selectedAttachmentIndex as number]?.DocType}
            onSubmit={(url: string) => {
              setValue(`attachments.${selectedAttachmentIndex}.Url`, url);
              setOpenUploadFileModel(false);
            }}
          />
        }
      />
      <CustomDialog
        onHide={() => setOpenViewFileModel(false)}
        show={openViewFileModel}
        header={"Document Viewer"}
        contentNode={
          fileUrl && <DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: fileUrl, fileName }]} />
        }
      />

      <Box className={BasicTableStyle.tab_outer} sx={{ width: "85%", my: 2, mx: "auto !important" }}>
        <Table className={BasicTableStyle.tab_outer} sx={{ maxWidth: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell className={BasicTableStyle.tab_header} width={"10%"}>
                                Select
                            </TableCell> */}
              <TableCell className={BasicTableStyle.tab_header} width={"10%"}>
                {/* <Box sx={{ ml: 0.8 }}> */}
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Box sx={{ ml: 2 }}>
                    <SelectDeselect readOnly={view || isAqmResponse} isAllSelected={handleAllSelected} indeterminate={isIndeterminate} value={areAllSelected} />
                  </Box>
                </Box>
              </TableCell>

              <TableCell className={BasicTableStyle.tab_header} width={"15%"}>
                Document
              </TableCell>
              <TableCell className={BasicTableStyle.tab_header} width={"15%"}>
                Type
              </TableCell>
              <TableCell className={BasicTableStyle.tab_header} width={"13%"}>
                Is Mandatory
              </TableCell>
              <TableCell className={BasicTableStyle.tab_header} width={"13%"}>
                Is Perpetual
              </TableCell>
              <TableCell className={BasicTableStyle.tab_header} width={"15%"}>
                Attachment
              </TableCell>
              <TableCell className={BasicTableStyle.tab_header} width={"19%"}>
                Expiry Date
              </TableCell>
            </TableRow>
            {fields.map((item: any, index: any) => {
              return (
                <TableRow>
                  <TableCell sx={{ textAlign: 'center !important' }} className={BasicTableStyle.tab_data}>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box sx={{ ml: 0.8 }}>
                        <Controller
                          name={`attachments.${index}.selected`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <CustomCheckBox
                                value={field.value}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  field.onChange(isChecked);
                                  setIsIndeterminate(true);
                                }}
                                readOnly={view || isAqmResponse}
                              />
                              {/* <CustomCheckBox
                             value={field.value}
                             onChange={field.onChange}
                             disabled={view || isAqmResponse}
                           /> */}
                            </>
                          )}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={BasicTableStyle.tab_data} sx={{ textAlign: 'center' }}>{item.DocumentName}</TableCell>
                  <TableCell className={BasicTableStyle.tab_data} sx={{ textAlign: 'center' }}>{item.DocType.toString()}</TableCell>
                  <TableCell className={BasicTableStyle.tab_data}>
                    {watch(`attachments.${index}.selected`) ? (
                      <Box sx={{ ml: "40%" }}>
                        <Controller
                          name={`attachments.${index}.IsMandatory`}
                          control={control}
                          render={({ field }) => (
                            <CustomToogleSwitch
                              label={""}
                              disabled={view || isAqmResponse}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </Box>
                    ) : (
                      <Box sx={{ ml: "40%" }}>

                        <CustomToogleSwitch
                          label={""}
                          readOnly={true}
                          value={false}
                        // onChange={field.onChange}
                        />

                      </Box>
                    )}
                  </TableCell>
                  <TableCell className={BasicTableStyle.tab_data}>
                    <Box sx={{ ml: "40%" }}>
                      <Controller
                        name={`attachments.${index}.IsPerpetual`}
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={""}
                            disabled={view || isAqmResponse}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </TableCell>
                  <TableCell className={BasicTableStyle.tab_data}>
                    <Box sx={{ ml: "10%" }}>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        variant="caption"
                        onClick={() => (view ? null : onButtonClick("upload", index))}
                      >
                        <Tooltip title="Upload">
                          <IconButton disabled={view}>
                            <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"upload"} />{" "}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        variant="caption"
                        onClick={() => onButtonClick("view", index)}
                      >
                        <Tooltip title="View">
                          <IconButton disabled={watch(`attachments.${index}.Url`) ? false : true}>
                            <CustomSvg
                              color={watch(`attachments.${index}.Url`) ? `${theme.palette.info.main}` : theme.palette.text.secondary}
                              size={"20px"}
                              name={"view"}
                            />{" "}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        variant="caption"
                        onClick={() => (view ? null : setValue(`attachments.${index}.Url`, null))}
                      >
                        <Tooltip title="Delete">
                          <IconButton disabled={view ? true : watch(`attachments.${index}.Url`) ? false : true}>
                            <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"trash"} />{" "}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={BasicTableStyle.tab_data}>
                    <Box>
                      {!watch(`attachments.${index}.IsPerpetual`) && watch(`attachments.${index}.selected`) && (
                        <Controller
                          name={`attachments.${index}.ExpiryDate`}
                          control={control}
                          defaultValue={null}
                          rules={{ required: "Expiry Date is Required" }}
                          render={({ field }) => (
                            <CustomDatePicker
                              readOnly={view}
                              minDate={dayjs().add(1, "day").toDate()}
                              maxDate={dayjs().add(100, "year").toDate()}
                              label={"Expiry Date"}
                              value={field.value ? dayjs(field.value) : null}
                              required={isAqmResponse}
                              onChange={field.onChange}
                              error={Boolean(
                                (errors.attachments?.[index as keyof typeof errors.attachments] as any)?.ExpiryDate
                                  ?.message
                              )}
                              helperText={(
                                errors.attachments?.[index as keyof typeof errors.attachments] as any
                              )?.ExpiryDate?.message.toString()}
                            />
                          )}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableHead>
        </Table>
      </Box>
      {errors.attachments?.root?.message && (
        <Typography variant="subtitle1" sx={{ color: "#D70000", textAlign: "left" }}>
          {errors.attachments?.root?.message as any}
        </Typography>
      )}
    </>
  );
};
