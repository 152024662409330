import { AxiosError, AxiosResponse } from "axios";
import { axiosProbeInstance } from "./config/axiosConfig";
import { PROBE_URL } from "./endpoints/probeAPI";


const getCompanyBase_Details = (requestBody: any): Promise<any> =>
    axiosProbeInstance
        .get(`${requestBody.ID}/${PROBE_URL.Comprehensive_Details}`, {
            params: {
                identifier_type: requestBody.identifier_type,
            }
        })
        // .get(PROBE_URL.base_Details, { params: requestBody })
        .then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });
const getCompanyComprehensive_Details = (requestBody: any): Promise<any> =>

    axiosProbeInstance
        .get(`${requestBody.ID}/${PROBE_URL.Comprehensive_Details}`, {
            params: {
                identifier_type: requestBody.identifier_type,
            }
        })

        .then((response: AxiosResponse<any>) => {
            console.log(requestBody, "requestBody");

            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })

        .catch((error: AxiosError | string) => {
            console.log(error, "error");
            
            throw error;
        });

        console.log(getCompanyComprehensive_Details)

const ProbeService = {
    getCompanyBase_Details,
    getCompanyComprehensive_Details,
};

export default ProbeService;
