import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatchThunk } from "../../store/store";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import {
  getMakerCheckerApprovalById,
  getMakerCheckerStatus,
  getPendingApprovalList,
  updateApproveorRejectFlag,
} from "../../store/slices/pendingApprovals";
import { IPendingApproval } from "../../models/pendingApproval.type";
import ApproveRejectPendingRecord from "../approveRejectPendingRecord/ApproveRejectPendingRecord";
import { updateToaster } from "../../store/slices/loader";
import { IMenu } from "../../models/loggin.type";

const SamPendingApproval: React.FC = () => {
  const [view, setView] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [selectedPendingApprovalRecord, setSelectedPendingApprovalRecord] = useState<any>();
  const [selectedPendingApprovalRecordData, setSelectedPendingApprovalRecordData] = useState<any>();

  const { user, menuData, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { pendingApprovalRecords, pendingApprovalByID, isApprovedorReject, ApproveorRejectReq } = useSelector(
    (state: RootState) => state.pendingApproval
  );
  const dispatch = useAppDispatchThunk();
  const [pendingApprovalRecorData, setPendingApprovalRecorData] = useState<IPendingApproval[]>([]);

  const columns: any = {
    table_name: "Sam Pending Approval",
    isAction: true,
    columns: [
      {
        Field: "ModuleName",
        DisplayName: "ModuleName",
        FieldType: "string",
      },
      {
        Field: "Name",
        DisplayName: "Primary Name",
        FieldType: "string",
      },

      {
        Field: "Action",
        DisplayName: "Activity",
        FieldType: "string",
      },
      {
        Field: "Status",
        DisplayName: "Status",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    if (
      menuData &&
      menuData.length > 0 &&
      menuData.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Configure Settings").length > 0
    ) {
      let menuPage = menuData.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Configure Settings")[0];
      if (
        menuPage.SubMenus &&
        menuPage.SubMenus.length > 0 &&
        menuPage.SubMenus.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Pending Approval").length >
          0
      ) {
        setPermissionForpage(
          menuPage.SubMenus.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Pending Approval")[0]
        );
      }
    }
  }, [menuData]);

  const handleOpen = (data: IPendingApproval, Option: string) => {
    setSelectedPendingApprovalRecord(data);
    dispatch(
      getMakerCheckerApprovalById({
        TempId: data.TempId,
        Id: data.Id,
        ModuleId: data.ModuleId,
      })
    );
  };

  useEffect(() => {
    dispatch(getPendingApprovalList({ category: "user" }));
    dispatch(getMakerCheckerStatus({ Name: "MakerChecker" }));
  }, []);

  useEffect(() => {
    setPendingApprovalRecorData(pendingApprovalRecords);
  }, [pendingApprovalRecords]);

  useEffect(() => {
    if (
      pendingApprovalByID &&
      (pendingApprovalByID?.NewValues.length > 0 || pendingApprovalByID?.OldValues.length > 0)
    ) {
      setSelectedPendingApprovalRecordData(pendingApprovalByID);
      setView(true);
    }
  }, [pendingApprovalByID]);

  useEffect(() => {
    if (isApprovedorReject) {
      dispatch(getPendingApprovalList({ category: "master" }));
      dispatch(updateApproveorRejectFlag(false));
      if (ApproveorRejectReq.Status === 1) {
        dispatch(updateToaster({ isToaster: true, toasterMsg: "Item Approved Successfully" }));
      } else {
        dispatch(updateToaster({ isToaster: true, toasterMsg: "Item Rejected Successfully" }));
      }
    }
  }, [isApprovedorReject]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              Pending Approvals
            </Typography>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={pendingApprovalRecorData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                showhistory={false}
                showAdd={false}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Pending Approval"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={view}
        onHide={() => {
          setView(false);
          dispatch(updateApproveorRejectFlag(false));
        }}
        maxWidth={"md"}
        minHeight={"50vh"}
        header={
          selectedPendingApprovalRecordData && selectedPendingApprovalRecordData.OldValues.length > 0
            ? "Pending Approval Update"
            : "Pending Approval Create"
        }
        contentNode={
          <ApproveRejectPendingRecord
            closePopUp={() => {
              dispatch(updateApproveorRejectFlag(false));
              setView(false);
            }}
            selectedGridRecord={selectedPendingApprovalRecord}
            selectedRecordDetails={selectedPendingApprovalRecordData}
          />
        }
      />
    </>
  );
};

export default SamPendingApproval;
