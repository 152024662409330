import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CustomAccordion from "../../../dynamicAccordion/CustomAccordion";
import CustomCheckBox from "../../../dynamicInputs/CustomCheckBox";

interface ReviewSectionProps {
  expanded: string | false;
  sectionType: "toBeReviewed" | "accepted";
  items: any[];
  onToggle: (item: any, index: number, type: "toBeReviewed" | "accepted") => void;
  onExpand: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onAcceptAll?: () => void;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({
  expanded,
  sectionType,
  items,
  onToggle,
  onExpand,
  onAcceptAll,
}) => {
  const title = sectionType === "toBeReviewed" ? "To Be Reviewed" : "Accepted";

  return (
    <Box>
      <CustomAccordion
        expanded={expanded === sectionType}
        handleChange={onExpand(sectionType)}
        title={title}
        details={
          <>
            <Box sx={{ width: sectionType === "toBeReviewed" ? "calc(100vw - 80px)" : "100%" }}>
              <Stack spacing={2}>
                {sectionType === "toBeReviewed" && items.length > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Typography
                      onClick={onAcceptAll}
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Accept All
                    </Typography>
                  </Box>
                )}

                {items.length > 0 ? (
                  items.map((item: any, index: number) => (
                    <Box
                      key={item.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: "5px",
                        border: "1px solid #ddd",
                        p: 1,
                      }}
                    >
                      <Box>
                        <Typography variant="body2" fontWeight="bold">
                          {item.header}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Finance
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Typography>Reviewed</Typography>
                        <CustomCheckBox
                          isStyle={true}
                          alignItems="center"
                          label=""
                          onChange={() => onToggle(item, index, sectionType)}
                          value={item.isSelected || false}
                        />
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      border: "1px solid #ddd",
                      p: 1,
                    }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      No Records Found
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Box>
          </>
        }
      />
    </Box>
  );
};

export default ReviewSection;
