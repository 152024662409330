import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddDepartmentResponse, IDepartmentResponse, IVHColumnRes, Idepartment } from "../../models/department.type";
import { DEPARTMENT_URL } from "./endpoints/department";

const getDepartmentList = (reqparams: any): Promise<IDepartmentResponse> =>
  axiosInstance
    .get(DEPARTMENT_URL.GetAllDepartment)
    .then((response: AxiosResponse<IDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getParentDepartmentList = (reqparams: any): Promise<IDepartmentResponse> =>
axiosInstance
  .get(DEPARTMENT_URL.GetParentDepartment,{params: reqparams})
  .then((response: AxiosResponse<IDepartmentResponse>) => {
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });

const updateDepartment = (requestBody: any): Promise<IAddDepartmentResponse> =>
  axiosInstance
    .post(DEPARTMENT_URL.UpdateDepartment, requestBody)
    .then((response: AxiosResponse<IAddDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addDepartment = (requestBody: any): Promise<IAddDepartmentResponse> =>
  axiosInstance
    .post(DEPARTMENT_URL.AddDepartment, requestBody)
    .then((response: AxiosResponse<IAddDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteDepartment = (requestBody: any): Promise<IAddDepartmentResponse> =>
  axiosInstance
    .post(DEPARTMENT_URL.DeleteDepartment, requestBody)
    .then((response: AxiosResponse<IAddDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryDepartment = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(DEPARTMENT_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const departmentService = {
  getDepartmentList,
  updateDepartment,
  addDepartment,
  deleteDepartment,
  getVersionHistoryDepartment,
  getParentDepartmentList
};

export default departmentService;
