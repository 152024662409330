import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import ProgressBar from "../../common/progressBar/progressBar";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import AddResponse from "./AddResponse";

export interface userFormData {
  attributeName: string;
}

const IrRiskMaster: React.FC = () => {
  const dispatch = useAppDispatchThunk();
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const columns: any = {
    table_name: "Attribute Master",
    isAction: true,
    columns: [
      {
        Field: "response",
        DisplayName: "Response",
        FieldType: "string",
      },
      {
        Field: "response_ord",
        DisplayName: "Response_Order",
        FieldType: "string",
      },
      {
        Field: "score",
        DisplayName: "Score",
        FieldType: "number",
      },
      
    ],
  };
  let formData: userFormData = {
    attributeName: "",
  };
  const {
    handleSubmit: handleUserForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<userFormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const handleSubmit = () => {
    console.log(getValues());
  };
  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
      {loader ? <ProgressBar showProgress={loader} /> : null}
      <Box>
        <Typography variant="h4"> IR Attribute Master</Typography>
      </Box>

      <Grid
        sx={{
          width: "100%",
          py: 4,
        }}
      >
        <Box
          sx={{
            px: {
              xs: 2,
              sm: 4,
              md: 2,
              lg: 2,
              xl: 2,
            },

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={1}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
              >
                <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                  Attribute Name
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
              >
                <Controller
                  name="attributeName"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "AttributeName Name is Required",
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"attributeName"}
                      required={true}
                      label={""}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.attributeName)}
                      helperText={errorsloginform.attributeName && errorsloginform.attributeName.message?.toString()}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column", py: 2 }}>
                {/* <DevExpDynamicGrid
                  data={columns}
                  rows={usersData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  uniqueIdKey="id"
                  hideVersionhistory={true}
                /> */}

                <MaterialDynamicGrid
                  data={columns}
                  rows={data}
                  //handleAction={handleOpen}
                  enableZoomIcon={false}
                  showhistory={false}
                  showDelete={false}
                  showView={false}
                  showEdit={false}
                  showExports={false}
                  showAdd={true}
                  handleAdd={() => setOpenAddForm(true)}
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                  pageName="Attribute Master"
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                mt: 2,
              }}
            >
              <React.Fragment>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    console.log("data");
                  }}
                />
                <CustomButton type="reset" name={"Submit"} onClick={handleUserForm(handleSubmit)} />
              </React.Fragment>
            </Box>
          </Box>

          <CustomDialog
            show={openAddForm}
            onHide={() => {
              setOpenAddForm(false);
            }}
            maxWidth={"lg"}
            minHeight={"50vh"}
            header={"Add Response"}
            contentNode={
              <>
                <AddResponse
                  closePopUp={() => {
                    setOpenAddForm(false);
                  }}

                  addData={(newdata:any)=>{
                    setData([...data,newdata])
                    setOpenAddForm(false);
                  }}
                />
              </>
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default IrRiskMaster;
