import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { BULKUPLOAD_URL } from "./endpoints/bulkuploadMaster";



const addBulkUploadDepartment = (requestBody: any ): Promise<any> => 

        axiosInstance.post(BULKUPLOAD_URL.AddDepartment, requestBody)
        .then((response: AxiosResponse<any>) => {
            console.log(response);
            
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });

 const addBulkUploadLocation = (requestBody: any ): Promise<any> => 

          axiosInstance.post(BULKUPLOAD_URL.AddLocation, requestBody)
          .then((response: AxiosResponse<any>) => {
              console.log(response);
              
            if (response.status === 200) {
              return response.data;
            }
            throw new Error("Error getting Data" + response);
          })
          .catch((error: AxiosError | string) => {
            throw error;
          });
        
          const addBulkUploadThirdParty = (requestBody: any ): Promise<any> => 

            axiosInstance.post(BULKUPLOAD_URL.AddThirdParty, requestBody)
            .then((response: AxiosResponse<any>) => {
                console.log(response);
                
              if (response.status === 200) {
                return response.data;
              }
              throw new Error("Error getting Data" + response);
            })
            .catch((error: AxiosError | string) => {
              throw error;
            });
            const addRegionalSetting = (requestBody: any ): Promise<any> => 

              axiosInstance.post(BULKUPLOAD_URL.AddregionalSettings, requestBody)
              .then((response: AxiosResponse<any>) => {
                  console.log(response);
                  
                if (response.status === 200) {
                  return response.data;
                }
                throw new Error("Error getting Data" + response);
              })
              .catch((error: AxiosError | string) => {
                throw error;
              });
              const addCurrency = (requestBody: any ): Promise<any> => 

                axiosInstance.post(BULKUPLOAD_URL.AddCurrency, requestBody)
                .then((response: AxiosResponse<any>) => {
                    console.log(response);
                    
                  if (response.status === 200) {
                    return response.data;
                  }
                  throw new Error("Error getting Data" + response);
                })
                .catch((error: AxiosError | string) => {
                  throw error;
                });
                const addIndustory = (requestBody: any ): Promise<any> => 

                  axiosInstance.post(BULKUPLOAD_URL.addIndustory, requestBody)
                  .then((response: AxiosResponse<any>) => {
                      console.log(response);
                      
                    if (response.status === 200) {
                      return response.data;
                    }
                    throw new Error("Error getting Data" + response);
                  })
                  .catch((error: AxiosError | string) => {
                    throw error;
                  });

                  const addDocument = (requestBody: any ): Promise<any> => 

                    axiosInstance.post(BULKUPLOAD_URL.addDocument, requestBody)
                    .then((response: AxiosResponse<any>) => {
                        console.log(response);
                        
                      if (response.status === 200) {
                        return response.data;
                      }
                      throw new Error("Error getting Data" + response);
                    })
                    .catch((error: AxiosError | string) => {
                      throw error;
                    });

                    const addBranch = (requestBody: any ): Promise<any> => 

                      axiosInstance.post(BULKUPLOAD_URL.addBranch, requestBody)
                      .then((response: AxiosResponse<any>) => {
                          console.log(response);
                          
                        if (response.status === 200) {
                          return response.data;
                        }
                        throw new Error("Error getting Data" + response);
                      })
                      .catch((error: AxiosError | string) => {
                        throw error;
                      });

                      const addAssessmentQuestions = (requestBody: any ): Promise<any> => 

                        axiosInstance.post(BULKUPLOAD_URL.assessmentQuestions, requestBody)
                        .then((response: AxiosResponse<any>) => {
                            console.log(response);
                            
                          if (response.status === 200) {
                            return response.data;
                          }
                          throw new Error("Error getting Data" + response);
                        })
                        .catch((error: AxiosError | string) => {
                          throw error;
                        });
                
                  
const BulkUploadService = {
  addBulkUploadDepartment,
  addBulkUploadLocation,
  addBulkUploadThirdParty,
  addRegionalSetting,
  addCurrency,
  addIndustory,
  addDocument,
  addBranch,
  addAssessmentQuestions
};

export default BulkUploadService;
