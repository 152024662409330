import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationSettings } from "../../models/notification.type";
import notificationService from "../services/notificationService";

export interface INotificationData {
  notifications: INotificationSettings[];
  isLoading: boolean;
  isNotificationAdded: boolean;
  isNotificationUpdated: boolean;
}

export const initialState: INotificationData = {
  notifications: [],
  isLoading: false,
  isNotificationAdded: false,
  isNotificationUpdated: false,
};

export const getNotifications = createAsyncThunk("getNotifications", async (userId: number, { rejectWithValue }) => {
  try {
    const response = await notificationService.getNotification(userId);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUnread = createAsyncThunk("updateUnread", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await notificationService.updateUnread(requestBody);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateNotificationStatus = createAsyncThunk("updateNotificationStatus", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response = await notificationService.updateNotificationStatus(requestBody);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const notifications = createSlice({
  name: "notificationData",
  initialState,
  reducers: {
    updateNotificationRecord: (state, action: PayloadAction<INotificationSettings[]>) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.notifications = action.payload;
        state.isLoading = false;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      // .addCase(addNotification.pending, (state) => {
      //     state.isLoading = true;
      // })
      // .addCase(addNotification.fulfilled, (state) => {
      //     state.isNotificationAdded = true;
      //     state.isLoading = false;
      // })
      // .addCase(addNotification.rejected, (state) => {
      //     state.isLoading = false;
      // })
      .addCase(updateNotificationStatus.pending, (state) => {
          state.isLoading = true;
      })
      .addCase(updateNotificationStatus.fulfilled, (state) => {
          state.isNotificationUpdated = true;
          state.isLoading = false;
      })
      .addCase(updateNotificationStatus.rejected, (state) => {
          state.isLoading = false;
      })
      .addCase(updateUnread.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUnread.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUnread.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateNotificationRecord } = notifications.actions;
export default notifications.reducer;
