export const WORKFLOW_URL={
    ListOfValues: `/getListOfValues`,
    CreateWorkFlow: `/createWorkflow`,
    WorkFlowList: `/workflowList`,
    UpdateWorkFlow: `/updateWorkflow`,
    TriggerWorkFlow: '/TrigerWorkflow',
    UpdateWorkFlowStep: `/updateProcessStatus`,
    GetAllWorkflowInstances:`/getAllWorkflowInstances`,
    GetWorkflowInstanceByCode:`/getWorkflowInstanceByCode`,
    GetWorkflowConditions:`/getWorkflowConditions`,
    TriggerWorkFlowInstance:`/getWorkflowInstanceById`,
    TriggerWorkFlowInstancebyVendorId:`/getWorkflowInstanceByVendor`,
    DeleteworkflowbyId:`/deleteworkflowbyId`,
    GetWorkflowByCode:`/getWorkflowByCode`
}