// const generateContent = (clause: any, parentIndex: string, idx: number) => {
//   let dataContent: string[] | null = clause.clause?.match(
//     /<p\b[^>]*>.*?<\/p>|<span\b[^>]*>.*?<\/span>|<strong\b[^>]*>.*?<\/strong>|<em\b[^>]*>.*?<\/em>|<u\b[^>]*>.*?<\/u>/g
//   );

//   if (dataContent) {
//     dataContent = dataContent.map((content: string) => {
//       content = content.replace(/>(\d+)\./g, (match: string, num: any) => `>${parentIndex}.${num} `);

//       if (content.includes("<table")) {
//         content = content.replace(
//           /<table\b[^>]*>/g,
//           '<table style="border-collapse: collapse; width: 100%; border: 1px solid black;">'
//         );
//         content = content.replace(
//           /<th\b[^>]*>/g,
//           '<th style="border: 1px solid black; padding: 8px; text-align: left;">'
//         );
//         content = content.replace(
//           /<td\b[^>]*>/g,
//           '<td style="border: 1px solid black; padding: 8px; text-align: left;">'
//         );
//       }
//       return content;
//     });

//     if (clause.subClauses && clause.subClauses.length > 0) {
//       const subClauseHtml = clause.subClauses
//         .map((subClause: any, subIndex: number) => {
//           const subClauseIndex = `${parentIndex}.${subIndex + 1}`;
//           return `
//             <div style="margin-left: 20px;">
//               <h4 style="font-weight: bold; font-size: 14px;">
//                 <span>${subClauseIndex}. ${subClause.clause_Contract_Header}</span>
//               </h4>
//               <p>${subClause.clause.replace(/>(\d+)\./g, (match: string, num: any) => `>${subClauseIndex}.${num}`)}</p>
//             </div>`;
//         })
//         .join("");

//       dataContent.push(subClauseHtml);
//     }

//     const htmlContent = `<div class="clause-content" style="margin-left: 20px;">${dataContent.join("")}</div>`;
//     return htmlContent;
//   }
//   return "";
// };
const generateContent = (clause: any, parentIndex: string, idx: number) => {
  let dataContent: string[] | null = clause.clause?.match(
    /<p\b[^>]*>.*?<\/p>|<span\b[^>]*>.*?<\/span>|<strong\b[^>]*>.*?<\/strong>|<em\b[^>]*>.*?<\/em>|<u\b[^>]*>.*?<\/u>|<a\b[^>]*>.*?<\/a>|<br\s*\/?>|<img\b[^>]*\/?>|<table\b[^>]*>.*?<\/table>/g
  );

  if (dataContent) {
    dataContent = dataContent.map((content: string) => {
      content = content.replace(/>(\d+)\./g, (match: string, num: any) => `>${parentIndex}.${num} `);

      // Apply styles to tables
      if (content.includes("<table")) {
        content = content.replace(
          /<table\b[^>]*>/g,
          '<table style="border-collapse: collapse; width: 100%; border: 1px solid black;">'
        );
        content = content.replace(
          /<th\b[^>]*>/g,
          '<th style="border: 1px solid black; padding: 8px; text-align: left;">'
        );
        content = content.replace(
          /<td\b[^>]*>/g,
          '<td style="border: 1px solid black; padding: 8px; text-align: left;">'
        );
      }

      if (content.includes("<img")) {
        content = content.replace(
            /<img\b([^>]*)(?:\s+width="[^"]*")?([^>]*)\/?>/g,
            '<img style="max-width: 200px; height: auto;" $1$2 />'
        );
    }
      return content;
    });

    if (clause.subClauses && clause.subClauses.length > 0) {
      const subClauseHtml = clause.subClauses
        .map((subClause: any, subIndex: number) => {
          const subClauseIndex = `${parentIndex}.${subIndex + 1}`;
          const headerHtml = subClause.clause_Contract_Header
            ? `<h4 style="font-weight: bold; font-size: 14px;">
                 <span>${subClauseIndex}. ${subClause.clause_Contract_Header}</span>
               </h4>`
            : "";

          let subClauseDataContent: string[] | null = subClause.clause?.match(
            /<p\b[^>]*>.*?<\/p>|<span\b[^>]*>.*?<\/span>|<strong\b[^>]*>.*?<\/strong>|<em\b[^>]*>.*?<\/em>|<u\b[^>]*>.*?<\/u>|<a\b[^>]*>.*?<\/a>|<br\s*\/?>|<img\b[^>]*\/?>|<table\b[^>]*>.*?<\/table>/g
          );

          if (subClauseDataContent) {
            subClauseDataContent = subClauseDataContent.map((content: string) => {
              content = content.replace(/>(\d+)\./g, (match: string, num: any) => `>${subClauseIndex}.${num}`);
              if (content.includes("<img")) {
                content = content.replace(
                  /<img\b([^>]*)\/?>/g,
                  '<img style="width: 100%; max-width: 200px; height: auto;" $1 />'
                );
              }
              return content;
            });
          }

          return `
            <div style="margin-left: 20px;" id="clause-${subClause.clause_Id}">
              ${headerHtml}
              <div class="sub-clause-content" style="text-align: justify;">
                ${subClauseDataContent ? subClauseDataContent.join("") : ""}
              </div>
            </div>`;
        })
        .join("");

      dataContent.push(subClauseHtml);
    }

    const htmlContent = `
      <div class="clause-content" style="margin-left: 20px;">
        ${dataContent.join("")}
      </div>`;
    return htmlContent;
  }
  return "";
};


// export const generateClauseHTML = (clauses: any, parentIndex = ""): string => {
//   let clauseData = "<div>";
//   clauses.forEach((clause: any, index: number) => {
//     console.log(clause, "clauseclause");
//     const currentIndex = parentIndex ? `${parentIndex}.${index + 1}` : `${index + 1}`;
//     const isMainClause = !parentIndex;
//     const contentHtml = generateContent(clause, currentIndex, index);

//     if (isMainClause) {
//       clauseData += `
//         <div style="margin-bottom: 20px;">
//           <h2 style="font-weight: bold; font-size: 18px;">
//             ${currentIndex}. <span style="text-decoration: underline;">${clause.clause_Header}</span>
//           </h2>
//           ${contentHtml}
//         </div>`;
//     } else {
//       clauseData += `
//         <div style="margin-left: 40px; margin-bottom: 15px;">
//           <h3 style="font-weight: bold; font-size: 16px;">
//             ${currentIndex}. ${clause.clause_Header}
//           </h3>
//           ${contentHtml}
//         </div>`;
//     }

//     if (clause.children && clause.children.length > 0) {
//       const childHtml = generateClauseHTML(clause.children, currentIndex);
//       clauseData += `<div style="${isMainClause ? "" : "margin-left: 40px;"}">${childHtml}</div>`;
//     }
//   });

//   clauseData += "</div>";
//   return clauseData;
// };
export const generateClauseHTML = (clauses: any, parentIndex = ""): string => {
  let clauseData = "<div>";
  clauses.forEach((clause: any, index: number) => {
    const currentIndex = parentIndex ? `${parentIndex}.${index + 1}` : `${index + 1}`;
    const isMainClause = !parentIndex;
    const contentHtml = generateContent(clause, currentIndex, index);

    if (isMainClause) {
      clauseData += `
        <div style="margin-bottom: 20px;" id="clause-${clause.clause_Id}">
          <h2 style="font-weight: bold; font-size: 18px;">
            ${currentIndex}. <span style="text-decoration: underline;">${clause.clause_Header}</span>
          </h2>
          ${contentHtml}
        </div>`;
    } else {
      clauseData += `
        <div style="margin-left: 40px; margin-bottom: 15px;" id="clause-${clause.clause_Id}">
          <h3 style="font-weight: bold; font-size: 16px;">
            ${currentIndex}. ${clause.clause_Header}
          </h3>
          ${contentHtml}
        </div>`;
    }

    if (clause.children && clause.children.length > 0) {
      const childHtml = generateClauseHTML(clause.children, currentIndex);
      clauseData += `<div style="${isMainClause ? "" : "margin-left: 40px;"}">${childHtml}</div>`;
    }
  });

  clauseData += "</div>";
  console.log(clauseData, "clauseData~clauseData");
  return clauseData;
};
