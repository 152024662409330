import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import CustomTextField from "../dynamicInputs/CustomTextField";
import CustomButton from "../dynamicButton/CustomButton";
import CustomCheckBox from "../dynamicInputs/CustomCheckBox";
import CustomSelect from "../dynamicInputs/CustomSelect";

import { RootState, useAppDispatch } from "../../store/store";
import { addCustomField } from "../../store/slices/customFormFields";

import { generateFormFieldFormData } from "./types";
import { FIELD_TYPE_OPTIONS, FIELD_WIDTH, FIELD_TYPES, LOOKUP_OPTIONS } from "./constants";

import { addFormFieldProps } from "./types";

const AddForm: React.FC<addFormFieldProps> = (props) => {
  const { FormId, FormName, onHide } = props;

  const {
    handleSubmit: handleAddFormField,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<generateFormFieldFormData>({
    // defaultValues: formData,
    mode: "all",
  });
  const [selectedType, setSelectedType] = useState("");
  const [fieldTypeOptions, setFieldTypeOptions] = useState([FIELD_TYPE_OPTIONS]);
  const [lookupTableOptions, setLookupTableOptions] = useState([LOOKUP_OPTIONS]);
  const { lookupTables, customFieldOptions } = useSelector((state: RootState) => state.customFormFields);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fieldTypeOptionsCopy = [...fieldTypeOptions];
    if (customFieldOptions) {
      customFieldOptions.forEach((field) => {
        const { FieldType, OptionName } = field;
        fieldTypeOptionsCopy.push({
          id: FieldType,
          lable: OptionName,
        });
      });
      setFieldTypeOptions(fieldTypeOptionsCopy);
    }
  }, [customFieldOptions]);

  useEffect(() => {
    const lookupTablesCopy = [...fieldTypeOptions];
    lookupTables.forEach((field) => {
      const { TableName, DisplayName, ...rest } = field;
      lookupTablesCopy.push({
        id: TableName,
        lable: DisplayName,
      });
    });
    setLookupTableOptions(lookupTablesCopy);
  }, [lookupTables]);

  const handleSubmit = () => {
    let {
      MinLength = 0,
      MaxLength = 0,
      FieldLabel,
      FieldName,
      IsRequired = false,
      LookupTable = "",
      FieldType,
    } = { ...getValues() };
    const payload: any = {
      FormId,
      FormName,
      RegEx: "",
      MinLength,
      IsRequired,
      LookupTable,
      FieldType,
      MaxLength,
      FieldLabel,
      FieldName,
      DefaultValue: "",
      SQLFieldType:
        customFieldOptions.find((customField) => {
          return customField.FieldType === FieldType;
        })?.SQLFieldType || "",
    };
    dispatch(addCustomField(payload));
    onHide();
  };

  const renderFieldsForTextInput = () => (
    <>
      <Grid item xs={FIELD_WIDTH.xs} sm={FIELD_WIDTH.sm} md={FIELD_WIDTH.md} lg={FIELD_WIDTH.lg} xl={FIELD_WIDTH.xl}>
        <Controller
          name="MinLength"
          control={control}
          defaultValue={1}
          render={({ field }) => (
            <CustomTextField
              name={"MinLength"}
              type="number"
              required={true}
              label={"Min Length"}
              value={field.value}
              onChange={(val) => field.onChange(+val)}
              error={Boolean(errorsloginform.MinLength)}
              helperText={errorsloginform.MinLength && errorsloginform.MinLength.message?.toString()}
            />
          )}
        />
      </Grid>
      <Grid item xs={FIELD_WIDTH.xs} sm={FIELD_WIDTH.sm} md={FIELD_WIDTH.md} lg={FIELD_WIDTH.lg} xl={FIELD_WIDTH.xl}>
        <Controller
          name="MaxLength"
          control={control}
          defaultValue={256}
          render={({ field }) => (
            <CustomTextField
              name={"MaxLength"}
              type="number"
              required={true}
              label={"Max Length"}
              value={field.value}
              onChange={(val) => field.onChange(+val)}
              error={Boolean(errorsloginform.MaxLength)}
              helperText={errorsloginform.MaxLength && errorsloginform.MaxLength.message?.toString()}
            />
          )}
        />
      </Grid>
    </>
  );

  const renderFieldLookup = () => (
    <Grid item xs={FIELD_WIDTH.xs} sm={FIELD_WIDTH.sm} md={FIELD_WIDTH.md} lg={FIELD_WIDTH.lg} xl={FIELD_WIDTH.xl}>
      <Controller
        name="LookupTable"
        control={control}
        defaultValue={""}
        rules={{ required: "Lookup is required" }}
        render={({ field }) => (
          <CustomSelect
            name={"LookupTable"}
            valueKey={"id"}
            optionLabelKey="lable"
            options={lookupTableOptions}
            required={true}
            label={"lookup"}
            value={field.value}
            onChange={field.onChange}
            error={Boolean(errorsloginform.LookupTable)}
            helperText={errorsloginform.LookupTable && errorsloginform.LookupTable.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderDynamicFieldsByType = () => {
    if (!selectedType) return null;

    if (selectedType === FIELD_TYPES.TEXT) {
      return <>{renderFieldsForTextInput()}</>;
    } else if (selectedType === FIELD_TYPES.DROPDOWN) {
      return <>{renderFieldLookup()}</>;
    }
  };

  const renderFieldName = () => (
    <Grid item xs={FIELD_WIDTH.xs} sm={FIELD_WIDTH.sm} md={FIELD_WIDTH.md} lg={FIELD_WIDTH.lg} xl={FIELD_WIDTH.xl}>
      <Controller
        name="FieldName"
        control={control}
        defaultValue={""}
        rules={{
          required: "Name is Required",
          validate: (data) => {
            return /^\S*$/.test(data) || "Name should not contain any white space";
          },
        }}
        render={({ field }) => (
          <CustomTextField
            name={"FieldName"}
            required={true}
            label={"Field Name"}
            value={field.value}
            onChange={field.onChange}
            error={Boolean(errorsloginform.FieldName)}
            helperText={errorsloginform.FieldName && errorsloginform.FieldName.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderFieldLabel = () => (
    <Grid item xs={FIELD_WIDTH.xs} sm={FIELD_WIDTH.sm} md={FIELD_WIDTH.md} lg={FIELD_WIDTH.lg} xl={FIELD_WIDTH.xl}>
      <Controller
        name="FieldLabel"
        control={control}
        defaultValue={""}
        rules={{
          required: "Label is Required",
        }}
        render={({ field }) => (
          <CustomTextField
            name={"FieldLabel"}
            required={true}
            label={"fieldLabel"}
            value={field.value}
            onChange={field.onChange}
            error={Boolean(errorsloginform.FieldLabel)}
            helperText={errorsloginform.FieldLabel && errorsloginform.FieldLabel.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderFieldType = () => (
    <Grid item xs={FIELD_WIDTH.xs} sm={FIELD_WIDTH.sm} md={FIELD_WIDTH.md} lg={FIELD_WIDTH.lg} xl={FIELD_WIDTH.xl}>
      <Controller
        name="FieldType"
        control={control}
        rules={{ required: "Type is required" }}
        render={({ field }) => (
          <CustomSelect
            name={"FieldType"}
            valueKey={"id"}
            optionLabelKey="lable"
            options={fieldTypeOptions}
            required={true}
            label={"type"}
            value={field.value}
            onChange={(val) => {
              setSelectedType(val);
              field.onChange(val);
            }}
            error={Boolean(errorsloginform.FieldType)}
            helperText={errorsloginform.FieldType && errorsloginform.FieldType.message?.toString()}
          />
        )}
      />
    </Grid>
  );

  const renderIsRequiredField = () => (

    <Grid sx={{ ml: "7px" }} item xs={FIELD_WIDTH.xs} ml={0} sm={FIELD_WIDTH.sm} md={FIELD_WIDTH.md} lg={FIELD_WIDTH.lg} xl={FIELD_WIDTH.xl}>
      <Controller
        name="IsRequired"
        control={control}
        defaultValue={false}
        render={({ field }) => <CustomCheckBox label="Required" value={field.value} onChange={field.onChange} />}
      />
    </Grid>
  );

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        <Grid
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                {renderFieldName()}
                {renderFieldLabel()}
                {renderFieldType()}
                {renderDynamicFieldsByType()}
                {renderIsRequiredField()}
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                width: "100%",
                mt: 2,
                // position: "absolute",
                // right: 24,
                // bottom: 16,
              }}
            >
              <CustomButton type="reset" variant="outlined" name={"Cancel"} onClick={reset} />
              <CustomButton type="reset" name={"Submit"} onClick={handleAddFormField(handleSubmit)} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddForm;
