import { Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface AQMMappingCOBProps {
  QuestionList?: any;
  view?: boolean;
  isbulkview?: boolean;
  onclickChange?: (row: any, isEdit: boolean , index : any) => void;
}

const QuestionColumns = [
  { Field: "Question", DisplayName: "Question" },
  { Field: "Answer_Type", DisplayName: "Type" },
  { Field: "options", DisplayName: "Options" },
  { Field: "EvidenceRequired", DisplayName: "Evidence" },
  { Field: "IsSME", DisplayName: "SME Response" },
  { Field: "SME", DisplayName: "SME Department" },
];
const bulkQuestionColumns = [
  { Field: "Question", DisplayName: "Question" },
  { Field: "Answer_Type", DisplayName: "Type" },
  { Field: "count", DisplayName: "Options Count" },
  { Field: "options", DisplayName: "Options" },
  { Field: "EvidenceRequired", DisplayName: "Evidence" },
  { Field: "IsSME", DisplayName: "SME Response" },
  { Field: "SME", DisplayName: "SME Department" },
  { Field: "error", DisplayName: "Error" },
];


export const AQMMappingCOBQuestionsTable: React.FC<AQMMappingCOBProps> = ({ QuestionList, view, onclickChange , isbulkview }) => {
  const { dataTable : DepartmentData } = useSelector((state: RootState) => state.departmentData);

  const [SMEDepartmentsList, setSMEDepartmentsList] = useState<any[]>([]);
  const [QuestionListData, setQuestionListData] = useState<any[]>([]);
  const [QuestionListDataoriginal, setQuestionListDataoriginal] = useState<any[]>([]);
  useEffect(() => {
    setSMEDepartmentsList(DepartmentData)
  },[DepartmentData])
  useEffect(() => {
    if (QuestionList) {
      let QuestionListdata = [...QuestionList]
      const QuestionListView = QuestionListdata.map((item: any) => {
        let Options = item.assessmentQuestionnaireOptions
          ? item.assessmentQuestionnaireOptions.map((oitem: any) => oitem?.Attribute_Response).join(', ')
          : '';
          
        return {
          ...item,
          Question: item.Question,
          Answer_Type: item.Answer_Type == 'multiple' ? "Multiple Choice" : "Free Text",
          EvidenceRequired: item.EvidenceRequired ? "Yes" : "No",
          IsSME: item.IsSME ? "Yes" : "No",
          SME: SMEDepartmentsList.find((smeitem : any) => smeitem?.DepartmentId == item.SME )?.DepartmentName,
          options: Options,
        };
      });
  
      setQuestionListData(QuestionListView);
      setQuestionListDataoriginal(QuestionList);
    }
  }, [QuestionList, SMEDepartmentsList]);

  const ClickRow = (row: any, action: any, index: any) => {
    if (onclickChange) {

      onclickChange(QuestionListDataoriginal[index], action === 'edit' ? true : false , index );
    }
  };
  return (
    <>
      <Box>
        <DynamicBasicTable
         key={isbulkview ? 'bulkupload table' : 'COBMappingQuestiontable'}
          style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}
          columns={isbulkview ? bulkQuestionColumns : QuestionColumns}
          rowData={QuestionListData}
          handleAction={ClickRow}
          showDeleteButton={!view}
          isEdit={!view}
        />
      </Box>
    </>
  );
};
