

export const BULKUPLOAD_URL={
    AddDepartment: `/UploadDepartment`,
    AddLocation : `/UploadLocation`,
    AddThirdParty : `/UploadThirdParty`,
    AddregionalSettings : `/UploadRegionalSettings`,
    AddCurrency : '/UploadCurrency',
    addIndustory : '/UploadIndustry',
    addDocument : '/UploadDocument',
    addBranch : '/UploadBranch',
    assessmentQuestions : '/UploadAssessmentQuestionaire', 
}