import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddDocumentMaster } from "./AddDocumentMater";
import {
  deleteDocument,
  getDocumentList,
  updateDocumentAddandEdit,
  getDocumentHistory,
} from "../../../store/slices/documentMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getListOfValues } from "../../../store/slices/ListOfValues";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG } from "../../../config/config";
import { RecordSearch } from "../../recordSearch/RecordSearch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { getNotifications } from "../../../store/slices/notification";

export default function DocumentMaster() {
  const columns_version: any = {
    table_name: "Document History Records",
    isAction: false,
    columns: [],
  };
  const dispatch = useAppDispatch();
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [DocumentData, setDocumentData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { lovTable } = useSelector((state: RootState) => state.listOfValues);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const [question, setQuestion] = useState<string | null>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  // console.log(rowData, "rowData");

  const handleRecordSearch = (Question: string) => {
    setQuestion(Question);
    // setIsDialogVisible(true);
  };

  const RecordSearchColumns = {
    table_name: "Record Search",
    isAction: false,
    columns: [
      { Field: "question", DisplayName: "Question", FieldType: "string" },
      { Field: "responder", DisplayName: "Responder", FieldType: "string" },
      { Field: "sme", DisplayName: "SME", FieldType: "string" },
      { Field: "field_type", DisplayName: "DD or Free", FieldType: "string" },
    ],
  };
  const RecordSearchCategoryData: any[] = [
    {
      id: 1,
      category: "Category One+Document",
      questions: [
        { id: 1, question: "What is your name?", responder: "Alice", sme: "Bob", field_type: "Free" },
        { id: 2, question: "What is your favorite color?", responder: "Charlie", sme: "Diana", field_type: "DD" },
      ],
    },
    {
      id: 2,
      category: "Category Two+Document",
      questions: [
        { id: 3, question: "What is your quest?", responder: "Eve", sme: "Frank", field_type: "Free" },
        {
          id: 4,
          question: "What is the airspeed velocity of an unladen swallow?",
          responder: "Arthur",
          sme: "Merlin",
          field_type: "Free",
        },
      ],
    },
  ];

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const { DocumentTable, isDocumentAdded, isDocumentDeleted, ToasterMsg, isLoading, versionHistory } = useSelector(
    (state: RootState) => state.DocumentMasterData
  );

  const transformData = (data: any) => {
    return data?.map((document: any) => {
      const docTypeExtnArray = document?.DocTypeExtnId ? JSON.parse(document?.DocTypeExtnId) : [];
      const docExtenTypes = docTypeExtnArray?.map((item: any) => item?.Description || item?.Options).join(",");

      const assesmentArray = document?.Assessment_Type ? JSON.parse(document?.Assessment_Type) : [];
      const assesmentTypes = assesmentArray?.map((item: any) => item?.Description || item?.Options).join(",");
      return {
        ...document,
        DocExtenTypes: docExtenTypes,
        assesmentTypes: assesmentTypes,
      };
    });
  };

  useEffect(() => {
    if (versionHistory.departmentVHeader.length > 0 && versionHistory.departmentVHeader.length > 0) {
      if (versionHistory.departmentVHeader && versionHistory.departmentVHeader.length > 0) {
        // setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.departmentVHeader,
        };
        getVersionHistoryData(re_columns);
      }
    }
  }, [versionHistory]);

  const columns: any = {
    table_name: "Document",
    isAction: true,
    columns: [
      {
        Field: "DocumentName",
        DisplayName: "Document Name",
        FieldType: "string",
      },
      {
        Field: "ShortName",
        DisplayName: "Short Name",
        FieldType: "string",
      },

      {
        Field: "Purpose",
        DisplayName: "Purpose",
        FieldType: "string",
      },
      {
        Field: "DocExtenTypes",
        DisplayName: "Document Ext Type",
        FieldType: "string",
      },
      {
        Field: "assesmentTypes",
        DisplayName: "Assessment Type",
        FieldType: "string",
      },
      {
        Field: "IsPerpetual",
        DisplayName: "Is Perpetual",
        FieldType: "string",
      },
      // {
      //   Field: "ExpiryDate",
      //   DisplayName: "Expiry Date",
      //   FieldType: "date",
      // },
      {
        Field: "IsMandatory",
        DisplayName: "Is Mandatory",
        FieldType: "string",
      },

      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "UpdatedBy",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getDocumentList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    setDocumentData(DocumentTable);
  }, [DocumentTable]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permssionPage?.MenuId,
      ModuleName: "Document Master",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Document Master",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        setIsEditing(true);
        dispatch(updateLoader(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      return [
        {
          RecordlockId: recordLock[0].RecordlockId,
          RecordId: recordLock[0].RecordId,
          IsActive: false,
        },
      ];
    }
    return [];
  };
  useEffect(() => {
    if (!isEdit) {
      const data = recordLockUpdate();
      if (data.length > 0) {
        dispatch(UpdateRecordLock(data.pop()));
      }
    }
  }, [isEdit]);

  const handleFetchValues = () => {
    dispatch(getListOfValues({ Name: "Purpose" }));
    dispatch(getListOfValues({ Name: "Assessment Type" }));
    dispatch(getListOfValues({ Name: "Document Ext" }));
  };
  useEffect(() => {
    handleFetchValues();
  }, []);
  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {
      setIsEditData(data);
      setOption(Option);
      dispatch(updateLoader(true));

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.DocumentId, FormID: 12 });
      const Edit_data: any = response;

      const canEdit = await handleRecordLockCheck(data?.DocumentId);
      if (Edit_data?.data === true) {
        if (canEdit) {
          dispatch(updateLoader(false));
          setIsEdit(true);
          const dispatchData = handleAddRecordLock(data?.DocumentId);
          await dispatch(AddRecordLock(dispatchData));
          //setIsEditOpen(true);
          const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.DocumentId)));
          if (GetRecordLock.fulfilled.match(resultAction)) {
            const recordLockData = resultAction.payload;
            setGetRecordLock(recordLockData);
          }
        }
      } else {
        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      }
    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      setIsVersionHistory(true);
      dispatch(
        getDocumentHistory({
          DocumentId: data.DocumentId,
          FormId: 12,
          // Approved_By: ,
        })
      );
    } else if (Option === "delete") {
      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.DocumentId, FormID: 12 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        setOption(Option);
        setIsDeleteData(data);
      } else {
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      }
    }
  };

  useEffect(() => {
    if (isDocumentAdded) {
      dispatch(updateDocumentAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(
        getDocumentList({
          FormID: 2,
        })
      );
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
    } else if (isDocumentDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      setTimeout(() => {
        dispatch(updateDocumentAddandEdit(false));
      }, 1000);
      dispatch(
        getDocumentList({
          FormID: 2,
        })
      );
    }
  }, [isDocumentAdded, isDocumentDeleted]);

  const deleteDepartmentList = () => {

    let JsonDataForDisplay: any = {
      "Document Name": isDeleteData.DocumentName,
      "Short Name": isDeleteData.ShortName,
      Purpose: isDeleteData.Purpose,
      "Document ExtType": isDeleteData.DocExtenTypes,
      "Assessment Type": isDeleteData.assesmentTypes,
      
      "Is Perpetual": isDeleteData.IsPerpetual ? "Yes" : "No",
      "Is Mandatory": isDeleteData.IsMandatory ? "Yes" : "No",
      Status: isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    };
    console.log(isDeleteData);
    let formData = {
      Is_Active: isDeleteData.IsActive === "Active" ? false : true,
      Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
      DocumentId: isDeleteData.DocumentId,
      DocumentName: isDeleteData.DocumentName,
      UserId: userLoginResponse?.UserId,
      MenuId: currentPagePermissionData?.MenuId,
      Form_Id: 12,
      ModuleName: "Document Master",
      CreatedBy: userLoginResponse?.UserName,
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
    };

    // setLoader(true);
    dispatch(deleteDocument(formData));
  };
 
  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentPagePermissionData?.MenuName}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>
          {false && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <CustomButton
                type="reset"
                variant="contained"
                name={"Search Document Questions"}
                onClick={() => {
                  handleRecordSearch("Question");
                }}
              />
            </Box>
          )}
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={DocumentData && DocumentData.length > 0 ? transformData(DocumentData) : []}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Document Master"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={"Add Document"}
        contentNode={
          // <></>
          <AddDocumentMaster
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"md"}
        minHeight={"50vh"}
        header={Option === "view" ? "View Document" : "Update Document"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddDocumentMaster
                view={false}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddDocumentMaster
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={"Document Master Version History"}
        contentNode={
          <>
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={transformData(versionHistory?.DocumentMaster)}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Document Master Version History"
            />
          </>
        }
      />

      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
          } the selected Document?`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
      {question ? (
        <RecordSearch
          Question={question}
          setQuestion={setQuestion}
          rowData={setRowData}
          columns={RecordSearchColumns}
          categoryData={RecordSearchCategoryData}
          dialogTitle={"Add Document Questions"}
        />
      ) : null}
    </>
  );
}
