import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddAssessmentCategoryResponse, IAssessmentCategoryResponse, IVHColumnRes, IAssessmentCategory } from "../../models/assessmentCategoryMaster.type";
import { ASSESSMENT_CATEGORY_URL } from "./endpoints/assessmentCategory";

const getAssessmentCategoryList = (reqparams: any): Promise<IAssessmentCategoryResponse> =>
  axiosInstance
    .get(ASSESSMENT_CATEGORY_URL.GetAllAssessmentCategory)
    .then((response: AxiosResponse<IAssessmentCategoryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getParentAssessmentCategoryList = (reqparams: any): Promise<IAssessmentCategoryResponse> =>
  axiosInstance
    .get(ASSESSMENT_CATEGORY_URL.GetParentAssessmentCategory, { params: reqparams })
    .then((response: AxiosResponse<IAssessmentCategoryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateAssessmentCategory = (requestBody: any): Promise<IAddAssessmentCategoryResponse> =>
  axiosInstance
    .post(ASSESSMENT_CATEGORY_URL.UpdateAssessmentCategory, requestBody)
    .then((response: AxiosResponse<IAddAssessmentCategoryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addAssessmentCategory = (requestBody: any): Promise<IAddAssessmentCategoryResponse> =>
  axiosInstance
    .post(ASSESSMENT_CATEGORY_URL.AddAssessmentCategory, requestBody)
    .then((response: AxiosResponse<IAddAssessmentCategoryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteAssessmentCategory = (requestBody: any): Promise<IAddAssessmentCategoryResponse> =>
  axiosInstance
    .post(ASSESSMENT_CATEGORY_URL.DeleteAssessmentCategory, requestBody)
    .then((response: AxiosResponse<IAddAssessmentCategoryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVersionHistoryAssessmentCategory = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(ASSESSMENT_CATEGORY_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const assessmentCategoryService = {
  getAssessmentCategoryList,
  getParentAssessmentCategoryList,
  updateAssessmentCategory,
  addAssessmentCategory,
  deleteAssessmentCategory,
  getVersionHistoryAssessmentCategory
};

export default assessmentCategoryService;
