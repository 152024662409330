import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, IconButton, FormLabel } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { IPageAccessControl, IRole, IRoleFormData } from "../../models/roles.type";
import { addRole, getRolesList, updateRole } from "../../store/slices/roleData";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import MaterialDynamicRolesGrid from "../../common/dynamicDataGrid/MaterialDynamicRolesGrid";
import { ACTIVE, INACTIVE, PermissionColumn, addMenu } from "../../utils/constants";
import ProgressBar from "../../common/progressBar/progressBar";
import { textFieldWidth } from "../../utils/constants";
import Close from "@mui/icons-material/Close";
import { MenuItem } from "material-ui";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import { updateLoader } from "../../store/slices/loader";
import { IMenu } from "../../models/loggin.type";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";

export interface roleProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  userBased?: boolean;
  roleData?: IRole | null | undefined;
  updateRoleData?: any;
  setFinalMenuData?: any;
  //   isEditData?: any;
  //   edit?: boolean;
  //   // setIsEdit?:boolean;
  //   isEdited?: any;
  //   isAdded?:any;
}

const AddRole: React.FC<roleProps> = ({ view, closePopUp, edit, roleData, userBased, updateRoleData, setFinalMenuData }) => {
  const dispatch = useAppDispatchThunk();
  const { isRoleAdded, isRoleUpdated, allMenuData } = useSelector((state: RootState) => state.roleData);
  const { userLoginResponse, user } = useSelector((state: RootState) => state.loggerData);
  const [pageAccessControl, setPageAccessControl] = useState<IPageAccessControl[]>([]);
  const [menuScreensData, setMenuScreens] = useState([]);
  const [updaterow, setUpdateRow] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [selectAll, setselectAll] = useState<boolean>(false);
  const [IsCancel, setIsCancel] = useState<boolean>(false);

  const selectAllEnable = (item: any): number => {
    let { IsAdd, IsEdit, IsDelete, IsView, IsExport, IsImport, IsApproval, ...rest } = item;
    return IsAdd && IsEdit && IsDelete && IsView && IsExport && IsApproval ? 1 : 0;
  };

  useEffect(() => {
    if (roleData) {
      // setPageAccessControl(roleData);
    }

  }, [roleData]);

  useEffect(() => {
    if (setFinalMenuData && menuScreensData) {
      setFinalMenuData(menuScreensData);
    }

  }, [menuScreensData]);

  useEffect(() => {
    if (updateRoleData) {
      updateRoleData(pageAccessControl);
    }
  }, [pageAccessControl]);

  const mapMenuBasedOnPermission = (userBasedMenu: any, pageMenuData: any, selectAll: number) => {
    let pageMenu = { ...pageMenuData };
    if (pageMenu.SubMenus && pageMenu.SubMenus.length > 0) {
      let subMenuSelectALL = selectAll;
      pageMenu.SubMenus.map((pageSubMenuitem: any, index: number) => {
        if (userBasedMenu.SubMenus.length > 0) {
          let userBasedMenuItem = userBasedMenu.SubMenus.filter(
            (userBasedMenusubItem: any) => userBasedMenusubItem.MenuId === pageSubMenuitem.MenuId
          );
          if (userBasedMenuItem.length > 0) {
            let mappedItem = mapMenuBasedOnPermission(userBasedMenuItem[0], pageSubMenuitem, selectAll);
            pageMenu.SubMenus[index] = mappedItem;
            if (subMenuSelectALL) {
              subMenuSelectALL = mappedItem.Select;
            }
          } else {
            subMenuSelectALL = 0;
            pageMenu.SubMenus[index] = { ...pageSubMenuitem };
          }
        } else {
          subMenuSelectALL = 0;
          pageMenu.SubMenus[index] = { ...pageSubMenuitem };
        }
      });
      return { ...pageMenu, Select: subMenuSelectALL };
    } else {
      let selectAllValue = selectAllEnable({ ...pageMenu, ...userBasedMenu });
      return { ...pageMenu, ...userBasedMenu, Select: selectAllValue };
    }
  };

  const mapSubMenus = (item: any, role: IMenu | undefined) => {
    if (item.SubMenus && item.SubMenus.length > 0) {
      let copyOfItem = { ...item };
      copyOfItem.SubMenus = copyOfItem.SubMenus.map((item: any) => {
        const res = role?.SubMenus?.filter((data) => data.MenuId === item.MenuId)?.[0]
        let mappedItem = mapSubMenus(item, res);
        return { ...mappedItem, disabled: res?.disabled };
      });
      item = copyOfItem;
    } else {
      item = { ...item, ...addMenu, Select: 0 };
    }
    return { ...item };
  };

  useEffect(() => {

    if (allMenuData.length > 0) {
      let copyoFMenuData: any = JSON.parse(JSON.stringify(allMenuData));
      let manuplatemenuScreen = copyoFMenuData.map((item: any) => {
        const filterRole = roleData &&
          roleData.Menus &&
          roleData.Menus.length > 0 &&
          roleData.Menus.filter((data) => data.MenuId === item.MenuId)?.[0] || undefined
        item = mapSubMenus({ ...item }, filterRole);
        return { ...item, disabled: filterRole && filterRole?.disabled };
      });
      let selectAll = false;
      if (edit || view) {
        selectAll = true;
        manuplatemenuScreen = manuplatemenuScreen.map((mappedMenu: any) => {
          const filterRole = roleData &&
            roleData.Menus &&
            roleData.Menus.length > 0 &&
            roleData.Menus.filter((data) => data.MenuId === mappedMenu.MenuId)
          mappedMenu = filterRole && filterRole?.length > 0
            ? mapMenuBasedOnPermission(
              filterRole[0],
              mappedMenu,
              1
            )
            : mappedMenu;

          if (selectAll && mappedMenu.Select) {
            selectAll = true;
          } else {
            selectAll = false;
          }
          return { ...mappedMenu, disabled: filterRole && filterRole[0]?.disabled };
        });
      }
      setselectAll(selectAll);
      setMenuScreens(manuplatemenuScreen);
    }
  }, [allMenuData, roleData]);

  useEffect(() => {
    if (updaterow) {
      updateTable();
    }
  }, [updaterow]);

  const subMenuItration = (item: any, row: any) => {
    if (item.SubMenus && item.SubMenus.length > 0) {
      let selectAllValue = 1;
      item.SubMenus = item.SubMenus.map((submenuItem: any) => {
        submenuItem = subMenuItration(submenuItem, row);
        if (selectAllValue) {
          selectAllValue = submenuItem.Select;
        }
        return submenuItem;
      });
      if (selectAllValue === 1) {
        item.Select = 1;
      } else {
        item.Select = 0;
      }
      return item;
    } else {
      if (item.MenuId === row.MenuId) {
        let newItem = { ...item, ...row };
        let value = selectAllEnable(newItem);
        newItem.Select = value;
        return newItem;
      } else {
        let value = selectAllEnable(item);
        item.Select = value;
        return item;
      }
    }
  };

  const mappCurrentValue = (row: any) => {
    console.log(menuScreensData, "menuScreensData", row);
    let localselectALL = true;
    let manuplatemenuScreen = menuScreensData.map((item: any) => {
      if (item.MenuId === row.MenuId) {
        //let cloneItem = { ...item };
        item = { ...item, ...row };
      } else if (
        item.SubMenus.length > 0 &&
        item.SubMenus.filter((subItem: any) => subItem.MenuId === row.MenuId).length > 0
      ) {
        let copofItem = { ...item };
        let subMenus = [...copofItem.SubMenus];
        subMenus = subMenus.map((subMenuItem: any) => {
          if (subMenuItem.MenuId === row.MenuId) {
            return { ...subMenuItem, ...row };
          } else {
            return subMenuItem;
          }
        });
        copofItem.SubMenus = subMenus;
        item = copofItem;
      } else if (item.SubMenus.length > 0) {
        item = subMenuItration(item, row);
      }
      let value = 1;

      if (localselectALL) {
        localselectALL = item.Select === 1 ? true : false;
      }
      return item;
    });
    setselectAll(localselectALL);
    setMenuScreens(JSON.parse(JSON.stringify(manuplatemenuScreen)));
  };

  const updateRowbasedOnselction = (item: any) => {
    if (edit && !item["RoleId"]) {
      item["RoleId"] = roleData?.RoleId;
      item["CreatedBy"] = userLoginResponse?.UserId;
      item["UpdatedBy"] = userLoginResponse?.UserId;
      let { MenuName, FormId, Select, Url, RoleName, ParentId, IsParent, SubMenus, ...rest } = item;
      return rest;
    } else if (edit) {
      item["RoleId"] = roleData?.RoleId;
      item["CreatedBy"] = userLoginResponse?.UserId;
      item["UpdatedBy"] = userLoginResponse?.UserId;
      let { MenuName, FormId, Select, Url, RoleName, ParentId, IsParent, SubMenus, ...rest } = item;
      return rest;
    } else {
      item["CreatedBy"] = userLoginResponse?.UserId;
      let { MenuName, FormId, Url, Select, RoleName, ParentId, IsParent, SubMenus, ...rest } = item;
      return rest;
    }
  };
  const updateTable = () => {
    //console.log( updaterow , "value update");

    let tableRowCopy = { ...updaterow };
    let copyRow = { ...updaterow };
    //copyRow[field] = value.currentTarget.checked? "active" : 0;
    let pageaccessControlCopy = [...pageAccessControl];

    let filteredValue =
      pageaccessControlCopy && pageaccessControlCopy.length > 0
        ? pageaccessControlCopy.filter((pageValue: any) => pageValue.MenuId === copyRow.MenuId)
        : [];
    if (filteredValue.length > 0) {
      pageaccessControlCopy = pageaccessControlCopy.map((pageValue: any) => {
        if (pageValue.MenuId === copyRow.MenuId) {
          return updateRowbasedOnselction(copyRow);
        } else {
          return pageValue;
        }
      });
    } else if (!copyRow.SubMenus || (copyRow.SubMenus && copyRow.SubMenus.length === 0)) {
      let newCopyValue = updateRowbasedOnselction({ ...copyRow });
      pageaccessControlCopy.push(newCopyValue);
    } else if (copyRow.SubMenus && copyRow.SubMenus.length > 0) {
      copyRow.SubMenus.map((item: any) => {
        if (item.SubMenus && item.SubMenus.length > 0) {
          item.SubMenus.map((itemTwo: any) => {
            if (pageaccessControlCopy.length > 0) {
              let subItemindex = pageaccessControlCopy.findIndex((subItem) => subItem.MenuId === itemTwo.MenuId);
              if (subItemindex === -1) {
                let newCopyValue = updateRowbasedOnselction({ ...itemTwo });
                pageaccessControlCopy.push(newCopyValue);
              } else {
                let newCopyValue = updateRowbasedOnselction({ ...itemTwo });
                pageaccessControlCopy[subItemindex] = newCopyValue;
              }
            } else {
              let newCopyValue = updateRowbasedOnselction({ ...itemTwo });
              pageaccessControlCopy.push(newCopyValue);
            }
          });
          if (pageaccessControlCopy.length > 0) {
            let subItemindex = pageaccessControlCopy.findIndex((subItem) => subItem.MenuId === item.MenuId);
            if (subItemindex === -1) {
              let newCopyValue = updateRowbasedOnselction({ ...item });
              pageaccessControlCopy.push(newCopyValue);
            } else {
              let newCopyValue = updateRowbasedOnselction({ ...item });
              pageaccessControlCopy[subItemindex] = newCopyValue;
            }
          } else {
            let newCopyValue = updateRowbasedOnselction({ ...item });
            pageaccessControlCopy.push(newCopyValue);
          }
        } else {
          if (pageaccessControlCopy.length > 0) {
            let subItemindex = pageaccessControlCopy.findIndex((subItem) => subItem.MenuId === item.MenuId);
            if (subItemindex === -1) {
              let newCopyValue = updateRowbasedOnselction({ ...item });
              pageaccessControlCopy.push(newCopyValue);
            } else {
              let newCopyValue = updateRowbasedOnselction({ ...item });
              pageaccessControlCopy[subItemindex] = newCopyValue;
            }
          } else {
            let newCopyValue = updateRowbasedOnselction({ ...item });
            pageaccessControlCopy.push(newCopyValue);
          }
          if (pageaccessControlCopy.length > 0) {
            let subItemindex = pageaccessControlCopy.findIndex((subItem) => subItem.MenuId === item.MenuId);
            if (subItemindex === -1) {
              let newCopyValue = updateRowbasedOnselction({ ...item });
              pageaccessControlCopy.push(newCopyValue);
            } else {
              let newCopyValue = updateRowbasedOnselction({ ...item });
              pageaccessControlCopy[subItemindex] = newCopyValue;
            }
          } else {
            let newCopyValue = updateRowbasedOnselction({ ...item });
            pageaccessControlCopy.push(newCopyValue);
          }
        }
      });
    }
    console.log(pageaccessControlCopy, "pageaccessControl");
    setPageAccessControl(pageaccessControlCopy);
    mappCurrentValue({ ...tableRowCopy });
  };

  const check_and_unChekcALL = (rowData: any, value: boolean, pageAccessControlCopy: any) => {
    if (rowData.SubMenus && rowData.SubMenus.length > 0) {
      rowData.SubMenus.map((item: any) => {
        let mappedItem = check_and_unChekcALL(item, value, pageAccessControlCopy);
        mappedItem.Select = value ? 1 : 0;
        return mappedItem;
      });
    } else {
      rowData.Select = value ? 1 : 0;
      rowData.IsView = value ? 1 : 0;
      rowData.IsAdd = value ? 1 : 0;
      rowData.IsEdit = value ? 1 : 0;
      rowData.IsDelete = value ? 1 : 0;
      rowData.IsExport = value ? 1 : 0;
      rowData.IsApproval = value ? 1 : 0;
      if (pageAccessControlCopy) {
        pageAccessControlCopy.push(rowData);
      }
    }
    return rowData;
  };

  const handleSelectAllCheck = (rowData: any, value: any) => {
    setselectAll(value.currentTarget.checked);
    let checkedValue = value.currentTarget.checked;
    let Mainrow: any[] = JSON.parse(JSON.stringify(rowData));
    let pageAccessControlCopy: any[] = [];
    Mainrow = Mainrow.map((item) => {
      let row = check_and_unChekcALL(item, checkedValue, pageAccessControlCopy);
      row.Select = checkedValue ? 1 : 0;
      return row;
    });

    console.log([...pageAccessControlCopy], "selectAll");
    setPageAccessControl([...pageAccessControlCopy]);
    setMenuScreens(JSON.parse(JSON.stringify(Mainrow)));
  };

  const handleCheck = (rowData: any, field: any, value: any) => {
    if (field === "Select") {
      let row = { ...rowData };
      let checkValue = value.currentTarget.checked;
      row.Select = value.currentTarget.checked ? 1 : 0;
      if (row.SubMenus && row.SubMenus?.length > 0) {
        row = check_and_unChekcALL(row, checkValue, null);
      } else {
        row = check_and_unChekcALL(row, checkValue, null);
      }
      console.log(row);
      setUpdateRow(row);
    } else {
      let row = { ...rowData };
      row[field] = value.currentTarget.checked ? 1 : 0;
      setUpdateRow(row);
    }
  };

  let formData: IRoleFormData = {
    RoleName: view || edit ? (roleData && roleData.RoleName ? roleData.RoleName : "") : "",
    Description: view || edit ? (roleData && roleData.Description ? roleData.Description : "") : "",
    IsActive: view || edit ? (roleData && roleData.IsActive ? (roleData.IsActive === "Active" ? true : false) : false) : true,
    PageAccessControl: [],
  };
  const {
    handleSubmit: handleUserForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    getValues,
    watch,
  } = useForm<IRoleFormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const isActiveDisabled = watch("IsActive");
  console.log(isActiveDisabled, "isActiveDisabled");


  const handleSubmit = () => {
    dispatch(updateLoader(true));
    setLoader(true);
    // let formData = getValues();
    let formData: any = getValues();
    console.log(formData, "formData");
    let JsonDataForDisplay: any = {
      "RoleName": formData.RoleName,
      "Description": formData.Description,
      "Status": formData.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
      "PageAccessControl": menuScreensData
    }

    formData.Form_Id = 3
    formData.UserId = userLoginResponse?.UserId
    if (edit) {
      formData.IsActive = formData.IsActive ? 1 : 0;
      formData.RoleId = roleData?.RoleId;
      formData.UpdatedBy = userLoginResponse?.UserId;
      formData.ModuleName = "Role Management";
      formData.Activity = "Edit";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      formData.PageAccessControl = pageAccessControl.map((i:any)=>{
        i.IsActive = "1"
        i.RoleId = roleData?.RoleId
        return i
      });
      dispatch(updateRole(formData));
    } else {
      // formData.IsActive = formData.IsActive ? true : false;
      formData.IsActive =  true;
      formData.CreatedBy = userLoginResponse?.UserId ? (userLoginResponse?.UserId).toString() : '' ;
      formData.ModuleName = "Role Management";
      formData.Activity = "create";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      formData.RoleId = 0
      formData.PageAccessControl = pageAccessControl.map((i:any)=>{
        i.IsActive = "1"
        i.RoleId = 0
        return i
      });
      dispatch(addRole(formData));
    }
  };


  useEffect(() => {
    if (isRoleAdded || isRoleUpdated) {
      dispatch(
        getRolesList({
          formId: 3,
        })
      );
      reset(formData);
      if (closePopUp) {
        closePopUp();
        setLoader(false);
        dispatch(updateLoader(false));
      }
    }
  }, [isRoleAdded, isRoleUpdated]);

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      closePopUp && closePopUp();
    }
  };

  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {/* {loader ? <ProgressBar showProgress={loader} /> : null} */}

        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0",
          }}
        >
          <Typography variant="h6" sx={{ m: 1 ,width:"100%", textAlign: "center"}}>
            {view ? "View Role" : edit ? "Update Role" : "Add Role"}
          </Typography>
          <IconButton
                aria-label="close"
                onClick={() => {
                  closePopUp && closePopUp()
                }}
              >
                <Close />
              </IconButton>
        </Box> */}
        <Grid
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 4,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              {!userBased && (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="RoleName"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Role Name is Required",
                        validate: {
                          minLength: (value) => value.length >= 2 || "Minimal 2 characters requires",
                          ValueLimitaion: (value) => /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(value) || "Name cannot contain special characters and empty value",
                        }
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"RoleName"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Role Name"}
                          value={field.value}
                          onChange={field.onChange}
                          // onChange={(value) => {
                          //   if (/^[a-zA-Z]*$/.test(value)) {
                          //     field.onChange(value);
                          //   }
                          // }}
                          error={Boolean(errorsloginform.RoleName)}
                          helperText={errorsloginform.RoleName && errorsloginform.RoleName.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Description"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Role Description is Required",
                        validate: {
                          minLength: (value) => value.length >= 4 || "Minimal 4 characters requires",
                          ValueLimitaion: (value) => /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(value) || "Name cannot contain special characters and empty value",
                        }
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"Description"}
                          required={true}
                          label={"Role Description"}
                          value={field.value}
                          disabled={view || !isActiveDisabled}
                          onChange={field.onChange}
                          // onChange={(value) => {
                          // if (/^[a-zA-Z]*$/.test(value)) {
                          //   field.onChange(value);
                          // }
                          // }}
                          error={Boolean(errorsloginform.Description)}
                          helperText={errorsloginform.Description && errorsloginform.Description.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  {/* {edit || view ? (<Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    name="IsActive"
                    control={control}
                    defaultValue={"InActive"}
                    render={({ field }) => (
                      <CustomToogleSwitch
                        label={"Role Status"}
                        dynamicLabel={field.value ? ACTIVE : INACTIVE}
                        value={field.value}
                        disabled={view}
                        onChange={(data) => {
                          setValue("IsActive", data);
                        }}
                      />
                    )}
                  />
                </Grid>) : null} */}
                </Grid>
              )}
              {!userBased && (
                <Box sx={{ mt: 2, pl: 2 }}>
                  <CustomCheckBox
                    onChange={(e: any) => handleSelectAllCheck(menuScreensData, e)}
                    disabled={view || !isActiveDisabled}
                    isStyle={true}
                    value={selectAll}
                    label={"Select All"}
                  />
                  {/* <FormLabel id="demo-controlled-radio-buttons-group" sx={{ textAlign: "start" }}>
                Select All
              </FormLabel> */}
                </Box>
              )}
              <Box sx={{ mt: 3 }}>
                <MaterialDynamicRolesGrid data={PermissionColumn} rows={menuScreensData} handleAction={handleCheck} view={view || !isActiveDisabled} />
              </Box>
              {!userBased && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "right",
                    mt: 2,
                  }}
                >
                  {view ? (
                    <CustomButton
                      type="reset"
                      name={"Cancel"}
                      onClick={() => {
                        closePopUp && closePopUp();
                      }}
                    />
                  ) : edit ? (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton type="reset" disabled={!isDirty} name={"Update"} onClick={handleUserForm(handleSubmit)} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton type="reset" disabled={!isDirty} name={"Submit"} onClick={handleUserForm(handleSubmit)} />
                    </React.Fragment>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ModalPopUp
        onDeleteMethod={() => closePopUp && closePopUp()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </>
  );
};

export default AddRole;
