import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import CustomAccordion from "../../common/dynamicAccordion/CustomAccordion";
import CustomButton from "../../common/dynamicButton/CustomButton";

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomNumberField from "../../common/dynamicInputs/CustomNumberField";
import CustomSvg from "../../common/CustomSvg";
import { StyledMenu } from "./utills";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import {
  addMetricsMaster,
  deleteMetricsMaster,
  getMetricsHistoryByID,
  getMetricsMasterList,
  updateMetricsMasterAddandEdit,
  updateMetricsMasterVersionHistory,
} from "../../store/slices/metricsMasters";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import ProgressBar from "../../common/progressBar/progressBar";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import styles from "./AccordionTable.module.scss";
import RecordLockDialog, { RecordLockUpdate } from "../recordLock/RecordLock";
interface RangeTypeItem {
  LovId: string;
  Options: string;
}

interface FormData {
  Metrics_Id?: any;
  Metrics_Type: string;
  Range_From: string | number;
  Range_To: string | number;
  Rating: string;
  IsActive: boolean | string;
}

const AccordionTable: React.FC = () => {
  const {
    metricsMasterData,
    MetricsMasterversionHistory,
    isMetricsMasterAdded,
    isMetricsMasterUpdated,
    isMetricsMasterDeleted,
    isLoading,
    MetricsMastertoasterMsg,
  } = useSelector((state: RootState) => state.metricsMasters);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const [versionHistoryData, getVersionHistoryData] = useState<any>();
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const { handleRecordLockUpdate } = RecordLockUpdate();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isRowDirtys, setIsRowDirtys] = useState<Record<number, boolean>>({});
  const [getRangeFromError, setRangeFromError] = useState<string | null>(null);
  const [getRangeToError, setRangeToError] = useState<string | null>(null);
  const [getRatingError, setRatingError] = useState<string | null>(null);
  const [editingCell, setEditingCell] = useState<{ rowIndex: number; fieldName: string } | null>(null);
  const dispatch = useAppDispatchThunk();
  const columns_version: any = {
    table_name: "Metrics Master Version History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);
  useEffect(() => {
    if (
      MetricsMasterversionHistory.MetricsMasterVHeader.length > 0 &&
      MetricsMasterversionHistory.MetricsMasterVHeader.length > 0
    ) {
      if (
        MetricsMasterversionHistory.MetricsMasterVHeader &&
        MetricsMasterversionHistory.MetricsMasterVHeader.length > 0
      ) {
        let re_columns = {
          ...columns_version,
          columns: MetricsMasterversionHistory.MetricsMasterVHeader,
        };
        getVersionHistoryData(re_columns);
      }
    }
  }, [MetricsMasterversionHistory]);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isDirty },
    setValue,
    watch,
    getValues,
    clearErrors,
  } = useForm<{ [key: string]: FormData[] }>({
    defaultValues: {
      serviceScoring: [],
      aqResponseType: [],
      deviationMatrix: [],
    },
    mode: "onChange",
  });

  const formValues = watch();
  const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isPromptVisible, setIsPromptVisible] = useState<boolean>(false);
  useEffect(() => {
    handleUserActivity();
  }, [formValues]);

  const handleUserActivity = () => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(() => {
      setIsPromptVisible(true);
    }, 5000);
  };
  const handlePromptResponse = (response: boolean) => {
    if (response) {
      handleUserActivity(); // Reset activity timeout
    } else {
      setIsPromptVisible(false);
    }
  };

  const {
    fields: serviceScoringFields,
    prepend: prependServiceScoring,
    remove: removeServiceScoring,
    update: updateServiceScoring,
  } = useFieldArray({
    control,
    name: "serviceScoring",
  });

  const {
    fields: aqResponseTypeFields,
    prepend: prependAQResponseType,
    remove: removeAQResponseType,
    update: updateAQResponseType,
  } = useFieldArray({
    control,
    name: "aqResponseType",
  });

  const {
    fields: deviationMatrixFields,
    prepend: prependDeviationMatrix,
    remove: removeDeviationMatrix,
    update: updateDeviationMatrix,
  } = useFieldArray({
    control,
    name: "deviationMatrix",
  });

  const [editingRow, setEditingRow] = useState<{ [key: string]: any }>({
    serviceScoring: null,
    aqResponseType: null,
    deviationMatrix: null,
  });
  const [originalData, setOriginalData] = useState<{ [key: string]: { [id: string]: FormData } }>({
    serviceScoring: {},
    aqResponseType: {},
    deviationMatrix: {},
  });

  const [expanded, setExpanded] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [editRowId, setEditRowId] = useState<any>(null);
  const [isNewRow, setIsNewRow] = useState<any>(false);
  const [activeRowAccordion, setActiveRowAccordion] = useState<any>(expanded ? expanded : null);
  const open = Boolean(anchorEl);
  console.log(isNewRow, "isNewRow");
  const id = open ? "simple-popover" : undefined;
  const [AccordianData, setAccordianData] = useState<any>(metricsMasterData);
  const fetchData = useCallback(() => {
    if (metricsMasterData && metricsMasterData.length > 0) {
      const seenIds = new Set();
      const aqResponseTypeFields: FormData[] = [];
      const serviceScoringFields: FormData[] = [];
      const deviationMatrixFields: FormData[] = [];

      metricsMasterData.forEach((data: any) => {
        // if (!seenIds.has(data.Metrics_Id)) {
        //   seenIds.add(data.Metrics_Id);

        // }
        switch (initialFieldName(data.Metrics)) {
          case "aqResponseType":
            aqResponseTypeFields.push(data);
            break;
          case "serviceScoring":
            serviceScoringFields.push(data);
            break;
          case "deviationMatrix":
            deviationMatrixFields.push(data);
            break;
          default:
            break;
        }
      });

      setValue(
        "serviceScoring",
        serviceScoringFields.sort((a: any, b: any) => Number(a.Range_From) - Number(b.Range_From))
      );
      setValue(
        "aqResponseType",
        aqResponseTypeFields.sort((a: any, b: any) => Number(a.Range_From) - Number(b.Range_From))
      );
      setValue(
        "deviationMatrix",
        deviationMatrixFields.sort((a: any, b: any) => Number(a.Range_From) - Number(b.Range_From))
      );
      console.log(deviationMatrixFields, "deviationMatrixFields");
    }
  }, [AccordianData, metricsMasterData]);

  useEffect(() => {
    fetchData();
  }, [metricsMasterData]);

  useEffect(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    setAccordianData(metricsMasterData);
  }, [metricsMasterData]);

  const processAndSetFieldValues = (AccordianData: any) => {
    const aqResponseTypeFields: FormData[] = [];
    const serviceScoringFields: FormData[] = [];
    const deviationMatrixFields: FormData[] = [];

    AccordianData.forEach((data: any) => {
      switch (initialFieldName(data.Metrics)) {
        case "aqResponseType":
          aqResponseTypeFields.push(data);
          break;
        case "serviceScoring":
          serviceScoringFields.push(data);
          break;
        case "deviationMatrix":
          deviationMatrixFields.push(data);
          break;
        default:
          break;
      }
    });

    setValue("serviceScoring", serviceScoringFields);
    setValue("aqResponseType", aqResponseTypeFields);
    setValue("deviationMatrix", deviationMatrixFields);
  };

  const fetchDataUpdate = useCallback(async () => {
    if (isMetricsMasterAdded || isMetricsMasterUpdated || isMetricsMasterDeleted) {
      dispatch(updateMetricsMasterAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: MetricsMastertoasterMsg.Message,
          isTosterFailed: !MetricsMastertoasterMsg.Status,
        })
      );

      const response = await dispatch(getMetricsMasterList({}));
      const data: any = response;
      const AccordianData = data.payload;
      console.log(AccordianData, "AccordianData");

      if (AccordianData && AccordianData.length > 0) {
        processAndSetFieldValues(AccordianData);
      }

      setIsEditing(false);
      setEditRowId(null);
      setSelectedRow(null);
      setIsNewRow(null);
      handleClose();
      dispatch(updateLoader(false));
    }
    setEditRowId(null);
    if (isMetricsMasterDeleted) {
      setIsDeleteData(null);
      setTimeout(() => {
        dispatch(updateMetricsMasterAddandEdit(false));
      }, 1000);
    }
  }, [isMetricsMasterAdded, isMetricsMasterUpdated, isMetricsMasterDeleted]);

  useEffect(() => {
    fetchDataUpdate();
  }, [fetchDataUpdate]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, selectedRow: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(selectedRow);
  };

  const handleClose = () => {
    if (editRowId) {
      // handleRecordLockUpdate();
    } else {
    }
    setAnchorEl(null);
    // fetchData();
    // setSelectedRow(null);
  };
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
    setActiveRowAccordion(panel);
  };
  const [permssionPage, setPermissionForpage] = useState<any>();

  function getNextMetricsId(data: any) {
    let maxMetricsId = 0;
    console.log(data, "next data check");
    data.forEach((item: any) => {
      if (item.Metrics_Id > maxMetricsId) {
        maxMetricsId = item.Metrics_Id;
      }
    });
    return maxMetricsId + 1;
  }

  const handleAddRow = (accordionName: string, length: number) => {
    const datas: any = length ? fieldsData(accordionName).slice(0, 1) : "";
    const data: any = fieldsData(accordionName);
    const nextMetricsId = getNextMetricsId(AccordianData);
    const newRow = {
      Metrics_Id: nextMetricsId ? nextMetricsId : 0,
      Metrics: updatedFieldName(accordionName),
      Metrics_Type: data.length > 0 ? datas[0].Metrics_Type : "",
      Range_From: "",
      Range_To: "",
      Rating: "",
      IsActive: true,
    };

    switch (accordionName) {
      case "serviceScoring":
        prependServiceScoring(newRow);
        setActiveRowAccordion(accordionName);
        setEditRowId(newRow.Metrics_Id);
        setIsNewRow(true);
        setIsEditing(true);
        break;
      case "aqResponseType":
        prependAQResponseType(newRow);
        setActiveRowAccordion(accordionName);
        setEditRowId(newRow.Metrics_Id);
        setIsNewRow(true);
        setIsEditing(true);
        break;
      case "deviationMatrix":
        prependDeviationMatrix(newRow);
        setActiveRowAccordion(accordionName);
        setEditRowId(newRow.Metrics_Id);
        setIsNewRow(true);
        setIsEditing(true);
        break;
      default:
        break;
    }
  };

  const handleCancelEditRow = (accordionName: string, id: string) => {
    handleClose();
    const rowIndex = getValues(accordionName).findIndex((row: FormData) => row.Metrics_Id === id);
    if (rowIndex === -1) return;

    if (editingRow[accordionName] === id) {
      if (originalData[accordionName] && originalData[accordionName][id]) {
        // Restore original data if it was saved before
        const originalRow = originalData[accordionName][id];
        setValue(`${accordionName}.${rowIndex}.Metrics_Type`, originalRow.Metrics_Type);
        setValue(`${accordionName}.${rowIndex}.Range_From`, originalRow.Range_From);
        setValue(`${accordionName}.${rowIndex}.Range_To`, originalRow.Range_To);
        setValue(`${accordionName}.${rowIndex}.Rating`, originalRow.Rating);
      } else {
        // Remove the newly added row
        switch (accordionName) {
          case "serviceScoring":
            removeServiceScoring(rowIndex);
            break;
          case "aqResponseType":
            removeAQResponseType(rowIndex);
            break;
          case "deviationMatrix":
            removeDeviationMatrix(rowIndex);
            break;
        }
      }
    }

    setEditingRow((prevEditingRow) => ({
      ...prevEditingRow,
      [accordionName]: null,
    }));
  };

  const handleEditRow = (accordionName: string, id: string) => {
    handleClose();
    const rowIndex = getValues(accordionName).findIndex((row: FormData) => row.Metrics_Id === id);
    if (rowIndex === -1) return;

    const row = getValues(`${accordionName}.${rowIndex}`);
    setOriginalData((prevData) => ({
      ...prevData,
      [accordionName]: {
        ...prevData[accordionName],
        [id]: row,
      },
    }));

    setEditingRow((prevEditingRow) => ({
      ...prevEditingRow,
      [accordionName]: id,
    }));
  };
  const getFirstMetricId = (fields: any[]) => {
    if (fields && fields.length > 0) {
      return fields[0].id;
    }
    return null;
  };

  const cancelEditingRow = (accordionName: string, rowId: string) => {
    const index =
      deviationMatrixFields && deviationMatrixFields.length > 0 ? getFirstMetricId(deviationMatrixFields) : null;

    if (index !== null) {
      switch (accordionName) {
        case "serviceScoring":
          removeServiceScoring(index);
          break;
        case "aqResponseType":
          removeAQResponseType(index);
          break;
        case "deviationMatrix":
          removeDeviationMatrix(index);
          break;
        default:
          console.warn(`Unhandled accordion name: ${accordionName}`);
          return;
      }
      setIsNewRow(null);
    }
  };

  const handleSaveRow = async (accordionName: string, data: string) => {
    const rowIndex = getValues(accordionName).findIndex((row: FormData) => row.Metrics_Id === id);
    const isValid =
      (await trigger(`${accordionName}.${rowIndex}`)) &&
      getRangeFromError == null &&
      getRangeToError == null &&
      getRatingError == null;
    console.log(getRangeFromError, getRangeToError, getRatingError, isValid, "Valid Data Content");
    if (isValid) {
      const updatedRow: any = data;

      const JsonDataForDisplay: any = {
        Metrics: accordion.filter((row: any) => row.fieldName === accordionName)?.[0].title,
        "Range Type": updatedRow.Metrics_Type || "",
        "Range From": Number(updatedRow.Range_From) || 0,
        "Range To": Number(updatedRow.Range_To) || 0,
        Rating: updatedRow.Rating || "",
        [updatedRow.Metrics_Id === 0 || isNewRow ? "Create By" : "Modified By"]: user?.UserName,
        IsActive: updatedRow?.IsActive == "Active" || updatedRow?.IsActive == true ? "true" : "false",
        Status: 1,
        Updated_By: user?.UserName,
        Updated_On: new Date().toISOString(),
        // Approved_By: "",
        Approved_On: new Date().toISOString(),
      };
      dispatch(updateLoader(true));
      const formattedRow: any = {
        Metrics_Id: isNewRow ? 0 : updatedRow.Metrics_Id || 0,
        Metrics: accordion.filter((row: any) => row.fieldName === accordionName)?.[0].title,
        Metrics_Type: updatedRow.Metrics_Type || "",
        Range_From: Number(updatedRow.Range_From) || 0,
        Range_To: Number(updatedRow.Range_To) || 0,
        Rating: updatedRow.Rating || "",
        IsActive: updatedRow?.IsActive == "Active" || updatedRow?.IsActive == true ? true : false,
        Status: 1,
        Updated_By: user?.UserName,
        Updated_On: new Date().toISOString(),
        // Approved_By: "",
        Approved_On: new Date().toISOString(),
        ModuleName: "Metrics Master",
        Activity: updatedRow.Metrics_Id === 0 || isNewRow ? "Create" : "Edit",
        UserId: userLoginResponse?.UserId,
        MenuId: permssionPage?.MenuId,
        Form_Id: 25,
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
      };

      const resultAction = await dispatch(addMetricsMaster(formattedRow));
      if (addMetricsMaster.fulfilled.match(resultAction)) {
        handleClose();
        setTimeout(() => {
          setEditRowId(null);
          setSelectedRow(null);
          // fetchData();
        }, 400);

        setEditingRow((prevEditingRow) => ({
          ...prevEditingRow,
          [accordionName]: null,
        }));
      }
    }
  };

  const handleDeleteRow = (accordionName: string, dataToDelete: any) => {
    setIsDeleteData(() => ({
      dataToDelete,
      accordionName,
    }));

    handleClose();
  };
  const handleVersionHistory = (accordionName: string, id: string) => {
    dispatch(
      getMetricsHistoryByID({
        MetricsID: id,
        FormID: 25,
      })
    );
    setIsVersionHistory(true);
    handleClose();
  };

  const accordion = [
    { id: 1, fieldName: "serviceScoring", title: "Service Scoring" },
    { id: 2, fieldName: "aqResponseType", title: "AQ Response Type" },
    { id: 3, fieldName: "deviationMatrix", title: "Deviation Matrix" },
  ];
  const theme = useTheme();
  const columns = ["Id", "Metrics", "Range or Single", "Range From", "Range To", "Rating", "Action"];
  const Metrics_Type: RangeTypeItem[] = [
    { LovId: "Range", Options: "Range" },
    { LovId: "Single", Options: "Single" },
  ];

  const deleteSelectedList = async () => {
    const { accordionName, dataToDelete } = isDeleteData;
    const JsonDataForDisplay: any = {
      Metrics: accordion.filter((row: any) => row.fieldName === accordionName)?.[0].title,
      "Range Type": dataToDelete.Metrics_Type || "",
      "Range From": Number(dataToDelete.Range_From) || 0,
      "Range To": Number(dataToDelete.Range_To) || 0,
      Rating: dataToDelete.Rating || "",
      [dataToDelete.Metrics_Id === 0 || isNewRow ? "Create By" : "Modified By"]: user?.UserName,
      IsActive: dataToDelete?.IsActive == "Active" || dataToDelete?.IsActive == true ? "false" : "true",
      Activity: dataToDelete?.IsActive == "Active" || dataToDelete?.IsActive == true ? "Inactive" : "Active",
      Status: 1,
      Updated_By: user?.UserName,
      Updated_On: new Date().toISOString(),
      Approved_By: "",
      Approved_On: new Date().toISOString(),
    };

    const formattedRow: any = {
      Metrics_Id: isNewRow ? 0 : dataToDelete.Metrics_Id || 0,
      Metrics: accordion.filter((row: any) => row.fieldName === accordionName)?.[0].title,
      Metrics_Type: dataToDelete.Metrics_Type || "",
      Range_From: Number(dataToDelete.Range_From) || 0,
      Range_To: Number(dataToDelete.Range_To) || 0,
      Rating: dataToDelete.Rating || "",
      IsActive: dataToDelete?.IsActive == "Active" || dataToDelete?.IsActive == true ? false : true,
      Status: 1,
      Updated_By: user?.UserName,
      Updated_On: new Date().toISOString(),
      Approved_By: "",
      Approved_On: new Date().toISOString(),
      ModuleName: "Metrics Master",
      Activity: dataToDelete?.IsActive == "Active" || dataToDelete?.IsActive == true ? "Inactive" : "Active",
      UserId: userLoginResponse?.UserId,
      MenuId: permssionPage?.MenuId,
      Form_Id: 25,
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
    };

    const resultAction = await dispatch(deleteMetricsMaster(formattedRow));
    // if (deleteMetricsMaster.fulfilled.match(resultAction)) {
    //   fetchData();
    // }
  };

  const fields = (acc: any) => {
    return acc.fieldName === "serviceScoring" || "Service Scoring"
      ? serviceScoringFields
      : acc.fieldName === "aqResponseType" || "AQ Response Type"
      ? aqResponseTypeFields
      : acc.fieldName === "deviationMatrix" || "Deviation Matrix"
      ? deviationMatrixFields
      : "";
  };

  const fieldsData = (fieldName: any) => {
    let returnData: any;

    switch (fieldName) {
      case "serviceScoring":
      case "Service Scoring":
        returnData = serviceScoringFields;
        break;
      case "aqResponseType":
      case "AQ Response Type":
        returnData = aqResponseTypeFields;
        break;
      case "deviationMatrix":
      case "Deviation Matrix":
        returnData = deviationMatrixFields;
        break;
      default:
        returnData = [];
    }

    return returnData;
  };

  const updatedFieldName = (fieldName: any) => {
    return fieldName === "serviceScoring"
      ? "Service Scoring"
      : fieldName === "aqResponseType"
      ? "AQ Response Type"
      : fieldName === "deviationMatrix"
      ? "Deviation Matrix"
      : "";
  };
  const initialFieldName = (fieldName: any) => {
    return fieldName === "Service Scoring"
      ? "serviceScoring"
      : fieldName === "AQ Response Type"
      ? "aqResponseType"
      : fieldName === "Deviation Matrix"
      ? "deviationMatrix"
      : "";
  };
  const onSubmit = (data: any, callback: (data: any) => void) => {
    if (data) {
      callback(data);
    }
  };

  const handleSaveAction = async (row: any) => {
    await handleSubmit((data) =>
      onSubmit(data, (validatedData) => {
        handleSaveRow(initialFieldName(row.Metrics), row);
      })
    )();
  };
  const clrStateErrors = () => {
    setRangeFromError(null);
    setRangeToError(null);
    setRatingError(null);
  };
  const handleAction = async (row: any, action: any) => {
    switch (action) {
      case "edit":
        clrStateErrors();
        clearErrors();
        handleClose();
        setIsEditing(true);
        setTimeout(() => {
          setEditRowId(row.Metrics_Id);
        }, 400);
        setTimeout(() => {
          if (isEditing) {
            setSelectedRow(null);
            setIsNewRow(null);
            clearErrors();
          }
        }, 500);
        break;
      case "cancel":
        setTimeout(() => {
          setIsEditing(false);
          setEditRowId(null);
          setSelectedRow(null);
          setIsNewRow(null);
          // fetchData();
          handleClose();
        }, 400);
        if (isNewRow) {
          cancelEditingRow(initialFieldName(row.Metrics), row);
        }
        break;
      case "save":
        await handleSaveAction(row);
        break;
      case "delete":
        handleDeleteRow(initialFieldName(row.Metrics), row);
        handleClose();
        setTimeout(() => {
          setEditRowId(null);
          setSelectedRow(null);
          setIsNewRow(null);
          // fetchData();
        }, 400);
        break;
      case "Version History":
        handleVersionHistory(initialFieldName(row.Metrics), row.Metrics_Id);
        break;
      default:
        console.error("Unknown action:", action);
    }
  };

  // // Function to check if ranges are continuous
  // const validateContinuousRanges = (rows: any[]): boolean => {
  //   // Sort rows by Range_From
  //   const sortedRows = [...rows].sort((a, b) => a.Range_From - b.Range_From);

  //   // Check for gaps between ranges
  //   for (let i = 1; i < sortedRows.length; i++) {
  //     const previousRangeEnd = sortedRows[i - 1].Range_To;
  //     const currentRangeStart = sortedRows[i].Range_From;

  //     // If there's a gap, ranges are not continuous
  //     if (currentRangeStart !== previousRangeEnd + 1) {
  //       return false;
  //     }
  //   }

  //   return true;
  // };
  const validateContinuousRanges = (rows: any[]): boolean => {
    // Sort rows based on Range_From
    const sortedRows = [...rows].sort((a, b) => Number(a.Range_From) - Number(b.Range_From));

    for (let i = 1; i < sortedRows.length; i++) {
      const previousRow = sortedRows[i - 1];
      const currentRow = sortedRows[i];

      // Check if the current Range_From is exactly 1 unit greater than the previous Range_To
      if (Number(currentRow.Range_From) !== Number(previousRow.Range_To) + 1) {
        return false; // Found a gap
      }
    }
    return true; // No gaps found
  };

  const RenderRows = (acc: any) => {
    const { fieldName, title } = acc;
    // const updatedAccordion = fieldsData(title);
    const updatedAccordion = fieldsData(title);
    const removeUnwantedFields = (obj: any, fieldsToRemove: string[], numericFields: string[] = []): any => {
      return Object.keys(obj)
        .filter((key) => !fieldsToRemove.includes(key))
        .reduce((res, key) => {
          let value = obj[key as keyof any];
          if (numericFields.includes(key)) {
            value = Number(value);
          }
          res[key as keyof any] = value;
          return res;
        }, {} as any);
    };

    const isRowDirty = (rowIndex: number) => {
      const currentValues = getValues(`${fieldName}[${rowIndex}]`) as any;
      const initialValues = updatedAccordion[rowIndex] as any;
      const numericFields = ["Range_From", "Range_To"];
      const filteredCurrentValues = removeUnwantedFields(currentValues, ["id"], numericFields);
      const filteredInitialValues = removeUnwantedFields(initialValues, ["id"], numericFields);
      const isDirty = JSON.stringify(filteredCurrentValues) !== JSON.stringify(filteredInitialValues);
      setIsRowDirtys((prev) => ({ ...prev, [rowIndex]: isDirty }));
      return isDirty;
    };

    const validateRangeOverlap = (
      rows: any[],
      newRangeFrom: number,
      newRangeTo: number | undefined,
      currentIndex: number
    ) => {
      if (newRangeTo === undefined || isNaN(newRangeTo)) {
        return false;
      }
      return rows.every((row: any, index: number) => {
        if (currentIndex !== -1 && index === currentIndex) {
          return true;
        }
        const existingRangeFrom = row.Range_From;
        const existingRangeTo = row.Range_To;
        const noOverlap = newRangeTo < existingRangeFrom || newRangeFrom > existingRangeTo;
        return noOverlap;
      });
    };

    const validateRanges = (
      rows: any[],
      newRangeFrom: number,
      newRangeTo: number,
      currentIndex: number
    ): string | true => {
      const newRow = { Range_From: newRangeFrom, Range_To: newRangeTo };
      const updatedRows = [...rows];
      if (currentIndex !== -1) {
        updatedRows[currentIndex] = newRow;
      } else {
        updatedRows.push(newRow);
      }
      updatedRows.sort((a: any, b: any) => Number(a.Range_From) - Number(b.Range_From));
      for (let i = 0; i < updatedRows.length - 1; i++) {
        const current = updatedRows[i];
        const next = updatedRows[i + 1];
        if (Number(current.Range_To) !== Number(next.Range_From) - 1) {
          return "Ranges are not continuous";
        }
      }
      const noOverlap = validateRangeOverlap(rows, newRangeFrom, newRangeTo, currentIndex);
      if (!noOverlap) {
        return "New range overlaps with existing ranges.";
      }
      return true;
    };

    const validateRatingUnique = (rows: any, newRating: string, currentIndex: number) => {
      return rows.every((row: any, index: number) => {
        if (index === currentIndex) return true;
        return row.Rating !== newRating;
      });
    };

    const anyRowDirty = Object.values(isRowDirtys).some((isDirty) => isDirty);
    return (
      <Table>
        <TableBody>
          {updatedAccordion.map((data: any, idx: number) => {
            let updatedEditRowId = editRowId !== -1 ? editRowId - 1 : 0;
            const isEdit = editRowId === data.Metrics_Id && activeRowAccordion === acc.fieldName && isEditing;
            console.log(editRowId, data.Metrics_Id, activeRowAccordion, acc.fieldName, isEditing, "Edit Data Data");
            const index = isNewRow ? 0 : editRowId ? editRowId : data.Metrics_Id;
            const rangeTypeWatch = getValues(`${fieldName}[${idx}].Metrics_Type`);
            const rangeFromValue = Number(watch(`${fieldName}[${idx}].Range_From`));
            const rangeToValue = Number(watch(`${fieldName}[${idx}].Range_To`));
            const isDisabled = !(
              editRowId === null ||
              (editRowId === data.Metrics_Id && activeRowAccordion === acc.fieldName)
            );
            console.log(isDisabled, editRowId, data.Metrics_Id, activeRowAccordion, acc.fieldName, "acc.fieldName");

            const isValid = () => {
              trigger(`${fieldName}[${idx}].Range_To`);
              trigger(`${fieldName}[${idx}].Range_From`);
              trigger(`${fieldName}[${idx}].Rating`);
            };

            return (
              <TableRow key={data.Metrics_Id} sx={{ border: "2px solid #f1f1f1", width: "auto" }}>
                <TableCell sx={{ border: "2px solid #f1f1f1", width: "221px" }}>
                  {isEdit && updatedAccordion.length <= 1 ? (
                    <Controller
                      name={`${fieldName}[${idx}].Metrics_Type`}
                      control={control}
                      rules={{ required: "Range Type is required" }}
                      defaultValue={data.Metrics_Type}
                      render={({ field }) => (
                        <Box sx={{ width: "auto" }}>
                          <CustomSelect
                            {...field}
                            valueKey={"LovId"}
                            optionLabelKey="Options"
                            label="Range Type"
                            options={Metrics_Type}
                            required={true}
                            onChange={(e) => field.onChange(e)}
                            error={!!errors?.[`${fieldName}`]?.[idx]?.Metrics_Type}
                            helperText={errors?.[`${fieldName}`]?.[idx]?.Metrics_Type?.message}
                          />
                        </Box>
                      )}
                    />
                  ) : (
                    data.Metrics_Type
                  )}
                </TableCell>

                <TableCell sx={{ border: "2px solid #f1f1f1", width: "221px" }}>
                  {isEdit ? (
                    <Controller
                      name={`${fieldName}[${idx}].Range_From`}
                      control={control}
                      rules={{
                        required: "Range From is required",
                        validate: {
                          rangeComparison: (value) => {
                            if (Number(value) >= Number(getValues(`${fieldName}[${idx}].Range_To`))) {
                              return "Range To cannot be greater than or equal to Range From";
                            }
                            return true;
                          },
                          rangeValidation: (value) => {
                            const validationError = validateRanges(
                              updatedAccordion,
                              Number(watch(`${fieldName}[${idx}].Range_To`)),
                              Number(value),
                              idx
                            );
                            if (validationError) {
                              return validationError;
                            }
                            return true;
                          },
                        },
                      }}
                      defaultValue={data.Range_From}
                      render={({ field }) => (
                        <CustomNumberField
                          {...field}
                          label="Range From"
                          isDecimal={true}
                          inputProps={{ min: 1 }}
                          onChange={(e) => {
                            field.onChange(e);
                            trigger(`${fieldName}[${idx}].Range_From`);
                            if (getValues(`${fieldName}[${idx}].Metrics_Type`) === "Single") {
                              setValue(`${fieldName}[${idx}].Range_To`, getValues(`${fieldName}[${idx}].Range_From`));
                            }
                            if (Number(getValues(`${fieldName}[${idx}].Range_To`))) {
                              trigger(`${fieldName}[${idx}].Range_To`);
                            }
                            if (Number(e) && Number(getValues(`${fieldName}[${idx}].Range_To`))) {
                              trigger(`${fieldName}[${idx}].Range_To`);
                            }
                            if (Number(e) && Number(e) < Number(getValues(`${fieldName}[${idx}].Range_To`))) {
                              return "Range To cannot be less than Range From";
                            }
                            isRowDirty(idx);
                          }}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          required
                          error={!!errors?.[fieldName]?.[idx]?.Range_From || getRangeFromError !== null}
                          helperText={
                            errors?.[fieldName]?.[idx]?.Range_From?.message?.toString() || getRangeFromError || ""
                          }
                        />
                      )}
                    />
                  ) : (
                    data.Range_From
                  )}
                </TableCell>

                <TableCell sx={{ border: "2px solid #f1f1f1", width: "221px" }}>
                  {isEdit ? (
                    <Controller
                      name={`${fieldName}[${idx}].Range_To`}
                      control={control}
                      rules={{
                        required: "Range To is required",
                        validate: {
                          rangeComparison: (value) => {
                            if (Number(value) <= Number(getValues(`${fieldName}[${idx}].Range_From`))) {
                              return "Range To cannot be less than or equal to Range From";
                            }
                            return true;
                          },
                          rangeValidation: (value) => {
                            const validationError = validateRanges(
                              updatedAccordion,
                              Number(watch(`${fieldName}[${idx}].Range_From`)),
                              Number(value),
                              idx
                            );
                            if (validationError) {
                              return validationError;
                            }
                            return true;
                          },
                        },
                      }}
                      defaultValue={data.Range_To}
                      render={({ field }) => (
                        <CustomNumberField
                          {...field}
                          label="Range To"
                          inputProps={{ min: 1 }}
                          isDecimal={true}
                          readOnly={rangeTypeWatch === "Single"}
                          onChange={(e) => {
                            field.onChange(e);
                            trigger(`${fieldName}[${idx}].Range_To`);
                            if (Number(e) && Number(getValues(`${fieldName}[${idx}].Range_From`))) {
                              trigger(`${fieldName}[${idx}].Range_From`);
                            }
                            if (
                              rangeTypeWatch &&
                              rangeTypeWatch === "Range" &&
                              Number(getValues(`${fieldName}[${idx}].Range_From`)) &&
                              Number(getValues(`${fieldName}[${idx}].Range_From`)) > Number(e) &&
                              Number(e) > 0
                            ) {
                              setRangeToError("Range From cannot be greater than Range To");
                              // return "The start range cannot be greater than the end range.";
                            } else if (Number(e) === 0) {
                              setRangeToError(null);
                            }
                            isRowDirty(idx);
                            const isValid = validateRangeOverlap(
                              updatedAccordion,
                              Number(e),
                              Number(getValues(`${fieldName}[${idx}].Range_From`)),
                              idx
                            );
                            console.log(isValid, "isValid Range To");
                            if (!isValid) {
                              setRangeToError("New range overlaps with existing ranges");
                              return "New range overlaps with existing ranges";
                            } else {
                              setRangeToError(null);
                            }
                          }}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            // Prevent invalid key entries
                            if (e.key === "-" || e.key === "e") {
                              e.preventDefault();
                            }
                          }}
                          required
                          error={!!errors?.[fieldName]?.[idx]?.Range_To || getRangeToError !== null}
                          helperText={
                            errors?.[fieldName]?.[idx]?.Range_To?.message?.toString() || getRangeToError || ""
                          }
                        />
                      )}
                    />
                  ) : (
                    data.Range_To
                  )}
                </TableCell>

                <TableCell sx={{ border: "2px solid #f1f1f1", width: "221px" }}>
                  {isEdit ? (
                    <Controller
                      name={`${fieldName}[${idx}].Rating`}
                      control={control}
                      rules={{
                        required: "Rating is required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                          maxLength: (value: string) => !value || value.length <= 50 || "Maximum 50 characters allowed",
                          valueLimitation: (value) =>
                            !value || /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters",
                          checkRangeOverlap: (value) => {
                            const isValid = validateRatingUnique(updatedAccordion, value, idx);
                            if (!isValid) {
                              return "Rating is overlaps with existing ratings";
                            }
                            return true;
                          },
                        },
                      }}
                      defaultValue={data.Rating}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          label="Rating"
                          required
                          onChange={(e) => {
                            field.onChange(e);
                            trigger(`${fieldName}[${idx}].Rating`);
                            isRowDirty(idx);
                            const isValid = validateRatingUnique(updatedAccordion, e, idx);
                            if (!isValid) {
                              return "Rating is overlaps with existing ratings";
                            }

                            if (!isValid) {
                              setRatingError("New range overlaps with existing ranges");
                              return "New range overlaps with existing ranges";
                            } else {
                              setRatingError(null);
                            }
                          }}
                          error={!!errors?.[fieldName]?.[idx]?.Rating || getRatingError !== null}
                          helperText={errors?.[fieldName]?.[idx]?.Rating?.message?.toString() || getRatingError || ""}
                        />
                      )}
                    />
                  ) : (
                    data.Rating
                  )}
                </TableCell>

                {
                  <TableCell sx={{ border: "2px solid #f1f1f1", width: "110px" }}>
                    <IconButton
                      className={styles.iconButton}
                      disabled={isDisabled}
                      onClick={(e) => handleClick(e, data)}
                    >
                      <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"more-vertical"} />
                    </IconButton>

                    <StyledMenu
                      id={id}
                      open={open && selectedRow?.Metrics_Id === data.Metrics_Id}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                    >
                      {data.Metrics_Id === editRowId && isEditing ? (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <MenuItem
                            sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                            onClick={() => {
                              isValid();
                              if (Object.entries(errors).length === 0) {
                                handleAction(getValues(`${fieldName}[${idx}]`), "save");
                              }
                            }}
                            disabled={!anyRowDirty}
                          >
                            <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"save"} />
                            <Typography>{isNewRow ? "Save" : "Update"}</Typography>
                          </MenuItem>
                          <MenuItem
                            sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                            onClick={() => {
                              handleAction(selectedRow, "cancel");
                            }}
                          >
                            <CustomSvg size={"20px"} name={"x"} />
                            <Typography>Cancel</Typography>
                          </MenuItem>
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <MenuItem
                            sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                            disabled={selectedRow?.IsActive === "Inactive"}
                            onClick={() => {
                              handleClose();
                              handleAction(getValues(`${fieldName}[${idx}]`), "edit");
                            }}
                          >
                            <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"edit"} />
                            <Typography>Edit</Typography>
                          </MenuItem>
                          <MenuItem
                            sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                            onClick={() => handleAction(getValues(`${fieldName}[${idx}]`), "delete")}
                          >
                            {/* <CustomSvg size={"20px"} name={"power"} /> */}
                            {(selectedRow?.IsActive && selectedRow?.IsActive === "Inactive") ||
                            (selectedRow?.IsActive && selectedRow?.IsActive === true) ? (
                              <CustomSvg
                                color={`${theme.palette.text.secondary}`}
                                size={"20px"}
                                name={"minus-circle"}
                              />
                            ) : (
                              <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"power"} />
                            )}
                            <Typography>{selectedRow?.IsActive === "Inactive" ? "Active" : "Inactive"}</Typography>
                          </MenuItem>
                          <MenuItem
                            sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                            onClick={() => handleAction(selectedRow, "Version History")}
                          >
                            <CustomSvg color={`black`} size={"20px"} name={"history"} />
                            <Typography>Version History</Typography>
                          </MenuItem>
                        </Box>
                      )}
                    </StyledMenu>
                  </TableCell>
                }
              </TableRow>
            );
          })}
          {updatedAccordion.length === 0 ? (
            <TableRow sx={{ border: "2px solid #f1f1f1", width: "auto" }}>
              <TableCell
                sx={{ border: "2px solid #f1f1f1", width: "calc(100vw - 160px)", textAlign: "center" }}
                colSpan={6}
              >
                No Records Found
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box sx={{ width: "100%", overflow: "auto" }}>
          <Table aria-label="metrics master table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                      width:
                        column === "Id"
                          ? "10px"
                          : column === "Matrix"
                          ? "150px"
                          : column === "Action"
                          ? "100px"
                          : "200px",
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow
                sx={{
                  textAlign: "center",
                  fontFamily: "aptos-bold",
                  border: "2px solid #f1f1f1",
                }}
              >
                <TableCell
                  colSpan={7}
                  sx={{
                    textAlign: "center",
                    fontFamily: "aptos",
                    border: "2px solid #f1f1f1",
                    padding: "0px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: expanded ? "490px" : "300px",
                      overflow: "hidden",
                      // border: "2px solid #f1f1f1",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        overflowY: "auto",
                        maxHeight: "100%",
                      }}
                    >
                      {isLoading ? (
                        <TableRow sx={{ fontFamily: "aptos-bold", border: "2px solid #f1f1f1" }}>
                          {
                            <TableCell colSpan={7}>
                              <Box
                                sx={{
                                  width: "100%",
                                  maxHeight: "450px",
                                  overflow: "auto",
                                }}
                              >
                                <Box sx={{ width: "100%", mt: "0px" }}>
                                  <Box>
                                    <Box sx={{ width: "100%", overflow: "auto" }}>
                                      <ProgressBar showProgress={isLoading} />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          }
                        </TableRow>
                      ) : null}{" "}
                      {accordion.map((acc, accIndex) => {
                        const expandedAccordion = expanded === acc.fieldName ? updatedFieldName(acc.fieldName) : "";
                        return (
                          <TableRow
                            sx={{ fontFamily: "aptos-bold", border: expanded ? "2px solid #f1f1f1" : "none" }}
                            key={acc.id}
                          >
                            {
                              <TableCell
                                colSpan={1}
                                sx={{ display: "flex", padding: "32px", paddingBottom: 0, borderBottom: "none" }}
                              >
                                <Box>{acc.id}</Box>
                              </TableCell>
                            }
                            {
                              <TableCell colSpan={6} sx={{ borderBottom: "none" }}>
                                <CustomAccordion
                                  key={accIndex}
                                  expanded={expanded === acc.fieldName}
                                  handleChange={handleChange(acc.fieldName)}
                                  title={acc.title}
                                  details={
                                    <Box sx={{ width: "100%" }}>
                                      {
                                        <Stack spacing={2}>
                                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Box sx={{ display: "flex", gap: "20px", justifyContent: "end" }}>
                                              <CustomButton
                                                variant="contained"
                                                name={"Add"}
                                                disabled={editingRow[acc.fieldName] !== null || isEditing}
                                                // disabled={open ? editRowId : false || isLoading}
                                                onClick={() => {
                                                  clrStateErrors();
                                                  handleAddRow(acc.fieldName, fieldsData(acc.fieldName).length);
                                                }}
                                                size="small"
                                              />
                                            </Box>
                                          </Box>
                                          <Box sx={{ marginLeft: "180px !important" }}>
                                            <Box
                                              sx={{
                                                width: "100%",
                                                maxHeight: "250px",
                                                overflow: "auto",
                                                paddingRight: "10px",
                                              }}
                                            >
                                              <Table>
                                                {acc.fieldName === expanded && <TableBody>{RenderRows(acc)}</TableBody>}
                                              </Table>
                                            </Box>
                                          </Box>
                                        </Stack>
                                      }
                                    </Box>
                                  }
                                ></CustomAccordion>
                              </TableCell>
                            }
                          </TableRow>
                        );
                      })}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <CustomDialog
          show={isVersionHistory}
          onHide={() => {
            dispatch(updateMetricsMasterVersionHistory([]));
            setIsVersionHistory(false);
          }}
          maxWidth={"lg"}
          header={"Metrics Master Version History"}
          contentNode={
            <>
              {" "}
              <MaterialDynamicGrid
                data={versionHistoryData}
                rows={MetricsMasterversionHistory.MetricsMasterversionHistory}
                handleAction={{}}
                enableZoomIcon={false}
                showhistory={false}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Metrics Master Version History"
              />
            </>
          }
        />
        <ModalPopUp
          onDeleteMethod={() => deleteSelectedList()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          isLoading={isLoading}
          text={`Are you sure you want to ${
            isDeleteData?.dataToDelete?.IsActive === "Active" || isDeleteData?.dataToDelete?.IsActive === true
              ? "deactivate"
              : "activate"
          } the selected Master`}
          ActionName={
            isDeleteData?.dataToDelete?.IsActive === "Active" || isDeleteData?.dataToDelete?.IsActive === true
              ? "Inactive"
              : "Active"
          }
        />
        {/* 
        {isPromptVisible && (
          <ModalPopUp
            onDeleteMethod={() => {
              handlePromptResponse(false);
            }}
            isDelete={true}
            onHide={() => setIsPromptVisible(false)}
            show={isPromptVisible ? true : false}
            header={"Confirm"}
            isLoading={isLoading}
            text={`Are you still editing ?`}
            ActionName={"Yes"}
          />
        )} */}
        {/* {editRowId && (isNewRow === false || isNewRow === null) && (
          <RecordLockDialog recordId={editRowId} moduleName="Metrics Master" setIsEditing={setIsEditing} />
        )} */}
      </Box>
    </Box>
  );
};

export default AccordionTable;
