import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { COST_APPROVAL_MASTER_URL } from "./endpoints/costApprovalMetrics";
import { ICostApprovalMetricTypeResponse, IVHColumnRes } from "../../../models/contractManagementModels/costApprovalMetricModel";

const getallCostApprovalMetricList = (requestBody: any): Promise<ICostApprovalMetricTypeResponse> =>
  axiosInstance
    .post(COST_APPROVAL_MASTER_URL.GetAllCostApprovalMetrics, requestBody)
    .then((response: AxiosResponse<ICostApprovalMetricTypeResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    
const createCostApprovalMetric = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(COST_APPROVAL_MASTER_URL.CreateCostApprovalMetrics, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateCostApprovalMetric = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(COST_APPROVAL_MASTER_URL.UpdateCostApprovalMetrics, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const   activeInactiveCostApprovalMetric = (requestBody: any): Promise<any> =>
  axiosInstance
    .put(COST_APPROVAL_MASTER_URL.ActiveInActiveCostApprovalMetrics, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        console.log(response.data,"DataResponse")
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
    
    const getCostApprovalMetricsById = (id: number): Promise<IVHColumnRes> => 
      axiosInstance
        .get(`VendorContract/GetCostApprovalMetricsById`, { params: { id } })
        .then((response: AxiosResponse<IVHColumnRes>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error Occurred: " + response.statusText);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });

const costApprovalMetricService = {
getallCostApprovalMetricList,
  createCostApprovalMetric,
  updateCostApprovalMetric,
  activeInactiveCostApprovalMetric,
  getCostApprovalMetricsById,
  
//   getVersionHistory,
};

export default costApprovalMetricService;
