import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import CustomAccordion from "../../../../common/dynamicAccordion/CustomAccordion";
import { useFormContext } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../../store/store";
import { useSelector } from "react-redux";
import { BasicDetails } from "./BasicDetails";
import { RegulatoryDetails } from "./RegulatoryDetails";
import { OtherDetails } from "./OtherDetails";
import {
  clearSignzyData,
  GSTSearch,
  PantoCin,
  PanToGst,
  SimpleSearchByCin,
} from "../../../../store/slices/signzySlice";
import { LovConfig } from "../../../../config/config";
import { FetchFromThirdParty } from "../../../fetchFromThirdParty/FetchFromThirdParty";
import { ConstitutionDetails } from "./ConstitutionDetails";
import { getCompanyBaseDetails } from "../../../../store/services/config/ProbeService";
import { ProbeCompanyComprehensive } from "../../../../store/slices/ProbeData";
import { updateLoader } from "../../../../store/slices/loader";
interface IFetchDataProps {
  category: any;
  ID: any;
}

export const VendorDetails: React.FC<any> = ({ isEditData, edit, view, SignzyDatas, isAqmResponse, isDraftData }) => {
  const dispatch = useAppDispatchThunk();
  const [fetchDetails, setFetchDetails] = useState<IFetchDataProps>({
    category: null,
    ID: null,
  });
  const [uniqueIds, setUniqueIds] = useState<any>([]);
  const [isSearchClick, setIsSearchClick] = useState<boolean>(false);
  const [selectedUniqueIdKey, setSelectedUniqueIdKey] = useState<number | undefined>();
  const [expanded, setExpanded] = useState<any>(0);
  const [uniqueIdValue, setUniqueIdValue] = useState<string>("");
  const [RegionalData, setRegionalData] = useState<any[]>([]);
  const [UniqueError, setUniqueError] = useState(false);
  const { CompanyComprehensiveData, CompanyComprehensiveAdded, CompanyComprehensiveFailed, ProbeToasterMsg } = useSelector((state: RootState) => state.ProbeData);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { RegionalTable } = useSelector((state: RootState) => state.RegionalSettingsData);
  const { SignzyData } = useSelector((state: RootState) => state.signzySlice);

  const handleSearch = async () => {
    if (selectedUniqueIdKey) {
      const search = {
        category: uniqueIds.find((data: any) => data?.LovId === selectedUniqueIdKey)?.Options || "",
        ID: uniqueIdValue,
      };
      setFetchDetails(search);
      console.log(search, "search");

      dispatch(updateLoader(true));

      dispatch(ProbeCompanyComprehensive({
        ID: search.ID,
        identifier_type: search.category,
      }));
      setSelectedUniqueIdKey(undefined);
    }
  };

  useEffect(() => {
    const filteredRegionalData = RegionalTable.filter((item: any) => item.LocationName === "India");
    setRegionalData(filteredRegionalData.filter((item: any) => item.IsActive === "Active"));
  }, [RegionalTable]);

  const clearSearch = () => {
    dispatch(clearSignzyData());
    setFetchDetails({
      category: null,
      ID: null,
    });
    setIsSearchClick(false);
    setUniqueIdValue("");
    setSelectedUniqueIdKey(0);

  };
  console.log(
    isSearchClick,
    SignzyData,
    fetchDetails,
    Object.entries(SignzyData).length === 0 && !isSearchClick,
    "resultresult"
  );
  const {
    handleSubmit: handleVendorDetailsForm,
    reset,
    setValue,
    formState: { errors: errorsVendorDetailsform },
    control,
    watch,
    getValues,
  } = useFormContext();

  useEffect(() => {
    setUniqueIds(lovTable.filter((item: any) => item.Name === LovConfig.UniqueIDType) || []);
  }, [lovTable]);

  useEffect(() => {
    if (CompanyComprehensiveAdded) {
      setUniqueIdValue("");
      setSelectedUniqueIdKey(undefined);
      setFetchDetails({
        category: null,
        ID: null,
      });
    }
    else if (CompanyComprehensiveFailed) {
      setUniqueIdValue("");
      setSelectedUniqueIdKey(undefined);
      setFetchDetails({
        category: null,
        ID: null,
      });
    }

  }, [CompanyComprehensiveAdded, CompanyComprehensiveFailed,]);

  const convertString = (str: any) => {
    return str
        .split("")
        .map((char: any) => {
            if (!isNaN(char) && char !== " ") {
                return "9";
            } else {
                return "X";
            }
        })
        .join("");
};

  const generateRegexFromFormats = (formats: any) => {
    return formats.map((format: any) => {
        const formatData = convertString(format);
        const escapedFormat = formatData.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regexPattern = escapedFormat.replace(/X/g, "[A-Z]").replace(/9/g, "\\d");
        const regex = new RegExp(`^${regexPattern}$`);
        return regex;
    });
}
const uniqueIdsvalue = uniqueIds.find((data: any) => data?.LovId === selectedUniqueIdKey)?.Options || "";

const panFormat = RegionalData.find((data) => data.Equivalent === "PAN")?.Format || /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const cinFormat = RegionalData.find((data) => data.Equivalent === "CIN")?.Format || /^[LU]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;


const handleRemarksChange = (value: string) => {
  setUniqueIdValue(value);


  const isPanValid = new RegExp(generateRegexFromFormats([panFormat]).pop()).test(value);
  const isCinValid = new RegExp(generateRegexFromFormats([cinFormat]).pop()).test(value);
  
  // console.log(panFormat, cinFormat, "panFormat and cinFormat");
  // console.log(isPanValid, "isPanValid");
  // console.log(isCinValid, "isCinValid");

  if (selectedUniqueIdKey) {
      
      if (uniqueIdsvalue === "PAN" && isPanValid) {
        setUniqueError(false); // PAN is valid
      } else if (uniqueIdsvalue === "CIN" && isCinValid) {
        setUniqueError(false); 
      } else {
        setUniqueError(true); 
      }
  }
};

  return (
    <>
      {!isAqmResponse && !edit && !view && (
        <Grid container spacing={2}>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <CustomSelect
              name={"Unique ID"}
              valueKey={"LovId"}
              optionLabelKey="Options"
              options={uniqueIds}
              disabled={view}
              label={"Unique ID Type"}
              readOnly={Object.entries(SignzyData).length !== 0 || isSearchClick}
              value={selectedUniqueIdKey || ""}
              onChange={(i) => {
                setSelectedUniqueIdKey(i);
                // handleSearch();
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <CustomTextField
              name={"Text Input"}
              disabled={view}
              label={"Unique ID"}
              value={uniqueIdValue}
              readOnly={Object.entries(SignzyData).length !== 0 || isSearchClick}
              error={UniqueError ? true : false}
              helperText={UniqueError ? (uniqueIdsvalue === "PAN" ? `Invalid format. Example: ${panFormat}` : `Invalid format. Example: ${cinFormat}`) : ""}
              onChange={(i) => {
                // setUniqueIdValue(i);
                handleRemarksChange(i);
                // handleSearch();
              }}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
              {Object.entries(SignzyData).length === 0 && !isSearchClick ? (
                // <FetchFromThirdParty
                //   displayName={"Fetch Details"}
                //   data={fetchDetails}
                //   handleFunction={() => setIsSearchClick(true)}
                //   disabled={view || !selectedUniqueIdKey || !uniqueIdValue}
                // />
                <CustomButton variant="contained" name={"Fetch Details"} onClick={handleSearch} disabled={view || !selectedUniqueIdKey || !uniqueIdValue || UniqueError} />
              ) : (
                <CustomButton
                  variant="contained"
                  name={"Clear"}
                  valuePass={{ name: "2", id: 2 }}
                  onClick={clearSearch}
                  disabled={view}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <CustomAccordion
        expanded={expanded === 0}
        handleChange={() => setExpanded((pre: any) => (pre === 0 ? null : 0))}
        title="Basic Details"
        details={
          <BasicDetails
            isEditData={isEditData}
            edit={edit}
            view={view}
            control={control}
            setValue={setValue}
            errorsVendorDetailsform={errorsVendorDetailsform}
            SignzyDatas={SignzyDatas}
          />
        }
      />
      <CustomAccordion
        expanded={expanded === 1}
        handleChange={() => setExpanded((pre: any) => (pre === 1 ? null : 1))}
        title="Constitution Details"
        details={
          <ConstitutionDetails
            isEditData={isEditData}
            edit={edit}
            view={view}
            control={control}
            watch={watch}
            errorsVendorDetailsform={errorsVendorDetailsform}
          />
        }
      />
      <CustomAccordion
        expanded={expanded === 2}
        handleChange={() => setExpanded((pre: any) => (pre === 2 ? null : 2))}
        title="Regulator Details"
        details={
          <RegulatoryDetails
            isEditData={isEditData}
            edit={edit}
            view={view}
            control={control}
            watch={watch}
            setValue={setValue}
            errorsVendorDetailsform={errorsVendorDetailsform}
          />
        }
      />
      <CustomAccordion
        expanded={expanded === 3}
        handleChange={() => setExpanded((pre: any) => (pre === 3 ? null : 3))}
        title="Third Party Profiling"
        details={
          <OtherDetails
            isEditData={isEditData}
            edit={edit}
            view={view}
            control={control}
            isDraftData={isDraftData}
            setValue={setValue}
            errorsVendorDetailsform={errorsVendorDetailsform}
          />
        }
      />
    </>
  );
};
