import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState, useAppDispatch } from "../store/store";
import { useSelector } from "react-redux";
import { updateToaster } from "../store/slices/loader";
import { Alert, Snackbar } from "@mui/material";

interface IToasterprops {
  msg: string;
}
const ToasterCompnent: React.FC = () => {
  // console.log("toast",msg)
  const { isToaster, toasterMsg, isTosterFailed } = useSelector((state: RootState) => state.appLoader);

  const[msg , setMsg] = useState('')
  const[isMsg , setIsMsg] = useState(true)
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isToaster && toasterMsg) {
      dispatch(updateToaster({ isToaster: false, toasterMsg: "" }));
      if(isTosterFailed){
        toast.error(toasterMsg);
        setMsg(toasterMsg)
        setIsMsg(true)
      } else {
        toast.success(toasterMsg)
        setMsg(toasterMsg)
        setIsMsg(true)
      }
    }else{
      setIsMsg(false)
    }
 

  }, [isToaster, toasterMsg]);

  const handleClose = () => {
    dispatch(updateToaster({ isToaster: false, toasterMsg: "" }));
  }

  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} />
      {/* <Snackbar open={isMsg}  autoHideDuration={3000} onClose={handleClose}>
  <Alert
    onClose={handleClose}
    severity= {!isTosterFailed ? "success" : "error" }
    variant="filled"
    sx={{ width: '100%' }}
  >
    {msg}
  </Alert>
</Snackbar> */}
    </div>
  );
};

export default ToasterCompnent;
