import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { DepartmentConfig, LovConfig, MasterBulkUpload } from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import { getDepartmentList } from "../../../../store/slices/departmentData";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
export const departmentImportdata: any = [
  {
    Field: "department_Type",
    FieldType: "string",
    DisplayName: "Department Type",
    config: {
      required: true,
    },
  },
  {
    Field: "department_Name",
    FieldType: "string",
    DisplayName: "Department Name",
    config: {
      required: true,
    },
  },
  {
    Field: "department_Code",
    FieldType: "string",
    DisplayName: "Department Code",
    config: {
      required: true,
    },
  },
  {
    Field: "department_Level",
    FieldType: "string",
    DisplayName: "Department Level",
    config: {
      required: false,
    },
  },
  {
    Field: "parent_Department",
    FieldType: "string",
    DisplayName: "Parent Department",
    config: {
      required: false,
    },
  },
];

const DepartmentMasterUploadSheet = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const [departmentTypeList, setDepartmentTypeList] = useState<any[]>([]);
  const [departmentLevelList, setDepartmentLevelList] = useState<any[]>([]);
  const [departmentList, setDepartmentList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    setDepartmentTypeList(lovTable.filter((item: any) => item.Name === LovConfig.Department_Type) || []);
    setDepartmentLevelList(lovTable.filter((item: any) => item.Name === LovConfig.Department_Level) || []);
  }, [lovTable]);

  useEffect(() => {
    setDepartmentList(dataTable || []);
  }, [dataTable]);

  const { setselectedValidation, uploaddata } = context || {};

  const findParentDepartmentLevel = (currentLevel: any) => {
    let level = currentLevel;
    while (level > 1) {
      level -= 1;
      const targetOption = `Level ${level}`;
      const findSelectDepartmentLevel = departmentLevelList.find((item: any) => item.Options === targetOption);
      if (findSelectDepartmentLevel) {
        return findSelectDepartmentLevel.LovId;
      }
    }
    return 0;
  };

  //validation the depatment Fields
  const department_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
      let ValidatedData: any[] = [];
      uploaddata.forEach((item: any , index : number) => {
        let error: string = "";
        let object: any = {
          // ...item,
          department_Type: "",
          department_Type_Id: "",
          department_Name: "",
          department_Code: "",
          department_Level: "",
          department_Level_Id: "",
          parent_Department: "",
          parent_Department_id: "",
          id: index + 1
        };
        let findValidDepatmenttype = departmentTypeList.find(
            (typeitem) => typeitem.Options == item["Department Type"]
          );
          let findValidDepatmentlevel = departmentLevelList.find(
            (typeitem) => typeitem.Options == item["Department Level"]
          );
        departmentImportdata.map((header_item: any) => {
          
          //validation the depatment Type Fields
          if (header_item.Field === "department_Type") {
            if (findValidDepatmenttype?.LovId) {
              object[header_item.Field] = item[header_item.DisplayName];
              object.department_Type_Id = findValidDepatmenttype?.LovId;
            } else {
              if (item[header_item.DisplayName]) {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Department Type,";
              } else {
                error += " Department Type is Required,";
              }
            }
          }
          //validation the depatment Name Fields
          if (header_item.Field === "department_Name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Department Name is Required,";
            }
          }
          if (header_item.Field === "department_Code") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              // error += " Department Code is Required";
            }
          }
          //validation the depatment Level Fields
          if (header_item.Field === "department_Level") {
            if (item[header_item.DisplayName]) {
              //validate if department selected only select Level 1 or sub department selected Not Level 1 , condition applyed
              if (
                findValidDepatmentlevel?.LovId &&
                ((findValidDepatmentlevel?.Options == DepartmentConfig.Department_Level_1 &&
                  findValidDepatmenttype?.Options == DepartmentConfig.Department) ||
                  (findValidDepatmenttype?.Options == DepartmentConfig.Sub_Department &&
                    findValidDepatmentlevel?.Options != DepartmentConfig.Department_Level_1))
              ) {
                object[header_item.Field] = item[header_item.DisplayName];
                object.department_Level_Id = findValidDepatmentlevel?.LovId;
              } else {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Department Level";
              }
            } else {
              error += " Department Level is Required";
            }
          }
          // parent department level validation checked , Not Level 1 if required Parent Department field
          if (
            header_item.Field === "parent_Department" &&
            findValidDepatmenttype &&
            findValidDepatmenttype?.Options == DepartmentConfig.Sub_Department &&
            findValidDepatmentlevel
          ) {
            console.log(findValidDepatmenttype, "findselectedParentObject 1", findValidDepatmentlevel);
            // parent level find
            const currentLevel = parseInt(findValidDepatmentlevel.Options.split(" ")[1]);
            const parentDepartmentLevelId = findParentDepartmentLevel(currentLevel);

            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              // split depatement name from selected value and validate
              let parentselectedvalue = item[header_item.DisplayName].split(" - ")[0];
              let findselectedParentObject = departmentList.find(
                (par_item: any) =>
                  par_item.DepartmentName == parentselectedvalue && par_item.Parent_Level == parentDepartmentLevelId
              );
             
              if (findselectedParentObject && findselectedParentObject?.DepartmentId) {
                object.parent_Department = item[header_item.DisplayName];
                object.parent_Department_Id = findselectedParentObject?.DepartmentId;
              } else {
                object.parent_Department = item[header_item.DisplayName];
                error += " Invalid Parent Department";
              }
            } else {
              error += " Parent Department is Required";
            }
          }
          object.error = error;
        });
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      department_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const hiddenSheet = workbook.addWorksheet("HiddenSheet"); // Hidden sheet for large data lists
      let sheet_Column: any = departmentImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);

      // Get your list data
      let DepLevel = departmentLevelList?.map((item) => item.Options);
      let DepType = departmentTypeList?.map((item) => item.Options);
      let DepList = departmentList?.map((item) => item.DepartmentName + " - " + item.Parent_LevelValue);

      DepList.forEach((item, index) => {
        hiddenSheet.getCell(`A${index + 1}`).value = item;
      });
      hiddenSheet.state = "hidden";

      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        worksheet.getCell(`A${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${DepType.join(",")}"`],
          showErrorMessage: true,
          errorTitle: "Invalid Department Type for",
          error: "Please select an Department Type from the list",
        };

        worksheet.getCell(`B${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: false,
          formulae: [55],
        };

        worksheet.getCell(`C${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: true,
          formulae: [55],
        };

        worksheet.getCell(`D${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${DepLevel.join(",")}"`],
          
          errorTitle: "Invalid Department Level for",
          error: "Please select an Department Level from the list",
        };

        worksheet.getCell(`E${row}`).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [`HiddenSheet!$A$1:$A$${DepList.length}`],
          errorTitle: "Invalid Parent Department for",
          error: "Please select an Parent Department from the list",
        };
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "Department_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <CustomButton type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default DepartmentMasterUploadSheet;
