import { useMsal } from "@azure/msal-react";
import React from "react";
import { loginRequest } from "../authConfig/authConfig";
import { Container, Grid } from "@mui/material";
import Button from "@mui/material/Button";

const ADLogin = () => {
    const {instance} = useMsal();
    const loginPrompt = () =>{
        instance.loginRedirect({
            ...loginRequest,
            prompt: 'create'
        })
    }

    return (
        <>
            <title> Login | </title>

            <Grid container>
                <Container>

                        <Button
                        type="submit"
                       
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={()=>{
                            loginPrompt()
                        }}
                      >
                        AD Login
                      </Button>
                
                </Container>
            </Grid>
        </>
    )
}

export default ADLogin;
