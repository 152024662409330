import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { getLovList } from "../../../store/slices/lovMasterData";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";

import { updateLoader } from "../../../store/slices/loader";
import { LocationConfig, ToasterActivityConfig } from "../../../config/config";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import { getLocationList } from "../../../store/slices/locationMasterData";
import { CreateHoliday, UpdateHoliday } from "../../../store/slices/holidayMasterData";

interface FormData {
    Holiday_Id?: number;
    Holiday_Date: any;
    Holiday_Description: string;
    Holiday_Type_National: any;
    Country_Id: any;
    State_Id: any;
    IsActive: string;
}

export interface HolidayMasterProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
}

export const AddHolidayMaster: React.FC<HolidayMasterProps> = ({ view, closePopUp, edit, isEditData }) => {

    const dispatch = useAppDispatchThunk();
    const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
    const [StateData, setStateData] = useState<[]>([]);

    const { locationTable } = useSelector((state: RootState) => state.locationMasterData);

    let CountryData = locationTable.filter((item: any) => item.LocationTypeValue === LocationConfig.Location_Country);

    const { HolidayTable, isHolidayAdded, isHolidayUpdated, versionHistory, isHolidayDeleted, ToasterMsg } = useSelector(
        (state: RootState) => state.HolidayMasterData
    );

    const StateIDData = isEditData ? isEditData?.State_Id : [];
    const optionsArray = StateIDData && StateIDData?.length > 0 ? (StateIDData.map((doc: any) => doc?.StateId)) : [];


    let formData: any = {
        Holiday_Id: isEditData ? isEditData?.Holiday_Id : 0,
        Holiday_Date: isEditData ? isEditData?.Holiday_Date ? dayjs(isEditData?.Holiday_Date) : dayjs() : null,
        Holiday_Description: isEditData ? isEditData?.Holiday_Description : "",
        Holiday_Type_National: isEditData ? (isEditData?.Holiday_Type_National === "Local Holiday" ? true : false) : false,
        State_Id: isEditData ? optionsArray : [],
        Country_Id: isEditData ? isEditData?.Country_Id : "",
        IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
    };

    const {
        handleSubmit: handleHolidayForm,
        reset,
        setValue,
        formState: { errors: errorsloginform, isDirty },
        control,
        watch,
        getValues,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });
    // console.log(getValues(), "PurposeId");

    const isActiveDisabled = watch("IsActive");
    const HolidayTypeWatch = watch("Holiday_Type_National");
    const Holiday_DateWatch = watch("Holiday_Date");
    const CountryWatch = watch("Country_Id");

    useEffect(() => {
        dispatch(getLovList({ Name: "" }));
        dispatch(
            getLocationList({
                LocationTypeId: "",
            })
        );
    }, []);

    const handleSubmit = (data: any) => {
        let formData = data;
        // dispatch(updateLoader(true));

        const transformedStateData = formData.State_Id.map((id: any) => {
            const matchingItem = locationTable.find((item: any) => item.Location_Id === id);
            return matchingItem ? { StateId: matchingItem.Location_Id, StateName: matchingItem.Location_Name } : null;
        }).filter((item: any) => item !== null);

        formData.MenuId = currentPagePermissionData?.MenuId;
        formData.Form_Id = 41;
        formData.ModuleName = "Holiday Master";
        formData.Remarks = "";
        // formData.Purpose = lovTable["Purpose"]?.data.find((item: any) => item?.LovId === data?.Purpose)?.Options;
        formData.CreatedBy = user?.UserName;
        formData.Status = 1;
        formData.State_Id = formData?.State_Id ? transformedStateData : [];
        // formData.State_Id = formData?.State_Id ? formData?.State_Id.map((item: any) => ({ StateId: item })) : [];
        formData.UserId = userLoginResponse?.UserId;
        const selectedDate = dayjs(formData?.Holiday_Date)?.startOf('day')?.format("YYYY-MM-DDTHH:mm:ss");
        console.log(selectedDate,"selectedDate");
        
        formData.Holiday_Date = selectedDate;

        let JsonDataForDisplay: any = {
            "Date": dayjs(formData?.Holiday_Date).format("DD-MMM-YYYY"),
            "Holiday Description": formData.Holiday_Description,
            "Holiday Type": formData.Holiday_Type_National ? "yes" : "No",
            "Country": formData.CountryName,
            "State": formData.StateValues,
            Status: formData.IsActive ? "Active" : "InActive",
            "Modified By": user?.UserName,
        };

        if (edit) {
            formData.IsActive = formData.IsActive ? true : false;
            formData.Activity = ToasterActivityConfig.Edit;
            formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

            dispatch(UpdateHoliday(formData));
        } else {
            formData.Activity = ToasterActivityConfig.create;
            formData.IsActive = true;
            formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

            dispatch(CreateHoliday(formData));
        }
        console.log(formData, "formData");
    };

    useEffect(() => {
        if (isHolidayAdded) {
            // dispatch(updateLoader(false));
            if (closePopUp) {
                closePopUp()
            }
        }
        else if (isHolidayUpdated) {
            // dispatch(updateLoader(false));
            if (closePopUp) {
                closePopUp()
            }
        }
    }, [isHolidayAdded, isHolidayUpdated]);

    useEffect(() => {

        if (CountryWatch && HolidayTypeWatch) {

            let StateBasedonCountry = locationTable.filter((item: any) => item.ParentLocation_Id === CountryWatch) || [];
            setStateData(StateBasedonCountry)
            console.log(CountryWatch, StateBasedonCountry, "StateBasedonCountry");

        }

    }, [CountryWatch, HolidayTypeWatch]);


    return (
        <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >

                <Grid
                    sx={{
                        height: "auto",
                        width: "100%",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="Holiday_Date"
                                        control={control}
                                        defaultValue={null}
                                        rules={{ 
                                            required: "Holiday Date is Required",
                                            validate: (value) => {
                                                const isSunday = dayjs(value).day() === 0;
                                                return isSunday ? "Sundays are not allowed" : true;
                                            },
                                         }}
                                        render={({ field }) => (
                                            <CustomDatePicker
                                                minDate={dayjs().toDate()}
                                                maxDate={dayjs().add(100, "year").toDate()}
                                                required={true}
                                                label={"Holiday Date"}
                                                value={field.value}
                                                readOnly={view || !isActiveDisabled}
                                                // readOnly={view}
                                                onChange={(e) => { field.onChange(e); }}
                                                error={Boolean(errorsloginform.Holiday_Date)}
                                                helperText={errorsloginform.Holiday_Date?.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="Holiday_Description"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Holiday Description is Required",
                                            validate: {
                                                noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                                                minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
                                                maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                                                ValueLimitaion: (value) =>
                                                    /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value.trim()) ||
                                                    "Name cannot contain special characters and empty value",
                                                // ValueLimitation: (value) => /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) || "Name cannot contain special characters except for , . - and spaces",
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomTextField
                                                name={"Holiday_Description"}
                                                required={true}
                                                readOnly={view || !isActiveDisabled}
                                                // readOnly={view}
                                                label={"Holiday Description"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.Holiday_Description)}
                                                helperText={errorsloginform.Holiday_Description && errorsloginform.Holiday_Description.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="Holiday_Type_National"
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <CustomToogleSwitch
                                                label={HolidayTypeWatch ? "Local Holiday" : "National Holiday"}
                                                title="Holiday Type"
                                                value={field.value}
                                                // onChange={field.onChange}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    // if(!HolidayTypeWatch){
                                                    setValue("State_Id", [])
                                                    // }
                                                }}
                                                error={Boolean(errorsloginform.Holiday_Type_National)}
                                                required={true}
                                                readOnly={view || !isActiveDisabled}
                                                // readOnly={view}
                                                helperText={
                                                    errorsloginform?.Holiday_Type_National?.message?.toString()
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                // md={6}
                                >
                                    <Controller
                                        name="Country_Id"
                                        control={control}
                                        rules={{ required: "Country is required" }}
                                        render={({ field }) => (
                                            <CustomSelect
                                                name={"Country_Id"}
                                                valueKey={"Location_Id"}
                                                optionLabelKey="Location_Name"
                                                options={CountryData}
                                                readOnly={view || !isActiveDisabled}
                                                // readOnly={view}
                                                required={true}
                                                label={"Country"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.Country_Id)}
                                                helperText={
                                                    errorsloginform.Country_Id && errorsloginform.Country_Id.message?.toString()
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                {HolidayTypeWatch && <Grid
                                    item
                                    xs={12}
                                >
                                    <Controller
                                        name="State_Id"
                                        control={control}
                                        rules={{
                                            required: "State is Required",
                                            validate: (value) =>
                                                (Array.isArray(value) && value.filter((item) => item !== undefined).length > 0) ||
                                                "State is Required",
                                        }}
                                        render={
                                            ({ field }) => (
                                                // lovTable["Document Ext"]?.data &&
                                                // lovTable["Document Ext"]?.data.length > 0 && (
                                                <CustomMultiSelect
                                                    optionLabelKey={`Location_Name`}
                                                    optionIdKey={`Location_Id`}
                                                    options={StateData}
                                                    required={true}
                                                    readOnly={view || !isActiveDisabled}
                                                    // readOnly={view}
                                                    label={"State"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.State_Id)}
                                                    helperText={
                                                        errorsloginform.State_Id && errorsloginform.State_Id.message?.toString()
                                                    }
                                                />
                                            )
                                        }
                                    />
                                </Grid>}

                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "right",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Update"}
                                            onClick={handleHolidayForm(handleSubmit)}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton
                                            type="reset"
                                            disabled={!isDirty}
                                            name={"Submit"}
                                            onClick={handleHolidayForm(handleSubmit)}
                                        />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
        </Grid>
    );
};
