export const STATUS_CODE = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
};

export const STATUS_CODE_HEADER = {
  [STATUS_CODE[0]]: "Invalid User",
  [STATUS_CODE[1]]: "Success",
  [STATUS_CODE[2]]: "User Locked",
  [STATUS_CODE[3]]: "Account Locked",
  [STATUS_CODE[4]]: "Password Expired",
};

export const STATUS_CODE_TEXT = {
  [STATUS_CODE[0]]: "Given username or password is invalid",
  [STATUS_CODE[1]]: "Success",
  [STATUS_CODE[2]]: "User has been locked",
  [STATUS_CODE[3]]: "Account had been locked",
  [STATUS_CODE[4]]: "Your password has expired",
};
