import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddCurrencyResponse, ICurrencyResponse, IVHColumnRes, } from "../../models/currency.type";
import { CURRENCY_URL } from "./endpoints/commonMasters";


const getCurrencyList = (reqparams: any): Promise<ICurrencyResponse> =>
  axiosInstance
    .get(CURRENCY_URL.GetAllCurrency)
    .then((response: AxiosResponse<ICurrencyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateCurrency = (requestBody: any): Promise<IAddCurrencyResponse> =>
  axiosInstance
    .post(CURRENCY_URL.UpdateCurrency, requestBody)
    .then((response: AxiosResponse<IAddCurrencyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addCurrency = (requestBody: any): Promise<IAddCurrencyResponse> =>
  axiosInstance
    .post(CURRENCY_URL.AddCurrency, requestBody)
    .then((response: AxiosResponse<IAddCurrencyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteCurrency = (requestBody: any): Promise<IAddCurrencyResponse> =>
  axiosInstance
    .post(CURRENCY_URL.DeleteCurrency, requestBody)
    .then((response: AxiosResponse<IAddCurrencyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryCurrency = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(CURRENCY_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const CurrencyService = {
  getCurrencyList,
  updateCurrency,
  addCurrency,
  deleteCurrency,
  getVersionHistoryCurrency,
};

export default CurrencyService;
