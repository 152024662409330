import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Vendor } from "../addvendor/AddVendor";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { RootState, useAppDispatch } from "../store/store";
import { IVendor } from "../models/authorize.type";
import { updateAddandEdit, getVendorList, getVersionHistory, updateVersionHistory } from "../store/slices/vendorData";
import CustomSelect from "../common/dynamicInputs/CustomSelect";
import CustomDatePicker from "../common/dynamicInputs/CustomDatePicker";
import CustomButton from "../common/dynamicButton/CustomButton";
import { updateLoader, updateToaster } from "../store/slices/loader";
import ModalPopUp from "../common/modalComponent/ModalPopUp";
import MaterialDynamicGrid from "../common/dynamicDataGrid/MaterialDynamicGrid";
import { IMenu } from "../models/loggin.type";
import CustomDialog from "../common/dynamicDialog/CustomDialog";
import { getAllFormsConfiguration, getCustomFieldOptions, getLookupTables, updateCustomAddandEdit } from "../store/slices/customFormFields";
import GenerateFormField from "../common/generateFormField/GenerateFormField";
import ToasterCompnent from "../toast/Toaster";
import { getDefaultValues, updateDefualtAddAndEdit } from "../store/slices/defaultValues";

interface formData {
  location: any;
  registeredDate: Date | null;
  EndDate: Date | null;
}

export default function VendorMain() {
  const [isEdit, setIsEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [addDefault, setAddDefault] = useState(false);
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [Option, setOption] = useState("");
  const [isEditDate, setIsEditDate] = useState<{ id?: string; }>({});
  const dispatch = useAppDispatch();
  const editRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState(false);

  const { dataTable, versionHistory, isVendorAdded, isVendorUpdated, ToasterMsg, } = useSelector(
    (state: RootState) => state.vendorData
  );
  const { isCustomFieldAdded, formConfiguration, lookupTables, customFieldOptions } = useSelector(
    (state: RootState) => state.customFormFields
  );

  const { DefaultId, DefaultData, isDefaultValuedAddedd, isDefaultValuedUpdated } = useSelector(
    (state: RootState) => state.defaultData
  );
  const { menuData, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const data = dataTable;
  let location_Data = [
    { id: "", lable: "Select your Location" },
    { id: "madurai", lable: "Madurai" },
    { id: "chennai", lable: "Chennai" },
    { id: "mumbai", lable: "Mumbai" },
  ];

  const [venderData, setVendorData] = useState<IVendor[]>([]);
  const masterId = "123";
  const [formId, setFormId] = useState(0);
  const [formName, setFormName] = useState('');
  const datas = localStorage.getItem("venderData");
  const [formData, setFormData] = useState<formData>({
    location: "",
    registeredDate: null,
    EndDate: null,
  });
  let columns: any = {
    table_name: "Vendor Records",
    isAction: true,
    columns: [],
  };

  const columns_version: any = {
    table_name: "Vendor History Reacords",
    isAction: false,
    columns: [],
  };
  const [vendorAdded, setVendorAdded] = useState(false);
  const [vendorUpdated, setVendorUpdated] = useState(false);
  const [searchopen, setSearchopen] = useState(false);
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [vendorHisHeaderColumn, setVendorHisHeaderColumn] = useState(columns_version);
  const [formValue, setFormValue] = useState<any>();
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [defaultData, setDefaultData] = useState<IVendor | null>(null);
  console.log("re", data);



  useEffect(() => {
    if (versionHistory.vendorHistory.length > 0 && versionHistory.vendorHeader.length > 0) {
      setIsEdit(true);
      setOption("history");
      if (versionHistory.vendorHeader && versionHistory.vendorHeader.length > 0) {
        let re_columns = {
          ...columns_version,
          columns: versionHistory.vendorHeader,
        };
        setVendorHisHeaderColumn(re_columns);
      }
    }
  }, [versionHistory]);

  useEffect(() => {
    return () => {
      if (dataTable.vendor?.length > 0) {
        // dispatch(updateVendorRecords([]));
        setformDisplay(false);
      }
      if (!formConfiguration || formConfiguration.length === 0) dispatch(getAllFormsConfiguration());
      if (!lookupTables || lookupTables.length === 0) dispatch(getLookupTables());
      if (!customFieldOptions || customFieldOptions.length === 0) dispatch(getCustomFieldOptions());
    };
  }, []);

  useEffect(() => {
    const vendorFormData = formConfiguration.find((form) => form.TableName === 'tblVendor');
    if (vendorFormData) {
      const { FormId, FormName } = vendorFormData;
      setFormId(FormId);
      setFormName(FormName);
    }
  }, [formConfiguration]);

  useEffect(()=>{
    dispatch(getDefaultValues({
      ModuleName: "Vendor Management",
      ScreenName: "Vendor Management",
    }))
  },[])

  useEffect(() => {
    if (dataTable.vendorHeader && dataTable.vendorHeader?.length > 0) {
      let re_columns = {
        ...columns,
        columns: dataTable.vendorHeader,
      };
      setHeaderColumn(re_columns);
    }
  }, [dataTable.vendorHeader]);

  useEffect(() => {
    if (isVendorAdded) {
      if (formValue) {
        dispatch(getVendorList(formValue));
        dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
        dispatch(updateAddandEdit(false));
      }
      //setVendorAdded(true);
    } else if (isVendorUpdated) {
      dispatch(getVendorList(formValue));
      //setVendorUpdated(true);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(updateAddandEdit(false));
    } else if (isCustomFieldAdded) {
      dispatch(getVendorList(formValue));
      dispatch(updateToaster({ isToaster: true, toasterMsg: 'Custom Field Added Successfully' }));
      dispatch(updateCustomAddandEdit(false));
    } else if (isDefaultValuedAddedd) {
      dispatch(
        getDefaultValues({
          ModuleName: "Vendor Management",
          ScreenName: "Vendor Management",
        })
      );
      dispatch(updateToaster({ isToaster: true, toasterMsg: "Default Values Update Successfully" }));
      dispatch(updateDefualtAddAndEdit(false));
    } else if (isDefaultValuedUpdated) {
      dispatch(
        getDefaultValues({
          ModuleName: "Vendor Management",
          ScreenName: "Vendor Management",
        })
      );
      dispatch(updateToaster({ isToaster: true, toasterMsg: "Default Values Update Successfully" }));
      dispatch(updateDefualtAddAndEdit(false));
    }

  }, [isVendorAdded, isVendorUpdated, isCustomFieldAdded, isDefaultValuedAddedd, isDefaultValuedUpdated]);

  useEffect(() => {
    console.log(data, "v_data");

    if (data.vendor?.length > 0) {
      setVendorData(data.vendor);
      setformDisplay(true);
      dispatch(updateLoader(false));
    } else {
      dispatch(updateLoader(false));
    }
  }, [data]);

  useEffect(() => {
    if (DefaultData && DefaultData !== "") {
      setDefaultData(JSON.parse(DefaultData));
    }
  }, [DefaultData]);

  useEffect(() => {
    if (
      menuData &&
      menuData.length > 0 &&
      menuData.filter((menuItem) => menuItem.MenuName === "Vendor Management").length > 0
    ) {
      let menuPage = menuData.filter((menuItem) => menuItem.MenuName === "Vendor Management")[0];
      setPermissionForpage(menuPage);
    }
  }, [menuData]);

  const handleOpen = (data: any, option: string) => {
    if (option === "history") {
      dispatch(
        getVersionHistory({
          vendorId: data.VendorId,
          formID: formId,
        })
      );
    } else {
      setIsEdit(true);
      setOption(option);
      setIsEditDate(data);
    }
  };

  const handleChange = (value: any, fieldName: string) => {
    let get_value: any = value || "";

    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: get_value,
    }));
  };

  useEffect(() => { handleClear(); }, []);

  const isFormValid = () => {
    return formData.location !== "" || (formData.registeredDate !== null && formData.EndDate !== null);
  };

  const onProcess = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData, "formData.location");

    if (formData.location == "" && formData.registeredDate == null && formData.EndDate == null) {
      console.log("Not allow");
      setSearchopen(true);

    } else {
      if (isFormValid()) {
        dispatch(updateLoader(true));
        let params: any = {};
        if (formData.location !== "" && formData.registeredDate !== null && formData.EndDate !== null) {
          if (new Date(formData.EndDate).getTime() > new Date(formData.registeredDate).getTime()) {
            params["location"] = formData.location;
            params["formid"] = 1;
            params["startDate"] = dayjs(formData.registeredDate).format("YYYY-MM-DD");
            params["endDate"] = dayjs(formData.EndDate).format("YYYY-MM-DD");
            setErrorMsg(false);
            dispatch(getVendorList(params));
            setFormValue(params);
          } else {
            setErrorMsg(true);
            dispatch(updateLoader(false));
          }
        } else if (formData.registeredDate !== null && formData.EndDate !== null) {
          if (new Date(formData.EndDate).getTime() > new Date(formData.registeredDate).getTime()) {
            params["startDate"] = dayjs(formData.registeredDate).format("YYYY-MM-DD");
            params["endDate"] = dayjs(formData.EndDate).format("YYYY-MM-DD");
            params["formid"] = 1;
            setErrorMsg(false);
            setFormValue(params);
            dispatch(getVendorList(params));
          } else {
            setErrorMsg(true);
            dispatch(updateLoader(false));
          }
        } else if (formData.location !== "") {
          params["location"] = formData.location;
          params["formid"] = 1;
          setFormValue(params);
          dispatch(getVendorList(params));
          setErrorMsg(false);
        }
      } else {
        dispatch(updateLoader(true));
      }
    }

  };

  const handleClear = () => {
    setFormData({
      location: "",
      registeredDate: null,
      EndDate: null,
    });
    setformDisplay(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              Vendor Management
            </Typography>
          </Box>

          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.08)",
              // padding: "16px",
              borderRadius: "10px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <CustomSelect
                  name={"location"}
                  valueKey={"id"}
                  optionLabelKey="lable"
                  options={location_Data}
                  value={formData.location}
                  onChange={handleChange}
                  required={true}
                  label={"Location"}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <CustomDatePicker
                  label={"Registered Date From"}
                  value={formData.registeredDate ? dayjs(formData.registeredDate) : null}
                  onChange={(newValue: any) => {
                    setFormData({
                      ...formData,
                      registeredDate: newValue,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <CustomDatePicker
                  label={"Registered Date To"}
                  value={formData.EndDate ? dayjs(formData.EndDate) : null}
                  onChange={(newValue: any) => {
                    setFormData({ ...formData, EndDate: newValue });
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <CustomButton
                      variant="contained"
                      name={"Search"}
                      onClick={(e: any) => {
                        onProcess(e);
                      }}
                      size="small"
                    />
                    <CustomButton
                      // color="inherit"
                      variant="outlined"
                      name={"Clear"}
                      onClick={() => handleClear()}
                      size="small"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {errorMsg ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h5"> Registered Date To should be greater than Registered Date From</Typography>
              </Grid>
            </Box>
          ) : null}

          {permssionPage?.IsAdd ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <CustomButton
                  variant="outlined"
                  name={"Add Custom Field"}
                  onClick={() => toggleCustomColumnForm(true)}
                  size="small"
                />
                <CustomButton variant="contained" name={"Set Default values"} onClick={() => setAddDefault(true)} />
                <CustomButton variant="contained" name={"Add Vendor"} onClick={() => setAddForm(true)} size="small" />
              </Grid>
            </Box>
          ) : null}

          <Box>
            {!formDisplay ? (
              ""
            ) : (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {/* <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        sx={{ marginBottom: 1, padding: "10px 20px" }}
                        variant="contained"
                        onClick={() => setAddForm(true)}
                      >
                        ADD Vendor
                      </Button>
                    </Grid> */}
                  {/* {false && <DevExpDynamicGrid
                    data={HeaderColumn}
                    rows={venderData}
                    handleAction={handleOpen}
                    enableZoomIcon={true}
                    uniqueIdKey = 'VendorId'
                  />} */}

                  <MaterialDynamicGrid
                    data={HeaderColumn}
                    rows={venderData}
                    handleAction={handleOpen}
                    enableZoomIcon={true}
                    // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                    showDelete={permssionPage?.IsDelete === 1}
                    showView={permssionPage?.IsView === 1}
                    showEdit={permssionPage?.IsEdit === 1}
                    showExports={permssionPage?.IsExport === 1}
                    showhistory={false}
                    pageName="Vendor Management"
                    userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                  />
                </Box>
              </>
            )}
          </Box>
        </Stack>
      </Box>
      {/* <Dialog
        open={addForm}
        onClose={() => setAddForm(false)}
        maxWidth={"sm"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              position: "absolute",
              right: "15px",
              top: "24px",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setAddForm(false);
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Vendor
            view={false}
            isAdded={(flag: boolean) => {
              setAddForm(flag);
            }}
          />
        </DialogContent>
      </Dialog> */}
      {/* <Dialog
        open={isEdit}
        onClose={() => {
          if (Option === "history") {
            dispatch(updateVersionHistory([]));
          }
          setIsEdit(false);
        }}
        maxWidth={Option === "history" ? "lg" : "sm"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <Box
            sx={{
              position: Option === "history" ? "unset" : "absolute",
              right: "15px",
              top: "24px",
              display: Option === "history" ? "flex" : "",
              justifyContent: "end",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                if (Option === "history") {
                  dispatch(updateVersionHistory([]));
                }
                setIsEdit(false);
              }}
            >
              <Close />
            </IconButton>
          </Box>

          {Option === "history" && (
            <MaterialDynamicGrid
              data={vendorHisHeaderColumn}
              rows={versionHistory.vendorHistory}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
            />
          )}
          {Option === "edit" && (
            <Vendor
              isEditData={isEditDate}
              edit={true}
              view={false}
              isEdited={setIsEdit}
            />
          )}
          {Option === "view" && (
            <Vendor
              view={true}
              isEditData={isEditDate}
              edit={false}
              isEdited={setIsEdit}
            />
          )}
        </DialogContent>
      </Dialog> */}
      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={"Add Vendor"}
        contentNode={
          <Vendor
            view={false}
            isAdded={(flag: boolean) => {
              setAddForm(flag);
            }}
            FormId={formId}
            masterId={masterId}
            defaultdata={defaultData}
            defaultId={DefaultId}
          />
        }
      />

      <CustomDialog
        show={addDefault}
        onHide={() => setAddDefault(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={"Add Default Vendor"}
        contentNode={
          <Vendor
            view={false}
            isAdded={(flag: boolean) => {
              setAddDefault(flag);
            }}
            FormId={formId}
            masterId={masterId}
            isDefault={true}
            defaultdata={defaultData}
            defaultId={DefaultId}
          />
        }
      />

      <GenerateFormField
        onHide={() => toggleCustomColumnForm(false)}
        show={formId && showCustomColumnForm ? true : false}
        FormId={formId}
        FormName={formName}
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          if (Option === "history") {
            dispatch(updateVersionHistory([]));
          }
          setIsEdit(false);
        }}
        maxWidth={Option == "history" ? "lg" : "md"}
        header={Option == "history" ? "Vendor History" : Option === "edit" ? "Edit Vendor" : "View Vendor"}
        contentNode={
          <>
            {" "}
            {Option === "history" && (
              <MaterialDynamicGrid
                data={vendorHisHeaderColumn}
                rows={versionHistory.vendorHistory}
                handleAction={handleOpen}
                enableZoomIcon={false}
                showhistory={false}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Vendor Management History"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            )}
            {Option === "edit" && (
              <Vendor
                FormId={formId}
                isEditData={isEditDate}
                edit={true}
                view={false}
                isEdited={setIsEdit}
                masterId={masterId}
              />
            )}
            {Option === "view" && (
              <Vendor
                FormId={formId}
                view={true}
                isEditData={isEditDate}
                edit={false}
                isEdited={setIsEdit}
                masterId={masterId}
              />
            )}
          </>
        }
      />
      <ModalPopUp
        header="Info"
        text="Custom Field added successfully"
        onHide={() => {
          setVendorAdded(false);
          dispatch(updateAddandEdit(false));
        }}
        show={vendorAdded}
      />

      <ModalPopUp
        header="Info"
        text="Vendor Updated SuccessFully"
        onHide={() => {
          setVendorUpdated(false);
          dispatch(updateAddandEdit(false));
        }}
        show={vendorUpdated}
      />

      <ModalPopUp
        header="Info"
        text="Please select atleast one filter before hitting Search button."
        onHide={() => {
          setSearchopen(false);
        }}
        show={searchopen}
      />
      {/* {vendorAdded ? <ToasterCompnent 
          msg = {ToasterMsg}
      />:null
    } */}
    </>
  );
}
