import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { REGIONALSETTINGS_URL } from "./endpoints/commonMasters";
import { IRegionalResponse, IAddRegionalResponse, IVHColumnRes } from "../../models/regional.type";

const getRegionalList = (reqparams: any): Promise<IRegionalResponse> =>
  axiosInstance
    .get(REGIONALSETTINGS_URL.GetRegional,{params:reqparams})
    .then((response: AxiosResponse<IRegionalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addEditRegional = (requestBody: any): Promise<IAddRegionalResponse> =>
  axiosInstance
    .post(REGIONALSETTINGS_URL.AddEditRegional, requestBody)
    .then((response: AxiosResponse<IAddRegionalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteRegional = (requestBody: any): Promise<IAddRegionalResponse> =>
  axiosInstance
    .post(REGIONALSETTINGS_URL.DeleteRegional, requestBody)
    .then((response: AxiosResponse<IAddRegionalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryRegional = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(REGIONALSETTINGS_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const RegionalSettingsService = {
    getRegionalList,
    addEditRegional,
    deleteRegional,
    getVersionHistoryRegional,
  
};

export default RegionalSettingsService;
