import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { IPageSettings } from '../../models/pageSettings.type';
import pageSettingService from '../services/pageSettingService';

const initialState: IPageSettings={
    isLoading: false,
    pageSettings:null,
    savedFilters:[]
}

export const addSettings = createAsyncThunk('addPageSetting', async(requesrParams: any, {rejectWithValue})=>{
    try{
        const response= await pageSettingService.addPageSetting(requesrParams);
        if(response || response === ""){
            return response.data
        }        
    }catch(error){
        return rejectWithValue(error)
    }
})

export const getPageSettings = createAsyncThunk('getPageSettingForUser', async(requesrParams: {UserId: number, PageName: string, FilterName: string}, {rejectWithValue})=>{
    try{
        const response= await pageSettingService.getPageSetting(requesrParams);        
        return response.data               
    }catch(error){
        return rejectWithValue(error)
    }
})

export const getSavedFilters = createAsyncThunk('getSavedFiltersForUser', async(requesrParams: {UserId: number, PageName: string, FilterName: string}, {rejectWithValue})=>{
    try{
        const response= await pageSettingService.getPageSetting(requesrParams);        
        return response.data               
    }catch(error){
        return rejectWithValue(error)
    }
})

export const saveFilterPageSettings = createAsyncThunk('saveFilterPageSettings', async(requesrParams: any, {rejectWithValue})=>{
    try{
        const response= await pageSettingService.addPageSetting(requesrParams);
        if(response || response === ""){
            return requesrParams;
        }        
    }catch(error){
        return rejectWithValue(error)
    }
})

export const deleteSettings = createAsyncThunk('deleteSavedFilterSettings', async(requesrParams: {SettingId: number}, {rejectWithValue})=>{
    try{
        const response= await pageSettingService.deleteSaveFilter(requesrParams);
        return response.data     
    }catch(error){
        return rejectWithValue(error)
    }
})


export const pageSettingSlice = createSlice({
    name: 'PageSettings',
    initialState,
    reducers:{        
        saveFilters: (state, action) => {
            state.savedFilters.push(action.payload);
        },
        deleteFilter: (state, action) => {
            state.savedFilters.splice(action.payload, 1);
        },
    },
    extraReducers: (builder)=>{
        builder.addCase(addSettings.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addSettings.fulfilled, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false;
        })
        .addCase(addSettings.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })    
        .addCase(getPageSettings.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getPageSettings.fulfilled, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false;
            state.pageSettings = action.payload.Settings[0]
        })
        .addCase(getPageSettings.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })  
        .addCase(saveFilterPageSettings.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(saveFilterPageSettings.fulfilled, (state, action: PayloadAction<any>)=>{            
            state.savedFilters = [...state.savedFilters, action.payload]
        })
        .addCase(saveFilterPageSettings.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })   
        .addCase(getSavedFilters.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getSavedFilters.fulfilled, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false;
            state.savedFilters = action.payload.Settings
        })
        .addCase(getSavedFilters.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })
        .addCase(deleteSettings.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(deleteSettings.fulfilled, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false;            
        })
        .addCase(deleteSettings.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })         
    }  
});
export const {deleteFilter} = pageSettingSlice.actions;
export default pageSettingSlice.reducer;