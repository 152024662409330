import {createSlice} from '@reduxjs/toolkit';
export interface IMenu{
    show: boolean;
    showHeader:boolean;
    themeMode : 'dark' | 'light'
}

const initialState={
    show: true,
    showHeader:true,
    themeMode: 'light'
}


export const menuSlice = createSlice({
    name: 'menuData',
    initialState,
    reducers:{
        updateHeaderMenu:(state, action)=>{            
            state.showHeader = action.payload;
        },
        updateMenu:(state, action)=>{            
            state.show = action.payload;
        },
        updatethemeMode:(state, action)=>{            
            state.themeMode = action.payload;
        },
        
    },
  
});

export const {updateMenu,updateHeaderMenu , updatethemeMode} = menuSlice.actions;
 export default menuSlice.reducer;