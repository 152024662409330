import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, InputAdornment, IconButton } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
//import { useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ProgressBar from "../../common/progressBar/progressBar";
import Paper from "@mui/material/Paper";
import { changePasswordData } from "../../models/changePassword.type";
import { useNavigate } from "react-router-dom";
import { textFieldWidth } from "../../utils/constants";
import { logoutUser } from "../../store/slices/authorization";
import { useSelector } from "react-redux";
import { postChangePassword, resetPassword, updatePassword } from "../../store/slices/chagepasswordslice";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { getSecurityParameters } from "../../store/slices/securityParameters";
import { PARAMETER_NAME_FIELDS } from "../../constants/app.constants";

export interface userProps {}

const ChangePassword: React.FC<userProps> = ({}) => {
  const dispatch = useAppDispatchThunk();
  const [oldPassword, setOldPassword] = React.useState(false);
  const [loginRedirect, setLoginRedirect] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState(false);
  const [pwdRegExp, setPwdRegExp] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const { isAuthenticated, userLoginResponse } = useSelector((state: RootState) => state.loggerData);

  const { activeSecurityParameters } = useSelector((state: RootState) => state.securityParameters);

  const [updatePasswordInfo, setUpdatePasswordInfo] = useState(false);

  const { changePassworUpdated, changePasswordUpdateStatus, changePassWordMessage } = useSelector(
    (state: RootState) => state.changePassword
  );
  const navigate = useNavigate();
  let formData: changePasswordData = {
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  };
  const {
    handleSubmit: handleChangePasswordForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<changePasswordData>({
    defaultValues: formData,
    mode: "onChange",
  });
  const handleSubmit = () => {
    let form: any = getValues();
    //delete form.ConfirmPassword;
    form["EmailId"] = userLoginResponse?.UserEmail;
    setLoader(true);
    // setLoginRedirect(true);
    dispatch(postChangePassword(form));
  };

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/login")
  //   }
  // }, [isAuthenticated])

  useEffect(() => {
    if (changePassworUpdated) {
      // dispatch(updatePassword(false))
      // dispatch(logoutUser(false));
      setUpdatePasswordInfo(true);
      
    }
  }, [changePassworUpdated]);
console.log(userLoginResponse,"userLoginResponse")
  useEffect(() => {
    getSecurityParameters({
      FormID: 2,
    });
  }, []);

  useEffect(() => {
    const minLength =
      (activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LENGTH] &&
        activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LENGTH]?.Value) ||
      8;
    const maxLength =
      (activeSecurityParameters[PARAMETER_NAME_FIELDS.MAX_LENGTH] &&
        activeSecurityParameters[PARAMETER_NAME_FIELDS.MAX_LENGTH]?.Value) ||
      12;
    const minLowerCase =
      (activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LOWERCASE] &&
        activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LOWERCASE]?.Value) ||
      1;
    const minUpperCase =
      (activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_UPPERCASE] &&
        activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_UPPERCASE]?.Value) ||
      1;
    const minNumbers =
      (activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_NUMBERS] &&
        activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_NUMBERS]?.Value) ||
      1;
    const minSpecialChars =
      (activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_SPECIAL_CHARACTERS] &&
        activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_SPECIAL_CHARACTERS]?.Value) ||
      1;
    const specialChars =
      (activeSecurityParameters[PARAMETER_NAME_FIELDS.SPECIAL_CHARACTERS] &&
        activeSecurityParameters[PARAMETER_NAME_FIELDS.SPECIAL_CHARACTERS]?.Value) ||
      "@#$%^&";

    const escapedSpecialChars = specialChars.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regExp = `^(?=.*[0-9]{${minNumbers},})(?=.*[${escapedSpecialChars}]{${minSpecialChars},})(?=.*[a-z]{${minLowerCase},})(?=.*[A-Z]{${minUpperCase},}).{${minLength},${maxLength}}$`;
    setPwdRegExp(regExp);
  }, [activeSecurityParameters]);

  const validatePassword = (data: any) => {
    const regex = new RegExp(pwdRegExp);

    if (!regex.test(data)) {
      const minLength = activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LENGTH]?.Value || 8;
      const maxLength = activeSecurityParameters[PARAMETER_NAME_FIELDS.MAX_LENGTH]?.Value || 12;
      const minLowerCase = activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LOWERCASE]?.Value || 1;
      const minUpperCase = activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_UPPERCASE]?.Value || 1;
      const minNumbers = activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_NUMBERS]?.Value || 1;
      const minSpecialChars = activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_SPECIAL_CHARACTERS]?.Value || 1;
      const specialChars = activeSecurityParameters[PARAMETER_NAME_FIELDS.SPECIAL_CHARACTERS]?.Value || "@#$%^&";

      if (data.length < minLength) {
        return (
          activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LENGTH]?.Description ||
          `Password must be between ${minLength} and ${maxLength} characters long.`
        );
      }
      if (data.length > maxLength) {
        return (
          activeSecurityParameters[PARAMETER_NAME_FIELDS.MAX_LENGTH]?.Description ||
          `Password must be between ${minLength} and ${maxLength} characters long.`
        );
      }
      if (!/[0-9]/.test(data) || (data.match(/\d/g) || []).length < minNumbers) {
        return (
          activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_NUMBERS]?.Description ||
          `Password must contain at least ${minNumbers} number(s).`
        );
      }
      if (
        (data.match(new RegExp(`[${specialChars.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}]`, "g")) || []).length <
        minSpecialChars
      ) {
        return (
          activeSecurityParameters[PARAMETER_NAME_FIELDS.SPECIAL_CHARACTERS]?.Description ||
          `Password must contain at least ${minSpecialChars} special character(s).`
        );
      }
      if (!/[a-z]/.test(data) || (data.match(/[a-z]/g) || []).length < minLowerCase) {
        return (
          activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_LOWERCASE]?.Description ||
          `Password must contain at least ${minLowerCase} lowercase letter(s).`
        );
      }
      if (!/[A-Z]/.test(data) || (data.match(/[A-Z]/g) || []).length < minUpperCase) {
        return (
          activeSecurityParameters[PARAMETER_NAME_FIELDS.MIN_UPPERCASE]?.Description ||
          `Password must contain at least ${minUpperCase} uppercase letter(s).`
        );
      }
    }
    return "Password is Invalid";
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "auto",
        width: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Grid
        item
        //   xs={false}
        //   sm={4}
        //   md={7}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          sx={{
            my: 10,
            mx: {
              xs: 2,
              sm: 4,
              md: 4,
              lg: 8,
              xl: 12,
            },
            px: 0,
            position: "relative",
            height: "auto",
          }}
          item
          xs={12}
          sm={8}
          md={5}
          lg={6}
          xl={8}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
              position: "relative",
            }}
          >
            <Typography variant="h6" sx={{ m: 2 }}>
              {"Change Password"}
            </Typography>
          </Box>
          <Grid
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                px: {
                  xs: 2,
                  sm: 4,
                  md: 2,
                  lg: 12,
                  xl: 12,
                },

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="OldPassword"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Old Password is required",
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          required
                          name="OldPassword"
                          label="Old Password"
                          type={oldPassword ? "text" : "password"}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setOldPassword(!oldPassword)}
                                  onMouseDown={() => setOldPassword(!oldPassword)}
                                  edge="end"
                                >
                                  {oldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(errorsloginform.OldPassword)}
                          helperText={errorsloginform.OldPassword && errorsloginform.OldPassword.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="NewPassword"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "New Password is required",
                        validate: (data) => {
                          const regex = new RegExp(pwdRegExp);
                          return regex.test(data) || validatePassword(data);
                        },
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          required
                          name="NewPassword"
                          label="New Password"
                          type={showPassword ? "text" : "password"}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(errorsloginform.NewPassword)}
                          helperText={errorsloginform.NewPassword && errorsloginform.NewPassword.message?.toString()}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="ConfirmPassword"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Confirm Password is required",
                        validate: (data) => getValues("NewPassword") === data || "Confirm doesnt match password",
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          required
                          name="confirmPassword"
                          label="Confirm Password"
                          type={confirmPassword ? "text" : "password"}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setConfirmPassword(!confirmPassword)}
                                  onMouseDown={() => setConfirmPassword(!confirmPassword)}
                                  edge="end"
                                >
                                  {confirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(errorsloginform.ConfirmPassword)}
                          helperText={
                            errorsloginform.ConfirmPassword && errorsloginform.ConfirmPassword.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              my: 2,
              mt: 4,
              mb: 3,
            }}
          >
            <CustomButton
              type="reset"
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                if (userLoginResponse?.IsFirstLogin) {
                  dispatch(logoutUser(false));
                } else {
                  navigate(-1);
                }
              }}
            />
            <CustomButton
              type="submit"
              variant="contained"
              //color="inherit"
              name={"Submit"}
              onClick={handleChangePasswordForm(handleSubmit)}
            />
          </Box>
        </Grid>
      </Grid>
      <ModalPopUp
        header="Info"
        text={changePassWordMessage}
        onHide={() => {
          setUpdatePasswordInfo(false);
          // dispatch(updatePassword(false))
          // dispatch(logoutUser(false));
          // navigate("/login")
          if (changePasswordUpdateStatus) {
            setLoginRedirect(true);
          } else {
            dispatch(resetPassword(false));
          }
        }}
        show={updatePasswordInfo}
      />

      <ModalPopUp
        header="Info"
        text="You are now redirected to the login page.. Kindly sign in again."
        onHide={() => {
          setLoginRedirect(false);
          //dispatch(updatePassword(false));
          dispatch(resetPassword(false));
          dispatch(logoutUser(false));
          navigate("/login");
        }}
        show={loginRedirect}
      />
    </Grid>
  );
};

export default ChangePassword;
