import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { LOV_URL } from "./endpoints/commonMasters";
import { IAddLovResponse, ILovNamesResponse, ILovResponse,IVHColumnRes } from "../../models/lov.type";

const getLovList = (reqparams: any): Promise<ILovResponse> =>
  axiosInstance
    .get(LOV_URL.GetLov, { params: reqparams })
    .then((response: AxiosResponse<ILovResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getLovNamesList = (reqparams: any): Promise<ILovNamesResponse> =>
  axiosInstance
    .get(LOV_URL.GetLovNames)
    .then((response: AxiosResponse<ILovNamesResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addEditLov = (requestBody: any): Promise<IAddLovResponse> =>
  axiosInstance
    .post(LOV_URL.AddEditLov, requestBody)
    .then((response: AxiosResponse<IAddLovResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteLov = (requestBody: any): Promise<IAddLovResponse> =>
  axiosInstance
    .post(LOV_URL.DeleteLov, requestBody)
    .then((response: AxiosResponse<IAddLovResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryLov = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(LOV_URL.VersionHistoryLov, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getListOfValues = (reqparams: any) =>
  axiosInstance
    .get(LOV_URL.GetLov, { params: reqparams })
    .then((response: any) => {
      if (response.status === 200) {
        return { data: response.data, params: reqparams };
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const lovMasterService = {
  getLovList,
  addEditLov,
  deleteLov,
  getLovNamesList,
  getVersionHistoryLov,
  getListOfValues,
};

export default lovMasterService;
