import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../store/store";

import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import { getLookupContent } from "../../store/slices/customFormFields";

import { customFieldsProps } from "./types";
import { FIELD_TYPES, COUNTRY_OPTIONS, STATE_OPTIONS, LOOKUP_CONTENT_OPTIONS } from "./constants";

const CustomFormField: React.FC<customFieldsProps> = (props) => {
  const {
    FieldLabel,
    IsRequired,
    FieldName,
    errorsloginform,
    control,
    FieldType,
    LookupTable,
    MinLength,
    MaxLength,
    disabled,
    diableRequired= false
  } = props;

  const { lookupTables, lookupContent } = useSelector((state: RootState) => state.customFormFields);
  const dispatch = useAppDispatch();
  const [lookupContentOptions, setLookupContentOptions] = useState([LOOKUP_CONTENT_OPTIONS]);

  const mapComponentByType = {
    [FIELD_TYPES.TEXT]: CustomTextField,
    [FIELD_TYPES.DROPDOWN]: CustomSelect,
  };

  const Component = mapComponentByType[FieldType || FIELD_TYPES.TEXT];

  useEffect(() => {
    if (FieldType === "Dropdown") {
      const lookupPayload = lookupTables.find((table) => {
        return table.TableName === LookupTable;
      });
      dispatch(getLookupContent(lookupPayload));
    }
  }, [FieldType]);

  useEffect(() => {
    const lookupContentOptionsCopy = [...lookupContentOptions];
    lookupContent.forEach((lookup) => {
      const { Name, Id } = lookup;
      lookupContentOptionsCopy.push({
        id: `${Id}`,
        lable: Name,
      });
      setLookupContentOptions(lookupContentOptionsCopy);
    });
  }, [lookupContent]);

  return (
    <Controller
      name={FieldName}
      control={control}
      rules={{
        required: diableRequired? false :`${FieldName} is required`,
        minLength:  diableRequired? undefined:{
          value: (MinLength),
          message: `Minimum ${MinLength} characters required`,
        },
        maxLength: diableRequired? undefined: {
          value: MaxLength === 0 ? 256 : MaxLength,
          message: `Maximum ${MaxLength} characters allowed`,
        },
      }}
      render={({ field }) => (
        <Component
          name={FieldName}
          label={FieldLabel}
          value={field.value}
          type={FieldType}
          onChange={field.onChange}
          required={diableRequired? false :IsRequired}
          error={Boolean(errorsloginform[FieldName])}
          helperText={errorsloginform[FieldName] && errorsloginform[FieldName].message?.toString()}
          valueKey={"id"}
          optionLabelKey="lable"
          options={lookupContentOptions}
          disabled={disabled || false}
        />
      )}
    />
  );
};

export default CustomFormField;
