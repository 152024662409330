import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddListValues } from "./AddListValues";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { IMenu } from "../../../models/loggin.type";
import {
  deleteLov,
  getLOVHistory,
  getLovList,
  getLovNamesList,
  updateLovAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/lovMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { HandleRecordLockCheck, recordLockGet } from "../../recordLock/RecordLock";
import CustomButton from "../../../common/dynamicButton/CustomButton";

interface formData {
  listName: any;
}

export default function ListofValues() {
  const dispatch = useAppDispatch();
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [LovData, setLovData] = useState<any[]>([]);
  const [LovId, setLovId] = useState<string>("");
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [selectedValue, setselectedValue] = useState(null);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  const [formData, setFormData] = useState<formData>({
    listName: "",
  });

  //const allOption= {listName:"All"}
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { lovTableMain, LovNames, islovAdded, islovDeleted, ToasterMsg, isLoading, versionHistory } = useSelector(
    (state: RootState) => state.lovMasterData
  );
  const columns_version: any = {
    table_name: "List of values version history",
    isAction: false,
    columns: [],
  };

  const columns: any = {
    table_name: "List Of Values",
    isAction: true,
    columns: [
      // {
      //   Field: "LovId",
      //   DisplayName: "List ID",
      //   FieldType: "number",
      // },
      {
        Field: "Name",
        DisplayName: "Name",
        FieldType: "string",
      },
      // {
      //   Field: "Code",
      //   DisplayName: "Code",
      //   FieldType: "string",
      // },
      {
        Field: "Options",
        DisplayName: "Option",
        FieldType: "string",
      },

      {
        Field: "Description",
        DisplayName: "Description",
        FieldType: "string",
      },

      {
        Field: "UpdatedBy",
        DisplayName: "Modified By",
        FieldType: "string",
      },

      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getLovNamesList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    console.log(lovTableMain, LovNames, "dataTable");

    setLovData(lovTableMain);
  }, [lovTableMain, LovNames]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory.lovVHeader.length > 0 && versionHistory.lovVHeader.length > 0) {
      if (versionHistory.lovVHeader && versionHistory.lovVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.lovVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(versionHistory, "versionHistory ");
      }
    }
  }, [versionHistory]);

  const allOption = { Name: "All" };
  const modifiedLovNames = [ ...LovNames];

  console.log(modifiedLovNames);

  const handleChange = (value: any, fieldName: string) => {
    let get_value: any = value || "";

    console.log(get_value, fieldName, "get_value");
    setselectedValue(value);

    // if (get_value === "All") {
    //   dispatch(getLovList({ Name: "" }));
    // } else {
    //   dispatch(getLovList({ Name: get_value }));
    // }
    // setLovId(get_value);

    // const filteredData = Table_Data.filter(item => item.Name === get_value);

    // console.log(filteredData, "filteredData");
    // setFilterTableData(filteredData);

    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: get_value,
    }));
  };

  const handleServerFilter = () => {
    let get_value: any = selectedValue || "";

    if (get_value === "All") {
      dispatch(getLovList({ Name: "" }));
    } else {
      dispatch(getLovList({ Name: get_value }));
    }
    setLovId(get_value);
  }

  // const recordLockGet = (id: any) => {
  //   const recordLockData = {
  //     RecordId: id,
  //     MenuId: permssionPage?.MenuId,
  //     ModuleName: "List Of Values",
  //   };
  //   return recordLockData;
  // };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "List Of Values",
      Activity: "edit",
    };
    return recordLockData;
  };

  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      const data = {
        RecordlockId: recordLock[0].RecordlockId,
        RecordId: recordLock[0].RecordId,
        IsActive: false,
      };
      dispatch(UpdateRecordLock(data));
    }
    return recordLock;
  };


  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {
      console.log(data, Option, "Option");
      // const dispatchData = handleAddRecordLock(data?.LovId);
      // dispatch(AddRecordLock(dispatchData));
      // setOption(Option);
      setIsEditData(data);
      // setIsEdit(true);
      setOption(Option);
      dispatch(updateLoader(true));
      const canEdit = await HandleRecordLockCheck(data?.LovId, permssionPage, "List Of Values", dispatch);
      if (canEdit) {
        const dispatchData = handleAddRecordLock(data?.LovId);
        await dispatch(AddRecordLock(dispatchData));
        //setIsEditOpen(true);
        const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.LovId, permssionPage, "List Of Values")));
        if (GetRecordLock.fulfilled.match(resultAction)) {
          const recordLockData = resultAction.payload;
          console.log(recordLockData, resultAction, isEditing, "recordLockData");
          setGetRecordLock(recordLockData);
        }
        dispatch(updateLoader(false));
        setIsEdit(true);
        // Set timeout to close the popup after 2.5 minutes
        // setTimeout(() => {
        //   setIsEdit(false);
        //   const data = recordLockUpdate();
        //   if (data.length > 0) {
        //     dispatch(UpdateRecordLock(data.pop()));
        //   }
        // }, 150000);
      }
      // setUserId(data.UserId);
    } else if (Option === "view") {
      console.log(data, Option, "Option");

      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      console.log(data, Option, "Option");

      dispatch(
        getLOVHistory({
          LovId: data.LovId,
          formID: 16,
          // Approved_By: ,
        })
      );

      setOption(Option);
      // setIsVersionHistory(true);
    } else if (Option === "delete") {
      setOption(Option);
      setIsDeleteData(data);
    }
  };

  useEffect(() => {
    console.log(filterTableData, "v_data");

    if (LovId?.length > 0) {
      setformDisplay(true);
    } else {
    }
  }, [LovId]);

  useEffect(() => {
    if (islovAdded === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false"? true : false }));
      dispatch(updateLovAddandEdit(false));
      if (LovId === "All") {
        dispatch(getLovList({ Name: "" }));
      } else {
        dispatch(getLovList({ Name: LovId }));
      }
      // dispatch(getLovList({ Name: LovId }));
    } else if (islovDeleted === true) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false"? true : false }));
      // setTimeout(() => {
      dispatch(updateLovAddandEdit(false));
      // }, 1000);
      if (LovId === "All") {
        dispatch(getLovList({ Name: "" }));
      } else {
        dispatch(getLovList({ Name: LovId }));
      }
      // dispatch(getLovList({ Name: LovId }));
    }
  }, [islovAdded, islovDeleted]);

  // useEffect(() => {
  //   const handleTabClose = async (event: BeforeUnloadEvent) => {
  //     const data = recordLockUpdate();
  //     console.log(data, "dataLock");
  //     if (data.length > 0) {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       try {
  //         await dispatch(UpdateRecordLock(data.pop())).unwrap();
  //       } catch (error) {
  //         console.error("Failed to update record lock:", error);
  //       }
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleTabClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  // }, []);

  const deleteDepartmentList = () => {
    console.log(isDeleteData);

    // let formData = { ...isDeleteData };
    let formData: any = {};
    // setLoader(true);
    formData.Is_Active = isDeleteData.IsActive === "Active" ? false : true;
    formData.UserId = userLoginResponse?.UserId;
    formData.Activity = isDeleteData.IsActive === "Active" ? "inactive" : "active";
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Name = LovId;
    formData.LovId = isDeleteData.LovId;
    formData.Form_Id = 16;
    formData.ModuleName = "List Of Values";
    formData.CreatedBy = userLoginResponse?.UserName;
    formData.DataForDisplay = "";

    console.log(formData);

    dispatch(deleteLov(formData));
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              List Of Values
            </Typography>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", width: "250px", }}>
              <CustomSelect
                name={"listName"}
                valueKey={"Name"}
                optionLabelKey="Name"
                options={modifiedLovNames}
                value={formData.listName}
                onChange={handleChange}
                required={true}
                label={"Select List Name"}
              />
            </Box>
          </Box> */}

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", width: "250px" }}>
                <CustomSelect
                  name={"listName"}
                  valueKey={"Name"}
                  optionLabelKey="Name"
                  showAll={allOption}
                  showAllOption = {true}
                  options={modifiedLovNames}
                  value={formData.listName}
                  onChange={handleChange}
                  required={true}
                  label={"Select List Name"}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
              </Box>
            </Grid>
          </Box>

          <Box>
            {!formDisplay ? (
              ""
            ) : (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <MaterialDynamicGrid
                    data={columns}
                    rows={lovTableMain}
                    handleAction={handleOpen}
                    enableZoomIcon={true}
                    handleAdd={() => setAddForm(true)}
                    // showAdd={LovId === "All" ? false : true}
                    showAdd={true}
                    showhistory={true}
                    showDelete={permssionPage?.IsDelete === 1}
                    showView={permssionPage?.IsView === 1}
                    // showEdit={permssionPage?.IsEdit === 1 && (LovId === "All") ? false : true}
                    showEdit={permssionPage?.IsEdit === 1}
                    showExports={permssionPage?.IsExport === 1}
                    pageName="List Of Values"
                    userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                  />
                </Box>
              </>
            )}
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={"Add Value"}
        contentNode={
          <AddListValues
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
            lovID={LovId}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          setIsEdit(false);
          if (Option === "edit") {
            recordLockUpdate();
          }
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={Option === "view" ? "View List" : "Update List"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddListValues
                view={false}
                closePopUp={() => {
                  recordLockUpdate();
                  setIsEdit(false);
                }}
                edit={isEdit}
                lovID={LovId}
                isEditData={IsEditData}
              />
            )}
            {Option === "view" && (
              <AddListValues
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          dispatch(updateVersionHistory([]));
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={"Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.LovRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showDelete={false}
              showhistory={false}
              showView={false}
              showEdit={false}
              showExports={true}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="List Of Values Version History"
            />
          </>
        }
      />

      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Value`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
}
