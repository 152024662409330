import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../../store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import InitiateContract from "./InitiateContract";
import {
  getContractList,
  updateContractDetailsAddandEdit,
  activeInactiveContract,
  getContractById,
} from "../../../store/contractManagement/slices/contractListSlice";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";

const columns = {
  table_name: "Contract",
  isAction: true,
  columns: [
    {
      Field: "contract_Number",
      FieldType: "string",
      DisplayName: "Contract No",
    },
    {
      Field: "contractType",
      FieldType: "string",
      DisplayName: "Contract Type",
    },
    {
      Field: "startDate",
      FieldType: "date",
      DisplayName: "Start Date",
    },
    {
      Field: "endDate",
      FieldType: "date",
      DisplayName: "End date",
    },
    {
      Field: "services",
      FieldType: "string",
      DisplayName: "Service",
    },
    {
      Field: "amount",
      FieldType: "number",
      DisplayName: "Amount",
    },
    {
      Field: "isActive",
      FieldType: "string",
      DisplayName: "Status",
    },
  ],
};

export interface IContractListProps {
  view?: boolean;
}

const ContractMaster: React.FC<IContractListProps> = ({ view = false }) => {
  const dispatch = useAppDispatch();
  const { contractDetailsData, isContractDetailsDeleted, ToasterMsg } = useSelector(
    (state: any) => state.contractManagementReducer?.contractlist
  );

  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [contractlist, setContractList] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);

  const columns_version: any = {
    table_name: "Vendor History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = () => {
    dispatch(getContractList());
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      setIsAddorEdit(false);
      // setIsVersionHistory(true);
    } else if (option === "delete") {
      setOption(option);
      setIsDeleteData(data);
    } else if(option === "edit" || option === "view" || option === "clone" ||option === "renewal"){
      dispatch(updateLoader(true));
      dispatch(getContractById(data.contractId)).unwrap()
      .then((contractDetils: any) => {
        setIsEditDate({ ...contractDetils });
        setIsAddorEdit(true);
        setOption(option);          
      })
      .catch(error=>{
        console.log(error)
      });;
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    setOption("");
    initializeData();
  };

  useEffect(() => {
    setContractList(contractDetailsData);
  }, [contractDetailsData]);

  const handleDelete = () => {
    let inactiveFormData = {
      vendor_Contract_Id: isDeleteData.contractId,
      isActive: isDeleteData?.isActive === "Active" ? false : true,
      updated_By: userLoginResponse?.UserName,
      approved_By: userLoginResponse?.UserName,
      userId: userLoginResponse?.UserId,
      moduleName: "Contracts",
      activity: isDeleteData?.isActive === "Active" ? "inactive" : "active",
      createdBy: userLoginResponse?.UserName,
    };
    dispatch(activeInactiveContract(inactiveFormData));
  };

  useEffect(() => {
    if (isContractDetailsDeleted === true) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.message,
          isTosterFailed: ToasterMsg.status !== "Success",
        })
      );
      dispatch(updateContractDetailsAddandEdit(false));
      initializeData();
      setIsDeleteData("");
      setOption("");
    }
  }, [ToasterMsg, isContractDetailsDeleted]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          {!isAddorEdit ? (
            <React.Fragment>
              {view ? null : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <Typography variant="h6" color={"text.primary"}>
                    All Contracts
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <MaterialDynamicGrid
                  data={HeaderColumn}
                  rows={contractlist}
                  handleAction={handleOpen}
                  handleAdd={() => setAddFormcloseoropen(true)}
                  enableZoomIcon={true}
                  showhistory={false}
                  isDraftView={false}
                  draftCount={0}
                  isEnableDraft={false}
                  handleIsDraft={(val: any) => setisShowDraft(val)}
                  showAdd={!view}
                  showDelete={!view}
                  showView={!view}
                  showEdit={!view}
                  showClone={true}
                  showRenewal={true}
                  showExports={false}
                  userId={userLoginResponse?.UserId || 0}
                  pageName="Contract Master"
                  disableselect={true}
                  isActivekey="isActive"
                />
              </Box>
            </React.Fragment>
          ) : (
            <Box sx={{ width: "100%" }}>
              <InitiateContract
                view={Option == "view" || (isEditData && !(isEditData?.isActive)) ? true : false}
                edit={Option == "edit" ? true : false}
                clone={Option == "clone" ? true : false}
                renewal={Option == "renewal" ? true : false}
                isEditData={isEditData}
                onClose={() => setAddFormcloseoropen(false)}
                // isDraftData={isDraftData}
              />
            </Box>
          )}
        </Stack>
        <ModalPopUp
          onDeleteMethod={() => handleDelete()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          text={`Are you sure you want to ${
            isDeleteData?.isActive === "Active" ? "deactivate" : "activate"
          } the selected Contract`}
          ActionName={isDeleteData?.isActive === "Active" ? "Inactive" : "Active"}
        />
      </Box>
    </>
  );
};

export default ContractMaster;
