import React, { useEffect, useState } from "react";
import { VOBPreview } from "./VendorOnBoardPreview";
import { getLovList } from "../../../store/slices/lovMasterData";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { textFieldWidth } from "../../../utils/constants";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { LovConfig } from "../../../config/config";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { getVendorDetailsList } from "../../../store/slices/vendorDetails";
import { getAQMList } from "../../../store/slices/assessmentQuestionnaireMaster";
import { getWorkList } from "../../../store/slices/workFlowSlice";

export const VOBSections: React.FC<any> = ({ view }) => {
  const dispatch = useAppDispatch();
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);
  const { vendorDetailsdata } = useSelector((state: RootState) => state.vendorDetails);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [column, setColumn] = useState<any[]>([]);
  const [assesmentTypeILV, setassesmentTypeILV] = useState<any[]>([]);
  const [assesmentTypeIILV, setassesmentTypeIILV] = useState<any[]>([]);
  const [CategoryDataLV, setCategoryDataLV] = useState<any[]>([]);
  const [WorkflowData, setWorkflowData] = useState<any[]>([]);
  const [vendorData, setVendorData] = useState<any[]>([]);

  const {
    handleSubmit: handleSCESubmit,
    reset,
    setValue,
    formState: { errors: errorbasicdetails },
    control,
    watch,
    getValues,
  } = useFormContext();

  useEffect(() => {
    dispatch(getAQMList({ FormID: 15 }));
    dispatch(getVendorDetailsList());
  }, []);

  useEffect(() => {
    setassesmentTypeILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_Type) || []);
    setassesmentTypeIILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_TypeII) || []);
    setCategoryDataLV(lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Catagory) || []);
  }, [lovTable]);
  useEffect(() => {
    setVendorData(vendorDetailsdata);
  }, [vendorDetailsdata]);
  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(getWorkList());
  }, []);
  useEffect(() => {
    setColumn(lovTable.filter((item: any) => item.Name === LovConfig.RiskCategoryIII) || []);
  }, [lovTable]);

  useEffect(() => {
    if (workFlowsData.length > 0) {
      setWorkflowData(workFlowsData);
    }
  }, [workFlowsData]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          // border: "1px solid #F7F7F7",
          // padding: 2,
        }}
      >
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Assessment_Number"
              control={control}
              defaultValue={""}
              rules={{
                required: "Assessment Number is Required",
                validate: {
                  maxLength: (value) => {
                    if (value === undefined) return "Assessment Number is required";
                    return value.length <= 50 || "Maximum 50 characters allowed";
                  },
                },
              }}
              render={({ field }) => (
                <CustomTextField
                  label={"Assessment Number"}
                  name={"Assessment Number"}
                  value={field.value}
                  readOnly={true}
                  required={true}
                  onChange={field.onChange}
                  error={Boolean(errorbasicdetails.Assessment_Number)}
                  helperText={
                    errorbasicdetails.Assessment_Number && errorbasicdetails.Assessment_Number.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Vendor_Name"
              control={control}
              defaultValue=""
              rules={{ required: "Third Party Name is Required" }}
              render={({ field }) => (
                <CustomTextField
                    label={"Third Party Name"}
                    name={"Vendor Name"}
                    value={field.value}
                    readOnly={true}
                    required={true}
                    onChange={field.onChange}
                   
                  />
                
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name={`Validity_in_Months`}
              control={control}
              defaultValue={0}
               
              render={({ field }) => (
                <CustomNumberField
                  name={"Attribute Response"}
                  required={true}
                  readOnly={true}
                  label={"Validity In Months"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorbasicdetails.Validity_in_Months)}
                  helperText={
                    errorbasicdetails.Validity_in_Months && errorbasicdetails.Validity_in_Months.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Assessment_Type_1"
              control={control}
              defaultValue=""
              rules={{ required: "Assessment Type I is Required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"Assessment Type I"}
                  valueKey={"LovId"}
                  optionLabelKey="Options"
                  options={assesmentTypeILV}
                  readOnly={true}
                  required={true}
                  label={"Assessment Type I"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorbasicdetails.Assessment_Type_1)}
                  helperText={
                    errorbasicdetails.Assessment_Type_1 && errorbasicdetails.Assessment_Type_1.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Assessment_Type_2"
              control={control}
              defaultValue=""
              rules={{ required: "Assessment Type II for is Required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"Assessment Type II"}
                  valueKey={"LovId"}
                  optionLabelKey="Options"
                  options={assesmentTypeIILV}
                  readOnly={true}
                  required={true}
                  label={"Assessment Type II"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorbasicdetails.Assessment_Type_2)}
                  helperText={
                    errorbasicdetails.Assessment_Type_2 && errorbasicdetails.Assessment_Type_2.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
          
                <Controller
                name="Workflow_Name"
                control={control}
                defaultValue=""
                rules={{ required: "Workflow is Required" }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Workflow"}
                    name={"Workflow_Name"}
                    value={field.value}
                    readOnly={true}
                    required={true}
                    onChange={field.onChange}
                   
                  />
               
              )}
            />
          </Grid>

          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Start_Date"
              control={control}
              defaultValue={null}
              rules={{ required: "Start Date is Required" }}
              render={({ field }) => (
                <CustomDatePicker
                  minDate={dayjs()}
                  maxDate={dayjs().add(100, "year").toDate()}
                  label={"Start Date"}
                  readOnly={true}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorbasicdetails.Start_Date)}
                  helperText={errorbasicdetails.Start_Date && errorbasicdetails.Start_Date.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={textFieldWidth.xs}
            sm={textFieldWidth.sm}
            md={textFieldWidth.md}
            lg={textFieldWidth.lg}
            xl={textFieldWidth.xl}
          >
            <Controller
              name="Target_Date"
              control={control}
              defaultValue={null}
              rules={{ required: "Target Date is Required" }}
              render={({ field }) => (
                <CustomDatePicker
                  minDate={dayjs()}
                  maxDate={dayjs().add(100, "year").toDate()}
                  label={"Target Date"}
                  value={field.value}
                  readOnly={true}
                  onChange={field.onChange}
                  error={Boolean(errorbasicdetails.Target_Date)}
                  helperText={errorbasicdetails.Target_Date && errorbasicdetails.Target_Date.message?.toString()}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
