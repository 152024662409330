import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { DOCUMENT_URL } from "./endpoints/commonMasters";
import { IAddDocumentResponse, IDocumentResponse,IVHColumnRes } from "../../models/document.type";

const getDocumentList = (reqparams: any): Promise<IDocumentResponse> =>
  axiosInstance
    .get(DOCUMENT_URL.GetDocument)
    .then((response: AxiosResponse<IDocumentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

  const getDocumentByAssessmentTypeId = (reqparams: any): Promise<IDocumentResponse> =>
    axiosInstance
      .get(DOCUMENT_URL.GetDocumentByAssessmentTypeId, { params: reqparams })
      .then((response: AxiosResponse<IDocumentResponse>) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Error getting Data" + response);
      })
      .catch((error: AxiosError | string) => {
        throw error;
      });

const addEditDocument = (requestBody: any): Promise<IAddDocumentResponse> =>
  axiosInstance
    .post(DOCUMENT_URL.AddEditDocument, requestBody)
    .then((response: AxiosResponse<IAddDocumentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteDocument = (requestBody: any): Promise<IAddDocumentResponse> =>
  axiosInstance
    .post(DOCUMENT_URL.DeleteDocument, requestBody)
    .then((response: AxiosResponse<IAddDocumentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryDocument = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(DOCUMENT_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const DocumentMasterService = {
  getDocumentList,
  addEditDocument,
  deleteDocument,
  getVersionHistoryDocument,
  getDocumentByAssessmentTypeId
};

export default DocumentMasterService;
