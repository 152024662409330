import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { FAVOURITES_URL } from "./endpoints/favouritesNav";
import { IFavouriteMenuResponse } from "../../models/favouriteNav.type";

const addFavouriteNav = (requestBody: any): Promise<IFavouriteMenuResponse> =>
    axiosInstance
        .post(FAVOURITES_URL.Add_User_Favourite, requestBody)
        .then((response: AxiosResponse<IFavouriteMenuResponse>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const getFavouriteNav = (reqparams: any): Promise<IFavouriteMenuResponse> =>
    axiosInstance
        .get(FAVOURITES_URL.GET_User_Favourite, { params: reqparams })
        .then((response: AxiosResponse<IFavouriteMenuResponse>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const FavouritesNavService = {

    addFavouriteNav,
    getFavouriteNav,
};

export default FavouritesNavService;
