import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IBranchResponse, IAddBranchResponse, IVHColumnRes } from "../../models/branch.type";
import { BRANCHMASTER_URL } from "./endpoints/commonMasters";

const getBranchList = (reqparams: any): Promise<IBranchResponse> =>
  axiosInstance
    .get(BRANCHMASTER_URL.GetBranch)
    .then((response: AxiosResponse<IBranchResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const GetBranchByCompany = (reqparams: any): Promise<IBranchResponse> =>
  axiosInstance
    .get(BRANCHMASTER_URL.GetBranchByCompany, {params:reqparams})
    .then((response: AxiosResponse<IBranchResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addEditBranch = (requestBody: any): Promise<IAddBranchResponse> =>
  axiosInstance
    .post(BRANCHMASTER_URL.AddEditBranch, requestBody)
    .then((response: AxiosResponse<IAddBranchResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteBranch = (requestBody: any): Promise<IAddBranchResponse> =>
  axiosInstance
    .post(BRANCHMASTER_URL.DeleteBranch, requestBody)
    .then((response: AxiosResponse<IAddBranchResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryBranch = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(BRANCHMASTER_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const BranchMasterService = {
  getBranchList,
  addEditBranch,
  deleteBranch,
  getVersionHistoryBranch,
  GetBranchByCompany,

};

export default BranchMasterService;
