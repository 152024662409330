import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IVendor, IVersionHistroy , IColumnHeaders } from '../../models/authorize.type';
import vendorService from '../services/vendorService';


export interface IVendorData{
    dataTable: {vendor :IVendor[] , vendorHeader : IColumnHeaders[]};
    isLoading:boolean;   
    isVendorAdded:boolean ;
    isVendorUpdated: boolean;
    versionHistory:{vendorHistory :IVersionHistroy[] , vendorHeader : IColumnHeaders[]};
    isVendorHistory: boolean;
    viewChanges:any;
    ToasterMsg: string;
}

export const initialState: IVendorData={
    dataTable: {vendor : [], vendorHeader : []},
    isLoading: false,
    isVendorAdded: false,
    isVendorUpdated: false,
    versionHistory: {vendorHistory :[] , vendorHeader : []},
    isVendorHistory: false,
    viewChanges:[],
    ToasterMsg: "added vendor data successfully",
}

export const getVendorList = createAsyncThunk('getVendors', async(requesrParams: {location:string,registeredDate:string,EndDate:string,formId:string}, {rejectWithValue})=>{
    try{
        const response= await vendorService.getVendorList(requesrParams);
        const {Vendors , HeaderJson } =response.data;
        console.log("vender",Vendors,requesrParams);
        return {Vendors: Vendors.Vendors, HeaderJson : HeaderJson.ColumnHeaders}
    }catch(error){
        return rejectWithValue(error)
    }
})

export const addVendor = createAsyncThunk('addVendor', async(requesrParams: any, {rejectWithValue})=>{
    try{
        const response: any= await vendorService.addVendor(requesrParams);  
        return response.data   
        
        
    }catch(error){
        return rejectWithValue(error)
    }
})

export const updateVendor = createAsyncThunk('updateVendor', async(requesrParams: any, {rejectWithValue})=>{
    try{
        let reqbody= {...requesrParams}
        delete reqbody.registeredDate;
        delete reqbody.EndDate;        
        const response: any= await vendorService.updateVendor(reqbody); 
        return response.data;
    }catch(error){
        return rejectWithValue(error)
    }
})

export const getVersionHistory = createAsyncThunk('getVersionHistory', async(requesrParams: any, {rejectWithValue})=>{
    try{
        let reqbody= {...requesrParams}      
       
        const response= await vendorService.versionHistory(reqbody);
        const {VendorHistory , HeaderJson } =response.data;
        return {VendorHistory:VendorHistory, HeaderJson : HeaderJson.ColumnHeaders}
        
    }catch(error){
        return rejectWithValue(error)
    }
})
export const getViewChanges = createAsyncThunk('getViewChanges', async(requesrParams: any, {rejectWithValue})=>{
    try{
        let reqbody= {...requesrParams}      
       
        const response= await vendorService.getViewChanges(reqbody);
        return response.data;
        
    }catch(error){
        return rejectWithValue(error)
    }
})

export const vendorDataSlice = createSlice({
    name: 'vendorData',
    initialState,
    reducers:{
        updateAddandEdit:(state, action)=>{            
            state.isVendorAdded = false;
            state.isVendorUpdated = false;
        },
        updateUser:(state, action)=>{
            let indexVendor= state.dataTable.vendor.findIndex(data=> data.VendorId === action.payload.vendorId)
            let copyVendorData= [...state.dataTable.vendor];
            copyVendorData[indexVendor]= action.payload;
            state.dataTable.vendor = copyVendorData;
        },
        updateVersionHistory:  (state, action)=>{            
            state.versionHistory.vendorHistory = [];
        }, 
        updateVendorRecords :(state, action)=>{            
            state.dataTable.vendor = [];
        } 
    },
    extraReducers: (builder)=>{
        builder.addCase(getVendorList.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getVendorList.fulfilled, (state, action: PayloadAction<any>)=>{
          
            state.dataTable.vendor = action.payload.Vendors
            state.dataTable.vendorHeader = action.payload.HeaderJson
            state.isLoading = false;
        })
        .addCase(getVendorList.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })
        .addCase(addVendor.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addVendor.fulfilled, (state, action: PayloadAction<any>)=>{           
            state.isVendorAdded = true;
            state.isLoading = false;
            state.ToasterMsg = action.payload.Message;
        })
        .addCase(addVendor.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })
        .addCase(updateVendor.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(updateVendor.fulfilled, (state, action: PayloadAction<any>)=>{
            state.isVendorUpdated = true;            
            // let indexVendor= state.dataTable.findIndex(data=> data.vendorId === updatedUser.vendorId);
            // let copyVendorData= [...state.dataTable];
            // copyVendorData[indexVendor]= updatedUser;
            // state.dataTable = copyVendorData;
            state.isLoading = false;
            state.ToasterMsg = action.payload.Message;
        })
        .addCase(updateVendor.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })
        .addCase(getVersionHistory.pending, (state)=>{
            state.isVendorHistory = true;
        })
        .addCase(getVersionHistory.fulfilled, (state, action: PayloadAction<any>)=>{
            state.versionHistory.vendorHistory = action.payload.VendorHistory
            state.versionHistory.vendorHeader = action.payload.HeaderJson
            state.isVendorHistory = false;
        })
        .addCase(getVersionHistory.rejected, (state, action: PayloadAction<any>)=>{            
            state.isVendorHistory = false
        })
        .addCase(getViewChanges.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getViewChanges.fulfilled, (state, action: PayloadAction<any>)=>{
            console.log(action,"Action PAyload")
            state.viewChanges=action.payload;
            state.isLoading = false;
        })
        .addCase(getViewChanges.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })

    }
});

export const {updateAddandEdit,updateUser,updateVersionHistory,updateVendorRecords } = vendorDataSlice.actions;
 export default vendorDataSlice.reducer;