import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import workFlowService from '../services/workFlowService';
import customFormFieldsService from '../services/customFormFieldsService';
import { IWorkflowInstanceData } from '../../models/workFlow.type';


export interface IWorkflow{    
    isLoading: boolean;
    workFlowInstanceData: IWorkflowInstanceData[];
    ToasterMsg: string;
}

export const initialState: IWorkflow={
    isLoading: false,
    workFlowInstanceData: [],
    ToasterMsg: "",
}

export const getWorkInstanceList = createAsyncThunk("getWorkInstanceList", async (postRequest, { rejectWithValue }) => {
  try {
    const response = await workFlowService.getListOfWorkFlowInstance();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const workFlowInstanceSlice = createSlice({
    name: 'workFlowInstance',
    initialState,
    reducers:{  },  
    extraReducers: (builder)=>{
        builder
          .addCase(getWorkInstanceList.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getWorkInstanceList.fulfilled, (state, action: PayloadAction<any>) => {
            state.workFlowInstanceData =action.payload.map((workFlow: IWorkflowInstanceData) => {
              workFlow.IsActive = workFlow.IsActive ? "Active" : "Inactive";
              return workFlow;
            });
            state.isLoading = false;            
          })
          .addCase(getWorkInstanceList.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })
    }
});

export const {} = workFlowInstanceSlice.actions;
export default workFlowInstanceSlice.reducer;