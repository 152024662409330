import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import lovMasterService from "../services/lovService";
import { IAddLovResponse, ILov, ILovNamesResponse, ILovResponse, IVHColumnRes } from "../../models/lov.type";
import WorkFlowHistoryService from "../services/workFlowHistoryService";

export interface IWorkFlowHistoryData {
  WorkFlowHistoryTable: any;
  WorkFlowHistoryViewData: any;
  isLoading: boolean;
  ToasterMsg: any;
}

export const initialState: IWorkFlowHistoryData = {
  WorkFlowHistoryTable: [],
  WorkFlowHistoryViewData: [],
  isLoading: false,
  ToasterMsg: { Message: "", Status: "" },
};

export const getWorkFlowHistoryList = createAsyncThunk(
  "getWorkFlowHistoryList",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await WorkFlowHistoryService.getWorkFlowHistoryList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWorkFlowHistoryView = createAsyncThunk("getWorkFlowHistoryView", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await WorkFlowHistoryService.getWorkFlowHistoryView(reqbody);
    const { data } = response;
    return data;

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const WorkFlowHistoryDataSlice = createSlice({
  name: "WorkFlowHistoryData",
  initialState,
  reducers: {
    updateWorkFlowHistoryView: (state, action) => {

      state.ToasterMsg = { Message: "", Status: "" };
      state.WorkFlowHistoryViewData = [];
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkFlowHistoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkFlowHistoryList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");

        state.WorkFlowHistoryTable = action.payload;


        // state.lovTable = action.payload
        state.isLoading = false;
      })
      .addCase(getWorkFlowHistoryList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

      .addCase(getWorkFlowHistoryView.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getWorkFlowHistoryView.fulfilled, (state, action: PayloadAction<any>) => {
        state.WorkFlowHistoryViewData = action.payload

        state.isLoading = false;
        // state.ToasterMsg = "Department data deleted successfully";
      })
      .addCase(getWorkFlowHistoryView.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

  },
});

export const { updateWorkFlowHistoryView, } = WorkFlowHistoryDataSlice.actions;
export default WorkFlowHistoryDataSlice.reducer;
