import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MetricsMasterService from "../services/metersMasterService";
import { chkDate, chkExpiryDate } from "../../utils/constants";

export interface metricsMasterData {
  metricsMasterData: any[];
  isLoading: boolean;
  isAddEditMetricsMasterLoading: boolean;
  isMetricsMasterAdded: boolean;
  isMetricsMasterUpdated: boolean;
  isMetricsMasterDeleted: boolean;
  MetricsMastertoasterMsg: any;
  MetricsMasterversionHistory: {
    MetricsMasterversionHistory: any[];
    MetricsMasterVHeader: any[];
  };
}

export const initialState: metricsMasterData = {
  metricsMasterData: [],
  isLoading: false,
  isAddEditMetricsMasterLoading: false,
  isMetricsMasterAdded: false,
  isMetricsMasterUpdated: false,
  isMetricsMasterDeleted: false,
  MetricsMastertoasterMsg: {Message: "", Status: ""},
  MetricsMasterversionHistory: { MetricsMasterversionHistory: [], MetricsMasterVHeader: [] },
};

export const getMetricsMasterList = createAsyncThunk(
  "getMetricsMasterList",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await MetricsMasterService.getMetricsList(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addMetricsMaster = createAsyncThunk(
  "addMetricsMaster",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await MetricsMasterService.addMetrics(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMetricsMaster = createAsyncThunk(
  "updateMetricsMaster",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response = await MetricsMasterService.updateMetrics(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteMetricsMaster = createAsyncThunk(
  "deleteMetricsMaster",
  async (MetricsMasterId: any, { rejectWithValue }) => {
    try {
      const response = await MetricsMasterService.updateMetrics(MetricsMasterId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMetricsHistoryByID = createAsyncThunk("getMetricsHistoryByID", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await MetricsMasterService.getMetricsHistoryByID(reqbody);
    const {MetricsResponseHistories , HeaderJson } =response.data;
    return {MetricsResponseHistories:MetricsResponseHistories, HeaderJson : HeaderJson.ColumnHeaders}

  } catch (error) {
    return rejectWithValue(error);
  }
});
export const MetricsMasterSlice = createSlice({
  name: "MetricsMaster",
  initialState,
  reducers: {
    updateMetricsMasterAddandEdit: (state, action) => {
      state.isMetricsMasterAdded = false;
      state.isMetricsMasterUpdated = false;
      state.isMetricsMasterDeleted = false;
    },
    updateMetricsMasterVersionHistory:  (state, action)=>{            
      state.MetricsMasterversionHistory.MetricsMasterVHeader = [];
      state.MetricsMasterversionHistory.MetricsMasterversionHistory = [];
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetricsMasterList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMetricsMasterList.fulfilled, (state, action: PayloadAction<any>) => {
        state.metricsMasterData = action.payload
          ? action.payload.map((data: any) => {
              data.IsActive = data.IsActive === true ? "Active" : "Inactive";
              return data;
            })
          : [];
        state.isLoading = false;
      })
      .addCase(getMetricsMasterList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addMetricsMaster.pending, (state) => {
        state.isLoading = true;
        state.isAddEditMetricsMasterLoading = true;
      })
      .addCase(addMetricsMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.isMetricsMasterAdded = true;
        state.isLoading = false;
        state.isAddEditMetricsMasterLoading = false;
        state.MetricsMastertoasterMsg = action.payload;
      })
      .addCase(addMetricsMaster.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditMetricsMasterLoading = false;
      })
      .addCase(updateMetricsMaster.pending, (state) => {
        state.isLoading = true;
        state.isAddEditMetricsMasterLoading = true;
      })
      .addCase(updateMetricsMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.isMetricsMasterUpdated = true;
        state.isLoading = false;
        state.isAddEditMetricsMasterLoading = false;
        state.MetricsMastertoasterMsg = action.payload;
      })
      .addCase(updateMetricsMaster.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditMetricsMasterLoading = false;
      })
      .addCase(deleteMetricsMaster.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteMetricsMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.isMetricsMasterDeleted = true;
        state.isLoading = false;
        state.MetricsMastertoasterMsg = action.payload;
      })
      .addCase(deleteMetricsMaster.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getMetricsHistoryByID.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getMetricsHistoryByID.fulfilled, (state, action: PayloadAction<any>) => {
        state.MetricsMasterversionHistory.MetricsMasterVHeader  = action.payload.HeaderJson
        state.MetricsMasterversionHistory.MetricsMasterversionHistory = action.payload.MetricsResponseHistories.map((user:any)=>{
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.Approved_On = chkDate(user.Approved_On);
          return user;
        })
        state.isLoading = false;
        // state.ToasterMsg = "Department data deleted successfully";
      })
      .addCase(getMetricsHistoryByID.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
  },
});

export const { updateMetricsMasterAddandEdit ,updateMetricsMasterVersionHistory} = MetricsMasterSlice.actions;
export default MetricsMasterSlice.reducer;
