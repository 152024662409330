import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../common/dynamicButton/CustomButton";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import {  getContentForm } from "../../store/slices/contentMasterData";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { ContentNameConfig, MenuIdForVendoruse } from "../../config/config";
import { AddVendorConsentForms } from "../../store/slices/consentFormData";

interface FormData {
  Id?: any;
  Welcometext?: string;
  ContestText?: string;
  Terms?: boolean;
}

export interface vendorConsentProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  ModuleId?: number | undefined;
  currentProcessData?: any;
  onAccept?: any;
}

export const AddVendorConsentForm: React.FC<vendorConsentProps> = ({
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  ModuleId,
  currentProcessData,
  onAccept,
}) => {
  const dispatch = useAppDispatch();
  const { user, menuData, userLoginResponse, currentPagePermissionData, vendorLoginDetails } = useSelector(
    (state: RootState) => state.loggerData
  );

  const currentDate = new Date().toLocaleDateString();
  const { vendorConentPurposes } = useSelector(
    (state: RootState) => state.contentData
  );
  const [isrejectshow, setisrejectshow] = useState<any>(false);

  let formData = {
    Id: isEditData ? isEditData?.Id : 0,
    Welcometext: isEditData ? isEditData?.Welcometext : "",
    ContestText: isEditData ? isEditData?.ContestText : "",
    Terms: isEditData ? (isEditData?.Terms === "Yes" ? true : false) : false,
  };

  const {
    handleSubmit: handleConsentForm,
    reset,
    setValue,
    formState: { errors: errorsConsentForm },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  function extractContent(htmlString: string): string {
    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string into a document
    const doc = parser.parseFromString(htmlString, "text/html");

    // Extract and return the text content
    return doc.body.textContent || "";
  }

  useEffect(() => {
    const consentPurposesArray = [ContentNameConfig.vendorwelcometext,ContentNameConfig.vendorContext];

    // Construct the request body
    const queryString = consentPurposesArray.reduce((acc, purpose, index) => {
      acc[`consentPurposes[${index}]`] = purpose;
      return acc;
    }, {} as Record<string, string>);
    dispatch(getContentForm(queryString));
  }, []);

  useEffect(() => {
    console.log(vendorConentPurposes);
    let EditObject = { Welcometext: "", ContestText: "" };

    vendorConentPurposes.map((item: any) => {
      if (item.Content_Purpose === ContentNameConfig.vendorwelcometext) {
        EditObject.Welcometext = item.Content_Text;
        setValue("Welcometext", extractContent(EditObject.Welcometext.replace("[ThirdPartyName]", vendorLoginDetails?.vendorName || "User")));
      }
      if (item.Content_Purpose === ContentNameConfig.vendorContext) {
        EditObject.ContestText = item.Content_Text;
        setValue("ContestText", extractContent(EditObject.ContestText.replace("[ThirdPartyName]", vendorLoginDetails?.vendorName || "User")));
      }
    });
  }, [vendorConentPurposes]);

  const handleSubmit = (data: any, sec: string) => {
    console.log(data, sec, currentProcessData);

    let request: any = {
      Consent_Purpose: data.Welcometext,
      Consent_Party: data.ContestText,
      Party_Name: vendorLoginDetails?.vendorName,
      Consent_By: user?.UserName,
      Consent_Status: sec === "accept" ? 1 : 0,
      Updated_By: user?.UserName,
      ModuleName: "Consent Form",
      Activity: sec === "accept" ? "Approved" : "Rejected",
      Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
      Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
      Vendor_Id: currentProcessData ? currentProcessData?.VendorId : 0,
      MenuId: MenuIdForVendoruse,
      FormId: currentProcessData?.ProcessId,
      IsRenewal: currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false,
      ProjectId: currentProcessData?.ProjectId ? currentProcessData?.ProjectId : 0,
    };

    console.log(request);

    dispatch(AddVendorConsentForms(request));
  };

  const handleSubmitaccept = () => {
    handleSubmit(getValues(), "accept");
  };
  const handleSubmitreject = () => {
    setisrejectshow(true);
    // handleSubmit(getValues(), "reject");
    if (onAccept) {
      onAccept();
    }
  };

  return (
    <>
      <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="Welcometext"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <>
                  {currentProcessData ? (
                    <Box sx={{ display: "flex", width: "100%", gap: 2, flexDirection: "column" }}>
                      {false && (
                        <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                          {`Welcome Text`}
                        </Typography>
                      )}
                      <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                        {field.value}
                      </Typography>
                    </Box>
                  ) : (
                    <CustomMultilineTextField
                      label={"Welcome Text"}
                      value={field.value}
                      rows={3}
                      // required={true}
                      readOnly={true}
                      onChange={field.onChange}
                      error={Boolean(errorsConsentForm.Welcometext)}
                      helperText={errorsConsentForm.Welcometext && errorsConsentForm.Welcometext.message?.toString()}
                    />
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="ContestText"
              control={control}
              defaultValue={""}
              render={({ field }) =>
                currentProcessData ? (
                  <Box sx={{ display: "flex", width: "100%", gap: 2, flexDirection: "column" }}>
                    {false && (
                      <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                        {`Consent Text`}
                      </Typography>
                    )}
                    <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                      {field.value}
                    </Typography>
                  </Box>
                ) : (
                  <CustomMultilineTextField
                    label={"Consent Text"}
                    value={field.value}
                    rows={8}
                    readOnly={true}
                    // required={true}
                    onChange={field.onChange}
                    error={Boolean(errorsConsentForm.ContestText)}
                    helperText={errorsConsentForm.ContestText && errorsConsentForm.ContestText.message?.toString()}
                  />
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="Terms"
              control={control}
              defaultValue={false}
              rules={{
                required: "Terms & conditions is Required",
              }}
              render={({ field }) => (
                <CustomCheckBox
                  isStyle={true}
                  alignItems="left"
                  label="I have read the terms & conditions"
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsConsentForm.Terms)}
                  helperText={errorsConsentForm.Terms && errorsConsentForm.Terms.message?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box>Consent By : {vendorLoginDetails?.vendorName}</Box>
              <Box>Consent On : {currentDate}</Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "right",
            mt: 2,
          }}
        >
          {!view ? (
            !edit ? (
              <></>
            ) : (
              <>
                {/* <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    onClose();
                  }}
                /> */}
                <CustomButton variant="contained" name={"Accept"} onClick={handleConsentForm(handleSubmitaccept)} />
                {/* <CustomButton variant="contained" name={"Reject"} onClick={handleConsentForm(handleSubmitreject)} /> */}
              </>
            )
          ) : (
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                onClose();
              }}
            />
          )}
        </Box>
      </Box>

      <ModalPopUp
        onDeleteMethod={() => handleSubmit(getValues(), "reject")}
        isDelete={true}
        onHide={() => setisrejectshow(false)}
        show={isrejectshow}
        ActionName={"Reject"}
        header={"Confirm"}
        text={`Are you sure you want to reject you assessment?`}
      />
    </>
  );
};
