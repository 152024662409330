import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import {
  GetBranchByCompanyList,
  addEditBranch,
  getBranchList,
  updateBranchAddandEdit,
} from "../../../store/slices/branchMasterData";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getListOfValues } from "../../../store/slices/ListOfValues";
import ProgressBar from "../../../common/progressBar/progressBar";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddLocation } from "../location/AddLocation";
import { AddListValues } from "../listofValues/AddListValues";
import { LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";

interface FormData {
  BranchId?: number;
  BranchCode: string;
  BranchName: string;
  BranchType: string;
  CompanyId: any;
  City: string;
  District: string;
  State: string;
  Region: string;
  IsActive: string;
}

export interface branchMasterProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
  isSelectedData?: any;
}

export const AddBranchMaster: React.FC<branchMasterProps> = ({
  view,
  closePopUp,
  edit,
  isEditData,
  isSelectedData,
}) => {
  const dispatch = useAppDispatchThunk();
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const [message, setMessage] = useState<boolean>(false);
  const [BranchTypelov, setBranchTypelov] = useState<any>([]);
  const [AddlovPopup, setAddlovPopup] = useState<boolean>(false);
  const [addPopupHeader, setAddPopupHeader] = useState<string>("");
  const [lovName, setLovName] = useState<string>("");
  const [IsCancel, setIsCancel] = useState<boolean>(false);

  const { lovTable, islovAdded } = useSelector((state: RootState) => state.lovMasterData);

  const { BranchTable, isBranchAdded, isBranchDeleted, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.BranchMasterData
  );

  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);

  console.log(lovTable, "lovTable");

  useEffect(() => {
    handleFetchValues();
  }, []);

  const handleFetchValues = () => {
    dispatch(getLovList({ Name: "" }));

    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
  };

  useEffect(() => {
    setBranchTypelov(lovTable.filter((item: any) => item.Name === LovConfig.Branch_Type) || []);
  }, [lovTable]);

  console.log(isEditData, "isEditData");

  const pharsedDocName = isEditData ? JSON.parse(isEditData?.CompanyId) : [];
  const optionsArray = pharsedDocName && pharsedDocName.length > 0 && pharsedDocName.map((doc: any) => doc?.Company_Id);
  console.log(optionsArray, "optionsArrayoptionsArray");

  let formData: any = {
    BranchId: isEditData ? isEditData?.BranchId : 0,
    BranchCode: isEditData ? isEditData?.BranchCode : "",
    BranchName: isEditData ? isEditData?.BranchName : "",
    BranchType: isEditData ? isEditData?.BranchType : "",
    CompanyId: isEditData ? optionsArray : [],
    Region: isEditData ? isEditData?.Region : "",
    State: isEditData ? isEditData?.State : "",
    District: isEditData ? isEditData?.District : "",
    City: isEditData ? isEditData?.City : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  const {
    handleSubmit: handleBranchMasterForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const CompanyWatch = watch("CompanyId");
  console.log(CompanyWatch, "CompanyWatch");

  const isActiveDisabled = watch("IsActive");
  const transformData = (Branch: any) => {
    let BranchCompanyArray = [];

    if (Branch) {
      try {
        BranchCompanyArray = JSON.parse(Branch);
      } catch (error) {
        console.error("Invalid JSON in Branch.CompanyId:", Branch.CompanyId, error);
      }
    }

    const Legalname = BranchCompanyArray.map((item: any) => item?.Legal_Name).join(",");
    return Legalname;
  };
  console.log(isDirty, "isDirtyisDirtyisDirty");
  const handleSubmit = (data: any) => {
    console.log(getValues());
    dispatch(updateLoader(true));
    let formData = data;

    const transformedDocTypeExtnId = formData.CompanyId.map((id: any) => {
      const matchingItem = CompanyTable.find((item: any) => item.Company_Id === id);
      return matchingItem ? { Company_Id: matchingItem.Company_Id, Legal_Name: matchingItem.Legal_Name } : null;
    }).filter((item: any) => item !== null);
    console.log(JSON.stringify(transformedDocTypeExtnId));

    formData.CompanyId = JSON.stringify(transformedDocTypeExtnId);

    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 14;
    formData.ModuleName = "Branch Master";
    formData.Remarks = "";
    formData.UserId = userLoginResponse?.UserId;
    formData.CreatedBy = user?.UserName;
    formData.UpdatedBy = user?.UserName;

    let JsonDataForDisplay: any = {
      "Branch Code": formData?.BranchCode || "",
      "Branch Name": formData?.BranchName || "",
      "Branch Type": BranchTypelov.find((item: any) => item.LovId == formData?.BranchType)?.Options || "",
      "Company": transformData(formData.CompanyId) || "",
      "Branch City": formData?.City || "",
      "Branch State": formData?.State || "",
      "Branch Region": formData?.Region || "",
      "Branch Zone": formData?.District || "",
      "Status": formData.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    };

    if (edit) {
      formData.IsActive = formData.IsActive ? true : false;
      formData.Activity = "Edit";
      formData.ActionTaken = "Edit";
      console.log(formData, " edit formData");
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addEditBranch(formData));
    } else {
      formData.Activity = "Create";
      formData.ActionTaken = "Create";
      formData.IsActive = true;
      console.log(formData, "formData");
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addEditBranch(formData));
    }
  };

  useEffect(() => {
    if (isBranchAdded) {
      dispatch(
        GetBranchByCompanyList({
          CompanyId: isSelectedData,
        })
      );
      dispatch(updateBranchAddandEdit(false));
      reset(formData);
      if (closePopUp) {
        closePopUp();
        // setLoader(false);
        dispatch(updateLoader(false));
      }
    }
  }, [isBranchAdded]);

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      closePopUp && closePopUp();
    }
  }

  // const onAddType = (header: string, fieldName: any, LovName: any) => {
  //     setAddPopupHeader(header);
  //     setLovName(LovName);
  //     setAddlovPopup(true);
  // };

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add Branch Master
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit Branch Master
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View Branch Master
              </Typography>
            )}
          </Box>
        )}
        <Grid
          sx={{
            height: "auto",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="BranchCode"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Branch Code is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        maxLength: (value) => value.length < 20 || "Maximum 20 characters requires",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"BranchCode"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Branch Code"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.BranchCode)}
                        helperText={errorsloginform.BranchCode && errorsloginform.BranchCode.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="BranchName"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Branch Name is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters requires",
                        ValueLimitation: (value) =>
                          /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) ||
                          "Name cannot contain special characters except for , . - and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"BranchName"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Branch Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.BranchName)}
                        helperText={errorsloginform.BranchName && errorsloginform.BranchName.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="BranchType"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Branch Type is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"BranchType"}
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={BranchTypelov}
                        // addField={true}
                        // onAddClick={() => {
                        //     onAddType("Add Branch Type", "BranchType", "Branch Type");
                        // }}
                        disabled={view || !isActiveDisabled}
                        required={true}
                        label={"Branch Type"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.BranchType)}
                        helperText={errorsloginform.BranchType && errorsloginform.BranchType.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="CompanyId"
                    control={control}
                    defaultValue={[]}
                    rules={{ required: "Company is required" }}
                    render={({ field }) => (
                      <CustomMultiSelect
                        optionLabelKey={`Legal_Name`}
                        optionIdKey={`Company_Id`}
                        options={CompanyTable}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Company"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.CompanyId)}
                        helperText={errorsloginform.CompanyId && errorsloginform.CompanyId.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="City"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Branch City is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters requires",
                        ValueLimitation: (value) =>
                          /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) ||
                          "City cannot contain special characters except for , . - and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"City"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Branch City"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.City)}
                        helperText={errorsloginform.City && errorsloginform.City.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="District"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Branch Zone is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters requires",
                        ValueLimitation: (value) =>
                          /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) ||
                          "Zone cannot contain special characters except for , . - and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"District"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Branch Zone"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.District)}
                        helperText={errorsloginform.District && errorsloginform.District.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="State"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Branch State is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters requires",
                        ValueLimitation: (value) =>
                          /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) ||
                          "State cannot contain special characters except for , . - and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"State"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Branch State"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.State)}
                        helperText={errorsloginform.State && errorsloginform.State.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Region"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Branch Region is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters requires",
                        ValueLimitation: (value) =>
                          /^[a-zA-Z0-9,.\- ]+$/.test(value.trim()) ||
                          "Region cannot contain special characters except for , . - and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Region"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Branch Region"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Region)}
                        helperText={errorsloginform.Region && errorsloginform.Region.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                {/* {edit || view ? (
                                    <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Box sx={{ mt: 1 }}>
                                            <Controller
                                                name="IsActive"
                                                control={control}
                                                render={({ field }) => (
                                                    <CustomToogleSwitch
                                                        label={"User Status"}
                                                        dynamicLabel={field.value ? 'ACTIVE' : 'INACTIVE'}
                                                        disabled={view}
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Grid>
                                ) : null} */}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    name={"Cancel"}
                    onClick={() => {
                      closePopUp && closePopUp();
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        handleCancelPopup();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty || !isActiveDisabled}
                      name={"Update"}
                      onClick={handleBranchMasterForm(handleSubmit)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        handleCancelPopup();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty || !isActiveDisabled}
                      name={"Submit"}
                      onClick={handleBranchMasterForm(handleSubmit)}
                    />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
      {/* <CustomDialog
                show={AddlovPopup}
                onHide={() => {
                    setAddlovPopup(false);
                }}
                maxWidth={"md"}
                minHeight={"50vh"}
                header={addPopupHeader}
                contentNode={
                    <>
                        <AddListValues
                            closePopUp={() => {
                                setAddlovPopup(false);

                            }}
                            lovID={lovName}
                        />
                    </>
                }
            /> */}
      <ModalPopUp
        onDeleteMethod={() => closePopUp && closePopUp()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </Grid>
  );
};
