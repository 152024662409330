import { Grid, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import RichTextEditor, { EditorValue } from "react-rte";

import { useState } from "react";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import {
  ACTIVE,
  EMAIL_ADD_SUCCESS_MSG, EMAIL_ADD_UPDATING_MSG, EMAIL_EDIT_SUCCESS_MSG, EMAIL_EDIT_UPDATING_MSG,
  INACTIVE, templateName, textFieldWidth
} from "../../utils/constants";
import { IMenu } from "../../models/loggin.type";
import { addEmailTemplate, updateAddandEdit, updateEmailTemplate } from "../../store/slices/EmailTemplateData";
import { LovConfig, ToasterActivityConfig } from "../../config/config";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { getLovList } from "../../store/slices/lovMasterData";
// import MUIRichTextEditor from 'mui-rte';
// import { stateToHTML } from 'draft-js-export-html';

export interface popupProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  // setIsEdit?:boolean;
  isEdited?: any;
  isAdded?: any;
}

interface FormData {
  EmailTemplateId?: number;
  ModuleName: string;
  TemplateName: string;
  EmailSubject: string;
  FromEmail: string;
  Template: EditorValue;
  TemplateDescription : EditorValue;
  IsAttachment: string;
  IsActive?: number;
  CreatedBy?: string;
  UpdateBy?: string;
}

export const AddEmailTemplate: React.FC<popupProps> = ({ view, isEditData, edit, isEdited, isAdded }) => {

  console.log(isEditData, "isEditDataisEditData");
  const [text, setText] = useState((edit || view) ? RichTextEditor.createValueFromString(isEditData?.Template, "html") : RichTextEditor.createEmptyValue());

  const dispatch = useAppDispatchThunk();
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { allMenuData } = useSelector((state: RootState) => state.emailTemplateData);
  console.log(allMenuData, "allMenuDataRes");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);


  const [option_module, setOption_module] = useState<any[]>([]);
  const [templateNameOption, setTemplateNameOptions] = useState<any[]>(templateName);

  
  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  
  }, []);

  useEffect(() => {
    setTemplateNameOptions(lovTable.filter((item: any) => item.Name === LovConfig?.Email_template_name) || []);
  }, [lovTable]);
  useEffect(() => {
    let option_modules: any[] = [];
    if (allMenuData.length > 0)
      allMenuData?.map((item: any) => {
        if (item.SubMenus.length == 0) {
          option_modules.push({ key: item.MenuName, value: item.MenuName });
        } else {
          if (item.SubMenus.length != 0) {
            item.SubMenus.map((itm: any) => {
              option_modules.push({ key: itm.MenuName, value: itm.MenuName });
            });
          }
        }
      });
    setOption_module(option_modules);
  }, [allMenuData]);




  // const toolbarConfig = {
  //     // Optionally specify the groups to display (displayed in the order listed).
  //     display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  //     INLINE_STYLE_BUTTONS: [
  //       {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
  //       {label: 'Italic', style: 'ITALIC'},
  //       {label: 'Underline', style: 'UNDERLINE'}
  //     ],
  //     BLOCK_TYPE_DROPDOWN: [
  //       {label: 'Normal', style: 'unstyled'},
  //       {label: 'Heading Large', style: 'header-one'},
  //       {label: 'Heading Medium', style: 'header-two'},
  //       {label: 'Heading Small', style: 'header-three'}
  //     ],
  //     BLOCK_TYPE_BUTTONS: [
  //       {label: 'UL', style: 'unordered-list-item'},
  //       {label: 'OL', style: 'ordered-list-item'}
  //     ]
  //   };

  let formData = {

    EmailTempId: isEditData ? isEditData?.EmailTempId : 0,
    ModuleName: isEditData ? isEditData?.ModuleName : "",
    TemplateName: isEditData ? isEditData?.TemplateName : "",
    EmailSubject: isEditData ? isEditData?.EmailSubject : "",
    FromEmail: isEditData ? isEditData?.FromEmail : "",
    Template: isEditData ? RichTextEditor.createValueFromString(isEditData?.Template, "html") : RichTextEditor.createEmptyValue(),
    TemplateDescription : isEditData ? RichTextEditor.createValueFromString(isEditData?.Template, "html") : RichTextEditor.createEmptyValue(),
    IsAttachment: (isEditData && isEditData?.IsAttachment) ? isEditData?.IsAttachment : false,
    IsActive: isEditData && isEditData?.IsActive ? (isEditData.IsActive === "Active" ? 1 : 0) : 1,
    CreatedBy: isEditData ? isEditData?.CreatedBy : 0,
  };

  const {
    handleSubmit: handleEmailTemplate,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  //!edit && !view ? setValue("IsActive", 1) : setValue("IsActive", isEditData?.IsActive ? isEditData?.IsActive : 0);

  const { user } = useSelector((state: RootState) => state.loggerData);

  console.log("userName", user);



  const { isEmailTemplateAdded, isEmailTemplateUpdated, isEmailLoading , emailtemplatetoasterMsg } = useSelector(
    (state: RootState) => state.emailTemplateData
  );


  const [message, setMessage] = useState<boolean>(false);

  useEffect(() => {
    if (isEmailTemplateAdded) {
      reset(formData);
      if (isAdded) {
        console.log(emailtemplatetoasterMsg , 'emailtemplatetoasterMsg');

        dispatch(updateToaster({ isToaster: true, toasterMsg: emailtemplatetoasterMsg }));
        isAdded(false);
        dispatch(updateAddandEdit(false));

      }
    } else if (isEmailTemplateUpdated) {
      if (edit) {
        console.log(emailtemplatetoasterMsg , 'emailtemplatetoasterMsg');
        
        dispatch(updateToaster({ isToaster: true, toasterMsg: emailtemplatetoasterMsg }));
        console.log("email template Edit");
        isEdited(false);
        dispatch(updateAddandEdit(false));
      }
    }
  }, [isEmailTemplateAdded, isEmailTemplateUpdated]);

  useEffect(() => {
    dispatch(updateLoader(isEmailLoading ));
  }, [isEmailLoading]);

  const handleSubmit = (data: any) => {
    if (edit) {
      let request: any = { ...getValues() };
      request.Template = request.Template.toString('html');
      request.TemplateDescription = 'Description';
      console.log("updateEmails", getValues());
      request.IsActive = request.IsActive ? 1 : 0;
      request.ModuleName = "Email Template";
      request.Activity = ToasterActivityConfig.Edit;
      // request["UpdateBy"] =user?.UserName;
      dispatch(updateEmailTemplate(request));
    } else {
      let request: any = { ...getValues() };
      request.Template = request.Template.toString('html');
      request.TemplateDescription = 'Description';
      delete request.EmailTemplateId;
      delete request.registeredDate;
      request.IsActive = request.IsActive ? 1 : 0;
      request.ModuleName = "Email Template";
      request.Activity = ToasterActivityConfig.create;
      // request["CreatedBy"] =user?.UserName;
      delete request.EndDate;
      dispatch(addEmailTemplate(request));
      console.log("submitted successFully ", getValues());
    }
  };

  function isJSONString(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="ModuleName"
            control={control}
            rules={{
              required: "Module Name is Required",
            }}
            render={({ field }) => {
              console.log(field.value);
              return (
                <CustomSelect
                  name="select"
                  valueKey={"value"}
                  optionLabelKey="value"
                  options={option_module}
                  required={true}
                  label={"Module Name"}
                  disabled={view}
                  value={field.value}
                  onChange={field.onChange}
                  error={errorsloginform.ModuleName && errorsloginform.ModuleName.message?.toString()}
                />
              );
            }}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="TemplateName"
            control={control}
            rules={{
              required: "Template Name is Required",
            }}
            render={({ field }) => (
              <CustomSelect
                name="select"
                valueKey="Options"
                optionLabelKey="Options"
                options={templateNameOption}
                required={true}
                label={"Template Name"}
                disabled={view}
                value={field.value}
                onChange={field.onChange}
                error={errorsloginform.TemplateName && errorsloginform.TemplateName.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="EmailSubject"
            control={control}
            defaultValue={""}
            rules={{
              required: "Email Subject is Required",
              validate: (data) =>{
                if (data.length < 10) {
                  return "Minimal 10 characters required";
                }
                if (data.length > 100) {
                  return "Maximum 100 characters allowed";
                }
                return true;
              },
            }}
            render={({ field }) => (
              <CustomTextField
                name={"EmailSubject"}
                required={true}
                label={"Email Subject"}
                value={field.value}
                disabled={view}
                onChange={field.onChange}
                error={Boolean(errorsloginform.EmailSubject)}
                helperText={errorsloginform.EmailSubject && errorsloginform.EmailSubject.message?.toString()}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="FromEmail"
            control={control}
            defaultValue={""}
            rules={{
              required: "Email is Required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <CustomTextField
                name={"FromEmail"}
                required={true}
                label={"From Email"}
                value={field.value}
                disabled={view}
                onChange={field.onChange}
                error={Boolean(errorsloginform.FromEmail)}
                helperText={errorsloginform.FromEmail && errorsloginform.FromEmail.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={4}
          lg={4}
          xl={4}
        >
     
            <Controller
              name="IsAttachment"
              control={control}
              defaultValue={""}
              // rules={{
              //   required: "Required Status",
              // }}
              render={({ field }) => (
               
                  <CustomCheckBox
                    label="Is Attachment Needed "
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(errorsloginform.IsAttachment)}
                    helperText={errorsloginform.IsAttachment && errorsloginform.IsAttachment.message?.toString()}
                    isStyle={true}
                  />
              
              )}
            />
         
        </Grid>
       {false && <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={4}
          lg={4}
          xl={4}
        >
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2, mt: "8px", }}>
            <Controller
              name="IsActive"
              control={control}
              render={({ field }) => (
                <CustomToogleSwitch
                  label={"Status"}
                  dynamicLabel={field.value ? ACTIVE : INACTIVE}
                  value={field.value}
                  disabled={view}
                  onChange={(data) => {
                    console.log("datas", data == true ? 1 : 0);
                    const datas = data == true ? 1 : 0;
                    setValue("IsActive", datas);
                  }}
                />
              )}
            />
          </Box>
        </Grid> }

        <Grid item xs={12}>
          <Controller
            name="Template"
            control={control}
            render={({ field }) => (
              <>
                <RichTextEditor
                  editorClassName="editor"
                  editorStyle={{ fontSize: "25px", height: width >= 768 ? "40vh" : "30vh" }}
                  value={field.value}
                  //   onChange={(x: any) => setText(x)}
                  onChange={field.onChange}
                />
                {errorsloginform.Template ? <span>{errorsloginform.Template && errorsloginform.Template.message?.toString()}</span> : null}
              </>
            )}

          />
        </Grid>



      </Grid >
      {message ? (
        <Box sx={{ display: "flex", justifyContent: "Center" }}>
          <Typography variant="h6">
            {" "}
            {edit ? EMAIL_EDIT_SUCCESS_MSG : EMAIL_ADD_SUCCESS_MSG}
          </Typography>
        </Box>
      ) : null}

      {isEmailLoading ? (
        <Box sx={{ display: "flex", justifyContent: "Center" }}>
          <Typography variant="h6">
            {" "}
            {edit ? EMAIL_EDIT_UPDATING_MSG : EMAIL_ADD_UPDATING_MSG}
          </Typography>
        </Box>
      ) : null}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "right",
            // mt: 2,
            // position: "absolute",
            // right: 24,
            // bottom: 16,
          }}
        >
          {!view ? (
            !edit ? (
              <>
                <CustomButton
                  type="reset"

                  variant="outlined"
                  color="inherit"
                  name={"Cancel"}
                  onClick={() => {
                    reset(formData);
                    if (isAdded) {
                      isAdded(false);
                    }
                  }}
                />
                <CustomButton type="reset" name={"Submit"} onClick={handleEmailTemplate(handleSubmit)} />


              </>
            ) : (
              <>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    console.log(isEdited);
                    if (isEdited) {
                      isEdited(false);
                    }
                  }}
                />

                <CustomButton variant="contained"
                  name={"Update"}
                  onClick={handleEmailTemplate(handleSubmit)} />
              </>
            )
          ) : (
            <CustomButton
              type="reset"

              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                console.log(isEdited);
                if (isEdited) {
                  isEdited(false);
                }
              }}
            />
          )}
        </Box>
      </Box>

    </Box>
  );
};