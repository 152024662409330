import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { HOLIDAYMASTER_URL } from "./endpoints/commonMasters";
import { IAddHolidayResponse, IHolidayResponse, IVHColumnRes } from "../../models/holiday.type";

const getHolidayList = (reqparams: any): Promise<IHolidayResponse> =>
    axiosInstance
        .get(HOLIDAYMASTER_URL.GetHoliday)
        .then((response: AxiosResponse<IHolidayResponse>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const getHolidayWithdaterange = (requestBody: any): Promise<IVHColumnRes> =>
    axiosInstance
        .get(HOLIDAYMASTER_URL.GetHolidayWithRange, { params: requestBody })
        .then((response: AxiosResponse<IVHColumnRes>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const CreateHoliday = (requestBody: any): Promise<IAddHolidayResponse> =>   
    axiosInstance
        .post(HOLIDAYMASTER_URL.CreateHoliday, requestBody)
        .then((response: AxiosResponse<IAddHolidayResponse>) => {

            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const UpdateHoliday = (requestBody: any): Promise<IAddHolidayResponse> =>
    axiosInstance
        .put(HOLIDAYMASTER_URL.UpdateHoliday, requestBody)
        .then((response: AxiosResponse<IAddHolidayResponse>) => {
            if (response.status === 200) {
                console.log(requestBody, "requestBody");
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const deleteHoliday = (requestBody: any): Promise<IAddHolidayResponse> =>
    axiosInstance
        .put(HOLIDAYMASTER_URL.DeleteHoliday, requestBody)
        .then((response: AxiosResponse<IAddHolidayResponse>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });
const getVersionHistoryHoliday = (requestBody: any): Promise<IVHColumnRes> =>
    axiosInstance
        .get(HOLIDAYMASTER_URL.VersionHistory, { params: requestBody })
        .then((response: AxiosResponse<IVHColumnRes>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const HolidayMasterService = {
    getHolidayList,
    getHolidayWithdaterange,
    CreateHoliday,
    UpdateHoliday,
    deleteHoliday,
    getVersionHistoryHoliday,

};

export default HolidayMasterService;
