import { FC, useCallback, useEffect, useReducer, useState } from "react";
// import Dashboard from "../components/dashboard/Dashboard";
import AddMasters from "../components/addMaster/AddMasters";
import { Box, Button, CircularProgress } from "@mui/material";
import Header from "../components/header/Header";
//import Sidenavbar from "../components/navbars/SideNavBar";
import Sidenavbar from "../components/navbars/SideNavBarNew";
import Dashboard from "../components/dashboard/Dashboard";
import ErrorLog from "../components/errorLog/errorLog";
import EventLogs from "../components/EventLog";
import VendorMain from "../addvendor/VendorMain";
import UserPage from "../components/userManagement/UserManagement";
import RolesManagement from "../components/rolesManagement/RolesManagement";
import EmailTemplate from "../components/emailTemplate/EmailTemplate";
import Toaster from "../components/toaster/Toaster";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../store/store";
import Backdrop from "@mui/material/Backdrop";
import Department from "../components/master/department/Department";
import AssessmentCategory from "../components/master/assessmentCategoryMaster/assessmentCategoryMaster";
import ChangePassword from "../components/changePassword/ChangePassword";
import { Footer } from "../components/footer/Footer";
import BulkUpload from "../components/master/masterBulkUpload/BulkUpload";
import MasterMain from "../components/masterMain/MasterMain";
import SubDepartmentMaster from "../components/subDepartmentMaster/SubDepartmentMaster";
import MakerChecker from "../components/makerChecker/MakerChecker";
import PendingApproval from "../components/pendingApproval/PendingApproval";
import AssessmentQuestionnaireMaster from "../components/master/assessmentQuestionnaireMaster/AssessmentQuestionnaireMaster";
import VendorConsentForm from "../components/master/contentMaster/ContentMaster";
import Industry from "../components/master/industry/Industry";
import Location from "../components/master/location/Location";
import ListofValues from "../components/master/listofValues/ListofValues";
import SamMakerChecker from "../components/makerChecker/SamMakerChecker";
import SamPendingApproval from "../components/pendingApproval/SamPendingApproval";
import DocumentMaster from "../components/master/documentMaster/DocumentMaster";
import { CurrencyMaster } from "../components/master/currencyMaster/CurrencyMaster";
import IrRiskMaster from "../components/irRiskMaster/IrRiskMaster";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { setCurrentPagePermission } from "../store/slices/authorization";
import { SecurityParameters } from "../components/SecurityParameters/SecurityParameters";
import { BranchMaster } from "../components/master/branchMaster/BranchMaster";
import { ApplicationParrameters } from "../components/applicationParameters/ApplicationParameters";
import { ThirdPartyMaster } from "../components/master/thirdPartyMaster/ThirdPartyMaster";
import { RegionalSettings } from "../components/master/regionalSettings/RegionalSettings";
import ServiceCatalogMaster from "../components/serviceCatalog/ServiceCatalogMaster";
import VendorDetailsMaster from "../components/vendorDetails/VendorDetailsMaster";
import { CompanyMasterMain } from "../components/companyMaster/CompanyMasterMain";
import WorkFlow from "../components/workflow/WorkFlow";
import CreateWorkFlow from "../components/workflow/CreateWorkFlow";
import { getNotifications } from "../store/slices/notification";
import RiskRatingMaster from "../components/master/riskRatingMaster/RiskRatingMaster";
import { AQMMapping } from "../components/vendorOnBoarding/aqmMapping/AQMMapping";
import WorkFlowExecution from "../components/workFlowExecution/WorkFlowExecution";
import { MetricsMaster } from "../components/metricsMaster/MetricsMaster";
import { PendingActions } from "../components/pendingActions/PendingActions";
import Protected from "../ProtectedRoute";
import { WorkFlowHistory } from "../components/workFlowHistory/WorkFlowHistory";
import { Renewal } from "../components/renewal/Renewal";
import { getApplicationParameterList } from "../store/slices/applicationParameterData";
import { WelcomeGreetings } from "../components/welcomeGreetings/WelcomeGreetings";

import TextEditor from "../common/textEditor/TextEditor";
import ContractTemplate from "../components/contractManagement/ContractTemplate/ContractTemplate";

import { getFavouriteNavMenu } from "../store/slices/FavouriteNavData";
import OnlyOfficeTextEditor from "../common/textEditor/OnlyOfficeTextEditor";

import ContractTemplateList from "../components/contractManagement/ContractTemplate/ContractTemplateList";

import AddClause from "../components/contractManagement/ClauseLibrary/AddClause";
import ContractList from "../components/contractManagement/ContractList/ContractList";
import ClauseList from "../components/contractManagement/ClauseLibrary/ClauseList";
import ClauseTypeMaster from "../components/contractManagement/ClauseTypeMaster/ClauseTypeMaster";
import ContractTypeMaster from "../components/contractManagement/ContractTypeMaster/ContractTypeMaster";
import { VendorWithdrawal } from "../components/vendorWithdrawal/VendorWithdrawal";
import CommonMasterBulkUpload from "../components/master/masterBulkUpload/CommonMasterBulkUpload";
import HolidayMaster from "../components/master/holidayMaster/HolidayMaster";
import CostApproval from "../components/contractManagement/CostApproval/CostApproval";
import { ContractRolesandResponsibility } from "../components/contractManagement/rolesAndResponsibility/ContractRolesandResponsibility";
import NotifySigningAuthority from "../components/contractManagement/NotifySigningAuthority/NotifySigningAuthority";
import AddClauseStepper from "../components/contractManagement/ClauseLibrary/AddClauseStepper";
import BoardApproval from "../components/contractManagement/BoardApproval/BoardApproval";
import RolesAndResponsibilities from "../components/master/R&R Setup/RandRMaster";
import {VendorReview} from "../components/contractManagement/vendorReview/VendorReview"
import { ContractCostApproval } from "../components/contractManagement/contractCostApprovalmetrics/ContractCostApproval";
import CustomerOnBoardingAssessment from "../components/customerOnBoarding/COBAssessments/CustomerOnBoardingAssessment";
import { AQMMappingCOB } from "../components/customerOnBoarding/AQMappingforCOB/AQMappingCOB";
interface BackdropState {
  width: number;
  isExpanded: boolean;
}

type Action = { type: "SET_EXPANDED"; payload: boolean } | { type: "SET_WIDTH"; payload: number } | { type: "RESET" };

const initialState: BackdropState = {
  width: 0,
  isExpanded: false,
};

function backdropReducer(state: BackdropState, action: Action): BackdropState {
  switch (action.type) {
    case "SET_EXPANDED":
      return { ...state, isExpanded: action.payload };
    case "SET_WIDTH":
      return { ...state, width: action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}
export const AppRoutes: FC = () => {
  const navigate = useNavigate();
  const { show, showHeader } = useSelector((state: RootState) => state.menu);
  const { menuData, userLoginResponse, isAuthenticated, isVendorAuthenticated } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [backdropState, dispatchBackdrop] = useReducer(backdropReducer, initialState);
  const [backDropIsOpen, setIsBackDropIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatchThunk();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );

    dispatch(
      getFavouriteNavMenu({
        UserId: userLoginResponse && userLoginResponse.UserId,
      })
    );
  }, []);
  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem("preUrl", location.pathname);
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const findMenuByURL = useCallback((menus: any, targetUrl: string) => {
    for (let menu of menus) {
      if (menu.Url && menu.Url?.trim() === targetUrl) {
        return menu;
      }
      if (menu.SubMenus && menu.SubMenus.length > 0) {
        const foundMenu: any = findMenuByURL(menu.SubMenus, targetUrl);
        if (foundMenu) {
          return foundMenu;
        }
      }
    }
    return null;
  }, []);

  useEffect(() => {
    if (userLoginResponse) {
      dispatch(getNotifications(userLoginResponse?.UserId));
    }
    let currentPath = location.pathname;

    if (currentPath && menuData && menuData.length > 0) {
      const matchingMenu = findMenuByURL(menuData, currentPath);
      dispatch(setCurrentPagePermission(matchingMenu));
    }
  }, [location.pathname, menuData, dispatch, findMenuByURL, userLoginResponse]);

  const handleClose = useCallback(() => {
    setIsBackDropIsOpen(false);
    dispatchBackdrop({ type: "SET_EXPANDED", payload: false });
    dispatchBackdrop({ type: "SET_WIDTH", payload: show ? 50 : 0 });
  }, [show]);

  const handleOpen = useCallback(
    (data: any) => {
      const isExpanded = !!data;
      const width = show ? (isExpanded ? 310 : 50) : 0;
      dispatchBackdrop({ type: "SET_EXPANDED", payload: isExpanded });
      dispatchBackdrop({ type: "SET_WIDTH", payload: width });
    },
    [show]
  );
  useEffect(() => {
    dispatchBackdrop({ type: "SET_WIDTH", payload: show ? (backdropState.isExpanded ? 310 : 50) : 0 });
  }, [show, backdropState.isExpanded]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
      {showHeader && (
        <Box
          sx={{
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            borderBottom: "1px solid rgb(182 181 181 / 87%)",
          }}
        >
          <Header />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {show && (
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: "fit-content",
              boxShadow: "inset -12px 0px 8px 10px rgba(103, 103, 103, 0.30)",
            }}
          >
            <Sidenavbar handleOpen={handleOpen} setIsBackDropIsOpen={setIsBackDropIsOpen} />
          </Box>
        )}
        {/* <Backdrop
          sx={{
            left: backdropState.width,
            background: "transparent !important",
            top: 60,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={backDropIsOpen}
          onClick={handleClose}
        /> */}

        <Box
          sx={{
            flex: 1,
            overflowY: "hidden",
            overflowX: "hidden",
            // backgroundColor: "#f5f5f5",
            backgroundColor: "background.paper",
            padding: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ overflowY: "scroll", overflowX: "hidden", px: 2, my: 1, flex: 1 }}>
            <Routes>
              {/* <Route path="/*" element={<Master />} /> */}
              <Route
                path="/dashboard"
                element={
                  <Protected isAllowed="/dashboard">
                    <Dashboard />
                  </Protected>
                }
              />
              <Route path="/errorLog" element={<ErrorLog />} />
              <Route path="/eventLogs" element={<EventLogs />} />
              <Route path="/add_master" element={<AddMasters />} />
              <Route path="/emailTemplate" element={<EmailTemplate />} />
              <Route path="/toasterTemplate" element={<Toaster />} />
              <Route path="/vendor_management" element={<VendorMain />} />
              <Route path="/users" element={<UserPage />} />
              <Route path="/roles" element={<RolesManagement />} />
              {/* <Route path="/department" element={<Department/>}/> */}
              <Route path="/changepassword" element={<ChangePassword />} />
              {/* <Route path="/bulkupload" element={<BulkUpload />} /> */}
              <Route path="/bulkupload" element={<CommonMasterBulkUpload />} />
              <Route path="/mastermain" element={<MasterMain />} />
              <Route path="/listofvalues" element={<ListofValues />} />
              <Route path="/industry" element={<Industry />} />
              <Route path="/location" element={<Location />} />
              <Route path="/documentmaster" element={<DocumentMaster />} />
              <Route path="/departmentmaster" element={<Department />} />
              <Route path="/assessmentCategoryMaster" element={<AssessmentCategory />} />
              <Route path="/subdepartmentmaster" element={<SubDepartmentMaster />} />
              <Route path="/currencymaster" element={<CurrencyMaster />} />
              <Route path="/serviceCatalog" element={<ServiceCatalogMaster />} />
              {/* <Route path="/vendorMaster" element= {<VendorDetailsMaster/>}/> */}
              <Route path="/holidaymaster" element={<HolidayMaster />} />

              <Route path="/makerChecker" element={<MakerChecker />} />
              <Route path="/smMakerChecker" element={<SamMakerChecker />} />
              <Route
                path="/pendingApproval"
                element={
                  <Protected isAllowed="/pendingApproval">
                    <PendingApproval />
                  </Protected>
                }
              />
              <Route path="/assessmentQuestionnaireMaster" element={<AssessmentQuestionnaireMaster />} />
              <Route path="/vendorConsentForm" element={<VendorConsentForm />} />
              <Route path="/samPendingApproval" element={<SamPendingApproval />} />
              <Route path="/irRiskMaster" element={<IrRiskMaster />} />
              <Route path="/security_parameter" element={<SecurityParameters />} />
              {/* <Route path="/companymaster" element= {<CompanyMaster/>}/> */}
              <Route path="/companymaster" element={<CompanyMasterMain />} />
              <Route path="/branchmaster" element={<BranchMaster />} />
              <Route path="/applicationparameter" element={<ApplicationParrameters />} />
              <Route path="/associatedpartymaster" element={<ThirdPartyMaster />} />
              <Route path="/regionalsettings" element={<RegionalSettings />} />
              <Route path="/workFlows" element={<WorkFlow />} />
              <Route path="/addworkFlow" element={<CreateWorkFlow />} />
              <Route path="/thirdpartymaster" element={<VendorDetailsMaster />} />
              <Route path="/riskcategorymaster" element={<RiskRatingMaster />} />
              <Route
                path="/pendingActions"
                element={
                  <Protected isAllowed="/pendingActions">
                    <PendingActions />
                  </Protected>
                }
              />
              <Route
                path="/pendingActions/:process_Id"
                element={
                  <Protected isAllowed="/pendingActions">
                    <PendingActions />
                  </Protected>
                }
              />
              <Route path="/workflowhistory" element={<WorkFlowHistory />} />

              <Route path="/metricsMaster" element={<MetricsMaster />} />
              {/* <Route path="/workFlowExecution" element={<WorkFlowExecution />} /> */}
              <Route path="/assessmentHistory" element={<Renewal />} />
              <Route path="/workFlowExecution" element={<WelcomeGreetings />} />
              {/* <Route path="/vendorassessment" element={<TextEditor />} /> */}
              <Route path="/textEditor" element={<OnlyOfficeTextEditor />} />
              {/* <Route path="/vendorassessment" element={<TextEditor />} /> */}
              <Route path="/initiateContract" element={<ContractList />} />
              <Route path="/contract-templates/" element={<ContractTemplateList />} />
              <Route path="/clauseTypeLibrary" element={<ClauseList />} />
              <Route path="/withdrawconsent" element={<VendorWithdrawal />} />
              <Route path="/costApproval" element={<CostApproval />} />
              <Route path="/clauseTypeMaster" element={<ClauseTypeMaster />} />
              <Route path="/contractTypeMaster" element={<ContractTypeMaster />} />
              <Route path="/contract/workflows/Roles-and-Responsibility" element={<ContractRolesandResponsibility />} />
              <Route path="/notify-signing" element={<NotifySigningAuthority />} />
              <Route path="/addClause" element={<AddClauseStepper />} />
              <Route path="/addTemplate" element={<ContractTemplate />} />
              <Route path="/boardApproval" element={<BoardApproval />} />
              <Route path="/rolesandresponsibilities" element={<RolesAndResponsibilities />} />
              <Route path="/contract/vendorReview" element={<VendorReview isEditData={null} />} />
              <Route path="/contract/contractCostApproval" element={<ContractCostApproval />}/>

              {/* <Route path="/initatieContract" element={<ContractTypeMaster />} /> */}
              {/* <Route path="/vendorassessment" element={<CustomerOnBoardingAssessment />} /> */}
              <Route path="/vendorassessment" element={<AQMMappingCOB />} />
              
            </Routes>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
