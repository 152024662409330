export const PENDING_APPROVAL_URL={
   GetPendingApproval: `/GetAllMakerCheckerApprovals`,
   GetMakerCheckerApprovalById: `/GetMakerCheckerApprovalById`,
   GetMakerCheckerStatus:`/getListOfValues`,
   ApproveMakerChecker:`/ApproveMakerChecker`,
   ApproveMakerCheckercompanymaster:`/UpdateApprovalStatusForCompanyMaster`,
   ApproveMakerCheckerVendorMaster:`/UpdateApprovalStatusForVendorMaster`,
   ApproveMakerCheckerAQMaster:`/UpdateApprovalStatusForAqmMaster`,
   UpdateApprovalStatusForUserMaster:`/UpdateApprovalStatusForUserMaster`,
   UpdateApprovalStatusForRoleMaster:`/UpdateApprovalStatusForRoleMaster`,
   UpdateApprovalStatusForServiceCatalogMaster:`/UpdateApprovalStatusForServiceCatalogMaster`
}