import { Box, Typography, useTheme, MenuItem, Tooltip, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../common/dynamicButton/CustomButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { keyframes } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { getNotifications, updateNotificationStatus, updateUnread } from "../../store/slices/notification";
import { useSelector } from "react-redux";
import CustomSvg from "../../common/CustomSvg";

const Notifications = ({ notificationCount, setNotificationCount, onClose }: any) => {
  const { menuData, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { notifications } = useSelector((state: RootState) => state.notification);
  const theme = useTheme();
  const dispatch = useAppDispatchThunk();
  const navigate = useNavigate();
  const [notificationsres, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    if (userLoginResponse) {
      dispatch(getNotifications(userLoginResponse?.UserId));
    }
  }, []);

  useEffect(() => {
    // Count the number of unread notifications
    const unreadCount = notifications.filter((notification: any) => !notification.IsRead).length;
    const unReadNotifications = notifications.filter((notification: any) => !notification.IsRead);
    setNotificationCount(unreadCount);
    setNotifications(unReadNotifications);
  }, [notifications, setNotificationCount, setNotifications]);

  const getNotificationUrl = (moduleId: any) => {
    if (menuData)
      for (const menu of menuData) {
        if (menu.MenuName) {
          if (menu.SubMenus) {
            for (const subMenu of menu.SubMenus) {
              if (subMenu.MenuId === moduleId) {
                console.log(subMenu, moduleId, subMenu.Url, "moduleNamemoduleName");
                return subMenu.Url;
              }
            }
          }
        }
      }
    return null;
  };
  const updatedNotifications = notificationsres.map((notification) => {
    const url = getNotificationUrl(notification.MenuId);
    return {
      ...notification,
      url: url,
    };
  });

  const ping = keyframes`
    75%, 100% {
        transform: scale(2);
        opacity: 0;
    }
  `;

  const handleClearAll = () => {
    dispatch(
      updateUnread({
        NotificationId: updatedNotifications.map((notification) => notification.NotificationId),
        Action: "",
        ResponseUserId: userLoginResponse?.UserId,
        UpdatedBy: userLoginResponse?.UserName,
      })
    );
    const updatedNotification = updatedNotifications.map((notification) => notification.NotificationId);
    setNotifications([]);
    setNotificationCount(0);
  };


  const handleClearTop5 = () => {
    const top5Notifications = updatedNotifications.slice(5);
    setNotifications(top5Notifications);
    dispatch(
      updateUnread({
        NotificationId: top5Notifications.map((notification) => notification.NotificationId),
        Action: "",
        ResponseUserId: userLoginResponse?.UserId,
        UpdatedBy: userLoginResponse?.UserName,
      })
    );

    setNotificationCount((prevCount: any) => prevCount - 5);
  };

  const markAsOpened = ({ NotificationId, url,NotificationType,UserId }: { NotificationId: number; url: string ,NotificationType:number, UserId:number}) => {
    dispatch(
      updateNotificationStatus({
        NotificationId: NotificationId,
        Action: "",
        ResponseUserId: userLoginResponse?.UserId,
        UpdatedBy: userLoginResponse?.UserName,
      })
    );

    const index = updatedNotifications.findIndex((notification) => notification.NotificationId === NotificationId);
    if (index !== -1) {
      const updatedNotificationsCopy = [...updatedNotifications];
      updatedNotificationsCopy[index].IsRead = true;
      const updatedNotificationsFiltered = updatedNotificationsCopy.filter((_, idx) => idx !== index);
      setNotifications(updatedNotificationsFiltered);
    }
    //navigate(url);
    if(NotificationType === 1){
      navigate('/pendingApproval');
    }
    if(NotificationType === 2){
      navigate('/pendingActions');
    }
    //navigate('/pendingApproval');
    setNotificationCount((prevCount: any) => prevCount - 1);
    onClose();
  };

  const handleRefreshNotification = () => {
    if (userLoginResponse) {
      dispatch(getNotifications(userLoginResponse?.UserId));
    }
  }

  return (
    <Box>
      <Box
        sx={{
          minWidth: "350px",
          paddingX: "10px",
          paddingY: "10px",
          backgroundColor: theme.palette.primary.main,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ paddingLeft: "10px" , display :'flex' }}>
          <Typography variant="h6" sx={{ color: "#fff" , paddingRight:1 }}>
            Notifications
          </Typography> 
          <Box sx={{padding:0}} >   <Tooltip title="Refresh">
                  <IconButton onClick={handleRefreshNotification}>
                    {/* <IosShareIcon />  */}

                    <CustomSvg color={`white`} size={18} name={"refresh-cw"} />
                  </IconButton>
                </Tooltip> </Box>
        </Box>
      </Box>
      {notificationsres.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ padding: "1rem" }}>
            <CustomButton
              className=""
              variant="outlined"
              name="Clear Top 5"
              disabled={notificationCount <= 5}
              onClick={handleClearTop5}
            />
          </Box>
          <Box sx={{ padding: "1rem" }}>
            <CustomButton
              variant="outlined"
              name="Clear All"
              disabled={notificationCount === 0}
              onClick={handleClearAll}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ padding: "1rem", maxHeight: "300px", overflow: "auto" }}>
        {updatedNotifications.length > 0 ? (
          updatedNotifications.map((item, index) => (
            <MenuItem
              key={item.NotificationId}
              sx={{
                paddingY: "10px",
                borderBottom: index === updatedNotifications.length - 1 ? "none" : "1px solid #ccc",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                markAsOpened(item);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    position: "relative",
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "50%",
                  }}
                >
                  <NotificationsIcon
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  {!item.IsRead && (
                    <Box sx={{ position: "relative", width: "8px", height: "8px" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          left: "20px",
                          right: "0%",
                          bottom: "0%",
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "50%",
                          width: "100%",
                          height: "100%",
                          animation: `${ping} 1.4s cubic-bezier(0, 0, 0.2, 1) infinite`,
                        }}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "50%",
                          width: "8px",
                          height: "8px",
                          left: "20px",
                          bottom: "0px",
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography fontSize="14px" sx={{ paddingLeft: "10px" }}>
                    {item.Module}
                  </Typography>
                  <Typography fontSize="10px" sx={{ paddingLeft: "10px" }}>
                    {item.Description}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          ))
        ) : (
          <Box sx={{ padding: "1rem", textAlign: "center" }}>
            <Typography variant="subtitle1">No Notifications</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Notifications;
