import React from "react";
import { IconButton, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomSvg from "../CustomSvg";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))<{ width?: number | string }>(({ theme, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: width || "auto",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
    dropShadow: theme.shadows[1],
  },
}));

type TooltipPlacement =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "top-start"
  | "top-end"
  | "bottom-start"
  | "bottom-end"
  | "left-start"
  | "left-end"
  | "right-start"
  | "right-end";

interface IToolTipProps {
  content: React.ReactNode;
  arrow?: boolean;
  placement?: TooltipPlacement;
  width?: number | string;
}

export const CommonTooltip: React.FC<IToolTipProps> = ({ content, arrow, placement, width }) => {
  return (
    <CustomTooltip
      placement={placement}
      arrow={arrow}
      title={<Typography color="inherit">{content}</Typography>}
      width={width}
    >
      <IconButton>
        <CustomSvg size={"15px"} name={"info"} />
      </IconButton>
    </CustomTooltip>
  );
};
