import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  // MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_DensityState,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  type MRT_ColumnDef,
  MRT_TableHeadCellFilterContainer,
  MRT_TablePagination,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_VisibilityState,
  MaterialReactTable,
  flexRender,
  useMaterialReactTable,
  MRT_TableBodyCellValue,
  MRT_ActionMenuItem,
  MRT_RowSelectionState,
} from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  Stack,
  Divider,
  ListItemIcon,
  Menu,
  Select,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomTextField from "../dynamicInputs/CustomTextField";
import CustomChip from "../dynamicChip/CustomChip";
import CustomSvg from "../CustomSvg";
import IosShareIcon from "@mui/icons-material/IosShare";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import dayjs from "dayjs";
import CustomDialog from "../dynamicDialog/CustomDialog";
import logo from "../../../src/assets/clifelogo.png";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../store/store";
import gridStyle from "./dynamicgrid.module.scss";
import CustomButton from "../dynamicButton/CustomButton";
import SaveGridSettings from "../../components/SaveGridSettings/SaveGridSettings";
import {
  addSettings,
  deleteFilter,
  deleteSettings,
  getPageSettings,
  getSavedFilters,
  saveFilterPageSettings,
} from "../../store/slices/pageSettings";
import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { IPageSetting, IPageSettings } from "../../models/pageSettings.type";
import LogoExcle from "../../assets/clifelogo.png";
//import { ISavedFilter } from "../../store/slices/authorization";
//import { saveFilters, deleteFilter } from "../../store/slices/authorization";
import ModalPopUp from "../modalComponent/ModalPopUp";
import { ACTIVITY } from "../../constants/app.constants";
import { addEventLog, addEventLogRequestParam } from "../../store/slices/eventLog";
import { ApplicationParameterName, WorkFlowType } from "../../config/config";
interface columns {
  Field: string;
  FieldType: any;
  DisplayName: string;
  description?: string;
  width?: number;
  Masking?: boolean;
}

interface MessageProps {
  data: {
    columns: columns[];
    table_name: string;
    isAction: boolean;
    groupBy?: string;
  };
}

interface buttonContionValue {
  key: any;
  value: any;
}
interface DataGridTableProps {
  data: MessageProps["data"];
  rows: any[];
  handleAction?: any;
  handleAdd?: any;
  handleIsDraft?: any;
  showRenewal?: boolean;
  enableZoomIcon: boolean;
  showDelete: boolean;
  showhistory: boolean;
  showView: boolean;
  showAdd?: boolean;
  showRun?: boolean;
  showWithdraw?: boolean;
  showNext?: boolean;
  showEdit: boolean;
  showExports: boolean;
  showClone?: boolean;
  userId: number;
  pageName: string;
  loading?: boolean;
  disableselect?: boolean;
  userSetting?: IPageSetting | null | undefined;
  isEnableDraft?: any;
  draftCount?: number;
  isDraftView?: boolean;
  IswithdrawalActivecontract ? : buttonContionValue[];
  isRunDisableByValue?: buttonContionValue[];
isNextDisableByValue?: buttonContionValue;
  onRowDoubleClick?: (row: any) => void;
  isActivekey?: string;
  appendData?: any;
  onReturnFunc?: (row: any) => void;
  isRecordSearch?: boolean;
  setSelectedQuestions?: (row: any) => void;
  showSubDelete?: boolean;
}

function convertNullToEmptyString(arr: any, col: any) {
  let columns: any = col?.columns || [];
  try {
    // Extract the fields from columns
    const columnFields = columns.map((column: any) => column.Field);

    return arr.map((obj: any) => {
      const newObj: any = {};

      // Ensure all specified fields are present and convert null values to empty strings
      columnFields.forEach((field: any) => {
        if (obj[field] === null || obj[field] === undefined) {
          newObj[field] = "";
        } else {
          newObj[field] = obj[field];
        }
      });

      // Add any additional fields from the object that were not specified in columns
      for (const key in obj) {
        if (!newObj.hasOwnProperty(key)) {
          newObj[key] = obj[key] === null ? "" : obj[key];
        }
      }
      //  console.log(newObj , 'Data Grid Converted Actual empty to string');

      return newObj;
    });
  } catch {
    return arr;
  }
}

const ColumnShown = (data: MessageProps["data"], MaskingDataDetails: any) => {
  // console.log(data, MaskingDataDetails, "datadatadata");

  const newColumns = [...(data.columns || [])];

  MaskingDataDetails?.forEach((detail: any) => {
    const matchedColumnIndex = newColumns.findIndex(
      column => column?.DisplayName === detail?.Parameter_Name
    );

    if (matchedColumnIndex !== -1) {
      // console.log(`Matched Column:`, matchedColumnIndex, newColumns[matchedColumnIndex]);
      // console.log(`Detail:`, detail);

      newColumns[matchedColumnIndex] = { 
        ...newColumns[matchedColumnIndex], 
        Masking: true 
      };
    } else {
      // console.log(`No match found for Parameter_Name: ${detail.Parameter_Name}`);
    }
  });

  return {
    ...data,
    columns: newColumns
  };
};

const RowsShown = (props: any, col: any) => {
  // console.log(convertNullToEmptyString(props , col) , 'check nukk');
  return props?.length > 0 ? (
    convertNullToEmptyString(props, col)
  ) : (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography>No Records To Display</Typography>
    </Box>
  );
};

const CountComponent = (pagination: any, count: any, handleClickOpen: any) => {
  // Destructure pagination for easy access
  const { pageIndex, pageSize } = pagination;

  // Calculate start and end index for displayed records
  const startIndex = pageIndex * pageSize + 1;
  const endIndex = (pageIndex + 1) * pageSize;

  // Ensure the indices are within the total row count
  const adjustedStartIndex = startIndex > count ? count : startIndex;
  const adjustedEndIndex = endIndex > count ? count : endIndex;

  return (
    <Typography
      sx={{ margin: "auto", fontSize: "16px" }}
      onClick={handleClickOpen}
      color="#6A6A6A"
      variant="caption"
      gutterBottom
    >
      Showing {adjustedStartIndex == adjustedEndIndex ? `` : `${adjustedStartIndex} to`} {adjustedEndIndex} of {count}{" "}
      records
    </Typography>
  );
};

const MaterialDynamicGrid = memo((props: DataGridTableProps) => {
  const [loading, setLoading] = useState(false);
  const [userSetting, setUserSetting] = useState<IPageSetting | null | undefined>();
  const [MaskingDataDetails, setMaskingDataDetails] = React.useState([]);
  const dispatch = useAppDispatchThunk();
  // console.log(userSetting, "userSetting");
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);

  useEffect(() => {
    if (props.userId && props.pageName) {
      setLoading(true);
      dispatch(getSavedFilters({ UserId: props.userId, PageName: props.pageName, FilterName: "Saved Filters" }));
      dispatch(getPageSettings({ UserId: props.userId, PageName: props.pageName, FilterName: "Grid Setting" }))
        .unwrap()
        .then(async (data: any) => {
          if (data && data.Settings && data.Settings.length > 0) {
            setUserSetting(data.Settings[0]);
            //setLoading(false);
          } else {
            setUserSetting(null);
            setLoading(false);
          }
        });
      //setUserSetting(null);
    }else{
      setUserSetting(null);
      setLoading(false);
    }
  }, [props.userId, props.pageName]);

  // console.log("isRunDisableByValue:", props.isRunDisableByValue);
  //          console.log("IswithdrawalActivecontract:" ,props.IswithdrawalActivecontract);

  useEffect(() => {
    if (applicationParameterData.length > 0) {
      let Masking_data: any = applicationParameterData?.filter(
        (item) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Value === "Yes" && item.IsActive === "Active");
      // console.log(Masking_data, "Masking_data");
      setMaskingDataDetails(Masking_data);
    }
  }, [applicationParameterData]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {props.data ? (
        <MaterialDynamicGridd
          // data={props.data}
          data={ColumnShown(props.data, MaskingDataDetails)}
          rows={RowsShown(props.rows, props.data)}
          handleAction={props.handleAction}
          handleAdd={props.handleAdd}
          handleIsDraft={props.handleIsDraft}
          enableZoomIcon={props.enableZoomIcon}
          showDelete={props.showDelete}
          showhistory={props.showhistory}
          showView={props.showView}
          showRenewal={props.showRenewal}
          showEdit={props.showEdit}
          showAdd={props.showAdd}
          showRun={props.showRun}
          showWithdraw={props.showWithdraw}
          showNext={props.showNext}
          showExports={props.showExports}
          showClone={props.showClone}
          pageName={props.pageName}
          userId={props.userId}
          loading={loading}
          appendData={props.appendData}
          userSetting={userSetting}
          disableselect={props.disableselect}
          isEnableDraft={props.isEnableDraft}
          isDraftView={props.isDraftView}
          draftCount={props.draftCount}
          isRunDisableByValue={props.isRunDisableByValue}
          
          isNextDisableByValue={props.isNextDisableByValue}
          onRowDoubleClick={props.onRowDoubleClick}
          onReturnFunc={props.onReturnFunc}
          isActivekey={props.isActivekey}
          isRecordSearch={props.isRecordSearch}
          setSelectedQuestions={props.setSelectedQuestions}
          IswithdrawalActivecontract = {props.IswithdrawalActivecontract}
          showSubDelete={props.showSubDelete}
        />
      ) : null}

      
    </LocalizationProvider>
    
  );
});


const MaterialDynamicGridd = memo((props: DataGridTableProps) => {
  const { data, isActivekey = "IsActive" } = props;

  const theme = useTheme();

  const [defaultOptions, setdefaultOptions] = useState({
    pageSize: 25,
    pageNumber: 0,
    sortField: "",
    sortDirection: 1,
  });
  // const defaultOptions = {
  //   pageSize: 25,
  //   pageNumber: 0,
  //   sortField: "",
  //   sortDirection: 1,
  // };
  const { isAuthenticated, user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);

  const { savedFilters } = useSelector((state: RootState) => state.pageSettings);

  const dispatch = useAppDispatch();

  const convert_filter_values = (filter: any) => {
    if (filter.length > 0) {
      let use_data =
        filter &&
        filter.map((item: any) => {
          if (data.columns.some((column_item) => column_item.FieldType === "date" && column_item.Field == item.id)) {
            //console.log(data.columns, item);

            if (typeof item.value == "object") {
              return {
                id: item.id,
                value: item.value.map((date: any) => {
                  return dayjs(date);
                }),
              };
            } else {
              // if (item.value && !isNaN(Date.parse(item.value))) {
              return {
                id: item.id,
                value: dayjs(item.value),
              };
              // } else {
              //   return item;
              // }
            }
          } else {
            if (data.columns.some((column_item) => column_item.Field == item.id)) {
              return item;
            }
          }
        });

      return use_data;
    } else {
      return filter;
    }
  };

  const [isLoading, setLoading] = useState(true);
  const [dataFormate, SetDateFormate] = useState("");
  const [open, setOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [filterName, setFilterName] = useState("");
  const [open_export, setOpen_export] = React.useState(false);
  const [saveFilter, setSaveFilter] = React.useState(false);
  const [pageSettings, setPageSettings] = React.useState<string[]>([]);
  const [openModalSaveSettingInfo, setOpenModalSaveSettingInfo] = React.useState(false);
  const [openModalInactive, setOpenModalInactive] = React.useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>({});

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
  const [density, setDensity] = useState<MRT_DensityState>("comfortable");
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(undefined);
  const [showGlobalFilter, setShowGlobalFilter] = useState(false);
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);

  const paginationSessiondata = sessionStorage.getItem("mrt_columnPagination");

  useEffect(() => {
    if (paginationSessiondata) {
      // setPagination(JSON.parse(paginationSessiondata));
    }
  }, [paginationSessiondata]);
  useEffect(() => {
    return () => {
      sessionStorage.setItem("mrt_columnPagination", JSON.stringify(""));
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("mrt_columnPagination", JSON.stringify(pagination));
  }, [pagination]);

  const default_filter_mode_set = (filterFns: any) => {
    let fnsObject: { [key: string]: any } = {};

    data.columns.map((item) => {
      if (item.FieldType === "string" || item.FieldType === "number") {
        fnsObject[item.Field] = filterFns[item.Field] !== undefined ? filterFns[item.Field] : "contains" ;
      }
      if (item.FieldType === "date") {
        fnsObject[item.Field] = filterFns[item.Field] || "greaterThan";
      }
    });

    return fnsObject;
  };

  const ResetFilter = () => {
    setColumnFilters([]);
    setGlobalFilter(undefined);
    setSorting([]);
    let defaultMode = default_filter_mode_set({});
    setColumnFilterFns(defaultMode);
    setRowSelection({});
  };

  useEffect(() => {
    if (props.loading) {
      setLoading(true);
    } else if (!props.loading && props.userSetting === null) {
      setLoading(false);
    }
  }, [props.loading, props.userSetting]);

  useEffect(() => {
    if (applicationParameterData.length > 0) {
      let dateFormat = applicationParameterData?.find(
        (item) => item.Parameter_Name === "Date Time Format" && item.Parameter_Group === "Date and Time"
      )?.Parameter_Value;
      if (dateFormat) SetDateFormate(dateFormat);
    } else SetDateFormate("DD-MM-YYYY hh:mm");
  }, [applicationParameterData]);

  const framRequest = (arrayString: string[]) => {
    let request: any = {
      Settingid: props.userSetting?.Settingid ? props.userSetting?.Settingid : 0,
      Pagename: props.pageName,
      Userid: props.userId,
      Hidecolumn: "",
      Sortby: "",
      Filtercolumn: "",
      Created_by: props.userId,
      Modified_by: props.userId,
      Pagerows: 25,
      Columnposition: "",
      FilterType: "",
      FilterName: "Grid Setting",
    };
    arrayString.forEach((settingName) => {
      switch (settingName) {
        case "Columns Order":
          request["Columnposition"] = columnOrder.join(",");
          break;
        case "Sorting":
          let sortableFilter = sorting;
          sortableFilter = sortableFilter !== null ? sortableFilter : [];
          // let sortingString = "";

          request["Sortby"] = JSON.stringify(sortableFilter);
          break;
        case "Rows per page":
          request["Pagerows"] = pagination.pageSize;
          break;
        case "Hidden Columns":
          let columnVisibilityFilter: any = columnVisibility;
          columnVisibilityFilter = columnVisibilityFilter != null ? columnVisibilityFilter : {};
          let hiddenString = "";

          request["Hidecolumn"] = JSON.stringify(columnVisibilityFilter);
          break;
      }
    });
    dispatch(addSettings(request));
  };

  const saveSettings = (arrayString: string[]) => {
    if (pageSettings.length != arrayString.length) {
      let intersection = pageSettings.filter((x) => !arrayString.includes(x));
      if (intersection.length > 0) {
        intersection.forEach((settingname) => {
          switch (settingname) {
            case "Columns Order":
              let columns = [];
              data.columns.forEach((columnItem) => {
                columns.push(columnItem.Field);
              });

              columns.unshift("mrt-row-select");
              columns.push("mrt-row-actions");
              setColumnOrder(columns);
              break;
            case "Sorting":
              setSorting([]);
              break;
            case "Rows per page":
              setPagination({
                pageIndex: defaultOptions.pageNumber,
                pageSize: defaultOptions.pageSize,
              });

              break;
            case "Hidden Columns":
              setColumnVisibility({});
              break;
            case "Filters":
              setColumnFilters([]);
              setShowColumnFilters(false);
              break;
          }
        });
      }
      if (arrayString.length > 0) {
        framRequest(arrayString);
      }
    } else {
      if (arrayString.length > 0) {
        framRequest(arrayString);
      }
    }
    setOpenModalSaveSettingInfo(true);
  };

  const mapSavedFiltersSettings = (userSetting: IPageSetting) => {
    if (paginationSessiondata) {
      setPagination(JSON.parse(paginationSessiondata));
    }
    Object.entries(userSetting).forEach(([key, value], index, userArray) => {
      if (key === "Hidecolumn" && value !== "" && value !== undefined && value !== null) {
        setColumnVisibility(JSON.parse(value));
      }

      if (key === "Sortby" && value !== "" && value !== undefined && value !== null) {
        setSorting(JSON.parse(value));
      }

      if (key === "Columnposition" && value !== "" && value !== undefined && value !== null) {
        let columnOrd = value.split(",");

        let columnOrdcheckMatch: any[] = columnOrd.filter(
          (item: any) => item !== "mrt-row-select" && item !== "mrt-row-actions"
        );

        if (data.columns.length === columnOrdcheckMatch.length) {
          setColumnOrder(columnOrd);
        }
      }

      if (key === "Pagerows" && value !== "" && value !== undefined && value !== null) {
        setPagination({
          pageIndex: defaultOptions.pageNumber,
          pageSize: value,
        });
      }
      if (index === userArray.length - 1) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (data.columns) {
      let defaultMode = default_filter_mode_set({});
      setColumnFilterFns(defaultMode);
    }
  }, [data.columns]);

  useEffect(() => {
    if (props.userSetting && data.columns) {
      mapSavedFiltersSettings(props.userSetting);
    }
  }, [props.userSetting, data.columns]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterSave = () => {
    // console.log(columnFilters);

    const saveFilterPayload: IPageSetting = {
      Settingid: 0,
      Pagename: props.pageName,
      Userid: userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0,
      Hidecolumn: "string",
      Sortby: "string",
      Filtercolumn: JSON.stringify(columnFilters),
      Created_by: 0,
      Modified_by: 0,
      Pagerows: 0,
      Columnposition: "string",
      FilterType: JSON.stringify(columnFilterFns),
      FilterName: filterName,
    };
    dispatch(saveFilterPageSettings(saveFilterPayload));
    setOpen(false);
    setFilterName("");
  };

  const handleSavedFilterClick = (fName: string) => {
    const savedFilterData = savedFilters.find((filter) => {
      return filter.FilterName === fName;
    }) || {
      Settingid: 0,
      Pagename: props.pageName,
      Userid: 0,
      Hidecolumn: "string",
      Sortby: "string",
      Filtercolumn: "",
      Created_by: 0,
      Modified_by: 0,
      Pagerows: 0,
      Columnposition: "string",
      FilterType: "string",
      FilterName: "",
    };
    let defaultMode = savedFilterData.FilterType || default_filter_mode_set({});
    setColumnFilters(convert_filter_values(JSON.parse(savedFilterData.Filtercolumn)));

    setColumnFilterFns(sameValue(defaultMode));
    setShowColumnFilters(true);
  };

  const sameValue = (val: any) => {
    try {
      return JSON.parse(val);
    } catch {
      return val;
    }
  };

  const handleSavedFieldDelete = (fName: string) => {
    const index = savedFilters.findIndex((filter) => {
      return filter.FilterName === fName;
    });
    if (index !== -1) {
      dispatch(deleteFilter(index));
      dispatch(deleteSettings({ SettingId: savedFilters[index].Settingid }));
      setColumnFilters([]);
    }
  };

  const handleClickOpen_export = () => {
    const addEventLogPayload: addEventLogRequestParam = {
      ModuleName: props.pageName,
      Action: ACTIVITY.EXPORT,
      Description: "Export",
      UserId: userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0,
      CreatedBy: user?.UserName || "",
    };
    dispatch(addEventLog(addEventLogPayload));
    setOpen_export(true);
  };

  const handleClose_export = () => {
    setOpen_export(false);
  };

  const additionalFilters = [
    { key: "contains", label: "Contains" },
    { key: "startsWith", label: "Starts With" },
    { key: "endsWith", label: "Ends With" },
    { key: "between", label: "Between" },
    { key: "betweenInclusive", label: "Between Inclusive" },
    { key: "empty", label: "Empty" },
    { key: "equals", label: "Equals" },
    { key: "fuzzy", label: "Fuzzy" },
    { key: "greaterThan", label: "Greater Than" },
    { key: "greaterThanOrEqualTo", label: "Greater Than or Equal To" },
    { key: "lessThan", label: "Less Than" },
    { key: "lessThanOrEqualTo", label: "Less Than or Equal To" },
    { key: "notEmpty", label: "Not Empty" },
    { key: "notEquals", label: "Not Equals" },
  ];
  const additionalFilters_date = [
    { key: "between", label: "Between" },
    // { key: "betweenInclusive", label: "Between Inclusive" },
    { key: "greaterThan", label: "Greater Than" },
    { key: "greaterThanOrEqualTo", label: "Greater Than or Equal To" },
    { key: "lessThan", label: "Less Than" },
    { key: "lessThanOrEqualTo", label: "Less Than or Equal To" },
  ];

  const checkGlobalFilterEnable = (field: any) => {
    const columnVisibilityusesearch = columnVisibility;

    try {
      if (columnVisibilityusesearch[field] == false) {
        return false;
      } else {
        return true;
      }
    } catch {
      return true;
    }
  };

  const columns = useMemo<MRT_ColumnDef<any, any>[]>(() => {
    return data.columns.map((column) => {
      if (column.FieldType === "date") {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          size: column.width || 200,
          enableGlobalFilter: checkGlobalFilterEnable(column.Field),
          dateSetting: {
            format: dataFormate,
          },
          // filterFn : 'contains',
          filterVariant: "date",
          // filterVariant : 'date-range' ,
          accessorFn: (originalRow) => new Date(originalRow[`${column.Field}`]),
          Cell: ({ cell }) => {
            const dateValue = cell.getValue<Date>();
            if (isNaN(dateValue.getTime())) {
              return "";
            } else {
              return moment(dateValue).format(dataFormate);
            }
          },
          renderColumnFilterModeMenuItems: ({ onSelectFilterMode }) => [
            <Box>
              {additionalFilters_date.map((filter, index) => (
                <MenuItem key={filter.key} onClick={() => onSelectFilterMode(filter.key)}>
                  <div>{filter.label}</div>
                </MenuItem>
              ))}
            </Box>,
          ],
        };
      } else if (column.FieldType === "number") {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          size: column.width || 200,
          enableGlobalFilter: checkGlobalFilterEnable(column.Field),
          Cell: ({ cell }) => {
            const NumberValue = cell.getValue<any>();
            
            if (column?.Masking && NumberValue != "") {
              return "*******";
            } else {
              return NumberValue;
            }
          },
          renderColumnFilterModeMenuItems: ({ onSelectFilterMode }) => [
            <Box>
              {additionalFilters.map((filter, index) => (
                <MenuItem key={filter.key} onClick={() => onSelectFilterMode(filter.key)}>
                  <div>{filter.label}</div>
                </MenuItem>
              ))}

              <MenuItem key="customFilterFn" onClick={() => onSelectFilterMode("customFilterFn")}>
                <div>Equals with index</div>
              </MenuItem>
            </Box>,
          ],
        };
      } else if (column.FieldType === "string") {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          size: column.width || 200,
          enableColumnOrdering: true,
          enableGlobalFilter: checkGlobalFilterEnable(column.Field),
          Cell: ({ cell }) => {
            const StringValue = cell.getValue<any>();
            
            if (column?.Masking && StringValue != "") {
              return "*******";
            } else {
              return StringValue;
            }
          },
          renderColumnFilterModeMenuItems: ({ onSelectFilterMode }) => [
            <Box>
              {additionalFilters.map((filter, index) => (
                <MenuItem key={filter.key} onClick={() => onSelectFilterMode(filter.key)}>
                  <div>{filter.label}</div>
                </MenuItem>
              ))}

              <MenuItem key="customFilterFn" onClick={() => onSelectFilterMode("customFilterFn")}>
                <div>Equals with index</div>
              </MenuItem>
              <MenuItem key="customFilterFnBetween" onClick={() => onSelectFilterMode("customFilterFnBetween")}>
                <div>String Between</div>
              </MenuItem>
            </Box>,
          ],
        };
      } else {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          enableGlobalFilter: checkGlobalFilterEnable(column.Field),
          size: column.width || 200,
        };
      }
    });
  }, [data.columns, columnVisibility, columnFilters, dataFormate]);

  const handleEditClick = (rowData: any) => {
    // Handle edit button click here
    //console.log("Edit clicked for edit row:", rowData);
    props.handleAction(rowData, "edit");
    ResetFilter();
  };
  const handleRunClick = (rowData: any) => {
    props.handleAction(rowData, "run");
    ResetFilter();
  };
  const handleRenewalClick = (rowData: any) => {
    props.handleAction(rowData, "renew");
    ResetFilter();
  };
  const handleNextClick = (rowData: any) => {
    props.handleAction(rowData, "next");
    ResetFilter();
  };

  const ToggleRowSelectedHandler = (rowData: any) => {
     if(props.showView){
      // handleviewClick(rowData);
     }
  }

  const handleviewClick = (rowData: any) => {
    // Handle view button click here
    //console.log("Edit clicked for preview row:", rowData);
    ResetFilter();
    const addEventLogPayload: addEventLogRequestParam = {
      ModuleName: props.pageName,
      Action: ACTIVITY.VIEW,
      Description: "View",
      UserId: userLoginResponse && userLoginResponse.UserId ? userLoginResponse.UserId : 0,
      CreatedBy: user?.UserName || "",
    };
    dispatch(addEventLog(addEventLogPayload));
    props.handleAction(rowData, "view");
  };

  const handlehistoryClick = (rowData: any) => {
    // Handle version History button click here
    //console.log("Edit clicked for history row:", rowData);
    ResetFilter();
    props.handleAction(rowData, "history");
  };
  const handledeletClick = (rowData: any) => {
    // Handle version History button click here
    ResetFilter();
    // console.log("Edit clicked for delete row:", rowData);
    props.handleAction(rowData, "delete");
    // if (rowData.IsActive) {
    //   if (rowData.IsActive === "Inactive") {
    //     setOpenModalInactive(true);
    //   } else {
    //     props.handleAction(rowData, "delete");
    //   }
    // } else {
    //   props.handleAction(rowData, "delete");
    // }
  };

  const handleSubdeleteClick = (rowData: any) => {
    // Handle version History button click here
    ResetFilter();
    // console.log("Edit clicked for delete row:", rowData);
    props.handleAction(rowData, "subdelete");
    // if (rowData.IsActive) {
    //   if (rowData.IsActive === "Inactive") {
    //     setOpenModalInactive(true);
    //   } else {
    //     props.handleAction(rowData, "delete");
    //   }
    // } else {
    //   props.handleAction(rowData, "delete");
    // }
  };

  const handleCloneClick = (rowData: any) => {
    ResetFilter();
    props.handleAction(rowData, "clone");
  };

  const findColumnNameforAppliedFilter = (id: any) => {
    try {
      return data.columns?.find((item) => item.Field === id)?.DisplayName || id;
    } catch {
      return id;
    }
  };

  const filter_values_show = (filter: any, condition: any) => {
    if (filter && condition) {
      let out = "";
      filter.map((item: any) => {
        let isDate = data.columns.find((col_check) => col_check.Field == item.id && col_check.FieldType == "date");
        try {
          if (Array.isArray(item.value)) {
            let empty_check = 0;
            let arr_values: any[] = [];
            item.value.map((ar_item: any, index: any) => {
              if (!ar_item) {
                empty_check += 1;
              }
              const formattedValue = isDate ? dayjs(ar_item).format(dataFormate).toString() : ar_item;
              arr_values.push(formattedValue || (index === 0 ? "- " : " "));
            });
            out +=
              empty_check < 2
                ? `[${findColumnNameforAppliedFilter(item.id)}] - ${condition[item.id]} - ${arr_values.join(", ")} , `
                : "";
          } else {
            const formattedValue = isDate ? dayjs(item.value).format(dataFormate).toString() : item.value;
            out += `[${findColumnNameforAppliedFilter(item.id)}] - ${condition[item.id]} - ${formattedValue} , `;
          }
        } catch {
          // Handle the case when the value cannot be formatted as a date
          out += `[${findColumnNameforAppliedFilter(item.id)}] - ${condition[item.id]} - ${item.value} , `;
        }
      });
      return out;
    } else {
      return "";
    }
  };

  const change_filter = (e: any) => {
    //console.log(e, "value");
    setColumnFilters(e);
  };

  useEffect(() => {
    if (!props.setSelectedQuestions) {
      setRowSelection({});
    }
  }, [props.rows]);

          //  console.log("isRunDisableByValue:", props.isRunDisableByValue);
          //  console.log("IswithdrawalActivecontract:" ,props.IswithdrawalActivecontract);

  const table = useMaterialReactTable({
    columns,
    data: props.rows,
    enableRowSelection: props.disableselect ? false : true,
    muiTableBodyCellProps: ({ cell }) => ({
      onDoubleClick: () => {
        if (props.onRowDoubleClick) {
          props.onRowDoubleClick(cell.row.original);
        }
      },
      sx: {
        cursor: props.onRowDoubleClick ? "pointer" : "default",
      },
    }),

    initialState: {
      columnPinning: { left: ["mrt-row-select", `${data.columns[0]?.Field}`], right: ["mrt-row-actions"] },
      showColumnFilters: false,
      showGlobalFilter: true,
      grouping: [`${data.groupBy}`],
      isLoading: isLoading,
      // expanded: true
      // pagination: { pageSize: 25, pageIndex: 0 },
    },
    onRowSelectionChange: setRowSelection,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: change_filter,
    onColumnVisibilityChange: setColumnVisibility,

    onGlobalFilterChange: setGlobalFilter,
    onShowColumnFiltersChange: setShowColumnFilters,
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    onPaginationChange: setPagination,
    //rowNumberDisplayMode
    state: {
      rowSelection,
      columnOrder,
      columnFilterFns,
      columnFilters,
      columnVisibility,
      globalFilter,
      showColumnFilters,
      sorting,
      pagination,
      isLoading,
    },
    filterFns: {
      customFilterFn: (row, id, filterValue) => {
        try {
          const selectedField = row.getValue(id) as string;

          const [indexStr, value] = filterValue.split("=");
          const index = parseInt(indexStr, 10);

          return selectedField[index] == value;
        } catch {
          return false;
        }
      },
      customFilterFnBetween: (row, id, filterValue) => {
        try {
          const selectedField = row.getValue(id) as string;

          const [startValue, endValue] = filterValue.split("?");

          return selectedField.startsWith(startValue.trim()) && selectedField.endsWith(endValue.trim());
        } catch {
          return false;
        }
      },
    },
    localization: {
      filterCustomFilterFn: "Equals with index",
      filterCustomFilterFnBetween: "String Between",
    } as any,
    enableColumnOrdering: true,
    enableColumnDragging: false,
    muiPaginationProps: {
      color: "primary",
      rowsPerPageOptions: [25, 50, 100, 250],
      showRowsPerPage: false,
      // variant: "outlined",
      // shape: "rounded",
    },
   
    enableColumnActions: false,
    enableColumnFilterModes: true,
    paginationDisplayMode: "pages",
    enableStickyHeader: true,
    enableGrouping: data.groupBy ? true : false,
    enableMultiSort: true,
    enableDensityToggle: false,
    isMultiSortEvent: (e: unknown) => true,
    positionToolbarAlertBanner: "bottom",

    muiTablePaperProps: ({ table }) => ({
      elevation: 0,
      style: {
        zIndex: table.getState().isFullScreen ? 1000 : undefined,
        borderRadius: "0",
      },
    }),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: data.isAction ? "Action" : undefined, //change header text
        size: 0,
        //size: data.isAction ? 100 : 0,
        //make actions column wider
      },
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        maxHeight: table.getState().isFullScreen ? "calc(100vh - 150px) !important" : "calc(100vh - 248px) !important",
        boxShadow: "none",
      },
    }),
    muiTableProps: {
      sx: {
        caption: {
          captionSide: "top",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid rgb(235,235,235)",
        height: "50px",
      },
    },
  
    muiTableBodyRowProps: ({ row }) => ({

      onDoubleClick: () => ToggleRowSelectedHandler(row.original),
      sx: {
        height: "50px",
        // marginBottom :'3px',
        boxShadow: "0px -3px 0px 0px rgb(0 0 0 / 7%)",
        border: "1px solid rgb(235,235,235)",
        cursor: "pointer",
      },
    }),

    muiSearchTextFieldProps: {
      sx: { textAlign: "center" },
      variant: "outlined",
    },

    icons: {
      MoreHorizIcon: () => <CustomSvg color={theme.palette.text.secondary} size="20px" name="more-vertical" />,
      FilterListIcon: () => <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"filter"} />,
      FilterListOffIcon: () => <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"filter"} />,
      ViewColumnIcon: () => <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"view"} />,
      FullscreenExitIcon: () => (
        <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"Exit-fullScreen"} />
      ),
      FullscreenIcon: () => <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"Expand-fullScreen"} />,
    },
    enableEditing: false,
    enableRowActions: data.isAction ? true : false,
    // enableColumnActions: data.isAction ? true : false,
    positionActionsColumn: data.isAction ? "last" : undefined,
    renderEmptyRowsFallback: ({ table }) => (
      <Box sx={{ height: "40vh", width: "90vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <Box sx={{ p: 3, width: "70%" }}>
            <Typography
              sx={{ margin: "auto", fontSize: "20px", cursor: "pointer", width: "100%" }}
              color="primary"
              variant="caption"
              gutterBottom
            >
              {`Currently there is no ${data.table_name?.toLowerCase()} data available`}
              {props.showAdd ? `. Start by adding one` : "."}
            </Typography>
          </Box>

          {props.showAdd && (
            <Box sx={{}}>
              <Tooltip title={`Add  ${data.table_name}`}>
                <CustomButton
                  startIcon={<CustomSvg name={"plus"} color={"white"} />}
                  name={`Add ${data.table_name}`}
                  onClick={() => {
                    props.handleAdd();
                    ResetFilter();
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      props.showEdit ? (
        <MenuItem
          key="edit"
          onClick={() => {
            handleEditClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {" "}
            <CustomSvg color={`${theme.palette.text.secondary}`} size={"18px"} name={"edit"} /> <>Edit</>{" "}
          </Box>
        </MenuItem>
      ) : null,
      props.showView ? (
        <MenuItem
          key="view"
          onClick={() => {
            handleviewClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomSvg color={`${theme.palette.text.secondary}`} size={18} name={"view"} /> <>View</>{" "}
          </Box>
        </MenuItem>
      ) : null,
      props.showRun &&
        row.original.WorkflowCategoryName !== WorkFlowType?.customerOnboarding &&
        (props?.isRunDisableByValue
          ? props.isRunDisableByValue.every((item: any) => row.original[item.key] === item.value)
          : true) ? (
        <MenuItem
          key="run"
          onClick={() => {
            handleRunClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {" "}
            <CustomSvg color={`${theme.palette.text.secondary}`} size={"18px"} name={"play"} /> <>Run</>{" "}
          </Box>
        </MenuItem>
      ) : null,


      props.showWithdraw &&
      
    <MenuItem
        key="withdraw"
        disabled={(props?.IswithdrawalActivecontract
          ? props.IswithdrawalActivecontract.every((item: any) => row.original[item.key] === item.value)
          : true)}
        onClick={() => {
          handleRunClick(row.original);
          closeMenu();
        }}
      >
       <Box sx={{ display: "flex", gap: 1 }}        
        >
          {" "}
          <CustomSvg color={`${theme.palette.text.secondary}`} size={"18px"} name={"play"} /> <>Withdraw</>{" "}
        </Box>
      </MenuItem>,
     
      props.showRenewal &&
        (props?.isRunDisableByValue
          ? props.isRunDisableByValue.every((item: any) => row.original[item.key] === item.value)
          : true) ? (
        <MenuItem
          key="renew"
          disabled={!row.original.isAction}
          onClick={() => {
            handleRenewalClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {" "}
            <CustomSvg color={`${theme.palette.text.secondary}`} size={"18px"} name={"play"} /> <>Renew</>{" "}
          </Box>
        </MenuItem>
      ) : null,
      props.showClone ? (
        <MenuItem
          key="clone"
          onClick={() => {
            handleCloneClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
            <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"copy"} /> <>Clone</>{" "}
          </Box>
        </MenuItem>
      ) : null,
      props.showNext &&
        (props?.isNextDisableByValue
          ? row.original[props.isNextDisableByValue.key] === props.isNextDisableByValue.value
          : true) ? (
        <MenuItem
          key="run"
          onClick={() => {
            handleNextClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {" "}
            <CustomSvg color={`${theme.palette.text.secondary}`} size={"18px"} name={"play"} /> <>Complete</>{" "}
          </Box>
        </MenuItem>
      ) : null,
      props.showhistory ? (
        <MenuItem
          key="history"
          onClick={() => {
            handlehistoryClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
            {" "}
            <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"history"} /> <>Version History</>{" "}
          </Box>
        </MenuItem>
      ) : null,
      props.showDelete ? (
        <MenuItem
          key="delete"
          onClick={() => {
            handledeletClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            {row.original && row.original[isActivekey] === "Inactive" ? (
              <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"minus-circle"} />
            ) : (
              <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"power"} />
            )}
            <>{row.original && row.original[isActivekey] === "Inactive" ? "Active" : "Inactive"}</>{" "}
          </Box>
        </MenuItem>
      ) : null,
      props.showSubDelete ? (
        <MenuItem
          key="showSubDelete"
          onClick={() => {
            handleSubdeleteClick(row.original);
            closeMenu();
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>           
              <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"delete"} />  <>Delete</>{" "}
          </Box>
        </MenuItem>
      ) : null,
    ],

    renderBottomToolbar: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 1,
          // flexDirection: "column",
          width: "100%",
          borderTop: "1px solid rgb(235,235,235)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography sx={{ margin: "auto", color: "#6A6A6A", fontSize: "16px" }} variant="caption">
            Records per page
          </Typography>
          <FormControl size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pagination?.pageSize}
              label=""
              onChange={(e) => {
                const newSize = parseInt(e.target.value as string, 10);
                if (!isNaN(newSize)) {
                  // Update the table options
                  table.setPageSize(newSize);
                  // Optionally, update any external state or trigger a data fetch
                  // setPagination(prev => ({ ...prev, pageSize: newSize }));
                }
              }}
            >
              {[25, 50, 100, 250].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "100%",
            // borderTop: "1px solid rgb(235,235,235)",
          }}
        >
          <MRT_TablePagination table={table} />
        </Box>
      </Box>
    ),
    renderTopToolbar: ({ table }) => (
      <Box
        sx={{
          p: 1,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <MRT_GlobalFilterTextField placeholder={`Search ${data.table_name}`} table={table} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {props.isDraftView ? (
              <Box
                className={gridStyle.icon_box}
                sx={{ width: props.isEnableDraft === 1 ? "80px !important" : "auto !important", px: 2 }}
                onClick={() => {
                  props.handleIsDraft(props.isEnableDraft === 1 ? 0 : 1);
                  ResetFilter();
                  // console.log(table);
                }}
              >
                {props.isEnableDraft === 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      fontSize: "14px",
                      color: theme.palette.primary.main,
                    }}
                  >
                    <Box>Draft</Box>
                    <Box sx={{ backgroundColor: theme.palette.primary.main }} className={gridStyle.count}>
                      {props?.draftCount || 0}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      fontSize: "14px",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {`Back to Saved Item`}
                  </Box>
                )}
              </Box>
            ) : null}
            <Divider sx={{ ml: 0.5, mr: 0.5 }} orientation="vertical" variant="middle" flexItem />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {props.showAdd && (
                <>
                  <Tooltip title={`Add  ${data.table_name}`}>
                    <Box className={gridStyle.icon_box}>
                      <IconButton
                        onClick={() => {
                          props.handleAdd();
                          ResetFilter();
                        }}
                      >
                        <CustomSvg name={"plus"} color={`${theme.palette.primary.main}`} size={20} />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </>
              )}
              {/* <Tooltip title={`Add  ${data.table_name}`}>
                  <CustomButton
                    variant="text"
                    startIcon={<CustomSvg name={"plus"} />}
                    name={`Add ${data.table_name}`}
                    size="medium"
                    onClick={() => {
                      props.handleAdd();
                    }}
                  />
                </Tooltip> */}

              {
                <Box className={gridStyle.icon_box}>
                  <Tooltip title="Save Grid Setting">
                    <IconButton
                      onClick={() => {
                        let gridSettings = [];
                        let sortableFilter = sorting;
                        sortableFilter = sortableFilter !== null ? sortableFilter : [];
                        if (sortableFilter && sortableFilter.length > 0) {
                          gridSettings.push("Sorting");
                        }
                        //gridSettings.push("Sorting");
                        let columnOrderFilter = columnOrder;
                        columnOrderFilter = columnOrderFilter !== null ? columnOrderFilter : [];
                        if (columnOrderFilter && columnOrderFilter.length > 0) {
                          gridSettings.push("Columns Order");
                        }
                        //gridSettings.push("Columns Order");
                        // if(pagination.pageSize !== 25){
                        //   gridSettings.push("Rows_Perpage");
                        // }
                        gridSettings.push("Rows per page");
                        let columnVisibilityFilter = columnVisibility;
                        columnVisibilityFilter = columnVisibilityFilter != null ? columnVisibilityFilter : {};
                        if (columnVisibilityFilter && Object.keys(columnVisibilityFilter).length > 0) {
                          gridSettings.push("Hidden Columns");
                        }

                        // let filters = sessionStorage.getItem(`mrt_columnFilters_table${data.table_name}`);
                        // filters = filters != null && filters !== "" ? JSON.parse(filters) : [];
                        // if (filters && filters.length > 0) {
                        //   gridSettings.push("Filters");
                        // }
                        setPageSettings(gridSettings);
                        setSaveFilter(true);
                      }}
                    // size="medium"
                    >
                      {" "}
                      <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"mySettings"} />
                    </IconButton>
                  </Tooltip>
                </Box>
              }

              <Divider sx={{ ml: 0.5, mr: 0.5 }} orientation="vertical" variant="middle" flexItem />
              <Box className={gridStyle.icon_box}>
                <MRT_ShowHideColumnsButton table={table} />
              </Box>
              <Box className={gridStyle.icon_box}>
                <MRT_ToggleFiltersButton table={table} />
              </Box>
              {props.showExports ? (
                <Box className={gridStyle.icon_box}>
                  <Tooltip title="Export">
                    <IconButton onClick={handleClickOpen_export}>
                      {/* <IosShareIcon />  */}

                      <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"export"} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : null}
              <Box className={gridStyle.icon_box}>
                <Tooltip title="Reset Filter">
                  <IconButton onClick={ResetFilter}>
                    {/* <IosShareIcon />  */}

                    <CustomSvg color={`${theme.palette.primary.main}`} size={18} name={"refresh-cw"} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider sx={{ ml: 0.5, mr: 0.5 }} orientation="vertical" variant="middle" flexItem />
              {props.enableZoomIcon && (
                <Box className={gridStyle.icon_box}>
                  {" "}
                  <Tooltip title="Export">
                    <>
                      {" "}
                      <MRT_ToggleFullScreenButton table={table} />
                    </>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: 1.2,
            mb: 1,
            // borderTop: "1px solid rgb(235,235,235)",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {CountComponent(pagination, table.getRowCount(), handleClickOpen)}

            <Divider orientation="vertical" flexItem />

            {/* <Button  >
                    Filter
                </Button> */}
            {filter_values_show(columnFilters, columnFilterFns) && columnFilters ? (
              <Box sx={{ display: "flex" }}>
                <Typography
                  onClick={handleClickOpen}
                  sx={{ margin: "auto", fontSize: "16px", cursor: "pointer" }}
                  variant="caption"
                  display="block"
                  color="text.primary"
                  textAlign={"left"}
                  gutterBottom
                >
                  {filter_values_show(columnFilters, columnFilterFns)}
                </Typography>
              </Box>
            ) : (
              <Typography
                sx={{ margin: "auto", fontSize: "16px", cursor: "pointer" }}
                onClick={handleClickOpen}
                color="text.primary"
                variant="caption"
                gutterBottom
              >
                No filter criteria applied
              </Typography>
            )}
          </Box>

          {/* <Box>
            <Typography
              sx={{ margin: "auto", fontSize: "13px", cursor: "pointer" }}
              onClick={ResetFilter}
              color="primary"
              variant="caption"
              gutterBottom
            >
              Reset Filter
            </Typography>
          </Box> */}
        </Box>
      </Box>
    ),
  });

  function reorderColumns(columnConfigs: any[], orderArray: string[]): any[] {
    return orderArray.map((key) => columnConfigs.find((config) => config.accessorKey === key)).filter(Boolean);
  }

  function reorderObjects(dataArray: any[], keyOrder: string[]): any[] {
    const tableData = dataArray.map((row) => {
      const reorderedRow = keyOrder.map((key) => row[key]);
      return reorderedRow;
    });
    return tableData;
  }

  const [tempSelectedRow, setTempSelectedRow] = useState<any[]>([]);

  const prevTempSelectedRowRef = useRef<any[]>(tempSelectedRow);

  useEffect(() => {
    const selectedRowsData = Object.keys(rowSelection)
      .filter((index) => rowSelection[Number(index)])
      .map((index) => props.rows[Number(index)]);

    if (
      JSON.stringify(selectedRowsData) !== JSON.stringify(tempSelectedRow) &&
      JSON.stringify(selectedRowsData) !== JSON.stringify(prevTempSelectedRowRef.current)
    ) {
      setTempSelectedRow(selectedRowsData);
      prevTempSelectedRowRef.current = selectedRowsData;

      if (props.setSelectedQuestions) {
        props.setSelectedQuestions(selectedRowsData);
      }
    }
  }, [rowSelection]);

  const handleExportRows = async (rows: any, con: any) => {
    let table_name = data.table_name || "clife";

    let check_group = rows[0]?.groupingColumnId;
    let column_values = data.columns || [];
    const main_tableData = rows.map((row: any) => row.original);
    const main_tableData_group = rows.map((row: any) => row);

    const reorderedColumnConfigs = reorderColumns(columns, columnOrder);

    if (check_group) {
      const index = reorderedColumnConfigs.findIndex((obj) => obj.accessorKey === check_group);
      if (index !== -1) {
        const removedItem = reorderedColumnConfigs.splice(index, 1)[0];
        reorderedColumnConfigs.unshift(removedItem);
      }
    }

    const tableHeaders_hided_check = reorderedColumnConfigs.filter((c) => columnVisibility[c.accessorKey] !== false);

    const tableHeaders_accessorKey = tableHeaders_hided_check.map((c) => c.accessorKey);

    const tableHeaders = tableHeaders_hided_check.map((c) => c.header);
    //console.log(tableHeaders, "tableHeadersssss");

    let tableData: any[] = [];

    if (check_group) {
      let transformedData: any[] = [];
      main_tableData_group.forEach((rowData: any) => {
        let rowData_recreate = { ...rowData.original };
        column_values.forEach((column_t: any) => {
          const columnName = column_t.Field;
          const columnType = column_t.FieldType;
          if (columnName == check_group) {
            if (columnType === "date") {
              try {
                if (rowData_recreate[columnName]) {
                  rowData_recreate[columnName] = moment(rowData_recreate[columnName]).format(dataFormate);
                } else {
                  rowData_recreate[columnName] = "";
                }
              } catch {
                rowData_recreate[columnName] = rowData_recreate[columnName];
              }
            } else if (columnType === "number") {
              try {
                if (rowData_recreate[columnName]) {
                  rowData_recreate[columnName] = rowData_recreate[columnName].toString();
                } else {
                  rowData_recreate[columnName] = "";
                }
              } catch {
                rowData_recreate[columnName] = rowData_recreate[columnName];
              }
            }
          } else {
            rowData_recreate[columnName] = "";
          }
        });
        transformedData.push(rowData_recreate);

        rowData.subRows.forEach((rowDatasub: any) => {
          let rowData_recreate_sub = { ...rowDatasub.original };

          column_values.forEach((column_t: any) => {
            const columnName = column_t.Field;
            const columnType = column_t.FieldType;
            if (columnName != check_group) {
              if (columnType === "date") {
                try {
                  if (rowData_recreate_sub[columnName]) {
                    rowData_recreate_sub[columnName] = moment(rowData_recreate_sub[columnName]).format(dataFormate);
                  } else {
                    rowData_recreate_sub[columnName] = "";
                  }
                } catch {
                  rowData_recreate_sub[columnName] = rowData_recreate_sub[columnName];
                }
              }
            } else {
              rowData_recreate_sub[columnName] = "";
            }
          });

          transformedData.push(rowData_recreate_sub);
        });
      });

      const reorderedDataArray = reorderObjects(transformedData, tableHeaders_accessorKey);
      tableData = reorderedDataArray;
    } else {
      let transformedData: any[] = [];
      main_tableData.forEach((rowData: any) => {
        let rowData_recreate = { ...rowData };
        column_values.forEach((column_t: any) => {
          const columnName = column_t.Field;
          const columnType = column_t.FieldType;
          if (columnType === "date") {
            try {
              if (rowData_recreate[columnName]) {
                rowData_recreate[columnName] = moment(rowData_recreate[columnName]).format(dataFormate);
              } else {
                rowData_recreate[columnName] = "";
              }
            } catch {
              rowData_recreate[columnName] = rowData_recreate[columnName];
            }
          }
        });
        transformedData.push(rowData_recreate);
      });

      const reorderedDataArray = reorderObjects(transformedData, tableHeaders_accessorKey);
      tableData = reorderedDataArray;
    }

    if (con === "pdf") {
      await export_pdf(tableHeaders, tableData, table_name);
      handleClose_export();
    } else {
      // export_excel(tableHeaders, tableData, table_name);
      await exportExcelNew(tableHeaders, tableData, table_name);
      handleClose_export();
    }
  };

  const export_pdf = (tableHeaders: any, tableData: any, table_name: any) => {
    const doc = new jsPDF({
      orientation: "landscape", // Set the orientation here
      unit: "pt",
      format: "a4",
    });
    const currentDate = new Date();
    // const dateTimeString = currentDate.toISOString().split("T")[0] + "_" + currentDate.toLocaleTimeString();
    const dateTimeString = moment(currentDate).format(dataFormate);

    function hexToRgb(hex: string): number[] | undefined {
      // Remove '#' if present
      hex = hex.replace("#", "");

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      // Check if conversion is successful
      if (isNaN(r) || isNaN(g) || isNaN(b)) {
        return undefined;
      }

      return [r, g, b];
    }

    const hexColor = theme.palette.primary.main;
    const rgbColor = hexToRgb(hexColor);

    const header = function (data: any) {
      const pageWidth = doc.internal.pageSize.width;
      const centerX = pageWidth / 2;

      // Add logo to the header at the center and above
      const logoWidth = 90; // Adjust the width of the logo as needed
      const logoHeight = 30; // Adjust the height of the logo as needed
      const logoX = (pageWidth - logoWidth) / 2;
      const logoY = 40; // Adjust the vertical position of the logo as needed
      doc.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);

      const fontSize = 20;
      doc.setFontSize(fontSize);
      const headerText = `${table_name}`;
      const yPosition = logoY + logoHeight + 20; // Adjust the vertical position as needed
      const padding = 10; // Set padding around the text

      // Add header text with padding and center alignment
      doc.text(headerText, centerX, yPosition + padding, { align: "center" }); // Align center horizontally
    };

    let pageCount = 1;
    const footer = function (data: any) {
      // Add footer text
      doc.setFontSize(12);

      const pageNumber = data.pageNumber;

      const pageNumberString = `${pageNumber}/${pageCount}`;

      let filter_valuepdf = filter_values_show(columnFilters, columnFilterFns) || globalFilter;
      const pageWidth = doc.internal.pageSize.width;

      const margin = 20; // Adjust the margin if needed
      const availableWidth = pageWidth - margin * 2;

      // Prepare the text string
      const printedByText = `Printed By: ${user?.UserName || ""}`;
      const pageNumberText = `Page No: ${pageNumberString}`;
      const printedOnText = `Printed On: ${dateTimeString}`;
      const filtersUsedText = `Filters used: ${filter_valuepdf || "No"}`;

      const fullText = `${printedByText}, ${pageNumberText}, ${printedOnText}, ${filtersUsedText}`;

      // Split the text into multiple lines if it exceeds the available width
      const splitText = doc.splitTextToSize(fullText, availableWidth);

      // Calculate the initial vertical position
      const initialYPosition = doc.internal.pageSize.height - 10 - (splitText.length - 1) * 10;

      // Adjust the Y position for each line
      splitText.forEach((line: any, index: any) => {
        doc.text(line, pageWidth / 2, initialYPosition - (splitText.length - 1) * 10 + index * 10, {
          align: "center",
        });
      });
      // doc.text(
      //   `Printed By : ${
      //     user?.UserName
      //   }, Page No : ${pageNumberString}, Printed On :  ${dateTimeString}, Filters used :   ${
      //     filter_valuepdf ? filter_valuepdf : "No"
      //   }  `,
      //   doc.internal.pageSize.width / 2,
      //   doc.internal.pageSize.height - 20,
      //   { align: "center"  }
      // ); // Adjust the position as needed
    };
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: 130,
      margin: { top: 130 },
      showHead: "everyPage",
      didDrawPage: function (data) {
        // Calculate remaining space on the page

        // Header
        header(data);
        // Footer
        footer(data);
        pageCount++;
      },
      headStyles: rgbColor
        ? {
          fillColor: rgbColor as [number, number, number],
          textColor: [255, 255, 255], // Set the text color of the header
        }
        : {
          textColor: [255, 255, 255], // Set the text color of the header
        },
    });
    header(data);
    doc.save(`${table_name}_${dateTimeString}.pdf`);
    handleClose_export();
  };

  const exportExcelNew = async (tableHeaders: any, tableData: any, table_name: any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(table_name, { views: [{ showGridLines: false }] });

    const currentDate = new Date();
    // const dateTimeString = currentDate.toISOString().split("T")[0] + "_" + currentDate.toLocaleTimeString();
    const dateTimeString = moment(currentDate).format(dataFormate);

    let TableHeadersValue: { name: any; header: any; key: any; width: number }[] = [];
    tableHeaders.forEach((head_item: any) => {
      TableHeadersValue.push({ name: head_item, header: head_item, key: head_item, width: 30 });
    });

    const fetchImage = async (imageUrl: string): Promise<string> => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const reader = new FileReader();

        return new Promise<string>((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Error fetching image:", error);
        throw error;
      }
    };

    const imageBuffer = await fetchImage(LogoExcle);
    const imageId = workbook.addImage({ base64: imageBuffer, extension: "jpeg" });

    worksheet.addImage(imageId, "B2:B5");
    let filter_valuepdf = filter_values_show(columnFilters, columnFilterFns) || globalFilter;
    worksheet.addRow(["", "Module Name :", table_name]);
    worksheet.addRow(["", "Printed By :", user?.UserName]);
    worksheet.addRow(["", "Printed On :", dateTimeString]);
    worksheet.addRow(["", "Filters used :  ", `${filter_valuepdf ? `${filter_valuepdf}` : "No"}  `]);

    worksheet.addRow([]);

    const columnWidths = TableHeadersValue.map((header) => ({ width: 20 }));
    const extraColumnWidth = { width: 20 };
    columnWidths.push(extraColumnWidth);
    worksheet.columns = columnWidths;

    worksheet.addTable({
      name: "MyTable",
      ref: "B13",
      headerRow: true,
      style: {
        theme: "TableStyleLight19",
        showRowStripes: false,
      },
      columns: TableHeadersValue.map((header) => ({
        name: header.header,
        header: header.header,
        key: header.key,
        width: 20,
        style: { alignment: { horizontal: "left" } },
      })),
      rows: tableData,
    });

    const applyCellStyle = (worksheet: ExcelJS.Worksheet, endCell: string) => {
      worksheet.getCell(endCell).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ff321149" },
        bgColor: { argb: "ff321149" },
      };
      worksheet.getCell(endCell).font = {
        color: { argb: "ffffffff" },
      };
    };

    TableHeadersValue.forEach((fill_item, index) => {
      const endCell = String.fromCharCode(65 + index + 1) + 13;
      applyCellStyle(worksheet, endCell);
    });

    if (props.pageName && props.pageName === "Role Management") {
      const permissionWorksheet = workbook.addWorksheet("Permission Mapping");
      const permissionHeaders = [
        "RoleName",
        "MenuName",
        "IsAdd",
        "IsEdit",
        "IsDelete",
        "IsView",
        "IsExport",
        "IsApproval",
      ];
      const permissionHeaderRow = permissionWorksheet.addRow(permissionHeaders);
      permissionHeaderRow.eachCell((cell, colNumber) => {
        applyCellStyle(permissionWorksheet, cell.address);
      });
      permissionWorksheet.getColumn("A").width = 30;
      permissionWorksheet.getColumn("B").width = 50;

      interface TableDataRow {
        RoleName: string;
        MenuName: string;
        IsAdd: string;
        IsEdit: string;
        IsDelete: string;
        IsView: string;
        IsExport: string;
        IsApproval: string;
        [key: string]: string; // Dynamic keys for additional MenuName columns
      }

      const permissionData: TableDataRow[] = [];
      props.rows.forEach((row: any) => {
        row.Menus?.forEach((menu: any) => {
          const submenuNames = menu.SubMenus ? menu.SubMenus.map((submenu: any) => submenu.MenuName) : [];
          if (submenuNames.length > 0) {
            submenuNames.forEach((submenuName: string) => {
              const submenu = menu.SubMenus.find((sub: any) => sub.MenuName === submenuName);
              const mappedRow: TableDataRow = {
                RoleName: row.RoleName || "",
                MenuName: submenuName || "",
                IsAdd: submenu ? (submenu.IsAdd === 1 ? "Yes" : "No") : "",
                IsEdit: submenu ? (submenu.IsEdit === 1 ? "Yes" : "No") : "",
                IsDelete: submenu ? (submenu.IsDelete === 1 ? "Yes" : "No") : "",
                IsView: submenu ? (submenu.IsView === 1 ? "Yes" : "No") : "",
                IsExport: submenu ? (submenu.IsExport === 1 ? "Yes" : "No") : "",
                IsApproval: submenu ? (submenu.IsApproval === 1 ? "Yes" : "No") : "",
              };
              permissionData.push(mappedRow);
            });
          } else {
            const mappedRow: TableDataRow = {
              RoleName: row.RoleName || "",
              MenuName: menu.MenuName || "",
              IsAdd: menu ? (menu.IsAdd === 1 ? "Yes" : "No") : "",
              IsEdit: menu ? (menu.IsEdit === 1 ? "Yes" : "No") : "",
              IsDelete: menu ? (menu.IsDelete === 1 ? "Yes" : "No") : "",
              IsView: menu ? (menu.IsView === 1 ? "Yes" : "No") : "",
              IsExport: menu ? (menu.IsExport === 1 ? "Yes" : "No") : "",
              IsApproval: menu ? (menu.IsApproval === 1 ? "Yes" : "No") : "",
            };
            permissionData.push(mappedRow);
          }
        });
      });

      // Add data rows
      permissionData.forEach((dataItem: TableDataRow) => {
        const rowData = permissionHeaders.map((header) => dataItem[header]);
        const row = permissionWorksheet.addRow(rowData);
        // Apply row border color
        row.eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "bf8bff" } },
            left: { style: "thin", color: { argb: "bf8bff" } },
            bottom: { style: "thin", color: { argb: "bf8bff" } },
            right: { style: "thin", color: { argb: "bf8bff" } },
          };
        });
      });
    }

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${table_name}_${dateTimeString}.xlsx`);
        handleClose_export();
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };



  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialReactTable table={table} />

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          // dividers={scroll === 'paper'}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Filter
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ width: "50%" }} mt={1} mb={1}>
                <CustomTextField
                  name="filterName"
                  type="text"
                  required
                  label="Filter Name"
                  value={filterName}
                  onChange={(val) => {
                    setFilterName(val);
                  }}
                />
              </Box>

              {savedFilters && savedFilters.length > 0 ? (
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} sx={{ mt: "20px", ml: "20px" }}>
                  {savedFilters.map((filterData) => {
                    return (
                      <CustomChip
                        label={filterData.FilterName}
                        onClick={() => {
                          handleSavedFilterClick(filterData.FilterName);
                        }}
                        onDelete={() => {
                          handleSavedFieldDelete(filterData.FilterName);
                        }}
                      />
                    );
                  })}
                </Stack>
              ) : null}
              <Box sx={{ width: "500px" }}>
                {table.getLeafHeaders().map((header) => (
                  <>
                    {header.id !== "mrt-row-select" && header.id !== "mrt-row-actions" ? (
                      <Box sx={{ mt: 1 }}>
                        <MRT_TableHeadCellFilterContainer key={header.id} header={header} table={table} in />
                      </Box>
                    ) : null}
                  </>
                ))}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton disabled={filterName === ""} name={"Save"} onClick={handleFilterSave} />
            <CustomButton variant="outlined" name={"Close"} onClick={handleClose} />
            {/* <Button disabled={filterName === ""} variant="contained" onClick={handleFilterSave}>
              Save
            </Button>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button> */}
          </DialogActions>
        </Dialog>

        <CustomDialog
          show={open_export}
          onHide={handleClose_export}
          maxWidth={"xs"}
          header={"Export"}
          contentNode={
            <>
              <Box
                sx={{
                  display: "grid",
                  gap: "8px",
                  padding: "8px",
                  flexWrap: "wrap",
                }}
              >
                <CustomButton
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, "xlsx")}
                  name={"Export to Excel - All Rows"}
                />
                <CustomButton
                  disabled={table.getRowModel().rows.length === 0}
                  onClick={() => handleExportRows(table.getRowModel().rows, "xlsx")}
                  name={"Export to Excel - Page Rows"}
                />
                <CustomButton
                  disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                  onClick={() => handleExportRows(table.getSelectedRowModel().rows, "xlsx")}
                  name={"Export to Excel - Selected Rows"}
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: "8px",
                  padding: "8px",
                  flexWrap: "wrap",
                }}
              >
                <CustomButton
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, "pdf")}
                  name={"Export to PDF - All Rows"}
                />
                <CustomButton
                  // color="inherit"
                  disabled={table.getRowModel().rows.length === 0}
                  onClick={() => handleExportRows(table.getRowModel().rows, "pdf")}
                  name={"Export to PDF - Page Rows"}
                />
                <CustomButton
                  // color="inherit"
                  disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                  onClick={() => handleExportRows(table.getSelectedRowModel().rows, "pdf")}
                  name={"Export to PDF - Selected Rows"}
                />
              </Box>
            </>
          }
          actionNode={<CustomButton variant="outlined" name={"Close"} onClick={handleClose_export} />}
        />
        <Dialog
          open={false}
          onClose={handleClose_export}
          maxWidth={"md"}
          // dividers={scroll === 'paper'}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Export
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ width: "500px" }}>
                <Box
                  sx={{
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, "pdf")}
                  >
                    Export Pdf All Row
                  </Button>
                  <Button
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={() => handleExportRows(table.getRowModel().rows, "pdf")}
                  >
                    Export Pdf Page Rows
                  </Button>
                  <Button
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows, "pdf")}
                  >
                    Export Pdf Selected Rows
                  </Button>

                  <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, "xlsx")}
                  >
                    Export Excel All Row
                  </Button>
                  <Button
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={() => handleExportRows(table.getRowModel().rows, "xlsx")}
                  >
                    Export Excel Page Rows
                  </Button>
                  <Button
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows, "xlsx")}
                  >
                    Export Excel Selected Rows
                  </Button>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose_export}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <CustomDialog
          show={saveFilter}
          onHide={() => {
            setSaveFilter(false);
          }}
          maxWidth={"sm"}
          header={"Save Grid Setting"}
          contentNode={
            <>
              <SaveGridSettings
                closePopUp={() => {
                  setSaveFilter(false);
                }}
                saveSettings={(data: string[]) => {
                  saveSettings(data);
                  setSaveFilter(false);
                }}
                pageSettings={pageSettings}
              />
            </>
          }
        />
        <ModalPopUp
          header="Page Settings"
          text="Settings saved successfully"
          onHide={() => {
            setOpenModalSaveSettingInfo(false);
          }}
          show={openModalSaveSettingInfo}
        />

        <ModalPopUp
          header="Inactive"
          text="Selected data is already inactive"
          onHide={() => {
            setOpenModalInactive(false);
          }}
          show={openModalInactive}
        />
      </LocalizationProvider>
    </>
  );
});

export default MaterialDynamicGrid;
