import { AxiosError, AxiosResponse } from "axios";
import { VENDOR_URL } from "./endpoints/vendor";
import { IAddVendorResponse, IVendorHistoryResponse, IVendorResponse } from "../../models/authorize.type";
import { axiosInstance } from "./config/axiosConfig";

const getVendorList = (requestParams: any): Promise<IVendorResponse> =>
  axiosInstance
    .get(VENDOR_URL.GetVendors, {
      params: requestParams,
    })
    .then((response: AxiosResponse<IVendorResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addVendor = (requestBody: any): Promise<IAddVendorResponse> =>
  axiosInstance
    .post(VENDOR_URL.AddVendor, requestBody)
    .then((response: AxiosResponse<IAddVendorResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateVendor = (requestBody: any): Promise<IAddVendorResponse> =>
  axiosInstance
    .post(VENDOR_URL.UpdateVendor, requestBody)
    .then((response: AxiosResponse<IAddVendorResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const versionHistory = (requestParams: any): Promise<IVendorHistoryResponse> =>
axiosInstance
  .get(VENDOR_URL.GetVendorHistory, {
    params: requestParams,
  })
  .then((response: AxiosResponse<IVendorHistoryResponse>) => {
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });
const getViewChanges = (requestParams: any): Promise<any> =>
axiosInstance
  .get(VENDOR_URL.GetVendorMasterByIdWithHistory, {
    params: requestParams,
  })
  .then((response: AxiosResponse<any>) => {
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Error getting Data" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });

const vendorService = {
  getVendorList,
  addVendor,
  updateVendor,
  versionHistory,
  getViewChanges
};

export default vendorService;
