import { Box, Button, Divider, Grid, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { updateVendorRecords } from "../../store/slices/vendorData";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../store/store";
import CustomTabs from "../../common/dynamicTabs/CustomTabs";
import { useSelector } from "react-redux";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import axios from "axios";
import CustomRadioButton from "../../common/dynamicInputs/CustomRadioButton";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import dayjs, { Dayjs } from "dayjs";
import CustomAutoComplete from "../../common/dynamicInputs/CustomAutoComplete";
import CustomAccordion from "../../common/dynamicAccordion/CustomAccordion";
import CustomButton from "../../common/dynamicButton/CustomButton";
import CustomFileUploader from "../../common/dynamicFileUploader/CustomFileUploader";
import CustomMultiFileUploader from "../../common/dynamicFileUploader/CustomMultiFileUploader";
import { updateLoader } from "../../store/slices/loader";
import CustomMultiSelect from "../../common/dynamicInputs/CustomMultiSelect";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Controller, useForm } from "react-hook-form";
import CustomNumberField from "../../common/dynamicInputs/CustomNumberField";
import { countryDropDown, stateDropDown } from "../../mockData/dropDownJson";
import EmailTemplate from "../emailTemplate/EmailTemplate";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { textFieldWidth } from "../../utils/constants";
import CustomChip from "../../common/dynamicChip/CustomChip";
import CustomStepper from "../../common/dynamicSteper/CustomStepper";
import CustomVerticalStepper from "../../common/dynamicSteper/CustomVerticalStepper";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import Department from "../master/department/Department";
import GenerateFormField from "../../common/generateFormField/GenerateFormField";
import ShowForm from "../../common/generateFormField/ShowForm";
import CustomBreadcrumbs from "../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { CompanyMasterMain } from "../companyMaster/CompanyMasterMain";
import BulkUpload from "../master/masterBulkUpload/BulkUpload";
import RiskScaleMaster from "../master/riskScaleMaster/RiskScaleMaster";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const dispatchThunk = useAppDispatchThunk();

  const [step, setstep] = useState([
    { name: "Components List" },
    { name: "step 2", icon: "list" },
    // { name: "Step 3" },
    // { name: "Step 4" },
    // { name: "Step 5", icon: "list" },
    // { name: "Step 6" },
  ]);
  const [value, setvalue] = useState("");
  const [showGenerateFormField, toggleGenerateFormField] = useState(false);
  const [showViewForm, setShowViewForm] = useState(false);
  const [datevalue, setdatevalue] = useState<Dayjs | null>(null);
  const [Data, setData] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openPopUp1, setOpenPopUp1] = useState(false);
  const [openPopUpDialog, setopenPopUpDialog] = useState(false);
  const { active } = useSelector((state: RootState) => state.tabData);

  const [masterId, setMasterId] = useState(0);

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    console.log(active, "dashboard page ");
  }, [active]);

  useEffect(() => {
    dispatch(updateVendorRecords([]));
    dispatch(updateLoader(false));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://dummyjson.com/products");

        let values = response.data.products;

        const columnsWithId = values.map((column: any) => {
          return {
            ...column,
            vendorId: column.id , // Set the id property to the value of the field property
          };
        });

        setData(columnsWithId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    //dispatch(updateVendorRecords([]));
    fetchData();
  }, []);

  const dialogClose = () => {
    setopenPopUpDialog(false);
  };
  const onChange = (value: any) => {
    console.log(value);
    setvalue(value);
  };
  const onChange_date = (value: any) => {
    console.log(value);
    setdatevalue(value);
  };

  const onButton_click = (value?: any) => {
    console.log("button click", value);
    setopenPopUpDialog(true);
  };

  const obj = { min: 1, max: 100 };

  const handleFileUpload = (files: any) => {
    // Handle the uploaded files here
    console.log("Uploaded files:", files);
  };

  const docs = [
    // Remote file
    { uri: require("../../assets/Sample-PNG-HD-Image.png") }, // Local File
    { uri: require("../../assets/samplecheckWord.docx") }, // Local File
    { uri: require("../../assets/SampleDrillDown(5).xlsx") }, // Local File
    { uri: require("../../assets/VendorRecords.pdf") }, // Local File
    { uri: require("../../assets/Presentation.pptx") }, // Local File
  ];

  const {
    handleSubmit: handleVendorForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      toogle_input: "",
      chek_input: "",
      radio_group: "",
      AutoComplete_multi: [],
      AutoComplete: "",
      OfficeLocation: "",
      Country: "",
      multiLinemax: "",
      text_field: "",
      number_field: "",
      date_picker: null,
      date_picker_not: null,
      multiLine: "",
      errorLogDatePicker: null,
    },
    mode: "onChange",
  });

  console.log(watch("AutoComplete_multi"));

  return (
    <>
      <CustomTabs steps={step} />
    
      {active == 0 && (
        <>
          {/* Comming soon .. */}

          <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="text_field"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Text Field is Required",
                      validate: (data) => {
                        return data.length >= 4 || "Minimal 4 characters requires";
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"text_field"}
                        required={true}
                        label={"Text Field"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.text_field)}
                        helperText={errorsloginform.text_field && errorsloginform.text_field.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="number_field"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Number Field is Required",
                      validate: (data) => {
                        const number = parseInt(data, 10);
                        // Check if it's a valid number and falls within the specified range
                        if (isNaN(number) || number < 0 || number > 99999999) {
                          return "Please enter a number between 0 and 99999999";
                        }
                      },
                    }}
                    render={({ field }) => (
                      <CustomNumberField
                        name={"number_field"}
                        required={true}
                        label={"Number Field"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.number_field)}
                        helperText={errorsloginform.number_field && errorsloginform.number_field.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="date_picker"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "Date Field is Required",
                    }}
                    render={({ field }) => (
                      <CustomDatePicker
                        required={true}
                        label={"Date Picker"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.date_picker)}
                        helperText={errorsloginform.date_picker && errorsloginform.date_picker.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="date_picker_not"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={dayjs()}
                        maxDate={dayjs().add(1, "year").toDate()}
                        label={"Date Picker Min/Max"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.date_picker_not)}
                        helperText={
                          errorsloginform.date_picker_not && errorsloginform.date_picker_not.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="multiLine"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <CustomMultilineTextField
                        label={"Multi Line"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.multiLine)}
                        helperText={errorsloginform.multiLine && errorsloginform.multiLine.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="multiLinemax"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Multi line Field is Required",
                    }}
                    render={({ field }) => (
                      <CustomMultilineTextField
                        label={"Multi line Max Height"}
                        value={field.value}
                        rows={5}
                        required={true}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.multiLinemax)}
                        helperText={errorsloginform.multiLinemax && errorsloginform.multiLinemax.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Country"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Select is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name="country"
                        valueKey={"value"}
                        optionLabelKey="value"
                        options={countryDropDown}
                        required={true}
                        label={"Select"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Country)}
                        helperText={errorsloginform.Country && errorsloginform.Country.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="OfficeLocation"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Cascading Select is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        cascadingKey={"country"}
                        cascadingvalue={watch("Country")}
                        name="Cascading Select"
                        valueKey={"value"}
                        optionLabelKey="value"
                        options={stateDropDown}
                        required={true}
                        label={"Cascading Select"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.OfficeLocation)}
                        helperText={
                          errorsloginform.OfficeLocation && errorsloginform.OfficeLocation.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="AutoComplete"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Auto Completed is Required",
                    }}
                    render={({ field }) => (
                      <CustomAutoComplete
                        optionLabelKey="title"
                        optionValueKey="id"
                        options={Data}
                        required={true}
                        label={"Auto Completed"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.AutoComplete)}
                        helperText={errorsloginform.AutoComplete && errorsloginform.AutoComplete.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="AutoComplete_multi"
                    control={control}
                    defaultValue={[]}
                    rules={{
                      required: "Multi Select is Required",
                    }}
                    render={({ field }) => (
                      <CustomMultiSelect
                        optionLabelKey="title"
                        optionIdKey="title"
                        options={Data}
                        required={true}
                        label={"Multi Select"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.AutoComplete_multi)}
                        helperText={
                          errorsloginform.AutoComplete_multi && errorsloginform.AutoComplete_multi.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="radio_group"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Radio group is Required",
                    }}
                    render={({ field }) => (
                      <CustomRadioButton
                        list={[
                          { value: 1, lable: "name" },
                          { value: 2, lable: "age" },
                        ]}
                        label="Radio Button Input"
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.radio_group)}
                        helperText={
                          errorsloginform.radio_group &&
                          errorsloginform.radio_group.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="chek_input"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Required Policy Check",
                    }}
                    render={({ field }) => (
                      <CustomCheckBox
                        label="Check Box Input"
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.chek_input)}
                        helperText={errorsloginform.chek_input && errorsloginform.chek_input.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="toogle_input"
                    control={control}
                    defaultValue={""}
                    rules={
                      {
                        //  required: "Required Policy Check",
                      }
                    }
                    render={({ field }) => (
                      <CustomToogleSwitch
                        list={[{ value: 1, lable: "name" }]}
                        label="Toggle Input"
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.toogle_input)}
                        helperText={errorsloginform.toogle_input && errorsloginform.toogle_input.message?.toString()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Divider flexItem />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 3,
              my: 4,
            }}
          >
            <Box>
              <CustomButton
                variant="contained"
                name={"Button"}
                valuePass={{ name: "2", id: 2 }}
                onClick={() => setOpenPopUp1(true)}
              />
            </Box>
            <Box>
              <CustomButton
                variant="contained"
                size="small"
                name={"Button"}
                valuePass={{ name: "2", id: 2 }}
                onClick={onButton_click}
              />
            </Box>
            <Box>
              <CustomButton
                variant="outlined"

                name={"Button"}
                valuePass={{ name: "2", id: 2 }}
                onClick={onButton_click}
              />
            </Box>
            <Box>
              <CustomButton
                variant="text"
                name={"Button Outline"}
                valuePass={{ name: "2", id: 2 }}
                onClick={onButton_click}
              />
            </Box>
            <Box>
              <CustomButton
                variant="contained"
                name={"Button"}
                valuePass={{ name: "2", id: 2 }}
                onClick={onButton_click}
                disabled={true}
              />
            </Box>
            <Box>
              <CustomButton
                variant="outlined"

                name={"Button"}
                valuePass={{ name: "2", id: 2 }}
                onClick={onButton_click}
                disabled={true}
              />
            </Box>
            <Box>
              <CustomButton
                variant="text"
                name={"Button Outline"}
                valuePass={{ name: "2", id: 2 }}
                onClick={onButton_click}
                disabled={true}
              />
            </Box>
          </Box>

          <Box>
            <CustomChip label="chip" onDelete={() => onButton_click()} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <CustomStepper activeStep={0} steps={['Select campaign settings', 'Create an ad group', 'Create an ad']} />
            {/* <CustomVerticalStepper steps ={['Select campaign settings', 'Create an ad group', 'Create an ad']}/> */}
          </Box>

          <Divider flexItem />
          <Box sx={{ my: 3 }}>
            <CustomAccordion
              expanded={expanded === "panel1"}
              handleChange={handleChange("panel1")}
              title="Accordian"
              secondaryText="accordion sub title"
              details={"Accordian Details"}
            />
            <CustomAccordion
              expanded={expanded === "panel2"}
              handleChange={handleChange("panel2")}
              title="Accordian"
              details={""}
            />
          </Box>
          <Divider flexItem />
          <Box sx={{ my: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <CustomFileUploader
                  onFileUpload={handleFileUpload}
                  maxFileSize={1048576}
                  acceptFormats={[".pdf", ".png", ".xlsx", ".docx", ".ppt", ".ppts", ".csv"]}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <CustomMultiFileUploader
                  onFileUpload={handleFileUpload}
                  maxFileSize={1048576}
                  acceptFormats={[".pdf", ".png", ".xlsx", ".docx", ".ppt", ".pptx", ".csv"]}
                />
              </Grid>
            </Grid>
          </Box>

          <CustomBreadcrumbs list={[{ link: '/dashboard', name: 'Dashboard' }, { link: '/master', name: 'Department' }]} />

          <BulkUpload/>
          <Box>
            <Button onClick={() => setOpenPopUp(true)}>view Documents check</Button>
            {/* <Department /> */}
            {/* <Dialog
              open={openPopUp}
              onClose={() => {
                setOpenPopUp(false);
              }}
              maxWidth={"lg"}
              fullWidth
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setOpenPopUp(false);
                    }}
                  >
                    X
                  </IconButton>
                </Box>
                <Box sx={{ width: "100%", height: "80vh" }}>
                  <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    documents={docs}
                  />
                </Box>
              </DialogContent>
            </Dialog> */}
          </Box>


        </>
      )}


      {active === 1 && (
        <Box>
          <Grid
            sx={{
              display: "flex",
              gap: "20px",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <CustomButton
              variant="contained"
              name={"Add Column"}
              onClick={() => { }}
              size="small"
            />
            <CustomButton variant="contained" name={"View Column"} onClick={() => { }} size="small" />
          </Grid>
        </Box>
      )}

      <CustomDialog onHide={dialogClose} show={openPopUpDialog} header={'Dialog Box'} contentNode={<Box>Details</Box>} />
      <CustomDialog onHide={() => setOpenPopUp(false)} show={openPopUp} header={'Document Viewer'} contentNode={<DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={docs}
      />} />

      <ModalPopUp
        header="Info"
        text="Vendor Added SuccessFully"
        onHide={() => setOpenPopUp1(false)}
        show={openPopUp1}
      />

      <Box sx={{mt:3}}>
      <RiskScaleMaster />

      </Box>
    </>

  );
}
