import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomSvg from "../CustomSvg";
import { useTheme } from "@mui/material/styles";
import { styled, alpha } from "@mui/material/styles";
import BasicTableStyle from "../dynamicDataGrid/basicTable.module.scss";
import CommonDataExport from "../CommonSheetsDownload/ExcelDownload";

interface IBasicTableProps {
  rowData: any[];
  columns: any[];
  style?: any;
  isOverFlow?: any;
  handleAction?: any;
  isEdit?: any;
  isView?: any;
  isDelete?: any;
  isDownload?: any;
  showDeleteButton?: boolean;
  disableAction?: boolean;
  statusIdMapping?: any;
  table_name? : any
}

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    minWidth: 50,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[400],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const DynamicBasicTable: React.FC<IBasicTableProps> = ({
  rowData,
  columns,
  style,
  isOverFlow,
  handleAction,
  isEdit,
  isView,
  isDelete,
  showDeleteButton,
  disableAction,
  statusIdMapping,
  isDownload,
  table_name
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<{ [key: number]: HTMLElement | null }>({});

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setAnchorEl((prev) => ({
      ...prev,
      [index]: event.currentTarget,
    }));
  };

  const handleClose = (index: number) => {
    setAnchorEl((prev) => ({
      ...prev,
      [index]: null,
    }));
  };

  return (
    <>
    <Stack spacing={2}>
  {isDownload && <Box sx={{display:'flex' , alignItems:'center' , justifyContent :'flex-end' }}>
  <CommonDataExport header={ columns } data={rowData} table_name={table_name ? table_name : 'Download'}   />
  </Box> }
   
   
    <TableContainer sx={style}>
      <Table stickyHeader aria-label="simple basic table">
        <TableHead>
          <TableRow>
            {columns &&
              columns.map((data: Record<string, any> | string) => {
                return (
                  <TableCell className={BasicTableStyle.tab_header} key={typeof data === "object" ? data.Field : data}>
                    {typeof data === "object" ? data.DisplayName : data}
                  </TableCell>
                );
              })}
            {(isEdit || isView) && <TableCell className={BasicTableStyle.tab_header}>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData && rowData.length > 0 ? (
            rowData.map((row: any, index: number) => (
              <TableRow className={BasicTableStyle.alternateRow} key={index}>
                {columns &&
                  columns.map((data: any) => {
                    const fieldValue = row[typeof data === "object" ? data.Field : data];
                    return (
                      <TableCell
                        className={BasicTableStyle.tab_data}
                        key={typeof data === "object" ? data.Field : data}
                        // sx={{ border: "1px solid #f1f1f1" }}
                      >
                        {fieldValue?.toString()?.startsWith("data:image/") ? (
                          <Box sx={{ width: "100%", maxHeight: "150px" }}>
                            <img
                              src={fieldValue}
                              style={{ width: "150px", height: "auto", objectFit: "contain" }}
                              alt={""}
                            />
                          </Box>
                        ) : (
                          fieldValue
                        )}
                      </TableCell>
                    );
                  })}

                {(isEdit || isView) && (
                  <TableCell className={BasicTableStyle.tab_data}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        columnGap: "10px",
                        height: "20px",
                        // px: 1,
                      }}
                    >
                      <IconButton
                        sx={{ padding: "0px" }}
                        disabled={disableAction}
                        onClick={(e) => handleClick(e, index)}
                      >
                        <CustomSvg size={"20px"} name={"more-vertical"} />
                      </IconButton>

                      <StyledMenu
                        id={`menu-${index}`}
                        open={Boolean(anchorEl[index])}
                        anchorEl={anchorEl[index]}
                        onClose={() => handleClose(index)}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column", padding: "5px 0px" }}>
                          {isEdit &&
                            (row?.isActive || statusIdMapping ? statusIdMapping[row.Status] === "Active" : true) && (
                              <MenuItem
                                sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                                onClick={() => {
                                  if (!disableAction) handleAction(row, "edit", index);
                                  handleClose(index);
                                }}
                              >
                                <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"edit"} />
                                <Typography>Edit</Typography>
                              </MenuItem>
                            )}

                          {isView && (
                            <MenuItem
                              sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                              onClick={() => {
                                handleAction(row, row?.isActive ? "InActive:" : "active", index);
                                handleClose(index);
                              }}
                            >
                              <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"view"} />
                              <Typography>{"View"}</Typography>
                            </MenuItem>
                          )}

                          {isDelete && (
                            <MenuItem
                              sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                              onClick={() => {
                                if (!disableAction)
                                  handleAction(
                                    row,
                                    row.isActive || statusIdMapping[row.Status] === "Active"
                                      ? "InActivate"
                                      : "Activate",
                                    index
                                  );
                                handleClose(index);
                              }}
                            >
                              <CustomSvg
                                size={"20px"}
                                name={
                                  row.isActive || statusIdMapping[row.Status] === "Active" ? "power" : "minus-circle"
                                }
                              />
                              <Typography>
                                {row.isActive || statusIdMapping[row.Status] === "Active" ? "InActive" : "Active"}
                              </Typography>
                            </MenuItem>
                          )}

                          {showDeleteButton && (
                            <MenuItem
                              sx={{ display: "flex", gap: "10px", textAlign: "left" }}
                              onClick={() => {
                                handleAction(row, "delete", index);
                                handleClose(index);
                              }}
                            >
                              <CustomSvg size={"20px"} name={"trash-2"} />
                              <Typography>{"Delete"}</Typography>
                            </MenuItem>
                          )}
                        </Box>
                      </StyledMenu>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2">No Records Found</Typography>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>

    </Stack>
    </>
  );
};

export default DynamicBasicTable;
