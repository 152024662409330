import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import costApprovalService from "../services/costApprovalService";

export interface ICostApprovalState {
  costApprovalData: any;
  isLoading: boolean;
  isCostApprovalDataAdded: boolean;
  isCostApprovalDataUpdated: boolean;
  isCostApprovalDataDeleted: boolean;
  costApprovalToasterMsg: { message: string; status: string };
}

export const initialState: ICostApprovalState = {
  costApprovalData: [],
  isLoading: false,
  isCostApprovalDataAdded: false,
  isCostApprovalDataUpdated: false,
  isCostApprovalDataDeleted: false,
  costApprovalToasterMsg: { message: "", status: "" },
};

export const costApprovalSubmitCostApproval = createAsyncThunk(
  "costApprovalSubmitCostApproval",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await costApprovalService.costApprovalSubmitCostApproval(requestBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const costApprovalSlice = createSlice({
  name: "costApproval",
  initialState,
  reducers: {
    costApprovalResetState: (state, action: PayloadAction<any>) => {
      state.costApprovalData = [];
      state.isLoading = false;
      state.isCostApprovalDataAdded = false;
      state.isCostApprovalDataUpdated = false;
      state.isCostApprovalDataDeleted = false;
      state.costApprovalToasterMsg = { message: "", status: "" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(costApprovalSubmitCostApproval.pending, (state) => {
        state.isLoading = true;
        state.isCostApprovalDataAdded = false;
        state.isCostApprovalDataUpdated = false;
        state.isCostApprovalDataDeleted = false;
      })
      .addCase(costApprovalSubmitCostApproval.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isCostApprovalDataAdded = true;
        state.costApprovalToasterMsg = action?.payload?.payload?.toaster;
      })
      .addCase(costApprovalSubmitCostApproval.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isCostApprovalDataAdded = true;
        state.costApprovalToasterMsg = action?.payload;
        state.costApprovalToasterMsg.message = action?.payload?.payload?.toaster?.message;
        state.costApprovalToasterMsg.status = "false";
      });
  },
});

export const { costApprovalResetState } = costApprovalSlice.actions;
export default costApprovalSlice.reducer;
