import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomAccordion from "../../../dynamicAccordion/CustomAccordion";
import CustomButton from "../../../dynamicButton/CustomButton";
import CustomCheckBox from "../../../dynamicInputs/CustomCheckBox";
import CustomTabs from "../../../dynamicTabs/CustomTabs";
import { useAppDispatchThunk } from "../../../../store/store";
import { setTabs } from "../../../../store/slices/tabsData";
import { PLUGIN_STEPPER } from "../../../../utils/constants";

interface IDOReview {
  formData: any;
  handleSelectChange: any;
}

const DOReview: React.FC<IDOReview> = ({ formData, handleSelectChange }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  // Separate states for Outliers
  const [toBeReviewedItemsOutliers, setToBeReviewedItemsOutliers] = useState<any[]>([]);
  const [acceptedItemsOutliers, setAcceptedItemsOutliers] = useState<any[]>([]);

  // Separate states for Non-Negotiables
  const [toBeReviewedItemsNonNegotiable, setToBeReviewedItemsNonNegotiable] = useState<any[]>([]);
  const [acceptedItemsNonNegotiable, setAcceptedItemsNonNegotiable] = useState<any[]>([]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const dispatch = useAppDispatchThunk();

  useEffect(() => {
    dispatch(setTabs(0));
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(formData)) {
      setToBeReviewedItemsOutliers(formData);
      setToBeReviewedItemsNonNegotiable(formData);
    } else {
      console.warn("formData is not an array:", formData);
      setToBeReviewedItemsOutliers([]);
      setToBeReviewedItemsNonNegotiable([]);
    }
  }, [formData]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAcceptItemOutliers = (item: any) => {
    setToBeReviewedItemsOutliers((prev) => prev.filter((i) => i.id !== item.id));
    setAcceptedItemsOutliers((prev) => [...prev, { ...item, isSelected: true }]);
  };

  const handleAcceptItemNonNegotiable = (item: any) => {
    setToBeReviewedItemsNonNegotiable((prev) => prev.filter((i) => i.id !== item.id));
    setAcceptedItemsNonNegotiable((prev) => [...prev, { ...item, isSelected: true }]);
  };

  const handleToggleClauseOutliers = (item: any, index: number, type: "toBeReviewed" | "accepted") => {
    if (type === "toBeReviewed") {
      setToBeReviewedItemsOutliers((prev) => prev.filter((_, i) => i !== index));
      setAcceptedItemsOutliers((prev) => [...prev, { ...item, isSelected: true }]);
    } else {
      setAcceptedItemsOutliers((prev) => prev.filter((_, i) => i !== index));
      setToBeReviewedItemsOutliers((prev) => [...prev, { ...item, isSelected: false }]);
    }
  };

  const handleToggleClauseNonNegotiable = (item: any, index: number, type: "toBeReviewed" | "accepted") => {
    if (type === "toBeReviewed") {
      setToBeReviewedItemsNonNegotiable((prev) => prev.filter((_, i) => i !== index));
      setAcceptedItemsNonNegotiable((prev) => [...prev, { ...item, isSelected: true }]);
    } else {
      setAcceptedItemsNonNegotiable((prev) => prev.filter((_, i) => i !== index));
      setToBeReviewedItemsNonNegotiable((prev) => [...prev, { ...item, isSelected: false }]);
    }
  };

  const handleSubmit = () => {
    console.log("Accepted Outlier Items Submitted:", acceptedItemsOutliers);
    console.log("Accepted Non-Negotiable Items Submitted:", acceptedItemsNonNegotiable);
    // handleSelectChange(PLUGIN_STEPPER.SME_SIGN_OFF);
  };

  const handleSendBackToSO = () => {
    console.log("Accepted Items Send Back to SO:", acceptedItemsNonNegotiable);
    // handleSelectChange(PLUGIN_STEPPER.SO);
  };

  const handleTabNavigation = (_: any, index: number) => {
    dispatch(setTabs(index));
    setActiveTab(index);
  };

  const tapSteps = [{ name: "Outliers" }, { name: "Non-Negotiables" }];

  return (
    <Box sx={{ maxHeight: "calc(100vh - 250px)", px: 2, py: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          DO Review
        </Typography>
      </Box>
      <Stack direction="column" spacing={2}>
        <CustomTabs steps={tapSteps} handleTabNavigation={handleTabNavigation} />
        <Box sx={{ maxHeight: "calc(100vh - 255px)", overflow: "auto", pr: 1 }}>
          {activeTab === 0 && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <CustomAccordion
                  expanded={expanded === "toBeReviewedOutliers"}
                  handleChange={handleChange("toBeReviewedOutliers")}
                  title={"To Be Reviewed"}
                  details={
                    <Box sx={{ width: "calc(100vw - 80px)" }}>
                      <Stack spacing={2}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                          {toBeReviewedItemsOutliers.length > 0 && (
                            <Typography
                              onClick={() => {
                                if (expanded === "toBeReviewedOutliers") {
                                  setAcceptedItemsOutliers((prev) => [
                                    ...prev,
                                    ...toBeReviewedItemsOutliers.map((item) => ({ ...item, isSelected: true })),
                                  ]);
                                  setToBeReviewedItemsOutliers([]);
                                }
                              }}
                              sx={{ textDecoration: "underline", cursor: "pointer" }}
                            >
                              Accept All
                            </Typography>
                          )}
                        </Box>
                        {toBeReviewedItemsOutliers.length > 0 ? (
                          toBeReviewedItemsOutliers.map((item: any, index: number) => (
                            <Box
                              key={item.id}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: "5px",
                                border: "1px solid #ddd",
                                p: 1,
                              }}
                            >
                              <Box>
                                <Typography variant="body2" fontWeight="bold">
                                  {item.header}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                  Finance
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex", gap: "10px" }}>
                                <Typography>Reviewed</Typography>
                                <CustomCheckBox
                                  isStyle={true}
                                  alignItems="center"
                                  label=""
                                  onChange={() => handleToggleClauseOutliers(item, index, "toBeReviewed")}
                                  value={item.isSelected || false}
                                />
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                              border: "1px solid #ddd",
                              p: 1,
                            }}
                          >
                            <Typography variant="body2" fontWeight="bold">
                              No Records Found
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </Box>
                  }
                />
              </Box>
              <CustomAccordion
                expanded={expanded === "acceptedOutliers"}
                handleChange={handleChange("acceptedOutliers")}
                title={"Accepted"}
                details={
                  <Box sx={{ width: "100%" }}>
                    <Stack spacing={2}>
                      {acceptedItemsOutliers.length > 0 ? (
                        acceptedItemsOutliers.map((item: any, index: number) => (
                          <Box
                            key={item.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "5px",
                              border: "1px solid #ddd",
                              p: 1,
                            }}
                          >
                            <Box>
                              <Typography variant="body2" fontWeight="bold">
                                {item.header}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                Finance
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography>Reviewed</Typography>
                              <CustomCheckBox
                                isStyle={true}
                                alignItems="center"
                                label=""
                                onChange={() => handleToggleClauseOutliers(item, index, "accepted")}
                                value={item.isSelected || false}
                              />
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            border: "1px solid #ddd",
                            p: 1,
                          }}
                        >
                          <Typography variant="body2" fontWeight="bold">
                            No Records Found
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                }
              />
            </>
          )}
          {activeTab === 1 && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <CustomAccordion
                  expanded={expanded === "toBeReviewedNonNegotiable"}
                  handleChange={handleChange("toBeReviewedNonNegotiable")}
                  title={"To Be Reviewed"}
                  details={
                    <Box sx={{ width: "calc(100vw - 80px)" }}>
                      <Stack spacing={2}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                          {toBeReviewedItemsNonNegotiable.length > 0 && (
                            <Typography
                              onClick={() => {
                                if (expanded === "toBeReviewedNonNegotiable") {
                                  setAcceptedItemsNonNegotiable((prev) => [
                                    ...prev,
                                    ...toBeReviewedItemsNonNegotiable.map((item) => ({ ...item, isSelected: true })),
                                  ]);
                                  setToBeReviewedItemsNonNegotiable([]);
                                }
                              }}
                              sx={{ textDecoration: "underline", cursor: "pointer" }}
                            >
                              Accept All
                            </Typography>
                          )}
                        </Box>
                        {toBeReviewedItemsNonNegotiable.length > 0 ? (
                          toBeReviewedItemsNonNegotiable.map((item: any, index: number) => (
                            <Box
                              key={item.id}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: "5px",
                                border: "1px solid #ddd",
                                p: 1,
                              }}
                            >
                              <Box>
                                <Typography variant="body2" fontWeight="bold">
                                  {item.header}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                  Finance
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex", gap: "10px" }}>
                                <Typography>Reviewed</Typography>
                                <CustomCheckBox
                                  isStyle={true}
                                  alignItems="center"
                                  label=""
                                  onChange={() => handleToggleClauseNonNegotiable(item, index, "toBeReviewed")}
                                  value={item.isSelected || false}
                                />
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                              border: "1px solid #ddd",
                              p: 1,
                            }}
                          >
                            <Typography variant="body2" fontWeight="bold">
                              No Records Found
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </Box>
                  }
                />
              </Box>
              <CustomAccordion
                expanded={expanded === "acceptedNonNegotiable"}
                handleChange={handleChange("acceptedNonNegotiable")}
                title={"Accepted"}
                details={
                  <Box sx={{ width: "100%" }}>
                    <Stack spacing={2}>
                      {acceptedItemsNonNegotiable.length > 0 ? (
                        acceptedItemsNonNegotiable.map((item: any, index: number) => (
                          <Box
                            key={item.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "5px",
                              border: "1px solid #ddd",
                              p: 1,
                            }}
                          >
                            <Box>
                              <Typography variant="body2" fontWeight="bold">
                                {item.header}
                              </Typography>
                              <Typography variant="caption" color="textSecondary">
                                Finance
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography>Reviewed</Typography>
                              <CustomCheckBox
                                isStyle={true}
                                alignItems="center"
                                label=""
                                onChange={() => handleToggleClauseNonNegotiable(item, index, "accepted")}
                                value={item.isSelected || false}
                              />
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            border: "1px solid #ddd",
                            p: 1,
                          }}
                        >
                          <Typography variant="body2" fontWeight="bold">
                            No Records Found
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                }
              />
            </>
          )}
        </Box>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "space-between", borderTop: "1px solid #ddd", pt: 1 }}>
        <CustomButton name="Send Back to SO" variant="outlined" onClick={handleSendBackToSO} />
        {activeTab === 0 && (
          <CustomButton
            variant="contained"
            onClick={() => {
              let index = 1;
              dispatch(setTabs(index));
              setActiveTab(index);
            }}
            name="Next"
          />
        )}
        {activeTab === 1 && <CustomButton variant="contained" onClick={handleSubmit} name="Submit" />}
        {/* <CustomButton name="Submit" variant="contained" onClick={handleSubmit} /> */}
      </Box>
    </Box>
  );
};

export default DOReview;
