import { Box, Button, Stack, Typography } from "@mui/material";

export default function AddMasters() {
  // const columns_data: any = {
  //   columns: [
  //     { field: "id", headerName: "ID", width: 90 },
  //     {
  //       field: "firstName",
  //       headerName: "First name",
  //       width: 150,
  //       isEditable: true,
  //     },
  //     {
  //       field: "lastName",
  //       headerName: "Last name",
  //       width: 150,
  //       type: "string",
  //     },
  //     {
  //       field: "age",
  //       headerName: "Age",
  //       type: "number",
  //       description: "adS",
  //       width: 110,
  //       isEditable: true,
  //     },
  //     {
  //       field: "role",
  //       headerName: "Department",
  //       width: 220,
  //       isEditable: true,
  //       type: "singleSelect",
  //       valueOptions: ["Market", "Finance", "Development"],
  //     },
  //     { field: "date", headerName: "Date", description: "Age", width: 110 },
  //     {
  //       field: "fullName",
  //       headerName: "Full name",
  //       description: "This column has a value getter and is not sortable.",
  //       sortable: false,
  //       width: 160,
  //     },
  //     { field: "Actions", headerName: "Action", width: 150, type: "actions" },
  //   ],

  //   enableCheckBox: true,

  //   tableHeight: 500,
  // };
  // const columns: any = {
  //   table_name: "new2",
  //   columns: [
  //     {
  //       field: "id",
  //       headerName: "ID",
  //       width: 90,
  //       type: "number",
  //     },
  //     {
  //       field: "firstName",
  //       headerName: "First name",
  //       width: 150,
  //       type: "string",
  //     },
  //     {
  //       field: "lastName",
  //       headerName: "Last name",
  //       width: 150,
  //       type: "string",
  //     },
  //     {
  //       field: "age",
  //       headerName: "Age",
  //       type: "number",
  //       description: "adS",
  //       width: 110,
  //     },
  //     {
  //       field: "role",
  //       headerName: "Department",
  //       width: 220,

  //       type: "number",
  //     },
  //     {
  //       field: "date",
  //       headerName: "Date",
  //       type: "date",

  //       description: "Age",
  //       width: 110,
  //     },
  //     {
  //       field: "description",
  //       headerName: "Description",
  //       type: "string",

  //       description: "Description",
  //       width: 110,
  //     },
  //   ],
  // };
  return (
    <>
      <Stack spacing={3}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Add Master</Typography>{" "}
          <Button variant="contained">Add More</Button>
        </Box>
        {/* <DevExpDynamicGrid data={columns}/>  */}
        {/* <CustomPaginationGrid  data={columns_data} /> */}
      </Stack>
    </>
  );
}
