import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddAQMResponse, IAQMResponse, IVHColumnRes, } from "../../models/assessmentQuestionnaireMaster.type";
import { AQM_URL } from "./endpoints/assessmentQuestionnaireMaster";


const getAQMList = (reqparams: any): Promise<IAQMResponse> =>
  axiosInstance
    .get(AQM_URL.GetAllAQM)
    .then((response: AxiosResponse<IAQMResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateAQM = (requestBody: any): Promise<IAddAQMResponse> =>
  axiosInstance
    .post(AQM_URL.UpdateAQM, requestBody)
    .then((response: AxiosResponse<IAddAQMResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addAQM = (requestBody: any): Promise<IAddAQMResponse> =>
  axiosInstance
    .post(AQM_URL.AddAQM, requestBody)
    .then((response: AxiosResponse<IAddAQMResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteAQM = (requestBody: any): Promise<IAddAQMResponse> =>
  axiosInstance
    .post(AQM_URL.DeleteAQM, requestBody)
    .then((response: AxiosResponse<IAddAQMResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryAQM = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(AQM_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const AQMService = {
  getAQMList,
  updateAQM,
  addAQM,
  deleteAQM,
  getVersionHistoryAQM,
};

export default AQMService;
