
import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../store/store";
import { CustomBasicAccordion } from "../../../../common/dynamicAccordion/CustomBasicAccordion";
import { textFieldWidth } from "../../../../utils/constants";
import LableandValuesPreview from "../../../../common/CommonPreview/LableandValuesPreview";
import { Assesmentdeviationcolums, thresholdQuestionViewColumns } from "../../VendorOnBoardingPreview/FinalAssesmentScoring";
import DynamicBasicTable from "../../../../common/dynamicbasicTable/DynamicBasicTable";
import CommonDataExport from "../../../../common/CommonSheetsDownload/ExcelDownload";
import { useTheme } from "@mui/material/styles";

interface VendorAssessmentSummeryCXOsignoffprops {
    calculatedAssessmentDetails:any
    setpreviewsection? : any
}

const responseQuestionsColumncommon = [
    { DisplayName: "Question ", Field: "Question" },
    { DisplayName: "Original Response", Field: "wtg" },
    { DisplayName: "Revised Response by so", Field: "bestScore" }
  ]

export const VendorAssessmentSummeryCXOsignoff: React.FC<VendorAssessmentSummeryCXOsignoffprops> = ({calculatedAssessmentDetails , setpreviewsection}) => {
  const [expanded, setExpanded] = useState<any>({ 0: true, 1: true, 2: true, 3: true, 4: true });
  const [natureServiceDetails, setnatureServiceDetails] = useState<any>({});
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [Finaldeviationdata, setFinaldeviationdata] = useState([]);
  const [responseQuestions, setresponseQuestions] = useState([]);
  const [responseQuestionsColumn, setresponseQuestionsColumn] = useState(responseQuestionsColumncommon);
  const [Finaldeviationdatacolumns, setFinaldeviationdatacolumns] = useState(Assesmentdeviationcolums);
  const [thresholdQuestionsdata, setthresholdQuestionsdata] = useState<any>([]);
  const [thresholdQuestioncolums, setThresholdQuestioncolums] = useState(thresholdQuestionViewColumns);


  useEffect(() => {
      setThresholdQuestioncolums((prevColumns) => [...prevColumns, { DisplayName: "Actions", Field: "Actions" }]);
  }, []);
  useEffect(() => {

    if(calculatedAssessmentDetails){
        console.log(calculatedAssessmentDetails , "calculatedAssessmentDetails");
        
        setFinaldeviationdata(calculatedAssessmentDetails?.deviation)
        setthresholdQuestionsdata(calculatedAssessmentDetails?.thresholdQuestions)
    }

  },[calculatedAssessmentDetails])

  const handleAccordionChange = (index: number) => {
    setExpanded((prevExpanded: any[]) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };



  return (
    <>
    <Box sx={{display:'flex' , alignItems :'center' , justifyContent:'flex-end' , gap:2}}>
    <Typography
                          onClick={() => {
                            setpreviewsection && setpreviewsection(true);
                          }}

                         
                          color={`${theme.palette.primary.main}`} 
                          sx={{ textAlign: "left", cursor: "pointer", textDecoration: "underline" }}
                        >
                          Vendor Assessment Details
                        </Typography>
    <CommonDataExport
            header={responseQuestionsColumn}
            data={ responseQuestions}
            otherdatas={[[...Finaldeviationdata] , [...thresholdQuestionsdata]]}
            otherheaders={[[...Finaldeviationdatacolumns] , [...thresholdQuestioncolums]]}
            table_name={"Aggregated Deviation"}
            button_title={"Download"}
          />{" "}
    </Box>
      <CustomBasicAccordion
        expanded={expanded[0]}
        handleChange={() => handleAccordionChange(0)}
        title="VENDOR ASSESSMENT RESPONSE"
        details={<>
                   <Box>
                   <DynamicBasicTable
            table_name={"Key Outliers"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px" }}
            rowData={responseQuestions}
            columns={responseQuestionsColumn}
          />
            </Box>
        
        </>}
      />
      
      <CustomBasicAccordion
        expanded={expanded[1]}
        handleChange={() => handleAccordionChange(1)}
        title="CATEGORY-WISE SUMMERY"
        details={<>   <Box>
                <DynamicBasicTable
            table_name={"Key Outliers"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px" }}
            rowData={Finaldeviationdata}
            columns={Finaldeviationdatacolumns}
          />
          </Box></>}
      />
       
      <CustomBasicAccordion
        expanded={expanded[2]}
        handleChange={() => handleAccordionChange(2)}
        title="OUTLINERS & MITIGATIONS"
        details={<>   <Box>
             <DynamicBasicTable
            table_name={"Key Outliers"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px" }}
            rowData={thresholdQuestionsdata}
            columns={thresholdQuestioncolums}
          />
          </Box></>}
      />
       
    </>
  );
};
