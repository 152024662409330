import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { getDepartmentList } from "../../../store/slices/departmentData";
import { getRolesList } from "../../../store/slices/roleData";
import { getUsersList } from "../../../store/slices/userData";
import { ProcessDetailContext } from "./ContractRolesandResponsibility";
import { useTheme } from "@mui/material/styles";
interface ProcessDetailsProps {
  view?: any;
}

export const ProcessDetails: React.FC<ProcessDetailsProps> = ({ view }) => {
  const context = useContext(ProcessDetailContext);
  const theme = useTheme();
  const dispatch = useAppDispatchThunk();
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { allRoles } = useSelector((state: RootState) => state.roleData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const [roleListData, setRoleListData] = useState<any[]>([]);
  const [departmentListData, setDepartmentListData] = useState<any[]>([]);
  const [userListData, setUserListData] = useState<any[]>([]);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getDepartmentList({ FormID: 2 }));
    dispatch(getRolesList({ formId: 3 }));
    dispatch(getUsersList({ FormID: 2 }));
  }, []);

  useEffect(() => {
    setRoleListData(allRoles?.roles || []);
  }, [allRoles]);
  useEffect(() => {
    setUserListData(allUsers);
  }, [allUsers]);

  useEffect(() => {
    setDepartmentListData(dataTable);
  }, [dataTable]);

  const validateform = () => {
    return "";
  };

  const conditionRoleandDepa = (roleId: any, department: any, primaryUser?: any) => {
    let seldepartment: any[] = department || [];

    if (roleId) {
      let selUser = allUsers.filter((item) => item.RoleId == roleId);

      if (seldepartment.length > 0) {
        selUser = selUser.filter((item: any) => seldepartment.includes(item?.DepartmentId));
      }

      if (primaryUser && primaryUser?.length > 0) {
        selUser = selUser.filter((item: any) => !primaryUser.includes(item?.UserId));
      }
      return selUser;
    } else {
      return [];
    }
  };

  if (!context) {
    return null;
  }

  const { workflowProcess, validationErrors, handleInputChange } = context;

  return (
    <>
      <Box className={BasicTableStyle.tab_outer} sx={{ width: "100%", mt: 2 }}>
        <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={BasicTableStyle.tab_header}
                // sx={{ minWidth: "24%", fontWeight: "bold", whiteSpace: "pre" }}
                // sx={{
                //   minWidth: "24%",
                //   fontWeight: "bold",
                //   whiteSpace: "pre",
                //   position: "sticky",
                //   left: 0,
                //   backgroundColor: "white",
                //   zIndex: 1,
                //   border: "1px solid rgb(235,235,235)",

                // }}
                sx={{
                  minWidth: "200px",
                  whiteSpace: "pre",
                  left: 0, // Keeps the first column header fixed horizontally
                  zIndex: "3 !important",
                }}
              >
                Process Name
              </TableCell>
              <TableCell className={BasicTableStyle.tab_header}>Role</TableCell>

              <TableCell className={BasicTableStyle.tab_header}> Department</TableCell>

              <TableCell className={BasicTableStyle.tab_header}>
                {/* {'Estimated Time (in Days)'}  */}
                {"TAT (in Days)"}
                {/* <Box sx={{ width: "100px !important", display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontWeight: "bold" }}>TAT</Typography>
                  <Typography sx={{ fontWeight: "bold", whiteSpace: "pre" }}>(in Days)</Typography>
                </Box> */}
              </TableCell>
              <TableCell className={BasicTableStyle.tab_header}>Primary User</TableCell>
              <TableCell className={BasicTableStyle.tab_header}>Secondary User</TableCell>
              <TableCell className={BasicTableStyle.tab_header}>Escalate To</TableCell>
            </TableRow>
          </TableHead>
          {workflowProcess && workflowProcess?.length !== 0 ? (
            <TableBody>
              {workflowProcess.map((item: any, index: any) => (
                <TableRow key={`${index + 1}-Process_Steps`}>
                  <TableCell
                    key={`${index + 1}-Process_Steps-ProcessName`}
                    sx={{
                      minWidth: "200px",
                      position: "sticky",
                      left: 0,
                      backgroundColor: `${theme.palette.background.default}`,
                      zIndex: 1,
                    }}
                    className={BasicTableStyle.tab_data}
                  >
                    {item.ProcessName}
                  </TableCell>
                  <TableCell
                    key={`${index + 1}-Process_Steps-RoleId`}
                    sx={{ width: "15%", verticalAlign: "top" }}
                    className={BasicTableStyle.tab_data}
                  >
                    <CustomSelect
                      name={"Roles"}
                      valueKey={"RoleId"}
                      optionLabelKey="RoleName"
                      options={roleListData}
                      readOnly={view}
                      required={true}
                      label={""}
                      value={item.RoleId}
                      onChange={(e) => handleInputChange(index, "RoleId", e)}
                      error={Boolean(validationErrors[`${index}-RoleId`])}
                      helperText={validationErrors[`${index}-RoleId`]}
                    />
                  </TableCell>

                  <TableCell sx={{ width: "15%", verticalAlign: "top" }} className={BasicTableStyle.tab_data}>
                    <CustomMultiSelect
                      optionLabelKey="DepartmentName"
                      optionIdKey="DepartmentId"
                      options={departmentListData}
                      // required={true}
                      readOnly={view}
                      label={""}
                      value={item.DepartmentName}
                      onChange={(e) => {
                        handleInputChange(index, "DepartmentName", e.target.value);
                        // handleInputChange(index, "PrimaryUser", [])
                      }}
                      error={Boolean(validationErrors[`${index}-DepartmentName`])}
                      helperText={validationErrors[`${index}-DepartmentName`]}
                    />
                  </TableCell>

                  <TableCell
                    sx={{ width: "100px !important", verticalAlign: "top" }}
                    className={BasicTableStyle.tab_data}
                  >
                    {" "}
                    <Box sx={{ width: "100px !important" }}>
                      <CustomNumberField
                        name={"Attribute Response"}
                        required={true}
                        readOnly={view}
                        isDecimal={true}
                        label={""}
                        value={item.TAT}
                        onChange={(e) => handleInputChange(index, "TAT", e)}
                        error={Boolean(validationErrors[`${index}-TAT`])}
                        helperText={validationErrors[`${index}-TAT`]}
                      />
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: "15%", verticalAlign: "top" }} className={BasicTableStyle.tab_data}>
                    {" "}
                    <CustomMultiSelect
                      optionLabelKey="EmailId"
                      optionIdKey="UserId"
                      options={conditionRoleandDepa(item.RoleId, item.DepartmentName)}
                      required={true}
                      readOnly={view}
                      label={""}
                      value={item.PrimaryUser}
                      onChange={(e) => handleInputChange(index, "PrimaryUser", e.target.value)}
                      error={Boolean(validationErrors[`${index}-PrimaryUser`])}
                      helperText={validationErrors[`${index}-PrimaryUser`]}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "15%", verticalAlign: "top" }} className={BasicTableStyle.tab_data}>
                    <CustomMultiSelect
                      optionLabelKey="EmailId"
                      optionIdKey="UserId"
                      options={conditionRoleandDepa(item.RoleId, item.DepartmentName, item.PrimaryUser)}
                      required={false}
                      readOnly={view}
                      label={""}
                      value={item.SecondaryUser}
                      onChange={(e) => handleInputChange(index, "SecondaryUser", e.target.value)}
                      error={Boolean(validationErrors[`${index}-SecondaryUser`])}
                      helperText={validationErrors[`${index}-SecondaryUser`]}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "15%", verticalAlign: "top" }} className={BasicTableStyle.tab_data}>
                    <CustomMultiSelect
                      optionLabelKey="EmailId"
                      optionIdKey="UserId"
                      options={allUsers}
                      required={false}
                      variant="standard"
                      label={""}
                      readOnly={true}
                      value={item.EscalateTo}
                      onChange={(e) => handleInputChange(index, "EscalateTo", e.target.value)}
                      error={Boolean(validationErrors[`${index}-EscalateTo`])}
                      helperText={validationErrors[`${index}-EscalateTo`]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <>
              <Typography color={"text.primary"} sx={{ m: 2 }}>
                Atleast One Process is Required
              </Typography>
            </>
          )}
        </Table>
      </Box>
    </>
  );
};
