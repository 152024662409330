import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IToaster , IColumnHeaders } from '../../models/authorize.type';
import toasterService from '../services/toasterService';
import moment from 'moment';


export interface IToasterData{
    dataTable: {toaster:IToaster[] , toasterHeader : IColumnHeaders[]};
    isLoading:boolean;   
    isToasterAdded:boolean ;
    isToasterUpdated: boolean;
    isToasterdelete: boolean;
    toastertemplatetoasterMsg: any;
}

export const initialState: IToasterData={
    dataTable: {toaster : [], toasterHeader : []},
    isLoading: false,
    isToasterAdded: false,
    isToasterUpdated: false,
    isToasterdelete: false,
    toastertemplatetoasterMsg: "",
}

export const getToaster = createAsyncThunk(
    'getToaster',
    async (_, { rejectWithValue }) => {
      try {
        const response = await toasterService.getToaster();
        const { Toasters, HeaderJson } = response.data;
        console.log(Toasters)
        return {
          Toaster: Toasters,
          HeaderJson: HeaderJson
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  

export const addToaster = createAsyncThunk('addToaster', async(requesrParams: any, {rejectWithValue})=>{
    try{
        const response= await toasterService.addToaster(requesrParams);        
        return response;
        
    }catch(error){
        return rejectWithValue(error)
    }
})

export const updateToaster = createAsyncThunk('updateToaster', async(requesrParams: any, {rejectWithValue})=>{
    try{
        let reqbody= {...requesrParams}
        console.log("update",requesrParams)
        delete reqbody.registeredDate;
        delete reqbody.EndDate;        
        const response= await toasterService.updateToaster(reqbody);        
        return response;
        
    }catch(error){
        return rejectWithValue(error)
    }
})

export const deleteToaster = createAsyncThunk('deleteToaster', async(requesrParams: any, {rejectWithValue})=>{
    try{
        let reqbody= {...requesrParams}      
        const response= await toasterService.deleteToaster(reqbody);        
        return response;
        
    }catch(error){
        return rejectWithValue(error)
    }
})



export const toasterDataSlice = createSlice({
    name: 'toasterData',
    initialState,
    reducers:{
        updateToasterAddandEdit:(state, action)=>{            
            state.isToasterAdded = false;
            state.isToasterUpdated = false;
            state.isToasterdelete = false;
            state.toastertemplatetoasterMsg = "";
        },
        updateToaster:(state, action)=>{
            let indexVendor= state.dataTable.toaster.findIndex(data=> data.ToasterId === action.payload.ToasterId)
            let copyToasterData= [...state.dataTable.toaster];
            copyToasterData[indexVendor]= action.payload;
            state.dataTable.toaster = copyToasterData;
        },
      
        updateToasterRecords :(state, action)=>{            
            state.dataTable.toaster = [];
        } 
    },
    extraReducers: (builder)=>{
        builder.addCase(getToaster.pending, (state)=>{
            // state.isLoading = true;
        })
        .addCase(getToaster.fulfilled, (state, action: PayloadAction<any>)=>{
          
            state.dataTable.toaster = action.payload.Toaster

            action.payload?.Toaster && action.payload?.Toaster.map((data: IToaster)=>{
                data.IsActive =data.IsActive === 1 ? "Active" : "Inactive"; 
                return data;
           });      
            console.log(action.payload.Toaster)
            state.dataTable.toasterHeader = action.payload.HeaderJson
            state.isLoading = false;
        })
        .addCase(getToaster.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })
        .addCase(addToaster.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(addToaster.fulfilled, (state, action: PayloadAction<any>)=>{           
            state.isToasterAdded = true;
            state.isLoading = false;
        })
        .addCase(addToaster.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })
        .addCase(updateToaster.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(updateToaster.fulfilled, (state, action: PayloadAction<any>)=>{
            state.isToasterUpdated = true;            
            state.isLoading = false;
        })
        .addCase(updateToaster.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        }) .addCase( deleteToaster.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(deleteToaster.fulfilled, (state, action: PayloadAction<any>)=>{
            state.isToasterdelete = true;            
            state.isLoading = false;
            console.log(action.payload , 'toastertemplatetoasterMsg');
            
            state.toastertemplatetoasterMsg = action.payload?.data?.Message;
      
        })
        .addCase(deleteToaster.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false;
        })

       
    }
});

export const {updateToasterAddandEdit,updateToasterRecords } = toasterDataSlice.actions;
 export default toasterDataSlice.reducer;