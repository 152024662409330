import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import {
  IAddApplicationParameterResponse,
  IApplicationParameterResponse,
  IVHColumnRes,
} from "../../models/applicationParameter.type";
import { APP_URL } from "./endpoints/applicationParameter";

const getApplicationParameterList = (requestParams: any): Promise<IApplicationParameterResponse> =>
  axiosInstance
    .get(APP_URL.GetAPP)
    .then((response: AxiosResponse<IApplicationParameterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getApplicationParameterListById = (requestParams: any): Promise<IApplicationParameterResponse> =>
  axiosInstance
    .get(APP_URL.GetAPPById)
    .then((response: AxiosResponse<IApplicationParameterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateApplicationParameter = (requestBody: any): Promise<IAddApplicationParameterResponse> =>
  axiosInstance
    .post(APP_URL.UpdateAPP, requestBody)
    .then((response: AxiosResponse<IAddApplicationParameterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addApplicationParameter = (requestBody: any): Promise<IAddApplicationParameterResponse> =>
  axiosInstance
    .post(APP_URL.AddAPP, requestBody)
    .then((response: AxiosResponse<IAddApplicationParameterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteApplicationParameter = (requestBody: any): Promise<IAddApplicationParameterResponse> =>
  axiosInstance
    .post(APP_URL.DeleteAPP, requestBody)
    .then((response: AxiosResponse<IAddApplicationParameterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getApplicationParameterHistory = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(APP_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const APService = {
  getApplicationParameterList,
  getApplicationParameterListById,
  updateApplicationParameter,
  addApplicationParameter,
  deleteApplicationParameter,
  getApplicationParameterHistory,
};

export default APService;
