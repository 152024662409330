import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ThirdPartyService from "../services/thirdPartyService";
import { IThirdParty, IVHThirdPartyColumn } from "../../models/thirdParty.type";
import { chkDate } from "../../utils/constants";
export interface IThirdPartyData {
  thirdPartyData: any;
  isLoading: boolean;
  isThirdPartyAdded: boolean;
  isThirdPartyUpdated: boolean;
  versionHistory: { ThirdPartyRecords: IThirdParty[]; ThirdPartyVHeader: IVHThirdPartyColumn[] };
  isThirdPartytHistory: boolean;
  isThirdPartyDeleted: boolean;
  ToasterMsg: string;
}

export const initialState: IThirdPartyData = {
  thirdPartyData: [],
  isLoading: false,
  isThirdPartyAdded: false,
  isThirdPartyUpdated: false,
  versionHistory: { ThirdPartyRecords: [], ThirdPartyVHeader: [] },
  isThirdPartytHistory: false,
  isThirdPartyDeleted: false,
  ToasterMsg: "added ThirdParty data successfully",
};

export const getThirdPartyList = createAsyncThunk(
  "getThirdPartyList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await ThirdPartyService.getThirdPartyList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addThirdParty = createAsyncThunk("addThirdParty", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await ThirdPartyService.addThirdParty(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateThirdParty = createAsyncThunk(
  "updateThirdParty",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await ThirdPartyService.updateThirdParty(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteThirdParty = createAsyncThunk(
  "deleteThirdParty",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await ThirdPartyService.deleteThirdParty(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getThirdPartyHistory = createAsyncThunk(
  "getThirdPartyHistory",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await ThirdPartyService.getVersionHistoryThirdParty(reqbody);
      const { ThirdPartyMaster, HeaderJson } = response.data;
      return { ThirdPartyRecords: ThirdPartyMaster, HeaderJson: HeaderJson.ColumnHeaders };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ThirdPartyDataSlice = createSlice({
  name: "thirdPartyMaster",
  initialState,
  reducers: {
    updateThirdPartyAddandEdit: (state, action) => {
      state.isThirdPartyAdded = false;
      state.isThirdPartyUpdated = false;
      state.isThirdPartyDeleted = false;
      state.ToasterMsg = "";
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.thirdPartyData.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.thirdPartyData];
        copyVendorData[indexVendor] = action.payload;
        state.thirdPartyData = copyVendorData;
      }
    },
    updateVersionHistory: (state, action) => {
      state.versionHistory.ThirdPartyRecords = [];
      state.versionHistory.ThirdPartyVHeader = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThirdPartyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getThirdPartyList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");

        state.thirdPartyData = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          return user;
        });
        state.isLoading = false;
      })
      .addCase(getThirdPartyList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addThirdParty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addThirdParty.fulfilled, (state, action: PayloadAction<any>) => {
        // state.thirdPartyData = [...state.thirdPartyData , ...action.payload  ]
        state.isThirdPartyAdded = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
        // state.ToasterMsg = " Third Party Added Sucessfully ";
      })
      .addCase(addThirdParty.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateThirdParty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateThirdParty.fulfilled, (state, action: PayloadAction<any>) => {
        state.isThirdPartyUpdated = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
        // state.ToasterMsg = ' Third Party Updated Sucessfully ';
      })
      .addCase(updateThirdParty.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteThirdParty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteThirdParty.fulfilled, (state, action: PayloadAction<any>) => {
        state.isThirdPartyDeleted = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
        // state.ToasterMsg = ' Third Party Deleted Sucessfully ';
      })
      .addCase(deleteThirdParty.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getThirdPartyHistory.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getThirdPartyHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.ThirdPartyVHeader = action.payload.HeaderJson;
        state.versionHistory.ThirdPartyRecords = action.payload.ThirdPartyRecords.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.Approved_On = chkDate(user.Approved_On);

          return user;
        });;
        state.isLoading = false;
        // state.ToasterMsg = "ThirdParty data deleted successfully";
      })
      .addCase(getThirdPartyHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});

export const { updateThirdPartyAddandEdit, updateUser, updateVersionHistory } = ThirdPartyDataSlice.actions;
export default ThirdPartyDataSlice.reducer;
