import { Box, Grid, IconButton, List, ListItem, ListItemText, Tooltip, Typography, useTheme } from "@mui/material";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import ProgressBar from "../../common/progressBar/progressBar";
import DeleteIcon from "@mui/icons-material/Delete"; // Import delete icon
import CustomSvg from "../../common/CustomSvg";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { updateToaster } from "../../store/slices/loader";

interface IRecordSearchProps {
  Question: any;
  setQuestion: any;
  maxWidth?: any;
  rowData?: any;
  categoryData?: any;
  columns?: any;
  dialogTitle?: any;
}

export const RecordSearch: React.FC<IRecordSearchProps> = ({
  Question,
  setQuestion,
  maxWidth,
  rowData,
  categoryData,
  columns,
  dialogTitle
}) => {
  const theme = useTheme();
  const [questionCategories, setQuestionCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [parentQuestions, setParentQuestions] = useState<any[]>([]);
  const { userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  // const { selectedData } = useSelector((state: RootState) => state.selectedData);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(true);
  const dispatch = useAppDispatchThunk();
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setQuestionCategories(categoryData);
      } catch (error) {
        console.error("Failed to fetch categories", error);
      }
    };
    fetchCategories();
  }, []);
  useEffect(() => {
    if (Question) {
      setIsDialogVisible(true);
    }
  }, [Question]);

  const handleLoadClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      const selected = questionCategories.find((cat) => cat.id === selectedCategory);
      setParentQuestions(selected ? selected.questions : []);
      setIsLoading(false);
    }, 1000);
  };

  const handleRowDoubleClick = (row: any) => {
    setSelectedQuestions((prevSelectedQuestions) => {
      const selectedIds = new Set(prevSelectedQuestions.map((r) => r.id));
      if (selectedIds.has(row.id)) {
        return prevSelectedQuestions;
      }
      return [...prevSelectedQuestions, row];
    });
    onClose();
    dispatch(updateToaster({ isToaster: true, toasterMsg: toasterMsg }));
  };

  const handleRemoveQuestion = (id: number) => {
    setSelectedQuestions((prevSelectedQuestions) => prevSelectedQuestions.filter((question) => question.id !== id));
  };

  const handleRemoveAll = () => {
    setSelectedQuestions([]);
  };
  const onClose = () => {
    setQuestion(null);
  };
  const handleSetSelectedQuestions = useCallback((questions: any[]) => {
    setSelectedQuestions(questions);
    
  }, []);
  // console.log(selectedData, selectedQuestions, "selectedData");
  const toasterMsg = `selected ${
    selectedQuestions && selectedQuestions.length > 1 ? "Questions" : "Question"
  } are added in the grid`;
  const searchRecord = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Grid container spacing={2}>
          <Grid item sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", gap: 2 }}>
            {questionCategories.length > 0 && (
              <CustomSelect
                label="Question Category"
                name="Question Category"
                valueKey="id"
                optionLabelKey="category"
                options={questionCategories}
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e)}
              />
            )}
          </Grid>
          <Grid item>
            <CustomButton
              variant="contained"
              disabled={!selectedCategory}
              name="Load"
              onClick={handleLoadClick}
              size="small"
            />
          </Grid>
          <Grid item sx={{ display: selectedQuestions.length >= 1 ? "block" : "none" }}>
            <CustomButton
              variant="contained"
              name={selectedQuestions.length <= 1 ? "Add Question" : "Add Questions"}
              onClick={() => {
                console.log(selectedQuestions, "selectedQuestions");
                onClose();
                dispatch(updateToaster({ isToaster: true, toasterMsg: toasterMsg }));
              }}
              size="small"
            />
          </Grid>
        </Grid>

        {false && selectedQuestions && selectedQuestions.length > 0 && (
          <Grid container>
            <Grid
              item
              sm={12}
              md={maxWidth === "xs" || maxWidth === "sm" ? 8 : 12}
              lg={maxWidth === "xs" || maxWidth === "sm" ? 8 : 12}
              xl={maxWidth === "xs" || maxWidth === "sm" ? 8 : 12}
              sx={{ display: "flex", flexDirection: "column", gap: 1 }}
            >
              <Typography variant="h6">Selected Questions:</Typography>
              <Box sx={{ maxWidth: "150px" }}>
                {selectedQuestions && selectedQuestions.length > 0 && (
                  <CustomButton variant="contained" name="Remove All" onClick={handleRemoveAll} size="small" />
                )}
              </Box>
              <List>
                {selectedQuestions.map((question, index) => (
                  <ListItem
                    key={question.id}
                    secondaryAction={
                      <Tooltip title="Remove">
                        <IconButton edge="end" aria-label="remove" onClick={() => handleRemoveQuestion(question.id)}>
                          <CustomSvg
                            color={`${theme.palette.text.secondary}`}
                            size={"20px"}
                            name={"delete"}
                            sx={{ ml: "3px", mt: "1px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <ListItemText primary={`${index + 1}. ${question.question}`} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        )}

        {isLoading ? (
          <Box sx={{ p: 4 }}>
            <ProgressBar showProgress={isLoading} />
          </Box>
        ) : (
          parentQuestions.length > 0 && (
            <Box>
              <MaterialDynamicGrid
                data={columns}
                rows={parentQuestions}
                handleAction={() => {}}
                enableZoomIcon
                handleAdd={() => {}}
                showhistory={false}
                disableselect={false}
                isDraftView={false}
                showDelete={false}
                showView={false}
                showEdit={false}
                showExports={false}
                pageName="Record Search"
                appendData={rowData}
                onReturnFunc={onClose}
                userId={userLoginResponse?.UserId || 0}
                onRowDoubleClick={handleRowDoubleClick}
                isRecordSearch={true}
                setSelectedQuestions={handleSetSelectedQuestions}
              />
            </Box>
          )
        )}
      </Box>
    );
  };
  return (
    <CustomDialog
      show={isDialogVisible}
      onHide={() => {
        setIsDialogVisible(false);
        setQuestion(null);
      }}
      maxWidth={maxWidth || "md"}
      header={dialogTitle || "Add Question"}
      contentNode={Question ? searchRecord() : null}
    />
  );
};
