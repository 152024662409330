import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton } from "@mui/material";
import { PermissionColumn } from "../../utils/constants";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import RemarkSectionApproval from "./RemarkSection";
import CustomSvg from "../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import BasicTableStyle from "../../common/dynamicDataGrid/basicTable.module.scss";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import serviceStyle from "../serviceCatalog/serviceCatalog.module.scss";

interface basicTableProps {
  selectedGridRecord: any;
  selectedRecordDetails: any;
  setSelectedRecordDetails?: any;
  closePopUp: any;
  view?: boolean;
  edit?: boolean;
}

const ServiceCatalogApproveRejectPendingRecord: React.FC<basicTableProps> = ({
  selectedGridRecord,
  selectedRecordDetails,
  setSelectedRecordDetails,
  closePopUp,
  view,
  edit,
}) => {
  const [oldValues, setOldValues] = useState<any>("");
  const [oldValuesRisk, setOldValuesRisk] = useState<any>([]);
  const [oldValuesRiskTable, setOldValuesRiskTable] = useState<any>([]);
  const [newValues, setNewValues] = useState<any>("");
  const [newValuesRisk, setNewValuesRisk] = useState<any>([]);
  const [newValuesRiskTable, setNewValuesRiskTable] = useState<any>([]);
  const [header, setHeader] = useState<any>([]);
  const theme = useTheme();
  const [openUserMenu, setOpenUserMenu] = useState<boolean>(false);
  const [menuCategory, setMenuCategory] = useState<any>(null);

  const [openQuestionFroPara, setopenQuestionFroPara] = useState<any>(null);
  const [comFieldIdselect, setComFieldIdselect] = useState<any>([]);


  const dispatch = useAppDispatchThunk();
  const { makerCheckerStatus, isApprovedorReject, isApprovedorRejectFailed } = useSelector(
    (state: RootState) => state.pendingApproval
  );
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (isApprovedorReject) {
      setLoader(false);
      closePopUp && closePopUp();
    } else if (isApprovedorRejectFailed) {
      setLoader(false);
    }
  }, [isApprovedorReject, isApprovedorRejectFailed]);

  const UserDisplayColumn = [
    {
      Field: "Service Name",
      FieldType: "string",
      DisplayName: "Service Name",
    },
    {
      Field: "Service Type",
      FieldType: "string",
      DisplayName: "Service Type",
    },
    {
      Field: "Service Group",
      FieldType: "string",
      DisplayName: "Service Group",
    },
    {
      Field: "Service Description",
      FieldType: "string",
      DisplayName: "Service Description",
    },
    {
      Field: "Engage",
      FieldType: "string",
      DisplayName: "Engage",
    },
    {
      Field: "Service Owner",
      FieldType: "string",
      DisplayName: "Service Owner",
    },
    {
      Field: "Sub Department",
      FieldType: "string",
      DisplayName: "Sub Department",
    },
    {
      Field: "Remark",
      FieldType: "string",
      DisplayName: "Remark",
    },
    {
      Field: "ApplicableRisk",
      FieldType: "string",
      DisplayName: "ApplicableRisk",
    }, 
    {
      Field: "Service Criticality",
      FieldType: "string",
      DisplayName: "Service Criticality",
    },
    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "IsActive",
    },
    {
      Field: "Modified By",
      FieldType: "string",
      DisplayName: "Modified By",
    },
    {
      Field: "selectedTableRisk",
      FieldType: "image",
      DisplayName: "Risk Scoring",
    }
  ];

  useEffect(() => {
    if (selectedRecordDetails) {
      if (selectedRecordDetails?.NewValues) {
        const newHeader: any = [{ DisplayName: "Fields" }, { DisplayName: "New Values" }];

        if (selectedRecordDetails && Object.keys(selectedRecordDetails?.OldValues).length > 0) {
          newHeader.push({ DisplayName: "Old Values" });
        }
        setHeader(newHeader);
      }
      if (selectedRecordDetails && Object.keys(selectedRecordDetails?.OldValues).length > 0) {
        setOldValues(selectedRecordDetails?.OldValues);
        const risk = selectedRecordDetails?.OldValues?.selectedRisk
        setOldValuesRisk(risk ? risk : []);
        const riskTable = selectedRecordDetails?.OldValues?.selectedTableRisk
        setOldValuesRiskTable(riskTable ? riskTable : []);
      }
      if (selectedRecordDetails?.NewValues) {
        setNewValues(selectedRecordDetails?.NewValues);
        const risk = selectedRecordDetails?.NewValues?.selectedRisk
        setNewValuesRisk(risk ? risk : []);
        const riskTable = selectedRecordDetails?.NewValues?.selectedTableRisk
        setNewValuesRiskTable(riskTable ? riskTable : []);
      }
    }
  }, [selectedRecordDetails]);
  
  const openQuestions = (index: any) => {
    setopenQuestionFroPara((prev: any) => (prev == index ? null : index));
  };

  return (
    <>
      <Stack spacing={3}>
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            User Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {UserDisplayColumn.map((col: any) => (
                <TableRow>
                  <TableCell
                    key={`${col.DisplayName}-column Section company Master approval`}
                    sx={{
                      textAlign: "left",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                  {newValues ? (
                    <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",
                        border: "2px solid #f1f1f1",
                      }}
                    >
                      {col.FieldType !== "image" ? newValues[col.Field] : newValues[col.Field] && (
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              setMenuCategory('new')
                              setOpenUserMenu(true)
                            }}
                          >
                            <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  ) : null}
                  {oldValues ? (
                    <TableCell
                      key={`${col.DisplayName}-column Section company Master approval`}
                      sx={{
                        textAlign: "left",
                        border: "2px solid #f1f1f1",
                      }}
                    >
                      {col.FieldType !== "image" ? oldValues[col.Field] : oldValues[col.Field] && (
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              setMenuCategory('old')
                              setOpenUserMenu(true)
                            }}
                          >
                            <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box>
          <RemarkSectionApproval selectedGridRecord={selectedGridRecord} closePopUp={closePopUp} view={view} />
        </Box>
      </Stack>

      <CustomDialog
        show={openUserMenu}
        onHide={() => {setOpenUserMenu(false);setMenuCategory('')}}
        maxWidth={"md"}
        header={"Risk Scoring"}
        contentNode={
          <Box className={BasicTableStyle.tab_outer} sx={{ width: "100%", mt: 2 }}>
          <Table className={BasicTableStyle.tab_outer} sx={{ maxWidth: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={BasicTableStyle.tab_header} width={"15%"}>
                  Parameter
                </TableCell>
                {/* <TableCell className={BasicTableStyle.tab_header} width={'15%'} >Question</TableCell> */}
                {newValuesRisk.map((item:any) => (
                  <TableCell className={BasicTableStyle.tab_header} align="center">
                    {item.RiskCategory}
                  </TableCell>
                ))}
                <TableCell className={BasicTableStyle.tab_header} align="center" width={"100px"}>
                  {" "}
                  SUM of MAX
                </TableCell>
                <TableCell className={BasicTableStyle.tab_header} align="center" width={"100px"}>
                  {" "}
                  MAX of MAX
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newValuesRiskTable.map((item:any, index:any) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell
                        sx={{ verticalAlign: "top" }}
                        className={BasicTableStyle.tab_data}
                        align={"left"}
                        width={"25%"}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {" "}
                          <IconButton onClick={() => openQuestions(index)}>
                            {openQuestionFroPara === index ? (
                              <CustomSvg size={"20px"} name={"chevron-down"} />
                            ) : (
                              <CustomSvg size={"20px"} name={"chevron-right"} />
                            )}
                          </IconButton>
                          <Box sx={{ ml: 1 }}>
                            <CustomCheckBox
                              value={comFieldIdselect.includes(item?.FieldId)}
                              // onChange={() => ClickedRowChecked(item?.FieldId)}
                            />
                          </Box>
                          {/**Create a Controller */}
                            {item.Parameter}
                        </Box>
                      </TableCell>

                      {newValuesRisk.map((R_item:any, R_index:any) => {
                        const maxScoreItem = item.MaxScoring.find(
                          (maxitem: any) => R_item.RiskCategory_Id === maxitem.RiskCategory_Id
                        );
                        const Score = maxScoreItem ? maxScoreItem.Score : 0;
                        return (
                          <TableCell
                            className={BasicTableStyle.tab_data}
                            key={`${index}-${"maxval"}-${R_index}`}
                            align="center"
                          >
                            {Score}
                          </TableCell>
                        );
                      })}
                      <TableCell className={BasicTableStyle.tab_data} align="center">
                        {" "}
                        {item.Sum}{" "}
                      </TableCell>
                      <TableCell className={BasicTableStyle.tab_data} align="center">
                        {" "}
                        {item.Max}{" "}
                      </TableCell>
                    </TableRow>
                    {item?.Questions &&
                      item?.Questions.map((Q_item: any, qIndex: any) =>
                        openQuestionFroPara == index ? (
                          <TableRow
                            className={serviceStyle.questionRow}
                            key={`${index}-${qIndex}-question for service catalog`}
                          >
                            <TableCell
                              className={BasicTableStyle.tab_data}
                              align={"left"}
                              width={"25%"}
                              sx={{ paddingLeft: "40px !important", verticalAlign: "top" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "15px !important",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <CustomCheckBox
                                    value={comFieldIdselect.includes(Q_item?.FieldId)}
                                    // onChange={() => ClickedRowChecked(Q_item?.FieldId)}
                                  />
                                </Box>
                                  {Q_item.Question}
                              </Box>
                            </TableCell>
                            {newValuesRisk.map((R_item:any, R_index:any) => (
                              <TableCell
                                sx={{ verticalAlign: "top" }}
                                className={BasicTableStyle.tab_data}
                                key={`${index}-${qIndex}-${R_index}`}
                                align="center"
                              >
                                 { Q_item.Risks[R_index]?.Score}
                              </TableCell>
                            ))}
                            <TableCell className={BasicTableStyle.tab_data} align="center"></TableCell>
                            <TableCell className={BasicTableStyle.tab_data} align="center"></TableCell>
                          </TableRow>
                        ) : null
                      )}
                  </React.Fragment>
                );
              })}

              {/* <TableRow>
                <TableCell className={BasicTableStyle.tab_data}>FINAL SCORE - MAX OF MAX</TableCell>

                {newValuesRisk.map((R_item, R_index) => {
                  const maxScoreItem = selectedRiskMaxofMax.find(
                    (maxitem: any) => R_item.RiskCategory_Id === maxitem.RiskCategory_Id
                  );

                  const MaxLastScore = maxScoreItem ? maxScoreItem.Score : 0;
                  return (
                    <TableCell
                      className={BasicTableStyle.tab_data}
                      key={`${1}-${"maxval"}-${R_index}`}
                      align="center"
                    >
                      {MaxLastScore}
                    </TableCell>
                  );
                })}
                <TableCell className={BasicTableStyle.tab_data} align="center" width={"40px"}>
                  {scoreMainSum}
                </TableCell>
                <TableCell className={BasicTableStyle.tab_data} align="center" width={"40px"}>
                  {scoreMainMax}
                </TableCell>
              </TableRow>

              (
                <TableRow>
                  <TableCell
                    // colSpan={selectedRisk.length + 1}
                    className={BasicTableStyle.tab_data}
                    align="center"
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box>Service Criticality</Box>
                      <Box sx={{ marginTop: "-4px" }}>
                        <CommonTooltip
                          arrow={true}
                          placement={"top-start"}
                          content={toolTipData()}
                          width={"400px"}
                        />
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell
                    colSpan={newValuesRisk.length + 2}
                    className={BasicTableStyle.tab_data}
                    align="center"
                    width={"40px"}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <CustomRadioButton
                          list={[
                            { value: "SUM OF MAX", lable: "SUM OF MAX" },
                            { value: "MAX OF MAX", lable: "MAX OF MAX" },
                          ]}
                          infoList={[
                            { value: "Sum of all the parameter`s score", label: "SUM OF MAX" },
                            { value: "Maximum of all the parameter`s score", label: "MAX OF MAX" },
                          ]}
                          arrow={true}
                          placement="top-end"
                          isInfo={true}
                          style={{ marginRight: 0 }}
                          label=""
                          value={selectedRiskMaxorSum}
                          onChange={(val) => {
                            setselectedRiskMaxorSum(val);
                            calculateMatrics(val);
                            setonchangeMaking(false);
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: criticalityColor,
                          padding: "2px 8px",
                          borderRadius: "16px",
                          height: "24px",
                          maxWidth: "110px",
                          color: "white !important",
                          mt: "-5px",
                        }}
                      >
                        {serviceCritical}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )  */}
            </TableBody>
          </Table>
          </Box>
        } />
    </>
  );
};

export default ServiceCatalogApproveRejectPendingRecord;
