import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react"
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import { Controller } from "react-hook-form";
import CustomFileUploader from "../../../../common/dynamicFileUploader/CustomFileUploader";
import { RootState, useAppDispatchThunk } from "../../../../store/store";
import { UploadAFile } from "../../../../store/slices/fileHandler";
import CustomSvg from "../../../../common/CustomSvg";
import { useTheme } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import fileHandlerService from "../../../../store/services/fileHandlerService";
import CustomNumberField from "../../../../common/dynamicInputs/CustomNumberField";
import { LovConfig } from "../../../../config/config";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import CustomMultiSelect from "../../../../common/dynamicInputs/CustomMultiSelect";
import CustomDatePicker from "../../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";

export const BasicDetails: React.FC<any> = ({ edit, view, control, errorsVendorDetailsform, fetchDetails }) => {
  const dispatch = useAppDispatchThunk();
  const theme = useTheme();
  const [image, setImage] = useState<any>();
  const { setValue, watch, getValues } = useFormContext()
  const [vendorTypeI, setVendorTypeI] = useState<any[]>([]);
  const [vendorTypeII, setVendorTypeII] = useState<any[]>([]);
  const [SubIndustryData, setSubIndustryData] = useState<any[]>([]);
  const [intialRendering, setIntialRendering] = useState(true);
  const [vendorStatus, setVendorStatus] = useState<any[]>([]);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { industryTable } = useSelector((state: RootState) => state.indutryMasterData);

  const fetchData = async () => {
    const { data } = await fileHandlerService.DownLoadAFile({ fileUrl: logoUrl });
    if (data) {
      const blob = "data:image/jpeg;base64," + data;
      setImage(blob);
    }
  };

  const logoUrl = watch("Logo");

  useEffect(() => {
    setVendorTypeI(lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeI) || []);
    setVendorTypeII(lovTable.filter((item: any) => item.Name === LovConfig.VendorTypeII) || []);
    setVendorStatus(lovTable.filter((item: any) => item.Name === LovConfig.VendorStatus) || []);
  }, [lovTable]);


  useEffect(() => {
    if (logoUrl) fetchData();
  }, [logoUrl]);

  const handleSubmit = async (uploadedFile: File | null) => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("fileUploadRequest", uploadedFile);
      const getUploadUrl = await dispatch(UploadAFile(formData));
      setValue('Logo', getUploadUrl.payload)
    }
  };

  const getSubIndustry = (id: number): void => {
    const filterdSubIndustry = industryTable.filter((item: any) => item.Parent_Industry_Id === id)
    setSubIndustryData(filterdSubIndustry)
    if (intialRendering) setIntialRendering(false)
    else setValue('SubIndustry_Id', [])
  };

  const formValues = getValues()


  useEffect(() => {
    if (formValues.Industry_Id && industryTable?.length) getSubIndustry(formValues.Industry_Id)
  }, [formValues.Industry_Id, JSON.stringify(industryTable)])

  const urlRegex = new RegExp(
    "^(https?:\\/\\/)?" +             // Protocol (http or https)
    "((www\\.)?)" +                 // Optional www.
    "([a-zA-Z0-9-]+\\.)?" +         // Optional subdomain
    "([a-zA-Z0-9-]{1,63})" +        // Second-level domain, no leading or trailing hyphens
    "\\." +                         // Dot before TLD
    "([a-zA-Z]{2,6})" +             // TLD between 2 and 6 characters
    "(\\:[0-9]{1,5})?" +            // Optional port
    "(\\/[^\\s]*)?$"                // Optional path
  );

  // Function to test a URL
  function isValidURL(url: any) {
    return urlRegex.test(url);
  }

  return (
    <>
      <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>

        <Grid container spacing={2}>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Type_Of_Vendor2"
              control={control}
              defaultValue={''}
              rules={{ required: "Third Party Type is required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"Type of Vendor - II"}
                  valueKey="LovId"
                  optionLabelKey="Options"
                  options={vendorTypeII}
                  readOnly={view}
                  required={true}
                  label={"Third Party Type"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.Type_Of_Vendor2)}
                  helperText={errorsVendorDetailsform.Type_Of_Vendor2?.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Legal_Name"
              control={control}
              defaultValue={""}
              rules={{
                required: "Third Party Name is required",
                validate: {
                  noLeadingSpaces: (value: string | undefined) => {
                    if (value === undefined) return "Value is required";
                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                  },
                  noSpecialCharacters: (value: string | undefined) => {
                    if (value === undefined) return "Value is required";
                    return !/[^a-zA-Z0-9\-\. ]/.test(value) || "Special Characters are not allowed";
                  },
                  maxLength: (value) => {
                    if (value === undefined) return "Value is required";
                    return value.length <= 100 || "Maximum 100 characters allowed";
                  }
                }
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"Legal Name"}
                  required={true}
                  readOnly={view}
                  label={"Third Party Name"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.Legal_Name)}
                  helperText={
                    errorsVendorDetailsform.Legal_Name?.message?.toString()
                  }
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Brand_Name"
              control={control}
              defaultValue={""}
              rules={{
                validate: {
                  noLeadingSpaces: (value: string | undefined) => {
                    if (!value) return;
                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                  },
                  noSpecialCharacters: (value: string | undefined) => {
                    if (!value) return;
                    return !/[^a-zA-Z0-9\-\. ]/.test(value) || "Special Characters are not allowed";
                  },
                  maxLength: (value) => {
                    if (!value) return;
                    return value.length <= 100 || "Maximum 100 characters allowed";
                  }
                }
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"Brand Name"}
                  readOnly={view}
                  label={"Third Party Brand Name"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.Brand_Name)}
                  helperText={
                    errorsVendorDetailsform.Brand_Name?.message?.toString()
                  }
                />
              )}
            />
          </Grid>


          {/* </Grid>

    <Grid container spacing={2} sx={{ mt: 1}}> */}
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="WebSite"
              control={control}
              defaultValue={""}
              rules={{
                // required: "WebSite is required",
                validate: {
                  maxLength: (value) => {
                    if (!value) return;
                    return value.trim().length < 100 || "Maximum 100 characters allowed, please use shorter URL";
                  },
                  validate: (value) => {
                    if (!value) return;
                    return isValidURL(value) || "Invalid URL format";
                  }
                },
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"WebSite"}
                  readOnly={view}
                  label={"Third Party website"}
                  required={false}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.WebSite)}
                  helperText={
                    errorsVendorDetailsform.WebSite?.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Contact_Number"
              control={control}
              defaultValue={""}
              rules={{
                required: "Contact Number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Contact number must be 10 digits",
                }
              }}
              render={({ field }) => (
                <CustomNumberField
                  name={"Contact No"}
                  required={true}
                  readOnly={view}
                  label={"Registered Contact Number"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.Contact_Number)}
                  helperText={
                    errorsVendorDetailsform.Contact_Number?.message?.toString()
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="EmailId"
              control={control}
              defaultValue={""}
              rules={{
                required: "Email Id is required",
                validate: {
                  noLeadingSpaces: (value: string | undefined) => {
                    if (value === undefined) return "Value is required";
                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                  },
                  isValidEmail: (value: string | undefined) => {
                    if (value === undefined) return "Value is required";
                    return /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,5}$/.test(value) || "Invalid Email id";
                  },
                  maxLength: (value) => {
                    if (value === undefined) return "Value is required";
                    return value.length <= 100 || "Maximum 100 characters allowed";
                  }
                }
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"Email Id"}
                  required={true}
                  readOnly={view}
                  label={"Registered Email Id"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.EmailId)}
                  helperText={
                    errorsVendorDetailsform.EmailId?.message?.toString()
                  }
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Group_Name"
              control={control}
              defaultValue={""}
              rules={{
                // required: "Group Name is required",
                validate: {
                  noLeadingSpaces: (value: string | undefined) => {
                    if (value === undefined) return "Value is required";
                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                  },
                  noSpecialCharacters: (value: string | undefined) => {
                    if (value === undefined) return "Value is required";
                    return !/[^a-zA-Z0-9\-\. ]/.test(value) || "Special Characters are not allowed";
                  },
                  maxLength: (value) => {
                    if (value === undefined) return "Value is required";
                    return value.length <= 100 || "Maximum 100 characters allowed";
                  }
                }
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"Group Name"}
                  // required={true}
                  readOnly={view}
                  label={"Third Party Group Name"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.Group_Name)}
                  helperText={
                    errorsVendorDetailsform.Group_Name?.message?.toString()
                  }
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Type_Of_Vendor1"
              control={control}
              defaultValue={''}
              rules={{ required: "Alliance Type is required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"Type of Vendor - I"}
                  valueKey="LovId"
                  optionLabelKey="Options"
                  options={vendorTypeI}
                  readOnly={view}
                  required={true}
                  label={"Alliance Type"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.Type_Of_Vendor1)}
                  helperText={errorsVendorDetailsform.Type_Of_Vendor1?.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Incorporated_Date"
              control={control}
              defaultValue={null}
              rules={!view ? { required: "Incorporated in year is Required" } : {}}
              render={({ field }) => (
                <CustomDatePicker
                  minDate={dayjs().subtract(100, "year").toDate()}
                  maxDate={dayjs().toDate()}
                  required={!view}
                  label={"Incorporated in year (YYYY-MM-DD)"}
                  value={field.value}
                  readOnly={view}
                  onChange={(e) => { field.onChange(e); }}
                  error={Boolean(errorsVendorDetailsform.Incorporated_Date)}
                  helperText={errorsVendorDetailsform.Incorporated_Date?.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Headquater_Country"
              control={control}
              defaultValue={""}
              rules={{
                // required: "Third Party Headquater Country is required",
                validate: {
                  noLeadingSpaces: (value: string | undefined) => {
                    if (!value) return;
                    return !/^\s/.test(value) || "Leading spaces are not allowed";
                  },
                  noSpecialCharacters: (value: string | undefined) => {
                    if (!value) return;
                    return !/[^a-zA-Z\-\. ]/.test(value) || "Special Characters are not allowed";
                  },
                  maxLength: (value) => {
                    if (!value) return;
                    return value.length <= 100 || "Maximum 100 characters allowed";
                  }
                }
              }}
              render={({ field }) => (
                <CustomTextField
                  name={"Headquater_Country"}
                  // required={true}
                  readOnly={view}
                  label={"Third Party headquarter Country"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.Headquater_Country)}
                  helperText={
                    errorsVendorDetailsform.Headquater_Country?.message?.toString()
                  }
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="Industry_Id"
              control={control}
              defaultValue={''}
              rules={{ required: "Industry is required" }}
              render={({ field }) => (
                <CustomSelect
                  name={"Industry_Id"}
                  valueKey={"IndustryId"}
                  optionLabelKey="IndustryName"
                  options={industryTable.filter((item: any) => item.IndustryTypeValues === "Industry")}
                  readOnly={view}
                  required={true}
                  label={"Industry"}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    getSubIndustry(e);
                  }}
                  error={Boolean(errorsVendorDetailsform.Industry_Id)}
                  helperText={errorsVendorDetailsform.Industry_Id?.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Controller
              name="SubIndustry_Id"
              control={control}
              defaultValue={[]}
              rules={{
                required: "Sub Industry is required",
                validate: (value) =>
                  (Array.isArray(value) && value.filter((item) => item !== undefined).length > 0) ||
                  "Sub Industry is required",
              }
              }
              render={({ field }) => (
                <CustomMultiSelect
                  optionLabelKey={`IndustryName`}
                  optionIdKey={`IndustryId`}
                  options={SubIndustryData}
                  readOnly={view}
                  required={true}
                  label={"Sub Industry"}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errorsVendorDetailsform.SubIndustry_Id)}
                  helperText={errorsVendorDetailsform?.SubIndustry_Id?.message?.toString()}
                />
              )}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography variant="subtitle1" color={"text.primary"}>
              <Controller
                name="Status"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    name="Status"
                    valueKey="LovId"
                    optionLabelKey="Options"
                    options={vendorStatus}
                    readOnly={true}
                    label={"Status"}
                    value={field.value}
                  />
                )}
              />
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <Box sx={{ width: '100%' }}>

              {!image && (
                <Box>
                  <Controller
                    name="Logo"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <CustomFileUploader
                        onFileUpload={(value) => {
                          handleSubmit(value?.[0] || null)
                        }}
                        maxFileSize={1048576}
                        title="Brand Logo"
                        readOnly={view}
                        acceptFormats={[".png", ".jpeg", '.jpg']}

                      />
                    )}
                  />
                </Box>
              )}
              <Box sx={{ position: "relative" }}>
                {image && (
                  <Box sx={{ width: "100%", maxHeight: "150px" }}>
                    <img
                      src={image}
                      style={{ width: "150px", height: "auto", objectFit: "contain" }}
                      alt={"CompanyLogo"}
                    />
                  </Box>
                )}
                {image && !view && (
                  <IconButton
                    onClick={() => {
                      setImage(null);
                      setValue("Logo", null);
                      // setIsImageChanged(true);
                      // setIsImageDeleted(true);
                      // fetchData();
                    }}
                    sx={{
                      position: "absolute",
                      top: 7,
                      left: 7,
                      background: "#d3d3d3",
                      color: "white",
                      borderRadius: "3px",
                      padding: "2px",

                      cursor: "pointer",
                      zIndex: 1,
                      "&:hover": {
                        background: "#bdbdbd",
                      },
                    }}
                  >
                    <CustomSvg
                      color={`${theme.palette.text.secondary}`}
                      size={"20px"}
                      name={"delete"}
                      sx={{ ml: "3px", mt: "1px" }}
                    />
                  </IconButton>
                )}
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Box>
    </>)
}