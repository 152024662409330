import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch } from "../../../../store/store";
import { addVendorSignOff, getVendorSignOffbyId } from "../../../../store/slices/vendorSignOff";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomBreadcrumbs from "../../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { LovConfig, serviceCriticality } from "../../../../config/config";
import { getLovList } from "../../../../store/slices/lovMasterData";
import { VOBProcessPreviews } from "../../vendorOnBoardingProcessPreview/VOBProcessPreview";
import ModalPopUp from "../../../../common/modalComponent/ModalPopUp";
import { DownLoadAFile } from "../../../../store/slices/fileHandler";
import { VendorHistory } from "../../../vendorHistory/VendorHistory";
import CustomStepper from "../../../../common/dynamicSteper/CustomStepper";
import { VendorDetailsCXOsignoff } from "./VendorDetailsCXOsignoff";
import { VendorAssessmentSummeryCXOsignoff } from "./VendorAssessmentCXOSignoff";
import VendorAssessmentService from "../../../../store/services/vendorAssessmentService";
import { calculate_thresholdanddeviation_forVendorAssesment } from "../../VendorOnBoardingPreview/FinalAssesmentScoring";
import rolesAndResponsibilityService from "../../../../store/services/rolesAndResponsibilityService";
import vendorDetailsService from "../../../../store/services/vendorDetails";

interface VendorOnboardingSignOffProps {
  vendor_Id: any;
  onClose: () => void;
  currentProcessData: any;
}

export const VendorOnboardingSignOffcxo: React.FC<VendorOnboardingSignOffProps> = ({
  vendor_Id,
  onClose,
  currentProcessData,
}) => {
  const dispatch = useAppDispatch();
  const { themeMode } = useSelector((state: RootState) => state.menu);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { VendorSignOffdataTable } = useSelector((state: RootState) => state.vendorSignOff);
  const currentDate = dayjs().format("DD-MMM-YYYY");
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [signoffData, setSignoffData] = useState<any>({});
  const [remark, setRemark] = useState("");
  const [remarkerror, setErrorRemark] = useState<any>("");
  const [currentStatus, setcurrentStatus] = useState("signoff");
  const [VendorStatus, setVendorStatus] = useState<any>([]);
  const [previewsection, setpreviewsection] = useState<any>(false);
  const [isConform, setisConform] = useState<any>(false);
  const [submitstatus, setsubmitstatus] = useState<any>("");
  const [logo, setLogo] = useState<string>("");
  const [mappingAssessmentDetails, setAQMMappingDetails] = useState<any>("");
  const [calculatedAssessmentDetails, setcalculatedAssessmentDetails] = useState<any>("");
  const [ProjectDetails, setProjectDetails] = useState<any>("");
  const [VendorDetails, setVendorDetails] = useState<any>("");
  const [threshold, setthreshold] = useState<any>(100);
  const [step, setstep] = useState([
    "Vendor Details",
    "Vendor Assessment Summery",
  ]);
  const [activestepCount, setactivestepCount] = useState(0);
  const isBase64Image = (image: any) => {
    return (
      image &&
      (image.startsWith("data:image/jpeg;base64,") ||
        image.startsWith("data:image/png;base64,") ||
        image.startsWith("data:image/jpg;base64,"))
    );
  };
  const handleImageDisplay = async (imageUrl: string): Promise<string | null> => {
    if (imageUrl && !isBase64Image(imageUrl)) {
      const resultAction = await dispatch(DownLoadAFile({ fileUrl: imageUrl }));
      if (DownLoadAFile.fulfilled.match(resultAction)) {
        const payloadData = resultAction.payload;
        if (payloadData && payloadData.length > 0) {
          const blob = "data:image/jpeg;base64," + payloadData;
          setLogo(blob);
          return blob;
        }
      }
    } else if (isBase64Image(imageUrl)) {
      return imageUrl;
    }
    return null;
  };
  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    setVendorStatus(lovTable.filter((item: any) => item.Name === LovConfig.VendorStatus) || []);
  }, [lovTable]);

  useEffect(() => {

    const callAQMMappingandassessmentDetails = async (venId: any ,ProjId : any) => {
    try {
      const res : any = await VendorAssessmentService.getAllQuestionByVendorandProjectId({ Vendor_Id: Number(venId) , Project_Detail_Id : ProjId });
        
      setAQMMappingDetails(JSON.parse(res.data?.CategoryQuestions));
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  }
  if (   currentProcessData && currentProcessData?.ProjectId && currentProcessData?.VendorId) {
    callAQMMappingandassessmentDetails(currentProcessData?.VendorId , currentProcessData?.ProjectId);
  
  }
  },[ currentProcessData ])

  useEffect(() => {
    const callProjecttDetails = async (ProjId : any) => {
      try {
        const res = await rolesAndResponsibilityService.getProjectDetail({ Project_Detail_Id : ProjId });
        console.log(res.data, "Project Details");
  
        setProjectDetails(res.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    }
    if (vendor_Id &&  currentProcessData && currentProcessData?.ProjectId) {
      callProjecttDetails(currentProcessData?.ProjectId);
      console.log(currentProcessData , 'currentProcessData');
      
    }
  },[currentProcessData])

  useEffect(() => {
    const callVendorDetails = async (venId: any) => {
      try {
        const res : any = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });
                setVendorDetails(res.data);
               
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };

    if (vendor_Id) {
      callVendorDetails(vendor_Id);
    }
    console.log(vendor_Id, "vendordetailsId");
  }, [vendor_Id]);

  useEffect(() => {
    if (mappingAssessmentDetails && threshold) {
      let returnValues: any = calculate_thresholdanddeviation_forVendorAssesment(mappingAssessmentDetails, threshold);
      setcalculatedAssessmentDetails(returnValues);
     
    }
  }, [mappingAssessmentDetails, threshold]);
  useEffect(() => {
    if (currentProcessData) {
      dispatch(
        getVendorSignOffbyId({
          vendor_Id: currentProcessData?.VendorId,
          project_Detail_Id: currentProcessData?.ProjectId,
        })
      );
    }
  }, [currentProcessData]);
  useEffect(() => {
    if (VendorSignOffdataTable.Vendor_Id === vendor_Id) {
      setSignoffData(VendorSignOffdataTable);
      if (VendorSignOffdataTable?.Logo) {
        handleImageDisplay(VendorSignOffdataTable?.Logo);
      }
    }
  }, [VendorSignOffdataTable]);

  const closeFormValue = () => {
    if (onClose) {
      onClose();
    }
  };

  const conformTheSignoff = (val: any) => {
    if (remark) {
      setsubmitstatus(val);
      setisConform(true);
    } else {
      setErrorRemark("Remarks is required");
    }
  };

  const closeConformationPopup = () => {
    setsubmitstatus("");
    setisConform(false);
  };

  const submitvendorSignOff = () => {
    const val = submitstatus;
    if (remark) {
      setErrorRemark("");
      let findedStatus =
        VendorStatus.find((item: any) => item?.Options == (val == 1 ? "Active" : "Terminated"))?.LovId || 0;

      let obj: any = {
        ModuleName: currentProcessData?.FormName,
        Activity: val == 1 ? "Create" : "Rejected",
        UserId: userLoginResponse?.UserId,
        CreatedBy: userLoginResponse?.UserEmail,
        MenuId: currentPagePermissionData?.MenuId,
        FormId: currentProcessData?.ProcessId,
        Vendor_Id: currentProcessData?.VendorId,
        Project_Detail_Id: currentProcessData ? currentProcessData?.ProjectId : 0,
        IsRenewal: currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false,
        Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
        Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
        Remarks: remark,
        Status: findedStatus,
      };

      dispatch(addVendorSignOff(obj));
    } else {
      setErrorRemark("Remarks is required");
    }
  };
  const getCriticalityColor = (criticality: any) => {
    switch (criticality) {
      case serviceCriticality.material:
        return "#F44336"; // Red
      case serviceCriticality.low_critical:
        return "#4CAF50"; // Green
      case serviceCriticality.critical:
        return "#FFC107"; // Amber
      default:
        return "#939393";
    }
  };

  const getdeviationColor = (criticality: any) => {
    switch (criticality) {
      case serviceCriticality.material:
        return "#F44336"; // Red
      case serviceCriticality.low_critical:
        return "#4CAF50"; // Green
      case serviceCriticality.critical:
        return "#FFC107"; // Amber
      default:
        return "#939393";
    }
  };

  const criticalityColor = getCriticalityColor(signoffData?.Service_Criticality);

  return (
    <>
      {!previewsection ? (
        <>
        
       


                  <Box>
          <Stack spacing={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <CustomBreadcrumbs
                list={[
                  { value: 0, name: "Pending Actions" },
                  { value: 1, name: `Sign off` },
                ]}
                onchange={closeFormValue}
              />

              {currentProcessData && (
                <Box sx={{width: "20", }}>
                <VendorHistory isEditData={currentProcessData} />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                {`${currentProcessData?.Vendor_Name}`}
              </Typography>
            </Box>
            <Box sx={{display:'flex' , alignItems:'center' , justifyContent:'flex-start' , width:'30%'}}>
            <CustomStepper activeStep={activestepCount} steps={step} />
            </Box>
          

{  activestepCount === 0 && (
  <VendorDetailsCXOsignoff 
  ProjectDetails={ProjectDetails}
  VendorDetails={VendorDetails}
  />
)}

{ activestepCount === 1 && (
  <VendorAssessmentSummeryCXOsignoff calculatedAssessmentDetails={calculatedAssessmentDetails} setpreviewsection={setpreviewsection}/>
)}
                   

           
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <CustomMultilineTextField
                            label={"Remarks"}
                            value={remark}
                            rows={4}
                            required={true}
                            onChange={(e) => {
                              setRemark(e);
                              if (e) {
                                setErrorRemark("");
                              } else {
                                setErrorRemark("Remark is required");
                              }
                            }}
                            error={Boolean(remarkerror)}
                            helperText={remarkerror}
                          />
                        </Grid>
               
                  <Box sx={{ display: "flex" , justifyContent: "space-between", mt: 4 }}>
                        <>
                        <Box sx={{ display: "flex", gap: 2}}>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Approved By : `}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {userLoginResponse?.UserName} {" - ("} {userLoginResponse?.UserRole}
                              {")"}
                            </Typography>
                          </Box>
                      
                   
                          <Box sx={{ display: "flex"  }}>
                            <Typography sx={{ textAlign: "left" }} color={"text.primary"}>
                              {`Dated On : `}
                            </Typography>
                            <Typography sx={{ fontWeight: 600, textAlign: "left" }} color={"text.primary"}>
                              {currentDate}
                            </Typography>
                          </Box>
                          </Box>
                        
                        </>
                     
                      <Box sx={{ display: "flex", gap: 2}}>
                        <CustomButton
                          type="submit"
                          variant="outlined"
                          
                          name={activestepCount === 0 ? "Vendor Assessment Summery" : "Vendor Details"}
                          onClick={() => {
                           if(activestepCount === 0){
                            setactivestepCount(1)
                           }else{
                            setactivestepCount(0)
                           }
                          }}
                        />
                    

                    {currentStatus === "signoff" && (
                      <>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          color="success"
                          name={"Sign Off"}
                          onClick={() => conformTheSignoff(1)}
                        />
                        <CustomButton
                          type="submit"
                          variant="contained"
                          color="error"
                          name={"Terminate"}
                          onClick={() => conformTheSignoff(0)}
                        />
                      </>
                    )}
                  </Box>
                  </Box>
      
          </Stack>
        
        </Box>

        <ModalPopUp
            onDeleteMethod={() => submitvendorSignOff()}
            isDelete={true}
            onHide={() => closeConformationPopup()}
            show={isConform}
            ActionName={"Complete"}
            header={"Confirm"}
            text={`Are you sure you want to ${submitstatus === 1 ? "Sign off" : "Terminate"}  the Vendor ?`}
          />
        </>

        
      ) : (
        <VOBProcessPreviews
          vendor_Id={vendor_Id}
          currentProcessData={currentProcessData}
          onClose={(e: any) => {
            if (e === 0) {
              closeFormValue();
            } else if (e === 1) {
              setpreviewsection(false);
            }
          }}
        />
      )}
    </>
  );
};
