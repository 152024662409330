import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { IMenu } from "../../models/loggin.type";
import AccordianTable from "./index";
import { getMetricsMasterList } from "../../store/slices/metricsMasters";
import MetricsMasterContainer from "./DataContent/MetricsMasterContainer";

export const MetricsMaster: React.FC<any> = () => {
  const [permissionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const { currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);
  const dispatch = useAppDispatchThunk();
  useEffect(() => {
    dispatch(getMetricsMasterList({}));
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",

              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {permissionPage && permissionPage.MenuName}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                sx={{
                  height: "auto",
                  width: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <Box sx={{ width: "100%", mt: "0px" }}>
                  <AccordianTable />
                  {/* <MetricsMasterContainer /> */}
                </Box>
              </Grid>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
