import React, { memo, useMemo, useRef } from "react";
import {
  MRT_ColumnDef,
  MRT_TableHeadCellFilterContainer,
  MRT_TablePagination,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomCheckBox from "../dynamicInputs/CustomCheckBox";
interface columns {
  Field: string;
  FieldType: any;
  DisplayName: string;
  width?: number;
}

interface MessageProps {
  data: {
    columns: columns[];
    table_name: string;
  };
}
interface DataGridTableProps {
  data: MessageProps["data"];
  rows: any[];
  handleAction: any;
  view?: boolean;
}

const MaterialDynamicRolesGrid = (props: DataGridTableProps) => (
  //App.tsx or AppProviders file

  <>
    {props.rows.length > 0 ? (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialDynamicRolesGridd data={props.data} rows={props.rows} handleAction={props.handleAction} view={props.view}/>
      </LocalizationProvider>
    ) : null}
  </>
);

const MaterialDynamicRolesGridd = memo((props: DataGridTableProps) => {
  const { data } = props;

  const [open, setOpen] = React.useState(false);
  //const [open_export, setOpen_export] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
console.log(props.view, "props.view");

  const columns = useMemo<MRT_ColumnDef<any, any>[]>(() => {
    return data.columns.map((column) => {
      if (column.FieldType === "string") {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          size: column.width || 100,
        };
      } else if (column.FieldType === "number") {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          size: column.width || 200,
          Cell: ({ row }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {row.original.SubMenus && row.original.SubMenus.length > 0 ? null : (
                <CustomCheckBox
                  isStyle={false}
                  readOnly={props.view}
                  alignItems="center"
                  onChange={(e: any) => props.handleAction(row.original, column.Field, e)}
                  value={row.original[column.Field] === 1 ? true : false}
                  disabled={row.original['disabled'] }
                />
              )}
            </Box>
          ),
        };
      } else if (column.FieldType === "select") {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          size: column.width || 100,
          Cell: ({ row }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {!row.original.SubMenus || row.original.SubMenus.length === 0 ? null : (
                <CustomCheckBox
                  alignItems="center"
                  readOnly={props.view}
                  onChange={(e: any) => props.handleAction(row.original, column.Field, e)}
                  value={row.original[column.Field] === 1 ? true : false}
                  disabled={row.original['disabled']}
                />
              )}
            </Box>
          ),
        };
      } else {
        return {
          accessorKey: column.Field,
          header: column.DisplayName,
          size: column.width || 100,
        };
      }
    });
  }, [data.columns]);

  const table = useMaterialReactTable({
    columns,
    data: props.rows,
    enableExpanding: true,
    getSubRows: (originalRow) => originalRow.SubMenus,
    enablePagination: false,
    enableTopToolbar: false,
    muiCircularProgressProps: {
      color: "secondary",
      thickness: 5,
      size: 55,
    },
    // muiPaginationProps: {
    //   color: "primary",
    //   rowsPerPageOptions: [25, 50, 100, 250],
    //   variant: "outlined",
    // },
    muiSkeletonProps: {
      animation: "pulse",
      height: 28,
    },
    muiTablePaperProps:{
      elevation: 0,
      sx: {
        // border: "1px solid rgb(235,235,235)",
      }
    },
    enableBottomToolbar:false,
    enableColumnFilterModes: false,
    paginationDisplayMode: "pages",
    enableStickyHeader: true,
    enableColumnActions: false,
    enableMultiSort: false,
    enableSorting:false,
    enableDensityToggle: false,
    // positionToolbarAlertBanner: "bottom",

    // muiTableContainerProps: { sx: { maxHeight: "500px" } },

    muiTableProps: {
      sx: {
        border: "1px solid rgb(235,235,235)",
        caption: {
          captionSide: "top",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid rgb(235,235,235)",
        height: "50px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid rgb(235,235,235)",
      },
    },

    muiTableBodyRowProps: {
      sx: {
        height: "50px",
      },
    },

    // renderBottomToolbar: ({ table }) => (
    //   <Box
    //     sx={{
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "space-between",
    //       px: 1,
    //       flexDirection: "column",
    //       width: "100%",
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "flex-end",
    //         width: "100%",
    //       }}
    //     >
    //       <MRT_TablePagination table={table} />
    //     </Box>
    //   </Box>
    // ),
    // renderTopToolbar: () => (
    //   <Box
    //     sx={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //       p: 1,
    //     }}
    //   ></Box>
    // ),
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialReactTable table={table} />
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          // dividers={scroll === 'paper'}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Filter
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ width: "500px" }}>
                {table.getLeafHeaders().map((header) => (
                  <Box sx={{ mt: 1 }}>
                    <MRT_TableHeadCellFilterContainer key={header.id} header={header} table={table} in />
                  </Box>
                ))}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </>
  )
});

export default MaterialDynamicRolesGrid;
