import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { addWorkingDays, getDateOrConvertSameTime, textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import { Applicable_For, AQMResponder, AssessmentCategoryConfig, LovConfig, ToasterActivityConfig, VOBWorflowPages } from "../../../config/config";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import dayjs from "dayjs";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import BasicTableStyle from "../../../common/dynamicDataGrid/basicTable.module.scss";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { getAQMList, updateAQMAddandEdit } from "../../../store/slices/assessmentQuestionnaireMaster";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import { addAQMMapping, getAQMMappingByVendorID, getAQMMappingList } from "../../../store/slices/assesmentQuestionnaireMapping";
import { getVendorDetailsList } from "../../../store/slices/vendorDetails";
import { getWorkList } from "../../../store/slices/workFlowSlice";
import workFlowService from "../../../store/services/workFlowService";
import { getLovList } from "../../../store/slices/lovMasterData";
import { getUsersList } from "../../../store/slices/userData";
import { useTheme } from "@mui/material/styles";
import vendorDetailsService from "../../../store/services/vendorDetails";
import { getAssessmentCategoryList } from "../../../store/slices/assessmentCategoryMasterData";
import { AddPreviewAssessmentQuestionnaireMaster } from "../../master/assessmentQuestionnaireMaster/AddPreviewAssesmentQuestionnaire";
import { VendorHistory } from "../../vendorHistory/VendorHistory";
import { getHolidayList } from "../../../store/slices/holidayMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";

export interface AQMMappingProps {
  view?: boolean;
  isViewOnly?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  prePageName?: any;
  ModuleId?: number | undefined;
  currentProcessData?: any;
}

interface FormData {
  Assessment_Questionaire_Mapping_Id?: any;
  Assessment_Number?: any;
  Vendor_Id?: any;
  Vendor_Name?: any;
  Validity_in_Months?: any;
  Assessment_Type_1?: any;
  Assessment_Type_2?: any;
  WorkFlow_Id?: any;
  Workflow_Name?: any;
  Start_Date?: any;
  Target_Date?: any;
  Category?: any;
  Service_type?: any;
  IsQuestionWeightage?: any;
  IsWeightageegual?: any;
}
export const AQMMapping: React.FC<AQMMappingProps> = ({
  isViewOnly,
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  ModuleId,
  prePageName,
  currentProcessData,
}) => {
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const theme = useTheme();
  const { themeMode } = useSelector((state: RootState) => state.menu);
  const { isUserAdded, allUsers, isUserUpdated } = useSelector((state: RootState) => state.userData);
  const { dataTable: assessmentCategoryMasterData } = useSelector((state: RootState) => state.assessmentCategoryMasterData);
  const { AQMMappingdataTable, AQMMappingByVendordata } = useSelector((state: RootState) => state.aQMMappingData);
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { HolidayTable } = useSelector((state: RootState) => state.HolidayMasterData);
  const { AQMdataTable, isAQMAdded } = useSelector((state: RootState) => state.aQMData);
  const dispatch = useAppDispatch();
  const currentDate = new Date();
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [assesmentTypeILV, setassesmentTypeILV] = useState<any[]>([]);
  const [assesmentTypeIILV, setassesmentTypeIILV] = useState<any[]>([]);
  const [CategoryDataLV, setCategoryDataLV] = useState<any[]>([]);

  const [selectedCatagoryMapping, setselectedCatagoryMapping] = useState<any[]>([]);
  const [VendorDetails, setVendorDetails] = useState<any>(undefined);
  const [totalwtg, settotalwtg] = useState<number>(0);
  const [isQuestionselectEnable, setIsQuestionselectEnable] = useState<boolean>(false);
  const [MapCatagory, setMapCatagory] = useState<any>("");
  const [MapCatagoryII, setMapCatagoryII] = useState<any>([]);
  const [MapCatagoryIII, setMapCatagoryIII] = useState<any>([]);
  const [mappingUseQuestions, setmappingUseQuestions] = useState<any[]>([]);
  const [mappingUseExistQuestions, setExistUseQuestions] = useState<any[]>([]);
  const [aqmListData, setaqmListData] = useState<any[]>([]);
  const [WorkflowProcessInstance, setWorkflowProcessInstance] = useState<any[]>([]);
  const [allUserList, setallUserList] = useState<any[]>([]);
  const [finalError, setfinalError] = useState<boolean>(false);
  const [validationErrors, setvalidationErrors] = useState<any>({});
  const [smeResponseStep, setSmeResponseStep] = useState<any>("");
  const [smeReviewStep, setSmeReviewStep] = useState<any>("");
  const [smeResponseStepuser, setSmeResponseStepuser] = useState<any>([]);
  const [smeReviewStepuser, setSmeReviewStepuser] = useState<any>([]);
  const [ProjectTAT, setTAT] = useState<any>(1);
  const [considerScoreList, setconsiderScoreList] = useState<any[]>([{ id: 0, Option: "N/A" }]);
  const [considerScoreListM, setconsiderScoreListM] = useState<any[]>([
    { id: 0, Option: "No" },
    { id: 1, Option: "Yes" },
  ]);
  const [HolidayData, setHolidayData] = useState<any[]>([]);
  const [AddNewQuestionOpen, setAddNewQuestionOpen] = useState<boolean>(false);
  const [importQuestionOpen, setImportQuestionOpen] = useState<boolean>(false);
  const [importedAQM, setImportedAQM] = useState<any>(null);
  const [aqmMappings, setAqmMappings] = useState<any>([]);
  const [AssessmentService, setAssessmentService] = useState<any>([]);
  const [IsCancel, setIsCancel] = useState<boolean>(false);
  const [IsPopupCancel, setIsPopupCancel] = useState<boolean>(false);

  let formData = {
    Assessment_Questionaire_Mapping_Id: isEditData ? isEditData?.Assessment_Questionaire_Mapping_Id : 0,
    Assessment_Number: isEditData ? isEditData?.Assessment_Number : "",
    Vendor_Id: isEditData ? isEditData?.Vendor_Id : "",
    Vendor_Name: isEditData ? isEditData?.Vendor_Name : "",
    Validity_in_Months: isEditData ? isEditData?.Validity_in_Months : 12,
    Assessment_Type_1: isEditData ? isEditData?.Assessment_Type_1 : "",
    Assessment_Type_2: isEditData ? isEditData?.Assessment_Type_2 : "",
    WorkFlow_Id: isEditData ? isEditData?.WorkFlow_Id : "",
    Workflow_Name: isEditData ? isEditData?.WorkFlow_Name : "",
    Start_Date: isEditData ? (isEditData?.Start_Date ? dayjs(isEditData?.Start_Date) : null) : null,
    Target_Date: isEditData ? (isEditData?.Target_Date ? dayjs(isEditData?.Target_Date) : null) : null,
    Category: isEditData ? (isEditData?.Category ? JSON.parse(isEditData?.Category) : []) : [],
    Service_type: isEditData ? (isEditData?.Service_type ? JSON.parse(isEditData?.Service_type) : []) : [],
    IsQuestionWeightage: isEditData ? (isEditData?.IsQuestionWeightage ? true : false) : false,
    IsWeightageegual: isEditData ? (isEditData?.IsWeightageegual ? true : false) : true,
  };

  const {
    handleSubmit: handleAQMMappingForm,
    reset,
    setValue,
    formState: { errors: errorsAQMMappingform, isDirty },
    control,
    clearErrors,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {

    dispatch(getServiceCatalogList());

  }, []);


  useEffect(() => {
    if (isEditData && isEditData?.CategoryQuestions) {
      try {
        let seleQuestionEdit: any = isEditData?.CategoryQuestions ? JSON.parse(isEditData?.CategoryQuestions) : [];
        // console.log(seleQuestionEdit, "seleQuestionEdit");

        setselectedCatagoryMapping(seleQuestionEdit);
      } catch { }
    }
  }, [isEditData]);

  useEffect(() => {
    if (allUsers) {
      setallUserList(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    const filtered = AQMMappingdataTable.filter((item: any) => item.Assessment_Type_1 === watch("Assessment_Type_1") && item.Assessment_Type_2 === watch("Assessment_Type_2"))
    const manipulatedAQMOptions = filtered.map((item: any) => {
      return { 'label': `${item.Vendor_Name} - ${item.Assessment_Number}`, 'value': item.Assessment_Questionaire_Mapping_Id }
    })
    setAqmMappings(manipulatedAQMOptions)
    // console.log(manipulatedAQMOptions, watch("Assessment_Type_1"), watch("Assessment_Type_2"), 'Assessment_Type');

  }, [AQMMappingdataTable, watch("Assessment_Type_1"), watch("Assessment_Type_2")]);

  useEffect(() => {
    setaqmListData(AQMdataTable.filter((item: any) => item.IsActive === "Active") || []);
  }, [AQMdataTable]);

  useEffect(() => {

    dispatch(getAQMMappingByVendorID({ VendorID: currentProcessData?.VendorId }));

    const fetchWorkflowInstance = async () => {
      if (currentProcessData?.WorkflowId) {

        try {
          const fetchData = await workFlowService.getWorkflowInstanceById({
            workflowId: currentProcessData?.WorkflowId,
            InstanceId: currentProcessData?.Workflow_InstanceId,
          });

          const responseData = fetchData?.data;

          if (responseData?.Id) {
            // WorkflowProcessInstanceAsJson
            setTAT(responseData?.TAT);
            try {
              let WorkflowProcessInstanceAsJson = JSON.parse(responseData?.WorkflowProcessInstanceAsJson);
              // console.log(WorkflowProcessInstanceAsJson, "WorkflowProcessInstanceAsJson");
              setWorkflowProcessInstance(WorkflowProcessInstanceAsJson);
              let smeReviewValue = WorkflowProcessInstanceAsJson.find(
                (smeReviewitem: any) => smeReviewitem?.FormName === VOBWorflowPages?.SMEReview
              );
              let smeResponseValue = WorkflowProcessInstanceAsJson.find(
                (smeResponseitem: any) => smeResponseitem?.FormName === VOBWorflowPages?.SMEResponse
              );
              if (smeResponseValue) {
                setSmeResponseStep(smeResponseValue);
              }
              if (smeReviewValue) {
                setSmeReviewStep(smeReviewValue);
              }
              console.log(smeResponseValue, "smeResponseValue", smeReviewValue);
            } catch {
              console.log("workflow instance Parse Failed");
            }
          }
        } catch (error) {
          console.error("Error fetching workflow instance:", error);
        }
      }
    };

    if (currentProcessData && !view) {
      setValue("Vendor_Id", currentProcessData?.VendorId);
      setValue("Vendor_Name", currentProcessData?.Vendor_Name);
      setValue("Start_Date", dayjs(currentProcessData?.Workflow_Start_Date));
      setValue("Target_Date", dayjs(currentProcessData?.Workflow_End_Date));
      setValue("WorkFlow_Id", currentProcessData?.WorkflowId);
      setValue("Workflow_Name", currentProcessData?.Workflow_Name);
      setValue("Assessment_Number", currentProcessData?.Assessment_Number);
      fetchWorkflowInstance();
    }
  }, [currentProcessData]);

  useEffect(() => {

    const VendorserviceIds = VendorDetails?.VendorServiceMappings ? VendorDetails?.VendorServiceMappings?.map((item: any) => item?.ServiceCatalogId) : [];

    const existingAQMserviceCatalogIds = AQMMappingByVendordata?.map((item: any) => {
      const targetDate = new Date(item?.Target_Date);
      const currentDate = new Date();
      return item?.ServiceName != null && targetDate > currentDate ? (JSON.parse(item?.ServiceName)) : []
    });

    const mergedUniqueIds = Array.from(new Set(existingAQMserviceCatalogIds?.flat()));

    console.log(VendorserviceIds, mergedUniqueIds, "mergedUniqueIds");

    let AssesmentserviceIDs: any = [];

    if (mergedUniqueIds?.length > 0) {
      AssesmentserviceIDs = VendorserviceIds.filter((id: any) => mergedUniqueIds.includes(id));
    } else {
      AssesmentserviceIDs = [...VendorserviceIds];
    }

    let FilterSelectedServiceData = ActiveServiceCatlogdata?.filter((item) =>
      AssesmentserviceIDs?.includes(item?.ServiceCatalogId)
    );
    setAssessmentService(FilterSelectedServiceData);
    console.log(AssesmentserviceIDs, FilterSelectedServiceData, "FilterSelectedServiceData");

  }, [AQMMappingByVendordata, VendorDetails, ActiveServiceCatlogdata]);

  useEffect(() => {
    if (assesmentTypeIILV && assesmentTypeIILV.length > 0 && currentProcessData && !view) {
      // console.log(assesmentTypeIILV, "assesmentTypeIILV");
      if (currentProcessData?.IsRenewal === true) {
        let findAssessmentIIRenewal =
          assesmentTypeIILV.find((item) => item.Options == Applicable_For.Renewal)?.LovId || "";
        setValue("Assessment_Type_2", findAssessmentIIRenewal);
      } else {
        let findAssessmentIIFirst =
          assesmentTypeIILV.find((item) => item.Options == Applicable_For.First_Assessment)?.LovId || "";
        setValue("Assessment_Type_2", findAssessmentIIFirst);
      }
    }
  }, [currentProcessData, assesmentTypeIILV]);

  useEffect(() => {
    if (VendorDetails && VendorDetails.VendorDetails && VendorDetails?.VendorDetails?.Assessment_TypeId) {
      setValue("Assessment_Type_1", VendorDetails?.VendorDetails?.Assessment_TypeId);
    }
  }, [currentProcessData, VendorDetails]);

  useEffect(() => {
    dispatch(getAssessmentCategoryList({ FormID: 40 }))
    dispatch(getAQMList({ FormID: 15 }));
    dispatch(getAQMMappingList({ FormID: 15 }));
    // dispatch(getVendorDetailsList());
    // dispatch(getWorkList())
    dispatch(
      getUsersList({
        FormID: 2,
      })
    );
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getHolidayList({
        FormID: 2,
      })
    );
  }, []);

  useEffect(() => {
    const callVendorDetails = async (venId: any) => {
      try {
        const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });

        setVendorDetails(res.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };

    if (currentProcessData && currentProcessData?.VendorId) {
      callVendorDetails(currentProcessData?.VendorId);
    }
    // console.log(currentProcessData, "currentProcessData");
  }, [currentProcessData]);

  useEffect(() => {
    setassesmentTypeILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_Type) || []);
    setassesmentTypeIILV(lovTable.filter((item: any) => item.Name === LovConfig?.Assessment_TypeII) || []);
    // setCategoryDataLV(lovTable.filter((item: any) => item.Name === LovConfig?.AQ_Catagory) || []);
  }, [lovTable]);

  useEffect(() => {
    setCategoryDataLV(assessmentCategoryMasterData.filter((item: any) => item.AQ_Category_Type_Value === AssessmentCategoryConfig?.Category && item.IsActive === "Active") || []);
    // console.log(assessmentCategoryMasterData, 'assessmentCategoryMasterData');

  }, [assessmentCategoryMasterData]);

  useEffect(() => {
    if (HolidayTable && HolidayTable.length > 0) {
      const ActiveHolidayList = HolidayTable.filter((item: any) => item.IsActive === "Active");
      // console.log(ActiveHolidayList, "ActiveHolidayList");

      setHolidayData(ActiveHolidayList);
    }
  }, [HolidayTable]);

  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      onClose();
      if (isDirty) {
        // setchangeMaking(true);
      } else {
        onClose();
      }
    }
  };

  const handleStartDateChange = (date: any) => {
    const endDate = addWorkingDays(date, ProjectTAT, HolidayData);
    setValue("Target_Date", dayjs(endDate));
  };

  function distributeScoreEvenly(count: number, weightage?: number): number[] {
    const baseScore = weightage ? weightage : 100;
    const baseValue = Math.floor(baseScore / count);
    const remainder = baseScore % count;
    const scores = Array(count).fill(baseValue);
    for (let i = 0; i < remainder; i++) {
      scores[i]++;
    }

    return scores;
  }

  const againChangeQuestions = () => {
    setselectedCatagoryMapping((prevState) => {
      const newState = [...prevState];

      let updatedVal: any = newState.map((item: any) => {
        return {
          ...item,
          Questions: [],
        };
      });
      return updatedVal;
    });
  };

  const setSelectedCatagory = (data: any) => {

    let findedCatagory = CategoryDataLV.filter((item) => data.includes(item.AQ_Category_Id)) || [];
    // console.log(findedCatagory, "findedCatagory");

    let countofCatagory = findedCatagory.length;
    let eachTot = 100 / countofCatagory;
    const distributedScores: any = distributeScoreEvenly(countofCatagory);
    setselectedCatagoryMapping((prevState) => {
      const newState = [...prevState];
      let updatedvalue = data.map((item: any, index: any) => {
        let findedCatagoryItem = newState.find((olditem) => olditem.Catagory_Id == item);
        if (findedCatagoryItem) {
          return { ...findedCatagoryItem, Cat_Wtg: distributedScores[index] };
        } else {
          let findedNewCatagoryItem = CategoryDataLV.find((newitem) => newitem.AQ_Category_Id == item);

          return {
            Catagory_Id: findedNewCatagoryItem.AQ_Category_Id,
            Options: findedNewCatagoryItem.AQ_Category_Name,
            Cat_Wtg: distributedScores[index],
            Questions: [],
          };
        }
      });
      return updatedvalue;
    });
    let totwtg = eachTot * countofCatagory;
    settotalwtg(totwtg);

  };

  const catagorywtgonChange = (value: any, index: number) => {
    setselectedCatagoryMapping((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        Cat_Wtg: value,
        onChange: true,
      };
      let errors: any = validateCatagoryField(index, "Category", newState[index]);
      setvalidationErrors((prevErrors: any) => ({

        ...errors
      }));
      // setvalidationErrors(errors);
      return newState;
    });

    syncTotal();
  };

  const catagoryQuestionwtgonChange = (value: any, index: number, Qindex: any, option?: boolean) => {
    setselectedCatagoryMapping((prevState) => {
      const newState = [...prevState];
      const updatedQuestions = [...newState[index].Questions];
      updatedQuestions[Qindex] = { ...updatedQuestions[Qindex], Ques_Wtg: value };
      if (option) {
        let errors: any = validateQuestionField(index, Qindex, newState[index], updatedQuestions[Qindex]);
        // setvalidationErrors(errors);
        setvalidationErrors((prevErrors: any) => ({

          ...errors   // Add or override with new errors
        }));
      }
      newState[index] = { ...newState[index], onChange: true, Questions: updatedQuestions };

      return newState;
    });
    syncTotal();
  };

  const validateCatagoryField = (index: number, field: string, value: any, oldvalidationErrors?: any) => {
    let errors: { [key: string]: string } = oldvalidationErrors ? { ...oldvalidationErrors } : { ...validationErrors };
    let thisCatagory: any = value;
    let thisCatagoryWtg: any = Number(value.Cat_Wtg);
    let thisCatagoryQues: any = value.Questions || [];

    if (thisCatagoryWtg === "" || thisCatagoryWtg < 0) {
      errors[`${index}-Category`] = "Invalid Category Weightage";
    } else if (thisCatagoryWtg == 0) {
      let finalValue = thisCatagoryQues.filter(
        (item: any) => item.Answer_Type == true && item.Consider_for_Scoring == 1
      );
      if (finalValue.length > 0) {
        errors[`${index}-Category`] = "Minimum Weightage is Required";
      } else {
        if (value && value?.Cat_Wtg == "") {
          errors[`${index}-Category`] = "All Free text category questions must have a weightage of 0.";
        } else {
          delete errors[`${index}-Category`];
        }
      }
    } else {
      delete errors[`${index}-Category`];
    }

    if (thisCatagoryQues.length === 0) {
      errors[`${index}-CategoryQuestion`] = "Atleast One Question is Consider Selected category";
    } else if (thisCatagoryWtg) {
      let allSum = 0;
      thisCatagoryQues.map((item: any) => {
        if (item.Answer_Type) {
          allSum += Number(item.Ques_Wtg);
        }
      });
      // console.log(thisCatagoryWtg, allSum, "allSum");

      if (allSum !== thisCatagoryWtg && !watch("IsQuestionWeightage")) {
        errors[
          `${index}-CategoryQuestion`
        ] = `The sum of the weightage of all the Question should be equal to this Catagory weightage`;
      } else {
        delete errors[`${index}-CategoryQuestion`];
      }
    } else {
      delete errors[`${index}-CategoryQuestion`];
    }


    return errors;
  };

  const validateQuestionField = (index: number, Q_index: number, thisCatagory: any, value: any, oldvalidationErrors?: any) => {
    let errors: { [key: string]: string } = oldvalidationErrors ? { ...oldvalidationErrors } : { ...validationErrors };
    let thisCatagoryQues: any = value || {};
    let wtg: any = Number(value.Ques_Wtg) || 0;
    let thisCatagoryWtg: any = Number(thisCatagory.Cat_Wtg);
    let thisCatagoryQuesAll: any = thisCatagory.Questions || [];

    if (thisCatagoryQues && thisCatagoryQues.Consider_for_Scoring == 1 && thisCatagoryQues.Answer_Type == true) {
      if (!wtg || wtg <= 0) {
        errors[`${index}-${Q_index}-Question`] = "Minimum Weightage is Required";
      } else {
        delete errors[`${index}-${Q_index}-Question`];
      }
    } else if (thisCatagoryQues.Consider_for_Scoring == 0 && wtg > 0 && thisCatagoryQues.Answer_Type == true) {
      errors[`${index}-${Q_index}-Question`] = "This Question Not Consider for Scoring so can not give weightage";
    } else {
      delete errors[`${index}-${Q_index}-Question`];
    }
    if (thisCatagoryWtg) {
      let allSum = 0;
      thisCatagoryQuesAll.map((item: any) => {
        if (item.Answer_Type) {
          allSum += Number(item.Ques_Wtg);
        }
      });
      if (allSum !== thisCatagoryWtg && !watch("IsQuestionWeightage")) {
        errors[
          `${index}-CategoryQuestion`
        ] = `The sum of the weightage of all the Question should be equal to this Catagory weightage `;
      } else {
        delete errors[`${index}-CategoryQuestion`];
      }
    } else {
      delete errors[`${index}-CategoryQuestion`];
    }


    return errors;
  };

  let currentIndex = selectedCatagoryMapping.findIndex((item) => item.Catagory_Id == MapCatagory);

  const ProceedTonextCatagory = (dir: boolean) => {
    if (dir) {
      handleNext(currentIndex);
    } else {
      handlePrevious(currentIndex);
    }
  };

  const handleNext = (currentIndex: any) => {
    if (currentIndex < selectedCatagoryMapping.length - 1) {
      const newIndex = currentIndex + 1;
      chnageLevesfilterMapCatagoryQuesionI(selectedCatagoryMapping[newIndex].Catagory_Id);
    }

    if (currentIndex == selectedCatagoryMapping.length - 1) {
      CloseTheQuestionSection()
    }
  };

  const handlePrevious = (currentIndex: any) => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      chnageLevesfilterMapCatagoryQuesionI(selectedCatagoryMapping[newIndex].Catagory_Id);
    }
  };

  const CloseTheQuestionSection = () => {
    setIsQuestionselectEnable(false);
    setIsPopupCancel(false);
    if (watch('IsWeightageegual')) {
      questionweightageEqual(true, false)
    }
  }

  const chnageLevesfilterMapCatagoryQuesionI = (id: any) => {
    setMapCatagory(id);
    setMapCatagoryII([])
    setMapCatagoryIII([])
    //  filterMapCatagoryQuesion()


  }

  const chnageLevesfilterMapCatagoryQuesionII = (id: any) => {
    // console.log(id, "asdas");

    setMapCatagoryII(id)
    setMapCatagoryIII([])
    // filterMapCatagoryQuesion()
  }


  const chnageLevesfilterMapCatagoryQuesionIII = (id: any) => {

    setMapCatagoryIII(id)

  }

  useEffect(() => {
    if (MapCatagory) {
      filterMapCatagoryQuesion()
    }

  }, [MapCatagory, MapCatagoryII, MapCatagoryIII, aqmListData, watch("Service_type")])

  const filterMapCatagoryQuesion = (filterCatagory = null) => {
    //console.log(aqmListData, id);
    let ass_type: any = watch("Assessment_Type_1") || "";
    let applicable_for: any = watch("Assessment_Type_2") || "";
    let AssessmentServiceWatch: any = watch("Service_type") || [];
    console.log(AssessmentServiceWatch, "AssessmentServiceWatch");

    let CatageryLevleI = filterCatagory ? filterCatagory : MapCatagory
    let CatageryLevleII = MapCatagoryII
    let CatageryLevleIII = MapCatagoryIII

    let filteredaqmListData = aqmListData.filter((item, index) => {
      const assessmentTypeID = item?.Assessment_TypeID;
      const isAssessmentTypeValid = assessmentTypeID ? JSON.parse(assessmentTypeID).includes(ass_type) : false;
      // console.log(CatageryLevleII, item, "CatageryLevleII");

      return (
        item.Question_CategoryId === CatageryLevleI && ((CatageryLevleII && CatageryLevleII?.length > 0) ? CatageryLevleII.includes(item.Question_CategoryId_Level2) : true) && ((CatageryLevleIII && CatageryLevleIII?.length > 0) ? CatageryLevleIII.includes(item.Question_CategoryId_Level3) : true) &&
        isAssessmentTypeValid &&
        (item?.Applicable_For === applicable_for || item?.Applicable_For_Value === Applicable_For.Both)
      );
    });
    // console.log(filteredaqmListData, "filteredaqmListData");
    // console.log(smeResponseStep, "filteredaqmListData 1");
    // console.log(smeReviewStep, "filteredaqmListData 2");

    let SMEResponseUserDepartments: any[] = [];
    let SMEReviewUserDepartments: any[] = [];

    let SMEResponseUserDepartmentsanduserName: any[] = [];
    let SMEReviewUserDepartmentsanduserName: any[] = [];
    //find sme response user Department
    if (smeResponseStep && smeResponseStep?.Primary_User) {
      let SmeresponseEachUser = smeResponseStep?.Primary_User + smeResponseStep?.Secondary_User;
      const userIdArrayResponse: string[] = SmeresponseEachUser.split(",").map((secid: any) => Number(secid.trim()));

      SMEResponseUserDepartments = userIdArrayResponse.map((departMentDataId: any) => {
        let finedUseData = allUserList.find((userItem) => userItem.UserId == departMentDataId);
        SMEResponseUserDepartmentsanduserName.push(finedUseData);
        return finedUseData?.DepartmentId;
      });
    }
    //find sme review user Department
    if (smeReviewStep && smeReviewStep?.Primary_User) {
      let SmeresponseEachUser = smeReviewStep?.Primary_User + smeReviewStep?.Secondary_User;
      const userIdArrayResponse: string[] = SmeresponseEachUser.split(",").map((smeid: any) => Number(smeid.trim()));

      SMEReviewUserDepartments = userIdArrayResponse.map((departMentDataId: any) => {
        let finedUseData = allUserList.find((userItem) => userItem.UserId == departMentDataId);
        SMEReviewUserDepartmentsanduserName.push(finedUseData);
        return finedUseData?.DepartmentId;
      });
    }
    setSmeResponseStepuser(SMEResponseUserDepartmentsanduserName);
    setSmeReviewStepuser(SMEReviewUserDepartmentsanduserName);
    // console.log(
    //   SMEResponseUserDepartments,
    //   SMEReviewUserDepartments,
    //   "SMEReviewUserDepartmentsanduserName"
    // );

    //  smeResponseStep
    //  smeReviewStep
    if (SMEResponseUserDepartments && SMEResponseUserDepartments?.length > 0) {
      const departmentIdArrayResponse: any[] = SMEResponseUserDepartments;

      filteredaqmListData = filteredaqmListData.filter((smeResponseQuestion) => {
        if (smeResponseQuestion?.Responder_Value === AQMResponder.SME) {
          if (smeResponseQuestion?.SMEVerified) {
            if (SMEReviewUserDepartments && SMEReviewUserDepartments?.length > 0) {
              const departmentIdArrayReviewSmes: any[] = SMEReviewUserDepartments;
              return departmentIdArrayReviewSmes.includes(smeResponseQuestion?.SME) && departmentIdArrayResponse.includes(smeResponseQuestion?.SME);
            }
          }
          return departmentIdArrayResponse.includes(smeResponseQuestion?.SME);
        } else {
          return true;
        }
      });
    } else {
      filteredaqmListData = filteredaqmListData.filter((smeResponseQuestion) => smeResponseQuestion?.Responder_Value !== AQMResponder.SME)
    }

    // console.log(filteredaqmListData, "filteredaqmListData smeres", SMEReviewUserDepartments, SMEReviewUserDepartmentsanduserName);

    if (SMEReviewUserDepartments && SMEReviewUserDepartments?.length > 0) {
      const departmentIdArrayReview: any[] = SMEReviewUserDepartments;

      filteredaqmListData = filteredaqmListData.filter((smeReviewQuestion) => {
        if (smeReviewQuestion?.Responder_Value === AQMResponder.Vendor && smeReviewQuestion?.SMEVerified) {
          return departmentIdArrayReview.includes(smeReviewQuestion?.SME);
        } else {
          return true;
        }
      });
      filteredaqmListData = filteredaqmListData.filter((smeResponseQuestion) => {
        if (smeResponseQuestion?.Responder_Value === AQMResponder.SME && smeResponseQuestion?.SMEVerified) {
          return departmentIdArrayReview.includes(smeResponseQuestion?.SME);
        } else {
          return true;
        }
      });
      if (AssessmentServiceWatch?.length > 0) {
        console.log(AssessmentServiceWatch, "AssessmentServiceWatch");
        filteredaqmListData = filteredaqmListData?.filter((AssessmentService) => {
          let AQMServiceJson = JSON.parse(AssessmentService?.AQMServiceMappingJson);
          console.log(AQMServiceJson, "AQMServiceJson");

          return AQMServiceJson?.some((AQMservice: any) => AssessmentServiceWatch?.includes(AQMservice?.ServiceCatalog_Id));
        });
      }

    }
    if (filterCatagory) return filteredaqmListData

    console.log(filteredaqmListData, 'filteredaqmListData');

    setmappingUseQuestions(filteredaqmListData || []);
    onchangeSelectQuestionAddRemove(CatageryLevleI);

  };

  const questionweightageEquals = (val?: boolean) => {
    setselectedCatagoryMapping((prevState) => {
      return prevState.map((item: any) => {
        const filteredQuestionyesWtg: any =
          item.Questions?.filter(
            (questionitem: any) => questionitem.Answer_Type === true && questionitem?.Consider_for_Scoring == 1
          ) || [];
        const filteredQuestionnoWtg: any =
          item.Questions?.filter(
            (questionitem: any) =>
              (questionitem.Answer_Type === false || questionitem.Answer_Type === true) &&
              questionitem?.Consider_for_Scoring == 0
          ) || [];
        const countofQuestion: number = filteredQuestionyesWtg?.length || 0;
        const CatagoryWeightage: number = item.Cat_Wtg ? Number(item.Cat_Wtg) : 0;
        const distributedScores: any = distributeScoreEvenly(countofQuestion, CatagoryWeightage);
        const updatedQuestionsyesWtg = filteredQuestionyesWtg?.map((Qitem: any, Qindex: number) => {
          return {
            ...Qitem,
            Ques_Wtg: val ? distributedScores[Qindex] : 1,
          };
        });
        const updatedQuestionsnoWtg = filteredQuestionnoWtg.map((Qitem: any) => {
          return {
            ...Qitem,
            Ques_Wtg: 0,
          };
        });

        return {
          ...item,
          onChange: true,
          Questions: [...updatedQuestionsyesWtg, ...updatedQuestionsnoWtg],
        };
      });
    });
    validateAllFields();
  };

  const questionweightageEqual = (val: boolean, remove: boolean) => {
    selectedCatagoryMapping.forEach((item: any, index: any) => {
      const filteredQuestionyesWtg: any =
        item.Questions?.filter(
          (questionitem: any) => questionitem.Answer_Type === true && questionitem?.Consider_for_Scoring == 1
        ) || [];
      console.log(filteredQuestionyesWtg, "filteredQuestionyesWtg ", index);

      const countofQuestion: number = filteredQuestionyesWtg?.length || 0;
      const CatagoryWeightage: number = item.Cat_Wtg ? Number(item.Cat_Wtg) : 0;
      const distributedScores: any = distributeScoreEvenly(countofQuestion, CatagoryWeightage);
      let yesIndex: any = 0;
      item.Questions?.forEach((Qitem: any, Qindex: number) => {
        let disWtg: any = 0;
        if (Qitem.Answer_Type === true && Qitem?.Consider_for_Scoring == 1) {
          disWtg = remove ? 0 : val ? distributedScores[yesIndex] : 1;
          yesIndex += 1;
        } else {
          disWtg = 0;
        }

        catagoryQuestionwtgonChange(disWtg, index, Qindex);
      });
    });
    // validateAssessmentAll()
    //  validateAllFields()
  };

  useEffect(() => {
    if (MapCatagory) {
      onchangeSelectQuestionAddRemove(MapCatagory);
    }
    syncTotal();
  }, [MapCatagory, selectedCatagoryMapping]);

  const onchangeSelectQuestionAddRemove = (id: any) => {
    let filteredExistaqmListData: any = selectedCatagoryMapping.find((item) => item.Catagory_Id === id);
    ////console.log();

    setExistUseQuestions(filteredExistaqmListData?.Questions || []);
  };

  const onSelectQuestion = (selitem: any) => {
    setselectedCatagoryMapping((prevState) => {
      let updatedState = prevState.map((item) => {
        if (item.Catagory_Id === MapCatagory) {
          const existingQuestionIndex = item.Questions.findIndex(
            (q: any) => q.Assessment_Questionaire_Id === selitem.Assessment_Questionaire_Id
          );
          if (existingQuestionIndex > -1) {
            item.Questions.splice(existingQuestionIndex, 1);
          } else {
            let val: any = {
              Assessment_Questionaire_Mapping_Id: 0,
              AQM_Category_Questions_Id: 0,
              Assessment_Questionaire_Id: selitem.Assessment_Questionaire_Id,
              Consider_for_Scoring: selitem.Answer_Type ? 1 : 0,
              Question: selitem.Question,
              Ques_Wtg: watch("IsQuestionWeightage") && selitem.Answer_Type ? 1 : 0,
              Answer_Type: selitem.Answer_Type,
              Updated_By: user?.UserName,
              Updated_On: currentDate,
              IsActive: true,
              Approved_By: user?.UserName,
              Approved_On: currentDate,
              Status: 0,
            };
            item.Questions.push(val);
          }
        }

        return item;
      });

      return updatedState;
    });
    onchangeSelectQuestionAddRemove(MapCatagory);
  };

  const catagoryQuestionscoregonChange = (value: any, selitem: any) => {
    setselectedCatagoryMapping((prevState) => {
      let updatedState = prevState.map((item: any) => {
        if (item.Catagory_Id === MapCatagory) {
          const updatedQuestions = item.Questions.map((q_item: any) => {
            if (q_item.Assessment_Questionaire_Id === selitem.Assessment_Questionaire_Id) {
              return {
                ...q_item,
                Consider_for_Scoring: value,
                Ques_Wtg: watch("IsQuestionWeightage") && selitem.Answer_Type && value == 1 ? 1 : 0,
              };
            }
            return q_item;
          });

          return { ...item, Questions: updatedQuestions };
        }

        return item;
      });
      //console.log(updatedState, "updatedState");

      return updatedState;
    });
    onchangeSelectQuestionAddRemove(MapCatagory);
  };

  const syncTotal = () => {
    let tot = 0;
    selectedCatagoryMapping.map((item) => {
      tot += item.Cat_Wtg ? Number(item.Cat_Wtg) : 0;
    });
    settotalwtg(tot);
  };

  const validateAssessmentAll = () => {
    let allErrors: { [key: string]: string } = {};

    selectedCatagoryMapping.forEach((item: any, index: any) => {
      const CatagoryErrors = validateCatagoryField(index, "Category", item, allErrors);
      console.log(CatagoryErrors, "catagory error ", index);

      allErrors = { ...allErrors, ...CatagoryErrors };
      item.Questions &&
        item.Questions.forEach((q_item: any, Q_index: any) => {
          const QuestionErrors = validateQuestionField(index, Q_index, item, q_item, allErrors);
          allErrors = { ...allErrors, ...QuestionErrors }; // Merge question errors
        });
    });

    return allErrors;
  };

  const validateAllFields = () => {
    let isValid = true;
    setvalidationErrors({})

    console.log(validationErrors, 'allErrors old');

    let allErrors: { [key: string]: string } = validateAssessmentAll();
    console.log(allErrors, 'allErrors');

    setvalidationErrors(allErrors);

    if (Object.keys(allErrors).length > 0) {
      isValid = false;
    }

    return isValid && totalwtg == 100;
  };

  const handleSubmitBoth = () => {
    setfinalError(true);
    validateAllFields();
    handleAQMMappingForm(handleSubmit)();
  };

  const convertFinalQuestions = () => {
    let finalValuesQues = selectedCatagoryMapping.map((item: any, index: any) => {
      let changedQues = item.Questions?.map((Qitem: any, Qindex: number) => {
        return {
          ...Qitem,
          Ques_Wtg: Qitem?.Ques_Wtg ? Qitem?.Ques_Wtg.toString() : "0",
        };
      });

      return {
        ...item,
        Questions: changedQues,
      };
    });

    return finalValuesQues;
  };

  const handleSubmit = (data: any) => {
    if (validateAllFields()) {
      setfinalError(true);
      let finalformdata = data;
      console.log(data?.Start_Date, data?.Target_Date, "finalData 1");
      const existingserviceCatalogIds = VendorDetails?.VendorServiceMappings ? VendorDetails?.VendorServiceMappings?.map((item: any) => item.ServiceCatalogId) : [];

      finalformdata.Start_Date = getDateOrConvertSameTime(data?.Start_Date?.$d);
      finalformdata.Target_Date = getDateOrConvertSameTime(data?.Target_Date?.$d);
      finalformdata.Approved_By = user?.UserName;
      finalformdata.Updated_By = user?.UserName;
      finalformdata.Updated_On = currentDate;
      finalformdata.Approved_On = currentDate;
      finalformdata.ServiceName = JSON.stringify(existingserviceCatalogIds);
      finalformdata.Status = 1;
      finalformdata.Category = JSON.stringify(finalformdata.Category);
      finalformdata.UserId = userLoginResponse?.UserId;
      finalformdata.ModuleName = "AssessmentQuestionaire";
      finalformdata.Activity = ToasterActivityConfig.create;
      finalformdata.CategoryQuestions = convertFinalQuestions();
      finalformdata.Menu_Id = currentPagePermissionData?.MenuId;
      finalformdata.Project_Detail_Id = currentProcessData ? currentProcessData?.ProjectId : 0;
      finalformdata.IsRenewal = currentProcessData?.IsRenewal ? currentProcessData?.IsRenewal : false;

      let finalData: any = {
        assessmentQuestionaireMapping: { ...finalformdata },
        Activity: ToasterActivityConfig.create,
        ModuleName: "Assessment Questionnaire Mapping",
        UserId: userLoginResponse?.UserId,
        Workflow_InstanceId: currentProcessData ? currentProcessData?.Workflow_InstanceId : 0,
        Process_InstanceId: currentProcessData ? currentProcessData?.Id : 0,
      };
      console.log(convertFinalQuestions(), "finalData", finalformdata?.Start_Date, finalformdata?.Target_Date);

      dispatch(addAQMMapping(finalData));
    } else {
      setfinalError(true);
    }
  };

  let questionNotconsiderWeightage: any = watch("IsQuestionWeightage");

  const AddNewQuestion = () => {
    setAddNewQuestionOpen(true);
  }

  useEffect(() => {
    if (isAQMAdded === true) {
      setAddNewQuestionOpen(false);
      dispatch(getAQMList({ FormID: 15 }));
      // setMapCatagory(MapCatagory);
      console.log(MapCatagory, "MapCatagory");

      dispatch(updateAQMAddandEdit(false));

    }
  }, [isAQMAdded])

  console.log(AddNewQuestionOpen, menuData, "AddNewQuestionOpen");

  const importQuestionsFromAQM = () => {
    // && item.IsActive ===  'Active' && item.Vendor_Name ===  importedAQM
    let selectedAQM = AQMMappingdataTable.find((item: any) => item.Assessment_Type_1 === watch("Assessment_Type_1") && item.Assessment_Type_2 === watch("Assessment_Type_2") && item.Assessment_Questionaire_Mapping_Id === importedAQM)
    const validCategory = JSON.parse(selectedAQM.Category).filter((i: any) => {
      const isValidCategory = CategoryDataLV.find((ele) => ele.AQ_Category_Id === i)
      if (isValidCategory) return true
      else return false
    })

    const updatedCategory = Array.from(new Set(watch('Category').concat(validCategory)));

    let normalizedQuestion = JSON.parse(selectedAQM?.AQMCategoryQuestions)?.reduce((ac: any, item: any) => {
      const category = CategoryDataLV.find((ele) => ele.AQ_Category_Id === item.Catagory_Id)
      if (category) {
        item.Options = category.AQ_Category_Name
        let aqmQuestions = filterMapCatagoryQuesion(item.Catagory_Id)
        const updatedAqmQuestions = aqmQuestions?.reduce((acc: any, q: any) => {
          const ind = item.Questions.findIndex((i: any) => i.Assessment_Questionaire_Id === q.Assessment_Questionaire_Id)
          if (ind > -1) {
            let val: any = {
              Assessment_Questionaire_Mapping_Id: 0,
              AQM_Category_Questions_Id: 0,
              Assessment_Questionaire_Id: q.Assessment_Questionaire_Id,
              Consider_for_Scoring: item.Questions[ind].Answer_Type ? 1 : 0,
              Question: q.Question,
              Ques_Wtg: watch("IsQuestionWeightage") && q.Answer_Type ? 1 : 0,
              Answer_Type: q.Answer_Type,
              Updated_By: user?.UserName,
              Updated_On: currentDate,
              IsActive: true,
              Approved_By: user?.UserName,
              Approved_On: currentDate,
              Status: 0,
            };
            acc.push(val)
            return acc
          }
          else return acc
        }, [])
        item.Questions = updatedAqmQuestions
        ac.push(item)
        return ac
      }
      else return ac
    }, [])

    selectedCatagoryMapping.map(sc => {
      const ind = normalizedQuestion.findIndex((i: any) => i.Catagory_Id === sc.Catagory_Id)
      if (ind === -1) normalizedQuestion.push(sc)
      else {
        normalizedQuestion[ind] = { ...normalizedQuestion[ind], Questions: [...normalizedQuestion[ind].Questions, ...sc.Questions] }
      }
    })
    setselectedCatagoryMapping(normalizedQuestion)
    setValue("Category", updatedCategory);
    setSelectedCatagory(updatedCategory);
    setImportQuestionOpen(false)
  }

  const handleCancelPopup = () => {

    setIsCancel(true);

  };

  return (
    <>
      <Box component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {!isViewOnly && (
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <CustomBreadcrumbs
              list={[
                { value: 0, name: prePageName ? prePageName : "Assessment Questionnaire Mapping" },
                { value: 1, name: `${edit ? "Edit" : view ? "View" : "Add New"}` },
              ]}
              onchange={closeForm}
            />

            {currentProcessData && (
              <Box sx={{ width: "20", }}>
                <VendorHistory isEditData={currentProcessData} />
              </Box>
            )}

          </Box>
        )}

        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {`${edit ? "Edit" : view ? "View" : "Add New"} Assessment Questionnaire Mapping`}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                border: "1px solid #F7F7F7",
                padding: 2,
              }}
            >
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Assessment_Number"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Assessment Number is Required",
                      validate: {
                        maxLength: (value) => {
                          if (value === undefined) return "Assessment Number is required";
                          return value.length <= 50 || "Maximum 50 characters allowed";
                        },
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        label={"Assessment Number"}
                        name={"Assessment Number"}
                        value={field.value}
                        readOnly={true}
                        required={true}
                        onChange={field.onChange}
                        error={Boolean(errorsAQMMappingform.Assessment_Number)}
                        helperText={
                          errorsAQMMappingform.Assessment_Number &&
                          errorsAQMMappingform.Assessment_Number.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Vendor_Name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Vendor Name is Required" }}
                    render={({ field }) => (
                      <CustomTextField
                        label={"Vendor Name"}
                        name={"Vendor_Name"}
                        value={field.value}
                        readOnly={true}
                        required={true}
                        onChange={field.onChange}
                        error={Boolean(errorsAQMMappingform.Vendor_Name)}
                        helperText={
                          errorsAQMMappingform.Vendor_Name && errorsAQMMappingform.Vendor_Name.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name={`Validity_in_Months`}
                    control={control}
                    defaultValue={0}
                    rules={{
                      required: "Validity Field is Required",
                      validate: (data: number | undefined) => {
                        if (data === undefined || isNaN(data)) {
                          return "Validity Field is Required";
                        }
                        const number = parseInt(data.toString(), 10);
                        let valuesFrom = 1;
                        let valuesTo = 60;
                        const numberFrom = parseInt(valuesFrom?.toString() ?? "", 10);
                        const numberTo = parseInt(valuesTo?.toString() ?? "", 10);

                        // Check if valuesFrom and valuesTo are valid numbers
                        if (isNaN(numberFrom) || isNaN(numberTo)) {
                          return "Please enter valid numbers for the scoring range.";
                        }

                        // Check if it's a valid number and falls within the specified range
                        if (isNaN(number) || number < numberFrom || number > numberTo) {
                          return `Please enter a number between ${numberFrom} and ${numberTo}`;
                        }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <CustomNumberField
                        name={"Attribute Response"}
                        required={true}
                        readOnly={view}
                        label={"Validity In Months"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsAQMMappingform.Validity_in_Months)}
                        helperText={
                          errorsAQMMappingform.Validity_in_Months &&
                          errorsAQMMappingform.Validity_in_Months.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Assessment_Type_1"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Assessment Type I is Required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"Assessment Type I"}
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={assesmentTypeILV}
                        readOnly={true}
                        required={true}
                        label={"Assessment Type I"}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          againChangeQuestions();
                        }}
                        error={Boolean(errorsAQMMappingform.Assessment_Type_1)}
                        helperText={
                          errorsAQMMappingform.Assessment_Type_1 &&
                          errorsAQMMappingform.Assessment_Type_1.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Assessment_Type_2"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Assessment Type II for is Required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"Assessment Type II"}
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={assesmentTypeIILV}
                        readOnly={true}
                        required={true}
                        label={"Assessment Type II"}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          againChangeQuestions();
                        }}
                        error={Boolean(errorsAQMMappingform.Assessment_Type_2)}
                        helperText={
                          errorsAQMMappingform.Assessment_Type_2 &&
                          errorsAQMMappingform.Assessment_Type_2.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Workflow_Name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Workflow is Required" }}
                    render={({ field }) => (
                      <CustomTextField
                        label={"Workflow"}
                        name={"Workflow_Name"}
                        value={field.value}
                        readOnly={true}
                        required={true}
                        onChange={field.onChange}
                        error={Boolean(errorsAQMMappingform.Workflow_Name)}
                        helperText={
                          errorsAQMMappingform.Workflow_Name && errorsAQMMappingform.Workflow_Name.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Start_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Start Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Workflow Start Date"}
                        value={field.value ? dayjs(field.value) : null}
                        readOnly={true}
                        onChange={(e) => {
                          field.onChange(e);
                          // handleStartDateChange(e);
                          clearErrors("Target_Date")
                        }}
                        error={Boolean(errorsAQMMappingform.Start_Date)}
                        helperText={
                          errorsAQMMappingform.Start_Date && errorsAQMMappingform.Start_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Target_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Target Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Workflow Target Date"}
                        value={field.value}
                        readOnly={true}
                        onChange={field.onChange}
                        error={Boolean(errorsAQMMappingform.Target_Date)}
                        helperText={
                          errorsAQMMappingform.Target_Date && errorsAQMMappingform.Target_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                border: "1px solid #F7F7F7",
                padding: 2,
              }}
            >
              <Box>
                <Grid container spacing={2} sx={{ mb: 3 }}>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Service_type"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        // required: "Service Type is Required",
                      }}
                      render={({ field }) => (
                        <CustomMultiSelect
                          optionLabelKey="ServiceGroupName"
                          optionIdKey="ServiceCatalogId"
                          options={AssessmentService}
                          // required={true}
                          readOnly={view}
                          label={"Select Services for Assessment"}
                          value={field.value}
                          onChange={(RiskVal) => {
                            field.onChange(RiskVal.target.value);
                          }}
                          error={Boolean(errorsAQMMappingform.Service_type)}
                          helperText={
                            errorsAQMMappingform.Service_type && errorsAQMMappingform.Service_type.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Category"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Category is Required",
                      }}
                      render={({ field }) => (
                        <CustomMultiSelect
                          optionLabelKey="AQ_Category_Name"
                          optionIdKey="AQ_Category_Id"
                          options={CategoryDataLV}
                          required={true}
                          readOnly={view}
                          label={"Category "}
                          value={field.value}
                          onChange={(RiskVal) => {
                            field.onChange(RiskVal.target.value);
                            setSelectedCatagory(RiskVal.target.value);
                          }}
                          error={Boolean(errorsAQMMappingform.Category)}
                          helperText={
                            errorsAQMMappingform.Category && errorsAQMMappingform.Category.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="IsQuestionWeightage"
                      control={control}
                      defaultValue={""}
                      // rules={{
                      //   required: "Required Status",
                      // }}
                      render={({ field }) => (
                        <CustomCheckBox
                          label="Don't Apply Weightage to Questions"
                          value={field.value}
                          readOnly={view}
                          onChange={(e) => {
                            field.onChange(e);

                            questionweightageEqual(false, e.target.checked ? false : true);
                            if (e.target && e.target.checked) {
                              setValue("IsWeightageegual", false);
                            }
                          }}
                          error={Boolean(errorsAQMMappingform.IsQuestionWeightage)}
                          helperText={
                            errorsAQMMappingform.IsQuestionWeightage &&
                            errorsAQMMappingform.IsQuestionWeightage.message?.toString()
                          }
                          isStyle={true}
                        />
                      )}
                    />
                  </Grid>
                  {watch("IsQuestionWeightage") == false && !view ? (
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}
                    >
                      <Controller
                        name="IsWeightageegual"
                        control={control}
                        defaultValue={""}
                        // rules={{
                        //   required: "Required Status",
                        // }}
                        render={({ field }) => (
                          <CustomCheckBox
                            label="Distribute weightage equally "
                            value={field.value}
                            readOnly={view}
                            onChange={(e) => {
                              field.onChange(e);

                              questionweightageEqual(true, e.target.checked ? false : true);

                            }}
                            error={Boolean(errorsAQMMappingform.IsWeightageegual)}
                            helperText={
                              errorsAQMMappingform.IsWeightageegual &&
                              errorsAQMMappingform.IsWeightageegual.message?.toString()
                            }
                            isStyle={true}
                          />
                        )}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
              {/* -------------------- tab1 ---------------- */}
              <Box className={BasicTableStyle.tab_outer} sx={{ width: "100%", mt: 2 }}>
                <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={BasicTableStyle.tab_header} width={"60%"}>
                        Category
                      </TableCell>
                      {/* <TableCell className={BasicTableStyle.tab_header} width={'15%'} >Question</TableCell> */}

                      <TableCell className={BasicTableStyle.tab_header} align="center" width={"100px"}>
                        {" "}
                        Weightage
                      </TableCell>

                      <TableCell className={BasicTableStyle.tab_header} align="center" width={"100px"}>
                        {" "}
                        Consider For Scoring
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedCatagoryMapping.map((item, index) => {
                      // const hasError = validatecatagory(item) !== null;
                      // const isItemChanged = item?.onChange && item?.onChange === true;
                      // const showError = (hasError && isItemChanged) || (hasError && finalError);

                      return (
                        <>
                          <TableRow>
                            <TableCell className={BasicTableStyle.tab_data}>
                              <Box>
                                {item.Options}{" "}
                                {index === 0 && !view ? (
                                  <IconButton
                                    onClick={() => {
                                      setIsQuestionselectEnable(true);
                                      chnageLevesfilterMapCatagoryQuesionI(item.Catagory_Id);
                                    }}
                                  >
                                    +
                                  </IconButton>
                                ) : null}
                              </Box>
                              <FormHelperText
                                error={Boolean(validationErrors[`${index}-CategoryQuestion`])}
                                id="my-helper-text"
                              >
                                {validationErrors[`${index}-CategoryQuestion`]}
                              </FormHelperText>
                            </TableCell>
                            <TableCell className={BasicTableStyle.tab_data} align="center">
                              <CustomNumberField
                                name={"Question"}
                                required={true}
                                readOnly={view}
                                label={""}
                                isDecimal={true}
                                value={item.Cat_Wtg}
                                onChange={(value) => catagorywtgonChange(value, index)}
                                // error={Boolean(showError)}
                                // helperText={showError ? validatecatagory(item) ?? '' : ''}
                                error={Boolean(validationErrors[`${index}-Category`])}
                                helperText={validationErrors[`${index}-Category`]}
                              />
                            </TableCell>
                          </TableRow>
                          {item.Questions.map((q_item: any, q_index: any) => (
                            <TableRow
                              sx={{
                                backgroundColor:
                                  themeMode === "dark" ? `${theme.palette.background.default}` : "#F7F7F7",
                              }}
                            >
                              <TableCell sx={{ paddingLeft: "20px !important" }} className={BasicTableStyle.tab_data}>
                                {q_item.Question}{" "}
                              </TableCell>
                              <TableCell className={BasicTableStyle.tab_data} align="center">
                                {q_item?.Answer_Type == true ? (
                                  !questionNotconsiderWeightage ? (
                                    <CustomNumberField
                                      name={"Ques_Wtg"}
                                      required={true}
                                      readOnly={view}
                                      label={""}
                                      isDecimal={true}
                                      value={q_item.Ques_Wtg}
                                      onChange={(value) => catagoryQuestionwtgonChange(value, index, q_index, true)}
                                      // error={Boolean(validatecatagory(item) !== null ? true : false)}
                                      // helperText={validatecatagory(item) || ""}
                                      error={Boolean(validationErrors[`${index}-${q_index}-Question`])}
                                      helperText={validationErrors[`${index}-${q_index}-Question`]}
                                    />
                                  ) : (
                                    "N/A"
                                  )
                                ) : (
                                  "Free Text"
                                )}
                              </TableCell>
                              <TableCell className={BasicTableStyle.tab_data} align="center">
                                {q_item?.Answer_Type == true
                                  ? q_item?.Consider_for_Scoring == 1
                                    ? "Yes"
                                    : "No"
                                  : "N/A"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      );
                    })}

                    <TableRow>
                      <TableCell className={BasicTableStyle.tab_data}>Total</TableCell>
                      <TableCell className={BasicTableStyle.tab_data} align="center">
                        {totalwtg}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: "8px", textAlign: "left", color: finalError && totalwtg != 100 ? "red" : "#6A6A6A" }}
                >
                  Note :
                </Typography>
                <Typography
                  sx={{ fontSize: "8px", textAlign: "left", color: finalError && totalwtg != 100 ? "red" : "#6A6A6A" }}
                >
                  The sum of the weightage of all the category should be equal to 100
                </Typography>
                <Typography
                  sx={{ fontSize: "8px", textAlign: "left", color: finalError && totalwtg != 100 ? "red" : "#6A6A6A" }}
                >
                  and Please ensure that at least one drop-down question is included, if the category has a weightage.
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isViewOnly && (
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                mt: 2,
              }}
            >
              {!view ? (
                !edit ? (
                  <>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        handleCancelPopup();
                      }}
                    />

                    {/* <CustomButton type="reset" name={"Save as Draft"} onClick={() => handleSubmitDraft()} /> */}
                    {false && (
                      <CustomButton
                        type="reset"
                        variant="contained"
                        name={"Save as Draft"}
                        disabled={!isDirty}
                        onClick={() => {
                          // saveAsDraftServiceCall();
                        }}
                      />
                    )}
                    <CustomButton type="reset" name={"Submit"} disabled={!isDirty} onClick={() => handleSubmitBoth()} />
                  </>
                ) : (
                  <>
                    <CustomButton
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        handleCancelPopup();
                      }}
                    />
                    <CustomButton
                      disabled={!isDirty}
                      variant="contained"
                      name={"Update"}
                      onClick={() => handleSubmitBoth()}
                    />
                  </>
                )
              ) : (
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    onClose();
                  }}
                />
              )}
            </Box>
          )}
        </Stack>
      </Box>

      <CustomDialog
        show={isQuestionselectEnable}
        onHide={() => AddNewQuestionOpen ? setAddNewQuestionOpen(false) : CloseTheQuestionSection()}
        maxWidth={"lg"}
        header={AddNewQuestionOpen ? "Add New Question" : `Select Questions`}
        contentNode={
          <>
            {!AddNewQuestionOpen ? (
              <>
                <Box sx={{ width: "100%" }}>
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}>
                      <CustomSelect
                        name={"Workflow"}
                        valueKey={"Catagory_Id"}
                        optionLabelKey="Options"
                        options={selectedCatagoryMapping}
                        disabled={view}
                        required={true}
                        label={"Selected category"}
                        value={MapCatagory}
                        onChange={(val: any) => {
                          chnageLevesfilterMapCatagoryQuesionI(val);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}>
                      <CustomMultiSelect
                        optionLabelKey="AQ_Category_Name"
                        optionIdKey="AQ_Category_Id"
                        options={assessmentCategoryMasterData.filter((item: any) => item.Parent_AQ_Category_Id === MapCatagory)}
                        readOnly={view}
                        // required={true}
                        label={"Sub Category I"}
                        value={MapCatagoryII}
                        onChange={(val: any) => {
                          chnageLevesfilterMapCatagoryQuesionII(val?.target?.value || [])
                        }}

                      />

                    </Grid>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={textFieldWidth.md}
                      lg={textFieldWidth.lg}
                      xl={textFieldWidth.xl}>
                      <CustomMultiSelect
                        optionLabelKey="AQ_Category_Name"
                        optionIdKey="AQ_Category_Id"
                        options={assessmentCategoryMasterData.filter((item: any) => item.Parent_AQ_Category_Id && MapCatagoryII.includes(item.Parent_AQ_Category_Id))}
                        readOnly={view}
                        // required={true}
                        label={"Sub Category II"}
                        value={MapCatagoryIII}
                        onChange={(val: any) => {
                          chnageLevesfilterMapCatagoryQuesionIII(val?.target?.value || [])
                        }}

                      />
                    </Grid>
                  </Grid>
                </Box>


                {/* -------------- tab2 ---------- */}
                <Box sx={{ width: "100%", mt: 2 }}>
                  <Box className={BasicTableStyle.tab_outer} sx={{ width: "100%" }}>
                    <Table className={BasicTableStyle.tab_outer} sx={{ maxWidth: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={BasicTableStyle.tab_header}>Select</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>Question Title</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>Question</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>Responder</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>SME</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>SME Verification</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>Answer Type</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>Consider For Scoring</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mappingUseQuestions.map((item, index) => (
                          <TableRow
                            key={item?.Assessment_Questionaire_Id}
                          //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              <Box sx={{ ml: 2, width: "100%" }}>
                                <CustomCheckBox
                                  label=""
                                  isStyle={true}
                                  disabled={view}
                                  value={mappingUseExistQuestions.find(
                                    (ex_item) => ex_item.Assessment_Questionaire_Id == item.Assessment_Questionaire_Id
                                  )}
                                  onChange={() => onSelectQuestion(item)}
                                />
                              </Box>
                            </TableCell>
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.Question_Title}
                            </TableCell>
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.Question}
                            </TableCell>
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.Responder_Value}
                            </TableCell>
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.SME_Value}
                            </TableCell>
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.SMEVerified ? "Yes" : "No"}
                            </TableCell>

                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.Answer_Type ? "Multiple Choice" : "Free Text"}
                            </TableCell>
                            <TableCell
                              key={item?.Assessment_Questionaire_Id}
                              className={BasicTableStyle.tab_data}
                              sx={{ verticalAlign: "top" }}
                            >
                              <CustomSelect
                                name={"Assessment Type I"}
                                valueKey={"id"}
                                optionLabelKey="Option"
                                options={item.Answer_Type ? considerScoreListM : considerScoreList}
                                label={""}
                                readOnly={item.Answer_Type ? false : true}
                                value={
                                  mappingUseExistQuestions.find(
                                    (ex_item) => ex_item.Assessment_Questionaire_Id === item.Assessment_Questionaire_Id
                                  )?.Consider_for_Scoring ?? ""
                                }
                                onChange={(val) => {
                                  catagoryQuestionscoregonChange(val, item);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  {/* -------------- tab3 ---------- */}
                  <Box className={BasicTableStyle.tab_outer} sx={{ width: "50%", mt: 2 }}>
                    <Table className={BasicTableStyle.tab_outer} sx={{ maxWidth: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={BasicTableStyle.tab_header}>Question category</TableCell>
                          <TableCell className={BasicTableStyle.tab_header}>No Of Questions Added</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedCatagoryMapping.map((item, index) => (
                          <TableRow
                            key={index + 1}
                          //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.Options}
                            </TableCell>
                            <TableCell className={BasicTableStyle.tab_data} sx={{ verticalAlign: "top" }}>
                              {item.Questions ? item.Questions.length : 0}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>

                  <Box sx={{ width: "100%", mt: 2, fontSize: "12px" }}>
                    {smeResponseStep || smeReviewStep ? <Box sx={{ fontSize: "14px" }}>Note :</Box> : null}
                    {smeResponseStep ? (
                      <Box>
                        {smeResponseStep?.FormName} {" step selected with [ "}{" "}
                        {smeResponseStepuser?.map((item: any, index: any) => {
                          return `${index === 0 ? "" : ", "}` + item.EmailId + ` (${item.DepartmentName})`;
                        })}{" "}
                        {" ] user."}
                      </Box>
                    ) : null}
                    {smeReviewStep ? (
                      <Box>
                        {smeReviewStep?.FormName} {" step selected with [ "}{" "}
                        {smeReviewStepuser?.map((item: any, index: any) => {
                          return `${index === 0 ? "" : ", "}` + item.EmailId + ` (${item.DepartmentName})`;
                        })}{" "}
                        {" ] user."}
                      </Box>
                    ) : null}
                  </Box>

                  <Box sx={{ display: "flex", gap: 2, justifyContent: "right", mt: 1 }}>
                    <CustomButton
                      variant="contained"
                      name={"Import Question"}
                      // onClick={() => setAddNewQuestionOpen(true)}
                      onClick={() => setImportQuestionOpen(true)}
                    />
                    <CustomButton
                      variant="contained"
                      name={"Add New Question"}
                      // onClick={() => setAddNewQuestionOpen(true)}
                      onClick={() => AddNewQuestion()}
                    />

                    <CustomButton
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        setIsPopupCancel(true);
                      }}
                    />
                    {currentIndex !== 0 ? (
                      <CustomButton
                        disabled={!isDirty}
                        variant="contained"
                        name={"Proceed to previous"}
                        onClick={() => ProceedTonextCatagory(false)}
                      />
                    ) : null}

                    {currentIndex !== -1 ? (
                      <CustomButton
                        disabled={!isDirty}
                        variant="contained"
                        name={"Proceed to Next"}
                        onClick={() => ProceedTonextCatagory(true)}
                      />
                    ) : null}
                  </Box>
                </Box>
              </>
            ) : (
              <AddPreviewAssessmentQuestionnaireMaster
                view={false}
                edit={false}
                onClose={() => {
                  setAddNewQuestionOpen(false);
                }}
                HideHeader={true}
              />
            )}
          </>
        }
      />

      <CustomDialog
        show={importQuestionOpen}
        onHide={() => setImportQuestionOpen(false)}
        maxWidth={"sm"}
        header={"Import Question from existing AQM"}
        contentNode={
          <>
            <CustomSelect
              name={"service Type"}
              valueKey="value"
              optionLabelKey="label"
              options={aqmMappings}
              disabled={view}
              required={true}
              label={"Vendor Name"}
              value={importedAQM}
              onChange={setImportedAQM}
            />
            <Box sx={{ width: "100%", mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <CustomButton
                type="reset"
                variant="outlined"
                name={"Close"}
                onClick={() => setImportQuestionOpen(false)}
              />
              <CustomButton
                type="reset"
                variant="contained"
                name={"Import"}
                onClick={() => { importQuestionsFromAQM() }}
              />
            </Box>
          </>
        } />

      <ModalPopUp
        onDeleteMethod={() => onClose && onClose()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />

      <ModalPopUp
        onDeleteMethod={() => CloseTheQuestionSection()}
        isDelete={true}
        onHide={() => setIsPopupCancel(false)}
        show={IsPopupCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </>
  );
};
