import { AxiosError, AxiosResponse } from "axios";
import { SECURITY_PARAMETERS } from "./endpoints/securityParameters";
import { axiosInstance } from "./config/axiosConfig";
import {
  IAddSecurityParameterDataResponse,
  ISecurityParameterDataResponse,
  ISPColumnRes,
} from "../../models/securityParameters.types";

import {
  IAddApplicationParameterResponse,
  IApplicationParameterResponse,
  IVHColumnRes,
} from "../../models/applicationParameter.type";
import { APP_URL } from "./endpoints/applicationParameter";

// const addSecurityParameter = (requestBody: any): Promise<any> =>
//   axiosInstance
//     .post(SECURITY_PARAMETERS.AddSecurityParameter, requestBody)
//     .then((response: AxiosResponse<any>) => {
//       if (response.status === 200) {
//         return response.data;
//       }
//       throw new Error("Error getting Data" + response);
//     })
//     .catch((error: AxiosError | string) => {
//       throw error;
//     });

const addSecurityParameter = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(SECURITY_PARAMETERS.AddSecurityParameter, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getSecurityParameters = (): Promise<ISecurityParameterDataResponse> =>
  axiosInstance
    .get(SECURITY_PARAMETERS.GetSecurityParameter)
    .then((response: AxiosResponse<ISecurityParameterDataResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const deleteSecurityParameter = (requestBody: any): Promise<IAddSecurityParameterDataResponse> =>
  axiosInstance
    .post(SECURITY_PARAMETERS.DeleteSecurityParameter, requestBody)
    .then((response: AxiosResponse<IAddSecurityParameterDataResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getSecurityParameterHistory = (requestBody: any): Promise<ISPColumnRes> =>
  axiosInstance
    .get(SECURITY_PARAMETERS.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<ISPColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const updateSecurityParameter = (requestBody: any): Promise<any> =>
      axiosInstance
        .post(SECURITY_PARAMETERS.EditSecurityParameter, requestBody)
        .then((response: AxiosResponse<any>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });
    
const eventLogService = {
  addSecurityParameter,
  getSecurityParameters,
  getSecurityParameterHistory,
  deleteSecurityParameter,
  updateSecurityParameter,
};

export default eventLogService;
