import { Box, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import vendorDetailsService from "../../../store/services/vendorDetails";
import { updateToaster, updateLoader } from "../../../store/slices/loader";
import { updateVersionHistory } from "../../../store/slices/vendorData";
import { getVendorDetailsList, updateVendorDetailsAddandEdit, getVendorDetailsHistory } from "../../../store/slices/vendorDetails";
import { useAppDispatch, RootState } from "../../../store/store";
import { AddVendorDetails } from "../../vendorDetails/AddVendor";
import AddClause from "./AddClause";
import { activeInactiveClause, getClauseDataById, getClauseDetailsList, getClauseTypeList, updateClauseDetailsAddandEdit } from "../../../store/contractManagement/slices/clauseDataSlice";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { useNavigate } from "react-router-dom";
import AddClauseStepper from "./AddClauseStepper";

const columns = {
  table_name: "Clause",
  isAction: true,
  columns: [
    // {
    //   Field: "clause_Id",
    //   FieldType: "string",
    //   DisplayName: "ID",
    // },
    {
      Field: "clause_header",
      FieldType: "string",
      DisplayName: "Header"
    },
    {
      Field: "clause_type",
      FieldType: "string",
      DisplayName: "Type"
    },
    {
      Field: "regulator",
      FieldType: "string",
      DisplayName: "Regulator"
    },
    {
      Field: "regulatory_Reference",
      FieldType: "string",
      DisplayName: "Regulatory Reference"
    },
    {
      Field: "clause",
      FieldType: "string",
      DisplayName: "Clause"
    },
    {
      Field: "isSme",
      FieldType: "string",
      DisplayName: "Is SME"
    },
    {
      Field: "sme",
      FieldType: "string",
      DisplayName: "SME"
    },
    {
      Field: "isSLA",
      FieldType: "string",
      DisplayName: "Is SLA"
    },
    {
      Field: "isPm",
      FieldType: "string",
      DisplayName: "Is Pm"
    },
    {
      Field: "isRisk",
      FieldType: "string",
      DisplayName: "Is Risk"
    },
    {
      Field: "riskLevel",
      FieldType: "string",
      DisplayName: "Risk Level"
    },
    {
      Field: "riskCategory",
      FieldType: "string",
      DisplayName: "Risk Category"
    },  
    {
      Field: "isNonNegotiable",
      FieldType: "string",
      DisplayName: "Is Non-Negotiable"
    },  
    {
      Field: "isActive",
      FieldType: "string",
      DisplayName: "Status"
    },  
    {
      Field: "modifiedBy",
      FieldType: "string",
      DisplayName: "Modified By"
    }    
  ],
};

const ClauseList = () => {
  const dispatch = useAppDispatch();

  const contractManagementReducer:any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    clauseDetailsData,    
    isClauseDetailsLoading,
    isClauseDetailsAdded,
    clauseToasterStatus,
    isClauseDetailsUpdated,
    isClauseDetailsDeleted,
    clauseToasterMsg
  }:any = contractManagementReducer.clauseData;  

  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [clauseDetails, setClauseDetails] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [clauseDetailsDraftData, setClauseDetailsDraftData] = useState<any[]>([]);
  const [isDraftData, setIsDraftData] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isDeleteData, setIsDeleteData] = useState<any>('');
  const navigate = useNavigate();
  const columns_version: any = {
    table_name: "Vendor History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
  }, []);

  const getDraftData = () => {
    const dataObj = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "Clause Master",
      ScreenName: "Clause Master",
    };
    //dispatch(getAllSaveandDraftList(dataObj));
  };

  const initializeData = () => {
    dispatch(updateLoader(true));
    dispatch(getClauseDetailsList());
    //getDraftData();    
  };

  useEffect(() => {
    if (isClauseDetailsDeleted === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: clauseToasterMsg, isTosterFailed: clauseToasterStatus }));
      dispatch(updateClauseDetailsAddandEdit(false));
      initializeData();
      setIsDeleteData("");
      setOption("");
      //setisShowDraft(1)
    }
  }, [ clauseToasterStatus, isClauseDetailsDeleted]);

//   useEffect(() => {
//     if (isDraftAdded) {
//       dispatch(updateSaveasDraftAddandEdit(false));
//       dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
//       initializeData();
//       setisShowDraft(0)
//     } else if (isDraftUpdated) {
//       dispatch(updateSaveasDraftAddandEdit(false));
//       dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
//       initializeData();
//       setisShowDraft(0)
//     }
//   }, [isDraftAdded, isDraftUpdated]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  // useEffect(() => {
  //   if (versionHistory.VendorDetailsVHeader.length > 0 && versionHistory.VendorDetailsVHeader.length > 0) {
  //     if (versionHistory.VendorDetailsVHeader && versionHistory.VendorDetailsVHeader.length > 0) {
  //       setIsVersionHistory(true);
  //       let re_columns = {
  //         ...columns_version,
  //         columns: versionHistory.VendorDetailsVHeader,
  //       };
  //       setVersionHistoryData(re_columns);
  //       console.log(versionHistory, "versionHistory ");
  //     }
  //   }
  // }, [versionHistory]);

  useEffect(() => {
    setClauseDetails(clauseDetailsData);
    dispatch(updateLoader(false));
  }, [clauseDetailsData]);

  useEffect(() => {
    let modifiedData: any[] = [];
    if (draftdata && Array.isArray(draftdata)) {
      draftdata &&
        draftdata.forEach((item: any) => {
          let JsonData = JSON.parse(item.DraftData);
          modifiedData.push({
            ...JsonData.VendorDetails,
            IsListedValue: JsonData.VendorDetails?.IsListed ? "Yes" : "No",
            IsRegulatedValue: JsonData.VendorDetails?.IsRegulated ? "Yes" : "No",
            DraftStatus: item.DraftStatus,
            DraftId: item.DraftId,
          });
        });
    }
    setClauseDetailsDraftData(modifiedData);
  }, [draftdata]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      dispatch(
        getVendorDetailsHistory({
          Vendor_Id: data.Vendor_Id,
          formID: 22,
        })
      );
      setIsAddorEdit(false);
      // setIsVersionHistory(true);
    } else if(option === "delete"){
      setOption(option);
      setIsDeleteData(data);
    }else {
      dispatch(updateLoader(true));
      // if (isShowDraft) {
      //   const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
      //   setIsAddorEdit(true);
      //   setOption(option);
      //   setIsEditDate(res.data);
      //   setIsDraftData(false);
      // }
      // else {
      // let filteredData = draftdata.find((item: any) => item.DraftId === data.DraftId);
      // let JsonData = JSON.parse(filteredData?.DraftData);
      
      dispatch(getClauseDataById(data?.clause_Id))
        .unwrap()
        .then((clauseData: any) => {
          setIsEditDate({ ...clauseData });
          setIsAddorEdit(true);
          setOption(option);          
        })
        .catch(error=>{
          console.log(error)
        });
      //setIsDraftData(true);
      //}
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    // setIsDraftDate(null)
    setOption("");
    initializeData();
    setIsDraftData(false);
    //navigate("/addClause")
  };

  const active_Inactive_Clause = () => {
    console.log(isDeleteData);
    let inactiveFormData = {
      clause_Id: isDeleteData.clause_Id,
      isActive: isDeleteData?.isActive === "Active" ? false: true,
      updated_By: userLoginResponse?.UserName,     
      approved_By: userLoginResponse?.UserName,
      userId : userLoginResponse?.UserId,
      moduleName :"Clause Library",
      activity : isDeleteData?.isActive === "Active"? "inactive" : "active",
      createdBy : userLoginResponse?.UserName      
    };
    dispatch(activeInactiveClause(inactiveFormData));
  };

  return (
    <>
      {!isAddorEdit ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                Clause Library Master
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={isShowDraft ? clauseDetails : clauseDetailsDraftData}
                handleAction={handleOpen}
                handleAdd={() => setAddFormcloseoropen(true)}
                enableZoomIcon={true}
                showhistory={false}
                isDraftView={false}
                draftCount={clauseDetailsDraftData.length}
                isEnableDraft={false}
                handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={permssionPage?.IsAdd}
                showDelete={true}
                showView={permssionPage?.IsView}
                showEdit={permssionPage?.IsEdit}
                showExports={permssionPage?.IsExport}
                userId={userLoginResponse?.UserId || 0}
                pageName="Clause Master"
                isActivekey="isActive"
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <AddClauseStepper
            view={Option == "view" ? true : false}
            edit={Option == "edit" ? true : false}
            isEditData={isEditData}           
            onClose={() => setAddFormcloseoropen(false)}
            isDraftData={isDraftData}
          />
        </Box>
      )}

      {/* <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Clause Master Version History"}
        contentNode={
          <> <MaterialDynamicGrid
            data={versionHistoryData}
            rows={versionHistory.VendorDetailsRecords}
            handleAction={handleOpen}
            enableZoomIcon={false}
            showhistory={false}
            showDelete={permssionPage?.IsDelete === 1}
            showView={permssionPage?.IsView === 1}
            showEdit={permssionPage?.IsEdit === 1}
            showExports={permssionPage?.IsExport === 1}
            userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
            pageName="Clause Master Version History"
          />
          </>
        }
      /> */}
      <ModalPopUp
        onDeleteMethod={() => active_Inactive_Clause()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.isActive === "Active" ? "deactivate" : "activate"} the selected Clause`}
        ActionName={isDeleteData?.isActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
};


 export default ClauseList;
