import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import makerCheckerService from '../services/makerCheckerService';
import { IMakerChecker } from '../../models/makerChecker';


export interface IMakerCheckerData {
  makerCheckers: IMakerChecker[];
  isLoading: boolean;
  updated: boolean;
}

export const initialState: IMakerCheckerData = {
  makerCheckers: [],
  isLoading: false,
  updated: false
};

export const getMakerCheckerList = createAsyncThunk('getMakerCheckerList', async(requesrParams: {category: string}, {rejectWithValue})=>{
    try{
        const response= await makerCheckerService.getMakerCheckerData(requesrParams);
        const {MakerCheckers, TotalCount} =response.data;
        return {MakerCheckers, TotalCount}
    }catch(error){
        return rejectWithValue(error)
    }
})

export const updateMakerCheckerList = createAsyncThunk('updateMakerCheckerList', async(requestBody: any, {rejectWithValue})=>{
    try{
        const response= await makerCheckerService.updateMakerChecker(requestBody);
        return response
    }catch(error){
        return rejectWithValue(error)
    }
})

export const roleDataSlice = createSlice({
    name: 'makerCheckers',
    initialState,
    reducers:{
        updateMakerChecker:(state, action)=>{            
            state.updated =action.payload
        },
    },
    extraReducers: (builder)=>{
        builder.addCase(getMakerCheckerList.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getMakerCheckerList.fulfilled, (state, action: PayloadAction<any>)=>{
            state.makerCheckers = action.payload.MakerCheckers;            
            state.isLoading = false;
        })
        .addCase(getMakerCheckerList.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })
        .addCase(updateMakerCheckerList.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(updateMakerCheckerList.fulfilled, (state, action: PayloadAction<any>)=>{                    
            state.isLoading = false;
            state.updated = true;
        })
        .addCase(updateMakerCheckerList.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false
        })            
    }
});

export const {updateMakerChecker } = roleDataSlice.actions;
 export default roleDataSlice.reducer;