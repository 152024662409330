import { AxiosError, AxiosResponse } from "axios";
import { FILE_HANDLER } from "./endpoints/fileHandler";
import { axiosInstance } from "./config/axiosConfig";
import { REGIONALSETTINGS_URL } from "./endpoints/commonMasters";

const UploadAFile = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(FILE_HANDLER.UploadAFile, requestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const DownLoadAFile = (reqparams: any): Promise<any> =>
  axiosInstance
    .get(FILE_HANDLER.DownLoadAFile, { params: reqparams })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const fileHandlerService = {
  UploadAFile,
  DownLoadAFile,
};

export default fileHandlerService;
