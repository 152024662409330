import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { IMenu } from "../../models/loggin.type";
import CustomButton from "../../common/dynamicButton/CustomButton";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { getRenewal, GetServicesByProjectID } from "../../store/slices/renewalSlices";
import { IRenewalData } from "../../models/renewal.type";
import { VOBMainWorkFlow } from "../vendorOnBoarding/vendorOnBoardingFromWorkflow/VOBMain";
import workFlowService from "../../store/services/workFlowService";
import { ACTIVE, Renewal_Date, RenewalParameterName } from "../../utils/constants";

import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import { getApplicationParameterList } from "../../store/slices/applicationParameterData";
import { WorkFlowStatusName } from "../../config/config";
import { ViewAssessmentHistory } from "./ViewAssesmentHistory";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { getServiceCatalogList } from "../../store/slices/serviceCatalog";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const formatDate = (dateString: string) => {
  return dayjs(dateString).utc().format("DD-MMM-YYYY");
};

interface RenewalRowData extends IRenewalData {
  isAction: boolean;
  isExpired: boolean;
  isExpiredstring: string;
  Renewal_Project_Id: any;
  Days_Until_Expiry: number;
}

export const Renewal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { renewalData, ServicesByProjectIDData } = useSelector((state: RootState) => state.renewal);
  const [showRenewal, setShowRenewal] = useState<boolean>(false);
  const [rowRenewalData, setRowRenewalData] = useState<RenewalRowData | null>(null);
  const [rowData, setRowData] = useState<RenewalRowData[]>([]);
  const [filteredData, setFilteredData] = useState<RenewalRowData[]>([]);
  const [toggleSwitch, setToggleSwitch] = useState<boolean>(false);
  const { currentPagePermissionData, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const [renewalDate, setRenewalDate] = useState<any>("");
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);

  const currentDate = new Date();

  useEffect(() => {
    dispatch(getRenewal());
    dispatch(getServiceCatalogList());
  }, []);

  useEffect(() => {
    dispatch(
      getApplicationParameterList({
        FormID: 2,
      })
    );
  }, []);
  useEffect(() => {
    console.log(filteredData, 'filteredData renew');

  }, [filteredData])

  const Renewal = () => {
    const data = applicationParameterData
      .filter((param: any) => param?.Parameter_Name === RenewalParameterName && param?.IsActive === "Active")
      .map((param) => param.Parameter_Value);
    return data ? Number(data[data.length - 1]) : Renewal_Date;
  };

  useEffect(() => {
    if (applicationParameterData && applicationParameterData?.length > 0) {
      const getData = Renewal();
      setRenewalDate(getData);
    }
  }, [applicationParameterData]);

  const processRenewalData = useCallback(
    (data: IRenewalData[]) => {
      return data.map((value: any) => {
        const endDate = new Date(value.Project_End_Date);
        const difference = Math.floor((endDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
        const isExpired = endDate < currentDate;
        const isExpiredstring = isExpired ? "true" : "false";
        const isRenewstring = (value.Renewal_Project_Id == 0 || value.Renewal_Project_Id == null) ? "No" : "Yes";
        const workFlowStatus = value.Workflow_Status;
        const isAction =
          difference <= renewalDate && value.Workflow_Status && value.Workflow_Status === WorkFlowStatusName?.Completed && (value.Renewal_Project_Id == 0 || value.Renewal_Project_Id == null)
            ? true
            : difference <= renewalDate && !value.Workflow_Status
              ? true
              : false;

        return {
          ...value,
          isAction,
          isExpired,
          isExpiredstring,
          workFlowStatus,
          Days_Until_Expiry: isExpired ? Math.abs(difference) : difference,
          isRenewedorNot: isRenewstring,
        };
      });
    },
    [renewalDate]
  );

  useEffect(() => {
    if (renewalData.length > 0) {
      const updatedRowData = processRenewalData(renewalData);
      setRowData(updatedRowData);
      setFilteredData(updatedRowData.filter((row) => !row.isExpired && row.workFlowStatus !== "Running"));
    }
  }, [renewalData, processRenewalData]);

  const handleToggleSwitch = useCallback(
    (data: boolean) => {
      setToggleSwitch(data);
      const updatedRowData = processRenewalData(renewalData);
      setFilteredData(
        updatedRowData.filter((row) =>
          data ? row.isExpired && row.workFlowStatus !== "Running" : !row.isExpired && row.workFlowStatus !== "Running"
        )
      );
    },
    [processRenewalData, renewalData]
  );

  const handleOpen = async (props: any, option: string) => {
    if (option === "renew") {
      try {
        const fetchData = await workFlowService.getWorkFlowByCodeandId({
          Id: props?.Workflow_Id,
          workflowCode: props?.Workflow_Code,
        });
        const { data } = fetchData;
        const updatedData = {
          ...data,
          ...props,
          Project_Start_Date: props.Project_Start_Date,
          Project_End_Date: props.Project_End_Date,
          Workflow_Id: props.Workflow_Id,
          Project_Description: props.Project_Description,
          Estimate_Spend: props.Estimated_Spend,
          Project_Name: props.Project_Name,
          WorkflowProcessAsJson: data?.WorkflowProcessAsJson,
        };
        setShowRenewal(true);
        setRowRenewalData(updatedData);
      } catch (error) {
        console.error("Error fetching workflow data:", error);
        // Handle error with user-friendly message or fallback UI here
      }
    } else if (option === "view") {
      console.log(props, "View data");
      setIsEditData(props);
      setIsEdit(true);
      dispatch(
        GetServicesByProjectID({
          ProjectID: props?.Project_Detail_Id,
        })
      );
    }
  };

  return (
    <React.Fragment>
      {!rowRenewalData ? (
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                <PageMainHeading title={currentPagePermissionData?.MenuName || ""} />
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
              <CustomToogleSwitch
                label={""}
                dynamicLabel={toggleSwitch ? "Expired Assessment" : "Unexpired Assessment"}
                value={toggleSwitch}
                onChange={() => handleToggleSwitch(!toggleSwitch)}
              />
            </Box>
            <MaterialDynamicGrid
              data={{
                table_name: "Assessment",
                isAction: true,
                columns: [
                  { Field: "Assessment_Number", DisplayName: "Assessment No.", FieldType: "string" },
                  { Field: "Workflow_Name", DisplayName: "Workflow", FieldType: "string" },
                  { Field: "Assessment_Type_1", DisplayName: "Assessment Type I", FieldType: "string" },
                  { Field: "Assessment_Type_2", DisplayName: "Assessment Type II", FieldType: "string" },
                  { Field: "Vendor_Name", DisplayName: "Vendor Name", FieldType: "string" },
                  { Field: "Project_Name", DisplayName: "Project / Service Name", FieldType: "string" },
                  { Field: "Project_Start_Date", DisplayName: "Start Date", FieldType: "date" },
                  { Field: "Project_End_Date", DisplayName: "End Date", FieldType: "date" },

                  // { Field: "workFlowStatus", DisplayName: "workFlow Status", FieldType: "string" },
                  {
                    Field: "Days_Until_Expiry",
                    DisplayName: !toggleSwitch ? "Days Left" : "Overdue",
                    FieldType: "string",
                  },
                  { Field: "isRenewedorNot", DisplayName: "Is Renewed", FieldType: "string" },
                ],
              }}
              rows={filteredData}
              handleAction={handleOpen}
              enableZoomIcon={true}
              showDelete={false}
              showhistory={false}
              showView={true}
              showEdit={false}
              showExports={true}
              showRenewal={true}
              userId={userLoginResponse?.UserId || 0}
              pageName={currentPagePermissionData?.MenuName || ""}
            />
          </Stack>
        </Box>
      ) : (
        showRenewal &&
        rowRenewalData && (
          <VOBMainWorkFlow
            data={rowRenewalData}
            onClose={() => {
              setShowRenewal(false);
              setRowRenewalData(null);
            }}
            isRenewal={showRenewal}
          />
        )
      )}

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"View Assessment Services" }
        contentNode={
          <>

            <ViewAssessmentHistory
              view={true}
              closePopUp={() => {
                setIsEdit(false);
              }}
              isEditData={ServicesByProjectIDData}
            />

          </>
        }
      />
    </React.Fragment>
  );
};
