import React, { useEffect } from "react";
import CompanyInformations from "./companyInformations/CompanyInformations";
import RegulatoryDetails from "./RegulatoryDetails/RegulatoryDetails";
import CustomAccordion from "../../../common/dynamicAccordion/CustomAccordion";
import { INACTIVE } from "../../../utils/constants";

interface ICompanyDetailsProps {
  view?: boolean;
  edit?: boolean;
  expanded: string | null;
  handleChange: (panel: string | null) => void;
  formData?: any;
}

const CompanyDetails: React.FC<ICompanyDetailsProps> = ({ edit, view, expanded, handleChange, formData }) => {
  return (
    <div>
      <CustomAccordion
        expanded={expanded === "companyDetails" || expanded === "All"}
        handleChange={handleChange("companyDetails")}
        title={"Company Details"}
        details={<CompanyInformations edit={edit} isWorkFlow={false} view={view} formData={formData} />}
      />
      <CustomAccordion
        expanded={expanded === "regulatoryDetails" || expanded === "All"}
        handleChange={handleChange("regulatoryDetails")}
        title={"Regulatory Details"}
        details={
          <>
            <RegulatoryDetails edit={edit} isWorkFlow={false} view={view} formData={formData} />
          </>
        }
      />
    </div>
  );
};

export default CompanyDetails;
