import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../../store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateToaster } from "../../../store/slices/loader";
import {
  getContractList,
  updateContractDetailsAddandEdit,
  activeInactiveContract,
} from "../../../store/contractManagement/slices/contractListSlice";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import RandRSetup from "./RandRSetup";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";

const columns = {
  table_name: "Contract",
  isAction: true,
  columns: [
    {
      Field: "contract_Number",
      FieldType: "string",
      DisplayName: "Sr. No",
    },
    {
      Field: "contractType",
      FieldType: "string",
      DisplayName: "Workflow",
    },
    {
      Field: "amount",
      FieldType: "number",
      DisplayName: "Estimated Days",
    },
    {
      Field: "amount",
      FieldType: "number",
      DisplayName: "No of Steps",
    },
    {
      Field: "auditFields",
      FieldType: "string",
      DisplayName: "Audit fields",
    },
    {
      Field: "isActive",
      FieldType: "string",
      DisplayName: "Status",
    },
  ],
};

export interface IRandRMasterProps {
  view?: boolean;
}

const RandRMaster: React.FC<IRandRMasterProps> = ({ view = false }) => {
  const dispatch = useAppDispatch();
  const { contractDetailsData, isContractDetailsDeleted, ToasterMsg } = useSelector(
    (state: any) => state.contractManagementReducer?.contractlist
  );

  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [contractlist, setContractList] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);

  const columns_version: any = {
    table_name: "Vendor History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = () => {
    dispatch(getContractList());
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      setIsAddorEdit(false);
      // setIsVersionHistory(true);
    } else if (option === "delete") {
      setOption(option);
      setIsDeleteData(data);
    } else {
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    setOption("");
  };

  useEffect(() => {
    setContractList(contractDetailsData);
  }, [contractDetailsData]);

  const handleDelete = () => {
    let inactiveFormData = {
      vendor_Contract_Id: isDeleteData.contractId,
      isActive: isDeleteData?.isActive === "Active" ? false : true,
      updated_By: userLoginResponse?.UserName,
      approved_By: userLoginResponse?.UserName,
      userId: userLoginResponse?.UserId,
      moduleName: "Contracts",
      activity: isDeleteData?.isActive === "Active" ? "inactive" : "active",
      createdBy: userLoginResponse?.UserName,
    };
    dispatch(activeInactiveContract(inactiveFormData));
  };

  useEffect(() => {
    if (isContractDetailsDeleted === true) {
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.message,
          isTosterFailed: ToasterMsg.status !== "Success",
        })
      );
      dispatch(updateContractDetailsAddandEdit(false));
      initializeData();
      setIsDeleteData("");
      setOption("");
    }
  }, [ToasterMsg, isContractDetailsDeleted]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <React.Fragment>
            {view ? null : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Typography variant="h6" color={"text.primary"}>
                  R & R Setup
                </Typography>
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={contractlist}
                handleAction={handleOpen}
                handleAdd={() => setAddFormcloseoropen(true)}
                enableZoomIcon={true}
                showhistory={false}
                isDraftView={false}
                draftCount={0}
                isEnableDraft={false}
                handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={!view}
                showDelete={!view}
                showView={!view}
                showEdit={!view}
                showExports={false}
                userId={userLoginResponse?.UserId || 0}
                pageName="R & R Setup"
                disableselect={true}
                isActivekey="isActive"
              />
            </Box>
          </React.Fragment>
        </Stack>
        <CustomDialog
          show={isAddorEdit}
          onHide={() => setIsAddorEdit(false)}
          maxWidth="lg"
          header="R & R Setup"
          contentNode={<RandRSetup />}
        />
        <ModalPopUp
          onDeleteMethod={() => handleDelete()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          text={`Are you sure you want to ${
            isDeleteData?.isActive === "Active" ? "deactivate" : "activate"
          } the selected Contract`}
          ActionName={isDeleteData?.isActive === "Active" ? "Inactive" : "Active"}
        />
      </Box>
    </>
  );
};

export default RandRMaster;
