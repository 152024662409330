import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddRiskRateResponse, IRiskRateResponse, IVHColumnRes } from "../../models/riskRating.type";
import { RISKMASTER_URL } from "./endpoints/commonMasters";


const getRiskRateList = (reqparams: any): Promise<IRiskRateResponse> =>
  axiosInstance
    .get(RISKMASTER_URL.GetRiskCategories)
    .then((response: AxiosResponse<IRiskRateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addEditRiskRate = (requestBody: any): Promise<IAddRiskRateResponse> =>
  axiosInstance
    .post(RISKMASTER_URL.AddEditRiskCategory, requestBody)
    .then((response: AxiosResponse<IAddRiskRateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteRiskRate = (requestBody: any): Promise<IAddRiskRateResponse> =>
  axiosInstance
    .post(RISKMASTER_URL.DeleteRiskCategory, requestBody)
    .then((response: AxiosResponse<IAddRiskRateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryRiskRate = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(RISKMASTER_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const RiskRateService = {
    getRiskRateList,
    addEditRiskRate,
    deleteRiskRate,
    getVersionHistoryRiskRate,
};

export default RiskRateService;
