import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { recordLockTimeOut } from "../../config/config";
import ProgressBar from "../../common/progressBar/progressBar";

export const recordLockGet = (id: any, permissionPage: any, moduleName: any) => {
  return {
    MenuId: permissionPage?.MenuId,
    ModuleName: moduleName,
    RecordId: id,
  };
};

export const HandleAddRecordLock = (
  id: number,
  userLoginResponse: any,
  permissionPage: any,
  moduleName: string,
  activity: string
) => {
  return {
    RecordId: id,
    UserId: userLoginResponse?.UserId || 0,
    CreatedBy: userLoginResponse?.UserName || "",
    MenuId: permissionPage?.MenuId,
    ModuleName: moduleName,
    Activity: activity,
  };
};

export const HandleRecordLockCheck = async (
  recordId: number,
  permissionPage: any,
  moduleName: any,
  dispatch: any,
  setIsEditing?: any
) => {
  const recordLockData = recordLockGet(recordId, permissionPage, moduleName);
  const resultAction = await dispatch(GetRecordLock(recordLockData));
  if (GetRecordLock.fulfilled.match(resultAction)) {
    const recordLockData = resultAction.payload;
    if (recordLockData && recordLockData.length > 0) {
      dispatch(updateLoader(false));
      if (setIsEditing) {
        setIsEditing(true);
      }
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
          isTosterFailed: true,
        })
      );

      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const RecordLockUpdate = () => {
  const dispatch = useAppDispatchThunk();
  const { recordLock } = useSelector((state: RootState) => state.recordLock);

  const handleRecordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      const data = {
        RecordlockId: recordLock[0].RecordlockId,
        RecordId: recordLock[0].RecordId,
        IsActive: false,
      };
      dispatch(UpdateRecordLock(data));
    }
  };

  return { handleRecordLockUpdate };
};

const RecordLockDialog = ({
  recordId,
  moduleName,
  setIsEditing,
}: {
  recordId: any;
  moduleName: any;
  setIsEditing: any;
}) => {
  const dispatch = useAppDispatchThunk();
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const checkRecordLock = async () => {
    setIsLoading(true);
    const canEdit = await HandleRecordLockCheck(
      recordId,
      currentPagePermissionData,
      moduleName,
      dispatch,
      setIsEditing
    );
    setIsLoading(false);
    dispatch(updateLoader(true));
    if (canEdit) {
      dispatch(updateLoader(false));
      setIsEditing(true);
      const dispatchData = HandleAddRecordLock(
        recordId,
        userLoginResponse,
        currentPagePermissionData,
        moduleName,
        "Edit"
      );
      await dispatch(AddRecordLock(dispatchData));
      const recordLockData = recordLockGet(recordId, currentPagePermissionData, moduleName);
      await dispatch(GetRecordLock(recordLockData));
    } else {
      setIsEditing(false);
      dispatch(updateLoader(false));
    }
  };
  useEffect(() => {
    checkRecordLock();
  }, []);

  const handleUserActivity = () => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(() => {
      setIsPromptVisible(true);
    }, recordLockTimeOut);
  };

  const handlePromptResponse = (response: boolean) => {
    if (response) {
      handleUserActivity();
    } else {
      setIsPromptVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keypress", handleUserActivity);
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keypress", handleUserActivity);
    };
  }, []);

  return (
    <>
      {isPromptVisible && (
        <ModalPopUp
          onDeleteMethod={() => handlePromptResponse(false)}
          isDelete={false}
          onHide={() => setIsPromptVisible(false)}
          show={isPromptVisible}
          header={"Confirm"}
          isLoading={isLoading}
          text={`Are you still editing?`}
          ButtonName={"Yes"}
        />
      )}
    </>
  );
};

export default RecordLockDialog;
