import { DynamicFieldData } from "../common/dynamicForm/dynamicControlType";

export const fields: DynamicFieldData[] = [
  {
    fieldName: "name",
    inputType: "text",
    label: "Name",
    defaultValue: "",
    config: {
      required: "Required",
    },
  },
  {
    fieldName: "age",
    inputType: "number",
    label: "Age",
    defaultValue: 18,
    config: {
      required: "Required",
      validate: (value: number) => value >= 18 || "Still a minor",
    },
  },
  {
    fieldName: "language",
    inputType: "select",
    label: "Language",
    options: [
      { value: "english", label: "English" },
      { value: "french", label: "French" },
    ],
    defaultValue: "english",
  },
  {
    fieldName: "address",
    inputType: "text",
    label: "Address",
    defaultValue: "",
  },
];

export const columns_data: any = {
  columns: [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      isEditable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      type: "string",
      isEditable: true,
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      description: "adS",
      width: 110,
      isEditable: true,
    },
    {
      field: "role",
      headerName: "Department",
      width: 220,
      isEditable: true,
      type: "singleSelect",
      valueOptions: ["Market", "Finance", "Development"],
    },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      isEditable: true,
      description: "Age",
      width: 110,
    },
    {
      field: "actions",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      type: "string",
      width: 160,
    },
  ],

  // disableFilter: true,
  // hideFooterPagination: false,
  // hideFooter: false,
  enableCheckBox: true,
  // pageSizeOptions: [1, 2, 5, 10],
  tableHeight: 500,
  // rowHeight: 50 ,
};

export const departmentImportdata: any = [
  {
    Field: "DepartmentName",
    FieldType: "string",
    DisplayName: "Department Name",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
  {
    Field: "Department_Code",
    FieldType: "string",
    DisplayName: "Department Code",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
];

export const industoryImportdata: any = [
  {
    Field: "Industry_Name",
    FieldType: "string",
    DisplayName: "Industry Name",
    config: {
      required: "Required",
      min: 2,
      max: 50,
    },
  },
  {
    Field: "Industry_Name",
    FieldType: "string",
    DisplayName: "Industry Name",
    config: {
      required: "Required",
      min: 2,
      max: 50,
    },
  },
];

export const citylocationImportdata: any = [
  {
    Field: "Location_Name",
    FieldType: "string",
    DisplayName: "Location Name",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
  {
    Field: "Start_Pin",
    FieldType: "number",
    DisplayName: "Start Pin",
    config: {
      required: "Required",
      min: 1,
      max: 6,
    },
  },
  {
    Field: "End_Pin",
    FieldType: "number",
    DisplayName: "End Pin",
    config: {
      required: "Required",
      min: 1,
      max: 6,
    },
  },
];

export const statelocationImportdata: any = [
  {
    Field: "Location_Name",
    FieldType: "string",
    DisplayName: "Location Name",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
];

export const countrylocationImportdata: any = [
  {
    Field: "Location_Name",
    FieldType: "string",
    DisplayName: "Location Name",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
];

export const thirdPartyImportdata: any = [
  {
    Field: "ThirdParty_Name",
    FieldType: "string",
    DisplayName: "ThirdParty Name",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
  {
    Field: "ShortName",
    FieldType: "string",
    DisplayName: "Short Name",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
  {
    Field: "ThirdParty_Address",
    FieldType: "string",
    DisplayName: "Address",
    config: {
      min: 4,
      max: 50,
    },
  },
  {
    Field: "PinCode",
    FieldType: "number",
    DisplayName: "Pin",
    config: {
      min: 1,
      max: 999999,
    },
  },
  {
    Field: "City",
    FieldType: "string",
    DisplayName: "City",
    config: {
      min: 4,
      max: 50,
    },
  },
  {
    Field: "ContactPerson",
    FieldType: "string",
    DisplayName: "Contact Persion",
    config: {
      min: 4,
      max: 50,
    },
  },
  {
    Field: "ContactNumber",
    FieldType: "number",
    DisplayName: "Contact Number",
    config: {
      min: 100000,
      max: 99999999999999,
    },
  },
  {
    Field: "EmailId",
    FieldType: "string",
    DisplayName: "Email Id",
    config: {
      common: "Email",
      min: 1,
      max: 100,
    },
  },
];

export const regionalSettingsImportdata: any = [
  {
    Field: "Setting_Name",
    FieldType: "string",
    DisplayName: "Setting Name",
    config: {
      required: "Required",
      min: 1,
      max: 50,
    },
  },
  {
    Field: "Equivalent",
    FieldType: "string",
    DisplayName: "Equivalent",
    config: {
      required: "Required",
      min: 1,
      max: 50,
    },
  },
  {
    Field: "DataWidth",
    FieldType: "number",
    DisplayName: "Data Width",
    config: {
      required: "Required",
      min: 1,
      max: 999999999,
    },
  },
  {
    Field: "Formate",
    FieldType: "string",
    DisplayName: "Formate",
    config: {
      min: 1,
      max: 100,
    },
  },
  {
    Field: "IsMandatory",
    FieldType: "boolean",
    DisplayName: "IsMandatory",
    config: {
      required: "Required",
    },
  },
];

export const currencyImportdata: any = [
  {
    Field: "Currency_Name",
    FieldType: "string",
    DisplayName: "Currency Name",
    config: {
      required: "Required",
      min: 1,
      max: 100,
    },
  },
  {
    Field: "Currency_Code",
    FieldType: "string",
    DisplayName: "Currency Code",
    config: {
      required: "Required",
      min: 1,
      max: 3,
    },
  },
  {
    Field: "Symbol",
    FieldType: "string",
    DisplayName: "Symbol",
    config: {
      required: "Required",
      min: 1,
      max: 1,
    },
  },
];

export const branchImportdata: any = [
  {
    Field: "BranchName",
    FieldType: "string",
    DisplayName: "Branch Name",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
  {
    Field: "BranchCode",
    FieldType: "string",
    DisplayName: "Branch Code",
    config: {
      required: "Required",
      min: 1,
      max: 50,
    },
  },
  {
    Field: "Region",
    FieldType: "string",
    DisplayName: "Region",
    config: {
      required: "Required",
      min: 2,
      max: 50,
    },
  },
  {
    Field: "State",
    FieldType: "string",
    DisplayName: "State",
    config: {
      required: "Required",
      min: 2,
      max: 50,
    },
  },
  {
    Field: "District",
    FieldType: "string",
    DisplayName: "District",
    config: {
      required: "Required",
      min: 2,
      max: 50,
    },
  },
  {
    Field: "City",
    FieldType: "string",
    DisplayName: "City",
    config: {
      required: "Required",
      min: 2,
      max: 50,
    },
  },
];

export const documentImportdata: any = [
  {
    Field: "Document_Name",
    FieldType: "string",
    DisplayName: "Document",
    config: {
      required: "Required",
      min: 4,
      max: 50,
    },
  },
  {
    Field: "ShortName",
    FieldType: "string",
    DisplayName: "Short Name",
    config: {
      required: "Required",
      min: 1,
      max: 50,
    },
  },
  {
    Field: "IsMandatory",
    FieldType: "boolean",
    DisplayName: "IsMandatory",
    config: {
      required: "Required",
    },
  },
];

export const AQMMappingDatadublicate = {
  assessmentQuestionaireMapping: {
    Assessment_Questionaire_Mapping_Id: 0,
    Assessment_Number: "7678687HF",
    Vendor_Id: 107,
    Validity_in_Months: 12,
    Assessment_Type_1: 26,
    Assessment_Type_2: 123,
    WorkFlow_Id: 1,
    Start_Date: "2024-06-27T18:30:00.000Z",
    Target_Date: "2024-06-28T18:30:00.000Z",
    Category: "[102,101]",
    Approved_By: "Karthick A",
    Updated_By: "Karthick A",
    Updated_On: "2024-06-28T13:20:48.307Z",
    Approved_On: "2024-06-28T13:20:48.307Z",
    Status: 1,
    UserId: 1,
    ModuleName: "AssessmentQuestionaire",
    Activity: "create",
    CategoryQuestions: [
      {
        Catagory_Id: 102,
        Options: "ESG - Environmental Social Goverance",
        Cat_Wtg: "50",
        Questions: [
          {
            Assessment_Questionaire_Mapping_Id: 0,
            AQM_Category_Questions_Id: 102,
            Assessment_Questionaire_Id: 44,
            Consider_for_Scoring: 1,
            Question: "What is the extent of dependency/concetration over the vendor for these outsourced operations?",
            Ques_Wtg: "25",
            Answer_Type: true,
            Updated_By: "Karthick A",
            Updated_On: "2024-06-28T13:13:12.335Z",
            IsActive: true,
            Approved_By: "Karthick A",
            Approved_On: "2024-06-28T13:13:12.335Z",
            Status: 0,
            best_Score: 0,
            actual_Score: 0,
            deviation: 0,
            Respondor: "Vendor",
            SME_Status: 0,
            SME_Remark: "",
            SO_Remark: "need to verification",
            Do_Status: 1,
            Do_Remark: "",
            To_DO: false,
            To_PM: false,
            Answer: "",
            ChoicesScore: 2,
            Risk_Scorig: [
              { risk_id: 143, score: 2 },
              { risk_id: 116, score: 2 },
              { risk_id: 115, score: 2 },
              { risk_id: 114, score: 2 },
            ],
            ApplicableRisks: [143, 116, 115, 114],
            ApplicableRisk_LowScore: [
              { risk_id: 143, score: 2 },
              { risk_id: 116, score: 2 },
              { risk_id: 115, score: 2 },
              { risk_id: 114, score: 2 },
            ],
            choices: [
              {
                Assessment_Questionaire_RatingId: 84,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "0% -25 %",
                Score: 1,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
              {
                Assessment_Questionaire_RatingId: 85,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "25% -50 %",
                Score: 2,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
              {
                Assessment_Questionaire_RatingId: 86,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "50% - 75%",
                Score: 3,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
              {
                Assessment_Questionaire_RatingId: 87,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "75% - 100%",
                Score: 4,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
            ],
          },
          {
            Assessment_Questionaire_Mapping_Id: 0,
            AQM_Category_Questions_Id: 102,
            Assessment_Questionaire_Id: 43,
            Consider_for_Scoring: 2,
            Question: "Are there functions that largly dependent on the vendor's services?",
            Ques_Wtg: "25",
            Answer_Type: true,
            Updated_By: "Karthick A",
            Updated_On: "2024-06-28T13:13:14.525Z",
            IsActive: true,
            Approved_By: "Karthick A",
            Approved_On: "2024-06-28T13:13:14.525Z",
            Status: 0,
            best_Score: 0,
            actual_Score: 0,
            deviation: 0,
            Respondor: "Vendor",
            SME_Status: 1,
            SME_Remark: "i verified this is ok",
            SO_Remark: "",
            Do_Status: 1,
            Do_Remark: "Document Needed",
            To_DO: false,
            To_PM: false,
            ApplicableRisks: [143, 115, 114],
            ApplicableRisksScore: [
              { risk_id: 143, score: 0 },
              { risk_id: 115, score: 0 },
              { risk_id: 114, score: 0 },
            ],
            choices: [
              {
                Assessment_Questionaire_RatingId: 84,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "Yes",
                Score: 1,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
              {
                Assessment_Questionaire_RatingId: 85,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "No",
                Score: 2,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
            ],
          },
        ],
        onChange: true,
      },
      {
        Catagory_Id: 101,
        Options: "A&R (Audit and Review)",
        Cat_Wtg: "50",
        Questions: [
          {
            Assessment_Questionaire_Mapping_Id: 0,
            AQM_Category_Questions_Id: 101,
            Assessment_Questionaire_Id: 31,
            Consider_for_Scoring: 2,
            Question: "How many team members do you have?",
            Ques_Wtg: "50",
            Answer_Type: true,
            Updated_By: "Karthick A",
            Updated_On: "2024-06-28T13:13:19.908Z",
            IsActive: true,
            Approved_By: "Karthick A",
            Approved_On: "2024-06-28T13:13:19.908Z",
            Status: 0,
            actual_Score: 0,
            best_Score: 0,
            deviation: 0,
            Respondor: "SME",
            SME_Status: 0,
            SME_Remark: "Need to verification one more time",
            SO_Remark: "",
            Do_Status: 1,
            Do_Remark: "Verfication Completed",
            To_DO: false,
            To_PM: false,
            ApplicableRisks: [115, 114],
            ApplicableRisksScore: [
              { risk_id: 115, score: 2 },
              { risk_id: 114, score: 1 },
            ],
            choices: [
              {
                Assessment_Questionaire_RatingId: 84,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "< 10",
                Score: 1,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
              {
                Assessment_Questionaire_RatingId: 85,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "10-20",
                Score: 2,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
              {
                Assessment_Questionaire_RatingId: 86,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "20-50",
                Score: 3,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
              {
                Assessment_Questionaire_RatingId: 87,
                Assessment_Questionaire_Id: 45,
                Attribute_Response: "50 <",
                Score: 4,
                Status: 1,
                Status_Value: "changeIndustry",
                Updated_By: "Karthick A",
                Updated_On: "2024-06-29T05:23:24.287",
                IsActive: true,
                Approved_By: "Karthick A",
                Approved_On: "2024-06-28T12:30:27.573",
              },
            ],
          },
          {
            Assessment_Questionaire_Mapping_Id: 0,
            AQM_Category_Questions_Id: 101,
            Assessment_Questionaire_Id: 26,
            Consider_for_Scoring: 0,
            Question: "Do you have ISO Certification",
            Ques_Wtg: "0",
            Answer_Type: false,
            Updated_By: "Karthick A",
            Updated_On: "2024-06-28T13:13:21.970Z",
            IsActive: true,
            Approved_By: "Karthick A",
            Approved_On: "2024-06-28T13:13:21.970Z",
            Status: 0,
            actual_Score: 0,
            best_Score: 0,
            deviation: 0,
            Respondor: "SME",
            SME_Status: 1,
            SME_Remark: "",
            SO_Remark: "",
            Do_Status: "",
            Do_Remark: "",
            To_DO: false,
            To_PM: false,
          },
        ],
        onChange: true,
      },
    ],
  },
  Activity: "create",
  ModuleName: "AssessmentQuestionaire",
  UserId: 1,
};
