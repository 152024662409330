import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { textFieldWidth } from "../../../utils/constants";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import {
  addDepartment,
  getDepartmentList,
  getParentDepartmentList,
  updateDepartment,
  updateDepartmentAddandEdit,
} from "../../../store/slices/departmentData";
import ProgressBar from "../../../common/progressBar/progressBar";
import Toaster from "../../toaster/Toaster";
import {
  addSaveandDraft,
  getSaveandDrafList,
  updateSaveandDraft,
  updateSaveasDraftAddandEdit,
} from "../../../store/slices/saveasdraft";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { getLovList } from "../../../store/slices/lovMasterData";
import { DepartmentConfig, LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";

interface FormData {
  DepartmentId?: number;
  Type?: string;
  DepartmentName?: string;
  // SubDepartmentName?: string;
  Department_Code?: string;
  Department_Type?: string;
  Parent_Level?: string;
  Parent_Department_Id?: string;
  Level?: string;
  Parent?: string;
  IsActive?: any;
}
export interface departmentProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  // setIsEdit?:boolean;
  isEdited?: any;
  closePopUp?: () => void;
  onchangeLable?: any;
  isAdded?: any;
  masterId?: string;
  onClose?: any;
  isDraftData?: any;
  ModuleId?: number | undefined;
}
interface FormDataDraft {
  UserId?: number | undefined;
  ModuleName: string;
  ScreenName: string;
  DraftData: string;
  DraftStatus: boolean;
  CreatedBy: string | undefined; // Assuming user?.UserName can be undefined
  DraftId?: string | undefined; // Assuming user?.UserName can be undefined
}

export const AddDepartment: React.FC<departmentProps> = ({
  view,
  isEditData,
  edit,
  isEdited,
  isAdded,
  masterId,
  onClose,
  onchangeLable,
  isDraftData,
  ModuleId,
  closePopUp,
}) => {
  const dispatch = useAppDispatchThunk();
  const [ParentDepartmentData, setParentDepartmentData] = useState<any[]>([]);
  const [DepartmentTypelov, setDepartmentTypelov] = useState<any[]>([]);
  const [Levelslov, setLevelslov] = useState<any[]>([]);
  const [array, setArray] = useState<any[]>([]);
  const [ParentVisible, setParentVisible] = useState<boolean>(false);

  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  // const filteredLovTable = lovTable.filter((item: any) => item.Name === "Department Type");
  const filteredLovTableLevel = lovTable.filter((item: any) => item.Name === "Department Level");
  // const lastItem = filteredLovTableLevel.slice(-1)[0];

  const departmentLevelParentMap: { [key: string]: string } = {};
  useEffect(() => {
    if (filteredLovTableLevel.length > 0) {
      const newArray = [...filteredLovTableLevel.slice(0, filteredLovTableLevel.length - 1)]; // Remove the last item
      setArray(newArray);
    }
  }, []);
  // const lastItem = filteredLovTableLevel.pop();

  const { dataTable, isDepartmentAdded, isDepartmentUpdated, isLoading, parentTable } = useSelector(
    (state: RootState) => state.departmentData
  );

  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, isDraftDeleted } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  // console.log(isDraftData, "isDraftData");

  let formData = {
    DepartmentId: isEditData ? isEditData?.DepartmentId : 0,
    DepartmentName: isEditData ? isEditData?.DepartmentName : "",
    Department_Code: isEditData ? isEditData?.Department_Code : "",
    Department_Type: isEditData ? isEditData?.Department_Type : '',
    Parent_Department_Id: isEditData ? isEditData?.ParentDepartmentId : '',
    Parent_Level: isEditData ? isEditData?.Parent_Level : '',
    UpdatedBy: isEditData ? isEditData?.UpdatedBy : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };
  // console.log(isEditData, 'edit formdata')
  const {
    handleSubmit: handleVendorForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const isActiveDisabled = watch("IsActive");
  const DepartmentTypeWatchvalue = watch("Department_Type");
  const DepartmentLevelWatch = watch("Parent_Level");
  const DepartmentLevelAsString = `${DepartmentLevelWatch}`;

  const handleSubmitDraft = () => {
    let draft_value = getValues();
    let formData: FormDataDraft = {
      ModuleName: "Master",
      ScreenName: "Department",
      DraftData: JSON.stringify(draft_value),
      DraftStatus: true,
      CreatedBy: user?.UserName,
    };

    if (isDraftData && isDraftData.DraftId) {
      formData.DraftId = isDraftData.DraftId;
      dispatch(updateSaveandDraft(formData));
    } else {
      if (userLoginResponse) {
        formData.UserId = userLoginResponse.UserId;
        dispatch(addSaveandDraft(formData));
      }
    }
  };

  const handleSubmit = (data: any) => {
    console.log(data);
    dispatch(updateLoader(true));
    let formData = data;
    // setLoader(true);
    formData.MenuId = ModuleId;
    formData.Form_Id = 7;
    formData.ModuleName = ParentVisible ? "Sub Department Master" : "Department Master";
    formData.UpdatedBy = user?.UserName;
    formData.Department_Type = formData.Department_Type ? formData.Department_Type : "";
    formData.Department_Code = formData.Department_Code ? formData.Department_Code : "";
    formData.DepartmentName = formData.DepartmentName ? formData.DepartmentName : "";
    formData.Parent_Department_Id = formData.Parent_Department_Id ? formData.Parent_Department_Id : 0;
    formData.Parent_Level = formData.Parent_Level ? formData.Parent_Level : 0;
    formData.IsActive = formData.IsActive ? true : false;

    let finededDeptType = DepartmentTypelov.find(
      (item) => item.Options === DepartmentConfig?.Department && formData.Department_Type == item.LovId
    );

    let JsonDataForDisplay;

    if (finededDeptType) {
      JsonDataForDisplay = {
          "Department Type": DepartmentTypelov.find((item) => item.LovId == formData?.Department_Type)?.Options || '',
          "Department Name": formData.DepartmentName,
          "Department Code": formData.Department_Code,
          "Status": formData.IsActive ? "Active" : "Inactive",
          "Modified By": user?.UserName,
      };
  } else {
      JsonDataForDisplay = {
          "Department Type": DepartmentTypelov.find((item) => item.LovId == formData?.Department_Type)?.Options || '',
          "Department Name": formData.DepartmentName,
          "Department Code": formData.Department_Code,
          "Parent Level": Levelslov.find((item) => item.LovId == formData?.Parent_Level)?.Options || '',
          "Parent Department Name": ParentDepartmentData.find((item) => item.DepartmentId == formData?.Parent_Department_Id)?.DepartmentName || '',
          "Status": formData.IsActive ? "Active" : "Inactive",
          "Modified By": user?.UserName,
      };
  }

    if (edit) {
      formData.UserId = userLoginResponse?.UserId;
      formData.Activity = "Edit";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(updateDepartment(formData));
    } else {
      formData.Activity = "Create";

      if (finededDeptType) {
        let finededIndDeptLevel = Levelslov.find((item: any) => item.Options === DepartmentConfig?.Department_Level_1);
        formData.Parent_Level = finededIndDeptLevel ? finededIndDeptLevel.LovId : 0;
      }
      // JsonDataForDisplay.Parent_LevelValue = Levelslov.find((item) => item.LovId == formData?.Parent_Level)?.Options || '';
      formData.IsActive = true;
      formData.UserId = userLoginResponse?.UserId;
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addDepartment(formData));
    }
  };

  useEffect(() => {
    if (isDepartmentAdded || isDepartmentUpdated) {
      dispatch(
        getDepartmentList({
          FormID: 2,
        })
      );
      dispatch(updateDepartmentAddandEdit(false));
      reset(formData);
      if (onClose) {
        onClose();
        // setLoader(false);
      }
    }
  }, [isDepartmentAdded, isDepartmentUpdated]);

  useEffect(() => {
    console.log(parentTable, "dataTable");

    setParentDepartmentData(parentTable);
  }, [parentTable]);

  const findParentDepartmentLevel = (currentLevel: any) => {
    let level = currentLevel;
    while (level > 1) {
      level -= 1;
      const targetOption = `Level ${level}`;
      const findSelectDepartmentLevel = Levelslov.find((item: any) => item.Options === targetOption);
      if (findSelectDepartmentLevel) {
        return findSelectDepartmentLevel.LovId;
      }
    }
    return 0;
  };

  useEffect(() => {
    if (isEditData && isEditData.Parent_Level) {
      let findSelectDepartment = Levelslov.find((item: any) => item.LovId == isEditData.Parent_Level);

      if (findSelectDepartment) {
        const currentLevel = parseInt(findSelectDepartment.Options.split(" ")[1]);
        const parentDepartmentId = findParentDepartmentLevel(currentLevel);
        dispatch(getParentDepartmentList({ levelId: parentDepartmentId }));
      }
    }
  }, [isEditData, Levelslov]);

  let levelIdofDepartment = watch("Parent_Level");

  useEffect(() => {
    let findSelectDepartment = Levelslov.find((item: any) => item.LovId == levelIdofDepartment);

    if (findSelectDepartment) {
      setValue("Parent_Department_Id", '')
      const currentLevel = parseInt(findSelectDepartment.Options.split(" ")[1]);
      const parentDepartmentId = findParentDepartmentLevel(currentLevel);
      dispatch(getParentDepartmentList({ levelId: parentDepartmentId }));
    }
  }, [levelIdofDepartment]);

  // useEffect(() => {
  //   DepartmentLevelChange()
  // }, []);

  useEffect(() => {
    let finededIndType: any = DepartmentTypelov.find(
      (item) => item.Options === DepartmentConfig?.Department && DepartmentTypeWatchvalue == item.LovId
    );

    if (finededIndType) {
      if (onchangeLable && DepartmentTypeWatchvalue) {
        onchangeLable('Department')
      }
      // setValue("Parent_Industry_Id", "0");
      setParentVisible(false);
    } else {
      if (onchangeLable && DepartmentTypeWatchvalue) {
        onchangeLable('Sub Department')
      }
      setParentVisible(true);
    }
  }, [DepartmentTypeWatchvalue, isEditData, DepartmentTypelov]);

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    const Levelslov = lovTable.filter((item: any) => item.Name === LovConfig?.Department_Level) || [];

    setLevelslov(Levelslov);
    setDepartmentTypelov(lovTable.filter((item: any) => item.Name === LovConfig?.Department_Type) || []);
  }, [lovTable]);

  useEffect(() => {
    if (isDraftAdded || isDraftUpdated || isDraftDeleted) {
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(
        getSaveandDrafList({
          DraftId: 12,
        })
      );
      reset(formData);
      dispatch(updateLoader(false));
      if (onClose) {
        onClose();
        // setLoader(false);
      }
    }
  }, [isDraftAdded, isDraftUpdated, isDraftDeleted]);
  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {/* {isLoading || isLoadingDraft ? <ProgressBar showProgress={isLoading || isLoadingDraft} /> : null} */}

        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="Department_Type"
                control={control}
                defaultValue=""
                rules={{ required: "Type is required" }}
                render={({ field }) => (
                  <CustomSelect
                    name={"Type"}
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={DepartmentTypelov.sort((a, b) => a.Options.localeCompare(b.Options))}
                    disabled={view || edit}
                    required={true}
                    // addField={true}
                    label={"Type"}
                    value={field.value}
                    // onChange={field.onChange}
                    onChange={(e) => {
                      field.onChange(e);
                      clearErrors();
                    }}
                    error={Boolean(errorsloginform.Department_Type)}
                    helperText={errorsloginform.Department_Type && errorsloginform.Department_Type.message?.toString()}
                  />
                )}
              />
            </Grid>

            {!ParentVisible && watch('Department_Type') ? (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="DepartmentName"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Department name is required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                        minLength: (value) => (value ?? "").length >= 4 || "Minimum 4 characters required",
                        maxLength: (value) => (value ?? "").length <= 100 || "Maximum 100 characters required",
                        ValueLimitaion: (value) => /^(?:^[a-zA-Z0-9\s,. -]+)$/.test(value ?? "") || "Department Name cannot contain special characters except spaces and ,.-",
                        minAlphabets: (value) => (value?.match(/[a-zA-Z]/g) || []).length >= 2 || "Minimum 2 alphabets required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"DepartmentName"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Department Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.DepartmentName)}
                        helperText={
                          errorsloginform.DepartmentName && errorsloginform.DepartmentName.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="Department_Code"
                    control={control}
                    defaultValue={""}
                    rules={{
                      // required: "Department Code is Required",
                      // validate: {
                      //   noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                      //   minLength: (value) => (value ?? "").length >= 4 || "Minimum 4 characters required",
                      //   maxLength: (value) => (value ?? "").length < 100 || "Maximum 100 characters required",
                      //   ValueLimitaion: (value) => /^[a-zA-Z0-9]+$/.test(value ?? "") || "Department code cannot contain special characters and empty value",
                      // },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Department_Code"}
                        required={false}
                        disabled={view || !isActiveDisabled}
                        label={"Department Code"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Department_Code)}
                        helperText={
                          errorsloginform.Department_Code && errorsloginform.Department_Code.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            ) : null}

            {ParentVisible && watch('Department_Type') ? (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="DepartmentName"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Sub Department name is required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                        minLength: (value) => (value ?? "").length >= 4 || "Minimum 4 characters required",
                        maxLength: (value) => (value ?? "").length <= 100 || "Maximum 100 characters required",
                        ValueLimitaion: (value) => /^(?:^[a-zA-Z0-9\s,. -]+)$/.test(value ?? "") || "Sub Department Name cannot contain special characters and empty value",
                        minAlphabets: (value) => (value?.match(/[a-zA-Z]/g) || []).length >= 2 || "Minimum 2 alphabets required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"DepartmentName"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Sub Department Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.DepartmentName)}
                        helperText={
                          errorsloginform.DepartmentName && errorsloginform.DepartmentName.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="Department_Code"
                    control={control}
                    defaultValue={""}
                    rules={{
                      // required: "Department Code is Required",
                      // validate: {
                      //   noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                      //   minLength: (value) => (value ?? "").length >= 4 || "Minimum 4 characters required",
                      //   maxLength: (value) => (value ?? "").length <= 10 || "Maximum 10 characters required",
                      //   ValueLimitaion: (value) => /^[a-zA-Z0-9]+$/.test(value ?? "") || "Department code cannot contain special characters and empty value",
                      // },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Department_Code"}
                        required={false}
                        disabled={view || !isActiveDisabled}
                        label={"Sub Department Code"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Department_Code)}
                        helperText={
                          errorsloginform.Department_Code && errorsloginform.Department_Code.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="Parent_Level"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Level is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name="Parent_Level"
                        valueKey="LovId"
                        optionLabelKey="Options"
                        options={Levelslov.filter((item) => item.Options != "Level 1").sort((a, b) => a.Options.localeCompare(b.Options))}
                        disabled={view || !isActiveDisabled}
                        required={true}
                        // addField={true}
                        label="Level"
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Parent_Level)}
                        helperText={
                          errorsloginform.Parent_Level && errorsloginform.Parent_Level.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="Parent_Department_Id"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Parent is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"Parent_Department_Id"}
                        valueKey="DepartmentId"
                        optionLabelKey="DepartmentName"
                        options={ParentDepartmentData.filter((item) => item.IsActive !== "Inactive")}
                        label={"Parent"}
                        disabled={view || !isActiveDisabled}
                        // addField={true}
                        required={true}
                        // disabled
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Parent_Department_Id)}
                        helperText={
                          errorsloginform.Parent_Department_Id && errorsloginform.Parent_Department_Id.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            ) : null}
            {/* {edit || view ? (
              <Grid item xs={textFieldWidth.xs}>
                <Controller
                  name="IsActive"
                  control={control}
                  render={({ field }) => (
                    <CustomToogleSwitch
                      label={"User Status"}
                      dynamicLabel={field.value ? "ACTIVE" : "INACTIVE"}
                      disabled={view}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Grid>
            ) : null} */}
          </Grid>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "right",
              mt: 2,
              // position: "absolute",
              // right: 24,
              // bottom: 16,
            }}
          >
            {!view ? (
              !edit ? (
                <>
                  <CustomButton
                    type="reset"
                    variant="outlined"
                    name={"Cancel"}
                    onClick={() => {
                      onClose();
                    }}
                  />

                  {/* <CustomButton type="reset" name={"Save as Draft"} onClick={() => handleSubmitDraft()} /> */}

                  <CustomButton type="reset" disabled={!isDirty} name={"Submit"} onClick={handleVendorForm(handleSubmit)} />
                </>
              ) : (
                <>
                  <CustomButton
                    variant="outlined"
                    name={"Cancel"}
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <CustomButton variant="contained" disabled={!isDirty} name={"Update"} onClick={handleVendorForm(handleSubmit)} />
                </>
              )
            ) : (
              <CustomButton
                variant="outlined"
                name={"Cancel"}
                onClick={() => {
                  onClose();
                }}
              />
            )}

            {/* {view ? (
              <CustomButton
                type="reset"
                name={"Cancel"}
                onClick={() => {
                  closePopUp && closePopUp();
                }}
              />
            ) : edit ? (
              <React.Fragment>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    reset(formData);
                    closePopUp && closePopUp();
                  }}
                />
                <CustomButton type="reset" name={"Update"} onClick={handleVendorForm(handleSubmit)} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    reset(formData);
                    closePopUp && closePopUp();
                  }}
                />
                <CustomButton type="reset" name={"Submit"} onClick={handleVendorForm(handleSubmit)} />
              </React.Fragment>
            )} */}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

// export default AddDepartment;
