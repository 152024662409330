import { createSlice } from "@reduxjs/toolkit";

export type PluginRole = "so" | "do" | "sme" |"smereview";

export interface PluginUserRole {
  pluginRole: PluginRole;
}

export const initialState: PluginUserRole = {
  pluginRole: "sme",
};

export const pluginUserSlice = createSlice({
  name: "PluginUserRole",
  initialState,
  reducers: {
    setPluginUserRole: (state, action) => {
      state.pluginRole = action.payload;
    },
  },
});

export const { setPluginUserRole } = pluginUserSlice.actions;
export default pluginUserSlice.reducer;
