import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CustomNumberField from '../../../common/dynamicInputs/CustomNumberField';
import CustomButton from '../../../common/dynamicButton/CustomButton';
import { RootState, useAppDispatch } from '../../../store/store';
import { changethresholdforAssessment, isVendorAssessmentthreshholdEdit } from '../../../store/slices/vendorAssessment';
import { useSelector } from 'react-redux';
import { updateLoader } from '../../../store/slices/loader';



interface ThresholdChangeFormProps {
 
    ThresholdData: any;
    currentProcessData : any ;
    onclose: () => void;
  }
  
  export const ThresholdChangeForm: React.FC<ThresholdChangeFormProps> = ({currentProcessData , onclose , ThresholdData   }) => {
    
    const { isVendorAssessmentthreshhold , isVendorAssessmentthresholdLoading  } = useSelector((state: RootState) => state.vendorAssessmentData);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [changethresholdvalue, setchangethresholdvalue] = useState<any>(ThresholdData);
    const [changethresholderror, setchangethresholderror] = useState<string>("");
    const dispatch = useAppDispatch();
  
    useEffect(() => {
         if(isVendorAssessmentthreshhold){
          dispatch(isVendorAssessmentthreshholdEdit(false));
          onclose();
         }
    },[isVendorAssessmentthreshhold])
    useEffect(() => {
      dispatch(
        updateLoader(isVendorAssessmentthresholdLoading)
      );
    },[isVendorAssessmentthresholdLoading])
    const handleSubmitthreshold = () => {
        if(changethresholdvalue && changethresholdvalue > 0 && changethresholdvalue <= 1000){
            
              let final_data: any =   {
                Vendorid:  currentProcessData?.VendorId,
                Project_Detailid: currentProcessData?.ProjectId,
                "Threshold": changethresholdvalue ,
                "ModuleName": "",
                "Activity": ""
              }
            dispatch(changethresholdforAssessment(final_data))
        }else{
          setchangethresholderror('Threshold is requied and between 1 to 1000')
        }
  }

  const changethresholdvalues = (e:any) => {
           setIsChanged(true)
           setchangethresholdvalue(e)
        if(e && e > 0 && e <= 1000){
          setchangethresholderror('')
        }else{
          setchangethresholderror('Threshold is requied and between 1 to 1000')
        }
  }
    return (
        <>
            <Box>
                <Stack spacing={2}>
                     <Box>
                     <CustomNumberField
                                      name={"Threshold"}
                                      // required={true}
                                      disabled={false}
                                      label={"Threshold"}
                                      value={changethresholdvalue}
                                      onChange={(e) => { changethresholdvalues(e)  }}
                                      error={Boolean(
                                        changethresholderror
                                      )}
                                      helperText={
                                        changethresholderror.toString()
                                      }
                                    />
                      </Box>   
                      <Typography  sx={{ fontSize: "8px", color:  "red" ,  }}> Note : The aggregated deviation percentage for this assessment will be calculated based on the updated threshold.  </Typography>

                      <Box sx={{display :'flex' , justifyContent:'flex-end' , gap:2}}>
                      <CustomButton
                    type="submit"
                    variant="outlined"
                    disabled={false}
                    name={"close"}
                    onClick={() =>   onclose()}
                  />

                    <CustomButton
                      type="submit"
                      variant="contained"
                      disabled={!isChanged || !changethresholdvalue || ThresholdData == changethresholdvalue }
                      name={"Submit"}
                      onClick={() => handleSubmitthreshold()}
                    />
                      </Box>
                  
</Stack>

                </Box>    
        </>
    )
  }