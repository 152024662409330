import { Box, Stack, Typography, IconButton } from "@mui/material";
import React, { useMemo, useState } from "react";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableContainer,
  MRT_TableOptions,
  useMaterialReactTable,
  MaterialReactTable,
} from "material-react-table";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/HighlightOff";

interface SigningAuthorityProps {
  authority: string;
  tableData: any;
  view: boolean;
}

const SigningAuthority: React.FC<SigningAuthorityProps> = ({ authority, tableData, view }) => {
  const [data, setData] = useState<any[]>(tableData);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

  const handleDeleteClause = (row: MRT_Row<any>) => {
    setData(data.filter((_, index) => index !== row.index).map((e, i) => ({ ...e, order: i + 1 })));
  };

  const validateRequired = (value: string) => !!value.length;
  const validateEmail = (email: string) =>
    !!email.length &&
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  function validateUser(user: any) {
    return {
      name: !validateRequired(user.name) ? "Name is Required" : "",
      designation: !validateRequired(user.designation) ? "Desingation is Required" : "",
      email: !validateEmail(user.email) ? "Incorrect Email Format" : "",
    };
  }

  const handleCreateUser: MRT_TableOptions<any>["onCreatingRowSave"] = async ({ values, table }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    setData([...data, { ...values, order: data.length + 1 }]);
    table.setCreatingRow(null); //exit creating mode
  };

  const col = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "order",
        header: "S.NO.",
        size: 50,
        enableEditing: false,
      },
      {
        accessorKey: "name",
        header: "NAME",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: "designation",
        header: "DESIGNATION",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.designation,
          helperText: validationErrors?.designation,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              designation: undefined,
            }),
        },
      },
      {
        accessorKey: "email",
        header: "EMAIL ID",
        muiEditTextFieldProps: {
          type: "email",
          required: true,
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    // autoResetPageIndex: false,
    columns: col,
    data,
    createDisplayMode: "row",
    enableRowOrdering: !view,
    enableSorting: false,
    enableEditing: true,
    enableRowActions: !view,
    enableBottomToolbar: false,
    enableColumnActions: false,
    positionActionsColumn: "last",
    enablePagination: false,
    getRowId: (row) => row.order,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    icons: {
      DragHandleIcon: () => <DragIndicatorIcon fontSize="small" />,
      SaveIcon: () => <SaveIcon fontSize="small" color="primary" />,
      CancelIcon: () => <CancelIcon fontSize="small" color="primary" />,
    },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice((hoveredRow as MRT_Row<any>).index, 0, data.splice(draggingRow.index, 1)[0]);
          setData(data.map((e, i) => ({ ...e, order: i + 1 })));
        }
      },
    }),
    renderTopToolbar: ({ table }) => (
      <Box width="100%" textAlign="right">
        <CustomButton
          name="Add New"
          variant="outlined"
          onClick={() => table.setCreatingRow(true)}
          startIcon={<AddIcon sx={{ cursor: "pointer" }} />}
        />
      </Box>
    ),
    muiTableBodyCellProps: {
      sx: {
        borderBottomColor: "transparent",
      },
      align: "center",
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottomColor: "transparent",
      },
      align: "center",
    },
    muiTableContainerProps: {
      sx: {
        borderRadius: "5px",
        border: "none",
      },
    },
    localization: {
      move: "",
      rowNumber: "S.NO.",
      actions: "ACTION",
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => handleDeleteClause(row)} disabled={data.length === 1}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    muiTablePaperProps: ({ table }) => ({
      style: {
        border: "none",
        boxShadow: "none",
      },
    }),
  });

  const handleAddUser = () => {
    if (showAdd) {
      return;
    }
    setShowAdd(true);
  };

  return (
    <Box width="60%" mb={4} key={authority}>
      <Stack direction="row" justifyContent="space-between">
        <Typography align="left">
          Signing Authority - <b>{authority}</b>
        </Typography>
      </Stack>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default SigningAuthority;
