import { createSlice } from "@reduxjs/toolkit";

interface MenuState {
  isShown: boolean;
}

const initialState: MenuState = {
  isShown: true,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isShown = !state.isShown;
    },
    showMenu: (state) => {
      state.isShown = true;
    },
    hideMenu: (state) => {
      state.isShown = false;
    },
  },
});

export const { toggleMenu, showMenu, hideMenu } = menuSlice.actions;

export default menuSlice.reducer;
