import React, { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, Stack, IconButton } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { useSelector } from "react-redux";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import { LocationConfig, LovConfig, Register_Address_tosterMSG, CompanyMaster_Address_Type } from "../../../config/config";
import { getParentLocationList, updateLocationAddandEdit } from "../../../store/slices/locationMasterData";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddLocation } from "../../master/location/AddLocation";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import { updateToaster } from "../../../store/slices/loader";

interface AddressFormProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  onClose?: any;
  onSubmit: (data: any) => void;
  addressType: any[];
  addressTableValue: any[];
}

interface FormData {
  Address_Number: number;
  CompanyAddress_Id: number;
  CompanyId: number;
  Address_Type: string;
  Address: string;
  Pin: number;
  City: string;
  State: string;
  Country: string;
  Status: number;
  SameAsRegisterAddress: boolean;
}

export const AddressForm: React.FC<AddressFormProps> = ({ view, isEditData, edit, onClose, onSubmit, addressType, addressTableValue }) => {
  const dispatch = useAppDispatchThunk();
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { ParentLocationTable, islocationAdded } = useSelector((state: RootState) => state.locationMasterData);
  const [Citydisabled, setCitydisabled] = useState<boolean>(false);
  const [CityData, setCityData] = useState<any[]>([]);
  const [AddLocationForm, setAddLocationForm] = useState<boolean>(false);
  let AddressTypeLOV = lovTable.filter((item: any) => item.Name === "Address Type") || []

  const formData: FormData = {
    Address_Number: isEditData ? isEditData.Address_Number : 0,
    CompanyAddress_Id: isEditData ? isEditData.CompanyAddress_Id : 0,
    CompanyId: isEditData ? isEditData.CompanyId : 0,
    Address_Type: isEditData ? isEditData.Address_Type : "",
    Address: isEditData ? isEditData.Address : "",
    Pin: isEditData ? isEditData.Pin : null,
    City: isEditData ? isEditData.City : "",
    State: isEditData ? isEditData.State : "",
    Country: isEditData ? isEditData.Country : "",
    Status: isEditData ? isEditData.Status : "",
    SameAsRegisterAddress: isEditData ? isEditData.SameAsRegisterAddress : false,
  };

  const setTheCityNameBasedonPin = (pinValue: any) => {
    let pinWatchAsStringValue = `${pinValue}`;
    if (pinValue) {
      const filterdata = ParentLocationTable.filter((item: any) => {
        return pinWatchAsStringValue >= item.Start_Pin && pinWatchAsStringValue <= item.End_Pin;
      });
      if (filterdata.length >= 1) {
        let cityvalue = filterdata[0].Location_Name;
        let stateValue = filterdata[0].ParentLocationName;
        setValue("City", cityvalue);
        setValue("State", stateValue);
        setValue("Country", "India");
        setCitydisabled(true);
      } else {
        setValue("City", "");
        setValue("State", "");
        setValue("Country", "");
        setCitydisabled(false);
      }
    } else {
      setValue("City", "");
      setValue("State", "");
      setValue("Country", "");
      setCitydisabled(false);
    }
  };

  const fetchParentLocation = () => {
    let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type)
    if (dublocationType && dublocationType.length > 0) {
      let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_City)
      if (countryTypeId && countryTypeId.LovId) {
        dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
      }
    }
  }
  useEffect(() => {
    fetchParentLocation()
  }, [lovTable])

  useEffect(() => {
    if (islocationAdded) {
      dispatch(updateLocationAddandEdit(false));
      let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type)
      if (dublocationType && dublocationType.length > 0) {
        let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_City)
        if (countryTypeId && countryTypeId.LovId) {
          dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
        }
      }

    }
  }, [islocationAdded]);

  useEffect(() => {
    setCityData(ParentLocationTable);
    const value = getValues('Pin')
    if (value) setTheCityNameBasedonPin(value)
  }, [ParentLocationTable]);

  const {
    handleSubmit: handleAddressForm,
    reset,
    setValue,
    formState: { errors: error },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  console.log(getValues(),"getValuesgetValues")

  const AddressTypeWatch = watch("Address_Type");
  console.log(AddressTypeWatch,"AddressTypeWatch")

  let findRegiterAddress: any = addressType.find(
    (item) => item.Options === CompanyMaster_Address_Type.RegisteredAddress && AddressTypeWatch == item.LovId
  );
  console.log(findRegiterAddress,"findRegiterAddress")

  let findRegiterAddressID: any = AddressTypeLOV.find(
    (item:any) => item.Options === CompanyMaster_Address_Type.RegisteredAddress
  );  
console.log(addressTableValue,"addressTableValue")
  const handleSameAsRegister = () => {

    const FindRegisterAddress = addressTableValue.find((item) => {
      return item.Address_Type_Value === CompanyMaster_Address_Type.RegisteredAddress || item.Address_Type === findRegiterAddressID.LovId;
    });
    // console.log(addressTableValue, addressType, addressTableValue, findRegiterAddressID, FindRegisterAddress, "addressTableValue");
    const IfSameAsChecked = getValues("SameAsRegisterAddress");

    if (FindRegisterAddress !== undefined) {
      if (FindRegisterAddress && IfSameAsChecked === true) {
        setValue("Address", FindRegisterAddress?.Address);
        setValue("Pin", FindRegisterAddress?.Pin);
        setValue("City", FindRegisterAddress?.City);
        setValue("State", FindRegisterAddress?.State);
        setValue("Country", FindRegisterAddress?.Country);
      } else if (IfSameAsChecked === false) {
        setValue("Address", "");
        setValue("Pin", 0);
        setValue("City", "");
        setValue("State", "");
        setValue("Country", "");
      }
    } else {
      dispatch(updateToaster({ isToaster: true, toasterMsg: Register_Address_tosterMSG.message, isTosterFailed: true }));
      setValue("SameAsRegisterAddress", false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <CustomDialog
        show={AddLocationForm}
        onHide={() => {
          fetchParentLocation();
          setAddLocationForm(false)
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={"Add Location"}
        contentNode={
          // <></>
          <AddLocation
            view={false}
            closePopUp={() => {
              fetchParentLocation();
              setAddLocationForm(false);
            }}
          />
        }
      />
      <Stack spacing={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Controller
                name="Address_Type"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Address Type is required",
                }}
                render={({ field }) => (
                  <CustomSelect
                    name={"Address_Type"}
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={addressType}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                    required={true}
                    label={"Address Type"}
                    error={Boolean(error["Address_Type"])}
                    helperText={error["Address_Type"] && error["Address_Type"].message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                name="Address"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Address is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    // ValueLimitaion: (value) =>
                    //   /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) || "Cannot contain special characters",
                  },
                }}
                render={({ field }) => (
                  <CustomMultilineTextField
                    label={"Address Line"}
                    value={field.value}
                    rows={3}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Address)}
                    helperText={error.Address?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="Pin"
                control={control}
                rules={{
                  required: "Pincode is required",
                  maxLength: {
                    value: 6,
                    message: "Please enter a valid 6-digit pincode",
                  },
                  pattern: {
                    value: /^\d{6}$/,
                    message: "Please enter a valid 6-digit pincode",
                  },
                  validate: (value) => {
                    // const number = parseInt(value, 10);
                    // Check if the input is exactly 6 digits and falls within the specified range
                    if (isNaN(value) || value.toString().length !== 6 || value <= 0 || value > 999999) {
                      return "Please enter a valid 6-digit pincode";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <CustomNumberField
                    label={"Pincode"}
                    name={"Pincode"}
                    value={field.value}
                    required={true}
                    onChange={(value) => {
                      field.onChange(parseInt(value));
                      setTheCityNameBasedonPin(value);
                    }}
                    error={Boolean(error.Pin)}
                    helperText={error.Pin && error.Pin.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="City"
                control={control}
                defaultValue={""}
                rules={{
                  // required: "City is required",
                  // validate: {
                  //   noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                  //   maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                  //   ValueLimitaion: (value) =>
                  //     /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) || "Cannot contain special characters",
                  // },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"City"}
                    name={"City"}
                    disabled={true}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    inputProps={(!Citydisabled ) ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { setAddLocationForm(true) }}
                            edge="end"
                          >
                            +
                          </IconButton>
                        </InputAdornment>
                      ),
                    } : {}}
                    error={Boolean(error.City)}
                    helperText={error.City && error.City.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="State"
                control={control}
                defaultValue={""}
                rules={{
                  required: "State is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"State"}
                    name={"State"}
                    value={field.value}
                    disabled={true}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.State)}
                    helperText={error.State && error.State.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="Country"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Country is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    ValueLimitaion: (value) =>
                      /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) || "Cannot contain special characters",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Country"}
                    name={"Country"}
                    value={field.value}
                    required={true}
                    disabled={true}
                    onChange={field.onChange}
                    error={Boolean(error.Country)}
                    helperText={error.Country && error.Country.message?.toString()}
                  />
                )}
              />
            </Grid>
            {!findRegiterAddress && (
              <Grid item sm={12} md={6}>
                <Box sx={{ pt: 1 }}>
                  <Controller
                    name="SameAsRegisterAddress"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <CustomCheckBox
                        label="Same As Registered Address"
                        isStyle={true}

                        readOnly={view}
                        value={field.value}
                        onChange={(e) => { field.onChange(e); handleSameAsRegister(); }}
                      />
                    )}
                  />
                </Box>
              </Grid>
            )}

          </Grid>

          <Grid
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={(e, value) => {
                onClose();
              }}
            />
            <CustomButton variant="contained" name={"Save"} onClick={handleAddressForm(onSubmit)} size="small" />
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
