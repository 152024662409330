export const TEMPLATE_MANAGEMENT_URL = {
  CreateTemplate: `/ContractTemplate/createContractTemplate`,
  GetTemplates: `/ContractTemplate/list`,
  GetTemplateById: `/ContractTemplate/`,
  updateTemplate: `/ContractTemplate/updateContractTemplate`,
  getClauseByService: "/Clause/masterclause",
  getTemplateType: "/ContractTypes",
  activeInactiveTemplate: "/ContractTemplate/EnableOrDisableContractTemplate/",
  savePreviewTemplate: "/ContractTemplate/SavePreviewTemplate",
  deletePreviewTemplateData: "/ContractTemplate/DeletePreviewTemplate/",
};
