import { AxiosError, AxiosResponse } from "axios";
import { IRenewalResponse, RenewalResponse } from "../../models/renewal.type";
import { axiosInstance } from "./config/axiosConfig";
import { RENEWAL_URL } from "./endpoints/renewal";

const getRenewal = (): Promise<RenewalResponse> =>
  axiosInstance
    .get(RENEWAL_URL.getRenewal)
    .then((response: AxiosResponse<RenewalResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const GetServicesByProjectID = (requestBody: any): Promise<any> =>
  axiosInstance
    .get(RENEWAL_URL.GetServicesByProjectID, { params: requestBody })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const renewalService = {
  getRenewal,
  GetServicesByProjectID,
};
export default renewalService;
