import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FavouritesNavService from "../services/favouritesNavService";

export interface IWorkFlowHistoryData {
    FavouriteNavMenuData: any;
    isLoading: boolean;
    isFavAdded: boolean;
    ToasterMsg: any;
}

export const initialState: IWorkFlowHistoryData = {
    FavouriteNavMenuData: [],
    isLoading: false,
    isFavAdded: false,
    ToasterMsg: {Message: "", Status: ""},
};

export const addFavouriteNav = createAsyncThunk(
    "addFavouriteNav",
    async (requestParams: any, { rejectWithValue }) => {
      try {
        const response: any = await FavouritesNavService.addFavouriteNav(requestParams);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const getFavouriteNavMenu = createAsyncThunk(
    "getFavouriteNavMenu",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await FavouritesNavService.getFavouriteNav(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const FavouriteNavDataSlice = createSlice({
    name: "FavouriteNavData",
    initialState,
    reducers: {
        updateFavouriteNavAddandEdit: (state, action) => {
            
            state.ToasterMsg = {Message: "", Status: ""};
            state.isFavAdded = false;
        },
       
       
    },
    extraReducers: (builder) => {
        builder
        .addCase(addFavouriteNav.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(addFavouriteNav.fulfilled, (state, action: PayloadAction<any>) => {
            state.isFavAdded = true;
            state.isLoading = false;
            // state.apToasterMsg = action.payload.Message;
            state.ToasterMsg.Message = action.payload.Message;
            state.ToasterMsg.Status = action.payload.Status;
          })
          .addCase(addFavouriteNav.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })

          .addCase(getFavouriteNavMenu.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getFavouriteNavMenu.fulfilled, (state, action: PayloadAction<any>) => {
            console.log(action.payload, "action.payload");

                state.FavouriteNavMenuData = action.payload;
                state.isLoading = false;
          })
          .addCase(getFavouriteNavMenu.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })

    },
});

export const { updateFavouriteNavAddandEdit,} = FavouriteNavDataSlice.actions;
export default FavouriteNavDataSlice.reducer;
