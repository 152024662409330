import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
//import { useSelector } from 'react-redux';
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ProgressBar from "../../common/progressBar/progressBar";
import Paper from "@mui/material/Paper";
import { changePasswordData } from "../../models/changePassword.type";
import { useNavigate } from "react-router-dom";
import { textFieldWidth } from "../../utils/constants";
import { forgotChangePassword, updateForgotPassword } from "../../store/slices/chagepasswordslice";
import { useSelector } from "react-redux";

interface IForgotPasswordForm {
  EmailId: string;
}

export interface forgotProps {
  onClose: () => void;
}

const ForgotPassword: React.FC<forgotProps> = ({ onClose }) => {
  const dispatch = useAppDispatchThunk();
  const [loader, setLoader] = useState<boolean>(false);
  const { sentMailtoForgotPassword } = useSelector(
    (state: RootState) => state.changePassword
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();
  let formData: IForgotPasswordForm = {
    EmailId: "",
  };
  const {
    handleSubmit: handleChangePasswordForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<IForgotPasswordForm>({
    defaultValues: formData,
    mode: "onChange",
  });
  const handleSubmit = () => {
    dispatch(forgotChangePassword(getValues()));
    setLoader(true);
    setTimeout(() => {
      onClose();
      navigate("/login");
      //window.location.reload();
    }, 2500);
  };

  useEffect(() => {
    if (sentMailtoForgotPassword) {
      dispatch(updateForgotPassword(false));
      setShowSuccessMessage(true);
      setLoader(false);
      //onClose && onClose();
    }
  }, [sentMailtoForgotPassword]);

  return (
    <Box
      component="form"
      noValidate
      sx={{
        pt: 0,
        px: 0,
        pb: 0,
      }}
    >
      {loader ? <ProgressBar showProgress={loader} /> : null}
      <Grid container spacing={2}>
        <Grid
          item
          // xs={textFieldWidth.xs}
          // sm={textFieldWidth.sm}
          md={7}
        // lg={textFieldWidth.lg}
        // xl={textFieldWidth.xl}
        >
          <Typography variant="subtitle1" sx={{ textTransform: "capitalize", mb: 1 }}>
            Please enter your
            registered email address</Typography>
          <Controller
            name="EmailId"
            control={control}
            defaultValue={""}
            rules={{
              required: "Email is Required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <CustomTextField
                name={"EmailId"}
                required={true}
                label={"Email"}
                value={field.value}
                error={Boolean(errorsloginform.EmailId)}
                onChange={field.onChange}
                helperText={
                  errorsloginform.EmailId &&
                  errorsloginform.EmailId.message?.toString()
                }
              />
            )}
          />
        </Grid>
      </Grid>

      {showSuccessMessage ? <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          my: 2,
        }}
      >
        <Typography variant="subtitle1">
          Sent temporary password to email
        </Typography>
      </Box> : null}

      {!showSuccessMessage && <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "right",
          my: 2,
        }}
      >
        <CustomButton
          type="submit"
          variant="contained"
          //color="inherit"
          name={"Submit"}
          onClick={handleChangePasswordForm(handleSubmit)}
        />
      </Box>}
    </Box>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //  </Grid>
  );
};

export default ForgotPassword;
