import { Box, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useEffect, useRef, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SigningAuthority from "../NotifySigningAuthority/SigningAuthority";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomChip from "../../../common/dynamicChip/CustomChip";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import CustomDateTimePicker from "../../../common/dynamicInputs/CustomDateTimePicker";
import dayjs from "dayjs";
import CustomSvg from "../../../common/CustomSvg";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  costApprovalResetEmailState,
  costApprovalSendEmail,
} from "../../../store/contractManagement/slices/costApprovalSliceEmail";
import RichTextEditor, { EditorValue } from "react-rte";
import EmailForm from "../CostApproval/EmailForm/EmailForm";
import { getEmailTemplateList } from "../../../store/slices/EmailTemplateData";
import { getUsersList } from "../../../store/slices/userData";
import { getGetVCWorkflowProcessesData, updateCmWorkFlowInstance } from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { getVendorDetailsList } from "../../../store/slices/vendorDetails";
import { getCompanyList } from "../../../store/slices/companyMasterData";
import {
  boardApproval,
  updateSendVendorReviewDetailsAddandEdit,
} from "../../../store/contractManagement/slices/sendForVendorReview";

interface approvalForm {
  ID?: number;
  approvedBy: string;
  desgination: string;
  emailId: string;
  date: string;
}

export interface userFormData {
  approvalData: approvalForm[];
  ApprovalStatus: number | null;
  ApprovedDate: any;
  RejectedDate: any;
  AddComments: string;
  attachment: any;
}

export interface emailDataProps {
  email_template_id: any;
  to_email_id: any;
  notify_email_id: any;
  subject: string;
  email_content: EditorValue;
  email_content_string?: string;
  is_Msword: boolean;
  is_Pdf: boolean;
  emailFormat: string;
}
interface BoardApproval {
  isEditData?: any;
  onClose?: any;
}

const BoardApproval: React.FC<BoardApproval> = ({ isEditData, onClose }) => {
  const [contractData, setContractData] = useState<any>("");
  const [contractBasicData, setContractBasicData] = useState<any>("");
  const [currentservices, setcurrentservices] = useState<any>([]);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);
  const { vendorDetailsdata } = useSelector((state: RootState) => state.vendorDetails);
  const { workFLowInstanceData }: any = contractManagementReducer.contractRolesAndResponsibility;

  const [expandSummary, setExpandSummary] = useState<boolean>(false);
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const [ActiveServiceCatlogdatalocal, setActiveServiceCatlogdata] = useState<any[]>([]);
  const [sendMail, setSendEmail] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);
  const [isFileView, setIsFileView] = useState<boolean>(false);
  const [fileconvert, setFileConvert] = useState<string>("");
  const placeHolder = () => {};
  const dispatch = useAppDispatchThunk();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState<boolean>(false);
  const {
    isEmailSent,
    emailToasterMsg,
    contractType: contractTypeOptions,
  } = useSelector((state: any) => state.contractManagementReducer?.costApprovalEmail);  
  const { isboardApprovalUpdated, isboardApprovalLoading,ToasterMsg }: any = contractManagementReducer.sendForVendorReview;
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  let formData: userFormData = {
    approvalData: [],
    ApprovalStatus: null,
    ApprovedDate: "",
    RejectedDate: "",
    AddComments: "",
    attachment: null,
  };

  let EmailFormData: emailDataProps = {
    email_template_id: null,
    email_content: RichTextEditor.createEmptyValue(),
    is_Msword: false,
    is_Pdf: false,
    emailFormat: "",
    notify_email_id: null,
    subject: "",
    to_email_id: null,
  };

  const boardApprovalDetails = useForm<userFormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  const {
    handleSubmit: handleBoardApprovalSubmit,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
    register,
  } = boardApprovalDetails;

  const emailDetailsFormMethods = useForm<emailDataProps>({ defaultValues: EmailFormData, mode: "onChange" });
  const {
    handleSubmit: emailDetailFormMethodsHandleSubmit,
    formState: { errors: errorsEmailDetailFormMethodsform, isDirty: emailDetailsFormMethodsIsDirty },
    getValues: emailDetailsFormMethodsGetValues,
    setValue: emailDetailsFormMethodsSetValue,
    reset: emailDetailsReset,
  } = emailDetailsFormMethods;
  const [approvalData, setApprovalData] = useState<approvalForm[]>([]);
  const [emailtosend, setemailtosend] = useState<any>("");

  const { append } = useFieldArray({ name: "approvalData", control });

  const [ApprovalStatusOptions, setApprovalStatusOptions] = useState([
    { LovId: 1, Options: "Approve" },
    { LovId: 2, Options: "Reject" },
  ]);

  const theme = useTheme();

  useEffect(() => {
    if (ActiveServiceCatlogdata.length > 0) {
      dispatch(updateLoader(false));
    }
  }, [ActiveServiceCatlogdata]);

  const addNew = () => {
    const newItem = {
      ID: Math.floor(Math.random() * 100000000000),
      approvedBy: "",
      desgination: "",
      emailId: "",
      date: "",
    };

    let apporvalData = getValues("approvalData");
    // setApprovalData((d:any) => ({
    //   ...d,
    //   approvalData: [...d.approvalData, newItem],
    // }));
    // apporvalData = [...apporvalData,newItem]
    // setValue("approvalData",apporvalData);
    append(newItem);
  };

  useEffect(() => {
    if (isEditData && isEditData !== null) {
      let request: any = null;
      if (isEditData?.ProjectId) {
        request = {
          projectId: isEditData?.ProjectId,
          workflowInstanceId: isEditData?.Workflow_InstanceId,
        };
      } else {
        request = {
          projectId: isEditData?.project_Id,
          workflowInstanceId: isEditData?.workflowInstance?.id,
        };
      }
      dispatch(getGetVCWorkflowProcessesData(request));
    }
    // let req: any = { projectId: "723", workflowInstanceId: 688 };
    // dispatch(getGetVCWorkflowProcessesData(req));
  }, []);

  useEffect(() => {
    if (workFLowInstanceData) {
      setcurrentservices(workFLowInstanceData?.serviceMappings || []);
      setContractData(workFLowInstanceData);
    }
  }, [workFLowInstanceData]);

  useEffect(() => {
    if (workFLowInstanceData) {
      const serviceCatalogIds = workFLowInstanceData?.serviceMappings.map(
        (mapping: any) => mapping.services.serviceCatalog_Id
      );

      // Filter actualservice based on serviceCatalog_Id
      const filteredServices = ActiveServiceCatlogdata.filter((service) =>
        serviceCatalogIds.includes(service.ServiceCatalogId)
      );
      setcurrentservices(filteredServices || []);
      console.log(ActiveServiceCatlogdata, "ActiveServiceCatlogdata");
    }
  }, [workFLowInstanceData, ActiveServiceCatlogdata]);

  useEffect(() => {
    if (workFLowInstanceData) {
      let obj = {
        // "Company Name":
        //   CompanyTable.find((item: any) => workFLowInstanceData?.company_Id == item?.Company_Id)?.Legal_Name || "",
        // "Vendor Name":
        //   vendorDetailsdata.find((item: any) => workFLowInstanceData?.vendor_Id == item?.Vendor_Id)?.Legal_Name || "",
        "Company Name": workFLowInstanceData?.company_Name,
        "Vendor Name": workFLowInstanceData?.vendor_Name,
        "Contract Number": workFLowInstanceData?.contract_Ref_Number,
      };
      setContractBasicData(obj);
      console.log(workFLowInstanceData, "workFLowInstanceData", vendorDetailsdata, CompanyTable);
    }
  }, [workFLowInstanceData, CompanyTable, vendorDetailsdata]);

  const handleSubmit = (data: any) => {
    console.log(data, "formdata 1");

    const contractBoardApprovalDetails =
      data?.approvalData?.map((item: any) => ({
        approvar_Name: item.approvedBy,
        designation: item.desgination,
        email_Sent_Date: item.date,
        facilitator_Email_Id: item.emailId,
      })) || [];

    let formdata = {
      contract_WF_Approval_Id: 0,
      contract_Number: contractData?.contract_Ref_Number,
      workflow_Approval_Type: "string",
      approval_Status: JSON.stringify(data.ApprovalStatus),
      approval_Date: data.ApprovedDate,
      commets: data.AddComments,
      contractBoardApprovalDetails: contractBoardApprovalDetails,
    };
    dispatch(boardApproval(formdata));
  };

  const handleDelete = (approvalItem: approvalForm) => {
    let values: approvalForm[] = getValues("approvalData");
    values = values.filter((item: approvalForm) => item.ID !== approvalItem.ID);
    setValue("approvalData", [...values]);
    setApprovalData([...values]);
  };

  useEffect(() => {
    dispatch(getEmailTemplateList());
    dispatch(getServiceCatalogList());
    dispatch(getUsersList({ FormID: 2 }));
    dispatch(
      getCompanyList({
        FormID: 2,
      })
    );
    dispatch(getVendorDetailsList());
  }, []);

  const watchApprovalStatus = watch("ApprovalStatus");
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setFileConvert(base64String);
          setSelectedFiles(file);
          setValue("attachment", file);
        };
        reader.readAsDataURL(file);
      } else {
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: "Please select a PDF or Word document.",
            isTosterFailed: true,
          })
        );
      }
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = (fileToDelete: File) => {
    setSelectedFiles(null);
  };

  const handleEmailFormSubmit = async (data: emailDataProps) => {
    let emailscommaseperated: any = data?.to_email_id.split(",").map((item: any) => item.trim());
    data.email_content_string = data.email_content.toString("html");

    const updatedContent = {
      email_Template_Id: data.email_template_id,
      to_Email_Id: "",
      to_Email_Ids: [],
      notify_Email_Id: data.notify_email_id,
      subject: data.subject,
      email_Content_String: data.email_content_string,
      is_MsWord: data.is_Msword,
      is_Pdf: data.is_Pdf,
      email_Format: data.is_Msword === true ? "Word" : data.is_Pdf === true ? "PDF" : "",
      from_Email_Id: 0,
      to_Emails: emailscommaseperated,
    };

    console.log(updatedContent, "updatedContent", data);
    dispatch(updateLoader(false));
    dispatch(costApprovalSendEmail(updatedContent));
    emailDetailsReset();
    setIsEmailModelOpen(false);
    setSendEmail(true);
    console.log(data);
  };

  useEffect(() => {
    if (isEmailSent) {
      dispatch(costApprovalResetEmailState());
      dispatch(updateLoader(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: emailToasterMsg?.message,
          isTosterFailed: emailToasterMsg?.status === false || emailToasterMsg?.status !== "Success" ? true : false,
        })
      );
      emailDetailsReset();
      setIsEmailModelOpen(false);
    } else {
      dispatch(updateLoader(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: emailToasterMsg?.message,
          isTosterFailed: emailToasterMsg?.status === false || emailToasterMsg?.status !== "Success" ? true : false,
        })
      );
    }
  }, [isEmailSent]);

  const updateWorkFLowInstance = async () => {
    if (isboardApprovalUpdated) {
      let request = {
        workflowInstanceId: isEditData?.Workflow_InstanceId,
        processInstanceId: isEditData?.Id,
        conditionValue: contractData?.vendor_Contract_Id,
        projectId: contractData.ProjectId,
        updatedBy: userLoginResponse ? userLoginResponse.UserName : "",
      };
      let data = await dispatch(updateCmWorkFlowInstance(request)).unwrap();
      if (data) {
        dispatch(updateSendVendorReviewDetailsAddandEdit(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: ToasterMsg.message,
            isTosterFailed: ToasterMsg.status !== "Success" ? true : false,
          })
        );
        dispatch(updateLoader(false));
        onClose();
      }
    }
  };

  useEffect(() => {
    if (isboardApprovalUpdated && ToasterMsg) {
      updateWorkFLowInstance();
    }
  }, [isboardApprovalUpdated, ToasterMsg]);

  useEffect(() => {
    dispatch(updateLoader(isboardApprovalLoading));
  }, [isboardApprovalLoading]);

  const boardApprovalFieldFields = Object.keys(formData);
  const sendEmailTriggerModal = async () => {
    const isValid = await boardApprovalDetails.trigger();
    const clauseInfoFieldsErrors = boardApprovalFieldFields.filter(
      (field: any) => boardApprovalDetails.getFieldState(field).invalid
    );
    if (clauseInfoFieldsErrors.length === 0) {
      let EmailIds: any = getValues("approvalData");
      const commaSeparatedEmails: any = EmailIds.map((item: any) => item.emailId).join(", ");

      setemailtosend(commaSeparatedEmails);
      setIsEmailModelOpen(true);
    }
  };

  return (
    <Box sx={{ width: "100%", px: 2 }}>
      <Box pt={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ChevronLeftIcon />
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
            Board Approval
          </Typography>
        </Stack>
        <Divider sx={{ my: 4 }} variant="fullWidth" />
      </Box>
      <Box>
        {contractBasicData && (
          <Stack spacing={8} direction="row">
            {Object.entries(contractBasicData).map(([label, value]: any) => (
              <Box key={label}>
                <Typography textAlign="left" fontWeight="bold">
                  {label} -{" "}
                </Typography>
                <Typography textAlign="left">{value}</Typography>
              </Box>
            ))}
          </Stack>
        )}
        <Divider sx={{ my: 4 }} variant="fullWidth" />
        <Box sx={{ display: "flex", gap: 1, flexDirection: "column", mb: 0.5 }}>
          <Typography variant="h6" color={"text.primary"} sx={{ textAlign: "left" }} fontWeight={500}>
            Selected Services
          </Typography>
          {currentservices.length > 0 ? (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: "left",
                  display: "flex",
                  paddingTop: "18px",
                  columnGap: "10px",
                  flexWrap: "wrap",
                  rowGap: "10px",
                }}
              >
                {currentservices.length > 0 &&
                  currentservices.map((filteredServiceItem: any) => {
                    return (
                      <CustomChip
                        label={`${filteredServiceItem?.ServiceName} - ${filteredServiceItem?.Summary || ""}`}
                        variant="outlined"
                        color="primary"
                      />
                    );
                  })}
              </Grid>
            </Grid>
          ) : null}
          <Box sx={{ display: "flex", gap: 1, flexDirection: "column", mb: 0.5 }}>
            <Stack direction="row" justifyContent="end" mt={4}>
              <CustomButton variant="outlined" name={"+Add New"} onClick={addNew} />
            </Stack>
          </Box>
          {getValues("approvalData") && getValues("approvalData").length > 0 ? (
            <Grid container spacing={2}>
              {getValues("approvalData").map((approvalItem: approvalForm, i: number) => {
                return (
                  <React.Fragment>
                    <Grid
                      key={`${approvalItem.emailId + "" + i}`}
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={3}
                      lg={3}
                    >
                      <Controller
                        //   {...register(`approvalData.${i}.approvedBy`, {
                        //     required: "This is a required Field",
                        //   })}
                        name={`approvalData.${i}.approvedBy`}
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Approved By is a required Field",
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            name={`approvalData.${i}.approvedBy`}
                            required={true}
                            disabled={false}
                            label={"ApprovedBy"}
                            value={field.value}
                            onChange={(value) => {
                              if (/^[a-zA-Z\s]*$/.test(value)) {
                                field.onChange(value);
                              }
                            }}
                            error={Boolean(
                              errorsloginform?.approvalData &&
                                errorsloginform?.approvalData[i] &&
                                errorsloginform?.approvalData[i]?.approvedBy
                            )}
                            helperText={
                              errorsloginform?.approvalData &&
                              errorsloginform?.approvalData[i] &&
                              errorsloginform?.approvalData[i]?.approvedBy &&
                              errorsloginform?.approvalData[i]?.approvedBy?.message?.toString()
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3}>
                      <Controller
                        //   {...register(`approvalData.${i}.desgination`, {
                        //     required: "This is a required Field",
                        //   })}
                        name={`approvalData.${i}.desgination`}
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Designation is a required Field",
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            name={`approvalData.${i}.desgination`}
                            required={true}
                            disabled={false}
                            label={"Designation"}
                            value={field.value}
                            onChange={(value) => {
                              if (/^[a-zA-Z\s]*$/.test(value)) {
                                field.onChange(value);
                              }
                            }}
                            error={Boolean(
                              errorsloginform?.approvalData &&
                                errorsloginform?.approvalData[i] &&
                                errorsloginform?.approvalData[i]?.desgination
                            )}
                            helperText={
                              errorsloginform?.approvalData &&
                              errorsloginform?.approvalData[i] &&
                              errorsloginform?.approvalData[i]?.desgination &&
                              errorsloginform?.approvalData[i]?.desgination?.message?.toString()
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={textFieldWidth.xs} sm={textFieldWidth.sm} md={3} lg={3}>
                      <Controller
                        //   {...register(`approvalData.${i}.emailId`, {
                        //     required: "This is a required Field",
                        //   })}

                        name={`approvalData.${i}.emailId`}
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Facilitator’s Email ID is Required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          },
                        }}
                        render={({ field }) => (
                          <CustomTextField
                            name={`approvalData.${i}.emailId`}
                            required={true}
                            disabled={false}
                            label={"Facilitator’s Email ID"}
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                            error={Boolean(
                              errorsloginform?.approvalData &&
                                errorsloginform?.approvalData[i] &&
                                errorsloginform?.approvalData[i]?.emailId
                            )}
                            helperText={
                              errorsloginform?.approvalData &&
                              errorsloginform?.approvalData[i] &&
                              errorsloginform?.approvalData[i]?.emailId &&
                              errorsloginform?.approvalData[i]?.emailId?.message?.toString()
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={textFieldWidth.xs}
                      sm={textFieldWidth.sm}
                      md={3}
                      lg={3}
                      sx={{
                        display: "flex",
                        columnGap: "10px",
                        alignItems: "center",
                        maxHeight: Boolean(
                          errorsloginform?.approvalData &&
                            errorsloginform?.approvalData[i] &&
                            errorsloginform?.approvalData[i]?.date
                        )
                          ? "74px"
                          : "54px",
                      }}
                    >
                      <Controller
                        name={`approvalData.${i}.date`}
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Date is a required Field",
                        }}
                        render={({ field }) => (
                          <CustomDatePicker
                            minDate={dayjs()}
                            maxDate={dayjs().add(100, "year").toDate()}
                            label={"Date"}
                            value={field.value ? dayjs(field.value) : null}
                            readOnly={false}
                            onChange={field.onChange}
                            error={Boolean(
                              errorsloginform?.approvalData &&
                                errorsloginform?.approvalData[i] &&
                                errorsloginform?.approvalData[i]?.date
                            )}
                            helperText={
                              errorsloginform?.approvalData &&
                              errorsloginform?.approvalData[i] &&
                              errorsloginform?.approvalData[i]?.date &&
                              errorsloginform?.approvalData[i]?.date?.message?.toString()
                            }
                            format="DD-MMM-YYYY"
                          />
                        )}
                      />
                      {i !== 0 ? (
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <CustomSvg
                            color={`${theme.palette.text.secondary}`}
                            size={"20px"}
                            name={"delete"}
                            onClick={() => {
                              handleDelete(approvalItem);
                            }}
                          />
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", gap: 1, visibility: "hidden" }}>
                          <CustomSvg
                            color={`${theme.palette.text.secondary}`}
                            size={"20px"}
                            name={"delete"}
                            onClick={() => {
                              handleDelete(approvalItem);
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          ) : null}
          <Box sx={{ display: "flex", gap: 1 }}>
            <Stack direction="row" justifyContent="end" mt={4}>
              <CustomButton
                variant="contained"
                name={"Send Email"}
                onClick={() => {
                  // setIsEmailModelOpen(true)
                  sendEmailTriggerModal();
                  //setSendEmail(true);
                }}
              />
            </Stack>
          </Box>
          {sendMail ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                <Controller
                  name="ApprovalStatus"
                  control={control}
                  defaultValue={"" as any}
                  rules={{
                    required: "Approval Status is Required",
                  }}
                  render={({ field }) => (
                    <CustomAutoComplete
                      optionValueKey={"LovId"}
                      optionLabelKey="Options"
                      options={ApprovalStatusOptions}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      required={true}
                      label={"Approval Status"}
                      error={Boolean(errorsloginform.ApprovalStatus)}
                      helperText={errorsloginform.ApprovalStatus && errorsloginform.ApprovalStatus.message?.toString()}
                    />
                  )}
                />
              </Grid>
              {watchApprovalStatus == 1 && (
                <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                  <Controller
                    name="ApprovedDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Approved Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Approved Date"}
                        required={true}
                        value={field.value ? field.value : null}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        error={Boolean(errorsloginform.ApprovedDate)}
                        helperText={errorsloginform.ApprovedDate && errorsloginform.ApprovedDate.message?.toString()}
                      />
                    )}
                  />
                </Grid>
              )}
              {watchApprovalStatus == 2 && (
                <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                  <Controller
                    name="RejectedDate"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Rejected Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Rejected Date"}
                        value={field.value ? field.value : null}
                        required={true}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        error={Boolean(errorsloginform.RejectedDate)}
                        helperText={errorsloginform.RejectedDate && errorsloginform.RejectedDate.message?.toString()}
                      />
                    )}
                  />
                </Grid>
              )}
              {watchApprovalStatus == 1 || watchApprovalStatus == 1 ? (
                !selectedFiles ? (
                  <Grid item xs={12} sm={12} md={5} lg={1} xl={2}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ mt: 0.3 }}>
                        <CustomButton
                          variant="outlined"
                          color="secondary"
                          name={"Attach File"}
                          onClick={handleButtonClick}
                          startIcon={<CustomSvg size={"15px"} name={"paperclip"} />}
                        />
                        <input
                          ref={fileInputRef}
                          accept=".pdf,.doc,.docx"
                          style={{ display: "none" }}
                          id="file-input"
                          type="file"
                          onChange={handleFileChange}
                          multiple={false}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ display: "flex", alignItems: "flex-start", mt: 0.3 }}>
                      {selectedFiles && (
                        <CustomChip
                          key={selectedFiles.name}
                          label={selectedFiles.name}
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsFileView(true)}
                          onDelete={() => handleDeleteFile(selectedFiles)}
                        />
                      )}
                    </Box>
                  </Grid>
                )
              ) : null}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Controller
                  name="AddComments"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <CustomMultilineTextField
                      label={"AddComments"}
                      value={field.value}
                      rows={5}
                      required={true}
                      readOnly={false}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.AddComments)}
                      helperText={errorsloginform.AddComments && errorsloginform.AddComments.message?.toString()}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Box>
      <CustomDialog
        show={isEmailModelOpen}
        onHide={() => {
          emailDetailsReset();
          setIsEmailModelOpen(false);
        }}
        maxWidth={"md"}
        header={"Send Email for Cost Approval"}
        contentNode={
          <>
            {" "}
            <FormProvider {...emailDetailsFormMethods}>
              <EmailForm
                // onSubmit={emailDetailsFormMethods.handleSubmit((data) => {
                //   console.log(data);
                //   setIsEmailModelOpen(false);
                // })}
                templateName="Board Approval"
                to_emails={emailtosend}
                onSubmit={(data) => {
                  handleEmailFormSubmit(data);
                }}
                onClose={() => {
                  emailDetailsReset();
                  setIsEmailModelOpen(false);
                }}
              />
            </FormProvider>
          </>
        }
      />
      <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
        <CustomButton variant="outlined" name={"Cancel"} onClick={() => {}} />
        <CustomButton
          variant="contained"
          name={"Submit"}
          endIcon={<ChevronRightIcon />}
          onClick={handleBoardApprovalSubmit(handleSubmit)}
        />
      </Stack>
    </Box>
  );
};

export default BoardApproval;
