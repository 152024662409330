import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CompanyMasterService from "../services/companyMasterService";
import { ICompany, IVHCompanyColumn } from "../../models/company.type";
import { chkDate } from "../../utils/constants";

export interface ICompanyData {
  CompanyTable: ICompany[];
  isLoading: boolean;
  isCompanyAdded: boolean;
  isCompanyUpdated: boolean;
  isDuplicate: boolean;
  versionHistory: { CompanyRecords: ICompany[]; CompanyVHeader: IVHCompanyColumn[] };
  isCompanyHistory: boolean;
  isCompanyDeleted: boolean;
  ToasterMsg: { Message: string; Status: string };
  getImageUrl: any;
}

export const initialState: ICompanyData = {
  CompanyTable: [],
  isLoading: false,
  isCompanyAdded: false,
  isCompanyUpdated: false,
  isDuplicate: false,
  versionHistory: { CompanyRecords: [], CompanyVHeader: [] },
  isCompanyHistory: false,
  isCompanyDeleted: false,
  ToasterMsg: { Message: "", Status: "" },
  getImageUrl: "",
};

export const getCompanyList = createAsyncThunk(
  "getCompanyList",
  async (requestParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await CompanyMasterService.getCompanyList();
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCompany = createAsyncThunk("addCompany", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await CompanyMasterService.addCompany(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCompany = createAsyncThunk("updateCompany", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await CompanyMasterService.updateCompany(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteCompany = createAsyncThunk("deleteCompany", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await CompanyMasterService.deleteCompany(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getImageData = createAsyncThunk("getImage", async (requestParams: any, { rejectWithValue }) => {
  try {
    const url = CompanyMasterService.getImage(requestParams.fileName);
    return url;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getCompanyHistory = createAsyncThunk(
  "getCompanyHistory",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requestParams };
      const response: any = await CompanyMasterService.getVersionHistoryCompany(reqbody);
      const { CompanyMasters, HeaderJson } = response.data;
      return { CompanyHistories: CompanyMasters, HeaderJson: HeaderJson.ColumnHeaders };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const CompanyDataSlice = createSlice({
  name: "CompanyMaster",
  initialState,
  reducers: {
    updateCompanyAddandEdit: (state, action) => {
      state.isCompanyAdded = false;
      state.isCompanyUpdated = false;
      state.isCompanyDeleted = false;
      state.isDuplicate = false;
      state.ToasterMsg.Message = action.payload.Message;
      state.ToasterMsg.Status = action.payload.Status;
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.CompanyTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.CompanyTable];
        copyVendorData[indexVendor] = action.payload;
        state.CompanyTable = copyVendorData;
      }
    },
    updateVersionHistory: (state, action) => {
      state.versionHistory.CompanyRecords = [];
      state.versionHistory.CompanyVHeader = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyList.fulfilled, (state, action: PayloadAction<any>) => {
        state.CompanyTable = action.payload.map((company: any) => {
          company.IsActive = company.IsActive === true ? "Active" : "Inactive";
          return company;
        });
        state.isLoading = false;
      })
      .addCase(getCompanyList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCompany.fulfilled, (state, action: PayloadAction<any>) => {
        // console.log(action.payload , 'action.payload1');
        //     let item = {...action.payload};
        //     let copyallUsers= [...state.dataTable];
        //     copyallUsers.push(item);
        //     state.dataTable = copyallUsers;
        state.isCompanyAdded = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(addCompany.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCompanyUpdated = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(updateCompany.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCompany.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCompanyDeleted = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
        state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(deleteCompany.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getCompanyHistory.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getCompanyHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.CompanyVHeader = action.payload.HeaderJson;
        state.versionHistory.CompanyRecords = action.payload.CompanyHistories.map((company: any) => {
          company.IsActive = company.IsActive === true ? "Active" : "Inactive";
          company.Approved_On = chkDate(company.Approved_On);
          return company;
        });
        state.isLoading = false;
      })
      .addCase(getImageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getImageData.fulfilled, (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.getImageUrl = action.payload;
      })
      .addCase(getImageData.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});

export const { updateCompanyAddandEdit, updateUser, updateVersionHistory } = CompanyDataSlice.actions;
export default CompanyDataSlice.reducer;
