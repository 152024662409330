import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomAccordion from "../../../dynamicAccordion/CustomAccordion";
import CustomButton from "../../../dynamicButton/CustomButton";
import CustomCheckBox from "../../../dynamicInputs/CustomCheckBox";

interface ISMESignoff {
  formData: any;
}

const SMESignoff: React.FC<ISMESignoff> = ({ formData }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [toBeReviewedItems, setToBeReviewedItems] = useState<any[]>([]);
  const [acceptedItems, setAcceptedItems] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (Array.isArray(formData)) {
      setToBeReviewedItems(formData);
    } else {
      console.warn("formData is not an array:", formData);
      setToBeReviewedItems([]);
    }
  }, [formData]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAcceptItem = (item: any) => {
    setToBeReviewedItems((prev) => prev.filter((i) => i.id !== item.id));
    setAcceptedItems((prev) => [...prev, { ...item, isSelected: true }]);
  };

  const handleAcceptAll = () => {
    const accepted = toBeReviewedItems.map((item) => ({ ...item, isSelected: true }));
    setAcceptedItems((prev) => [...prev, ...accepted]);
    setToBeReviewedItems([]);
  };

  const handleToggleClause = (item: any, index: number, type: "toBeReviewed" | "accepted") => {
    if (type === "toBeReviewed") {
      // Move item from "To Be Reviewed" to "Accepted"
      setToBeReviewedItems((prev) => prev.filter((_, i) => i !== index));
      setAcceptedItems((prev) => [...prev, { ...item, isSelected: true }]);
    } else {
      // Move item from "Accepted" back to "To Be Reviewed"
      setAcceptedItems((prev) => prev.filter((_, i) => i !== index));
      setToBeReviewedItems((prev) => [...prev, { ...item, isSelected: false }]);
    }
  };

  const handleNext = (activeTab: number) => {
    setActiveTab(activeTab);
  };

  const handleSubmit = () => {
    console.log("Accepted Items Submitted:", acceptedItems);
  };

  return (
    <Box sx={{ maxHeight: "calc(100vh - 250px)", px: 2, py: 2 }}>
      {activeTab === 0 && (
        <Box>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                borderBottom: "1px solid #ddd",
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                SME Sign Off
              </Typography>
            </Box>
            <Stack direction="column" spacing={2} sx={{ maxHeight: "calc(100vh - 220px)", overflow: "auto", pr: 1 }}>
              {toBeReviewedItems.length > 0 ? (
                toBeReviewedItems.map((item: any, index: number) => (
                  <Box
                    key={item.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "5px",
                      border: "1px solid #ddd",
                      p: 1,
                    }}
                  >
                    <Box sx={{ textAlign: "left" }}>
                      <Typography variant="body2" fontWeight="bold">
                        {item.header}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Finance
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    p: 1,
                  }}
                >
                  <Typography variant="body2" fontWeight="bold">
                    No Records Found
                  </Typography>
                </Box>
              )}
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                borderTop: "1px solid #ddd",
                pt: 1,
              }}
            >
              <CustomButton
                name="Sign Off"
                variant="contained"
                onClick={() => {
                  handleNext(1);
                }}
              />
            </Box>
          </Stack>
        </Box>
      )}

      {activeTab === 1 && (
        <Box>
          <Stack direction="column" spacing={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                borderBottom: "1px solid #ddd",
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Vendor Portal
              </Typography>
            </Box>
            <Stack
              direction="column"
              spacing={2}
              sx={{ maxHeight: "calc(100vh - 220px)", overflow: "auto", pr: 1, mb: "16px !important" }}
            >
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1">Company Name</Typography>
                <Typography variant="body1" fontWeight={600}>
                  CLife Pvt Ltd
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1">Contract Name</Typography>
                <Typography variant="body1" fontWeight={600}>
                  Lorem ipsum dolor
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1">Contract Summary</Typography>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis nobis harum deserunt obcaecati
                  possimus animi in, modi ipsam quas itaque nemo ipsa eaque autem consectetur porro fugiat facere
                  temporibus similique? Libero eligendi magnam cum architecto eius, illo nobis, voluptatem quas sunt
                  eveniet non voluptate repellat mollitia! Ipsa molestias iste ipsam facere dicta, numquam voluptatem
                  tempora nihil maiores earum unde aliquid? Recusandae sunt, exercitationem maxime consequatur libero
                  perferendis officiis sed facilis eum repudiandae doloribus, et voluptatum obcaecati cumque deleniti
                  praesentium corrupti reiciendis id inventore? Non harum rem dolore voluptatem ipsa inventore?
                  Voluptatibus hic ducimus fuga, aperiam enim placeat officiis corporis nulla vero fugiat aliquam unde
                  facere accusantium perspiciatis! Architecto assumenda reiciendis, alias, cum dolores nobis et facere
                  tenetur blanditiis possimus illum!
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              borderTop: "1px solid #ddd",
              pt: 1,
            }}
          >
            <CustomButton name="Submit" variant="contained" onClick={handleSubmit} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SMESignoff;
