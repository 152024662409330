import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomAccordion from "../../../dynamicAccordion/CustomAccordion";
import CustomButton from "../../../dynamicButton/CustomButton";
import CustomCheckBox from "../../../dynamicInputs/CustomCheckBox";
import { PLUGIN_STEPPER } from "../../../../utils/constants";

interface ISMEReview {
  formData: any;
  handleSelectChange:any;
}

const SMEReview: React.FC<ISMEReview> = ({ formData,handleSelectChange }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [toBeReviewedItems, setToBeReviewedItems] = useState<any[]>([]);
  const [acceptedItems, setAcceptedItems] = useState<any[]>([]);

  useEffect(() => {
    if (Array.isArray(formData)) {
      setToBeReviewedItems(formData);
    } else {
      console.warn("formData is not an array:", formData);
      setToBeReviewedItems([]);
    }
  }, [formData]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAcceptItem = (item: any) => {
    setToBeReviewedItems((prev) => prev.filter((i) => i.id !== item.id));
    setAcceptedItems((prev) => [...prev, { ...item, isSelected: true }]);
  };

  const handleAcceptAll = () => {
    const accepted = toBeReviewedItems.map((item) => ({ ...item, isSelected: true }));
    setAcceptedItems((prev) => [...prev, ...accepted]);
    setToBeReviewedItems([]);
  };

  const handleToggleClause = (item: any, index: number, type: "toBeReviewed" | "accepted") => {
    if (type === "toBeReviewed") {
      // Move item from "To Be Reviewed" to "Accepted"
      setToBeReviewedItems((prev) => prev.filter((_, i) => i !== index));
      setAcceptedItems((prev) => [...prev, { ...item, isSelected: true }]);
    } else {
      // Move item from "Accepted" back to "To Be Reviewed"
      setAcceptedItems((prev) => prev.filter((_, i) => i !== index));
      setToBeReviewedItems((prev) => [...prev, { ...item, isSelected: false }]);
    }
  };

  const handleSubmit = () => {
    console.log("Accepted Items Submitted:", acceptedItems);
    handleSelectChange(PLUGIN_STEPPER.SO);
  };

  return (
    <Box sx={{ maxHeight: "calc(100vh - 250px)", px: 2, py: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
          borderBottom: "1px solid #ddd",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          SME Review
        </Typography>
      </Box>
      <Stack direction="column" spacing={2} sx={{ maxHeight: "calc(100vh - 165px)", overflow: "auto", pr: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <CustomAccordion
            expanded={expanded === "toBeReviewed"}
            handleChange={handleChange("toBeReviewed")}
            title={"To Be Reviewed"}
            details={
              <>
                <Box sx={{ width: "calc(100vw - 80px)" }}>
                  <Stack spacing={2}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                      {toBeReviewedItems.length > 0 && (
                        <Typography onClick={handleAcceptAll} sx={{ textDecoration: "underline", cursor: "pointer" }}>
                          Accept All
                        </Typography>
                      )}
                    </Box>
                    {toBeReviewedItems.length > 0 ? (
                      toBeReviewedItems.map((item: any, index: number) => (
                        <Box
                          key={item.id}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: "5px",
                            border: "1px solid #ddd",
                            p: 1,
                          }}
                        >
                          <Box>
                            <Typography variant="body2" fontWeight="bold">
                              {item.header}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              Finance
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Typography>Reviewed</Typography>
                            <CustomCheckBox
                              isStyle={true}
                              alignItems="center"
                              label=""
                              onChange={() => handleToggleClause(item, index, "toBeReviewed")}
                              value={item.isSelected || false}
                            />
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                          border: "1px solid #ddd",
                          p: 1,
                        }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          No Records Found
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </>
            }
          />
        </Box>
        <CustomAccordion
          expanded={expanded === "accepted"}
          handleChange={handleChange("accepted")}
          title={"Accepted"}
          details={
            <>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2}>
                  {acceptedItems.length > 0 ? (
                    acceptedItems.map((item: any, index: number) => (
                      <Box
                        key={item.id}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: "5px",
                          border: "1px solid #ddd",
                          p: 1,
                        }}
                      >
                        <Box>
                          <Typography variant="body2" fontWeight="bold">
                            {item.header}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            Finance
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Typography>Reviewed</Typography>
                          <CustomCheckBox
                            isStyle={true}
                            alignItems="center"
                            label=""
                            onChange={() => handleToggleClause(item, index, "accepted")}
                            value={item.isSelected || false}
                          />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        border: "1px solid #ddd",
                        p: 1,
                      }}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        No Records Found
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Box>
            </>
          }
        />
      </Stack>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", borderTop: "1px solid #ddd", pt: 1 }}
      >
        <CustomButton name="Submit" variant="contained" onClick={handleSubmit} />
      </Box>
    </Box>
  );
};

export default SMEReview;
