import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import fileHandlerService from "../../store/services/fileHandlerService";
import * as mime from "react-native-mime-types";
import CustomSvg from "../CustomSvg";
import { useTheme } from "@mui/material/styles";
import CustomDialog from "../dynamicDialog/CustomDialog";
import FilteViewer from "./FilteViewer";
interface IFilePreviewerProps {
  URL?: any;
  file?: any;
  view?: boolean;
  onRemovefile?: () => void;
}

const FilePreviewer: React.FC<IFilePreviewerProps> = ({ URL, file , view, onRemovefile }) => {
  const theme = useTheme();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [isfilePreview, setIsfilePreview] = useState<boolean>(false);
  const [isfilePreviewurl, setIsfilePreviewurl] = useState<any>("");
  const [previewImage, setImage] = useState<any>("");


const file_nameFetch = () => {
       if(URL){
        const fileNames = URL?.split("/").pop();

        const cleanFileName = fileNames?.split("_").slice(1).join("_");
        const decodedFileName = decodeURIComponent(cleanFileName);
        return decodedFileName
       }

       if(file){
             const fileNAme = file[0]?.name
             return fileNAme
       }
    return ""

}

  const onButtonClick = async (bloburl: any) => {
    if (bloburl) {
      const fileName = decodeURIComponent(bloburl.substring(bloburl.indexOf("_") + 1));
      const response: any = await fileHandlerService.DownLoadAFile({ fileUrl: bloburl });
      const { data } = response;
      const mimeType = mime.lookup(fileName.substring(fileName.lastIndexOf(".") + 1));
      setFileName(fileName as string);
      setIsfilePreviewurl(`data:${mimeType};base64,${data}`);
      setIsfilePreview(true);
    }
  };
  const fetchData = async () => {
    const { data } = await fileHandlerService.DownLoadAFile({ fileUrl: URL });
    if (data) {
      const blob = "data:image/jpeg;base64," + data;
      setImage(blob);
    }
  };

  useEffect(() => {
    if (URL) {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
        
        const extension = URL?.split('.').pop().toLowerCase();
        if (imageExtensions.includes(extension)) {
          fetchData();
        }
      }
  }, [URL]);

  const removeFile = () => {
    if (onRemovefile) {
      onRemovefile();
    }
  };
  return (
    <>
      <Box
        sx={{ width: "100%", marginY: "10px", padding: "5px 10px", borderRadius: "6px", border: "1px dashed #6A6A6A" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box>{file_nameFetch()}</Box>
          <Box>
           {URL ? <Tooltip title="View">
              <IconButton
                onClick={() => {
                  onButtonClick(URL);
                  // setIsfilePreviewurl(q_item?.Attachment);
                  // setIsfilePreview(true);
                }}
                disabled={!URL}
              >
                <CustomSvg color={`${theme.palette.info.main}`} size={"20px"} name={"view"} />{" "}
              </IconButton>
            </Tooltip> : null }
            {!view && (
              <Tooltip title="Delete">
                <IconButton disabled={view} onClick={() => removeFile()}>
                  <CustomSvg color={`${theme.palette.text.secondary}`} size={"20px"} name={"delete"} />{" "}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <>
          {previewImage && (
            <Box sx={{ width: "100%", maxHeight: "150px", display: "flex", justifyContent: "center" }}>
              <img
                src={previewImage}
                style={{ width: "250px", height: "auto", objectFit: "contain" }}
                alt={"CompanyLogo"}
              />
            </Box>
          )}
        </>
      </Box>

      <CustomDialog
        show={isfilePreview}
        onHide={() => {
          setIsfilePreview(false);
        }}
        key={"File_preview_from_file_Previewer"}
        maxWidth={"lg"}
        header={"File Preview"}
        contentNode={
          <>
            <FilteViewer URL={isfilePreviewurl} fileName={fileName} />
          </>
        }
      />
    </>
  );
};
export default FilePreviewer;
