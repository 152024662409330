export const ACTIVITY = {
    CREATE: 'Create',
    VIEW: 'View',
    EDIT: 'Edit',
    EXPORT: 'Export',
    DELETE: 'Delete',
    LOGIN: 'Login',
    LOGOUT: 'Logout'
};

export const ACTIVITY_OPTIONS: any[] = [
  { id: ACTIVITY.CREATE, lable: ACTIVITY.CREATE },
  { id: ACTIVITY.VIEW, lable: ACTIVITY.VIEW },
  { id: ACTIVITY.EDIT, lable: ACTIVITY.EDIT },
  { id: ACTIVITY.EXPORT, lable: ACTIVITY.EXPORT },
  { id: ACTIVITY.DELETE, lable: ACTIVITY.DELETE },
  { id: ACTIVITY.LOGIN, lable: ACTIVITY.LOGIN },
  { id: ACTIVITY.LOGOUT, lable: ACTIVITY.LOGOUT },
];

export const PARAMETER_NAME_FIELDS = {
  MIN_LENGTH: "Min_Length", 
  MAX_LENGTH: "Max_Length", 
  MIN_LOWERCASE: "Min_Lowercase",
  MIN_UPPERCASE: "Min_Uppercase",
  MIN_NUMBERS: "Min_Numbers",
  MIN_SPECIAL_CHARACTERS: "Min_SpecialChars",
  SPECIAL_CHARACTERS: "Special Characters",
  PASSWORD_HISTORY: "Password History",
  PASSWORD_EXPIRY: "Password Expiry",
  ACCOUNT_LOCK: "Account Lock",
  ACCOUNT_TYPE: "Authentication Type",
};
