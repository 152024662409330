import { AxiosError, AxiosResponse } from "axios";
import { PROFILE_PICTURE_URL } from "./endpoints/profilePicture";
import { axiosInstance } from "./config/axiosConfig";

const addProfilePicture = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PROFILE_PICTURE_URL.UploadUserProfile, requestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const updateProfilePicture = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PROFILE_PICTURE_URL.UploadUserProfile, requestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteProfilePicture = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PROFILE_PICTURE_URL.DeleteUserProfile, requestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const roleService = {
  addProfilePicture,
  updateProfilePicture,
  deleteProfilePicture,
};

export default roleService;
