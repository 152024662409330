import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import costApprovalMetricService from "../services/costApprovalMetricService";
import {
    ICostApprovalMetricTypeResponse,
    ICostApprovalMetricType,
  IVHColumn,
  IVHColumnRes,
} from "../../../models/contractManagementModels/costApprovalMetricModel";
import { ACTIVE, chkDate, INACTIVE } from "../../../utils/constants";

export interface ICostApprovalMetricData {
    CostApprovalMetricData: any;
  isCostApprovalMetricDataLoading: boolean;
  isCostApprovalMetricDataAdded: boolean;
  CostApprovalMetricToasterStatus: boolean | null;
  isCostApprovalMetricDataUpdated: boolean;
  isCostApprovalMetricDataDeleted: boolean;
  ToasterMsg: { Message: string; Status: string };
  //versionHistory: {  CostApprovalMetricRecords: IVHColumnRes[];  CostApprovalMetricVHeader: IVHColumn[] };
  clauseData: any; 
}

export const initialState: ICostApprovalMetricData = {
    CostApprovalMetricData: [],
    isCostApprovalMetricDataLoading: false,
    isCostApprovalMetricDataAdded: false,
    CostApprovalMetricToasterStatus: false,
    isCostApprovalMetricDataUpdated: false,
    isCostApprovalMetricDataDeleted: false,
  ToasterMsg: { Message: "", Status: "" },
  //versionHistory: { ClauseMasterRecords: [], ClauseMasterVHeader: [] },
  clauseData: null,
};

export const getallCostApprovalMetricList = createAsyncThunk(
  "getallCostApprovalMetricList", async (reqBody: any, { rejectWithValue }) => {
  try {
    const response: any = await costApprovalMetricService.getallCostApprovalMetricList(reqBody);
    return response.costApprovalMetricsList.map((i: any) => ({ ...i, status: i?.isActive ? "Active" : "Inactive" })) ;
    
  } catch (error:any) {
    return rejectWithValue(error?.response?.data?.errors[0] || "Failed to Get Cost Approval Metrics");
  }
});

export const createCostApprovalMetric = createAsyncThunk(
  "createCostApprovalMetric",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      let response: any = await costApprovalMetricService.createCostApprovalMetric(reqBody);
     // response=response.
     
      return response;
    } catch (error : any) {
      return rejectWithValue(error?.response?.data?.errors[0] );
    }
  }
);

export const updateCostApprovalMetric = createAsyncThunk(
  "updateCostApprovalMetric",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response: any = await costApprovalMetricService.updateCostApprovalMetric(reqBody);
      return response;
    } catch (error:any) {
      return rejectWithValue(error?.response?.data?.errors[0] );    }
  }
);

export const activeInactiveCostApprovalMetric = createAsyncThunk(
  "activeInactiveCostApprovalMetric",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response: any = await costApprovalMetricService.activeInactiveCostApprovalMetric(reqBody);
      return response;
    } catch (error: any) {
      console.error("Error in activeInactiveClauseMaster:", error);
      return rejectWithValue(error?.response?.data?.errors[0] );
    }
  }
);

// export const getVersionHistory = createAsyncThunk(
//   "getVersionHistory",
//   async (requestParams: any, { rejectWithValue }) => {
//     try {
//       const response: any = await clauseMasterService.getVersionHistory(requestParams);
//       const { Records, HeaderJson } = response.data;
//       return { History: Records, HeaderJson: HeaderJson.ColumnHeaders };
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const CostApprovalMetricSlice = createSlice({
  name: "CostApprovalMetricDetails",
  initialState,
  reducers: {
    updateCostApprovalMetricAddandEdit: (state, action: PayloadAction<any>) => {
      state.isCostApprovalMetricDataAdded = false;
      state.isCostApprovalMetricDataUpdated = false;
      state.isCostApprovalMetricDataDeleted = false;
      state.ToasterMsg.Message = action?.payload?.Message  || "";;
      state.ToasterMsg.Status = action?.payload?.Status || "";;
    },
    clearData: (state) => {
      state.clauseData = null;
    },
    updateVersionHistory: (state, action) => {
      console.log(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getallCostApprovalMetricList.pending, (state) => {
        state.isCostApprovalMetricDataLoading = true;
      })
      .addCase(getallCostApprovalMetricList.fulfilled, (state, action: PayloadAction<ICostApprovalMetricTypeResponse[]>) => {
        // state.clauseMasterDetailsData = action?.payload;
        state.CostApprovalMetricData = action?.payload?.map((data: any) => {
          data.isActive = data.isActive === true ? ACTIVE : INACTIVE;
          return data;
        });
        state.isCostApprovalMetricDataLoading = false;
      })
      .addCase(getallCostApprovalMetricList.rejected, (state, action: PayloadAction<any>) => {
        state.isCostApprovalMetricDataLoading = false;
        state.ToasterMsg.Message = action?.payload;
        state.ToasterMsg.Status = "false";
      })
      .addCase(createCostApprovalMetric.pending, (state) => {
        state.isCostApprovalMetricDataLoading = true;
      })
      .addCase(createCostApprovalMetric.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCostApprovalMetricDataLoading = false;
        state.isCostApprovalMetricDataAdded = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message ;
        state.ToasterMsg.Status = action?.payload?.toaster?.status ;
        state.clauseData = action?.payload;
      })
      .addCase(createCostApprovalMetric.rejected, (state , action: PayloadAction<any>) => {
        state.isCostApprovalMetricDataLoading = false;
        state.isCostApprovalMetricDataAdded=true
        state.ToasterMsg.Message = action?.payload;
        state.ToasterMsg.Status = "false";
      })
      .addCase(updateCostApprovalMetric.pending, (state) => {
        state.isCostApprovalMetricDataLoading = true;
      })
      .addCase(updateCostApprovalMetric.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCostApprovalMetricDataLoading = false;
        state.isCostApprovalMetricDataUpdated = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message ;
        state.ToasterMsg.Status = action?.payload?.toaster?.status ;
      })
      .addCase(updateCostApprovalMetric.rejected, (state,action: PayloadAction<any>) => {
        state.isCostApprovalMetricDataLoading = false;
        state.isCostApprovalMetricDataUpdated = true;
        state.ToasterMsg.Message = action?.payload;
        state.ToasterMsg.Status = "false";
      })
      .addCase(activeInactiveCostApprovalMetric.pending, (state) => {
        state.isCostApprovalMetricDataLoading = true;
      })
      .addCase(activeInactiveCostApprovalMetric.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCostApprovalMetricDataLoading = false;
        state.isCostApprovalMetricDataDeleted = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message ;
        state.ToasterMsg.Status = action?.payload?.toaster?.status ;
      })
      .addCase(activeInactiveCostApprovalMetric.rejected, (state , action: PayloadAction<any>) => {
        state.isCostApprovalMetricDataLoading = false;
        state.isCostApprovalMetricDataDeleted = true;
        state.ToasterMsg.Message = action?.payload;
        state.ToasterMsg.Status = "false";
      })
    //   .addCase(getVersionHistory.pending, (state) => {
    //     state.isCostApprovalMetricDataLoading = true;
    //   })
    //   .addCase(getVersionHistory.fulfilled, (state, action: PayloadAction<any>) => {
    //     state.versionHistory.ClauseMasterVHeader = action?.payload?.HeaderJson;
    //     state.versionHistory.ClauseMasterRecords = action?.payload?.History.map(
    //       ({ IsActive, Approved_On, ...rest }: any) => ({
    //         ...rest,
    //         IsActive: IsActive ? "Active" : "Inactive",
    //         Approved_On: chkDate(Approved_On),
    //       })
    //     );
    //     state.isclauseMasterDetailsLoading = false;
    //   })
    //   .addCase(getVersionHistory.rejected, (state) => {
    //     state.isclauseMasterDetailsLoading = false;
    //   });
  },
});

export const { updateCostApprovalMetricAddandEdit, updateVersionHistory, clearData } = CostApprovalMetricSlice.actions;
export default CostApprovalMetricSlice.reducer;
