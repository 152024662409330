import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import {  LovConfig, MasterBulkUpload } from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
import { getCompanyList } from "../../../../store/slices/companyMasterData";
export const BranchImportdata: any = [
  {
    Field: "branch_code",
    FieldType: "string",
    DisplayName: "Branch Code",
    config: {
      required: true,
    },
  },
  {
    Field: "branch_name",
    FieldType: "string",
    DisplayName: "Branch Name",
    config: {
      required: true,
    },
  },
  {
    Field: "branch_type",
    FieldType: "string",
    DisplayName: "Branch Type",
    config: {
      required: true,
    },
  },
  {
    Field: "company",
    FieldType: "string",
    DisplayName: "Company",
    config: {
      required: true,
    },
  },
  {
    Field: "branch_city",
    FieldType: "string",
    DisplayName: "Branch City",
    config: {
      required: true,
    },
  },
  {
    Field: "branch_zone",
    FieldType: "string",
    DisplayName: "Branch Zone",
    config: {
      required: true,
    },
  },
  {
    Field: "branch_state",
    FieldType: "string",
    DisplayName: "Branch State",
    config: {
      required: true,
    },
  },
  {
    Field: "branch_region",
    FieldType: "string",
    DisplayName: "Branch Region",
    config: {
      required: true,
    },
  },
];

const BranchMasterUploadSheet: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);
  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [branch_typeList , setBranch_type] = useState<any>([])
  const [company_List , setCompany_List] = useState<any[]>([])

  useEffect(() => {
    dispatch(
        getCompanyList({
          FormID: 2,
        })
      );
  },[])
  useEffect(() => {
    let dubbrachType: any[] = lovTable.filter((item: any) => item.Name === LovConfig.Branch_Type);
    setBranch_type(dubbrachType)
  }, [lovTable]);

  useEffect(() => {
    setCompany_List(CompanyTable)
  },[CompanyTable])

  const { setselectedValidation, uploaddata } = context || {};

  //validation the depatment Fields
  const department_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
      let ValidatedData: any[] = [];
      const encounteredBranchCodes = new Set<any>();
      const encounteredBranchNames = new Set<any>();
      uploaddata.forEach((item: any) => {
        let error: string = "";
        let object: any = {
            ...item,
            branch_code: "",
            branch_name: "",
            branch_type: "",
            branch_type_Id: "",
            company: "",
            company_Id: "",
            branch_city: "",
            branch_zone: "",
            branch_state: "",
            branch_region: "",
        };
        let findValidtype = branch_typeList.find(
            (typeitem : any) => typeitem.Options == item["Branch Type"]
          );
       
        BranchImportdata.map((header_item: any) => {
    
          if (header_item.Field === "branch_code") {
            const branchCode = item[header_item.DisplayName];
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
          
              if (encounteredBranchCodes.has(branchCode)) {
                error += `Duplicate Branch Code: ${branchCode}, `;
              } else {
                encounteredBranchCodes.add(branchCode); // Track new branch code
                object[header_item.Field] = branchCode;
              }
            } else {
              error += " Branch Code is Required,";
            }
          }

          if (header_item.Field === "branch_name") {
           
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
             
              const branchName = item[header_item.DisplayName];
              object[header_item.Field] = branchName;
              if (encounteredBranchNames.has(branchName)) {
                error += `Duplicate Branch Name: ${branchName}, `;
              } else {
                encounteredBranchNames.add(branchName); // Track new branch code
                object[header_item.Field] = branchName;
              }
            } else {
              error += " Branch Name is Required,";
            }
          }
          if (header_item.Field === "branch_city") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Branch City is Required,";
            }
          }
          if (header_item.Field === "branch_zone") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Branch Zone is Required,";
            }
          }
          if (header_item.Field === "branch_state") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Branch State is Required,";
            }
          }
          if (header_item.Field === "branch_region") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Branch Region is Required,";
            }
          }

           //validation the branch Type Fields
           if (header_item.Field === "branch_type") {
            if (findValidtype?.LovId) {
              object[header_item.Field] = item[header_item.DisplayName];
              object.branch_type_Id = findValidtype?.LovId;
            } else {
              if (item[header_item.DisplayName]) {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Branch Type,";
              } else {
                error += " Branch Type is Required,";
              }
            }
          }
            // Comapny multi select validation

            if (header_item.Field === "company") {
                if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                  let enteredCompany = item[header_item.DisplayName];
                  object[header_item.Field] = enteredCompany;
                  let assessmentArray = enteredCompany.split(",");
                     let invalidValue : any = "" 
                  let matchingIds = assessmentArray.map((assessValue: any) => {
                    let trimmedValue = assessValue?.trim();
                    let matchedItem = company_List.find((asseitem: any) => asseitem.Legal_Name?.trim() === trimmedValue);
                    if(!matchedItem){
                        invalidValue = invalidValue + " "  + trimmedValue
                    }
                    return matchedItem ? matchedItem.Company_Id : null;
                  });
                  matchingIds = matchingIds.filter((id: any) => id !== null);
    
                  if (matchingIds && matchingIds?.length > 0 && !invalidValue) {
             
                    let companyFinal : any[] = matchingIds?.map((comVal : any) => {
                        let findObj = company_List.find((compitem: any) => compitem.Company_Id == comVal)
                            return {
                                Company_Id : findObj?.Company_Id ,
                                Legal_Name : findObj?.Legal_Name
                            }
                    })
                    object.company_Id = companyFinal
                  } else {
                    error += `${invalidValue} - Invalid Company,`;
                  }
                } else {
                  error += " Company is Required,";
                }
              }
          

          object.error = error;
        });
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      department_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const NoteSheet = workbook.addWorksheet("Note");
      let sheet_Column: any = BranchImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);

      let CompList = company_List?.map((item : any) => item.Legal_Name);
      let branchypeList = branch_typeList?.map((item : any) => item.Options);
      const firstFiveItems = CompList?.slice(0, 5).join(",");
      NoteSheet.getCell(
        `A${1}`
      ).value = `Note : Company is Multi Select data, set Comma sepertated Value only Given below, Example : ${firstFiveItems}`;
    
      CompList.forEach((item : any, index: number) => {
        NoteSheet.getCell(`A${index + 3}`).value = item;
      });
   
      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        worksheet.getCell(`A${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: true,
          formulae: [55],
        };
        
    
          worksheet.getCell(`C${row}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${branchypeList.join(",")}"`],
            
            errorTitle: "Invalid Company for",
            error: "Please select an Company from the list",
          };
      }

      

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "Branch_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <CustomButton disabled={company_List?.length == 0} type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default BranchMasterUploadSheet;
