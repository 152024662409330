import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { VendorConsent_URL } from "./endpoints/contentMaster";
import { IAddVendorConsentResponse, IVHColumnRes, IVendorConsentResponse } from "../../models/vendorConsentMaster.type";


const getContentForm = (requestBody: any): Promise<IVHColumnRes> =>

  axiosInstance
    .get(VendorConsent_URL.GetVendorConsentForm, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getcontentList = (reqparams: any): Promise<IVendorConsentResponse> =>
  axiosInstance
    .get(VendorConsent_URL.GetAllVendorConsent)
    .then((response: AxiosResponse<IVendorConsentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateContentMaster = (requestBody: any): Promise<IAddVendorConsentResponse> =>
  axiosInstance
    .post(VendorConsent_URL.UpdateVendorConsent, requestBody)
    .then((response: AxiosResponse<IAddVendorConsentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addcontentMaster = (requestBody: any): Promise<IAddVendorConsentResponse> =>
  axiosInstance
    .post(VendorConsent_URL.AddVendorConsent, requestBody)
    .then((response: AxiosResponse<IAddVendorConsentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteContentMaster = (requestBody: any): Promise<IAddVendorConsentResponse> =>
  axiosInstance
    .post(VendorConsent_URL.DeleteVendorConsent, requestBody)
    .then((response: AxiosResponse<IAddVendorConsentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistorycontent = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(VendorConsent_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });



const ContentMasterService = {
  getcontentList,
  updateContentMaster,
  addcontentMaster,
  deleteContentMaster,
  getVersionHistorycontent,
  getContentForm
};

export default ContentMasterService;
