import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contractListService from "../services/contractListService";
import { IContractApiDetails, IContractDetails } from "../../../models/contractManagementModels/contractListModels";

export interface IContractDetailsData {
 
  isContractvendormailUpdatLoading: boolean;
  isContractvendormailUpdated: boolean;
  isboardApprovalLoading: boolean;
  isboardApprovalUpdated: boolean;  
  ToasterMsg: { message: string; status: string };  
}

export const initialState: IContractDetailsData = {
  
  isContractvendormailUpdated: false,
  isContractvendormailUpdatLoading: false,
  isboardApprovalLoading: false,
  isboardApprovalUpdated: false,  
  ToasterMsg: { message: "", status: "" }
  
  
};



export const sendEmailForcontractVendor = createAsyncThunk(
  "sendEmailForcontractVendor",
  async (requestBody: any, { rejectWithValue }) => {
    try {
      const response: any = await contractListService.sendEmailForcontractVendor(requestBody);
      return response;
    } catch (e: any) {
      //rejectWithValue({message:"Contract Added SuccessFully", status: "Success"})
      return rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
    }
  }
);

export const boardApproval = createAsyncThunk("boardApproval", async (requestBody: any, { rejectWithValue }) => {
  try {
    const response: any = await contractListService.boardApproval(requestBody);
    return response;
  } catch (e: any) {
    //rejectWithValue({message:"Contract Added SuccessFully", status: "Success"})
    return rejectWithValue({ message: e?.response?.data?.errors[0], status: e?.response?.data?.statusMessage });
  }
});

export const senForVendorReview = createSlice({
  name: "senForVendorReview",
  initialState,
  reducers: {
    updateSendVendorReviewDetailsAddandEdit: (state, action) => {
      state.isContractvendormailUpdatLoading  = false;   
      state.isContractvendormailUpdated = false;      
      state.ToasterMsg = { message: "", status: "" };
      state.isboardApprovalLoading = false;
      state.isboardApprovalUpdated = false;
    }    
  },
  extraReducers: (builder) => {
    builder      
      .addCase(sendEmailForcontractVendor.pending, (state) => {
        state.isContractvendormailUpdatLoading = true;
      })
      .addCase(sendEmailForcontractVendor.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractvendormailUpdatLoading = false;
        state.isContractvendormailUpdated = true;
        state.ToasterMsg = action?.payload?.payload;
      })
      .addCase(sendEmailForcontractVendor.rejected, (state, action: PayloadAction<any>) => {
        state.isContractvendormailUpdatLoading = false;
        state.isContractvendormailUpdated = true;
        state.ToasterMsg = action?.payload?.payload;
      })
      .addCase(boardApproval.pending, (state) => {
        state.isboardApprovalLoading = true;
      })
      .addCase(boardApproval.fulfilled, (state, action: PayloadAction<any>) => {
        state.isboardApprovalLoading = false;
        state.isboardApprovalUpdated = true;
        state.ToasterMsg = action?.payload?.payload?.toaster;
      })
      .addCase(boardApproval.rejected, (state, action: PayloadAction<any>) => {
        state.isboardApprovalLoading = false;
        state.isboardApprovalUpdated = true;
        state.ToasterMsg = action?.payload;
      });
  },
});

export const { updateSendVendorReviewDetailsAddandEdit } = senForVendorReview.actions;
export default senForVendorReview.reducer;
