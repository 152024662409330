import React, { useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography, Menu, MenuItem, useTheme } from "@mui/material";
import CustomSvg from "../../../CustomSvg";
import CustomRadioButton from "../../../dynamicInputs/CustomRadioButton";
import { useFormContext } from "react-hook-form";
import CustomDialog from "../../../dynamicDialog/CustomDialog";
import AddExistingClause from "../../../../components/contractManagement/ContractTemplate/AddExistingClause";

interface TaggingRiskProps {
  TRData: {
    TRHeading: string;
    TRClauses: { title: string; riskLevel: string }[];
  }[];
}

const TaggingRisk: React.FC<TaggingRiskProps> = ({ TRData }) => {
  const [TR, setTR] = useState(TRData);
  const { setValue } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogBox, setDialogBox] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setValue("taggingRisk", TR);
  }, [TR]);

  const handleToggleRiskLevel = (sectionIndex: number, clauseIndex: number, riskLevel: string) => {
    const updatedTR = TR.map((section, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...section,
          TRClauses: section.TRClauses.map((clause, cIndex) => {
            if (cIndex === clauseIndex) {
              return { ...clause, riskLevel };
            }
            return clause;
          }),
        };
      }
      return section;
    });
    setTR(updatedTR);
  };

  return (
    <Box sx={{ maxHeight: "calc(100vh - 250px)", px: 2, py: 0 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          Tag Risk
        </Typography>
        <IconButton onClick={handleClick}>
          <CustomSvg name={"more-vertical"} color={"#321149"} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            onClick={() => {
              setDialogBox(true);
              setAnchorEl(null);
            }}
            sx={{ display: "flex", gap: "10px" }}
          >
            <CustomSvg color={`${theme.palette.text.secondary}`} name={"plus"} />
            Add Existing Clause
          </MenuItem>
        </Menu>
      </Box>
      <Stack direction="column" spacing={2}>
        {TR.map((data, sectionIndex) => (
          <Box key={data.TRHeading} sx={{ mb: 3 }}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>{data.TRHeading}</Typography>
                <Box sx={{ display: "flex", gap: "40px", marginRight: "25px" }}>
                  <Typography sx={{ textAlign: "left" }}>L</Typography>
                  <Typography sx={{ textAlign: "left" }}>M</Typography>
                  <Typography sx={{ textAlign: "left" }}>H</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {data.TRClauses.map((clause, clauseIndex) => (
                  <Box
                    key={clause.title}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ textAlign: "left", width: "220px" }}>{clause.title}</Typography>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <CustomRadioButton
                        list={[
                          { value: "L", lable: "" },
                          { value: "M", lable: "" },
                          { value: "H", lable: "" },
                        ]}
                        style={{ marginTop: "-7px" }}
                        label=""
                        value={clause.riskLevel}
                        onChange={(e) => handleToggleRiskLevel(sectionIndex, clauseIndex, e)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>

      <CustomDialog
        show={dialogBox}
        onHide={() => setDialogBox(false)}
        maxWidth={"xl"}
        header={`Add Existing Clause`}
        contentNode={<AddExistingClause onClose={() => setDialogBox(false)} onAdd={() => {}} />}
      />
    </Box>
  );
};

export default TaggingRisk;
