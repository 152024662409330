import React, { useEffect, useState } from "react";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { Box } from "@mui/material";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { ACTIVE, COBWorkFlowName } from "../../utils/constants";
import { getWorkFlowByCode } from "../../store/slices/workFlowExecutionslice";
import { fetchWorkFlowByCode, getWorkList } from "../../store/slices/workFlowSlice";
import { getLovList } from "../../store/slices/lovMasterData";
import { IWorkflowData } from "../../models/workFlow.type";
import { COBMainWorkFlow } from "../customerOnBoarding/customerOnBoardingFromWorkFlow/COBMain";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { useNavigate } from "react-router-dom";
import { hideMenu, showMenu } from "../../store/slices/MenuShow";
import { getContentForm } from "../../store/slices/contentMasterData";

export const WelcomeGreetings: React.FC = () => {
  const dispatch = useAppDispatch();
  const [getGreetingMessage, setGreetingMessage] = useState<string>("");
  const { vendorConentPurposes } = useSelector((state: RootState) => state.contentData);
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const [isWorkFlowTriggred, setIsWorkFlowTriggered] = useState<boolean>(false);
  const [onBoardID, setOnBoardID] = useState<Number | undefined>();
  const [workFlowData, setWorkFlowData] = useState<IWorkflowData[]>([]);
  const [COBWorkFlowData, setCOBWorkFlowData] = useState<any[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { isAuthenticated, user, userLoginResponse, isLoginFailed, menuData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const loadData = () => {
    // dispatch(
    //   getVendorConsentList({
    //     FormID: 2,
    //   })
    // );
    const consentPurposesArray = [COBWorkFlowName];
    const queryString = consentPurposesArray.reduce((acc, purpose, index) => {
      acc[`consentPurposes[${index}]`] = purpose;
      return acc;
    }, {} as Record<string, string>);
    dispatch(getContentForm(queryString));
  };
  useEffect(() => {
    loadData();
    dispatch(hideMenu());
  }, []);
  useEffect(() => {
    dispatch(getWorkList());
    dispatch(getLovList({ Name: "" }));
  }, []);
  useEffect(() => {
    setWorkFlowData(workFlowsData);
  }, [workFlowsData]);
  useEffect(() => {
    if (vendorConentPurposes && vendorConentPurposes.length > 0) {
      const customerOnboardingContent = vendorConentPurposes.filter((item: any) => item.IsActive);

      if (customerOnboardingContent.length > 0) {
        let message = customerOnboardingContent[0].Content_Text;

        if (user?.UserName) {
          message = message.replace("Hi XXXX", `Hi ${user.UserName}`);
        }
        // if (user?.UserName) {
        //   message = message.replace("XXXX@yyy.com", `Hi ${user.UserName}`);
        // }
        setGreetingMessage(message);
      }
    }
  }, [vendorConentPurposes]);

  useEffect(() => {
    if (applicationParameterData && applicationParameterData.length > 0) {
      const parameterValue = applicationParameterData.find(
        (n) => n.Parameter_Group === COBWorkFlowName && (n.IsActive as string) === ACTIVE
      )?.Parameter_Value;
      
      const onboardDataID: number | undefined = parameterValue ? parseFloat(parameterValue) : undefined;
      setOnBoardID(onboardDataID);
    }
  }, [applicationParameterData]);

  useEffect(() => {
    if (applicationParameterData && applicationParameterData.length > 0) {
      const filteredParameters = applicationParameterData.filter(
        (n) => n.Parameter_Group === COBWorkFlowName && (n.IsActive as string) === ACTIVE
      );
      if (filteredParameters.length > 1) {
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: "More than one Customer Onboarding is Active",
            isTosterFailed: true,
          })
        );
        setIsError(true);
        setIsLoading(false);
      } else if (filteredParameters.length === 0) {
        // dispatch(
        //   updateToaster({
        //     isToaster: true,
        //     toasterMsg: "No active Customer Onboarding found",
        //     isTosterFailed: true,
        //   })
        // );
        if (isAuthenticated && menuData && menuData.length > 0) {
          dispatch(showMenu());
          if (menuData[0].Url) {
            navigate(menuData[0].Url);
          } else if (!menuData[0].Url && menuData[0].SubMenus && menuData[0].SubMenus.length > 0) {
            if (menuData[0].SubMenus[0].Url) {
              navigate(menuData[0].SubMenus[0].Url);
            } else if (
              !menuData[0].SubMenus[0].Url &&
              menuData[0].SubMenus[0].SubMenus &&
              menuData[0].SubMenus[0].SubMenus.length > 0
            ) {
              navigate(menuData[0].SubMenus[0].SubMenus[0].Url);
            }
          } else {
            navigate("/dashboard");
          }
          dispatch(updateLoader(false));
        } else if (isAuthenticated && (menuData === null || menuData.length === 0)) {
          dispatch(updateLoader(false));
          // dispatch(logoutUser(false));
          // setShowNoMeniNfo(true);
        }

        setIsError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, [applicationParameterData]);

  const triggerWorkFlow: any = async () => {
    if (onBoardID && workFlowData && workFlowData?.length > 0) {
      const foundCategoryItem = workFlowData?.find((n: any) => n.Id === onBoardID);
      // console.log(foundCategoryItem,workFlowData, "foundCategoryItem");
      // setCOBWorkFlowData([foundCategoryItem]);
    }
    const filteredParameters = applicationParameterData.filter(
      (n) => n.Parameter_Group === COBWorkFlowName && (n.IsActive as string) === ACTIVE
    );
    if (filteredParameters.length === 1) {
      const [firstMatch] = filteredParameters;
      const workflowCode = firstMatch?.Parameter_Value;
      // console.log(workflowCode, "workflowCode");
      setIsWorkFlowTriggered(true);

      if (workflowCode) {
        const response = await dispatch(
          getWorkFlowByCode({
            workflowCode: workflowCode,
          })
        );
        // console.log(response?.payload, "ResponseData");
        setCOBWorkFlowData([response?.payload]);
      }
      // if (workflowCode) {
      //   const response = await dispatch(
      //     getWorkFlowByCode({
      //       workflowCode: workflowCode,
      //     })
      //   );

      //   // Create a deep copy of the response payload
      //   if (response?.payload) {
      //     const updatedPayload = { ...response.payload };

      //     if (updatedPayload.WorkflowProcessAsJson && updatedPayload.WorkflowProcessAsJson.length > 0) {
      //       // Deep copy of the WorkflowProcessAsJson array
      //       const updatedWorkflowProcessAsJson = [...updatedPayload.WorkflowProcessAsJson];

      //       // Modify the copy
      //       updatedWorkflowProcessAsJson[0] = {
      //         ...updatedWorkflowProcessAsJson[0],
      //         FormName: "Company Master",
      //       };

      //       // Assign the modified copy back to the payload
      //       updatedPayload.WorkflowProcessAsJson = updatedWorkflowProcessAsJson;
      //     }
      //     console.log(updatedPayload, "Updated Response Data");
      //     setCOBWorkFlowData([updatedPayload]);
      //   }
      // }
    }
  };
  // console.log(
  //   !isLoading && !isError && COBWorkFlowData && COBWorkFlowData.length > 0,
  //   COBWorkFlowData[COBWorkFlowData.length - 1],
  //   "applicationParameterData"
  // );
  console.log(COBWorkFlowData, "COBWorkFlowDataCOBWorkFlowData");
  return (
    <React.Fragment>
      <CustomDialog
        show={!isLoading && !isError && COBWorkFlowData && COBWorkFlowData.length === 0}
        minHeight={"50vh"}
        onHide={() => {}}
        header={"Welcome to C-Life"}
        style={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflowY: "hidden",
              margin: "0px !important",
              minWidth: "100vw important",
              minHeight: "100vh !important",
            },
          },
        }}
        isCloseIconDisabled={true}
        contentNode={
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <div dangerouslySetInnerHTML={{ __html: getGreetingMessage }} />
          </Box>
        }
        actionNode={
          <CustomButton
            type="submit"
            variant="contained"
            disabled={onBoardID === undefined}
            name={"Ok"}
            onClick={() => {
              triggerWorkFlow();
            }}
          />
        }
      />
      {!isLoading && !isError && COBWorkFlowData && COBWorkFlowData.length > 0 && (
        <COBMainWorkFlow data={COBWorkFlowData[COBWorkFlowData.length - 1]} />
      )}
    </React.Fragment>
  );
};
