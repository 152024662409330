import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import {  IVHColumnRes, } from "../../models/assessmentQuestionnaireMaster.type";
import { VendorAssessment_URL } from "./endpoints/vendorAssessment";


const getAllVendorAssessmentList = (reqparams: any): Promise<any> =>
  axiosInstance
    .get(VendorAssessment_URL.GetAllVendorAssessment)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const getVendorAssessment = (reqparams: any): Promise<any> =>
        axiosInstance
          .get(VendorAssessment_URL.GetVendorAssessment , {params:reqparams})
          .then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
              return response.data;
            }
            throw new Error("Error getting Data" + response);
          })
          .catch((error: AxiosError | string) => {
            throw error;
          });

const updateVendorAssessment = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(VendorAssessment_URL.UpdateVendorAssessment, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addVendorAssessment = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(VendorAssessment_URL.AddVendorAssessment, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteVendorAssessment = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(VendorAssessment_URL.DeleteVendorAssessment, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryVendorAssessment = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(VendorAssessment_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const getAllQuestionByVendorandProjectId = (requestBody: any): Promise<IVHColumnRes> =>
      axiosInstance
        .get(VendorAssessment_URL.getAllQuestionByVendorandProjectId, { params: requestBody })
        .then((response: AxiosResponse<IVHColumnRes>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });

        const changethresholdforAssessment = (requestBody: any): Promise<any> =>
          axiosInstance
            .post(VendorAssessment_URL.changethresholdforAssessment, requestBody)
            .then((response: AxiosResponse<any>) => {
              if (response.status === 200) {
                return response.data;
              }
              throw new Error("Error getting Data" + response);
            })
            .catch((error: AxiosError | string) => {
              throw error;
            });

const VendorAssessmentService = {
    getAllVendorAssessmentList,
  getVendorAssessment,
  updateVendorAssessment,
  addVendorAssessment,
  deleteVendorAssessment,
  getVersionHistoryVendorAssessment,
  getAllQuestionByVendorandProjectId,
  changethresholdforAssessment
};

export default VendorAssessmentService;
