import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { Navigate } from "react-router-dom";


function Protected({  isAllowed ,children  }: any) {

    const { menuData, userLoginResponse , isAuthenticated } = useSelector((state: RootState) => state.loggerData);


    const findMenuByURL = (menus: any, targetUrl: string) => {
   
    if(isAuthenticated){
        for (let menu of menus) {
          if (menu.Url && menu.Url?.trim() === targetUrl) {
            return menu;
          }
          if (menu.SubMenus && menu.SubMenus.length > 0) {
            const foundMenu: any = findMenuByURL(menu.SubMenus, targetUrl);
            if (foundMenu) {
              return foundMenu;
            }
          }
        }
        return null;
      }
       else{
        return null;
    }
    }


     return(
         <>
         
     {findMenuByURL(menuData, isAllowed) ? children : <Navigate to={'/'} replace />}
         </>   
            
     )
}

export default Protected;