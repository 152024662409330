import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../common/dynamicAccordion/CustomAccordion";
import { Box, Grid, Stack } from "@mui/material";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { textFieldWidth } from "../../../utils/constants";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";

interface RepresentativeFormProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  onClose?: any;
  onSubmit: (data:any)=>void;
}

interface FormData {
  Representative_Number:number;
  CompanyRepresentative_Id: number;
  // CompanyId: number;
  Purpose: string;
  Name: string;
  Designation: string;
  Contact_Number: string;
  EmailId: string;  
  Status: number;
}

export const RepresentativeForm: React.FC<RepresentativeFormProps> = ({ view, isEditData, edit, onClose, onSubmit }) => {
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [Purpose, SetPurpose] = useState<any[]>([]);
  const formData: FormData = {
    Representative_Number: isEditData ? isEditData.Representative_Number : 0,
    CompanyRepresentative_Id: isEditData ? isEditData.CompanyRepresentative_Id : 0,
    // CompanyId: isEditData ? isEditData.CompanyId : 0,
    Purpose: isEditData ? isEditData.Purpose : "",
    Name: isEditData ? isEditData.Name : "",
    Designation: isEditData ? isEditData.Designation : "",
    Contact_Number: isEditData ? isEditData.Contact_Number : null,
    EmailId: isEditData ? isEditData.EmailId : "",
    Status :isEditData ? isEditData.Status : ""
  };

  useEffect(() => {
    SetPurpose(lovTable.filter((item: any) => item.Name === "Purpose") || []);
  }, [lovTable]);

  const {
    handleSubmit: handleAddressForm,
    reset,
    setValue,
    formState: { errors: error },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stack spacing={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Controller
                name="Purpose"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Purpose is required",
                }}
                render={({ field }) => (
                  <CustomSelect
                    name={"Purpose"}
                    valueKey={"LovId"}
                    optionLabelKey="Options"
                    options={Purpose}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                    required={true}
                    label={"Purpose"}
                    error={Boolean(error["Purpose"])}
                    helperText={error["Purpose"] && error["Purpose"].message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                name="Name"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Name is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    ValueLimitaion: (value) =>
                      /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) || "Cannot contain special characters",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Name"}
                    name={"Name"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Name)}
                    helperText={error.Name && error.Name.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                name="Designation"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Designation is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    ValueLimitaion: (value) =>
                      /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) || "Cannot contain special characters",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Designation"}
                    name={"Designation"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Designation)}
                    helperText={error.Designation?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="Contact_Number"
                control={control}
                rules={{
                  required: "Contact number is required",
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Contact number must be 10 digits",
                  }
                }}
                render={({ field }) => (
                  <CustomNumberField
                    label={"Contact No"}
                    name={"Contact_Number"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.Contact_Number)}
                    helperText={ error.Contact_Number?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <Controller
                name="EmailId"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Email is required",
                  validate: {
                    noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                    maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                    ValueLimitaion: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <CustomTextField
                    label={"Email Id"}
                    name={"EmailId"}
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    error={Boolean(error.EmailId)}
                    helperText={error.EmailId?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={(e, value) => {
                onClose();
              }}
            />
            <CustomButton variant="contained" name={"Save"} onClick={handleAddressForm(onSubmit)} size="small" />
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
