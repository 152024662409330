import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { VendorConsentWithdrawalForm } from "./VendorConsentWithdrawalForm";
import { getWithdrawConsentList } from "../../store/slices/consentFormData";
import CustomToogleSwitch from "../../common/dynamicInputs/CustomToogleSwitch";




export const VendorWithdrawal : React.FC<any> = ({ }) => {
    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
      (state: RootState) => state.loggerData
    );
    const {vendorConsentWithdrawList  } = useSelector(
      (state: RootState) => state.vendorConsentData
    );
    const dispatch = useAppDispatch();
    const [permssionPage ,setPermissionForpage  ] = useState<any>()
    const [ withdrawList ,setisWithdrawList  ] = useState<any>([])
    const [ withdrawData ,setisWithdrawData  ] = useState<any>("")
    const [ isWithdraw ,setIswithdraw  ] = useState<boolean>(false)
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(true);


    useEffect(() => {

      onpageloadingApi()
    },[])

    const onpageloadingApi = () =>{
      dispatch(getWithdrawConsentList());
    }
    useEffect(() => {
      setisWithdrawList(vendorConsentWithdrawList)
    },[vendorConsentWithdrawList])
    useEffect(() => {
        if (currentPagePermissionData !== null) {
          setPermissionForpage(currentPagePermissionData);
        }
      }, [currentPagePermissionData]);
      
      
     // console.log(vendorConsentWithdrawList , 'vendorConsentWithdrawList')

      // const filteredWithdrawList = withdrawList.filter((item:any) =>
      //   toggleSwitch ? item.Consent_Status === 1 : item.Consent_Status === 0
      // );
      
      const groupedWithdrawList = withdrawList.reduce((ary: any, item: any) => {
        console.log(ary)
        if (!ary[item.Vendor_Id]) {
          ary[item.Vendor_Id] = { ...item, consentStatuses: [] };
        }
        ary[item.Vendor_Id].consentStatuses.push(item.Consent_Status);
        return ary;
      }, {});

      //console.log(groupedWithdrawList, 'groupedWithdrawList')
    
      const filteredWithdrawList = Object.values(groupedWithdrawList).filter((item: any) => {
        const ActiveConsent = item.consentStatuses.includes(1);
        console.log(ActiveConsent , 'ActiveConsent')
        return toggleSwitch ? ActiveConsent : !ActiveConsent;
      });
    
      
            const handleToggleSwitch =()=>{ setToggleSwitch(prev => !prev);} 
            
     console.log(filteredWithdrawList)
    
     const withDrawn = !toggleSwitch ?   {
            
      Field:"Consent_Updated_On",
      FieldType: "string",
      DisplayName:"Withdrawn Date" ,
      
    } : null ;

    console.log(withDrawn)

      

      const columns: any = {
        table_name: "Vendor Withdrawal",
         isAction:toggleSwitch?  true : false,
        columns: [
        
          {
            Field: "Group_Name",
            FieldType: "string",
            DisplayName: "Vendor Group Name",
          },
          {
            Field: "Legal_Name",
            FieldType: "string",
            DisplayName: "Vendor Name",
          },

          ...(withDrawn ? [withDrawn] : []),
        
          {
            Field: "Contact_Number",
            FieldType: "string",
            DisplayName: "Registered Contact No",
          },
          {
            Field: "EmailId",
            FieldType: "string",
            DisplayName: "Registered Email Id",
          },
          
          {
            Field: "PAN",
            FieldType: "string",
            DisplayName: "PAN",
          },
          {
            Field: "TAN",
            FieldType: "string",
            DisplayName: "TAN",
          },
          {
            Field: "CIN",
            FieldType: "string",
            DisplayName: "CIN",
          },
          {
            Field: "Jurisdiction_Value",
            FieldType: "string",
            DisplayName: "Applicable Jurisdiction",
          },
          {
            Field: "Constitution_Value",
            FieldType: "string",
            DisplayName: "Constitution",
          },
       
         
        ],
      };


      const handleOpen = (data: any, option: any) => {
        if (option === "run") {
          setIswithdraw(true);
          //console.log(data , 'data list');
          
          setisWithdrawData(data);
        } 
      }
    const CloseForm = () => {
      setIswithdraw(false)
      onpageloadingApi()
    }



    return (
        <>

<Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <PageMainHeading title={permssionPage?.MenuName} />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
              <CustomToogleSwitch
                label={""}
                dynamicLabel={toggleSwitch ? "Active Consent" : "Withdrawn Consent"}
                value={toggleSwitch}
                onChange={() =>handleToggleSwitch()}
              />
            </Box>
          
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={filteredWithdrawList}
                handleAction={handleOpen}
                enableZoomIcon={true}
                disableselect={false}
                showAdd={false}
                showDelete={false}
                showEdit={false}
                showView={false}
                showhistory={false}
                showRun={false}
                showWithdraw={true}
                showExports={permssionPage?.IsExport === 1}
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                pageName="Vendor Withdrawal"
                // isRunDisableByValue={[{ key: "Status", value: "Not Started" }]}
               IswithdrawalActivecontract={toggleSwitch? [{key:"IsAnyActiveContract", value:true}] : []}
              />
            </Box>
          </Stack>     
          </Box>     

          <CustomDialog
        show={isWithdraw}
        onHide={() => CloseForm()}
        maxWidth={"md"}
        header={`Withdraw Consent`}
        contentNode={
          <>
            {" "}
            <VendorConsentWithdrawalForm
              view={ false}
              edit={true}
              onClose={() => CloseForm()}
              isEditData={withdrawData}
              ModuleId={permssionPage?.MenuId}
            />{" "}
          </>
        }
      />
        </>
    )
}