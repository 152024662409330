import { AxiosError, AxiosResponse } from "axios";
import { FORM_BUILDER } from "./endpoints/customFields";
import { ICustomFields, IGetCustomFieldOptions, IGetFormConfiguration, IGetLookupContent, IGetLookupTables } from "../../models/customFields.type";
import { axiosInstance } from "./config/axiosConfig";

const getAllFormsConfiguration = (): Promise<IGetFormConfiguration> => {
  return axiosInstance
    .get(FORM_BUILDER.GetAllFormConfiguration)
    .then((response: AxiosResponse<IGetFormConfiguration>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Getting Form Configuration Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const getAllWorkFlowFormConfiguration = (): Promise<IGetFormConfiguration> => {
  return axiosInstance
    .get(FORM_BUILDER.getAllWorkFlowFormConfiguration)
    .then((response: AxiosResponse<IGetFormConfiguration>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Getting Form Configuration Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};



const addCustomField = (requestBody: any): Promise<ICustomFields> => {
  return axiosInstance
  .post(FORM_BUILDER.CreateCustomField, requestBody)
  .then((response: AxiosResponse<ICustomFields>) => {
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Error Adding Custom Field" + response);
  })
  .catch((error: AxiosError | string) => {
    throw error;
  });
}

const getLookupTables = (): Promise<IGetLookupTables> => {
  return axiosInstance
    .get(FORM_BUILDER.GetLookUpTables)
    .then((response: AxiosResponse<IGetLookupTables>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Getting Lookup Table Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const getLookupContent = (requestBody: any): Promise<IGetLookupContent> => {
  return axiosInstance
    .post(FORM_BUILDER.GetLookupContent, requestBody)
    .then((response: AxiosResponse<IGetLookupContent>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Getting Lookup Content Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const getCustomFieldOptions = (): Promise<IGetCustomFieldOptions> => {
  return axiosInstance
    .get(FORM_BUILDER.GetCustomFieldOptions)
    .then((response: AxiosResponse<IGetCustomFieldOptions>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error Getting Custom Options Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
};

const customFormFieldsService = {
  getAllFormsConfiguration,
  addCustomField,
  getLookupTables,
  getLookupContent,
  getCustomFieldOptions,
  getAllWorkFlowFormConfiguration
};

export default customFormFieldsService;
