import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { IndustryConfig , LovConfig, MasterBulkUpload } from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
import { getIndustryList } from "../../../../store/slices/industryMasterData";
export const industoryImportdata: any = [
  {
    Field: "industory_Type",
    FieldType: "string",
    DisplayName: "Industry Type",
    config: {
      required: true,
    },
  },
  {
    Field: "industory_Name",
    FieldType: "string",
    DisplayName: "Industry Name",
    config: {
      required: true,
    },
  },
 
  {
    Field: "industory_Level",
    FieldType: "string",
    DisplayName: "Industry Level",
    config: {
      required: false,
    },
  },
  {
    Field: "parent_Industory",
    FieldType: "string",
    DisplayName: "Parent Industry",
    config: {
      required: false,
    },
  },
];

const IndustoryMasterUploadSheet = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);
  const {
    industryTable,
} = useSelector((state: RootState) => state.indutryMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [industoryTypeList, setIndustoryTypeList] = useState<any[]>([]);
  const [industoryLevelList, setIndustoryLevelList] = useState<any[]>([]);
  const [industoryList, setIndustoryList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(
        getIndustryList({
            FormID: 2,
        })
    );
  }, []);

  useEffect(() => {
    setIndustoryTypeList(lovTable.filter((item: any) => item.Name === LovConfig.Industry_Type) || []);
    setIndustoryLevelList(lovTable.filter((item: any) => item.Name === LovConfig.Industry_Level) || []);
  }, [lovTable]);

  useEffect(() => {
    setIndustoryList(industryTable || []);
  }, [industryTable]);

  const { setselectedValidation, uploaddata } = context || {};

  const findParentIndustoryLevel = (currentLevel: any) => {
    let level = currentLevel;
    while (level > 1) {
      level -= 1;
      const targetOption = `Level ${level}`;
      const findSelectIndustoryLevel = industoryLevelList.find((item: any) => item.Options === targetOption);
      if (findSelectIndustoryLevel) {
        return findSelectIndustoryLevel.LovId;
      }
    }
    return 0;
  };

  //validation the depatment Fields
  const industory_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
      let ValidatedData: any[] = [];
      uploaddata.forEach((item: any , index : number) => {
        let error: string = "";
        let object: any = {
          ...item,
          industory_Type: "",
          industory_Type_Id: "",
          industory_Name: "",
          industory_Code: "",
          industory_Level: "",
          industory_Level_Id: "",
          parent_Industory: "",
          parent_Industory_Id: "",
          id: index + 1
        };
        industoryImportdata.map((header_item: any) => {
          let findValidIndustorytype = industoryTypeList.find(
            (typeitem) => typeitem.Options == item["Industry Type"]
          );
          let findValidIndustoryLevel = industoryLevelList.find(
            (typeitem) => typeitem.Options == item["Industry Level"]
          );
          //validation the depatment Type Fields
          if (header_item.Field === "industory_Type") {
            if (findValidIndustorytype?.LovId) {
              object[header_item.Field] = item[header_item.DisplayName];
              object.industory_Type_Id = findValidIndustorytype?.LovId;
            } else {
              if (item[header_item.DisplayName]) {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Industry Type,";
              } else {
                error += " Industry Type is Required,";
              }
            }
          }
          //validation the depatment Name Fields
          if (header_item.Field === "industory_Name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Industry Name is Required,";
            }
          }
        
          //validation the depatment Level Fields
          if (header_item.Field === "industory_Level") {
            if (item[header_item.DisplayName]) {
              //validate if industory selected only select Level 1 or sub industory selected Not Level 1 , condition applyed
              if (
                findValidIndustoryLevel?.LovId &&
                ((findValidIndustoryLevel?.Options == IndustryConfig.Industry_Level_1 &&
                  findValidIndustorytype?.Options == IndustryConfig.Industry) ||
                  (findValidIndustorytype?.Options == IndustryConfig.Sub_Industry &&
                    findValidIndustoryLevel?.Options != IndustryConfig.Industry_Level_1))
              ) {
                object[header_item.Field] = item[header_item.DisplayName];
                object.industory_Level_Id = findValidIndustoryLevel?.LovId;
              } else {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Industry Level";
              }
            } else {
              error += " Industry Level is Required";
            }
          }
          // parent industory level validation checked , Not Level 1 if required Parent Industry field
          if (
            header_item.Field === "parent_Industory" &&
            findValidIndustorytype &&
            findValidIndustorytype?.Options == IndustryConfig.Sub_Industry &&
            findValidIndustoryLevel
          ) {
            console.log(findValidIndustorytype, "findselectedParentObject 1", findValidIndustoryLevel);
            // parent level find
            const currentLevel = parseInt(findValidIndustoryLevel.Options.split(" ")[1]);
            const parentIndustoryLevelId = findParentIndustoryLevel(currentLevel);

            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              // split depatement name from selected value and validate
              let parentselectedvalue = item[header_item.DisplayName].split(" - ")[0];
              let findselectedParentObject = industoryList.find(
                (par_item: any) =>
                  par_item.IndustryName == parentselectedvalue && par_item.Parent_Level == parentIndustoryLevelId
              );
              console.log(findselectedParentObject, "findselectedParentObject", parentselectedvalue);

              if (findselectedParentObject && findselectedParentObject?.IndustryId) {
                object.parent_Industory = item[header_item.DisplayName];
                object.parent_Industory_Id = findselectedParentObject?.IndustryId;
              } else {
                object.parent_Industory = item[header_item.DisplayName];
                error += " Invalid Parent Industry";
              }
            } else {
              error += " Parent Industry is Required";
            }
          }
          object.error = error;
        });
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      industory_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const hiddenSheet = workbook.addWorksheet("HiddenSheet"); // Hidden sheet for large data lists
      let sheet_Column: any = industoryImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);

      // Get your list data
      let IndLevel = industoryLevelList?.map((item) => item.Options);
      let IndType = industoryTypeList?.map((item) => item.Options);
      let IndList = industoryList?.map((item) => item.IndustryName + " - " + item.Parent_LevelValues);

      IndList.forEach((item, index) => {
        hiddenSheet.getCell(`A${index + 1}`).value = item;
      });
      hiddenSheet.state = "hidden";

      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        worksheet.getCell(`A${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${IndType.join(",")}"`],
          showErrorMessage: true,
          errorTitle: "Invalid Intustory Type for",
          error: "Please select an Intustory Type from the list",
        };

        worksheet.getCell(`B${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: false,
          formulae: [55],
        };

       

        worksheet.getCell(`C${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${IndLevel.join(",")}"`],
        };

        worksheet.getCell(`D${row}`).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [`HiddenSheet!$A$1:$A$${IndList.length}`],
        };
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "Industry_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <CustomButton type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default IndustoryMasterUploadSheet;
