import React from 'react';
// import { styled } from '@mui/system';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import breadcrumbsStyle from "./custombreadcrumbs.module.scss";
import { Box } from '@mui/material';

interface CustomBreadCrumpProps {
    list: any[];
    onchange? : any;
  }
  const CustomBreadcrumbs: React.FC<CustomBreadCrumpProps> = (props) => {
    const {
        list = [] ,
        onchange
    } = props;

    const valueChange = (val : any) => {
         if(onchange){
          onchange(val)
         }
    }

  return (
    <>
  
       {list.length > 0 && <Box className={`${breadcrumbsStyle.breadcrumb} ${breadcrumbsStyle.flat}`}>

{list.map((item : any , index : number) => (
    item.link ? (
      <Link className='sec' key={index} href={item.link} underline="none">
        {item.name}
      </Link>
    ) : (
      <Link className='sec' key={index} onClick ={() => valueChange(item.value)} underline="none">
        {item.name}
      </Link>
      
    )
))}
       

       
</Box>}

    </>
   
  );
}

export default CustomBreadcrumbs;
