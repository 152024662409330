import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import CustomSvg from '../CustomSvg';


interface CustomDialogProps {
    steps: number;
    activeStep: number;
    completed?: number[];
    pending?: number[];
    handleAction?: () => void;
}

export const CustomSquareStepper: React.FC<CustomDialogProps> = (props) => {
    const {
        steps = 0,
        activeStep = 0,
        completed = [],
        pending = [],
    } = props;

    const theme = useTheme();
    //   const steps = 15; 

    //   const completed = [0, 1, 2, 5];
    //   const pending = [3, 4];
    //   const activeStep = 6; 

    // const containerWidth = 300;
    // const gap = 5;
    // const stepSize = (containerWidth - (steps - 1) * gap) / steps;

    const containerWidth = 200;
    const gap = 5;

    const getStepColor = (index: any) => {
        if (completed.includes(index)) return '#4caf50'; // Green for completed steps
        if (pending.includes(index)) return '#ffa726'; // Orange for pending steps
        if (index === activeStep) return '#1976d2'; // Blue for active step
        return '#c4c4c4'; // Default grey for remaining steps
    };

    const handleClose = () => {
        if (props.handleAction) {
            props.handleAction();
        }
    };

    return (
        <Box
            sx={{
                width: containerWidth,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                pl: "10px",
                backgroundColor: 'background.paper',
                borderRadius: 1.5,
                boxShadow: '2px 4px 12px rgba( 0.1, 0.1,  0.1, 0.1)',
                border: '0.5px solid #ddd',  
            }}
        >
            {/* Dynamically render steps with adjusted size */}
            {Array.from({ length: steps }).map((_, index) => (
                <Box
                    key={index}
                    sx={{
                        flexGrow: 1, // Allow each step to grow to fill the available space
                        flexBasis: 0, // Ensure flex-grow is in control
                        height: 10, // Adjust height of the step for small size
                        backgroundColor: getStepColor(index),
                        transition: 'background-color 0.3s ease',
                        marginRight: index < steps - 1 ? `${gap}px` : 0, // Add gap except for the last step
                    }}
                />
            ))}

            {/* Placeholder for close icon */}
            <Box sx={{ pl: 1 }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{

                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    {/* <CloseIcon /> */}
                    <CustomSvg color={`${theme.palette.primary.main}`}size={16} name={"Expand-fullScreen"} />
                </IconButton>
            </Box>
        </Box>

    );
}
