import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';

import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { RootState } from '../../store/store';
import { textFieldWidth } from '../../utils/constants';
import CustomTextField from '../../common/dynamicInputs/CustomTextField';
import CustomSelect from '../../common/dynamicInputs/CustomSelect';
import CustomButton from '../../common/dynamicButton/CustomButton';
import CustomToogleSwitch from '../../common/dynamicInputs/CustomToogleSwitch';
import CustomNumberField from '../../common/dynamicInputs/CustomNumberField';

interface FormData {
    DepartmentID?: number;
    FunctionName: string;
    SubDepartmentName: string;
    IsActive?: number;

}

export interface SubDepartmentProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
}

export const AddSubDepartmentMaster: React.FC<SubDepartmentProps> = ({
    view, closePopUp, edit, isEditData
}) => {


    const { user } = useSelector((state: RootState) => state.loggerData);
    const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
    const [message, setMessage] = useState<boolean>(false);


    let FunctionName = [
        { id: "", lable: "Select your Location" },
        { id: "HR", lable: "HR" },
        { id: "Finance", lable: "Finance" },
        { id: "Devlop", lable: "Devlop" },
    ];

   


    console.log(isEditData, "isEditData");

    let formData: any = {
        industryID: edit ? isEditData?.id : 0,
        FunctionName: isEditData ? isEditData?.FunctionName : "",
        SubDepartmentName: isEditData ? isEditData?.SubDepartmentName : "",
        IsActive: isEditData ? isEditData?.Status : "",

    };

    const {
        handleSubmit: handleSubdepartmentForm,
        reset,
        setValue,
        formState: { errors: errorsloginform },
        control,
        watch,
        getValues,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });
    const handleSubmit = () => {
        console.log(getValues())

    };



    return (
        <Grid
            container
            component="main"
            sx={{ height: "auto", width: "100%", overflow: "hidden" }}
        >
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >
                
                <Grid
                    sx={{
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>

                            <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="FunctionName"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Function Name is required" }}
                                        render={({ field }) => (
                                            <CustomSelect
                                                name={"FunctionName"}
                                                valueKey={"id"}
                                                optionLabelKey="lable"
                                                options={FunctionName}
                                                disabled={view}
                                                required={true}
                                                label={"Function Name"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.FunctionName)}
                                                helperText={
                                                    errorsloginform.FunctionName &&
                                                    errorsloginform.FunctionName.message?.toString()
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                   xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="SubDepartmentName"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Sub Department Name is Required",
                                            validate: (data) => {
                                                return (
                                                    data.length >= 4 || "Minimal 4 characters requires"
                                                );
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomTextField
                                                name={"SubDepartmentName"}
                                                required={true}
                                                disabled={view}
                                                label={"Sub Department Name"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.SubDepartmentName)}
                                                helperText={
                                                    errorsloginform.SubDepartmentName &&
                                                    errorsloginform.SubDepartmentName.message?.toString()
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                {edit || view ? (<Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Box sx={{mt:1}}>
                                        <Controller
                                            name="IsActive"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomToogleSwitch
                                                    label={"User Status"}
                                                    dynamicLabel={field.value ? 'ACTIVE' : 'INACTIVE'}

                                                    disabled={view}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    </Box>

                                </Grid>) : null}

                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "right",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton type="reset" name={"Update"} onClick={handleSubdepartmentForm(handleSubmit)} />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton type="reset" name={"Submit"} onClick={handleSubdepartmentForm(handleSubmit)} />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
        </Grid>
    );
};
