import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { getLovList } from "../../../store/slices/lovMasterData";
import { addCurrency, updateCurrency } from "../../../store/slices/currencyData";
import { getParentLocationList } from "../../../store/slices/locationMasterData";
import ProgressBar from "../../../common/progressBar/progressBar";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddLocation } from "../location/AddLocation";
import { LocationConfig, LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";

interface FormData {
  CurrencyId?: number;
  CurrencyCode: string;
  CurrencyName: string;
  Symbol: string;
  Country_Id: string;
  Default_Currency: string;
  IsActive: string;
}

export interface RegionalSettingsProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
}

export const AddCurrencyMaster: React.FC<RegionalSettingsProps> = ({ view, closePopUp, edit, isEditData }) => {
  const dispatch = useAppDispatchThunk();
  const { CurrencyData, isCurrencyUpdated, isCurrencyAdded, isCurrencyDeleted, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.CurrencyData
  );
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const [message, setMessage] = useState<boolean>(false);
  const [currencyCode_Data, setCurrencyCode_Data] = useState([]);
  const [AddLocationForm, setAddLocationForm] = useState<boolean>(false);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);

  console.log(isEditData, "isEditData");

  let formData: any = {
    CurrencyId: edit ? isEditData?.CurrencyId : 0,
    CurrencyCode: isEditData ? isEditData?.CurrencyCode : "",
    CurrencyName: isEditData ? isEditData?.CurrencyName : "",
    Symbol: isEditData ? isEditData?.Symbol : "",
    Country_Id: isEditData ? isEditData?.Country_Id : "",
    Default_Currency: isEditData ? isEditData?.Default_Currency : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
    Remarks: isEditData ? (isEditData?.Remarks ? isEditData?.Remarks : "") : "",
  };

  const {
    handleSubmit: handleRegionalSettings,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const isActiveDisabled = watch("IsActive");
  const handleSubmit = (data: any) => {
    console.log(isEditData, "isEditData");
    dispatch(updateLoader(true));
    let formData = data;
    formData.Status = formData.Status ? 1 : 0;
    formData.IsActive = formData.IsActive ? true : false;
    formData.ActionTaken = "";

    let JsonDataForDisplay : any = {
      "Currency Code" : formData.CurrencyCode,
      "Currency Name" : formData.CurrencyName,
      "Symbol" : formData.Symbol,
      "Country" : ParentLocationTable.find((item: any) => item.Location_Id == formData?.Country_Id )?.Location_Name || '',
      "Status" : formData.IsActive? "Active" : "InActive",
      "Modified By" : user?.UserName,
    }    

    if (edit) {
      formData.UpdatedBy = user?.UserName;
      formData.CreatedBy = user?.UserName;
      formData.MenuId = currentPagePermissionData?.MenuId;
      formData.ModuleName = "Currency Master";
      formData.Activity = "Edit";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay) ;
      formData.UserId = userLoginResponse?.UserId;
      formData.Form_Id = 13;
      dispatch(updateCurrency(formData));
    } else {
      formData.UpdatedBy = user?.UserName;
      formData.CreatedBy = user?.UserName;
      delete formData.DepartmentId;
      formData.MenuId = currentPagePermissionData?.MenuId;
      formData.ModuleName = "Currency Master";
      formData.Activity = "create";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay) ;
      formData.UserId = userLoginResponse?.UserId;
      formData.Form_Id = 13;
      dispatch(addCurrency(formData));
    }
  };

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    setCurrencyCode_Data(lovTable);
    console.log(lovTable, "lovTable");

    let dublocationType: any[] = lovTable.filter((item: any) => item.Name ===  LovConfig.Location_Type);
    console.log(dublocationType, "dublocationType");
    
    if (dublocationType && dublocationType.length > 0) {
      let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_Country);
      console.log(countryTypeId, "countryTypeId");

      if (countryTypeId && countryTypeId.LovId) {
        dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
        console.log("countryApiCall");

      }
    }
    
  }, [lovTable]);

  useEffect(() => {
    if (isCurrencyAdded && closePopUp) {
      reset(formData);
      closePopUp();
      dispatch(updateLoader(false));
    } else if (isCurrencyUpdated && closePopUp) {
      reset(formData);
      closePopUp();
      dispatch(updateLoader(false));
    }
  }, [isCurrencyUpdated, isCurrencyAdded]);

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add Branch Master
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit Branch Master
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View Branch Master
              </Typography>
            )}
          </Box>
        )}
        <Grid
          sx={{
            height: "auto",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="CurrencyCode"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Currency Code is Required",
                      
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 3 || "Maximum 3 characters Required",
                        ValueLimitation: (value) => /^[a-zA-Z]+$/.test(value.trim()) || "Currency Code cannot contain special characters, number and spaces",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"CurrencyCode"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Currency Code"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.CurrencyCode)}
                        helperText={errorsloginform.CurrencyCode && errorsloginform.CurrencyCode.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="CurrencyName"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Currency Name is Required",

                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 100 || "Maximum 100 characters Required",
                        minAlphabets: (value) => (value?.match(/[a-zA-Z]/g) || []).length >= 2 || "Minimum 2 alphabets required",

                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"CurrencyName"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Currency Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.CurrencyName)}
                        helperText={errorsloginform.CurrencyName && errorsloginform.CurrencyName.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Symbol"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Symbol is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                        minLength: (value) => value.length >= 1 || "Minimal 1 characters Required",
                        maxLength: (value) => value.length <= 1 || "Maximum 1 characters Required",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Symbol"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Symbol"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Symbol)}
                        helperText={errorsloginform.Symbol && errorsloginform.Symbol.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Country_Id"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Country is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"Country_Id"}
                        valueKey={"Location_Id"}
                        optionLabelKey="Location_Name"
                        options={ParentLocationTable}
                        // addField={true}
                        // onAddClick={() => {
                        //     setAddLocationForm(true);
                        // }}
                        disabled={view || !isActiveDisabled}
                        required={true}
                        label={"Country"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Country_Id)}
                        helperText={errorsloginform.Country_Id && errorsloginform.Country_Id.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                {/* {edit || view ? (
                  <Grid
                    item
                    xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsActive"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"User Status"}
                            dynamicLabel={field.value ? "ACTIVE" : "INACTIVE"}
                            disabled={view}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                ) : null} */}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "end",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    name={"Cancel"}
                    onClick={() => {
                      closePopUp && closePopUp();
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Update"}
                      onClick={handleRegionalSettings(handleSubmit)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Submit"}
                      onClick={handleRegionalSettings(handleSubmit)}
                    />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* <CustomDialog
                show={AddLocationForm}
                onHide={() => setAddLocationForm(false)}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={"Add Location"}
                contentNode={
                    // <></>
                    <AddLocation
                        view={false}
                        closePopUp={() => {
                            setAddLocationForm(false);
                        }}
                    />
                }
            /> */}
    </Grid>
  );
};
