import axios from 'axios';
import {version,signzyVersion} from './constant';
const {REACT_APP_API_URL,REACT_APP_CORS_SIGNZY_URL,REACT_APP_SIGNZY_URL,REACT_APP_SIGNZY_TOKEN,REACT_APP_PROBE_URL,REACT_APP_PROBE_KEY}= process.env;

export const axiosInstance = axios.create({
    baseURL:`${REACT_APP_API_URL}${version}`
});
export const axiosSingnzyInstance = axios.create({
    baseURL:`${REACT_APP_CORS_SIGNZY_URL}${signzyVersion}${REACT_APP_SIGNZY_URL}`
});
export const axiosProbeInstance = axios.create({
    baseURL:`${REACT_APP_PROBE_URL}${version}`
});


axiosInstance.interceptors.request.use(
    (config : any)=>{    
    let token = sessionStorage.getItem("Token");
    config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';   
    config.headers['UserId'] = sessionStorage.getItem("UserId");
    if(token){
        config.headers['Authorization'] = `Bearer ${token}`
    }   
    return config;
    },
    (error : any) => {
        console.log(error)
        Promise.reject(error)
    }
)
axiosSingnzyInstance.interceptors.request.use(
    (config : any)=>{    
    let token = REACT_APP_SIGNZY_TOKEN;
    console.log(token,"REACT_APP_SIGNZY_TOKEN");
    config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';   
    if(token){
        config.headers['Authorization'] = `${token}`
    }   
    return config;
    },
    (error : any) => {
        console.log(error)
        Promise.reject(error)
    }
)

axiosProbeInstance.interceptors.request.use(
    (config : any)=>{    
    let token = REACT_APP_PROBE_KEY;
    config.headers['accept'] = 'application/json';   
    config.headers['x-api-version'] = "1.3";
    if(token){
        config.headers['x-api-key'] = `${token}`
    }   
    return config;
    },
    (error : any) => {
        console.log(error)
        Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    async (response : any) => {
        if(response.status === 401){
            // need to check wat needs to be done if authentication fails
            console.log("Authenicate fails", response)
        }else{
            
        }
        return response
    }
)
axiosSingnzyInstance.interceptors.response.use(
    async (response : any) => {
        if(response.status === 401){
            // need to check wat needs to be done if authentication fails
            console.log("Authenicate fails", response)
        }else{
            
        }
        return response
    }
)

axiosProbeInstance.interceptors.response.use(
    async (response : any) => {
        if(response.status === 401){
            // need to check wat needs to be done if authentication fails
            console.log("Authenicate fails", response)
        }else{
            
        }
        return response
    }
)