import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { DepartmentConfig, LocationConfig, LovConfig, MasterBulkUpload } from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
import { getParentLocationList } from "../../../../store/slices/locationMasterData";
export const CurrencyImportdata: any = [
  {
    Field: "currency_code",
    FieldType: "string",
    DisplayName: "Currency Code",
    config: {
      required: true,
    },
  },
  {
    Field: "currency_name",
    FieldType: "string",
    DisplayName: "Currency Name",
    config: {
      required: true,
    },
  },
  {
    Field: "symbol",
    FieldType: "string",
    DisplayName: "Symbol",
    config: {
      required: true,
    },
  },
  {
    Field: "country",
    FieldType: "string",
    DisplayName: "Country",
    config: {
      required: true,
    },
  },
];

const CurrencyMasterUploadSheet: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const [countryList, setCountrytList] = useState<any[]>([]);

  useEffect(() => {
    let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig.Location_Type);
    console.log(dublocationType, "dublocationType");
    if (dublocationType && dublocationType.length > 0) {
      let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_Country);

      if (countryTypeId && countryTypeId.LovId) {
        dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
      }
    }
  }, [lovTable]);

  useEffect(() => {
    setCountrytList(ParentLocationTable);
  }, [ParentLocationTable]);

  const { setselectedValidation, uploaddata } = context || {};

  //validation the depatment Fields
  const department_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
      let ValidatedData: any[] = [];
      const encounteredCountryNames = new Set<any>();
      uploaddata.forEach((item: any) => {
        let error: string = "";
        let object: any = {
          ...item,
          currency_code: "",
          currency_name: "",
          symbol: "",
          country_Id: "",
          country: "",
        };
        CurrencyImportdata.map((header_item: any) => {
          //validation the depatment Type Fields
          if (header_item.Field === "country") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              const CountName: any = item[header_item.DisplayName];
              object[header_item.Field] = CountName;
              const findSelectCountry = countryList.find((locitem: any) => locitem.Location_Name == CountName);
            
              if (findSelectCountry && findSelectCountry?.Location_Id) {
                object.country_Id = findSelectCountry?.Location_Id;
                if (encounteredCountryNames.has(findSelectCountry?.Location_Id)) {
                  error += `Duplicate Country: ${CountName}, `;
                } else {
                  encounteredCountryNames.add(findSelectCountry?.Location_Id); // Track new branch code
             
                }
              } else {
                error += " Invalid Country,";
              }
            } else {
              error += " Country is Required,";
            }
          }
          //validation the depatment Name Fields
          if (header_item.Field === "currency_code") {
            const currencyCode = item[header_item.DisplayName];
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
              if (currencyCode?.length === 3) {
                object[header_item.Field] = currencyCode;
              } else {
                error += " Currency Code must be exactly 3 characters, ";
              }
            } else {
              error += " Currency Code is Required,";
            }
          }

          if (header_item.Field === "currency_name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Currency Name is Required,";
            }
          }
          if (header_item.Field === "symbol") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Symbol is Required,";
            }
          }

          object.error = error;
        });
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      department_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const hiddenSheet = workbook.addWorksheet("HiddenSheet"); // Hidden sheet for large data lists
      let sheet_Column: any = CurrencyImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);

      let CouList = countryList
        ?.filter((citem) => citem.LocationTypeValue == LocationConfig.Location_Country)
        .map((item) => item.Location_Name);

      CouList.forEach((item, index) => {
        hiddenSheet.getCell(`A${index + 1}`).value = item;
      });
      hiddenSheet.state = "hidden";

      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        worksheet.getCell(`A${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: true,
          formulae: [55],
        };

        const countryName = CouList[row - 2]; // Adjust for zero-indexed array
        if (countryName) {
          worksheet.getCell(`D${row}`).value = countryName; // Directly set country name
        }
      }

      //     worksheet.getCell(`D${row}`).dataValidation = {
      //       type: "list",
      //       allowBlank: true,
      //       formulae: [`HiddenSheet!$A$1:$A$${CouList.length}`],
      //     };
      //   }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "Currency_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <CustomButton type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default CurrencyMasterUploadSheet;
