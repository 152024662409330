import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import locationMasterService from "../services/locationMasterService";
import { ILocation, IVHColumnRes } from "../../models/location.type";
import { chkDate } from "../../utils/constants";

export interface ILocationData {
    locationTable: any;
    ParentLocationTable: any;
    isLoading: boolean;
    islocationAdded: boolean;
      locationVersionHistory:{LocationRecords :ILocation[] , LocationVHeader : IVHColumnRes[]};
    islocationHistory: boolean;
    islocationDeleted: boolean;
    ToasterMsg: any;
}

export const initialState: ILocationData = {
    locationTable: [],
    ParentLocationTable: [],
    isLoading: false,
    islocationAdded: false,
      locationVersionHistory:{LocationRecords :[] , LocationVHeader : []},
    islocationHistory: false,
    islocationDeleted: false,
    ToasterMsg: {Message: "", Status: ""},
};

export const getLocationList = createAsyncThunk(
    "getLocationList",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await locationMasterService.getLocationList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getParentLocationList = createAsyncThunk(
    "getParentLocationList",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await locationMasterService.getParentLocationList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);



export const addEditLocation = createAsyncThunk("addEditLocation", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await locationMasterService.addEditLocation(requesrParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteLocation = createAsyncThunk(
    "deleteLocation",
    async (requesrParams: any, { rejectWithValue }) => {
        console.log(requesrParams,"requesrParams");
        
        try {
            let reqbody = { ...requesrParams };
            console.log(reqbody,"requesrParams");
            const response: any = await locationMasterService.deleteLocation(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLocationHistory = createAsyncThunk("getLocationHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await locationMasterService.getVersionHistoryLocation(reqbody);
    const {LocationMaster , HeaderJson } =response.data;
    return {LocationRecords:LocationMaster, HeaderJson : HeaderJson.ColumnHeaders}

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const locationMasterDataSlice = createSlice({
    name: "locationMasterData",
    initialState,
    reducers: {
        updateLocationAddandEdit: (state, action) => {
            state.islocationAdded = false;
            state.islocationDeleted = false;
            state.ToasterMsg = {Message: "", Status: ""};
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.locationTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.locationTable];
                copyVendorData[indexVendor] = action.payload;
                state.locationTable = copyVendorData;
            }
        },
        updateVersionHistory:  (state, action)=>{            
          state.locationVersionHistory.LocationRecords = [];
          state.locationVersionHistory.LocationVHeader = [];
        }, 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocationList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLocationList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.locationTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getLocationList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(getParentLocationList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getParentLocationList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.ParentLocationTable = action.payload
                state.isLoading = false;
            })
            .addCase(getParentLocationList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(addEditLocation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEditLocation.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action.payload , 'action.payload1');
                //     let item = {...action.payload};
                //     let copyallUsers= [...state.dataTable];
                //     copyallUsers.push(item);
                //     state.dataTable = copyallUsers;
                state.islocationAdded = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(addEditLocation.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(deleteLocation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteLocation.fulfilled, (state, action: PayloadAction<any>) => {
                state.islocationDeleted = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(deleteLocation.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
          .addCase(getLocationHistory.pending, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })
          .addCase(getLocationHistory.fulfilled, (state, action: PayloadAction<any>) => {
            state.locationVersionHistory.LocationVHeader = action.payload.HeaderJson
            //console.log(state.locationVersionHistory.LocationVHeader)
            state.locationVersionHistory.LocationRecords = action.payload.LocationRecords.map((user: any) => {
                user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                user.Approved_On = chkDate(user.Approved_On);
                console.log(user)

                return user ;
            });
            state.isLoading = false;
            // state.ToasterMsg = "Department data deleted successfully";
          })
          .addCase(getLocationHistory.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })

    },
});

export const { updateLocationAddandEdit, updateUser, updateVersionHistory} = locationMasterDataSlice.actions;
export default locationMasterDataSlice.reducer;
