import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BranchMasterService from "../services/branchMasterService";
import { IBranch, IVHColumnRes } from "../../models/branch.type";
import { chkDate } from "../../utils/constants";

export interface IBranchData {
    BranchTable: any;
    BranchByCompanyTable: any;
    isLoading: boolean;
    isBranchAdded: boolean;
      versionHistory:{BranchRecords :IBranch[] , BranchVHeader : IVHColumnRes[]};
    isBranchHistory: boolean;
    isBranchDeleted: boolean;
    ToasterMsg: any;
}

export const initialState: IBranchData = {
    BranchTable: [],
    BranchByCompanyTable: [],
    isLoading: false,
    isBranchAdded: false,
      versionHistory:{BranchRecords :[] , BranchVHeader : []},
    isBranchHistory: false,
    isBranchDeleted: false,
    ToasterMsg: {Message: "", Status: ""},
};

export const getBranchList = createAsyncThunk(
    "getBranchList",
    async (requesrParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await BranchMasterService.getBranchList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const GetBranchByCompanyList = createAsyncThunk(
    "GetBranchByCompanyList",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            const response = await BranchMasterService.GetBranchByCompany(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const addEditBranch = createAsyncThunk("addEditBranch", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await BranchMasterService.addEditBranch(requesrParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteBranch = createAsyncThunk(
    "deleteBranch",
    async (requesrParams: any, { rejectWithValue }) => {
        console.log(requesrParams,"requesrParams");
        
        try {
            let reqbody = { ...requesrParams };
            console.log(reqbody,"requesrParams");
            const response: any = await BranchMasterService.deleteBranch(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getBranchHistory = createAsyncThunk("getBranchHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await BranchMasterService.getVersionHistoryBranch(reqbody);
    const {BranchMaster , HeaderJson } =response.data;
    return {BranchRecords:BranchMaster, HeaderJson : HeaderJson.ColumnHeaders}

  } catch (error) {
    return rejectWithValue(error);
  }
});

export const BranchMasterDataSlice = createSlice({
    name: "BranchMasterData",
    initialState,
    reducers: {
        updateBranchAddandEdit: (state, action) => {
            state.isBranchAdded = false;
            state.isBranchDeleted = false;
            state.ToasterMsg = {Message: "", Status: ""};
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.BranchTable.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.BranchTable];
                copyVendorData[indexVendor] = action.payload;
                state.BranchTable = copyVendorData;
            }
        },
        updateVersionHistory:  (state, action)=>{            
          state.versionHistory.BranchRecords = [];
          state.versionHistory.BranchVHeader = [];
        }, 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranchList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getBranchList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.BranchTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(getBranchList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(GetBranchByCompanyList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetBranchByCompanyList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                // state.BranchByCompanyTable = action.payload
                state.BranchByCompanyTable = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
                state.isLoading = false;
            })
            .addCase(GetBranchByCompanyList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(addEditBranch.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEditBranch.fulfilled, (state, action: PayloadAction<any>) => {
              
                state.isBranchAdded = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(addEditBranch.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(deleteBranch.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteBranch.fulfilled, (state, action: PayloadAction<any>) => {
                state.isBranchDeleted = true;
                state.isLoading = false;
                // state.ToasterMsg = action.payload.Message;
                state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
            })
            .addCase(deleteBranch.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
          .addCase(getBranchHistory.pending, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })
          .addCase(getBranchHistory.fulfilled, (state, action: PayloadAction<any>) => {
            state.versionHistory.BranchRecords = action.payload.BranchRecords.map((user: any) => {
                user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                user.CreatedOn = chkDate(user.CreatedOn);

                return user;
            });
            state.versionHistory.BranchVHeader = action.payload.HeaderJson
            state.isLoading = false;
            // state.ToasterMsg = "Department data deleted successfully";
          })
          .addCase(getBranchHistory.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
          })

    },
});

export const { updateBranchAddandEdit, updateUser, updateVersionHistory } = BranchMasterDataSlice.actions;
export default BranchMasterDataSlice.reducer;
