import React, { useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography, Menu, MenuItem, useTheme } from "@mui/material";
import CustomCheckBox from "../../../dynamicInputs/CustomCheckBox";
import CustomSvg from "../../../CustomSvg";

interface DOReviewMappingProps {
  DOData: {
    DOHeading: string;
    DOClauses: { title: string; isSelected: boolean }[];
  }[];
  selectedDeviationClauses: any;
  setSelectedDeviationClauses: any;
}

const Deviation: React.FC<DOReviewMappingProps> = ({
  DOData,
  selectedDeviationClauses,
  setSelectedDeviationClauses,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const [DO, setDO] = useState(DOData);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const selectedCount = DO.reduce((count, section) => {
      return count + section.DOClauses.filter((clause) => clause.isSelected).length;
    }, 0);
    setSelectedDeviationClauses(selectedCount);
  }, [DO]);

  const handleToggleClause = (sectionIndex: number, clauseIndex: number) => {
    const updateDO = [...DO];
    updateDO[sectionIndex].DOClauses[clauseIndex].isSelected =
      !updateDO[sectionIndex].DOClauses[clauseIndex].isSelected;
    setDO(updateDO);
  };

  const handleSelectDeselectToggle = (sectionIndex: number) => {
    const allSelected = DO[sectionIndex].DOClauses.every((clause) => clause.isSelected);
    const updateDO = [...DO];
    updateDO[sectionIndex].DOClauses = updateDO[sectionIndex].DOClauses.map((clause) => ({
      ...clause,
      isSelected: !allSelected,
    }));
    setDO(updateDO);
  };

  return (
    <Box sx={{ maxHeight: "calc(100vh - 250px)", px: 2, py: 0 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          DO Review Mapping ({selectedDeviationClauses})
        </Typography>
        <IconButton onClick={handleClick}>
          <CustomSvg name={"more-vertical"} color={`${theme.palette.text.secondary}`} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem onClick={handleClose} sx={{ display: "flex", gap: "10px" }}>
            <CustomSvg color={`${theme.palette.text.secondary}`} name={"plus"} />
            Map DO
          </MenuItem>
        </Menu>
      </Box>
      <Stack direction={"column"} spacing={2}>
        <Box>
          {DO.map((data, sectionIndex) => {
            const allSelected = data.DOClauses.every((clause) => clause.isSelected);
            return (
              <Box key={data.DOHeading} sx={{ mb: 3 }}>
                <Stack direction={"column"} spacing={2}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
                    <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>{data.DOHeading}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 1 }}>
                      <Typography
                        sx={{ textAlign: "left", textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => handleSelectDeselectToggle(sectionIndex)}
                      >
                        {allSelected ? "Unselect All" : "Select All"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    {data.DOClauses.map((clause, clauseIndex) => (
                      <Box
                        key={clause.title}
                        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                      >
                        <Typography>{clause.title}</Typography>
                        <CustomCheckBox
                          isStyle={true}
                          alignItems="center"
                          label=""
                          onChange={() => handleToggleClause(sectionIndex, clauseIndex)}
                          value={clause.isSelected}
                        />
                      </Box>
                    ))}
                  </Box>
                </Stack>
              </Box>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
};

export default Deviation;
