import React, { useEffect, useState } from "react";
import { Grid, Box, Stack } from "@mui/material";
import DynamicBasicTable from "../../common/dynamicbasicTable/DynamicBasicTable";
import { textFieldWidth } from "../../utils/constants";
import CustomMultilineTextField from "../../common/dynamicInputs/CustomMultilineTextField";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { updateApproveorReject } from "../../store/slices/pendingApprovals";
import ProgressBar from "../../common/progressBar/progressBar";
import { DownLoadAFile } from "../../store/slices/fileHandler";
import RemarkSectionApproval from "./RemarkSection";
import { updateLoader } from "../../store/slices/loader";
interface basicTableProps {
  selectedGridRecord: any;
  selectedRecordDetails: any;
  setSelectedRecordDetails?: any;
  closePopUp: any;
  view?: boolean;
  edit?: boolean;
}

const ApproveRejectPendingRecord: React.FC<basicTableProps> = ({
  selectedGridRecord,
  selectedRecordDetails,
  setSelectedRecordDetails,
  closePopUp,
  view,
  edit,
}) => {
  const dispatch = useAppDispatchThunk();
  const { makerCheckerStatus, isApprovedorReject, isApprovedorRejectFailed } = useSelector(
    (state: RootState) => state.pendingApproval
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [updatedselectedRecordDetails, setupdatedselectedRecordDetails] = useState<any>("");
  const isBase64Image = (image: any) => {
    return (
      image &&
      (image.startsWith("data:image/jpeg;base64,") ||
        image.startsWith("data:image/png;base64,") ||
        image.startsWith("data:image/jpg;base64,"))
    );
  };
  const handleImageDisplay = async (imageUrl: string): Promise<string | null> => {
    if (imageUrl && !isBase64Image(imageUrl)) {
      const resultAction = await dispatch(DownLoadAFile({ fileUrl: imageUrl }));
      if (DownLoadAFile.fulfilled.match(resultAction)) {
        const recordLockData = resultAction.payload;
        if (recordLockData && recordLockData.length > 0) {
          const blob = "data:image/jpeg;base64," + recordLockData;
          return blob;
        }
      }
    } else if (isBase64Image(imageUrl)) {
      return imageUrl;
    }
    return null;
  };

  useEffect(() => {
    const processImageUrls = async (obj: any) => {
      const promises = Object.entries(obj).map(async ([key, value]) => {
        if (typeof value === "string" && key.toLowerCase().includes("logo")) {
          const imageUrl = await handleImageDisplay(value);
          obj[key] = imageUrl; // Replace the URL with the base64 data or placeholder
        }
      });
      await Promise.all(promises);
    };

    const loadImages = async () => {
      if (selectedRecordDetails) {
        // Create deep copies of OldValues and NewValues to compare later
        const oldValuesCopy = { ...selectedRecordDetails.OldValues };
        const newValuesCopy = { ...selectedRecordDetails.NewValues };

        // Process OldValues
        if (selectedRecordDetails.OldValues) {
          await processImageUrls(oldValuesCopy);
        }

        // Process NewValues
        if (selectedRecordDetails.NewValues) {
          await processImageUrls(newValuesCopy);
        }

        // Compare the processed data to the current state
        const isDifferent =
          JSON.stringify(oldValuesCopy) !== JSON.stringify(selectedRecordDetails.OldValues) ||
          JSON.stringify(newValuesCopy) !== JSON.stringify(selectedRecordDetails.NewValues);

        // Only update state if there are differences
        if (isDifferent) {
          setSelectedRecordDetails({
            ...selectedRecordDetails,
            OldValues: oldValuesCopy,
            NewValues: newValuesCopy,
          });
        }
      }
    };

    loadImages();
  }, [selectedRecordDetails]);

  useEffect(() => {
    if (isApprovedorReject) {
      setLoader(false);
      closePopUp && closePopUp();
      dispatch(updateLoader(false));
    } else if (isApprovedorRejectFailed) {
      setLoader(false);
      dispatch(updateLoader(false));
    }
  }, [isApprovedorReject, isApprovedorRejectFailed]);

  const isJsonString = (str: any) => {
    if (typeof str !== "string") {
      return false;
    }
    try {
      const parsed = JSON.parse(str);
      return typeof parsed === "object" && parsed !== null;
    } catch (e) {
      return false;
    }
  };

  const parseAndFormatJSON = (jsonStr: any) => {
    try {
      const parsedArray = JSON.parse(jsonStr);
      if (Array.isArray(parsedArray)) {
        return (
          <table style={{ border: "1px solid #ccc", borderCollapse: "collapse", textAlign: "center" }}>
            <thead>
              <tr>
                {Object.keys(parsedArray[0]).map((key) => (
                  <th key={key} style={{ border: "1px solid #ccc" }}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedArray.map((item, index) => (
                <tr key={index}>
                  {Object.values(item).map((value: any, idx) => (
                    <td key={idx} style={{ border: "1px solid #ccc" }}>
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      return "Invalid JSON Array";
    } catch (e) {
      return "Invalid JSON";
    }
  };

  const convertToString = (value: any) => {
    if (value === null || value === undefined) return "";
    if (typeof value === "boolean") return value.toString();
    if (typeof value === "object") return JSON.stringify(value);
    return value.toString();
  };

  const prepareRowData = (selectedRecordDetails: any) => {
    if (!selectedRecordDetails) return [];

    const oldValues = selectedRecordDetails?.OldValues || {};
    const newValues = selectedRecordDetails?.NewValues || {};

    const fields = new Set([...Object.keys(oldValues), ...Object.keys(newValues)]);

    return Array.from(fields).map((field) => {
      let oldValue = oldValues[field];
      let newValue = newValues[field];

      if (isJsonString(oldValue)) {
        oldValue = parseAndFormatJSON(oldValue);
      } else {
        oldValue = convertToString(oldValue);
      }

      if (isJsonString(newValue)) {
        newValue = parseAndFormatJSON(newValue);
      } else {
        newValue = convertToString(newValue);
      }

      return {
        Field: field,
        "Old Value": oldValue || "",
        "New Value": newValue || "",
      };
    });
  };

  const rowData = prepareRowData(selectedRecordDetails);
  console.log(edit, view, "rowdata");
  return (
    <>
    <Stack spacing={3}>
      <Box>
                <DynamicBasicTable
                  style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}
                  columns={
                    selectedRecordDetails && Object.keys(selectedRecordDetails?.OldValues).length > 0
                      ? ["Field", "Old Value", "New Value"]
                      : ["Field", "New Value"]
                  }
                  rowData={rowData}
                />
                  </Box>
                  <Box>
            <RemarkSectionApproval selectedGridRecord={selectedGridRecord} closePopUp={closePopUp} view={view} />
            </Box>
          </Stack>
      </>
  );
};

export default ApproveRejectPendingRecord;
