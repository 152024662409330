import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
// import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
// import ProgressBar from "../../../common/progressBar/progressBar";
import { getLovList } from "../../../store/slices/lovMasterData";
import { ToasterActivityConfig } from "../../../config/config";
import { addEditRiskRate, getRiskRateList, updateRiskRateAddandEdit } from "../../../store/slices/riskRateMasterData";
import ProgressBar from "../../../common/progressBar/progressBar";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
// import { addEditLocation,getLocationList,getParentLocationList,updateLocationAddandEdit} from "../../../store/slices/locationMasterData";

interface FormData {
  RiskRatingID?: number;
  RiskCategory?: string;
  Weightage?: string;
  IsActive: string;
}

export interface RiskRatingProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
}

export const AddRiskRatingMaster: React.FC<RiskRatingProps> = ({ view, closePopUp, edit, isEditData }) => {
  const dispatch = useAppDispatchThunk();

  //   const [ParentLocationData, setParentLocationData] = useState<any[]>([]);
  const [IsCancel, setIsCancel] = useState<boolean>(false);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { RiskRateData,
    isLoading,
    isRiskRateAdded,

    ToasterMsg
  } = useSelector(
    (state: RootState) => state.RiskRateData
  );

  console.log(isEditData, "isEditData");
  let formData: any = {
    RiskCategory_Id: isEditData ? isEditData?.RiskCategory_Id : 0,
    RiskCategory: isEditData ? isEditData?.RiskCategory : "",
    Weightage: isEditData ? isEditData?.Weightage : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  const {
    handleSubmit: handleRiskRatingForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const isActiveDisabled = watch("IsActive");

  const handleSubmit = (data: any) => {

    console.log(getValues());
    dispatch(updateLoader(true));

    let formData = data;

    let JsonDataForDisplay: any = {
      "Risk Category": formData.RiskCategory,
      "Weightage": formData.Weightage,
      "Status": formData.IsActive ? "Active" : "InActive",
      "Modified By": user?.UserName,
    }

    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 24;
    formData.ModuleName = currentPagePermissionData?.MenuName;
    formData.CreatedBy = user?.UserName;
    formData.Updated_By = user?.UserName;
    formData.Updated_On = new Date().toISOString();
    // formData.RiskCategory = formData.RiskCategory ? formData.RiskCategory : "";
    // formData.Weightage = formData.Weightage ? formData.Weightage : "";
    if (edit) {
      formData.UserId = userLoginResponse?.UserId;
      formData.IsActive = formData.IsActive ? true : false;
      formData.Activity = ToasterActivityConfig.Edit;
      console.log(formData, " edit formData");
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(
        addEditRiskRate((formData))
      );
    } else {
      formData.Activity = ToasterActivityConfig.create;
      formData.IsActive = true;
      formData.UserId = userLoginResponse?.UserId;
      console.log(formData, "formData");
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(
        addEditRiskRate((formData))
      );
    }
  };

  useEffect(() => {
    if (isRiskRateAdded) {

      dispatch(
        getRiskRateList({
          FormID: 2,
        })
      );
      dispatch(updateRiskRateAddandEdit(false));
      dispatch(updateLoader(false));

      if (closePopUp) {
        reset(formData);
        closePopUp();
        // setLoader(false);
      }
    }
  }, [isRiskRateAdded,]);

  const handleCancelPopup = () => {

    if (isDirty) {
      setIsCancel(true);
    } else {
      closePopUp && closePopUp();
    }
  }

  return (
    <>
      <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
        <Grid
          sx={{
            backgroundImage: `url()`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "0",
          }}
        >
          <Grid
            sx={{
              height: "auto",
              width: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                px: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
              <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                  //   sm={textFieldWidth.sm}
                  //   md={textFieldWidth.md}
                  //   lg={textFieldWidth.lg}
                  //   xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="RiskCategory"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Risk Category is Required",
                        validate: {
                          noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                          minLength: (value) => (value ?? "").length >= 1 || "Minimum 1 character required",
                          valueLimitation: (value) => /^[a-zA-Z,. -]+$/.test(value ?? "") ||
                            "Risk Category cannot contain special characters and numbers",
                        }
                      }}
                      render={({ field }) => (
                        <CustomTextField
                          name={"RiskCategory"}
                          required={true}
                          disabled={view || !isActiveDisabled}
                          label={"Risk Category"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.RiskCategory)}
                          helperText={errorsloginform.RiskCategory && errorsloginform.RiskCategory.message?.toString()}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  //   sm={textFieldWidth.sm}
                  //   md={textFieldWidth.md}
                  //   lg={textFieldWidth.lg}
                  //   xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Weightage"
                      control={control}
                      defaultValue={""}
                      rules={{
                        required: "Weightage is Required",
                        validate: {
                          noNegative: (value) => parseFloat(value ?? "") >= 0 || "Negative values are not allowed",
                          inRange: (value) => (parseFloat(value ?? "") >= 0 && parseFloat(value ?? "") <= 99) || "Weightage must be between 0 and 99"
                        },
                      }}
                      render={({ field }) => (
                        <CustomNumberField
                          name={"Weightage"}
                          required={true}
                          isDecimal={true}
                          disabled={view || !isActiveDisabled}
                          label={"Weightage"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.Weightage)}
                          helperText={errorsloginform.Weightage && errorsloginform.Weightage.message?.toString()}
                        />
                      )}
                    />
                  </Grid>


                  {/*{edit || view ? (
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsActive"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"User Status"}
                            dynamicLabel={field.value ? "ACTIVE" : "INACTIVE"}
                            disabled={view}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                ) : null} */}
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "right",
                    mt: 2,
                  }}
                >
                  {view ? (
                    <CustomButton
                      type="reset"
                      name={"Cancel"}
                      onClick={() => {
                        handleCancelPopup();
                      }}
                    />
                  ) : edit ? (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton type="reset" disabled={!isDirty} name={"Update"} onClick={handleRiskRatingForm(handleSubmit)} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          reset(formData);
                          handleCancelPopup();
                        }}
                      />
                      <CustomButton type="reset" disabled={!isDirty} name={"Submit"} onClick={handleRiskRatingForm(handleSubmit)} />
                    </React.Fragment>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <ModalPopUp
        onDeleteMethod={() => closePopUp && closePopUp()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </>
  );
};
