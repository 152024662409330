import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CustomCheckBox from "../../common/dynamicInputs/CustomCheckBox";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { getMakerCheckerList, updateMakerChecker, updateMakerCheckerList } from "../../store/slices/makerChecker";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { IMakerChecker } from "../../models/makerChecker";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";

const SamMakerChecker: React.FC = () => {
  const [checked, setChecked] = React.useState<IMakerChecker[]>([]);
  const [left, setLeft] = React.useState<IMakerChecker[]>([]);
  const [right, setRight] = React.useState<IMakerChecker[]>([]);
  const [updatedMakerChecker, setUpdatedMakerChecker] = React.useState<boolean>(false);
  const dispatch = useAppDispatchThunk();
  const not = (a: IMakerChecker[], b: IMakerChecker[]) => {
    return a.filter((value) => b.filter(data=> data.MasterName ===value.MasterName).length === 0);
  };

  const intersection = (a: IMakerChecker[], b: IMakerChecker[]) => {
    return a.filter((value) => b.filter(data=> data.MasterName ===value.MasterName).length !== 0);
  };

  const { makerCheckers,updated } = useSelector(
    (state: RootState) => state.makerChecker
  );

  useEffect(()=>{
    dispatch(getMakerCheckerList({category: "users"}))
  },[])

  useEffect(()=>{
      if(makerCheckers.length>0){
        let copyOfMakerCheckrs:IMakerChecker[]= JSON.parse(JSON.stringify(makerCheckers));
        let splitRight = copyOfMakerCheckrs.filter((rightItem:IMakerChecker) =>rightItem.IsMakerChecker );
        let splitleft = copyOfMakerCheckrs.filter((leftItem:IMakerChecker)=>!leftItem.IsMakerChecker );
        setLeft(splitleft)
        setRight(splitRight)
      }
  },[makerCheckers])

  useEffect(()=>{
    if(updated){
      setUpdatedMakerChecker(true)
    }
},[updated])

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: IMakerChecker) => () => {
    const currentIndex = checked.findIndex(item=> item.MasterName === value.MasterName);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    let copyLeft= left.map(leftItem=>{
      leftItem.IsMakerChecker =true 
      return leftItem
    } )
    setRight([...right, ...copyLeft]);
    setLeft([]);
  };

  const handleCheckedRight = () => {
    let copyCheckedLeft= [...leftChecked];

    copyCheckedLeft= copyCheckedLeft.map(leftItem=>{
      leftItem.IsMakerChecker =true 
      return leftItem
    } )
    setRight([...right, ...copyCheckedLeft]);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    let copyCheckedright= [...rightChecked];
    copyCheckedright= copyCheckedright.map(rigthItem=>{
      rigthItem.IsMakerChecker =false; 
      return rigthItem;
    } )
    setLeft([...left, ...copyCheckedright]);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {   
    let copyCheckedright= right.map(rigthItem=>{
      rigthItem.IsMakerChecker =false 
      return rigthItem
    } )
    setLeft([...left, ...copyCheckedright]);
    setRight([]);
  };

  const customList = (items: IMakerChecker[], title: string) => (
    <Paper sx={{ width: 250, height: 230, overflow: "auto" }}>
      {/* <Typography variant="subtitle1">  {title} </Typography> */}
      <List dense component="div" role="list">
        {items.map((value: IMakerChecker) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItemButton
              key={value.MasterName}
              role="listitem"
              onClick={handleToggle(value)}
              sx={{
                justifyContent: "flex-start",
                wordBreak: "break-word",
                pl: {
                  xs: 4                  
                }
              }}
            >
              <CustomCheckBox value={checked.filter(item=> item.MasterName === value.MasterName).length>0}  Width={"10%"}/>
              <Typography variant="subtitle2"> {value.MasterName}</Typography>
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        backgroundImage: `url()`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) => (t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5"),
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        sx={{
          m: 6,
          p: 6,
          position: "relative",
          height: "80%",
          width: "100%",
          borderRadius: "10px",
        }}
        item
        xs={12}
        component={Paper}
        elevation={6}
        square
      >
        <Grid container sx={{ height: "100%" }} spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList(left,  "Available Maker Checkers")}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(right, "Selected Maker Checkers")}</Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "right",
            mt: 3,
          }}
        >
          <CustomButton
            type="reset"
            name={"Submit"}
            onClick={() => {
              dispatch(updateMakerCheckerList([...right, ...left]))
            }}
          />
        </Box>

        <ModalPopUp
          header="Maker Checker"
          text="Sam Maker Checker Updated SuccessFully"
          onHide={() => {
            setUpdatedMakerChecker(false);
            dispatch(updateMakerChecker(false));
          }}
          show={updatedMakerChecker}
        />
      </Grid>
    </Grid>
  );
};

export default SamMakerChecker;
