import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CustomStepper from "../../../dynamicSteper/CustomStepper";
import CustomButton from "../../../dynamicButton/CustomButton";
import VendorOnboardingDeviation from "../VendorOnboardingDeviation/VendorOnboardingDeviation";
import SMEReviewMapping from "../SMEReviewMapping/SMEReviewMapping";
import TaggingRisk from "../TaggingRisk/TaggingRisk";
import TaggingSLAPM from "../TaggingSLAPM/TaggingSLAPM";
import SMEReview from "../SMEReview/SMEReview";
import headerstyle from "../../../../components/header/header.module.scss";
import headerlogo from "../../../../assets/headerlogo.png";
import DOReview from "../DOReview/DOReview";
import SOReview from "../SOReview/SOReview";
import SMESignoff from "../SME Signoff/SMESignoff";
import { PLUGIN_STEPPER } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../../store/store";
import { setPluginUserRole } from "../../../../store/contractManagement/slices/pluginUserRole";
import { getClauseDetailsList } from "../../../../store/contractManagement/slices/clauseDataSlice";
import { getTemplateById, getTemplateList } from "../../../../store/contractManagement/slices/templateData";
import clauseService from "../../../../store/contractManagement/services/clauseService";
import { updateLoader } from "../../../../store/slices/loader";

interface IPluginStepper {}

const PluginStepper: React.FC<IPluginStepper> = () => {
  const steps: string[] = [
    "Vendor Onboarding Deviation",
    "SME Review Mapping",
    "Tagging Risk",
    // "Tagging SLA/PM"
  ];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [FormData, setFormData] = useState<any>();
  const { isLoading, templateList, isTemplateDeleted, templateToasterStatus, ToasterMsg } = useSelector(
    (state: any) => state.contractManagementReducer.templateData
  );
  const { editorDocumentID } = useSelector((state: any) => state.contractManagementReducer.editorDoc);
  const [isEditData, setIsEditDate] = useState<any>(null);

  const { pluginRole } = useSelector((state: any) => state.contractManagementReducer?.pluginLoginUserRole);
  const { pluginData } = useSelector((state: any) => state.contractManagementReducer?.pluginData);
  const dispatch = useAppDispatchThunk();
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    clauseDetailsData,
    isClauseDetailsLoading,
    isClauseDetailsAdded,
    clauseToasterStatus,
    isClauseDetailsUpdated,
    isClauseDetailsDeleted,
    clauseToasterMsg,
  }: any = contractManagementReducer.clauseData;

  const methods = useForm({
    defaultValues: {
      vendorDeviation: {},
      smeMapping: {},
      taggingRisk: {},
      taggingSlaPm: {},
    },
  });
  const initializeData = () => {
    dispatch(getClauseDetailsList());
    dispatch(getTemplateList());
  };
  useEffect(() => {
    initializeData();
  }, []);

  console.log(window, "window~plugin~Data");

  const { handleSubmit } = methods;

  const SMEData = [
    {
      SMEHeading: "Finance",
      SMEClauses: [
        { title: "Payment", isSelected: false },
        { title: "Billing", isSelected: true },
        { title: "Taxation", isSelected: true },
      ],
    },
    {
      SMEHeading: "Operational",
      SMEClauses: [
        { title: "Leave License", isSelected: false },
        { title: "Scope of Agreement", isSelected: false },
        { title: "Clause 3", isSelected: false },
      ],
    },
    {
      SMEHeading: "Legal",
      SMEClauses: [
        { title: "Clause 1", isSelected: false },
        { title: "Clause 2", isSelected: false },
        { title: "Clause 3", isSelected: false },
      ],
    },
  ];

  const TRData = [
    {
      TRHeading: "Finance",
      TRClauses: [
        { title: "Payment", riskLevel: "low" },
        { title: "Billing", riskLevel: "low" },
        { title: "Taxation", riskLevel: "high" },
      ],
    },
    {
      TRHeading: "Operational",
      TRClauses: [
        { title: "Leave License", riskLevel: "low" },
        { title: "Scope of Agreement", riskLevel: "low" },
        { title: "Clause 3", riskLevel: "low" },
      ],
    },
    {
      TRHeading: "Legal",
      TRClauses: [
        { title: "Clause 1", riskLevel: "low" },
        { title: "Clause 2", riskLevel: "low" },
        { title: "Clause 3", riskLevel: "low" },
      ],
    },
  ];

  const TagSLAPMData = [
    {
      TagSLAPMHeading: "Section 1",
      TagSLAPMClauses: [
        { title: "Clause 1", isSLA: true, isPM: false },
        { title: "Clause 2", isSLA: false, isPM: false },
        { title: "Clause 3", isSLA: true, isPM: true },
      ],
    },
    {
      TagSLAPMHeading: "Section 2",
      TagSLAPMClauses: [
        { title: "Clause A", isSLA: false, isPM: true },
        { title: "Clause B", isSLA: true, isPM: false },
      ],
    },
  ];
  const FinalStep = [
    { id: 1, header: "Clause Header 1" },
    { id: 2, header: "Clause Header 2" },
    { id: 3, header: "Clause Header 3" },
    { id: 4, header: "Clause Header 4" },
    { id: 5, header: "Clause Header 5" },
    { id: 6, header: "Clause Header 6" },
    { id: 7, header: "Clause Header 7" },
    { id: 8, header: "Clause Header 8" },
    { id: 9, header: "Clause Header 9" },
    { id: 10, header: "Clause Header 10" },
    { id: 11, header: "Clause Header 11" },
    { id: 12, header: "Clause Header 12" },
    { id: 13, header: "Clause Header 13" },
    { id: 14, header: "Clause Header 14" },
    { id: 15, header: "Clause Header 15" },
    { id: 16, header: "Clause Header 16" },
    { id: 17, header: "Clause Header 17" },
    { id: 18, header: "Clause Header 18" },
    { id: 19, header: "Clause Header 19" },
    { id: 20, header: "Clause Header 20" },
  ];
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <VendorOnboardingDeviation />;
      case 1:
        return <SMEReviewMapping SMEData={smePluginData} />;
      case 2:
        return <TaggingRisk TRData={trPluginData} />;
      // case 3:
      //   return <TaggingSLAPM TagSLAPMData={TagSLAPMData} />;
      default:
        return "Unknown step";
    }
  };
  const transformToTrData = (data: any) => {
    const trData: any = {};

    data.forEach((clause: any) => {
      const riskCategories: any = clause.riskCategory.split(","); // Split by comma to handle multiple categories

      if (clause.isRisk === "Yes") {
        riskCategories.forEach((category: any) => {
          const trimmedCategory = category.trim(); // Trim whitespace

          if (!trData[trimmedCategory]) {
            trData[trimmedCategory] = {
              TRHeading: trimmedCategory,
              TRClauses: [],
            };
          }

          trData[trimmedCategory].TRClauses.push({
            title: clause.clause_header,
            riskLevel: clause.riskLevel,
          });
        });
      }
    });

    return Object.values(trData);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onSubmit = (data: any) => {
    console.log("Form Data:", data);
    setFormData(FinalStep);
    handleSelectChange(PLUGIN_STEPPER.SME_REVIEW);
    setIsCompleted(true);
  };
  const [component, setComponent] = useState(pluginRole ?? "");

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement> | string) => {
    let role = typeof event === "string" ? event : event.target.value;
    setComponent(role);
    dispatch(setPluginUserRole(role));
  };
  const [smePluginData, setSmePluginData] = useState<any>();
  const [trPluginData, setTRPluginData] = useState<any>();
  console.log(trPluginData, "component");
  const transformToSmeData = (data: any) => {
    const smeData: any = {};

    data.forEach((clause: any) => {
      const sme = clause.sme;
      if (sme) {
        if (!smeData[sme]) {
          smeData[sme] = {
            SMEHeading: sme,
            SMEClauses: [],
          };
        }

        smeData[sme].SMEClauses.push({
          title: clause.clause_header,
          isSelected: clause.isSme === "Yes",
        });
      }
    });

    return Object.values(smeData);
  };

  const [filteredClauseData, setFilteredClauseData] = useState<any[]>([]);

  const handleLoadContract = async (services: number[], filterClause: number[] = []) => {
    try {
      dispatch(updateLoader(true));
      const data = await clauseService.getClauseByService(services);
      let clauseList = data.clauseList;

      if (filterClause.length) {
        clauseList = clauseList.filter((clause: any) => filterClause.includes(clause.clause_Id));
      }

      const orderedClauses = clauseList.map((clause: any, index: number) => ({ ...clause, order: index + 1 }));
      setFilteredClauseData(orderedClauses);

      return orderedClauses;
    } catch (error) {
      console.error("Error loading contract:", error);
    } finally {
      dispatch(updateLoader(false));
    }
  };

  useEffect(() => {
    const clauseDatas = filteredClauseData?.map((data: any) => data.clause_Id) || [];
    const serviceCategories = isEditData?.ctServiceMappings.map((service: any) => service.serviceCatalog_Id) || [];

    if (serviceCategories.length > 0) {
      handleLoadContract(serviceCategories, clauseDatas).then((data) => {
        console.log(data, "dataContent");
      });
    }
  }, [isEditData, filteredClauseData]);

  console.log(filteredClauseData, "dataLoaddataLoad");

  useEffect(() => {
    if (clauseDetailsData && clauseDetailsData?.length > 0) {
      setSmePluginData(transformToSmeData(clauseDetailsData));
    }
    if (clauseDetailsData && clauseDetailsData?.length > 0) {
      setTRPluginData(transformToTrData(clauseDetailsData));
    }
  }, [clauseDetailsData]);
  useEffect(() => {
    dispatch(getTemplateById("3"))
      .unwrap()
      .then((templateData: any) => {
        console.log(templateData, "TemplateData");
        setIsEditDate({ ...templateData });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(editorDocumentID, "editorDocumentIDeditorDocumentID");
  return (
    <Box id={"plugin-stepper"}>
      <Box
        className={headerstyle.sliding_border}
        sx={{ display: "flex", height: "45px important", width: "150px !important" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={headerlogo} alt="Logo" style={{ width: "70px", height: "27px", objectFit: "contain" }} />
        </Box>
      </Box>
      {/* <label htmlFor="select">Select:</label>
      <select onChange={handleSelectChange} id="select" name="">
        <option value="">--Select--</option>
        <option value="smereview">SME Review</option>
        <option value="smeSignOff">SME Sign off</option>
        <option value="so">SO</option>
        <option value="do">DO</option>
      </select> */}
      <FormProvider {...methods}>
        <Box sx={{ position: "relative" }}>
          {component === PLUGIN_STEPPER.SME && (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 18,
                      left: 0,
                      zIndex: 1000,
                      background: "#fff",
                      width: "100%",
                    }}
                  >
                    <CustomStepper activeStep={activeStep} steps={steps} />
                  </Box>
                  <Stack spacing={2}>
                    <Box>
                      {activeStep === steps.length ? (
                        <Box>
                          <Typography>All steps completed - you're finished</Typography>
                          <Button onClick={handleReset}>Reset</Button>
                        </Box>
                      ) : (
                        <Box>
                          <Stack spacing={2}>
                            <Box sx={{ position: "relative", top: 120, overflow: "auto", maxHeight: "185px", mb: 2 }}>
                              {getStepContent(activeStep)}
                            </Box>
                          </Stack>
                          <Box
                            sx={{
                              position: "fixed",
                              bottom: 0,
                              left: 0,
                              // zIndex: 1000,
                              background: "#fff",
                              borderTop: "2px solid #f1f1f1",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: activeStep > 0 ? "space-between" : "flex-end",
                                gap: 1,
                                p: 1,
                              }}
                            >
                              {activeStep > 0 && (
                                <CustomButton
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  variant="outlined"
                                  name={"Back"}
                                  size="small"
                                />
                              )}
                              {activeStep === steps.length - 1 ? (
                                <CustomButton
                                  variant="contained"
                                  name="Completed"
                                  size="small"
                                  onClick={handleSubmit(onSubmit)}
                                />
                              ) : (
                                <CustomButton variant="outlined" name="Next" onClick={handleNext} size="small" />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Stack>
                </Box>
              </form>
            </FormProvider>
          )}

          {component === PLUGIN_STEPPER.SME_REVIEW && (
            <SMEReview formData={FormData} handleSelectChange={handleSelectChange} />
          )}
          {component === PLUGIN_STEPPER.DO && <DOReview formData={FormData} handleSelectChange={handleSelectChange} />}
          {component === PLUGIN_STEPPER.SO && <SOReview formData={FinalStep} handleSelectChange={handleSelectChange} />}
          {component === PLUGIN_STEPPER.SME_SIGN_OFF && <SMESignoff formData={FormData} />}
        </Box>
      </FormProvider>
    </Box>
  );
};

export default PluginStepper;
