import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import { CircularProgress, IconButton, InputAdornment, Toolbar } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { updateVendorRecords } from "../../store/slices/vendorData";
import { updateLoader } from "../../store/slices/loader";
import { ILogginForm } from "../../models/loggin.type";
import { getLogginUser, getMenuByRolesId, updateAuthorize, updateLogin } from "../../store/slices/authorization";
import { useSelector } from "react-redux";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import loginstyle from "./login.module.scss";
import headerlogo from "../../assets/headerlogo.png";
import Loginbg from "../../assets/Loginbg.png";
import { STATUS_CODE_HEADER, STATUS_CODE_TEXT } from "./login.constants";
import { getDepartmentList } from "../../store/slices/departmentData";
import { bizAdminDepartmentName, bizAdminDepartmentId } from "../../utils/constants";
import { getFavouriteNavMenu } from "../../store/slices/FavouriteNavData";
import { hideMenu } from "../../store/slices/MenuShow";
import { AnyARecord } from "dns";
export default function Login() {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showPop, setShowPop] = useState(false);
  const [forgotPasswordPopUp, setForGotPasswordPopUp] = useState(false);

  const [showNoMenuInfo, setShowNoMeniNfo] = useState(false);
  const [userDetailsInfo, setUserDetailsInfo] = useState(false);
  const [showAccountInfo, toggleAccountInfo] = useState<boolean>(false);
  const { isAuthenticated, userLoginResponse, isLoginFailed, menuData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const [getDepartmentDetails, setDepartmentDetails] = useState<any>();
  const [isBizAdmin, setIsBizAdmin] = useState<boolean>(false);
  //const token = localStorage.getItem("AUTH_TOKEN_ZN") || "";
  const [previousURL, setPreviousURL] = useState<any>(null);

  const dispatch = useAppDispatch();

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  let logginForm = {
    email: "",
    password: "",
  };

  const {
    handleSubmit: handleSubmitloginform,
    reset,
    formState: { errors: errorsloginform },
    control,

    getValues,
  } = useForm<ILogginForm>({
    defaultValues: logginForm,
    mode: "onChange",
  });

  useEffect(() => {
    //resetloginform();
    const preUrl = localStorage.getItem("preUrl") ? localStorage.getItem("preUrl")?.toString() : null;
    setPreviousURL(preUrl);
    sessionStorage.clear();
    dispatch(updateVendorRecords([]));
    console.log(preUrl, "preUrl");
  }, []);

  // useEffect(() => {
  //   if (dataTable && dataTable.length > 0) {
  //     console.log(dataTable, "Datatable");
  //     const parameter = dataTable.filter(
  //       (n: any) => n.DepartmentId === bizAdminDepartmentId || n.DepartmentName === bizAdminDepartmentName
  //     );
  //     console.log(parameter, "parameter");
  //     if (parameter && parameter.length > 0) {
  //       console.log(userLoginResponse, "userLoginResponse");

  //       console.log(bizAdminDepartmentId, userLoginResponse?.Department, "parameterparameter");
  //      const departmentName= dataTable.find((_:any)=>{_.DepartmentId ===parameter?.DepartmentId &&_.IsActive==="IsActive" return _.DepartmentName})
  //       if (bizAdminDepartmentId === userLoginResponse?.Department ||bizAdminDepartmentName === departmentName) {
  //         setIsBizAdmin(true);
  //       }
  //     }
  //   }
  // }, [dataTable, userLoginResponse]);

  useEffect(() => {
    if (dataTable.length > 0) {
      const filteredData = dataTable.filter(
        (item: any) => item.DepartmentName === bizAdminDepartmentName && item.IsActive === "Active"
      );
      // console.log(filteredData,"departmentNamedepartmentName")
      if (filteredData.length > 0) {
        const departmentName = dataTable.find(
          (item: any) => item.DepartmentId === filteredData[0].DepartmentId && item.IsActive === "Active"
        )?.DepartmentName;
        // console.log(departmentName,"departmentNamedepartmentNamedepartmentNamedepartmentName")
        if (bizAdminDepartmentId === userLoginResponse?.Department || bizAdminDepartmentName === departmentName) {
          setIsBizAdmin(true);
        }
      }
    }
  }, [dataTable, userLoginResponse]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        userLoginResponse?.IsFirstLogin === true &&
        userLoginResponse?.UserEmail !== null &&
        userLoginResponse.StatusCode === 1
      ) {
        dispatch(updateLoader(false));
        navigate("/changepassword");
      } else if (userLoginResponse?.UserEmail === null) {
        dispatch(updateLoader(false));
        dispatch(updateAuthorize(false));
        setUserDetailsInfo(true);
      } else if (userLoginResponse?.StatusCode !== 1) {
        dispatch(updateLoader(false));
        dispatch(updateAuthorize(false));
        toggleAccountInfo(true);
      } else {
        dispatch(
          getMenuByRolesId({
            RoleId: userLoginResponse && userLoginResponse.RoleId,
            UserId: userLoginResponse && userLoginResponse.UserId,
          })
        );
        dispatch(
          getFavouriteNavMenu({
            UserId: userLoginResponse && userLoginResponse.UserId,
          })
        );
      }
      dispatch(
        getDepartmentList({
          FormID: 2,
        })
      );
    } else if (isLoginFailed) {
      dispatch(updateLoader(false));
      setShowPop(true);
    }
  }, [isAuthenticated, isLoginFailed]);

  const findMenuByMenuId = (menus: any, MenuId: any) => {
    if (isAuthenticated) {
      for (let menu of menus) {
        if (menu.Url && menu.MenuId == MenuId) {
          return menu;
        }
        if (menu.SubMenus && menu.SubMenus.length > 0) {
          const foundMenu: any = findMenuByMenuId(menu.SubMenus, MenuId);
          if (foundMenu) {
            return foundMenu;
          }
        }
      }
      return null;
    } else {
      return null;
    }
  };

  const findfirstMenu = (menus: any) => {
    if (isAuthenticated) {
      for (let menu of menus) {
        if (menu.Url) {
          return menu;
        }
        if (menu.SubMenus && menu.SubMenus.length > 0) {
          const foundMenu: any = findfirstMenu(menu.SubMenus);
          if (foundMenu) {
            return foundMenu;
          }
        }
      }
      return null;
    } else {
      return null;
    }
  };

  const NavigatethefirstMenu = () => {
    let findedfistMenu: any = findfirstMenu(menuData);
    if (findedfistMenu) {
      navigate(findedfistMenu.Url);
    } else if (isBizAdmin) {
      navigate("/workFlowExecution");
      dispatch(hideMenu());
    }
  };

  useEffect(() => {
    if (isAuthenticated && menuData && menuData.length > 0 && userLoginResponse) {
      if (isBizAdmin) {
        navigate("/workFlowExecution");
        dispatch(hideMenu());
      } 
      // else if (previousURL && typeof previousURL === "string" && previousURL.startsWith("/")) {
      //   navigate(previousURL);
      // }
       else if (userLoginResponse?.MenuId) {
        let findedMenu: any = findMenuByMenuId(menuData, userLoginResponse?.MenuId);
        if (findedMenu) {
          navigate(findedMenu.Url);
        } else {
          NavigatethefirstMenu();
        }
      } else if (menuData) {
        NavigatethefirstMenu();
      }

      dispatch(updateLoader(false));
    } else if (isAuthenticated && (menuData === null || menuData.length === 0)) {
      dispatch(updateLoader(false));
      // dispatch(logoutUser(false));
      // setShowNoMeniNfo(true);
      if (isBizAdmin) {
        navigate("/workFlowExecution");
        dispatch(hideMenu());
      } 
      // else navigate("/dashboard");
    }
  }, [menuData, isBizAdmin , isAuthenticated , userLoginResponse]);

  

  const handleSubmit = (event: any) => {
    //event.preventDefault();
    let formValues = getValues();
    let request: any = {
      Useremail: formValues.email,
      Password: formValues.password,
      ModuleName: "Authentication",
      Activity: "Login",
    };
    dispatch(getLogginUser(request));
    dispatch(updateLoader(true));
   
  };

  const goto_forgot_password = () => {
    setForGotPasswordPopUp(true);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh", width: "100%" }}>
      <CssBaseline />
      <Grid
        item
        //   xs={false}
        //   sm={4}
        //   md={7}
        sx={{
          backgroundImage: `url(${Loginbg})`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "black" : "#F5F5F5",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "75%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={loginstyle.sliding_border}
      >
        {/* <img
         
         src= {headerlogo}
         alt="Logo"
         style={{ width:  "200px" , height:'71px', objectFit:'contain' }}
       /> */}
        <Grid
          sx={{
            my: 8,
            position: "absolute",
            right: "-25vw",
            top: "10vh",
            zIndex: 1,
            mx: {
              xs: 2,
              sm: 4,
              md: 4,
              lg: 8,
              xl: 12,
            },
          }}
          item
          xs={12}
          sm={8}
          md={5}
          lg={4}
          xl={4}
          // component={Paper}
          // elevation={6}
          // square
        >
          <Box
            sx={{
              py: 8,
              px: {
                xs: 2,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            {/* <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="Logo" width="150px" />
            </Toolbar> */}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", width: "100%" }}>
              <Typography textAlign="left" component="h1" variant="h4">
                Sign in
              </Typography>
            </Box>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Controller
                name="email"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Email is Required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    // sx={{ boxShadow: "inset 0 0 50px rgba(0, 0, 0, 0.2)", }}
                    {...field}
                    margin="normal"
                    // required
                    fullWidth
                    id="email"
                    // label="Email"
                    label=""
                    placeholder="Email"
                    // InputProps={{
                    //   style: { border: "none" },
                    // }}
                    // InputLabelProps={{
                    //   style: { color: "#fff" },
                    // }}
                    name="email"
                    size="small"
                    //autoComplete="email"
                    //autoFocus
                    tabIndex={1}
                    error={Boolean(errorsloginform.email)}
                    helperText={errorsloginform.email && errorsloginform.email.message?.toString()}
                    InputProps={{
                      sx: {
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset",
                        "& input::placeholder": {
                          fontWeight: "bold",
                        },
                        "& input:-ms-input-placeholder": {
                          fontWeight: "bold",
                        },
                        "& input::-ms-input-placeholder": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Password is Required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    // required
                    fullWidth
                    size="small"
                    id="password"
                    label=""
                    //label="Password"
                    placeholder="Password"
                    //name="password"
                    //autoComplete="password"
                    //autoFocus
                    tabIndex={2}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      sx: {
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset",
                        "& input::placeholder": {
                          fontWeight: "bold",
                        },
                        "& input:-ms-input-placeholder": {
                          fontWeight: "bold",
                        },
                        "& input::-ms-input-placeholder": {
                          fontWeight: "bold",
                        },
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errorsloginform.password)}
                    helperText={errorsloginform.password && errorsloginform.password.message?.toString()}
                  />
                )}
              />

              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Grid item>
                  <FormControlLabel
                    sx={{
                      color: "text.secondary",
                    }}
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />
                </Grid> */}
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                  onClick={handleSubmitloginform(handleSubmit)}
                >
                  {loading ? <CircularProgress size={20} /> : "LOGIN"}
                </Button>

                <Typography
                  onClick={() => goto_forgot_password()}
                  sx={{
                    color: "#016CCE",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {"Forgot password ?"}
                </Typography>
              </Grid>

              {false && (
                <Grid container sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {"Don't have an account? "}
                    <Typography
                      variant="caption"
                      onClick={() => goto_forgot_password()}
                      sx={{
                        color: "primary.main",
                        cursor: "pointer",
                      }}
                    >
                      {" Sign Up"}
                    </Typography>
                  </Typography>

                  {/* <Grid item>
                <NavLink
              to="/register"
            
            >
                    {"Don't have an account? Sign Up"}
                  </NavLink>
                </Grid> */}
                </Grid>
              )}
            </Box>

            <CustomDialog
              show={forgotPasswordPopUp}
              onHide={() => {
                setForGotPasswordPopUp(false);
              }}
              maxWidth={"sm"}
              header={"Forgot Password"}
              contentNode={
                <ForgotPassword
                  onClose={() => {
                    setForGotPasswordPopUp(false);
                  }}
                />
              }
            />

            <ModalPopUp
              header="Login Failed"
              text="Please check your username and password"
              onHide={() => {
                dispatch(updateLogin(false));
                setShowPop(false);
              }}
              show={showPop}
            />

            <ModalPopUp
              header="Login Failed"
              text="No Menu Has Been configured to your Id ..Please contact admin"
              onHide={() => {
                setShowNoMeniNfo(false);
              }}
              show={showNoMenuInfo}
            />

            <ModalPopUp
              header="Login Failed"
              text="Invalid Username or Password. Please try again..."
              onHide={() => {
                setUserDetailsInfo(false);
              }}
              show={userDetailsInfo}
            />

            <ModalPopUp
              header={STATUS_CODE_HEADER[userLoginResponse?.StatusCode || 0]}
              text={STATUS_CODE_TEXT[userLoginResponse?.StatusCode || 0]}
              onHide={() => {
                toggleAccountInfo(false);
              }}
              show={showAccountInfo}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
