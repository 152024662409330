import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddCurrencyMaster } from "./AddCurrencyMaster";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  deleteCurrency,
  getCurrencyHistory,
  getCurrencyList,
  updateCurrencyAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/currencyData";
import { getLovList } from "../../../store/slices/lovMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG } from "../../../config/config";
import { ApiFieldsMapping } from "../../apiCatalogue/apiFieldsMapping/ApiFieldsMapping";
import { ApiTabs } from "../../apiCatalogue/ApiTabs";



export interface RegionalSettingsProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const CurrencyMaster: React.FC<RegionalSettingsProps> = ({ handlePrevious, handleNext }) => {
  const dispatch = useAppDispatchThunk();
  const { CurrencyData, isCurrencyUpdated, versionHistory, isCurrencyAdded, isCurrencyDeleted, ToasterMsg } =
    useSelector((state: RootState) => state.CurrencyData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [currencyFormData, setCurrencyFormData] = useState<any>([]);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);


  const columns_version: any = {
    table_name: "Currency History Records",
    isAction: false,
    columns: [],
  };

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  useEffect(() => {
    setCurrencyFormData(CurrencyData);
  }, [CurrencyData]);

  useEffect(() => {
    callMasterData();
  }, []);

  const callMasterData = () => {
    dispatch(getCurrencyList({ FormID: 0 }));
  };

  useEffect(() => {
    if (isCurrencyAdded) {
      dispatch(updateCurrencyAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      callMasterData();
    } else if (isCurrencyUpdated) {
      dispatch(updateCurrencyAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      callMasterData();
    } else if (isCurrencyDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      setTimeout(() => {
        dispatch(updateCurrencyAddandEdit(false));
      }, 1000);
      callMasterData();
    }
  }, [isCurrencyUpdated, isCurrencyAdded, isCurrencyDeleted]);

  const Currency = () => {
    // let formData = { ...isDeleteData };
    
    let formData: any = {};

    let JsonDataForDisplay: any = {
      "Currency Code" : isDeleteData.CurrencyCode,
      "Currency Name" : isDeleteData.CurrencyName,
      "Symbol" : isDeleteData.Symbol,
      "Country" : isDeleteData.Country_Name,
      // "Country" : ParentLocationTable.find((item: any) => item.Location_Id == isDeleteData?.Country_Id)?.Location_Name || '',
      "Status": isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    }
    formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
    console.log(isDeleteData,formData.DataForDisplay, "isDeleteData");


    formData.Is_Active = isDeleteData.IsActive === "Active" ? false : true;
    formData.Activity = isDeleteData.IsActive === "Active" ? "inactive" : "active";
    formData.UpdatedBy = user?.UserName;
    formData.CreatedBy = user?.UserName;
    formData.ModuleName = "Currency Master";

    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 13;
    formData.UserId = userLoginResponse?.UserId;
    formData.CurrencyId = isDeleteData.CurrencyId;
    formData.CurrencyName = isDeleteData.CurrencyName;

    dispatch(deleteCurrency(formData));
    setIsDeleteData(null);
  };

  const columns: any = {
    table_name: "Currency",
    isAction: true,
    columns: [
      {
        Field: "CurrencyCode",
        DisplayName: "Code",
        FieldType: "string",
      },
      {
        Field: "CurrencyName",
        DisplayName: "Name",
        FieldType: "string",
      },
      {
        Field: "Symbol",
        DisplayName: "Symbol",
        FieldType: "string",
      },
      {
        Field: "Country_Name",
        DisplayName: "Country",
        FieldType: "string",
      },

      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "UpdatedBy",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory.CurrencyVHeader.length > 0 && versionHistory.CurrencyVHeader.length > 0) {
      if (versionHistory.CurrencyVHeader && versionHistory.CurrencyVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.CurrencyVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(versionHistory, "versionHistory ");
      }
    }
  }, [versionHistory]);

  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permssionPage?.MenuId,
      ModuleName: "Location",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Location",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        dispatch(updateLoader(false));
        setIsEditing(true);
        console.log(recordLock, resultAction.payload, "recordLock");
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      return [
        {
          RecordlockId: recordLock[0].RecordlockId,
          RecordId: recordLock[0].RecordId,
          IsActive: false,
        },
      ];
    }
    return [];
  };
  useEffect(() => {
    if (!isEdit) {
      const data = recordLockUpdate();
      if (data.length > 0) {
        dispatch(UpdateRecordLock(data.pop()));
      }
    }
  }, [isEdit]);

  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {
      console.log(data, Option, "Option");

      setIsEditData(data);
      // setIsEdit(true);
      setOption(Option);
      dispatch(updateLoader(true));

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.CurrencyId, FormID: 13 });
      const Edit_data: any = response;

      const canEdit = await handleRecordLockCheck(data?.CurrencyId);
      if (Edit_data?.data === true) {
        if (canEdit) {
          const dispatchData = handleAddRecordLock(data?.CurrencyId);
          await dispatch(AddRecordLock(dispatchData));
          //setIsEditOpen(true);
          const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.CurrencyId)));
          if (GetRecordLock.fulfilled.match(resultAction)) {
            const recordLockData = resultAction.payload;
            console.log(recordLockData, resultAction, isEditing, "recordLockData");
            setGetRecordLock(recordLockData);
          }
          dispatch(updateLoader(false));
          setIsEdit(true);

        }
      } else {

        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }

      // setUserId(data.UserId);
    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      dispatch(
        getCurrencyHistory({
          CurrencyId: data.CurrencyId,
          formID: 13,
          // Approved_By: ,
        })
      );
      // setIsVersionHistory(true);
    } else if (Option === "delete") {
      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.CurrencyId, FormID: 13 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        setOption(Option);
        setIsDeleteData(data);
      } else {

        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }
    }
  };
  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(data, "dataLock");
      if (data.length > 0) {
        event.preventDefault();
        event.returnValue = "";
        try {
          await dispatch(UpdateRecordLock(data.pop())).unwrap();
        } catch (error) {
          console.error("Failed to update record lock:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentPagePermissionData?.MenuName}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={currencyFormData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Currency Master"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={"Add Currency"}
        contentNode={
          // <></>
          <AddCurrencyMaster
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={Option === "view" ? "View Currency" : "Update Currency"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddCurrencyMaster
                view={false}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddCurrencyMaster
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
              // <></>
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Currency Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.CurrencyRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showDelete={false}
              showhistory={false}
              showView={false}
              showEdit={false}
              showExports={true}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Sub Department Version History"
            />
          </>
        }
      />
      
      <ModalPopUp
        onDeleteMethod={() => Currency()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Currency`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />

      
  
    </>

    
  );
};
