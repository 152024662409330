export const VendorConsent_URL={
    AddVendorConsent: `/createUpdateMasterContent`,
    GetVendorConsent:`/getMasterContentById`,
    GetAllVendorConsent:`/getMasterContents`,
    UpdateVendorConsent:`/createUpdateMasterContent`,
    DeleteVendorConsent:`/deleteMasterContent`,
    VersionHistory:`/getMasterContentHistory`,
    GetVendorConsentForm: `/getMasterContentByPurposes`,


}