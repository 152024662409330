import { createSlice } from "@reduxjs/toolkit";

export interface PluginData {
  pluginData: {};
}

export const initialState: PluginData = {
  pluginData: {},
};

export const pluginDataSlice = createSlice({
  name: "PluginData",
  initialState,
  reducers: {
    setPluginData: (state, action) => {
      state.pluginData = action.payload;
    },
  },
});

export const { setPluginData } = pluginDataSlice.actions;
export default pluginDataSlice.reducer;
