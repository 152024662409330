import React from "react";
import { Button, ButtonProps, CircularProgress, Typography } from "@mui/material";

// below props are usable 

// name: string;
// onClick: () => void;
// variant?: 'outlined' | 'text' | 'contained';
// size?: 'small' | 'medium' | 'large';
// color?: 'primary' | 'secondary' | string;
// disabled?: boolean;
// startIcon?: React.ReactNode;
// endIcon?: React.ReactNode;
// disableElevation?: boolean;
// href?: string;
// target?: string;
// type?: 'button' | 'submit' | 'reset';
// fullWidth?: boolean;
// loading?: boolean;
// rounded?: boolean;
// className?: string;


interface Buttonfield  {
    name: string;
onClick: (e: any , value? : any ) => void;
variant?: 'outlined' | 'text' | 'contained';
size?: 'small' | 'medium' | 'large';
color?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error';
disabled?: boolean | undefined;
startIcon?: React.ReactNode;
endIcon?: React.ReactNode;
disableElevation?: boolean;
href?: string;
target?: string;
type?: 'button' | 'submit' | 'reset';
fullWidth?: boolean;
loading?: boolean;
rounded?: boolean;
className?: string;
valuePass? : any;
    
}

const CustomButton = (props: Buttonfield) => {

    const {
        name,
        onClick,
        variant = 'contained' ,
        size = 'small' ,
        color = 'primary'  ,
        disabled,
        startIcon,
        endIcon,
        disableElevation = true,
        href,
        target,
        type = 'button',
        fullWidth,
        loading,
        rounded,
        className,
        valuePass,
    } = props

const handleClick = (e :any) => {
    if(valuePass !== undefined){
        onClick(e , valuePass)
    }else{
        onClick(e)
    }
    
} 
    return (
        <Button  id={`button-id-${name}`}  onClick={handleClick} disableElevation={disableElevation} className={className} type={type} startIcon={startIcon} endIcon={endIcon}  disabled={disabled || loading} size={size}  fullWidth={fullWidth} color={color} variant={variant}   >
            {loading ? <CircularProgress size={24} color="inherit" /> : <Typography sx={{whiteSpace:"nowrap"}}>{name}</Typography>} 
        </Button>
    );
};

export default CustomButton;
