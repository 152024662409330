import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../store/store";
import { addVendor, updateVendor, updateAddandEdit } from "../store/slices/vendorData";
import { addEventLog, addEventLogRequestParam } from "../store/slices/eventLog";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  ADD_SUCCESS_MSG,
  ADD_UPDATING_MSG,
  EDIT_SUCCESS_MSG,
  EDIT_UPDATING_MSG,
  textFieldWidth,
} from "../utils/constants";
import CustomTextField from "../common/dynamicInputs/CustomTextField";
import CustomSelect from "../common/dynamicInputs/CustomSelect";
import CustomButton from "../common/dynamicButton/CustomButton";
// import { textFieldWidth } from '../mockData/styledata';
import CustomFormField from "../common/generateFormField/CustomFormField";
import { ACTIVITY } from "../constants/app.constants";
import { addDefaultValue, updateDefaultValue } from "../store/slices/defaultValues";
interface FormData {
  vendorId?: number;
  vendorName: string;
  vendorEmail: string;
  address: string;
  phoneNumber: string;
  paNumber: string;
  location: string;
  registeredDate?: Date | string;
  EndDate?: Date | string;
}

export interface vendorProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  // setIsEdit?:boolean;
  isEdited?: any;
  isAdded?: any;
  masterId?: string;
  FormId: number;
  defaultdata?: any;
  isDefault?: boolean;
  defaultId?: number;
}

export const Vendor: React.FC<vendorProps> = ({
  view,
  isEditData,
  edit,
  isEdited,
  isAdded,
  FormId,
  defaultdata = null,
  isDefault = false,
  defaultId,
}) => {
  const dispatch = useAppDispatchThunk();

  //const [datas, setDatas] = useState(0);
  const data = useSelector((state: any) => state.vendorData.dataTable);
  const { isVendorAdded, isVendorUpdated, isLoading } = useSelector((state: RootState) => state.vendorData);
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const { isDefaultValuedAddedd, isDefaultValuedUpdated } = useSelector((state: RootState) => state.defaultData);
  const [message, setMessage] = useState<boolean>(false);
  useEffect(() => {
    //setDatas(data.length)
    console.log("Data", isEditData);
    localStorage.setItem("VenderData", data);
    // console.log(editRef)
  }, [data]);

  let location_Data = [
    { id: "", lable: "Select your Location" },
    { id: "madurai", lable: "Madurai" },
    { id: "chennai", lable: "Chennai" },
    { id: "mumbai", lable: "Mumbai" },
  ];

  let formData: any = {
    vendorId: isEditData
      ? isEditData?.VendorId
      : isDefault && defaultdata
      ? defaultdata.vendorId
      : defaultdata
      ? defaultdata.vendorId
      : 0,
    vendorName: isEditData
      ? isEditData?.VendorName
      : isDefault && defaultdata
      ? defaultdata.vendorName
      : defaultdata
      ? defaultdata.vendorName
      : "",
    vendorEmail: isEditData
      ? isEditData?.Email
      : isDefault && defaultdata
      ? defaultdata.vendorEmail
      : defaultdata
      ? defaultdata.vendorEmail
      : "",
    address: isEditData
      ? isEditData?.Address
      : isDefault && defaultdata
      ? defaultdata.address
      : defaultdata
      ? defaultdata.address
      : "",
    phoneNumber: isEditData
      ? isEditData?.PhoneNo
      : isDefault && defaultdata
      ? defaultdata.phoneNumber
      : defaultdata
      ? defaultdata.phoneNumber
      : "",
    paNumber: isEditData
      ? isEditData?.Pan
      : isDefault && defaultdata
      ? defaultdata.paNumber
      : defaultdata
      ? defaultdata.paNumber
      : "",
    location: isEditData
      ? isEditData?.Location.toLowerCase()
      : isDefault && defaultdata
      ? defaultdata.location
      : defaultdata
      ? defaultdata.location
      : "",
    registeredDate: new Date(),
  };
  // extracting dynamic fields for edit - prepopulate
  if (isEditData) {
    const { VendorId, VendorName, Email, Address, PhoneNo, Pan, Location, RegisteredDate, ...dynamicEditData } =
      isEditData;
    for (const [key, objVal] of Object.entries(dynamicEditData)) {
      formData[key] = objVal;
    }
  }

  if (defaultdata !== null) {
    const {
      vendorId,
      vendorName,
      vendorEmail,
      address,
      phoneNumber,
      paNumber,
      location,
      registeredDate,
      ...dynamicEditData
    } = defaultdata;
    for (const [key, objVal] of Object.entries(dynamicEditData)) {
      formData[key] = objVal;
    }
  }
  const {
    handleSubmit: handleVendorForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  const handleSubmit = () => {
    console.log("vendorsubmit");
    if (isDefault) {
      if (defaultdata && defaultId) {
        let request = {
          DefaultId: defaultId,
          ModuleName: "Vendor Management",
          ScreenName: "Vendor Management",
          Activity : "default",
          DefaultData: JSON.stringify({...getValues()}),
          CreatedBy: user?.UserName,
        };
        dispatch(updateDefaultValue(request));
      } else {
        let request = {
          ModuleName: "Vendor Management",
          ScreenName: "Vendor Management",
          Activity : "default",
          DefaultData: JSON.stringify({...getValues()}),
          CreatedBy: user?.UserName,
        };
        dispatch(addDefaultValue(request));
      }
    } else {
      if (edit) {
        // console.log("edit", edit)
        // console.log(getValues())
        // dispatch(updateuser(getValues()))
        // if (isEdited) {
        //   isEdited(false)
        // }

        const request: any = { ...getValues() };
        const {
          vendorName,
          vendorEmail,
          vendorId,
          registeredDate,
          address,
          phoneNumber,
          location,
          paNumber,
          pan,
          ...dynamicFields
        } = request;
        const payload: any = {
          vendorId,
          vendorEmail,
          vendorName,
          address,
          phoneNumber,
          location,
          paNumber, // static fields
          createdBy: user?.UserName,
          UserId: userLoginResponse?.UserId,
          dynamicFields: [],
          ModuleName: "Vendor Management",
          Activity: "Edit",
        };
        for (const [key, objVal] of Object.entries(dynamicFields)) {
          payload.dynamicFields.push({
            colName: key,
            value: objVal || "",
          });
        }
        dispatch(updateVendor(payload));
      } else {
        // setValue("vendorId", datas + 1);
        // console.log(getValues())
        // dispatch(addUser(getValues()));
        // reset(formData);
        // setMessage(true);
        // setTimeout(()=>{
        //   setMessage(false);
        // setTimeout(()=>{

        //  },2000)
        // },2000)
        const request: any = { ...getValues() };
        const {
          vendorName,
          vendorEmail,
          vendorId,
          registeredDate,
          address,
          phoneNumber,
          location,
          paNumber,
          ...dynamicFields
        } = request;
        const payload: any = {
          vendorEmail,
          vendorName,
          address,
          phoneNumber,
          location,
          paNumber, // static fields
          createdBy: user?.UserName, //static fields
          UserId: userLoginResponse?.UserId,
          dynamicFields: [],
          ModuleName: "Vendor Management",
          Activity: "create",
        };
        for (const [key, objVal] of Object.entries(dynamicFields)) {
          payload.dynamicFields.push({
            colName: key,
            value: objVal || "",
          });
        }
        dispatch(addVendor(payload));
        // setValues(true)
        console.log("submitted successFully ");
        // toast.success("submitted successfully")

        // if(isAdded){
        //   isAdded(false)
        // }
      }
    }
  };

  // useEffect(() => {
  //       if(!edit && !view){
  //         dispatch(updateVendorRecords([]));
  //       }
  // }, []);

  useEffect(() => {
    if (isVendorAdded) {
      reset(formData);
      if (isAdded) {
        isAdded(false);
      }
      // const addEventLogPayload: addEventLogRequestParam = {
      //   ModuleName: "Vendor Management",
      //   Action: ACTIVITY.CREATE,
      //   Description: "Vendor Added Successfully",
      //   UserId: 1,
      //   CreatedBy: user?.UserName || "",
      // };
      // dispatch(addEventLog(addEventLogPayload));
      dispatch(updateAddandEdit({}));
    } else if (isVendorUpdated) {
      if (edit) {
        isEdited(false);
      }
      // const addEventLogPayload = {
      //   ModuleName: "Vendor Management",
      //   Action: ACTIVITY.EDIT,
      //   Description: "Vendor Updated Successfully",
      //   UserId: 1,
      //   CreatedBy: user?.UserName || "",
      // };

      // dispatch(addEventLog(addEventLogPayload));
      dispatch(updateAddandEdit({}));
    } else if (isDefaultValuedAddedd) {
      isAdded(false);
    } else if (isDefaultValuedUpdated) {
      isAdded(false);
    }
  }, [isVendorAdded, isVendorUpdated, isDefaultValuedAddedd, isDefaultValuedUpdated]);

  const renderCustomFields = () => {
    const index = formConfiguration.findIndex((form) => form.FormId === FormId);

    if (index === -1) return null;

    const customFields = formConfiguration[index].CustomFields || [];

    return customFields.map((customFields: any) => (
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <CustomFormField
          disabled={view}
          diableRequired={isDefault}
          control={control}
          errorsloginform={errorsloginform}
          {...customFields}
        />
      </Grid>
    ));
  };

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add Vendor
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit Vendor
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View Vendor
              </Typography>
            )}
          </Box>
        )}
        <Grid
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="vendorName"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Name is Required",
                      validate: (data) => {
                        return isDefault || data.length >= 4 || "Minimal 4 characters requires";
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"vendorName"}
                        required={isDefault ? false : true}
                        disabled={view}
                        label={"Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.vendorName)}
                        helperText={errorsloginform.vendorName && errorsloginform.vendorName.message?.toString()}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="vendorEmail"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Email is Required",
                      pattern: !isDefault
                        ? {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                          }
                        : undefined,
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"vendorEmail"}
                        required={isDefault ? false : true}
                        label={"Vendor Email"}
                        value={field.value}
                        disabled={view || edit}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.vendorEmail)}
                        helperText={errorsloginform.vendorEmail && errorsloginform.vendorEmail.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="address"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Address is Required",
                      validate: (data) => {
                        return isDefault || data.length > 5 || "Minimal 5 characters requires";
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"address"}
                        required={isDefault ? false : true}
                        label={"Addrsss"}
                        value={field.value}
                        disabled={view}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.address)}
                        helperText={errorsloginform.address && errorsloginform.address.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Phone Number is Required",
                      maxLength: isDefault
                        ? undefined
                        : {
                            value: 10,
                            message: "Number should be equal to 10 digits",
                          },
                      minLength: isDefault
                        ? undefined
                        : {
                            value: 10,
                            message: "Number must be 10 digits.",
                          },
                      min: isDefault
                        ? undefined
                        : {
                            value: 0,
                            message: "Invalid Data",
                          },
                      validate: (data) => {
                        return isDefault || /^\d{10}$/.test(data) || "Phone Number is not valid";
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"phoneNumber"}
                        required={isDefault ? false : true}
                        type={"number"}
                        label={"Phone Number"}
                        value={field.value}
                        disabled={view}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.phoneNumber)}
                        helperText={errorsloginform.phoneNumber && errorsloginform.phoneNumber.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="paNumber"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: isDefault ? false : "Pan is Required",
                      validate: edit
                        ? () => {
                            return true;
                          }
                        : (data) => {
                            return (
                              isDefault || /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(data) || "Pan is not Valid"
                            );
                          },
                      maxLength: isDefault
                        ? undefined
                        : {
                            value: 10,
                            message: `Pan must be maximum 10 characters`,
                          },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"paNumber"}
                        required={isDefault ? false : true}
                        label={"Pan Number"}
                        value={field.value}
                        onChange={field.onChange}
                        disabled={view}
                        error={Boolean(errorsloginform.paNumber)}
                        helperText={errorsloginform.paNumber && errorsloginform.paNumber.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="location"
                    control={control}
                    defaultValue=""
                    rules={{ required: isDefault ? false : "Location is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"location"}
                        valueKey={"id"}
                        optionLabelKey="lable"
                        options={location_Data}
                        disabled={view}
                        required={isDefault ? false : true}
                        label={"Location"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.location)}
                        helperText={errorsloginform.location && errorsloginform.location.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                {renderCustomFields()}
              </Grid>
              {message ? (
                <Box sx={{ display: "flex", justifyContent: "Center" }}>
                  <Typography variant="h6"> {edit ? EDIT_SUCCESS_MSG : ADD_SUCCESS_MSG}</Typography>
                </Box>
              ) : null}

              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "Center" }}>
                  <Typography variant="h6"> {edit ? EDIT_UPDATING_MSG : ADD_UPDATING_MSG}</Typography>
                </Box>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                  // position: "absolute",
                  // right: 24,
                  // bottom: 16,
                }}
              >
                {!view ? (
                  !edit ? (
                    <>
                      <CustomButton
                        type="reset"
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          reset(formData);
                          if (isAdded) {
                            isAdded(false);
                          }
                        }}
                      />
                      <CustomButton type="reset" name={"Submit"} onClick={handleVendorForm(handleSubmit)} />
                    </>
                  ) : (
                    <>
                      <CustomButton
                        variant="outlined"
                        name={"Cancel"}
                        onClick={() => {
                          console.log(isEdited);
                          if (isEdited) {
                            isEdited(false);
                          }
                        }}
                      />

                      <CustomButton variant="contained" name={"Update"} onClick={handleVendorForm(handleSubmit)} />
                    </>
                  )
                ) : (
                  <CustomButton
                    variant="outlined"
                    name={"Cancel"}
                    onClick={() => {
                      console.log(isEdited);
                      if (isEdited) {
                        isEdited(false);
                      }
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
    </Grid>
  );
};
