import { Box, Chip, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface PageMainHeadingProps {
  title : string;
 
}

const PageMainHeading : React.FC<PageMainHeadingProps> = (props) => {
  const {
    title ="",
  } = props;

  return (
    <>
    <Box>
    <Typography variant="h6" color={"text.primary"}>
              {title}
            </Typography>
    </Box>
      
    
    </>
  );
};

export default PageMainHeading;
