import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';

import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import CustomFileUploader from '../../../common/dynamicFileUploader/CustomFileUploader';
import CustomButton from '../../../common/dynamicButton/CustomButton';
import { RootState } from '../../../store/store';


interface FormData {
    branchID?: number;
    FileUpload: boolean;
}

export interface uploadFileProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
}

export const UploadFiles: React.FC<uploadFileProps> = ({
    view, closePopUp, edit, isEditData
}) => {


    const { user } = useSelector((state: RootState) => state.loggerData);
    const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
    const [message, setMessage] = useState<boolean>(false);


    let branchType_Data = [
        { id: "", lable: "Select your Location" },
        { id: "Main", lable: "Main" },
        { id: "Sub", lable: "Sub-Division" },
        { id: "Branch", lable: "Branch" },
    ];

    let branchStatus_Data = [
        { id: "", lable: "Select your Location" },
        { id: "Active", lable: "Active" },
        { id: "Maintanance", lable: "Maintanance" },
        { id: "Relocate", lable: "Relocate" },
    ];

    let company_Data = [
        { id: "", lable: "Select your Location" },
        { id: "AAA", lable: "AAA tech" },
        { id: "BBB", lable: "BBB Tech" },
        { id: "CCC", lable: "CCC Tech" },
    ];

    let branchState_Data = [
        { id: "", lable: "Select your Location" },
        { id: "TamilNadu", lable: "Tamil Nadu" },
        { id: "Kerala", lable: "Kerala" },
        { id: "AP", lable: "AP" },
    ];

    let branchRegion_Data = [
        { id: "", lable: "Select your Location" },
        { id: "South", lable: "South" },
        { id: "East", lable: "East" },
        { id: "SouthEast", lable: "South East" },
        { id: "NorthEast", lable: "North East" },
        { id: "SouthWest", lable: "South West" },
    ];

    console.log(isEditData, "isEditData");

    let formData: any = {
        branchID: edit ? isEditData?.id : 0,
        FileUpload: isEditData ? isEditData?.BranchName : "",

        // IsActive: isEditData ? isEditData?.Pan : "",

    };

    const {
        handleSubmit: handleUploadFile,
        reset,
        setValue,
        formState: { errors: errorsloginform },
        control,
        watch,
        getValues,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });
    const handleSubmit = () => {
        console.log(getValues())

    };

    const handleFileUpload = (files: any) => {
        // Handle the uploaded files here
        console.log("Uploaded files:", files);
    };



    return (
        <Grid
            container
            component="main"
            sx={{ height: "auto", width: "100%", overflow: "hidden" }}
        >
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >

                <Grid
                    sx={{
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Box>
                                        <Controller
                                            name="FileUpload"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomFileUploader

                                                    onFileUpload={field.onChange}
                                                    maxFileSize={1048576}
                                                    acceptFormats={[".pdf", ".png", ".xlsx", ".docx", ".ppt", ".ppts", ".csv"]}
                                                />
                                            )}
                                        />
                                    </Box>

                                </Grid>


                                {/* <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="IsActive"
                                        control={control}
                                        render={({ field }) => (
                                            <CustomToogleSwitch
                                                label={"User Status"}
                                                dynamicLabel={field.value ? 'ACTIVE' : 'INACTIVE'}

                                                disabled={view}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                </Grid> */}

                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "right",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton type="reset" name={"Update"} onClick={handleUploadFile(handleSubmit)} />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton type="reset" name={"Upload"} onClick={handleUploadFile(handleSubmit)} />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
        </Grid>
    );
};
