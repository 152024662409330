import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import renewalService from "../services/renewalService";
import { IRenewalData } from "../../models/renewal.type";

export interface IRenewal {
  isLoading: boolean;
  renewalData: IRenewalData[];
  ServicesByProjectIDData: any;
}
export const initialState: IRenewal = {
  isLoading: false,
  renewalData: [],
  ServicesByProjectIDData: [],
};

export const getRenewal = createAsyncThunk("getRenewal", async (_, { rejectWithValue }) => {
  try {
    const response = await renewalService.getRenewal();
    const { data } = response;
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const GetServicesByProjectID = createAsyncThunk("GetServicesByProjectID", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await renewalService.GetServicesByProjectID(reqbody);
    const { data } = response;
    return data;

  } catch (error) {
    return rejectWithValue(error);
  }
});

const renewalSlices = createSlice({
  name: "renewal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRenewal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRenewal.fulfilled, (state, action: PayloadAction<any>) => {
        state.renewalData = action.payload;
        state.isLoading = false;
      })
      .addCase(getRenewal.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

      .addCase(GetServicesByProjectID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetServicesByProjectID.fulfilled, (state, action: PayloadAction<any>) => {
        state.ServicesByProjectIDData = action.payload;
        state.isLoading = false;
      })
      .addCase(GetServicesByProjectID.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});
export default renewalSlices.reducer;
