import React, { useEffect, useState } from "react";
import { ACTIVE, textFieldWidth } from "../../../../utils/constants";
import { Box, FormHelperText, Grid, IconButton,InputAdornment, useTheme } from "@mui/material";
import { emailDataProps } from "../../../contractManagement/CostApproval/CostApproval";
import { Controller, useFormContext } from "react-hook-form";
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import CustomRadioButton from "../../../../common/dynamicInputs/CustomRadioButton";
import CustomSvg from "../../../../common/CustomSvg";
import CustomFileUploader from "../../../../common/dynamicFileUploader/CustomFileUploader";

import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { LocationConfig, LovConfig } from "../../../../config/config";
import CustomMultiSelect from "../../../../common/dynamicInputs/CustomMultiSelect";
import { getLocationList } from "../../../../store/slices/locationMasterData";
import CustomMultilineTextField from "../../../../common/dynamicInputs/CustomMultilineTextField";
import CustomAutoComplete from "../../../../common/dynamicInputs/CustomAutoComplete";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ApplicationParameterName } from "../../../../config/config";

interface IRegulatoryDetails {
  edit?: boolean;
  view?: boolean;
  isWorkFlow?: boolean;
  isEditData?: any;
  formData?: any;
}
const RegulatoryDetails = ({ edit, view, isWorkFlow, isEditData, formData }: IRegulatoryDetails) => {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formState: { errors, isDirty },
  } = useFormContext();
  const { CompanyTable } = useSelector((state: RootState) => state.CompanyMasterData);
  const isActiveDisabled = isWorkFlow ? true : watch("IsActive");
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { industryTable, ParentindustryTable } = useSelector((state: RootState) => state.indutryMasterData);
  //const { CompanyMasterData } = useSelector((state: RootState) => state.CompanyMasterData);
  //const { RegionalTable } = useSelector((state: RootState) => state.RegionalSettingsData);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const [regulatorData, setRegulatorData] = useState<any[]>([]);
  const [isMasked, setIsMasked] = useState(false);
    const [isTANMasked, setIsTANMasked] = useState(false);
    const [isCINMasked, setIsCINMasked] = useState(false);
    const [PAN_Masking_data, setPAN_Masking_data] = useState(false);
    const [TAN_Masking_data, setTAN_Masking_data] = useState(false);
    const [CIN_Masking_data, setCIN_Masking_data] = useState(false);
    const [RegionalData, setRegionalData] = useState<any[]>([]);

    const { RegionalTable } = useSelector((state: RootState) => state.RegionalSettingsData);
    const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);



useEffect(() => {
  const filteredRegulatorData = CompanyTable.filter((item: any) => item.IsActive === "Active" && item.ThirdPartyTypeValue === "Regulator");
  setRegulatorData(filteredRegulatorData);
}, [CompanyTable]);

useEffect(() => {
  const filteredRegionalData = RegionalTable.filter((item: any) => item.LocationName === "India");
  setRegionalData(filteredRegionalData.filter((item: any) => item.IsActive === "Active"));
}, [RegionalTable])

useEffect(() => {
  if (applicationParameterData.length > 0) {
      let Masking_data: any = applicationParameterData?.filter(
          (item:any) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Value === "Yes" && item.IsActive === "Active");
      setPAN_Masking_data(Masking_data?.find((item: any) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Name === ApplicationParameterName.Masking_PAN_Name) ? true : false);
      setTAN_Masking_data(Masking_data?.find((item: any) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Name === ApplicationParameterName.Masking_TAN_Name) ? true : false);
      setCIN_Masking_data(Masking_data?.find((item: any) => item.Parameter_Group === ApplicationParameterName.Masking && item.Parameter_Name === ApplicationParameterName.Masking_CIN_Name) ? true : false);
  }
}, [applicationParameterData]);

  const convertString = (str: any) => {
    return str
      .split("")
      .map((char: any) => {
        if (!isNaN(char) && char !== " ") {
          return "9";
        } else {
          return "X";
        }
      })
      .join("");
  };
  

  const Regulatedwatch = watch("Is_Regulated");
  //console.log(Regulatedwatch,"Regulatedwatch")

  const generateRegexFromFormats = (formats: any) => {
    return formats.map((format: any) => {
      const formatData = convertString(format);
      const escapedFormat = formatData.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regexPattern = escapedFormat.replace(/X/g, "[A-Z]").replace(/9/g, "\\d");
      const regex = new RegExp(`^${regexPattern}$`);

      return regex;
    });
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
};

  const GridItem = ({ content }: { content: React.ReactNode }) => (
    <Grid
      item
      xs={textFieldWidth.xs}
      sm={textFieldWidth.sm}
      md={textFieldWidth.md}
      lg={textFieldWidth.lg}
      xl={textFieldWidth.xl}
    >
      {content}
    </Grid>
  );

  
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="PAN"
          control={control}
          defaultValue=""
          rules={{
            required: "PAN is Required",
            validate: {
              noLeadingSpaces: (value) => {
                  if (view) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
              },
              maxLength: (value) => {
                  if (view) return;
                  return value.length < 50 || "Maximum 50 characters allowed";
              },
              ValueLimitation: (value) => {
                  if (view) return;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters";
              },
              RegrexValidation: (value) => {
                  const panFormat = RegionalData.find((data) => data.Equivalent === "PAN")?.Format;
                  if (view) return;
                  return panFormat
                      ? new RegExp(generateRegexFromFormats([panFormat]).pop()).test(value) ||
                      `Invalid PAN format. Example: ${panFormat}`
                      : true;
              },
          },
            // validate: {
            //   noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
            //   minLength: (value) => value.length >= 1 || "Minimum 1 character required",
            //   maxLength: (value) => value.length <= 50 || "Maximum 50 characters allowed",
            //   ValueLimitation: (value) => /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters",
            //   RegrexValidation: (value) => {
            //     const panFormat = RegionalTable?.find((data: any) => data?.Equivalent === "PAN")?.Format;
            //     return panFormat
            //       ? new RegExp(generateRegexFromFormats([panFormat]).pop()).test(value) ||
            //           `Invalid PAN format. Example: ${panFormat}`
            //       : true;
            //   },
            //   isNotRegistered: async (value) => {
            //     await new Promise<void>((resolve) => setTimeout(resolve, 500));

            //     const isRegistered = CompanyTable?.find((data: any) => data?.PAN === value) !== undefined;

            //     if (edit) {
            //       if (isDirty) {
            //         if (formData?.PAN === value) {
            //           return true;
            //         } else {
            //           return isRegistered ? "PAN Number is already registered" : true;
            //         }
            //       }
            //     } else {
            //       return isRegistered ? "PAN Number is already registered" : true;
            //     }
            //   },
            // },
          }}
          render={({ field }) => (
            <CustomTextField
              name={"PAN"}
              required={true}
              readOnly={view || !isActiveDisabled}
              label={"PAN"}
              type={PAN_Masking_data ? (isMasked ? "text" : "password") : "text"}
              value={field.value}
              onChange={field.onChange}
              inputProps={PAN_Masking_data ? {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { setIsMasked(!isMasked); }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disabled={view}
                        >
                            {isMasked ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            } : {}}
              error={Boolean(errors.PAN)}
              helperText={errors.PAN && errors.PAN.message?.toString()}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="TAN"
          control={control}
          defaultValue=""
          rules={{
            //required: "TAN is required",
            validate: {
              noLeadingSpaces: (value) => {
                  if (!value || view) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
              },
              maxLength: (value) => {
                  if (!value || view) return;
                  return value.length < 50 || "Maximum 50 characters allowed";
              },
              ValueLimitation: (value) => {
                  if (!value || view) return;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(value) ||
                      "Cannot contain special characters and spaces";
              },
              RegExValidation: (value) => {
                  const tanFormat = RegionalData.find((data) => data.Equivalent === "TAN")?.Format;
                  if (!value || view) return;
                  return tanFormat
                      ? new RegExp(generateRegexFromFormats([tanFormat]).pop()).test(value) ||
                      `Invalid TAN format example:${tanFormat}`
                      : true;
              },
          },
          }}
          render={({ field }) => (
            <CustomTextField
            name={"TAN"}
            // required={true}
            readOnly={view}
            label={"TAN"}
            type={TAN_Masking_data ? (isTANMasked ? "text" : "password") : "text"}
            value={field.value}
            onChange={field.onChange}
            inputProps={TAN_Masking_data ? {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { setIsTANMasked(!isTANMasked); }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disabled={view}
                        >
                            {isTANMasked ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            } : {}}
              error={Boolean(errors.TAN)}
              helperText={errors.TAN && errors.TAN.message?.toString()}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="CIN"
          control={control}
          defaultValue=""
          rules={{
            //required: "CIN is required",
            // validate: {
            //   noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
            //   minLength: (value) => value.length >= 1 || "Minimum 1 character required",
            //   maxLength: (value) => value.length <= 50 || "Maximum 50 characters allowed",
            //   ValueLimitation: (value) => /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters",
            //   RegExValidation: (value) => {
            //     const cinFormat = RegionalTable?.find((data: any) => data?.Equivalent === "CIN")?.Format;
            //     return cinFormat
            //       ? new RegExp(generateRegexFromFormats([cinFormat]).pop()).test(value) ||
            //           `Invalid CIN format. Example: ${cinFormat}`
            //       : true;
            //   },
            //   // isNotRegistered: async (value) => {
            //   //   await new Promise<void>((resolve) => setTimeout(resolve, 500));
            //   //   return (
            //   //     CompanyTable.find((data: any) => data.CIN === value) === undefined ||
            //   //     "CIN Number is already registered"
            //   //   );
            //   // },
            //   isNotRegistered: async (value) => {
            //     await new Promise<void>((resolve) => setTimeout(resolve, 500));

            //     const isRegistered = CompanyTable?.find((data: any) => data?.CIN === value) !== undefined;

            //     if (edit) {
            //       if (isDirty) {
            //         if (formData?.CIN === value) {
            //           return true;
            //         } else {
            //           return isRegistered ? "CIN Number is already registered" : true;
            //         }
            //       }
            //     } else {
            //       return isRegistered ? "CIN Number is already registered" : true;
            //     }
            //   },
            // },
            validate: {
              noLeadingSpaces: (value) => {
                  if (!value || view) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
              },
              maxLength: (value) => {
                  if (!value || view) return;
                  return value.length < 50 || "Maximum 50 characters allowed";
              },
              ValueLimitation: (value) => {
                  if (!value || view) return;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(value) ||
                      "Cannot contain special characters and spaces";
              },
              RegExValidation: (value) => {
                  const cinFormat = RegionalData.find((data) => data.Equivalent === "CIN")?.Format;
                  if (!value || view) return;
                  return cinFormat
                      ? new RegExp(generateRegexFromFormats([cinFormat]).pop()).test(value) ||
                      `Invalid CIN format. example: ${cinFormat}`
                      : true;
              },
          },
          }}
          render={({ field }) => (
            <CustomTextField
            name={"CIN"}
            // required={true}
            readOnly={view}
            label={"CIN"}
            type={CIN_Masking_data ? (isCINMasked ? "text" : "password") : "text"}
            value={field.value}
            onChange={field.onChange}
            inputProps={CIN_Masking_data ? {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { setIsCINMasked(!isCINMasked); }}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disabled={view}
                        >
                            {isCINMasked ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            } : {}}
              error={Boolean(errors.CIN)}
              helperText={errors.CIN && errors.CIN.message?.toString()}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Box sx={{ marginTop: "-4px" }}>
          <Controller
            name="Constitution"
            control={control}
            defaultValue=""
            rules={{ required: "Constitution is required" }}
            render={({ field }) => (
              <CustomAutoComplete
                optionValueKey={"LovId"}
                optionLabelKey="Options"
                options={
                  lovTable?.filter(
                    (item: any) => item?.Name === LovConfig.Company_Constitution && item?.IsActive === ACTIVE
                  ) || []
                }
                readOnly={view || !isActiveDisabled}
                required={true}
                label={"Constitution"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errors.Constitution)}
                helperText={errors.Constitution && errors.Constitution.message?.toString()}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Jurisdiction"
          control={control}
          defaultValue={""}
          rules={{
            required: "Jurisdiction Name is Required",
          }}
          render={({ field }) => (
            <CustomAutoComplete
              optionValueKey={"Location_Id"}
              optionLabelKey="Location_Name"
              options={
                locationTable?.filter(
                  (item: any) => item?.LocationTypeValue === LocationConfig.Location_City && item?.IsActive === ACTIVE
                ) || []
              }
              readOnly={view || !isActiveDisabled}
              required={true}
              label={"Jurisdiction"}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(errors.Jurisdiction)}
              helperText={errors.Jurisdiction && errors.Jurisdiction.message?.toString()}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Company_LOB"
          control={control}
          defaultValue={[]}
          rules={{
            required: "Line of Business is Required",
          }}
          render={({ field }) => {
            const filteredOptions =
              lovTable?.filter((item: any) => item?.Name === LovConfig?.LOB && item?.IsActive === ACTIVE) || [];

            return (
              <>
                {filteredOptions.length > 0 && (
                  <CustomMultiSelect
                    optionLabelKey="Options"
                    optionIdKey="LovId"
                    options={filteredOptions}
                    required={true}
                    readOnly={view || !isActiveDisabled}
                    label="Line Of Business Mapping"
                    value={Array.isArray(field.value) ? field.value : []}
                    onChange={field.onChange}
                    error={Boolean(errors.Company_LOB)}
                    helperText={errors.Company_LOB && errors.Company_LOB.message?.toString()}
                  />
                )}
              </>
            );
          }}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Department"
          control={control}
          defaultValue={[]}
          rules={{
            required: "Department Mapping is Required",
          }}
          render={({ field }) => {
            const filteredDepartments = dataTable?.filter((data: any) => data?.IsActive === ACTIVE) || [];

            return (
              <>
                {filteredDepartments.length > 0 && (
                  <CustomMultiSelect
                    optionLabelKey="DepartmentName"
                    optionIdKey="DepartmentId"
                    options={filteredDepartments}
                    required={true}
                    readOnly={view || !isActiveDisabled}
                    label="Department Mapping"
                    value={Array.isArray(field.value) ? field.value : []}
                    onChange={field.onChange}
                    error={Boolean(errors.Department)}
                    helperText={errors.Department && errors.Department.message?.toString()}
                  />
                )}
              </>
            );
          }}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Is_Regulated"
          control={control}
          defaultValue=""
          rules={{ required: "Is_Regulated is required" }}
          render={({ field }) => (
            <CustomRadioButton
              list={[
                { value: 1, lable: "Yes" },
                { value: 2, lable: "No" },
              ]}
              style={{ marginTop: "-10px" }}
              label="Is Regulated"
              value={field.value}
              readOnly={view || !isActiveDisabled}
              required={true}
              onChange={(e) => {
                field.onChange(Number(e));
                setValue("Regulator", "", {
                  shouldValidate: false,
                  shouldDirty: true,
                });
              }}
              error={Boolean(errors.Is_Regulated)}
              helperText={errors.Is_Regulated && errors.Is_Regulated.message?.toString()}
            />
          )}
        />
      </Grid>

      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="Regulator"
          control={control}
          defaultValue={""}
          rules={{
            required: Regulatedwatch !== 2 && "Regulator is Required",
          }}
          render={({ field }) => (
            <CustomAutoComplete
              optionValueKey={"ThirdPartyId"}
              optionLabelKey="ThirdPartyName"
              options={
                CompanyTable.filter(
                  (item: any) => item.ThirdPartyTypeValue === LovConfig.Regulator && item.IsActive === ACTIVE
                ) || []
              }
              disabled={Regulatedwatch == 2 ? true : false || view || !isActiveDisabled}
              readOnly={Regulatedwatch == 2 ? true : false || view || !isActiveDisabled}
              required={Regulatedwatch !== 2 ? true : false}
              label={"Regulator"}
              value={field.value !== null ? field.value : []}
              onChange={field.onChange}
              error={Boolean(errors?.Regulator)}
              helperText={errors?.Regulator && errors?.Regulator?.message?.toString()}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={textFieldWidth.xs}
        sm={textFieldWidth.sm}
        md={textFieldWidth.md}
        lg={textFieldWidth.lg}
        xl={textFieldWidth.xl}
      >
        <Controller
          name="About_Company"
          control={control}
          defaultValue={""}
          rules={{
            validate: {
              noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
              maxLength: (value) => {
                if (!value) return true;
                return value?.length < 100 || "Maximum 100 characters allowed";
              },
              ValueLimitation: (value) => {
                if (!value) return true;
                return /^[a-zA-Z0-9,.\- ]+$/.test(value) || "Cannot contain special characters";
              },
            },
          }}
          render={({ field }) => (
            <CustomMultilineTextField
              label={"About Company"}
              value={field.value}
              rows={3}
              required={false}
              readOnly={view || !isActiveDisabled}
              onChange={field.onChange}
              error={Boolean(errors.About_Company)}
              helperText={errors.About_Company && errors.About_Company.message?.toString()}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
export default RegulatoryDetails;
