import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IHeaderInUser, ILoggedInUser, IMenu } from "../../models/loggin.type";
import loginService from "../services/loginService";
import { IMenuOptions } from "../../models/roles.type";

export interface IAuthDetails {
  // userName: string;
  isAuthenticated: boolean;
  isVendorAuthenticated: boolean;
  isLoading: boolean;
  user: IHeaderInUser | null;
  userLoginResponse: ILoggedInUser | null;
  isLoginFailed: boolean;
  menuData: IMenu[] | null;
  menuOptions: IMenuOptions[];
  currentPagePermissionData: IMenu | null;
  vendorLoginDetails: any | null;
}

export const initialState: IAuthDetails = {
  //userName:"",
  isAuthenticated: false,
  isVendorAuthenticated: false,
  isLoading: false,
  userLoginResponse: {
    UserEmail: "",
    UserName: "",
    UserRole: "",
    RoleId: 0,
    UserId: 0,
    MenuId: 0,
    IsFirstLogin: false,
    StatusCode: 0,
    StatusDescription: "",
    Token: "",
    Expiration: "",
    ProfilePicture: "",
    ProfileUrl: "",
    Department:0,
  },
  user: {
    UserEmail: "",
    UserName: "",
    UserRole: "",
    ProfilePicture: "",
    ProfileUrl: "",
  },

  isLoginFailed: false,
  menuData: [],
  menuOptions: [],
  vendorLoginDetails: null,
  currentPagePermissionData: null,
};

export const getLogginUser = createAsyncThunk("getLogginData", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await loginService.getLogginRequest(requesrParams);
    const { data } = response;
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getLogginVendor = createAsyncThunk("getLogginVendor", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await loginService.getLogginVendor(requesrParams);
    const { data } = response;
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMenuByRolesId = createAsyncThunk(
  "getMenuBasedRoleId",
  async (requesrParams: { RoleId: number | null, UserId: number | null }, { rejectWithValue }) => {
    try {
      const response = await loginService.getMenuByRoleId(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    updateLogin: (state, action) => {
      state.isLoginFailed = action.payload;
    },
    updateAuthorize: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setCurrentPagePermission: (state, action) => {
      state.currentPagePermissionData = action.payload;
    },
    // setUserDetails:(state, action)=>{
    //     state.user = action.payload;
    //     state.isAuthenticated = true;
    // },
    logoutUser: (state, action) => {
      sessionStorage.clear();
      localStorage.clear();
      state.user = null;
      state.menuData = [];
      state.isAuthenticated = action.payload;
     
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLogginUser.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action, 'Actions');
        let userData: IHeaderInUser = {
          UserName: action.payload.UserName,
          UserEmail: action.payload.UserEmail,
          UserRole: action.payload.UserRole,
          ProfilePicture: action.payload.ProfilePicture || "",
          ProfileUrl: action.payload.ProfileUrl || "",
          MenuId:action.payload.MenuId,
          MenuUrl:action.payload.MenuUrl,
        };
        sessionStorage.setItem("UserId", action.payload.UserId);
        sessionStorage.setItem("Token", action.payload.Token);
        state.user = userData;
        state.userLoginResponse = action.payload;
        state.isAuthenticated = true;
        state.isLoading = false;
        state.isLoginFailed = false;
      })
      .addCase(getLogginUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isLoginFailed = true;
        //state.isAuthenticated= true;
      })
      .addCase(getLogginVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLogginVendor.fulfilled, (state, action: PayloadAction<any>) => {
        let vendorData: any = {
          vendorName: action.payload.Vendor_Name,
          vendorEmail: action.payload.Vendor_Email,
          vendorId: action.payload.Vendor_Id,
        };
      
        sessionStorage.setItem("Token", action.payload.Token);
        state.vendorLoginDetails = vendorData;
        state.isLoading = false;
        state.isVendorAuthenticated = true;
        state.isLoginFailed =  !action.payload?.IsSuccess;
      })
      .addCase(getLogginVendor.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isLoginFailed = true;
        //state.isAuthenticated= true;
      })
      .addCase(getMenuByRolesId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMenuByRolesId.fulfilled, (state, action: PayloadAction<any>) => {
        state.menuData = action.payload;
        const tempArray: IMenuOptions[] = [];
        action.payload?.forEach((item: any) => {
          if (item.SubMenus.length === 0) {
            tempArray.push({ key: item.MenuName, value: item.MenuName });
          } else {
            if (item.SubMenus.length !== 0) {
              item.SubMenus.map((itm: any) => {
                tempArray.push({ key: itm.MenuName, value: itm.MenuName });
              });
            }
          }
        });
        console.log(tempArray);
        state.menuOptions = tempArray;
        state.isLoading = false;
      })
      .addCase(getMenuByRolesId.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});

export const { logoutUser, updateLogin, updateAuthorize, setCurrentPagePermission } = authorizationSlice.actions;
export default authorizationSlice.reducer;
