import React, { useEffect, useState } from "react";
import RemarkSectionApproval from "./RemarkSection";
import { Box, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";


interface basicTableProps {
  selectedGridRecord?: any;
  selectedRecordDetails: any;
  setSelectedRecordDetails?: any;
  closePopUp?: any;
  view?: boolean;
  edit?: boolean;
}

const VendorApproveRejectPendingRecord: React.FC<basicTableProps> = ({
  selectedGridRecord,
  selectedRecordDetails,
  setSelectedRecordDetails,
  closePopUp,
  view,
  edit,
}) => {
  const [oldValues, setOldValues] = useState<any>("");
  const [oldValuesAddress, setOldValuesAddress] = useState<any>([]);
  const [NewValuesSPOC, setNewValuesSPOC] = useState<any>([]);
  const [newValues, setNewValues] = useState<any>("");
  const [newValuesAddress, setNewValuesAddress] = useState<any>([]);
  const [OldValuesSPOC, setOldValuesSPOC] = useState<any>([]);
  const [OldValuesEscalation, setOldValuesEscalation] = useState<any>([]);
  const [NewValuesEscalation, setNewValuesEscalation] = useState<any>([]);
  const [OldValuesSCM, setOldValuesSCM] = useState<any>([]);
  const [NewValuesSCMData, setNewValuesSCMData] = useState<any>([]);
  const [NewValuesSCM, setNewValuesSCM] = useState<any>([]);
  const [OldValuesSCMData, setOldValuesSCMData] = useState<any>([]);
  const [OldValuesAttachment, setOldValuesAttachment] = useState<any>([]);
  const [NewValuesAttachment, setNewValuesAttachment] = useState<any>([]);
  const [header, setHeader] = useState<any>([]);
  useEffect(() => {
    console.log(selectedRecordDetails, "selectedRecordDetails");

    if (selectedRecordDetails) {
      if (selectedRecordDetails?.NewValues) {
        const newHeader: any = [{ DisplayName: "Fields" }, { DisplayName: "New Values" }];

        if (selectedRecordDetails?.OldValues?.BasicDetailsData) {
          newHeader.push({ DisplayName: "Old Values" });
        }
        setHeader(newHeader);
      }
      try {
        if (selectedRecordDetails?.OldValues) {
          setOldValues(selectedRecordDetails?.OldValues);
          setOldValuesAddress(selectedRecordDetails?.OldValues?.ThirdPartyAddressData);
          setOldValuesSPOC(selectedRecordDetails?.OldValues?.VendorSpocsData);
          setOldValuesEscalation(selectedRecordDetails?.OldValues?.VendorEscalationsData);
          setOldValuesSCM(selectedRecordDetails?.OldValues?.ServiceCatlogMappingData);
          setOldValuesSCMData(selectedRecordDetails?.OldValues?.ServiceCatlogMappingData?.ServiceCatlogMapping);
          setOldValuesAttachment(selectedRecordDetails?.OldValues?.VendorAttachmentsData);
        }
        if (selectedRecordDetails?.NewValues) {
          setNewValues(selectedRecordDetails?.NewValues);
          setNewValuesAddress(selectedRecordDetails?.NewValues?.ThirdPartyAddressData);
          setNewValuesSPOC(selectedRecordDetails?.NewValues?.VendorSpocsData);
          setNewValuesEscalation(selectedRecordDetails?.NewValues?.VendorEscalationsData);
          setNewValuesSCM(selectedRecordDetails?.NewValues?.ServiceCatlogMappingData);
          setNewValuesSCMData(selectedRecordDetails?.NewValues?.ServiceCatlogMappingData?.ServiceCatlogMapping);
          setNewValuesAttachment(selectedRecordDetails?.NewValues?.VendorAttachmentsData);
        }
      } catch { }
    }
  }, [selectedRecordDetails]);
  console.log(selectedRecordDetails, selectedGridRecord, "selectedRecordDetails");
  console.log(newValues, oldValues, "oldValues");


  const renderAddressRow = (displayName: string, newValue: any, oldValue?: any, addType?: any) => (
    <TableRow key={`${displayName}-${addType || ""}`}>
      <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>{displayName || ""}</TableCell>
      <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
        {newValue !== undefined ? newValue : ""}
      </TableCell>
      {oldValuesAddress?.length ? (
        <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
          {oldValue !== undefined ? oldValue : ""}
        </TableCell>
      ) : null}
    </TableRow>
  );

  const renderNormalRow = (displayName: any, newValue: any, oldValue: any) => {

    const formatValue = (value: any) => {
      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
      }
      return value !== undefined ? value : "N/A";
    };

    return (
      <TableRow key={displayName}>
        {/* Field Name */}
        <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
          {displayName}
        </TableCell>

        {/* New Value */}
        <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
          {formatValue(newValue)}
        </TableCell>

        {/* Old Value */}
        {oldValuesAddress?.length ? (
          <TableCell sx={{ textAlign: "left", border: "2px solid #f1f1f1" }}>
            {formatValue(oldValue)}
          </TableCell>) : null}
      </TableRow>
    );
  };

  return (
    <>

      <Stack>

        {/* Vendor Basic Details section */}
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Basic Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Basic_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newValues?.BasicDetailsData || oldValues?.BasicDetailsData ? (
                <>
                  {renderNormalRow("Third Party Type", newValues?.BasicDetailsData?.ThirdPartyType, oldValues?.BasicDetailsData?.ThirdPartyType)}
                  {renderNormalRow("Third Party Name", newValues?.BasicDetailsData?.ThirdPartyName, oldValues?.BasicDetailsData?.ThirdPartyName)}
                  {renderNormalRow("Third Party Brand Name", newValues?.BasicDetailsData?.ThirdPartyBrandName, oldValues?.BasicDetailsData?.ThirdPartyBrandName)}
                  {renderNormalRow("Third Party Website", newValues?.BasicDetailsData?.ThirdPartyWepsite, oldValues?.BasicDetailsData?.ThirdPartyWepsite)}
                  {renderNormalRow("Registered Contact Number", newValues?.BasicDetailsData?.ContactNumber, oldValues?.BasicDetailsData?.ContactNumber)}
                  {renderNormalRow("Registered Email Id", newValues?.BasicDetailsData?.EmailID, oldValues?.BasicDetailsData?.EmailID)}
                  {renderNormalRow("Third Party Group Name", newValues?.BasicDetailsData?.ThirdPartyGroupName, oldValues?.BasicDetailsData?.ThirdPartyGroupName)}
                  {renderNormalRow("Alliance Type", newValues?.BasicDetailsData?.AllienceType, oldValues?.BasicDetailsData?.AllienceType)}
                  {renderNormalRow("Incorporated Year", newValues?.BasicDetailsData?.InCorporatedYear, oldValues?.BasicDetailsData?.InCorporatedYear)}
                  {renderNormalRow("Headquater Country", newValues?.BasicDetailsData?.Headquater_Country, oldValues?.BasicDetailsData?.Headquater_Country)}
                  {renderNormalRow("Industry", newValues?.BasicDetailsData?.Industry, oldValues?.BasicDetailsData?.Industry)}
                  {renderNormalRow("SubIndustry", newValues?.BasicDetailsData?.SubIndustry, oldValues?.BasicDetailsData?.SubIndustry)}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor Constitution Details section */}
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Constitution Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Constitution_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newValues?.ConstitutionDetailsData || oldValues?.ConstitutionDetailsData ? (
                <>
                  {renderNormalRow("PAN", newValues?.ConstitutionDetailsData?.PAN, oldValues?.ConstitutionDetailsData?.PAN)}
                  {renderNormalRow("TAN", newValues?.ConstitutionDetailsData?.TAN, oldValues?.ConstitutionDetailsData?.TAN)}
                  {renderNormalRow("CIN", newValues?.ConstitutionDetailsData?.CIN, oldValues?.ConstitutionDetailsData?.CIN)}
                  {renderNormalRow("Applicable Jurisdiction", newValues?.ConstitutionDetailsData?.Jurisdiction, oldValues?.ConstitutionDetailsData?.Jurisdiction)}
                  {renderNormalRow("Constitution", newValues?.ConstitutionDetailsData?.Constitution, oldValues?.ConstitutionDetailsData?.Constitution)}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor Regulator Details section */}
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Regulator Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Regulator_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newValues?.RegulatorDetailsData || oldValues?.RegulatorDetailsData ? (
                <>
                  {renderNormalRow("Is Listed", newValues?.RegulatorDetailsData?.IsListed, oldValues?.RegulatorDetailsData?.IsListed)}
                  {renderNormalRow("Is Regulated", newValues?.RegulatorDetailsData?.ISRegulated, oldValues?.RegulatorDetailsData?.ISRegulated)}
                  {renderNormalRow("Regulator Name", newValues?.RegulatorDetailsData?.RegulatorName, oldValues?.RegulatorDetailsData?.RegulatorName)}
                  {renderNormalRow("Other Regulator", newValues?.RegulatorDetailsData?.Others_Regulator, oldValues?.RegulatorDetailsData?.Others_Regulator)}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor Profiling section */}
        <Box>
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Third Party Profiling Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Profiling_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newValues?.ThirdPartyProfilingData || oldValues?.ThirdPartyProfilingData ? (
                <>
                  {renderNormalRow("Key Customer", newValues?.ThirdPartyProfilingData?.KeyCustomer, oldValues?.ThirdPartyProfilingData?.KeyCustomer)}
                  {renderNormalRow("Industries Catered", newValues?.ThirdPartyProfilingData?.IndustriesCatered, oldValues?.ThirdPartyProfilingData?.IndustriesCatered)}
                  {renderNormalRow("Volume Handled", newValues?.ThirdPartyProfilingData?.VolumeHandled, oldValues?.ThirdPartyProfilingData?.VolumeHandled)}
                  {renderNormalRow("Competitor Details", newValues?.ThirdPartyProfilingData?.CompetitorDetails, oldValues?.ThirdPartyProfilingData?.CompetitorDetails)}
                  {renderNormalRow("Internal Third Party Code", newValues?.ThirdPartyProfilingData?.InternalThirdPartyCode, oldValues?.ThirdPartyProfilingData?.InternalThirdPartyCode)}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor address section */}
        < Box >
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Third Party Address Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Address_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: Math.max(newValuesAddress?.length || 0, oldValuesAddress?.length || 0) }).map(
                (_, index: number) => {
                  const newAddress = newValuesAddress ? newValuesAddress[index] : null;
                  const oldAddress = oldValuesAddress ? oldValuesAddress[index] : null;

                  return (
                    <React.Fragment key={`address-row-${index}`}>
                      {renderAddressRow(
                        `Address Number ${index + 1}`,
                        newAddress?.Address_Number,
                        oldAddress?.Address_Number,

                      )}
                      {renderAddressRow("Address Type", newAddress?.Address_Type_Value, oldAddress?.Address_Type_Value)}
                      {renderAddressRow("Address", newAddress?.Address, oldAddress?.Address)}
                      {renderAddressRow("Pin", newAddress?.Pin, oldAddress?.Pin)}
                      {renderAddressRow("City", newAddress?.City, oldAddress?.City)}
                      {renderAddressRow("State", newAddress?.State, oldAddress?.State)}
                      {renderAddressRow("Country", newAddress?.Country, oldAddress?.Country)}
                      {renderAddressRow("Status", newAddress?.Status_Value, oldAddress?.Status_Value)}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor SPOC section */}
        < Box >
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Third Party POC Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-SPOC_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: Math.max(NewValuesSPOC?.length || 0, OldValuesSPOC?.length || 0) }).map(
                (_, index: number) => {
                  const newSPOC = NewValuesSPOC ? NewValuesSPOC[index] : null;
                  const oldSPOC = OldValuesSPOC ? OldValuesSPOC[index] : null;

                  return (
                    <React.Fragment key={`SPOC-row-${index}`}>
                      {renderAddressRow(
                        `Third Party SPOC Number ${index + 1}`,
                        newSPOC?.Address_Number,
                        oldSPOC?.Address_Number,

                      )}
                      {renderAddressRow("Purpose", newSPOC?.Purpose_Value, oldSPOC?.Purpose_Value)}
                      {renderAddressRow("Name", newSPOC?.Name, oldSPOC?.Name)}
                      {renderAddressRow("Designation", newSPOC?.Designation, oldSPOC?.Designation)}
                      {renderAddressRow("Contact NO", newSPOC?.ContactNumber, oldSPOC?.ContactNumber)}
                      {renderAddressRow("Email Id", newSPOC?.EmailId, oldSPOC?.EmailId)}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor Escalations section */}
        < Box >
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Third Party Escalations Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Escalation_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: Math.max(OldValuesEscalation?.length || 0, NewValuesEscalation?.length || 0) }).map(
                (_, index: number) => {
                  const oldEscalation = OldValuesEscalation ? OldValuesEscalation[index] : null;
                  const newEscalation = NewValuesEscalation ? NewValuesEscalation[index] : null;

                  return (
                    <React.Fragment key={`Escalation-row-${index}`}>
                      {renderAddressRow(
                        `Third Party Escalation Number ${index + 1}`,
                        newEscalation?.Address_Number,
                        oldEscalation?.Address_Number,

                      )}
                      {renderAddressRow("Name", newEscalation?.Name, oldEscalation?.Name)}
                      {renderAddressRow("Designation", newEscalation?.Designation, oldEscalation?.Designation)}
                      {renderAddressRow("Contact NO", newEscalation?.ContactNumber, oldEscalation?.ContactNumber)}
                      {renderAddressRow("Email Id", newEscalation?.EmailId, oldEscalation?.EmailId)}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor Service Catolog Mapping section */}
        < Box >
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Service Catalog Mapping Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Service_Catolog_Mapping_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {OldValuesSCM?.AssessmentType || NewValuesSCM?.AssessmentType ? (
                <>
                  {renderNormalRow("Scope Of Service", NewValuesSCM?.ScopeOfService, OldValuesSCM?.ScopeOfService)}
                  {renderNormalRow("Exclusion", NewValuesSCM?.Exclusion, OldValuesSCM?.Exclusion)}
                  {renderNormalRow("Onboarding Owner", NewValuesSCM?.OnboardingOwner, OldValuesSCM?.OnboardingOwner)}
                  {renderNormalRow("Assessment Type", NewValuesSCM?.AssessmentType, OldValuesSCM?.AssessmentType)}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                    -
                  </TableCell>
                </TableRow>
              )}
              {Array.from({ length: Math.max(OldValuesSCMData?.length || 0, NewValuesSCMData?.length || 0) }).map(
                (_, index: number) => {
                  const oldSCM = OldValuesSCMData ? OldValuesSCMData[index] : null;
                  const newSCM = NewValuesSCMData ? NewValuesSCMData[index] : null;

                  return (
                    <React.Fragment key={`Service-Catolog-Mapping-row-${index}`}>
                      {renderAddressRow(
                        `Service Catolog Mapping Number ${index + 1}`,
                        newSCM?.Address_Number,
                        oldSCM?.Address_Number,

                      )}
                      {renderAddressRow("Service Name", newSCM?.ServiceName, oldSCM?.ServiceName)}
                      {renderAddressRow("Summary", newSCM?.Summary, oldSCM?.Summary)}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>

        {/* Vendor Attachments section */}
        < Box >
          <Typography variant="h6" sx={{ margin: "16px 0", fontWeight: "bold" }}>
            Third Party Attachments Details
          </Typography>
          <Table sx={{ width: "100%", marginBottom: "24px" }}>
            <TableHead>
              <TableRow>
                {header.map((col: any) => (
                  <TableCell
                    key={`${col.DisplayName}-Attachments_Details_column`}
                    sx={{
                      textAlign: "center",
                      fontFamily: "aptos-bold",
                      border: "2px solid #f1f1f1",
                    }}
                  >
                    {col.DisplayName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: Math.max(NewValuesAttachment?.length || 0, OldValuesAttachment?.length || 0) }).map(
                (_, index: number) => {
                  const oldAttachment = OldValuesAttachment ? OldValuesAttachment[index] : null;
                  const newAttachment = NewValuesAttachment ? NewValuesAttachment[index] : null;

                  const transformData = (data: any) => {
                    const docTypeExtnArray = data ? JSON.parse(data) : [];
                    const docExtenTypes = docTypeExtnArray.map((item: any) => item?.Description || item?.Options).join(",");


                    return docExtenTypes
                  };

                  return (
                    <React.Fragment key={`Attachments-row-${index}`}>
                      {renderAddressRow(
                        `Third Party Attachments Number ${index + 1}`,
                        newAttachment?.Address_Number,
                        oldAttachment?.Address_Number,

                      )}
                      {renderAddressRow("Document Name", newAttachment?.DocumentName, oldAttachment?.DocumentName)}
                      {renderAddressRow("Document Type", transformData(newAttachment?.DocTypeExtnId), transformData(oldAttachment?.DocTypeExtnId))}
                      {renderAddressRow("Is Mandatory", newAttachment?.IsMandatory, oldAttachment?.IsMandatory)}
                      {renderAddressRow("Is Perpetual", newAttachment?.IsPerpetual, oldAttachment?.IsPerpetual)}
                    </React.Fragment>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>

        <RemarkSectionApproval selectedGridRecord={selectedGridRecord} closePopUp={closePopUp} view={view} />

      </Stack >

    </>
  );
};

export default VendorApproveRejectPendingRecord;
