import React, { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
  DialogProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CustomDialogProps {
  show: boolean;
  onHide: () => void;
  header: string;
  contentNode?: ReactNode;
  actionNode?: ReactNode;
  maxWidth?: any;
  minHeight?: any;
  style?: any;
  isCloseIconDisabled?: boolean;
  hideBackdrop?:boolean;
  hideDivider?:boolean;
  key?:string
}

const CustomDialog: React.FC<CustomDialogProps> = (props) => {
  const { key = 'showPopupValue' , show, onHide, header, contentNode, actionNode, maxWidth = "lg", minHeight = "20vh", style ,isCloseIconDisabled,hideBackdrop, hideDivider} = props;
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  return (
    <Dialog
    key={`${key}-popupfor`}
      open={show}
      hideBackdrop={hideBackdrop}
      // onClose={onHide}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth
      sx={{ style }}
      scroll={scroll}
    >
      <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
      {!isCloseIconDisabled && (
        <IconButton
          aria-label="close"
          onClick={onHide}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      
      <DialogContent dividers={!hideDivider} sx={{}}>
        {contentNode}
      </DialogContent>
      {actionNode ? <DialogActions>{actionNode}</DialogActions> : null}
    </Dialog>
  );
};

export default CustomDialog;
