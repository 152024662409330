import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { deletePreviewTemplateData } from "../../../store/contractManagement/slices/templateData";

import { updateHeaderMenu, updateMenu } from "../../../store/slices/menu";
import OnlyOfficeTextEditor from "../../../common/textEditor/OnlyOfficeTextEditor";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { updateLoader } from "../../../store/slices/loader";
import { getGetVCWorkflowProcessesData, updateCmWorkFlowInstance } from "../../../store/contractManagement/slices/contractRolesAndResponsiblity";
import { useSelector } from "react-redux";

interface OnlyOfficeTextEditorCM {
  isEditData: any;
  onClose: any;
}

const OnlyOfficeTextEditorCM: React.FC<OnlyOfficeTextEditorCM> = ({ isEditData =null, onClose }) => {
  const dispatch = useAppDispatchThunk();

  const [previewId, setPreviewId] = useState<number>(0);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    isRolesAndResponsibilityAdded,
    isRolesAndResponsibilityUpdated,
    isLoading,
    isRolesAndResponsibilityDeleted,
    workFLowInstanceData,
  }: any = contractManagementReducer.contractRolesAndResponsibility;
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const [contractData, setContractData] = useState<any>(null);
  const [filename, setFileName] = useState<string>("");
  useEffect(()=>{
    if(isEditData !== null){
      let request: any = null;
      if (isEditData.ProjectId) {
        request = {
          projectId: isEditData?.ProjectId,
          workflowInstanceId: isEditData?.Workflow_InstanceId,
        };
      }
      dispatch(updateLoader(true));
      dispatch(getGetVCWorkflowProcessesData(request));
    }
  },[isEditData])

  useEffect(() => {
    if (workFLowInstanceData !== null) {
      setContractData(workFLowInstanceData);
      setFileName(workFLowInstanceData?.contract_Tempate_Name);
      localStorage.setItem('contract_Tempate_Id', `${workFLowInstanceData?.contract_Tempate_Id}`);
      let role = userLoginResponse?.UserRole? userLoginResponse?.UserRole : "";
      localStorage.setItem("USER_ROLE_KEY", role);
    }
  }, [workFLowInstanceData]);

  return (
    <React.Fragment>
      {filename ?<Box
        sx={{
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
          <CustomButton
            variant="outlined"
            name={"Back"}
            onClick={async () => {
              // await dispatch(deletePreviewTemplateData(previewId));              
              // dispatch(updateHeaderMenu(true));
              // dispatch(updateMenu(true));             
                if (onClose) {
                   let request={
                    "workflowInstanceId": isEditData?.Workflow_InstanceId,
                    "processInstanceId": isEditData.Id,
                    "conditionValue": contractData?.vendor_Contract_Id,
                    "projectId": contractData.ProjectId,
                    "updatedBy": userLoginResponse ? userLoginResponse.UserName : "",
                  }                 
                   let data = await dispatch(updateCmWorkFlowInstance(request)).unwrap();
                   if (data) {                     
                     onClose();
                   }
                }
            }}
            size="small"
          />
        </Box>
        {filename && (
          <OnlyOfficeTextEditor           
            fileName={`${filename}.docx`}
            editorsMode={"review"}            
            isOpenDocument={true}
          />
        )}
      </Box> : <p>loading</p>}
    </React.Fragment>
  );
};

export default OnlyOfficeTextEditorCM;
