import { Box, Typography } from '@mui/material';
import React from 'react';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useTheme } from "@mui/material/styles";
export const Footer =()=>{
    const theme = useTheme();
    return(


        <Box sx={{ backgroundColor: theme.palette.primary.main,
      
           borderTop:'1px solid #D9D9D9',
           display:'flex' ,
           alignItems:'center',
           justifyContent:'center',
           padding:'5px 5px',
           color:'#ffff'
        }}>

<Typography sx={{color:'#ffff' , textAlign:'center' , paddingRight:'4px'}} >
Copyrights
            </Typography>
<CopyrightIcon style={{fontSize:'16px'}} />     <Typography sx={{color:'#ffff' , textAlign:'center' , paddingLeft:'4px'}} >
          2024 | All Rights Reserved | Powered by AI  
            </Typography>
             
        </Box>
    )
}