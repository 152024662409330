import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import VendorAssessmentService from "../services/vendorAssessmentService";

export interface IVendorAssessmentData {
  VendorAssessmentdataTableList: any;
  VendorAssessmentdata: any;
  isVendorAssessmentSuccess: any;
  isVendorAssessmentLoading: boolean;
  isVendorAssessmentthresholdLoading: boolean;
  isAddEditVendorAssessmentLoading: boolean;
  isVendorAssessmentAdded: boolean;
  isVendorAssessmentUpdated: boolean;
  isVendorAssessmenttHistory: boolean;
  isVendorAssessmentDeleted: boolean;
  isVendorAssessmentthreshhold: boolean;
  VendorAssessmentToasterMsg: string;
  thresholdforVendorAssessment: any;
}

export const initialState: IVendorAssessmentData = {
  VendorAssessmentdataTableList: [],
  VendorAssessmentdata: {},
  isVendorAssessmentSuccess: '',
  isVendorAssessmentLoading: false,
  isVendorAssessmentthresholdLoading: false,
  isAddEditVendorAssessmentLoading: false,
  isVendorAssessmentAdded: false,
  isVendorAssessmentUpdated: false,
  isVendorAssessmenttHistory: false,
  isVendorAssessmentDeleted: false,
  isVendorAssessmentthreshhold: false,
  VendorAssessmentToasterMsg: "",
  thresholdforVendorAssessment : 0,
};

export const getVendorAssessmentList = createAsyncThunk(
  "getVendorAssessmentList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await VendorAssessmentService.getAllVendorAssessmentList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorAssessment = createAsyncThunk(
    "getVendorAssessment",
    async (requesrParams: { Vendor_Id : number , RoleId : any , UserId : any , IsSmeReview : any , Project_Detail_Id  : any }, { rejectWithValue }) => {
      try {
        const response = await VendorAssessmentService.getVendorAssessment(requesrParams);
        const { data } = response;
        return data ;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const addVendorAssessment = createAsyncThunk("addVendorAssessment", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await VendorAssessmentService.addVendorAssessment(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateVendorAssessment = createAsyncThunk(
  "updateVendorAssessment",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await VendorAssessmentService.updateVendorAssessment(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteVendorAssessment = createAsyncThunk(
  "deleteVendorAssessment",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await VendorAssessmentService.deleteVendorAssessment(reqbody);
      return response.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorAssessmentHistory = createAsyncThunk("getVendorAssessmentHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await VendorAssessmentService.getVersionHistoryVendorAssessment(reqbody);
    const {AssessmentQuestionaireHistories , HeaderJson } =response.data;
    return {VendorAssessmentHistories: AssessmentQuestionaireHistories , HeaderJson : HeaderJson.ColumnHeaders}
        
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getVendorAssessmentbyVendorId = createAsyncThunk(
  "getVendorAssessmentbyVendorId",
  async (requesrParams: { Vendor_Id : number , Project_Detail_Id  : any }, { rejectWithValue }) => {
    try {
      const response = await VendorAssessmentService.getAllQuestionByVendorandProjectId(requesrParams);
      const { data } = response;
      return data ;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changethresholdforAssessment = createAsyncThunk(
  "changethresholdforAssessment",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await VendorAssessmentService.changethresholdforAssessment(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);




export const VendorAssessmentDataSlice = createSlice({
  name: "vendorAssessmenDetails",
  initialState,
  reducers: {
    updateVendorAssessmentAddandEdit: (state, action) => {
      state.isVendorAssessmentAdded = false;
      state.isVendorAssessmentUpdated = false;
      state.isVendorAssessmentDeleted = false;
      state.isVendorAssessmentSuccess = '';
      state.VendorAssessmentToasterMsg = '';
    },
    isVendorAssessmentthreshholdEdit: (state, action) => {
      state.isVendorAssessmentthreshhold = false;
    },
    
    updatethresholdforVendorAssessment : (state, action) => {
      state.thresholdforVendorAssessment = action.payload.value
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.VendorAssessmentdataTableList.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.VendorAssessmentdataTableList];
        copyVendorData[indexVendor] = action.payload;
        state.VendorAssessmentdataTableList = copyVendorData;
      }
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendorAssessmentList.pending, (state) => {
        state.isVendorAssessmentLoading = true;
      })
      .addCase(getVendorAssessmentList.fulfilled, (state, action: PayloadAction<any>) => {
        state.VendorAssessmentdataTableList = action.payload
        state.isVendorAssessmentLoading = false;
      })
      .addCase(getVendorAssessmentList.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentLoading = false;
      })
      .addCase(getVendorAssessment.pending, (state) => {
        state.isVendorAssessmentLoading = true;
      })
      .addCase(getVendorAssessment.fulfilled, (state, action: PayloadAction<any>) => {
        let actualData : any = action.payload
        state.VendorAssessmentdata = {
       ...actualData,
       Category : JSON.parse(actualData.Category),
       CategoryQuestions : JSON.parse(actualData.CategoryQuestions)
        } 
        state.isVendorAssessmentLoading = false;
      })
      .addCase(getVendorAssessment.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentLoading = false;
      })
      .addCase(getVendorAssessmentbyVendorId.pending, (state) => {
        state.isVendorAssessmentLoading = true;
      })
      .addCase(getVendorAssessmentbyVendorId.fulfilled, (state, action: PayloadAction<any>) => {
        let actualData : any = action.payload
        state.VendorAssessmentdata = {
       ...actualData,
       Category : JSON.parse(actualData.Category),
       CategoryQuestions : JSON.parse(actualData.CategoryQuestions)
        } 
        state.isVendorAssessmentLoading = false;
      })
      .addCase(getVendorAssessmentbyVendorId.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentLoading = false;
      })
      .addCase(addVendorAssessment.pending, (state) => {
        state.isVendorAssessmentLoading = true;
        state.isAddEditVendorAssessmentLoading = true;
      })
      .addCase(addVendorAssessment.fulfilled, (state, action: PayloadAction<any>) => {
        // state.VendorAssessmentdataTableList = [...state.VendorAssessmentdataTableList , ...action.payload  ]
        state.isVendorAssessmentAdded = true;
        state.isVendorAssessmentLoading = false;
        state.isAddEditVendorAssessmentLoading = false;
        state.VendorAssessmentToasterMsg = action.payload.Message;
        state.isVendorAssessmentSuccess = action.payload.IsSuccess;
      })
      .addCase(addVendorAssessment.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentLoading = false;
        state.isAddEditVendorAssessmentLoading = false;
      })
      .addCase(updateVendorAssessment.pending, (state) => {
        state.isVendorAssessmentLoading = true;
        state.isAddEditVendorAssessmentLoading = true;
      })
      .addCase(updateVendorAssessment.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentUpdated = true;
        state.isVendorAssessmentLoading = false;
        state.isAddEditVendorAssessmentLoading = false;
        state.VendorAssessmentToasterMsg = action.payload.Message;
      })
      .addCase(updateVendorAssessment.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentLoading = false;
        state.isAddEditVendorAssessmentLoading = false;
      })
      .addCase(deleteVendorAssessment.pending, (state) => {
        state.isVendorAssessmentLoading = true;
      })
      .addCase(deleteVendorAssessment.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentDeleted = true;
        state.isVendorAssessmentLoading = false;
        state.VendorAssessmentToasterMsg = action.payload.Message;
      })
      .addCase(deleteVendorAssessment.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentLoading = false;
      }).addCase(changethresholdforAssessment.pending, (state) => {
        state.isVendorAssessmentthresholdLoading = true;
      })
      .addCase(changethresholdforAssessment.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentthreshhold = true;
        state.isVendorAssessmentthresholdLoading = false;
        state.thresholdforVendorAssessment = action.payload.Threshold ;
      })
      .addCase(changethresholdforAssessment.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorAssessmentthresholdLoading = false;
      })    
      
      

  },
});

export const { updateVendorAssessmentAddandEdit, updateUser , updatethresholdforVendorAssessment , isVendorAssessmentthreshholdEdit } = VendorAssessmentDataSlice.actions;
export default VendorAssessmentDataSlice.reducer;
