import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rolesService from '../services/rolesService';
import { IMENU, IRole, IRoleColumnHeaders, IVHColumnRes } from '../../models/roles.type';


export interface IRoleData {
    allRoles: { headers: IRoleColumnHeaders[], roles: IRole[] }
    isLoading: boolean;
    isRoleAdded: boolean;
    isRoleUpdated: boolean;
    isRoleDeleted: boolean;
    allMenuData: IMENU[];
    ToasterMsg: string;
    errormessage: string;
    roleVersionHistory: { roleRecords: IRole[], roleVHeader: IVHColumnRes[] };

}

export const initialState: IRoleData = {
    allRoles: { headers: [], roles: [] },
    isLoading: false,
    isRoleAdded: false,
    isRoleUpdated: false,
    isRoleDeleted: false,
    allMenuData: [],
    ToasterMsg: "",
    errormessage: "",
    roleVersionHistory: { roleRecords: [], roleVHeader: [] },

}

export const getRolesList = createAsyncThunk('getRolesList', async (requesrParams: { formId: number }, { rejectWithValue }) => {
    try {
        const response = await rolesService.getRolesList(requesrParams);
        const { HeaderJson, Roles } = response.data;
        return { headers: HeaderJson.ColumnHeaders, roles: Roles }
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const addRole = createAsyncThunk('addRole', async (requesrParams: any, { rejectWithValue }) => {
    try {
        //requesrParams["createdBy"] ="Bala";
        const response = await rolesService.addRole(requesrParams);
        return response.data

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const updateRole = createAsyncThunk('updateRole', async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response = await rolesService.updateRole(requesrParams);
        return response.data
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const deleteRole = createAsyncThunk('deleteRole', async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        const response = await rolesService.deleteRole(reqbody);

        return response.data;


    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getALLMenus = createAsyncThunk('getALLMenus', async (req: any, { rejectWithValue }) => {
    try {
        const response = await rolesService.allmenus();
        const menudata = response.data
        return menudata

    } catch (error) {
        return rejectWithValue(error)
    }
})

export const getRolesById = createAsyncThunk('getRolesById', async (req: any, { rejectWithValue }) => {
    try {
        const response = await rolesService.getRosById(req);
        const menudata = response.data
        return menudata
    } catch (error) {
        return rejectWithValue(error)
    }
})


export const getRoleHistory = createAsyncThunk("getRoleHistory", async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        const response: any = await rolesService.getVersionHistoryRole(reqbody);
        const { Roles, HeaderJson } = response.data;
        return { RoledataRecords: Roles, HeaderJson: HeaderJson.ColumnHeaders }

    } catch (error) {
        return rejectWithValue(error);
    }
});

export const roleDataSlice = createSlice({
    name: 'roleData',
    initialState,
    reducers: {
        updateRoleAddandEdit: (state, action) => {
            state.isRoleAdded = false;
            state.isRoleUpdated = false;
            state.isRoleDeleted = false;
            state.errormessage = "";
        },
        updateRoledata: (state, action) => {
            if (action.payload.id) {
                let indexVendor = state.allRoles.roles.findIndex(data => data.RoleId === action.payload.RoleId)
                let copyVendorData = [...state.allRoles.roles];
                copyVendorData[indexVendor] = action.payload;
                state.allRoles.roles = copyVendorData;
            }
        }, 
        updateVersionHistory: (state, action) => {
            state.roleVersionHistory.roleRecords = [];
            state.roleVersionHistory.roleVHeader = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRolesList.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getRolesList.fulfilled, (state, action: PayloadAction<any>) => {
                state.allRoles.headers = action.payload.headers;
                state.allRoles.roles = action.payload.roles.map((data: IRole) => {
                    data.IsActive = data.IsActive === 1 ? "Active" : "Inactive";
                    return data
                });
                state.isLoading = false;
            })
            .addCase(getRolesList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false
            })
            .addCase(addRole.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addRole.fulfilled, (state, action: PayloadAction<any>) => {
                state.isRoleAdded = true;
                state.isLoading = false;
                state.ToasterMsg = action.payload.Message
                // state.ToasterMsg = action.payload.message;

            })
            .addCase(addRole.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false
            })
            .addCase(updateRole.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateRole.fulfilled, (state, action: PayloadAction<any>) => {
                state.isRoleUpdated = true;
                // let indexVendor= state.dataTable.findIndex(data=> data.vendorId === updatedUser.vendorId);
                // let copyVendorData= [...state.dataTable];
                // copyVendorData[indexVendor]= updatedUser;
                // state.dataTable = copyVendorData;
                state.isLoading = false;
                state.ToasterMsg = action.payload.Message
                // state.ToasterMsg = action.payload.message;
            })
            .addCase(updateRole.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false
            })
            .addCase(deleteRole.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteRole.fulfilled, (state, action: PayloadAction<any>) => {
                state.isRoleDeleted = true;
                // let indexVendor= state.dataTable.findIndex(data=> data.vendorId === updatedUser.vendorId);
                // let copyVendorData= [...state.dataTable];
                // copyVendorData[indexVendor]= updatedUser;
                // state.dataTable = copyVendorData;
                state.isLoading = false;
                state.ToasterMsg = action.payload.Message
            })
            .addCase(deleteRole.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false
                state.errormessage = "This Role is already mapped to user we can't deactivate this role"
            })
            .addCase(getALLMenus.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getALLMenus.fulfilled, (state, action: PayloadAction<any>) => {
                state.allMenuData = action.payload;
                state.isLoading = false;
            })
            .addCase(getALLMenus.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false
            })
            .addCase(getRoleHistory.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
              })
              .addCase(getRoleHistory.fulfilled, (state, action: PayloadAction<any>) => {
                state.roleVersionHistory.roleVHeader = action.payload.HeaderJson
                state.roleVersionHistory.roleRecords = action.payload.RoledataRecords.map((user: any) => {
                    user.IsActive = user.IsActive === 1 ? "Active" : "Inactive";
                    return user ;
                });
                state.isLoading = false;
                // state.ToasterMsg = "Department data deleted successfully";
              })
              .addCase(getRoleHistory.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
              })
    }
});

export const { updateRoleAddandEdit, updateRoledata, updateVersionHistory } = roleDataSlice.actions;
export default roleDataSlice.reducer;