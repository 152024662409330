import { AxiosError, AxiosResponse } from "axios";
import { IWorkflowListOfValuesResponse, WorkflowInstanceListOfValuesResponse } from "../../models/workFlow.type";
import { axiosInstance } from "./config/axiosConfig";
import { WORKFLOW_URL } from "./endpoints/workFlow";

const getListOfValues = (requestParams: any): Promise<IWorkflowListOfValuesResponse> =>
  axiosInstance
    .get(WORKFLOW_URL.ListOfValues, {
      params: requestParams,
    })
    .then((response: AxiosResponse<IWorkflowListOfValuesResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const creatrWorkFlow = (request: any): Promise<IWorkflowListOfValuesResponse> =>
  axiosInstance
    .post(WORKFLOW_URL.CreateWorkFlow, request)
    .then((response: AxiosResponse<IWorkflowListOfValuesResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateWorkFlow = (request: any): Promise<IWorkflowListOfValuesResponse> =>
  axiosInstance
    .post(WORKFLOW_URL.UpdateWorkFlow, request)
    .then((response: AxiosResponse<IWorkflowListOfValuesResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getListOfWorkFlows = (): Promise<IWorkflowListOfValuesResponse> =>
  axiosInstance
    .get(WORKFLOW_URL.WorkFlowList)
    .then((response: AxiosResponse<IWorkflowListOfValuesResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getWorkFlowByCodeandId = (req: any): Promise<any> =>
  axiosInstance
    .get(WORKFLOW_URL.GetWorkflowByCode, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getWorkFlowByCode = (req: any): Promise<any> =>
  axiosInstance
    .get(WORKFLOW_URL.TriggerWorkFlow, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const fetchWorkFlowByCode = (req: any): Promise<any> =>
  axiosInstance
    .get(WORKFLOW_URL.GetWorkflowByCode, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateWorkFlowStep = (req: any): Promise<IWorkflowListOfValuesResponse> =>
  axiosInstance
    .post(WORKFLOW_URL.UpdateWorkFlowStep, req)
    .then((response: AxiosResponse<IWorkflowListOfValuesResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getListOfWorkFlowInstance = (): Promise<WorkflowInstanceListOfValuesResponse> =>
  axiosInstance
    .get(WORKFLOW_URL.GetAllWorkflowInstances)
    .then((response: AxiosResponse<WorkflowInstanceListOfValuesResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getWorkFlowInstanceByCode = (req: any): Promise<any> =>
  axiosInstance
    .get(WORKFLOW_URL.GetWorkflowInstanceByCode, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getWorkflowConditions = (): Promise<any> =>
  axiosInstance
    .get(WORKFLOW_URL.GetWorkflowConditions)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getWorkflowInstanceById = (req: any): Promise<any> =>
  axiosInstance
    .get(WORKFLOW_URL.TriggerWorkFlowInstance, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });


const getWorkflowInstanceByVendorId = (req: any): Promise<any> =>
  axiosInstance
    .get(WORKFLOW_URL.TriggerWorkFlowInstancebyVendorId, { params: req })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteworkflowbyId = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(WORKFLOW_URL.DeleteworkflowbyId, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error deleting Workflow: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const workFlowService = {
  getListOfValues,
  creatrWorkFlow,
  updateWorkFlow,
  getListOfWorkFlows,
  getWorkFlowByCode,
  updateWorkFlowStep,
  getListOfWorkFlowInstance,
  getWorkFlowInstanceByCode,
  getWorkflowConditions,
  getWorkflowInstanceById,
  getWorkflowInstanceByVendorId,
  deleteworkflowbyId,
  fetchWorkFlowByCode,
  getWorkFlowByCodeandId,
};

export default workFlowService;
