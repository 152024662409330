export const HEADING = "Event Logs";

export const BASE_ACTIVITY_OPTION = [{ id: "", lable: "Select Activity" }];

export const BASE_MODULE_OPTION = [{ key: "", value: "Select Module" }];

export const START_TIME = "T00:00:01.001Z";

export const END_TIME = "T23:59:59.999Z";

export const EVENT_LOG_RESPONSE_FIELDS = {
  EventLogId: "EventLogId",
  ModuleName: "ModuleName",
  Action: "Action",
  Description: "Description",
  UserId: "UserId",
  CreatedOn: "CreatedOn",
  CreatedBy: "CreatedBy",
};

export const EVENT_LOG_RESPONSE_FIELDS_TYPES = {
  [EVENT_LOG_RESPONSE_FIELDS.EventLogId]: "number",
  [EVENT_LOG_RESPONSE_FIELDS.ModuleName]: "string",
  [EVENT_LOG_RESPONSE_FIELDS.Action]: "string",
  [EVENT_LOG_RESPONSE_FIELDS.Description]: "string",
  [EVENT_LOG_RESPONSE_FIELDS.UserId]: "number",
  [EVENT_LOG_RESPONSE_FIELDS.CreatedOn]: "string",
  [EVENT_LOG_RESPONSE_FIELDS.CreatedBy]: "string",
};

export const EVENT_LOG_RESPONSE_FIELDS_DISPLAY_NAME = {
  [EVENT_LOG_RESPONSE_FIELDS.EventLogId]: "Event Log Id",
  [EVENT_LOG_RESPONSE_FIELDS.ModuleName]: "Module Name",
  [EVENT_LOG_RESPONSE_FIELDS.Action]: "Action",
  [EVENT_LOG_RESPONSE_FIELDS.Description]: "Description",
  [EVENT_LOG_RESPONSE_FIELDS.UserId]: "User Id",
  [EVENT_LOG_RESPONSE_FIELDS.CreatedOn]: "Created On",
  [EVENT_LOG_RESPONSE_FIELDS.CreatedBy]: "Created By",
};
