import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DocumentMasterService from "../services/documentMasterService";
import { IDocument, IVHColumnRes } from "../../models/document.type";
import { chkDate, chkExpiryDate } from "../../utils/constants";

export interface IDocumentData {
  DocumentTable: any;
  isLoading: boolean;
  isDocumentAdded: boolean;
  versionHistory: { DocumentMaster: IDocument[]; departmentVHeader: IVHColumnRes[] };
  isDocumentHistory: boolean;
  isDocumentDeleted: boolean;
  ToasterMsg: string;
}

export const initialState: IDocumentData = {
  DocumentTable: [],
  isLoading: false,
  isDocumentAdded: false,
  versionHistory: { DocumentMaster: [], departmentVHeader: [] },
  isDocumentHistory: false,
  isDocumentDeleted: false,
  ToasterMsg: "",
};

export const getDocumentList = createAsyncThunk(
  "getDocumentList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await DocumentMasterService.getDocumentList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEditDocument = createAsyncThunk("addEditDocument", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await DocumentMasterService.addEditDocument(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteDocument = createAsyncThunk("deleteDocument", async (requesrParams: any, { rejectWithValue }) => {
  console.log(requesrParams, "requesrParams");

  try {
    let reqbody = { ...requesrParams };
    console.log(reqbody, "requesrParams");
    const response: any = await DocumentMasterService.deleteDocument(reqbody);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDocumentHistory = createAsyncThunk(
  "getDocumentHistory",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await DocumentMasterService.getVersionHistoryDocument(reqbody);
      const { DocumentMaster, HeaderJson } = response.data;
      return { DocumentMaster: DocumentMaster, HeaderJson: HeaderJson.ColumnHeaders };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const DocumentMasterDataSlice = createSlice({
  name: "DocumentMasterData",
  initialState,
  reducers: {
    updateDocumentAddandEdit: (state, action) => {
      state.isDocumentAdded = false;
      state.isDocumentDeleted = false;
      state.ToasterMsg = "";
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.DocumentTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.DocumentTable];
        copyVendorData[indexVendor] = action.payload;
        state.DocumentTable = copyVendorData;
      }
    },
    // updateVersionHistory:  (state, action)=>{
    //   state.versionHistory.departmentRecords = [];
    //   state.versionHistory.departmentVHeader = [];
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocumentList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");

        state.DocumentTable = action.payload.map((user: any) => {
          user.IsMandatory = user.IsMandatory === true ? "Yes" : "No";
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.IsPerpetual = user.IsPerpetual === true ? "Yes" : "No";
          user.ExpiryDate = chkExpiryDate(user.ExpiryDate);
          return user;
        });
        state.isLoading = false;
      })
      .addCase(getDocumentList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addEditDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addEditDocument.fulfilled, (state, action: PayloadAction<any>) => {
        // console.log(action.payload , 'action.payload1');
        //     let item = {...action.payload};
        //     let copyallUsers= [...state.dataTable];
        //     copyallUsers.push(item);
        //     state.dataTable = copyallUsers;
        state.isDocumentAdded = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
      })
      .addCase(addEditDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDocumentDeleted = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
      })
      .addCase(deleteDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getDocumentHistory.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getDocumentHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.departmentVHeader = action.payload.HeaderJson;
        state.versionHistory.DocumentMaster = action.payload.DocumentMaster.map((user: any) => {
          user.IsMandatory = user.IsMandatory === true ? "Yes" : "No";
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.IsPerpetual = user.IsPerpetual === true ? "Yes" : "No";
          user.ExpiryDate = user.ExpiryDate === true ? "Yes" : "No";
          user.CreatedOn = chkDate(user.CreatedOn);
          return user;
        });
        state.isLoading = false;
        // state.ToasterMsg = "Department data deleted successfully";
      })
      .addCase(getDocumentHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});

export const { updateDocumentAddandEdit, updateUser } = DocumentMasterDataSlice.actions;
export default DocumentMasterDataSlice.reducer;
