import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { DepartmentConfig, LocationConfig, LovConfig, MasterBulkUpload } from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import { getDepartmentList } from "../../../../store/slices/departmentData";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
import { getParentLocationList } from "../../../../store/slices/locationMasterData";
export const ThirdpartyImportdataImportdata: any = [
  {
    Field: "name",
    FieldType: "string",
    DisplayName: "Name",
    config: {
      required: true,
    },
  },
  {
    Field: "short_Name",
    FieldType: "string",
    DisplayName: "Short Name",
    config: {
      required: true,
    },
  },
  {
    Field: "third_Party_type",
    FieldType: "string",
    DisplayName: "Third Party Type",
    config: {
      required: true,
    },
  },
  {
    Field: "address",
    FieldType: "string",
    DisplayName: "Address",
    config: {
      required: true,
    },
  },
  {
    Field: "pincode",
    FieldType: "string",
    DisplayName: "Pincode",
    config: {
      required: true,
    },
  },
  {
    Field: "contact_Person",
    FieldType: "string",
    DisplayName: "Contact Person",
    config: {
      required: true,
    },
  },
  {
    Field: "contact_Number",
    FieldType: "string",
    DisplayName: "Contact Number",
    config: {
      required: true,
    },
  },
  {
    Field: "email_id",
    FieldType: "string",
    DisplayName: "Email Id",
    config: {
      required: true,
    },
  },
];

const ThirdPartyMasterUploadSheet: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);
  const [countryList, setCountrytList] = useState<any[]>([]);
  const [thirdPartyType, setThirdPartyType] = useState<any[]>([]);

  useEffect(() => {
    let dublocationType: any[] = lovTable.filter((item: any) => item.Name === LovConfig.Location_Type);
    console.log(dublocationType, "dublocationType");
    if (dublocationType && dublocationType.length > 0) {
      let countryTypeId: any = dublocationType.find((item) => item.Options === LocationConfig?.Location_City);

      if (countryTypeId && countryTypeId.LovId) {
        dispatch(getParentLocationList({ LocationtypeId: countryTypeId.LovId }));
      }
    }
  }, [lovTable]);

  useEffect(() => {
    setCountrytList(ParentLocationTable);
  }, [ParentLocationTable]);

  useEffect(() => {
    setThirdPartyType(lovTable.filter((item: any) => item.Name === LovConfig.ThirdPartyType) || []);
  }, [lovTable]);
  

  const { setselectedValidation, uploaddata } = context || {};

  //validation the depatment Fields
  const department_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
        console.log(uploaddata , 'uploaddata');
        
      let ValidatedData: any[] = [];
      uploaddata.forEach((item: any) => {
        let error: string = "";
        let object: any = {
          ...item,
            name: "",
            short_Name: "",
            third_Party_type: "",
            third_Party_type_Id: "",
            address: "",
            pincode: "",
            city_Name: "",
            contact_Person: "",
            contact_Number: "",
            email_id: "",
        };
        let findValidtype = thirdPartyType.find(
            (typeitem) => typeitem.Options == item["Third Party Type"]
          );
        ThirdpartyImportdataImportdata.map((header_item: any) => {
      
          //validation the depatment Name Fields
          if (header_item.Field === "name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Name is Required,";
            }
          }

          if (header_item.Field === "short_Name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Short Name is Required,";
            }
          }
            //validation the third party Type Fields
            if (header_item.Field === "third_Party_type") {
                if (findValidtype?.LovId) {
                  object[header_item.Field] = item[header_item.DisplayName];
                  object.third_Party_type_Id = findValidtype?.LovId;
                } else {
                  if (item[header_item.DisplayName]) {
                    object[header_item.Field] = item[header_item.DisplayName];
                    error += " Invalid Third Party Type,";
                  } else {
                    error += " Third Party is Required,";
                  }
                }
              }
          if (header_item.Field === "address") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                object[header_item.Field] = item[header_item.DisplayName];
            } 
          }
          if (header_item.Field === "contact_Person") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                object[header_item.Field] = item[header_item.DisplayName];
            } 
          }
          if (header_item.Field === "contact_Number") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              console.log(item[header_item.DisplayName] , Number(item[header_item.DisplayName]) , 'conlength');
                        let ConNumber = Number(item[header_item.DisplayName]);
                if(item[header_item.DisplayName] &&  ConNumber >= 1000000000 && ConNumber <= 9999999999){
                    object[header_item.Field] = item[header_item.DisplayName];
                }else{
                    object[header_item.Field] = item[header_item.DisplayName];
                    error += " Invalid Number,";  
                }
                
            } 
          }
          if (header_item.Field === "email_id") {
            const email = item[header_item.DisplayName];
            
            if (email && email !== undefined) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (emailRegex.test(email)) {
                object[header_item.Field] = email; 
              } else {
                object[header_item.Field] = email; 
                error += " Invalid Email,";
              }
            } 
          }
          if (header_item.Field === "pincode") {
            const pincode = item[header_item.DisplayName];
            
            if (pincode && pincode !== undefined) {
                let findedLocation = countryList.find((citem) =>  citem.Start_Pin <=  pincode && citem.End_Pin >=  pincode)
              if (findedLocation?.Location_Name) {
                object[header_item.Field] = pincode; 
                object.city_Name = findedLocation?.Location_Name; 
              } else {
                object[header_item.Field] = pincode; 
                error += " Invalid Pincode,";
              }
            } 
          }

          object.error = error;
        });
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      department_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const hiddenSheet = workbook.addWorksheet("Note"); // Hidden sheet for large data lists
      let sheet_Column: any = ThirdpartyImportdataImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);
      hiddenSheet.getCell(`A${1}`).value = "Note : Name, Short Name, Third Paty Type is Mandatory, remaing Optional , Pin Code Use below the city Range only";
      hiddenSheet.getCell(`A${3}`).value = "City Name";
      hiddenSheet.getCell(`B${3}`).value = "Start Pin";
      hiddenSheet.getCell(`C${3}`).value = "End Pin";
      countryList.forEach((item, index) => {
        hiddenSheet.getCell(`A${index + 5}`).value = item?.Location_Name;
        hiddenSheet.getCell(`B${index + 5}`).value = item?.Start_Pin;
        hiddenSheet.getCell(`C${index + 5}`).value = item?.End_Pin;
      });
    //   hiddenSheet.state = "hidden";
    let typeList = thirdPartyType?.map((item) => item.Options);
      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        worksheet.getCell(`A${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: true,
          formulae: [55],
        };
        worksheet.getCell(`C${row}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: [`"${typeList.join(",")}"`],
            showErrorMessage: true,
            errorTitle: "Invalid",
            error: "Please select from the list",
          };
        worksheet.getCell(`E${row}`).dataValidation = {
            type: 'whole',  // For whole numbers, use 'decimal' for floating numbers
            operator: 'between',
            formulae: ['100000', '999999'],
            showErrorMessage: true,
            errorTitle: 'Invalid Number',
            error: 'Please enter Valid Number 6 digit',
          };
          worksheet.getCell(`G${row}`).dataValidation = {
            type: 'whole',  // For whole numbers, use 'decimal' for floating numbers
            operator: 'between',
            formulae: ['1000000000', '9999999999'],
            showErrorMessage: true,
            errorTitle: 'Invalid Number',
            error: 'Please enter a Valid Number.',
          };
         

      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "ThirdParty_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <CustomButton type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default ThirdPartyMasterUploadSheet;
