import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import assessmentCategoryService from "../services/assessmentCategoryMasterService";
import { IAddAssessmentCategoryResponse, IAssessmentCategoryResponse, IVHColumnRes, IAssessmentCategory } from "../../models/assessmentCategoryMaster.type";
import { chkDate } from "../../utils/constants";

export interface IAssessmentCategoryData {
  dataTable: any;
  parentTable:any;
  isLoading: boolean;
  isAssessmentCategoryAdded: boolean;
  isAssessmentCategoryUpdated: boolean;
  versionHistory:{assessmentCategoryRecords :IAssessmentCategory[] , assessmentCategoryVHeader : IVHColumnRes[]};
  isAssessmentCategoryHistory: boolean;
  isAssessmentCategoryDeleted: boolean;
  ToasterMsg: any;
}

export const initialState: IAssessmentCategoryData = {
  dataTable: [],
  parentTable:[],
  isLoading: false,
  isAssessmentCategoryAdded: false,
  isAssessmentCategoryUpdated: false,
  versionHistory:{assessmentCategoryRecords :[] , assessmentCategoryVHeader : []},
  isAssessmentCategoryHistory: false,
  isAssessmentCategoryDeleted: false,
  ToasterMsg: {Message: "", Status: ""},
};

export const getAssessmentCategoryList = createAsyncThunk(
  "getAssessmentCategoryList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await assessmentCategoryService.getAssessmentCategoryList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getParentAssessmentCategoryList = createAsyncThunk(
  "getParentAssessmentCategoryList",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await assessmentCategoryService.getParentAssessmentCategoryList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAssessmentCategory = createAsyncThunk("addAssessmentCategory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await assessmentCategoryService.addAssessmentCategory(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAssessmentCategory = createAsyncThunk(
  "updateAssessmentCategory",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await assessmentCategoryService.updateAssessmentCategory(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAssessmentCategory = createAsyncThunk(
  "deleteAssessmentCategory",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await assessmentCategoryService.deleteAssessmentCategory(reqbody);
      return response.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssessmentCategoryHistory = createAsyncThunk("getAssessmentCategoryHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await assessmentCategoryService.getVersionHistoryAssessmentCategory(reqbody);
    const {AQCategoryHistories , HeaderJson } =response.data;
    return {AQCategoryHistories:AQCategoryHistories, HeaderJson : HeaderJson.ColumnHeaders}
        
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const assessmentCategoryDataSlice = createSlice({
  name: "assessmentCategoryData",
  initialState,
  reducers: {
    updateAssessmentCategoryAddandEdit: (state, action) => {
      state.isAssessmentCategoryAdded = false;
      state.isAssessmentCategoryUpdated = false;
      state.isAssessmentCategoryDeleted = false;
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.dataTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.dataTable];
        copyVendorData[indexVendor] = action.payload;
        state.dataTable = copyVendorData;
      }
    },
    updateVersionHistory:  (state, action)=>{            
      state.versionHistory.assessmentCategoryRecords = [];
      state.versionHistory.assessmentCategoryVHeader = [];
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentCategoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssessmentCategoryList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");

        state.dataTable = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          return user;
        });
        state.isLoading = false;
      })
      .addCase(getAssessmentCategoryList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getParentAssessmentCategoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParentAssessmentCategoryList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");

        state.parentTable = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          return user;
        });
        state.isLoading = false;
      })
      .addCase(getParentAssessmentCategoryList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addAssessmentCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAssessmentCategory.fulfilled, (state, action: PayloadAction<any>) => {
        // console.log(action.payload , 'action.payload1');
        //     let item = {...action.payload};
        //     let copyallUsers= [...state.dataTable];
        //     copyallUsers.push(item);
        //     state.dataTable = copyallUsers;
        state.isAssessmentCategoryAdded = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(addAssessmentCategory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateAssessmentCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAssessmentCategory.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAssessmentCategoryUpdated = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.IsSuccess;
      })
      .addCase(updateAssessmentCategory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteAssessmentCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAssessmentCategory.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAssessmentCategoryDeleted = true;
        state.isLoading = false;
        // state.ToasterMsg = action.payload.Message;
        state.ToasterMsg.Message = action.payload.Message;
                state.ToasterMsg.Status = action.payload.Status;
      })
      .addCase(deleteAssessmentCategory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })      
      .addCase(getAssessmentCategoryHistory.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getAssessmentCategoryHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.assessmentCategoryVHeader = action.payload.HeaderJson
        state.versionHistory.assessmentCategoryRecords = action.payload.AQCategoryHistories.map((user:any)=>{
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.ApprovedOn = chkDate(user.ApprovedOn);

          return user;
        })
        state.isLoading = false;
        // state.ToasterMsg = "AssessmentCategory data deleted successfully";
      })
      .addCase(getAssessmentCategoryHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

  },
});

export const { updateAssessmentCategoryAddandEdit, updateUser, updateVersionHistory } = assessmentCategoryDataSlice.actions;
export default assessmentCategoryDataSlice.reducer;
