import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { CLAUSE_MASTER_URL } from "./endpoints/clauseMaster";
import { ICaluseMasterTypeResponse, IVHColumnRes } from "../../../models/contractManagementModels/clauseMasterModel";

const getClauseMasterTypeList = (): Promise<ICaluseMasterTypeResponse> =>
  axiosInstance
    .get(CLAUSE_MASTER_URL.GetClauseMaster)
    .then((response: AxiosResponse<ICaluseMasterTypeResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const createClauseTypeMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CLAUSE_MASTER_URL.CreateClauseTypeMaster, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateClauseTypeMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .put(CLAUSE_MASTER_URL.UpdateClauseTypeMaster, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const activeInactiveClauseTypeMaster = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CLAUSE_MASTER_URL.ActiveInActiveClauseTypeMaster, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        console.log(response.data,"DataResponse")
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistory = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(CLAUSE_MASTER_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const clauseMasterService = {
  getClauseMasterTypeList,
  createClauseTypeMaster,
  updateClauseTypeMaster,
  activeInactiveClauseTypeMaster,
  getVersionHistory,
};

export default clauseMasterService;
