import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AqmResponse } from "./aqmResponse/AqmResponse";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/store";
import { useParams } from "react-router-dom";
import { getLogginVendor } from "../store/slices/authorization";
import { updateLoader } from "../store/slices/loader";

export const VendorPortal: React.FC = () => {
    const dispatch = useAppDispatch();

    const { isVendorAuthenticated  , isLoading , vendorLoginDetails } = useSelector((state: RootState) => state.loggerData);
    const { vendor_persional_Id , Project_Details_Id } = useParams();

    useEffect(() => {

        dispatch(updateLoader( isLoading));
    
      }, [ isLoading]);

    useEffect(() => {
        const fetchVendorData = async () => {
          if (vendor_persional_Id) {
            try {
              const data = await dispatch(
                getLogginVendor({ Vendor_Id: vendor_persional_Id })
              )
      
             
            } catch (error) {
              console.error("Error fetching vendor data:", error);
            }
          }
        };
      
        fetchVendorData();
      }, [vendor_persional_Id]);

      console.log(vendorLoginDetails , 'vendorLoginDetails');
      
     return(
        <>
        <Box sx={{p:2 , overflowY:'scroll' , height:'100vh'  }} >
           {isVendorAuthenticated && vendorLoginDetails.vendorId  && <AqmResponse/> }
        </Box>
        </>
     )
}