import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {
  deleteLocation,
  getLocationHistory,
  getLocationList,
  updateLocationAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/locationMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { Edit_Enable_tosterMSG, LovConfig } from "../../../config/config";
import { getLovList } from "../../../store/slices/lovMasterData";
import { HandleRecordLockCheck, recordLockGet } from "../../recordLock/RecordLock";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { getClauseTypeList } from "../../../store/contractManagement/slices/clauseDataSlice";
import clauseService from "../../../store/contractManagement/services/clauseService";

interface AddExistingClauseProps {
  onClose?: () => void;
  onAdd?: (data: any) => void;
  pluginWindow?: boolean;
}

export default function AddExistingClause({ onClose, onAdd, pluginWindow = false }: AddExistingClauseProps) {
  const dispatch = useAppDispatch();
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [LocationData, setLocationData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [selectedValue, setselectedValue] = useState(null);
  const [FilterData, setFilterData] = useState<any[]>([]);
  const [versionHistoryListData, setversionHistoryListData] = useState<any[]>([]);
  const [filteredLovTableLocationType, setFilteredLovTableLocationType] = useState<any[]>([]);
  const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const [clauseDetails, setClauseDetails] = useState<any[]>([]);
  const [selectedClauseType, setSelectedClauseType] = useState<any[]>([]);
  const [filteredClause, setFilteredClause] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isClauseAddedPlugin, setIsClauseAddedPlugin] = useState<any>(false);

  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    clauseDetailsData,
    isClauseDetailsLoading,
    isClauseDetailsAdded,
    clauseToasterStatus,
    isClauseDetailsUpdated,
    isClauseDetailsDeleted,
    clauseToasterMsg,
    clauseTypeList,
  }: any = contractManagementReducer.clauseData;

  useEffect(() => {
    setFilteredLovTableLocationType(lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type));
  }, [lovTable]);
  const columns_version: any = {
    table_name: "Location History Records",
    isAction: false,
    columns: [],
  };

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const { locationTable, islocationAdded, locationVersionHistory, islocationDeleted, ToasterMsg, isLoading } =
    useSelector((state: RootState) => state.locationMasterData);

  const allOption = {
    LovId: 0,
    Options: "All",
  };

  const modifiedLovNames = [allOption, ...filteredLovTableLocationType];

  // const columns: any = {
  //   table_name: "Location",
  //   isAction: true,
  //   columns: [
  //     // {
  //     //     Field: "LocationId",
  //     //     DisplayName: "Location Id",
  //     //     FieldType: "string",
  //     // },

  //     {
  //       Field: "Location_Name",
  //       DisplayName: "Location Name",
  //       FieldType: "string",
  //     },
  //     {
  //       Field: "LocationTypeValue",
  //       DisplayName: "Location Type",
  //       FieldType: "string",
  //     },
  //     {
  //       Field: "ParentLocationName",
  //       DisplayName: "Parent",
  //       FieldType: "string",
  //     },
  //     {
  //       Field: "Start_Pin",
  //       DisplayName: "Start PIN",
  //       FieldType: "string",
  //     },
  //     {
  //       Field: "End_Pin",
  //       DisplayName: "End PIN",
  //       FieldType: "string",
  //     },
  //     {
  //       Field: "RegionIdValue",
  //       DisplayName: "Region",
  //       FieldType: "string",
  //     },
  //     {
  //       Field: "ZoneIdValue",
  //       DisplayName: "Zone",
  //       FieldType: "string",
  //     },

  //     {
  //       Field: "IsActive",
  //       DisplayName: "Status",
  //       FieldType: "string",
  //     },
  //     {
  //       Field: "Updated_By",
  //       DisplayName: "Modified By",
  //       FieldType: "string",
  //     },
  //   ],
  // };

  const columns = {
    table_name: "Clause List",
    isAction: false,
    columns: [
      {
        Field: "clause_Id",
        FieldType: "string",
        DisplayName: "ID",
      },
      {
        Field: "clause_type",
        FieldType: "string",
        DisplayName: "Clause Type",
      },
      {
        Field: "clause_header",
        FieldType: "string",
        DisplayName: "Clause Header",
      },
      // {
      //   Field: "clause",
      //   FieldType: "string",
      //   DisplayName: "Clause",
      // },
      {
        Field: "services",
        FieldType: "string",
        DisplayName: "Services",
      },
      {
        Field: "sme",
        FieldType: "string",
        DisplayName: "SME/Department",
      },
      {
        Field: "status",
        FieldType: "string",
        DisplayName: "Status",
      },
    ],
  };

  useEffect(() => {
    // dispatch(
    //   getLocationList({
    //     FormID: 2,
    //   })
    // );
    dispatch(getLovList({ Name: "" }));
    dispatch(getClauseTypeList());
    loadClauseList();
  }, []);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const loadClauseList = async () => {
    const data: any = await clauseService.getClauseDetailsList({});
    setClauseDetails(data.clauseList);
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Location",
      Activity: "edit",
    };
    return recordLockData;
  };

  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      const data = {
        RecordlockId: recordLock[0].RecordlockId,
        RecordId: recordLock[0].RecordId,
        IsActive: false,
      };
      dispatch(UpdateRecordLock(data));
    }
    return recordLock;
  };

  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {
      console.log(data, Option, "Option");
      //   setOption(Option);
      //   setIsEditData(data);
      //   setIsEdit(true);
      setIsEditData(data);
      // setIsEdit(true);
      setOption(Option);
      dispatch(updateLoader(true));

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Location_Id, FormID: 9 });
      const Edit_data: any = response;

      const canEdit = await HandleRecordLockCheck(data?.Location_Id, permssionPage, "Location Master", dispatch);
      if (Edit_data?.data === true) {
        if (canEdit) {
          setIsEdit(true);
          const dispatchData = handleAddRecordLock(data?.Location_Id);
          await dispatch(AddRecordLock(dispatchData));
          //setIsEditOpen(true);
          const resultAction = await dispatch(
            GetRecordLock(recordLockGet(data?.Location_Id, permssionPage, "Location Master"))
          );
          if (GetRecordLock.fulfilled.match(resultAction)) {
            const recordLockData = resultAction.payload;
            console.log(recordLockData, resultAction, isEditing, "recordLockData");
            setGetRecordLock(recordLockData);
          }
          dispatch(updateLoader(false));
          //  Set timeout to close the popup after 2.5 minutes
          //  setTimeout(() => {
          //   setIsEdit(false);
          //   const data = recordLockUpdate();
          //   if (data.length > 0) {
          //     dispatch(UpdateRecordLock(data.pop()));
          //   }
          // }, 150000);
        }
      } else {
        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      }

      // setUserId(data.UserId);
    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    }
    // else if (Option === "history") {
    //   setOption(Option);
    //   dispatch(
    //     getLocationHistory({
    //       LocationId: data.Location_Id,
    //       formID: 9,
    //       // Approved_By: ,
    //     })
    //   );
    //   // setIslocationVersionHistory(true);
    // }
    else if (Option === "history") {
      setIsVersionHistory(true);
      // setIsEdit(true);
      setOption(Option);
      setIsEditData(data);
      dispatch(
        getLocationHistory({
          LocationId: data.Location_Id,
          formID: 9,
          // Approved_By: ,
        })
      );
    } else if (Option === "delete") {
      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Location_Id, FormID: 9 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        setOption(Option);
        setIsDeleteData(data);
      } else {
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
      }
    }
  };

  useEffect(() => {
    if (islocationAdded) {
      dispatch(updateLocationAddandEdit(false));
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      dispatch(
        getLocationList({
          LocationTypeId: selectedValue,
        })
      );
    } else if (islocationDeleted) {
      setIsDeleteData(null);
      dispatch(
        updateToaster({
          isToaster: true,
          toasterMsg: ToasterMsg.Message,
          isTosterFailed: ToasterMsg.Status === "false" ? true : false,
        })
      );
      setTimeout(() => {
        dispatch(updateLocationAddandEdit(false));
      }, 1000);
      dispatch(
        getLocationList({
          LocationTypeId: selectedValue,
        })
      );
    }
  }, [islocationAdded, islocationDeleted]);

  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(Object.entries(data).length > 0, Object.entries(data), "data");

      if (Object.entries(data).length > 0) {
        // Show the confirmation dialog
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";

        // Handle the case when the user confirms to leave (reload the page)
        const handleUnload = async () => {
          try {
            await dispatch(UpdateRecordLock(data));
          } catch (error) {
            console.error("Failed to update record lock:", error);
          }
        };

        window.addEventListener("unload", handleUnload);
      }
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const handleChange = (event: any) => {
    setselectedValue(event);
  };

  const handleServerFilter = () => {
    if (selectedClauseType.length) {
      let filteredData = clauseDetails
        .filter((data: any) => selectedClauseType.includes(data?.clauseType?.clauseType_Id))
        .map((i: any) => {
          return {
            clause_Id: i?.clause_Id,
            clause_type: i?.clauseType?.clauseType_Name,
            clause_header: i?.clause_Header,
            services:
              i?.serviceMappings?.length > 0
                ? i?.serviceMappings?.map((item: any) => item.services.service_Name).join(",")
                : "",
            sme: i?.department?.departmentName,
          };
        });
      setFilteredClause(filteredData);
    }
  };

  const handleRowSelection = (data: any) => {
    setSelectedRows(data);
  };

  const handleAddClause = () => {
    const tempSelectedRow = selectedRows.flatMap((e: any) => e.clause_Id);
    if (pluginWindow) {
     

      window?.parent?.postMessage(
        JSON.stringify(clauseDetails.filter((data: any) => tempSelectedRow.includes(data.clause_Id))),
        "*"
      );

      localStorage.setItem("isExistingClauseAdded", JSON.stringify("true"));
      setIsClauseAddedPlugin(true);
    } else {
      if (onAdd) {
        onAdd(clauseDetails.filter((data: any) => tempSelectedRow.includes(data.clause_Id)));
      }
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {!isClauseAddedPlugin ? (
        <Box
          sx={{
            width: "100%",
            // backgroundColor: "#ffff",
            // backgroundColor: "background.default",
            // px: 3,
            // py: 4,
            // borderRadius: "10px",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                // flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                {" "}
                {currentPagePermissionData?.MenuName}
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", width: "250px" }}>
                  {/* <CustomSelect
                name={"Location_TypeId"}
                valueKey={"LovId"}
                optionLabelKey="Options"
                options={modifiedLovNames}
                value={selectedValue}
                onChange={handleChange}
                disabled={false}
                required={true}
                label={"Clause Type"}
              /> */}
                  <CustomMultiSelect
                    optionLabelKey={`clauseType_Name`}
                    optionIdKey={`clauseType_Id`}
                    options={clauseTypeList}
                    readOnly={false}
                    label={"Clause type"}
                    value={selectedClauseType}
                    onChange={(field: any) => {
                      setSelectedClauseType(field.target.value);
                    }}
                    error={false}
                    helperText={""}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
                </Box>
              </Grid>
            </Box>
            <Box>
              {/* {!formDisplay ? (
              ""
            ) : ( */}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <MaterialDynamicGrid
                  data={columns}
                  rows={filteredClause}
                  handleAction={handleOpen}
                  // handleAdd={() => setAddFormcloseoropen(true)}
                  enableZoomIcon={true}
                  showhistory={false}
                  isDraftView={false}
                  // draftCount={clauseDetailsDraftData.length}
                  isEnableDraft={false}
                  // handleIsDraft={(val: any) => setisShowDraft(val)}
                  setSelectedQuestions={handleRowSelection}
                  showAdd={false}
                  showDelete={false}
                  showView={false}
                  showEdit={false}
                  showExports={true}
                  userId={userLoginResponse?.UserId || 0}
                  pageName="Clause Master"
                />
              </Box>
              {/* )} */}
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            />
            {/* <CustomButton variant="outlined" disabled name={"Save Draft"} onClick={() => placeHolder()} /> */}
            <CustomButton variant="contained" name="Add" onClick={handleAddClause} />
          </Stack>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            // backgroundColor: "#ffff",
            // backgroundColor: "background.default",
            // px: 3,
            // py: 4,
            // borderRadius: "10px",
          }}
        >
          <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p style={{ textTransform: "capitalize", height: "100%", display: "contents", fontSize: "28px" }}>
              Clause Has been Updated Successfully, please close the window to see the changes.
            </p>
          </Box>
        </Box>
      )}
    </Box>
  );
}
