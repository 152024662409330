import { Box, Stack } from "@mui/material"
import CustomStepper from "../../common/dynamicSteper/CustomStepper"
import { useState } from "react";
import { ApplicationParrameters } from "../applicationParameters/ApplicationParameters";
import { CompanyMaster } from "../companyMaster/CompanyMaster";
import { RegionalSettings } from "../master/regionalSettings/RegionalSettings";
import { BranchMaster } from "../master/branchMaster/BranchMaster";
import { ThirdPartyMaster } from "../master/thirdPartyMaster/ThirdPartyMaster";
import { CompanyMasterMain } from "../companyMaster/CompanyMasterMain";



export default function MasterMain() {

    const [activestepCount, setactivestepCount] = useState(0);

    const main_steps = ['Company Master', 'Branch Master', 'Application Parameter', 'Third Party Master', 'Regional Settings']

    const handleNext = () => {
        setactivestepCount(prevCount => prevCount + 1);
        console.log(activestepCount, "activestepCount");

    };
    const handlePrevious = () => {
        setactivestepCount(prevCount => prevCount - 1);
    };

    return (


        <Box sx={{ width: "100%", paddingTop: 2 }}>
            <Stack spacing={6}>
                <CustomStepper activeStep={activestepCount} steps={main_steps} />

                <Box
                    sx={{
                        mt: 2
                    }}
                >
                    {activestepCount == 0 &&
                        // < CompanyMaster
                        //     handlePrevious={handlePrevious}
                        //     handleNext={handleNext}
                        // />

                        < CompanyMasterMain
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                        />
                    }

                    {activestepCount == 1 &&
                        < BranchMaster
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                        />
                    }

                    {activestepCount == 2 &&
                        < ApplicationParrameters
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                        />
                    }

                    {activestepCount == 3 &&
                        < ThirdPartyMaster
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                        />
                    }

                    {activestepCount == 4 &&
                        < RegionalSettings
                            handlePrevious={handlePrevious}
                            handleNext={handleNext}
                        />
                    }

                </Box>
            </Stack>
        </Box>
    )
}