import React, { useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import styles from "./sandClock.module.scss";
import Loader from "./sandclock.svg";

const SandClockLoader = () => {
  const { isLoading } = useSelector((state: RootState) => state.appLoader);
  const [open, setOpen] = useState(false); 

  useEffect(() => {
    setOpen(isLoading);
  }, [isLoading]);


  // useEffect(() => {
  //   setOpen(true);
  // }, []);

  return (
    <Backdrop
      sx={{ color: "#fff",backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: (theme) => theme.zIndex.snackbar + 1 }}
      open={open}
    >
      <div className={styles.sandClockContainer}>
        <img
          src={Loader}
          alt="Sand Clock"
          className={styles.sandClock}
          height="50px"
          width="50px"
        />
      </div>
    </Backdrop>
  );
};

export default SandClockLoader;
