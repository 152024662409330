import { AxiosError, AxiosResponse } from "axios";

import { axiosInstance } from "./config/axiosConfig";
import { VENDORSIGNOFF_URL } from "./endpoints/vendorSignOff";

const getVendorList = (requestParams: any): Promise<any> =>
  axiosInstance
    .get(VENDORSIGNOFF_URL.getSignOff, {
      params: requestParams,
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addVendor = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(VENDORSIGNOFF_URL.AddsignOff, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
    const vendorSignOff = {
        getVendorList,
        addVendor,
       
      };
      
      export default vendorSignOff;