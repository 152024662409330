import { Box, Divider, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomSvg from "../../../common/CustomSvg";
import gridStyle from "../../../common/dynamicDataGrid/dynamicgrid.module.scss";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
interface AAddAssessmentQuestionnaireforCOBProps {
  isEditData?: any;
  view?: boolean;
  onclicksave?: (row: any) => void;
  onclose?: (data: any) => void;
  SMEDepartmentsListdata: any;
}

interface Response {
    
     Attribute_Response?: string;
     IsActive?: boolean;
     Assessment_Questionaire_Id?: any;
     Assessment_Questionnaire_OptionId?: any;
     Id?: any;
     Status?: any;
  }

interface FormData {
  Assessment_Questionaire_Id?: any;
  Assessment_Questionnaire_Mapping_Id?: any;
  Answer_Type?: any;
  Question?: any;
  assessmentQuestionnaireOptions?: Response[];
  EvidenceRequired?: any;
  IsSME?: any;
  SME?: any;

}

export const AAddAssessmentQuestionnaireforCOB: React.FC<AAddAssessmentQuestionnaireforCOBProps> = ({
  isEditData,
  view,
  onclicksave,
  onclose,
  SMEDepartmentsListdata
}) => {
  const theme = useTheme();

  const [QuestionListData, setQuestionListData] = useState<any[]>([]);
  const [SMEDepartmentsList, setSMEDepartmentsList] = useState<any[]>([]);
  const [answerType, setanswerType] = useState<any[]>([
    { value: "multiple", lable: "Multiple Choice" },
    { value: "text", lable: "Free Text" },
  ]);

  let formData = {
    Assessment_Questionaire_Id: isEditData ? isEditData?.Assessment_Questionaire_Id : 0,
    Assessment_Questionnaire_Mapping_Id: isEditData ? isEditData?.Assessment_Questionnaire_Mapping_Id : 0,
    Question: isEditData ? isEditData?.Question : "",
    Answer_Type: isEditData ? isEditData?.Answer_Type : "",
    assessmentQuestionnaireOptions: isEditData ? isEditData?.assessmentQuestionnaireOptions.map((item:any , index:any) => ({...item , Id :index+1})) : [],
    EvidenceRequired: isEditData ? isEditData?.EvidenceRequired : "",
    IsSME: isEditData ? isEditData?.IsSME : "",
    SME: isEditData ? isEditData?.SME : "",
  };
  const {
    handleSubmit: handleAQMForm,
    reset,
    setValue,
    formState: { errors: errorsAQMform, isDirty },
    control,
    watch,
    getValues,
    clearErrors,
    trigger,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "assessmentQuestionnaireOptions",
  });

  useEffect(() => {
    if(SMEDepartmentsListdata){
      setSMEDepartmentsList(SMEDepartmentsListdata)
    }
  },[SMEDepartmentsListdata])

  const addAtributes = () => {
    const dubAttributeResponse = getValues("assessmentQuestionnaireOptions");
    if ((dubAttributeResponse && dubAttributeResponse?.length <= 4) || !dubAttributeResponse) {

        let Id = 1;
  
        if (dubAttributeResponse && dubAttributeResponse.length > 0) {
          Id = dubAttributeResponse.length + 1;
          dubAttributeResponse.map((item : any) => {
            if (item.Id == Id) {
              Id = Id + 1;
            }
        })
    }
    
        append({
            Attribute_Response : "" ,
            Assessment_Questionaire_Id: 0,
            Assessment_Questionnaire_OptionId: 0,
            IsActive: true,
            Id: Id,
            Status: 0,
          });

    
}
  }

  const deleteAttributes = (index: number) => {
    const attributeResponse = getValues("assessmentQuestionnaireOptions");
    if (attributeResponse && attributeResponse.length > index) {
      const updatedAttributeResponse = attributeResponse.slice();
      console.log(updatedAttributeResponse , 'updatedAttributeResponse 1');
      
      updatedAttributeResponse.splice(index, 1);
      console.log(updatedAttributeResponse , 'updatedAttributeResponse 2');
    //   clearErrors(`assessmentQuestionnaireOptions.${index}.Attribute_Response`);

      setValue("assessmentQuestionnaireOptions", updatedAttributeResponse, { shouldDirty: true, shouldValidate: true });
      trigger("assessmentQuestionnaireOptions");
    }
  };

  const clearOption = () => {
    setValue("assessmentQuestionnaireOptions", [], { shouldDirty: true, shouldValidate: true });
    trigger("assessmentQuestionnaireOptions");
  }

  const handlesubmitForm = (data: any) => {
    if (onclicksave) {
          onclicksave(data);
    }
  };
  return (
    <>
      <Stack spacing={3}>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <Controller
                name="Answer_Type"
                control={control}
                defaultValue=""
                rules={{ required: "Please specify the Question Type" }}
                render={({ field }) => (
                  <CustomSelect
                    name={"Answer_Type"}
                    valueKey={"value"}
                    optionLabelKey="lable"
                    options={answerType}
                    readOnly={view}
                    required={true}
                    label={"Question Type"}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      clearOption()} }
                    error={Boolean(errorsAQMform.Answer_Type)}
                    helperText={errorsAQMform.Answer_Type && errorsAQMform.Answer_Type.message?.toString()}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Controller
            name="Question"
            control={control}
            defaultValue={""}
            rules={{
              required: "Please provide a Question",
              validate: {
                maxLength: (value) => {
                  if (value === undefined) return "Please provide a Question";
                  return value.length <= 250 || "Maximum 250 characters allowed";
                },
              },
            }}
            render={({ field }) => (
              <CustomMultilineTextField
                label={"Question"}
                value={field.value}
                readOnly={view}
                rows={4}
                required={true}
                onChange={field.onChange}
                error={Boolean(errorsAQMform.Question)}
                helperText={errorsAQMform.Question && errorsAQMform.Question.message?.toString()}
              />
            )}
          />
        </Box>

        {watch('Answer_Type') == "multiple"  &&  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Options{" "}
          </Typography>
          <Tooltip title={`Add  Options`}>
            <Box className={gridStyle.icon_box}>
              <IconButton
                onClick={() => {
                  addAtributes();
                  clearErrors();
                  // trigger("assessmentQuestionaireRatings");
                  clearErrors(`assessmentQuestionnaireOptions.${fields.length + 1}`);
                }}
              >
                <CustomSvg name={"plus"} color={`${theme.palette.primary.main}`} size={20} />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>}

      
      {watch('Answer_Type') == "multiple"  && <Box>
          <Grid container spacing={2} >
            {fields.map((row, index) => {
              return (
                <Grid
                  key={row.Id}
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                    <Box  key={`optionsofQuestion_box${index + 1}`} sx={{display:'flex' ,width :'100%'  ,height :'100%'  ,
                                          justifyContent: "start",
                                          flexDirection: "row"}}>

                   
                  <Controller
                    name={`assessmentQuestionnaireOptions.${index}.Attribute_Response`}
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Choice Field is Required",
                      validate: (data: string | undefined) => {
                        if (data === undefined || data.trim() === "") {
                          return "Choice Field is Required";
                        }
                        let allFields = watch("assessmentQuestionnaireOptions") || [];
                        const choices = allFields.map((field: any) => field?.Attribute_Response?.trim() || "");
                        const duplicateChoices = choices.filter((choice: any) => choice === data.trim());

                        if (duplicateChoices.length > 1) {
                          return "Duplicate Choices are not allowed.";
                        }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={`Option ${index + 1}`}
                        required={true}
                        readOnly={view}
                        label={`Option ${index + 1}`}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("assessmentQuestionnaireOptions");
                        }}
                        error={Boolean(
                            errorsAQMform?.assessmentQuestionnaireOptions &&
                              errorsAQMform?.assessmentQuestionnaireOptions[index]
                                ?.Attribute_Response
                          )}
                          helperText={
                            errorsAQMform?.assessmentQuestionnaireOptions &&
                            errorsAQMform?.assessmentQuestionnaireOptions[index]
                              ?.Attribute_Response &&
                            errorsAQMform?.assessmentQuestionnaireOptions[
                              index
                            ]?.Attribute_Response?.message?.toString()
                          }
                      />
                    )}
                  />
                   <IconButton key={`attributebtn_${index+1}`}
                                         onClick={() => {
                                            deleteAttributes(index);
                                          }}
                                        >
                <CustomSvg name={"trash"} size={20} />
                     </IconButton>
                     </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box> }

        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              {" "}
              <Controller
                name="EvidenceRequired"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CustomCheckBox
                    label="Evidence Required"
                    isStyle={true}
                    readOnly={view}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              {" "}
              <Controller
                name="IsSME"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CustomCheckBox
                    label="SME to Respond"
                    isStyle={true}
                    readOnly={view}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                       setValue("SME" , "")}
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        {watch("IsSME") ? (
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="SME"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Please provide the SME" }}
                  render={({ field }) => (
                    <CustomSelect
                      name={"SME"}
                      valueKey={"DepartmentId"}
                      optionLabelKey="DepartmentName"
                      options={SMEDepartmentsList}
                      readOnly={view}
                      required={true}
                      label={"SME Department"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsAQMform.SME)}
                      helperText={errorsAQMform.SME && errorsAQMform.SME.message?.toString()}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}

        <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent : 'flex-end' , gap: 2 , width :'100%' }}>
          <CustomButton
            type="reset"
            variant="outlined"
            name={"Discard"}
            onClick={() => {
                onclose &&
                onclose(false) 
            }}
          />
          <CustomButton type="reset" disabled={!isDirty} name={"Save Question"} onClick={handleAQMForm(handlesubmitForm)} />
        </Box>
      </Stack>
    </>
  );
};
