import { Box, Button, Chip, Step, StepConnector, StepIconProps, StepLabel, Stepper, Typography, stepConnectorClasses } from '@mui/material';
import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface CustomDialogProps {
    steps : string [] ,
    activeStep : number ,
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        // borderColor: theme.palette.grey[400],
        // borderStyle: 'solid',
        borderTop :`2px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#cccccc'}`
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        // borderColor: theme.palette.grey[400],
        // borderStyle: 'solid',
        borderTop :`2px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#cccccc'}`
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      
    //   borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    //   borderTopWidth: 0.2,
    //   borderRadius: 1,
    //   borderStyle: 'dashed',
      borderTop :`2px dashed ${theme.palette.mode === 'dark' ? theme.palette.grey[600] : '#cccccc'}`
    },
  }));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
     
      '& .QontoStepIcon-completedIcon': {
        color: '#129507',
        zIndex: 1,
        fontSize: 26,
      },
      '& .QontoStepIcon-circle': {
        width: 22,
        height: 22,
        borderRadius: '50%',
        backgroundColor:  theme.palette.primary.light,
        position: 'relative',
        opacity : ownerState.active ? 1 : 0.6
        
      },
      '& .QontoStepIcon-circle-center': {
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor:  theme.palette.primary.main,
        position : 'absolute',
        left: 4,
        top:4 ,
        opacity : ownerState.active ? 1 : 0.6
        
      },
      
    }),
  );

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckCircleIcon className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" >

              <div className="QontoStepIcon-circle-center">
                 
              </div>
          </div>
        )}
      </QontoStepIconRoot>
    );
  }
const CustomStepper: React.FC<CustomDialogProps> = (props) => {
  const {
    steps = [],
    activeStep = 0 ,
  
  } = props; 

 

  return (
    <>

    <Box sx={{ width: '100%' }}>
      <Stepper  activeStep={activeStep} alternativeLabel connector={<QontoConnector />} >
        {steps && steps.map((label : any , index : any ) => (
          <Step key={`${label}- ${index + 1} - horizontal stepper`}>
             <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>

  
    </>
  )

}

export default CustomStepper;