import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { EMAILTEMPLATE_URL } from "./endpoints/emailtemplate";
import { IAddEmailTemplateResponse, IEmailTemplateResponse } from "../../models/authorize.type";
import { axiosInstance } from "./config/axiosConfig";


const getEmailTemplateList = (): Promise<IEmailTemplateResponse> =>
  axiosInstance
    .get(EMAILTEMPLATE_URL.GetEmailTemplateList)
    .then((response: AxiosResponse<IEmailTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });


const addEmailTemplate = (requestBody: any): Promise<IAddEmailTemplateResponse> =>

  axiosInstance
    .post(EMAILTEMPLATE_URL.AddEmailTemplate, requestBody)
    .then((response: AxiosResponse<IAddEmailTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateEmailTemplate = (requestBody: any): Promise<IAddEmailTemplateResponse> =>
  axiosInstance
    .post(EMAILTEMPLATE_URL.UpdateEmailTemplate, requestBody)
    .then((response: AxiosResponse<IAddEmailTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getAllMenuModulesList = (): Promise<IEmailTemplateResponse> =>
  axiosInstance
    .get(EMAILTEMPLATE_URL.GetAllMenuModulesList)
    .then((response: AxiosResponse<IEmailTemplateResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
    const DeleteEmailTemplete = (requestBody: any): Promise<IAddEmailTemplateResponse> =>
      axiosInstance
        .post(EMAILTEMPLATE_URL.DeleteEmailTemplete, requestBody)
        .then((response: AxiosResponse<IAddEmailTemplateResponse>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });
    
const emailTemplateService = {
  getEmailTemplateList,
  addEmailTemplate,
  updateEmailTemplate,
  getAllMenuModulesList,
  DeleteEmailTemplete
};

export default emailTemplateService;
