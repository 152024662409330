import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { LOCATION_URL } from "./endpoints/commonMasters";
import { IAddLocationResponse, ILocationResponse, IParentLocationResponse, IVHColumnRes } from "../../models/location.type";

const getLocationList = (reqparams: any): Promise<ILocationResponse> =>
  axiosInstance
    .get(LOCATION_URL.GetLocation, {params:reqparams})
    .then((response: AxiosResponse<ILocationResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const getParentLocationList = (reqparams: any): Promise<IParentLocationResponse> =>
    axiosInstance
        .get(LOCATION_URL.GetParentLocation, {params:reqparams})
        .then((response: AxiosResponse<IParentLocationResponse>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const addEditLocation = (requestBody: any): Promise<IAddLocationResponse> =>
  axiosInstance
    .post(LOCATION_URL.AddEditLocation, requestBody)
    .then((response: AxiosResponse<IAddLocationResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteLocation = (requestBody: any): Promise<IAddLocationResponse> =>
  axiosInstance
    .post(LOCATION_URL.DeleteLocation, requestBody)
    .then((response: AxiosResponse<IAddLocationResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryLocation = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(LOCATION_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const locationMasterService = {
    getLocationList,
    addEditLocation,
    deleteLocation,
    getParentLocationList,
    getVersionHistoryLocation,
  
};

export default locationMasterService;
