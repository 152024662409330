import React, { useEffect, useState } from 'react'
import { ContentNameConfig, MasterBulkUpload } from '../../../../config/config';
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import CustomButton from '../../../../common/dynamicButton/CustomButton';
import CustomSvg from '../../../../common/CustomSvg';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store/store';
import { getContentForm } from '../../../../store/slices/contentMasterData';
export const AQMasterCOBImportdata: any = [
  {
    Field: "SrNo",
    FieldType: "string",
    DisplayName: "SrNo",
    config: {
      required: true,
    },
  },{
    Field: "question_type",
    FieldType: "string",
    DisplayName: "Answer Type",
    config: {
      required: true,
    },
  },
  {
    Field: "question",
    FieldType: "string",
    DisplayName: "Question",
    config: {
      required: true,
    },
  },
  {
    Field: "choices_Count",
    FieldType: "array",
    DisplayName: "Choices Count",
    config: {
      required: false,
      info: "Required if question type is 'Multiple Choice'",
    },
  },
  {
    Field: "choices",
    FieldType: "array",
    DisplayName: "Choices (Comma Seperated)",
    config: {
      required: false,
      info: "Required if question type is 'Multiple Choice'",
    },
  },

  {
    Field: "evidence_required",
    FieldType: "boolean",
    DisplayName: "Evidence Required",
    config: {
      required: false,
      default: false,
    },
  },
  {
    Field: "Is_SME_respond",
    FieldType: "boolean",
    DisplayName: "Is SME Respond",
    config: {
      required: false,
      default: false,
    },
  },
  {
    Field: "department",
    FieldType: "string",
    DisplayName: "Department",
    config: {
      required: true,
    },
  }

];

interface BulkUploadQuestionCOBTemplateProps {
  DepartmentListdata : any[]
}

export const BulkUploadQuestionCOBTemplate : React.FC <BulkUploadQuestionCOBTemplateProps> = ({DepartmentListdata}) => {
  const { vendorConentPurposes } = useSelector(
    (state: RootState) => state.contentData
  );
  const dispatch = useAppDispatch();
  const [question_typeList, setquestion_typeList] = useState<any[]>(["Multiple Choice", "Free Text"]);
  const [checkbokvalue, setcheckbokvalue] = useState<any[]>(["Yes", "No"]);
  const [DepartmentList, setDepartmentList] = useState<any[]>([]);
  const [Note, setNote] = useState<any>("");

  useEffect(() => {
   if(DepartmentListdata){
    setDepartmentList(DepartmentListdata);
   }
  },[DepartmentListdata])

  useEffect(() => {
    const consentPurposesArray = [ContentNameConfig.cobQuestionSheetNote];

    // Construct the request body
    const queryString = consentPurposesArray.reduce((acc, purpose, index) => {
      acc[`consentPurposes[${index}]`] = purpose;
      return acc;
    }, {} as Record<string, string>);
    dispatch(getContentForm(queryString));
  }, []);
  function extractContent(htmlString: string): string {
    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string into a document
    const doc = parser.parseFromString(htmlString, "text/html");

    // Extract and return the text content
    return doc.body.textContent || "";
  }
  useEffect(() => {
    console.log(vendorConentPurposes);
    
    vendorConentPurposes.map((item: any) => {
      if (item.Content_Purpose === ContentNameConfig.cobQuestionSheetNote) {
        let notes = item.Content_Text;
        setNote( extractContent(notes));
      }
   
    });
  }, [vendorConentPurposes]);
    const generateExcelWorkbook = async () => {
      console.log('Download sheet');
      
        try {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Sheet 1");
          const hiddenSheet = workbook.addWorksheet("HiddenSheet"); // Hidden sheet for large data lists
          let sheet_Column: any = AQMasterCOBImportdata.map((col_item: any , index : any) => {
            return { header: col_item.DisplayName, key: col_item.Field, width: index == 0 ? 90 : 30 };
          });
          worksheet.columns = sheet_Column;

          worksheet.addRow(["", "", "", "", ""]);
    
          let DepartmentListDataSheet = DepartmentList?.map((item: any) => item.DepartmentName);

          if (DepartmentListDataSheet.length > 0) {
            DepartmentListDataSheet.forEach((item, index) => {
              hiddenSheet.getCell(`A${index + 1}`).value = item;
            });
          }
          worksheet.getCell(`A6`).value = `${Note}`;
          worksheet.getCell("A6").alignment = { wrapText: true };
          hiddenSheet.state = "hidden"; // Hide the sheet
       
          for (let row = 2; row <= 2 ; row++) {
     
            worksheet.getCell(`B${row}`).dataValidation = {
              type: "list",
              allowBlank: false,
              formulae: [`"${question_typeList.join(",")}"`],
              errorTitle: "Invalid Evidence Required",
              error: "Please select a Evidence Required from the list",
            };
 
            worksheet.getCell(`F${row}`).dataValidation = {
              type: "list",
              allowBlank: false,
              formulae: [`"${checkbokvalue.join(",")}"`],
              errorTitle: "Invalid Evidence Required",
              error: "Please select a Evidence Required from the list",
            };
           

            worksheet.getCell(`G${row}`).dataValidation = {
              type: "list",
              allowBlank: false,
              formulae: [`"${checkbokvalue.join(",")}"`],
              errorTitle: "Invalid SME verification is needed",
              error: "Please select a SME verification is needed from the list",
            };
    
            if (DepartmentListDataSheet.length > 0) {
              worksheet.getCell(`H${row}`).dataValidation = {
                type: "list",
                allowBlank: true,
                formulae: [`HiddenSheet!$A$1:$A$${DepartmentListDataSheet.length}`],
              };
            }
          console.log('down 1');
        }
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          FileSaver.saveAs(blob, "Assessment_Questionnaire_bulk_upload_template.xlsx");
          console.log('down 2');
     
       } catch (error) {
        console.log('down err');
          console.error("Error generating Excel:", error);
        }
      
      }
      
     return(
        <>
            <CustomButton
                type="reset"
                variant="text"
                startIcon={<CustomSvg  size={"16px"} name={"export"} />}
                name={"Download Template"}
                onClick={() => generateExcelWorkbook()}
              />
        </>
     )
}