import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { textFieldWidth } from "../../../utils/constants";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import ProgressBar from "../../../common/progressBar/progressBar";
import { getLovList } from "../../../store/slices/lovMasterData";
import {
  addEditLocation,
  getLocationList,
  getParentLocationList,
  updateLocationAddandEdit,
} from "../../../store/slices/locationMasterData";
import { LocationConfig, LovConfig } from "../../../config/config";
import { updateLoader } from "../../../store/slices/loader";

interface FormData {
  Location_Id?: number;
  Location_TypeId: string;
  Location_Name: string;
  ParentLocation_Id: string;
  ZoneId: string;
  RegionId: string;
  Start_Pin: string;
  End_Pin: string;
  IsActive: string;
}

export interface LocationProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  isEditData?: any;
}

export const AddLocation: React.FC<LocationProps> = ({ view, closePopUp, edit, isEditData }) => {
  const dispatch = useAppDispatchThunk();
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const [message, setMessage] = useState<boolean>(false);
  const [ParentLocationData, setParentLocationData] = useState<any[]>([]);
  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  console.log(lovTable, "lovTable");
  const filteredLovTableLocationType = lovTable.filter((item: any) => item.Name === LovConfig.Location_Type);
  const filteredLovTableZone = lovTable.filter((item: any) => item.Name === LovConfig.Zone);
  const filteredLovTableRegion = lovTable.filter((item: any) => item.Name === LovConfig.Region);

  const { locationTable, ParentLocationTable, islocationAdded, islocationDeleted, ToasterMsg, isLoading } = useSelector(
    (state: RootState) => state.locationMasterData
  );

  useEffect(() => {
    console.log(ParentLocationTable, "dataTable");

    setParentLocationData(ParentLocationTable);
  }, [ParentLocationTable]);

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
  }, []);

  console.log(isEditData, "isEditData");

  let formData: any = {
    Location_Id: isEditData ? isEditData?.Location_Id : 0,
    Location_TypeId: isEditData ? isEditData?.Location_TypeId : "",
    Location_Name: isEditData ? isEditData?.Location_Name : "",
    ParentLocation_Id: isEditData ? isEditData?.ParentLocation_Id : "",
    ZoneId: isEditData ? isEditData?.ZoneId : "",
    RegionId: isEditData ? isEditData?.RegionId : "",
    Start_Pin: isEditData ? isEditData?.Start_Pin : "",
    End_Pin: isEditData ? isEditData?.End_Pin : "",
    IsActive: isEditData ? (isEditData?.IsActive === "Active" ? true : false) : true,
  };

  const {
    handleSubmit: handleLoactionForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
    trigger,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  const LocationTypeWatchvalue = watch("Location_TypeId");

  const isActiveDisabled = watch("IsActive");

  useEffect(() => {
    if (
      LocationTypeWatchvalue &&
      filteredLovTableLocationType.find(
        (item: any) =>
          (item.Options === LocationConfig?.Location_City || item.Options === LocationConfig?.Location_State) &&
          LocationTypeWatchvalue == item.LovId
      )
    ) {
      let findSeletcetTypeOption = filteredLovTableLocationType.find(
        (item: any) => item.LovId == LocationTypeWatchvalue
      );
      let findedParentLovId = 0;
      if (findSeletcetTypeOption && findSeletcetTypeOption.Options == LocationConfig?.Location_City) {
        findedParentLovId = filteredLovTableLocationType.find(
          (item: any) => item.Options === LocationConfig?.Location_State
        )?.LovId;
      }
      if (findSeletcetTypeOption && findSeletcetTypeOption.Options == LocationConfig?.Location_State) {
        findedParentLovId = filteredLovTableLocationType.find(
          (item: any) => item.Options === LocationConfig?.Location_Country
        )?.LovId;
      }
      dispatch(getParentLocationList({ LocationTypeId: findedParentLovId }));
    }
  }, [LocationTypeWatchvalue]);

  const handleSubmit = (data: any) => {
    console.log(getValues());
    dispatch(updateLoader(true));
    let formData = data;
    // setLoader(true);
    formData.IsActive = formData.IsActive ? true : false;
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 9;
    formData.ModuleName = "Location";
    formData.CreatedBy = user?.UserName;
    formData.Status = 1;
    formData.checkMaker = "Check the makerer";

    if (
      LocationTypeWatchvalue &&
      filteredLovTableLocationType.find(
        (item: any) => item.Options === LocationConfig?.Location_Country && LocationTypeWatchvalue == item.LovId
      )
    ) {
      formData.ParentLocation_Id = 0;
      formData.ZoneId = 0;
      formData.End_Pin = 0;
      formData.Start_Pin = 0;
      formData.RegionId = 0;
    }

    if (
      LocationTypeWatchvalue &&
      filteredLovTableLocationType.find(
        (item: any) => item.Options === LocationConfig?.Location_State && LocationTypeWatchvalue == item.LovId
      )
    ) {
      formData.ZoneId = 0;
      formData.End_Pin = 0;
      formData.Start_Pin = 0;
      formData.RegionId = 0;
    }

    let JsonDataForDisplay: any = {
      "Location Name": formData.Location_Name,
      "Location Type":
        filteredLovTableLocationType.find((item: any) => item.LovId == formData?.Location_TypeId)?.Options || "",
      "Parent":
        ParentLocationData.find((item) => item.Location_Id == formData?.ParentLocation_Id)?.Location_Name || "",
      "Start PIN": formData.Start_Pin,
      "End PIN": formData.End_Pin,
      "Region": filteredLovTableRegion.find((item: any) => item.LovId == formData?.RegionId)?.Options || "",
      "Zone": filteredLovTableZone.find((item: any) => item.LovId == formData?.ZoneId)?.Options || "",
      Status: formData.IsActive ? "Active" : "InActive",
      "Modified By": userLoginResponse?.UserName,
    };

    if (edit) {
      formData.UserId = userLoginResponse?.UserId;
      formData.Remarks = "";
      formData.Activity = "Edit";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      console.log(formData, "formData");

      dispatch(addEditLocation(formData));
    } else {
      formData.Activity = "Create";
      formData.IsActive = true;
      formData.UserId = userLoginResponse?.UserId;
      formData.Remarks = "";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
      dispatch(addEditLocation(formData));
    }
    console.log(JsonDataForDisplay, "JsonDataForDisplay");
  };

  useEffect(() => {
    if (islocationAdded) {
      dispatch(
        getLocationList({
          FormID: 2,
        })
      );
      dispatch(updateLocationAddandEdit(false));
      dispatch(updateLoader(false));
      if (ToasterMsg.Status !== "false") {
        if (closePopUp) {
          reset(formData);
          closePopUp();
          // setLoader(false);
        }
      }
      
    }
  }, [islocationAdded]);
  const endPinValue = watch("End_Pin");
  const startPinValue = watch("Start_Pin");

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden" }}>
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        <Grid
          sx={{
            height: "auto",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Location_TypeId"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Location Type is required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"Location_TypeId"}
                        valueKey={"LovId"}
                        optionLabelKey="Options"
                        options={filteredLovTableLocationType}
                        disabled={view || edit || !isActiveDisabled}
                        // addField={true}
                        required={true}
                        label={"Location Type"}
                        value={field.value}
                        onChange={(value: any) => {
                          field.onChange(value);
                          setValue("ParentLocation_Id", "");
                        }}
                        error={Boolean(errorsloginform.Location_TypeId)}
                        helperText={
                          errorsloginform.Location_TypeId && errorsloginform.Location_TypeId.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Location_Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Location Name is Required",
                      validate: {
                        noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Location_Name"}
                        required={true}
                        disabled={view || !isActiveDisabled}
                        label={"Location Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Location_Name)}
                        helperText={errorsloginform.Location_Name && errorsloginform.Location_Name.message?.toString()}
                      />
                    )}
                  />
                </Grid>

                {filteredLovTableLocationType.find(
                  (item: any) =>
                    (item.Options === LocationConfig?.Location_City ||
                      item.Options === LocationConfig?.Location_State) &&
                    watch("Location_TypeId") == item.LovId
                ) !== undefined ? (
                  <Grid
                    item
                    xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="ParentLocation_Id"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Location Type is required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"ParentLocation_Id"}
                          valueKey={"Location_Id"}
                          optionLabelKey="Location_Name"
                          options={ParentLocationData}
                          disabled={view || !isActiveDisabled}
                          required={true}
                          // addField={true}
                          label={"Parent Location"}
                          value={field.value}
                          onChange={field.onChange}
                          error={Boolean(errorsloginform.ParentLocation_Id)}
                          helperText={
                            errorsloginform.ParentLocation_Id && errorsloginform.ParentLocation_Id.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                ) : null}

                {filteredLovTableLocationType.find(
                  (item: any) =>
                    item.Options === LocationConfig?.Location_City && watch("Location_TypeId") == item.LovId
                ) !== undefined ? (
                  <>
                    <Grid
                      item
                      xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                    >
                      <Controller
                        name="ZoneId"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Zone is required" }}
                        render={({ field }) => (
                          <CustomSelect
                            name={"Zone"}
                            valueKey={"LovId"}
                            optionLabelKey="Options"
                            options={filteredLovTableZone}
                            disabled={view || !isActiveDisabled}
                            required={true}
                            // addField={true}
                            label={"Zone"}
                            value={field.value}
                            onChange={field.onChange}
                            error={Boolean(errorsloginform.ZoneId)}
                            helperText={errorsloginform.ZoneId && errorsloginform.ZoneId.message?.toString()}
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                    >
                      <Controller
                        name="RegionId"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Region is required" }}
                        render={({ field }) => (
                          <CustomSelect
                            name={"Region"}
                            valueKey={"LovId"}
                            optionLabelKey="Options"
                            options={filteredLovTableRegion}
                            disabled={view || !isActiveDisabled}
                            required={true}
                            // addField={true}
                            label={"Region"}
                            value={field.value}
                            onChange={field.onChange}
                            error={Boolean(errorsloginform.RegionId)}
                            helperText={errorsloginform.RegionId && errorsloginform.RegionId.message?.toString()}
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                    >
                      <Controller
                        name="Start_Pin"
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: "Start Pin Field is Required",
                          validate: (data) => {
                            const number = parseInt(data, 10);
                            const endPinNumber = parseInt(endPinValue, 10);
                            // Check if the input is exactly 6 digits and falls within the specified range
                            if (isNaN(number) || data.toString().length !== 6 || number <= 0 || number > 999999) {
                              return "Please enter a valid 6-digit pincode";
                            }
                            // // Check if Start_Pin is less than or equal to End_Pin
                            // if (!isNaN(endPinNumber) && number > endPinNumber) {
                            //   return "Start Pin should be less than or equal to End Pin";
                            // }
                            return true;
                          },
                          maxLength: {
                            value: 6,
                            message: "Please enter a valid 6-digit pincode",
                          },
                          pattern: {
                            value: /^\d{6}$/,
                            message: "Please enter a valid 6-digit pincode",
                          },
                          // validate: (data) => {
                          //   const number = parseInt(data, 10);
                          //   const endPinNumber = parseInt(endPinValue, 10);
                          //   // Check if the input is exactly 6 digits and falls within the specified range
                          //   if (isNaN(number) || data.length !== 6 || number <= 0 || number > 999999) {
                          //     return "Please enter a valid 6-digit pincode";
                          //   }
                          //   // // Check if Start_Pin is less than or equal to End_Pin
                          //   // if (!isNaN(endPinNumber) && number > endPinNumber) {
                          //   //   return "Start Pin should be less than or equal to End Pin";
                          //   // }
                          //   return true;
                          // },
                        }}
                        render={({ field }) => (
                          <CustomNumberField
                            name={"Start_Pin"}
                            required={true}
                            disabled={view || !isActiveDisabled}
                            label={"Start Pin"}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key == "-" || e.key == "e") {
                                e.preventDefault();
                              }
                            }}
                            error={Boolean(errorsloginform.Start_Pin)}
                            helperText={errorsloginform.Start_Pin && errorsloginform.Start_Pin.message?.toString()}
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    // sm={textFieldWidth.sm}
                    // md={textFieldWidth.md}
                    // lg={textFieldWidth.lg}
                    // xl={textFieldWidth.xl}
                    >
                      <Controller
                        name="End_Pin"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "End Pin Field is Required",
                          validate: (data) => {
                            const number = parseInt(data, 10);
                            const startPinNumber = parseInt(startPinValue, 10);
                            if (isNaN(number) || data.toString().length !== 6 || number <= 0 || number > 999999) {
                              console.log(data.length, number, "numbervalue");
                              return "Please enter a valid 6-digit pincode";
                            }
                            // if (!isNaN(startPinNumber) && number < startPinNumber) {
                            //   return "End Pin should be greater than or equal to Start Pin";
                            // }
                            return true;
                          },
                          maxLength: {
                            value: 6,
                            message: "Please enter a valid 6-digit pincode",
                          },
                          pattern: {
                            value: /^\d{6}$/,
                            message: "Please enter a valid 6-digit pincode",
                          },
                          // validate: (data) => {
                          //   const number = parseInt(data, 10);
                          //   const startPinNumber = parseInt(startPinValue, 10);
                          //   // Check if the input is exactly 6 digits and falls within the specified range
                          //   if (isNaN(number) || data.length !== 6 || number <= 0 || number > 999999) {
                          //     return "Please enter a valid 6-digit pincode";
                          //   }
                          //   // // Check if End_Pin is greater than or equal to Start_Pin
                          //   // if (!isNaN(startPinNumber) && number < startPinNumber) {
                          //   //   return "End Pin should be greater than or equal to Start Pin";
                          //   // }
                          //   return true;
                          // },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <CustomNumberField
                            name="End_Pin"
                            required={true}
                            disabled={view || !isActiveDisabled}
                            label="End Pin"
                            value={field.value}
                            onChange={field.onChange}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "-" || e.key === "e") {
                                e.preventDefault();
                              }
                            }}
                            error={Boolean(error)}
                            helperText={error ? error.message : ""}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : null}

                {/* {edit || view ? (
                  <Grid
                    item
                    xs={12}
                  // sm={textFieldWidth.sm}
                  // md={textFieldWidth.md}
                  // lg={textFieldWidth.lg}
                  // xl={textFieldWidth.xl}
                  >
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        name="IsActive"
                        control={control}
                        render={({ field }) => (
                          <CustomToogleSwitch
                            label={"User Status"}
                            dynamicLabel={field.value ? "ACTIVE" : "INACTIVE"}
                            disabled={view}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                ) : null} */}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    name={"Cancel"}
                    onClick={() => {
                      closePopUp && closePopUp();
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Update"}
                      onClick={handleLoactionForm(handleSubmit)}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        reset(formData);
                        closePopUp && closePopUp();
                      }}
                    />
                    <CustomButton
                      type="reset"
                      disabled={!isDirty}
                      name={"Submit"}
                      onClick={handleLoactionForm(handleSubmit)}
                    />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
    </Grid>
  );
};
