import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { INotificationSettingsResponse } from "../../models/notification.type";
import { NOTIFICATION_URL } from "./endpoints/notification";

// const addNotification = (requestBody: any): Promise<INotificationSettingsResponse> =>
//   axiosInstance
//     .post(NOTIFICATION_URL.addNotification, requestBody)
//     .then((response: AxiosResponse<INotificationSettingsResponse>) => {
//       if (response.status === 200) {
//         return response.data;
//       } else {
//         throw new Error("Error adding notification: " + response.statusText);
//       }
//     })
//     .catch((error: AxiosError) => {
//       throw error;
//     });

const updateUnread = (requestBody: any): Promise<void> =>
  axiosInstance
    .post(NOTIFICATION_URL.updateUnread, requestBody)
    .then((response: AxiosResponse<void>) => {
      if (response.status !== 200) {
        throw new Error("Error updating unread status: " + response.statusText);
      }
    })
    .catch((error: AxiosError) => {
      throw error;
    });

const updateNotificationStatus = (requestBody: any): Promise<void> =>
  axiosInstance
    .post(NOTIFICATION_URL.updateNotificationStatus, requestBody)
    .then((response: AxiosResponse<void>) => {
      if (response.status !== 200) {
        throw new Error("Error updating notification status: " + response.statusText);
      }
    })
    .catch((error: AxiosError) => {
      throw error;
    });

const getNotification = (userId: number): Promise<INotificationSettingsResponse[]> =>
  axiosInstance
    .get(NOTIFICATION_URL.getNotification, {
      params: {
        userid: userId,
      },
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data["result"];
      } else {
        throw new Error("Error getting notifications: " + response.statusText);
      }
    })
    .catch((error: AxiosError) => {
      throw error;
    });

const notificationService = {
  // addNotification,
  updateUnread,
   updateNotificationStatus,
  getNotification,
};

export default notificationService;
