import { AxiosError, AxiosResponse } from "axios";

import { axiosInstance } from "./config/axiosConfig";
import { PENDING_ACTION_URL } from "./endpoints/pendingAction";

const getAllWorkFlowInstance = (): Promise<any> =>
  axiosInstance
    .get(PENDING_ACTION_URL.GetAllWorkFlowInstances)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVendorList = (requestParams: any): Promise<any> =>
  axiosInstance
    .get(PENDING_ACTION_URL.GetPendingAction, {
      params: requestParams,
    })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addVendor = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(PENDING_ACTION_URL.updatependingApproval, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const pendingActionsService = {
  getAllWorkFlowInstance,
  getVendorList,
  addVendor,
};

export default pendingActionsService;
