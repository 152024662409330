import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import CustomTabs from "../../../common/dynamicTabs/CustomTabs";
import { AddVendorDetails } from "../../vendorDetails/AddVendor";
import vendorDetailsService from "../../../store/services/vendorDetails";
import CustomStepper from "../../../common/dynamicSteper/CustomStepper";
import { RolesandResponsibility } from "../rolesAndResponsibility/RolesAndResponsibility";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { AQMMapping } from "../aqmMapping/AQMMapping";
import { VendorOnboardingAssessment } from "../VendorOnBoardingPreview";
import AQMMappingService from "../../../store/services/assesmentQuestionnaireMapping";
import VendorAssessmentService from "../../../store/services/vendorAssessmentService";
import rolesAndResponsibilityService from "../../../store/services/rolesAndResponsibilityService";

interface VendorOnboardingSignOffProps {
  vendor_Id: any;
  onClose: (val: any) => void;
  currentProcessData: any;
}

export const VOBProcessPreviews: React.FC<VendorOnboardingSignOffProps> = ({
  vendor_Id,
  onClose,
  currentProcessData,
}) => {
  const [step, setstep] = useState([
    "Roles and Responsibility",
    "Vendor Details",
    "Assessment Questionnaire Mapping",
    "Assessment Details",
  ]);
  const [activestepCount, setactivestepCount] = useState(0);

  const [VendorDetails, setVendorDetails] = useState<any>(null);
  const [AQMMappingDetails, setAQMMappingDetails] = useState<any>(null);
  const [ProjectDetails, setProjectDetails] = useState<any>(null);

  const closeSection = (e: any) => {
    console.log(e);

    onClose(e);
  };

  useEffect(() => {

    const callAQMMappingandassessmentDetails = async (venId: any ,ProjId : any) => {
    try {
      const res = await VendorAssessmentService.getAllQuestionByVendorandProjectId({ Vendor_Id: Number(venId) , Project_Detail_Id : ProjId });
      console.log(res.data, "AQM MApping Details");

      setAQMMappingDetails(res.data);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  }
  if (vendor_Id &&  currentProcessData && currentProcessData?.ProjectId) {
    callAQMMappingandassessmentDetails(vendor_Id , currentProcessData?.ProjectId);
    console.log(currentProcessData , 'currentProcessData');
    
  }
  },[vendor_Id , currentProcessData ])


  useEffect(() => {
    const callProjecttDetails = async (ProjId : any) => {
      try {
        const res = await rolesAndResponsibilityService.getProjectDetail({ Project_Detail_Id : ProjId });
        console.log(res.data, "Project Details");
  
        setProjectDetails(res.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    }
    if (vendor_Id &&  currentProcessData && currentProcessData?.ProjectId) {
      callProjecttDetails(currentProcessData?.ProjectId);
      console.log(currentProcessData , 'currentProcessData');
      
    }
  },[currentProcessData])

  useEffect(() => {
    const callVendorDetails = async (venId: any) => {
      try {
        const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: Number(venId) });
        console.log(res.data, "vendordetailsId");

        setVendorDetails(res.data);
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };

    if (vendor_Id) {
      callVendorDetails(vendor_Id);
    }
    console.log(vendor_Id, "vendordetailsId");
  }, [vendor_Id]);

  return (
    <>
      <Box>
        <Stack spacing={4}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <CustomBreadcrumbs
              list={[
                { value: 0, name: "Pending Actions" },
                { value: 1, name: "Sign Off" },
                { value: 2, name: `Preview` },
              ]}
              onchange={closeSection}
            />
          
          </Box>

          <CustomStepper activeStep={activestepCount} steps={step} />

          {ProjectDetails && activestepCount === 0 && (
            <RolesandResponsibility
              view={true}
              isEditData={ProjectDetails}
              renewal={false}
              isViewOnly={true}
            />
          )}

          {VendorDetails && activestepCount === 1 && (
            <AddVendorDetails
              key={"Vendor Component From work flow Preview Use"}
              view={true}
              edit={false}
              isEditData={VendorDetails || undefined}
              isDisableBreadCrums={true}
            />
          )}

          {activestepCount === 2 && <AQMMapping isViewOnly={true} currentProcessData={currentProcessData} isEditData={AQMMappingDetails} view={true} />}

          {activestepCount === 3 &&  <VendorOnboardingAssessment
            currentProcessData={currentProcessData}
            vendor_Id={vendor_Id}
            issmeReview={false}
            isPreviewMode={true}
            isdisableBreadCrums={true}
            
          /> }

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "right",
              mt: 2,
            }}
          >
            <>
              {activestepCount !== 0 && (
                <CustomButton
                  name={`Back to ${step[activestepCount - 1]}`}
                  onClick={() => setactivestepCount((pre) => pre - 1)}
                />
              )}
              {activestepCount !== 3 && (
                <CustomButton
                  type="submit"
                  name={`Go to ${step[activestepCount + 1]}`}
                  onClick={() => setactivestepCount((pre) => pre + 1)}
                />
              )}
            </>
          </Box>
        </Stack>
      </Box>
    </>
  );
};
