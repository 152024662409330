import React, { useEffect, useMemo, useState } from "react";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import { threshold } from "../../../utils/constants";
import { Box, IconButton, Stack, TableContainer, Typography } from "@mui/material";
import { checkstepAvailable } from "./AssessmentValidation";
import { useTheme } from "@mui/material/styles";
import CustomSvg from "../../../common/CustomSvg";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { ThresholdChangeForm } from "./ThresholdChangeForm";
import CommonDataExport from "../../../common/CommonSheetsDownload/ExcelDownload";

export const calculate_thresholdanddeviation_forVendorAssesment = (val: any, ThresholdData: any) => {
  let dubValue: any = [];

  let Final_bestScore = 0;
  let Final_ActualScore = 0;

  let Final_deviations = 0;

  let subtotal_deviation = 0;
  let subthresholdQuestions: any[] = [];
  val.forEach((element: any) => {
    let obj: any = { Category: element.Options, wtg: element.Cat_Wtg, bestScore: 0, ActualScore: 0, deviation: 0 };
    let thisBest_Score = 0;
    let thisActual_Score = 0;
    let deviation = 0;

    let subDeviation = 0;

    element.Questions &&
      element.Questions.forEach((Qelement: any) => {
        // console.log(Qelement, "Qelement");
        const data = Qelement.Best_Score - Qelement.Actual_Score;
        // console.log(data, "datadatadata");
        // subDeviation += Qelement.Best_Score - Qelement.Actual_Score || 0;
        if (Qelement && Qelement?.Deviation >= ThresholdData) {
          // console.log(Qelement.Actual_Score ,  Qelement.Best_Score , 'subtotal_ best actual');
          let QuestionSubDeviation = Qelement.Actual_Score - Qelement.Best_Score || 0;
          subDeviation += QuestionSubDeviation;

          let qobj = {
            Category: element.Options,
            Service_Questions: Qelement?.Question,
            Deviations: QuestionSubDeviation.toFixed(2),
            Actions: Qelement?.DO_Remarks,
          };
          subthresholdQuestions.push(qobj);
        }
        thisBest_Score += Number(Qelement.Best_Score) || 0;
        thisActual_Score += Number(Qelement.Actual_Score) || 0;
        deviation += Number(Math.abs(Qelement.Deviation)) || 0;
        // console.log(Qelement.Best_Score, Qelement.Actual_Score, subDeviation, "Score_Sub_Deviation");
      });
    subtotal_deviation += subDeviation;
    let finalValue: any = thisActual_Score - thisBest_Score;
    let Percentage: any = 0;
    if (thisBest_Score === 0) {
      Percentage = 0;
    } else {
      Percentage = (finalValue / thisBest_Score) * 100;
    }
    // let Percentage: any = (finalValue / thisBest_Score) * 100;

    obj.bestScore = thisBest_Score.toFixed(2);
    obj.ActualScore = thisActual_Score.toFixed(2);
    obj.deviation = isNaN(Percentage) ? 0 : `${Percentage.toFixed(2)}%`;

    Final_bestScore += thisBest_Score;
    Final_ActualScore += thisActual_Score;
    Final_deviations += deviation / 100;

    dubValue.push(obj);
  });

  let conclusionfinalValue: any = Final_ActualScore - Final_bestScore;
  let conclusionPercentage: any = 0;
  if (Final_bestScore == 0) {
    conclusionPercentage = 0;
  } else {
    conclusionPercentage = (conclusionfinalValue / Final_bestScore) * 100;
  }

  // ----Find inherit--------

  const updatedFinalDeviation = conclusionPercentage;

  let netOutliners = updatedFinalDeviation / 100 - subtotal_deviation;

  let netOutliners_sumOfBest = 0;
  if (Final_bestScore == 0) {
    netOutliners_sumOfBest = 0;
  } else {
    netOutliners_sumOfBest = netOutliners / Final_bestScore;
  }

  let inherentRisk = `${((1 - netOutliners_sumOfBest) * 100).toFixed(2)}%`;

  let lastobj: any = {
    Category: "Total",
    wtg: "",
    bestScore: Final_bestScore.toFixed(2),
    ActualScore: Final_ActualScore.toFixed(2),
    deviation: `${updatedFinalDeviation.toFixed(2)} %`,
  };

  let lastobj4: any = {
    Category: "Aggregated Deviation %",
    wtg: "",
    bestScore: "",
    // this changes from kini inherentRisk changed 06-11-2024
    ActualScore: `${updatedFinalDeviation.toFixed(2)} %`,
    // deviation: inherentRisk,
    deviation: "",
  };

  let thresholdDeviationsum: any = {
    Service_Questions: "Sum of Deviation",
    Actions: "",
    Category: "",
    Deviations: subtotal_deviation.toFixed(2),
  };
  let thresholdDeviationnetout: any = {
    Service_Questions: "Net of Outliers",
    Actions: "",
    Category: "",
    Deviations: netOutliners.toFixed(2),
  };
  let thresholdDeviationnetoutsumofbest: any = {
    Service_Questions: "Net of Outliers / Sum of Best",
    Actions: "",
    Category: "",
    Deviations: netOutliners_sumOfBest.toFixed(2),
  };

  let thresholdDeviationinherit: any = {
    Service_Questions: "Inherent Risk",
    Actions: "",
    Category: "",
    Deviations: inherentRisk,
  };
  dubValue.push(lastobj, lastobj4);
  subthresholdQuestions.push(
    thresholdDeviationsum,
    thresholdDeviationnetout,
    thresholdDeviationnetoutsumofbest,
    thresholdDeviationinherit
  );
  return { thresholdQuestions: subthresholdQuestions, deviation: dubValue };
 
};

export const thresholdQuestionViewColumns = [
  { DisplayName: "Parameters", Field: "Category" },
  { DisplayName: "Service Questions", Field: "Service_Questions" },
  { DisplayName: "Deviation", Field: "Deviations" },
];

export const Assesmentdeviationcolums = [
  { DisplayName: "Category ", Field: "Category" },
  { DisplayName: " Weightage %", Field: "wtg" },
  { DisplayName: " Best ", Field: "bestScore" },
  { DisplayName: " Actual ", Field: "ActualScore" },
  { DisplayName: " Deviation %", Field: "deviation" },
];

interface FinalAssessmentScoringProps {
  data: any[];
  deviationMetrix: any[];
  processStepsList: any[];
  ThresholdData: any;
  onclose: () => void;
  currentProcessData: any;
}

export const FinalAssessmentScoring: React.FC<FinalAssessmentScoringProps> = ({
  currentProcessData,
  data,
  deviationMetrix,
  onclose,
  ThresholdData,
  processStepsList,
}) => {
  const theme = useTheme();
  
  const [Finaldata, setfinaldata] = useState([]);
  const [deviationScoringdata, setdeviationScoringdata] = useState([]);
  const [thresholdQuestionsdata, setthresholdQuestionsdata] = useState<any>([]);
  const [thresholdQuestioncolums, setThresholdQuestioncolums] = useState(thresholdQuestionViewColumns);

  const scoringcolums = [
    { DisplayName: "Threshold", Field: "deviation_scoring" },
    { DisplayName: "Rating", Field: "Rating" },
  ];

  useEffect(() => {
    if (checkstepAvailable("Do Status", processStepsList)) {
      setThresholdQuestioncolums((prevColumns) => [...prevColumns, { DisplayName: "Actions", Field: "Actions" }]);
    }
  }, [processStepsList]);

  useEffect(() => {
    if (data && ThresholdData) {
      let returnValues: any = calculate_thresholdanddeviation_forVendorAssesment(data, ThresholdData);
      setthresholdQuestionsdata(returnValues?.thresholdQuestions);
      setfinaldata(returnValues?.deviation);
    }
  }, [data, ThresholdData]);

  useEffect(() => {
    if (deviationMetrix) {
      let deviationValues: any = [];
      deviationMetrix.sort((a, b) => {
        const rangeFromA = parseFloat(a?.Range_From);
        const rangeFromB = parseFloat(b?.Range_From);

        // If Range_From values are the same, sort by Range_To
        if (rangeFromA === rangeFromB) {
          const rangeToA = parseFloat(a?.Range_To);
          const rangeToB = parseFloat(b?.Range_To);
          return rangeToA - rangeToB;
        }

        return rangeFromA - rangeFromB;
      });
      deviationMetrix.forEach((item) => {
        let obj = { deviation_scoring: `${item?.Range_From}%  to  ${item?.Range_To}%`, Rating: item.Rating };
        deviationValues.push(obj);
      });
      setdeviationScoringdata(deviationValues);
    }
  }, [deviationMetrix]);

  const doStepAvailable = checkstepAvailable("Do Status", processStepsList);

  const findOtherTabledata = useMemo(() => {
    let val: any[] = [];

    if (doStepAvailable && thresholdQuestionsdata) {
      val.push(thresholdQuestionsdata);
    }
    val.push(deviationScoringdata);

    return [...val];
  }, [doStepAvailable, thresholdQuestionsdata, deviationScoringdata]);

  const findOtherTableheaderdata = useMemo(() => {
    let val: any[] = [];

    if (doStepAvailable && thresholdQuestioncolums) {
      val.push(thresholdQuestioncolums);
    }
    val.push(scoringcolums);

    return [...val];
  }, [doStepAvailable, thresholdQuestioncolums, scoringcolums]);

  return (
    <>
      <Stack spacing={3}>
        <Box>
          {" "}
          <CommonDataExport
            header={Assesmentdeviationcolums}
            data={Finaldata}
            otherdatas={findOtherTabledata}
            otherheaders={findOtherTableheaderdata}
            table_name={"Aggregated Deviation"}
            button_title={"Export All"}
          />{" "}
        </Box>
        <DynamicBasicTable
          style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}
          isDownload={true}
          rowData={Finaldata}
          columns={Assesmentdeviationcolums}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography sx={{ fontSize: "8px", color: "red" }}>
            {" "}
            Note : Threshold for Outliers {ThresholdData}{" "}
          </Typography>{" "}
        </Box>
        {doStepAvailable && <Typography sx={{ fontSize: "12px", fontFamily: "aptos-bold" }}> Key Outliers</Typography>}

        {doStepAvailable && (
          <DynamicBasicTable
            isDownload={true}
            table_name={"Key Outliers"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px" }}
            rowData={thresholdQuestionsdata}
            columns={thresholdQuestioncolums}
          />
        )}

        <Typography sx={{ fontSize: "12px", fontFamily: "aptos-bold" }}>Threshold Metrics</Typography>
        <Box sx={{ width: "50%" }}>
          <DynamicBasicTable
            isDownload={true}
            table_name={"Threshold Metrics"}
            style={{ border: "1px solid #f1f1f1", borderRadius: "5px", maxHeight: "50vh" }}
            rowData={deviationScoringdata}
            columns={scoringcolums}
          />
        </Box>
      </Stack>
    </>
  );
};
