import react, { useEffect, useState } from "react";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { AddToaster } from "./AddToaster";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { IToaster } from "../../models/authorize.type";
import { deleteToaster, getToaster, updateToasterAddandEdit } from "../../store/slices/ToasterData";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { getAllMenuModulesList } from "../../store/slices/EmailTemplateData";
import { IMenu } from "../../models/loggin.type";
import recordLock, { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import PageMainHeading from "../../common/CommonHeadings/PageMainHeading";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";

export default function Toaster() {
  const [addPopup, setAddPopup] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const list = [
    { id: 1, name: "madurai", status: "true" },
    { id: 2, name: "mumbai", status: "false" },
    { id: 3, name: "chennai", status: "true" },
  ];
  const [permissionPage, setPermissionForPage] = useState<IMenu | null>(null);
  const { dataTable, isToasterAdded, isToasterUpdated , toastertemplatetoasterMsg , isToasterdelete , isLoading } = useSelector((state: RootState) => state.toasterData);
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const [toaster, setToaster] = useState<IToaster[]>([]);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditData, setIsEditData] = useState<{ id?: string }>({});
  const [isDeleteData, setIsDeleteData] = useState<any>('');
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const { user } = useSelector((state: RootState) => state.loggerData);

  const dispatch = useAppDispatch();
  useEffect(() => {
    console.log("isEdit", isEdit);
    dispatch(getToaster());
    dispatch(getAllMenuModulesList());
  }, []);
  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForPage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

 

  useEffect(() => {
    dispatch(updateLoader(isLoading ));
  }, [isLoading]);

  useEffect(() => {
    console.log(dataTable, "t_data");

    if (dataTable?.toaster.length > 0) {
      setToaster(dataTable?.toaster);
      dispatch(updateLoader(false));
    } else {
      dispatch(updateLoader(false));
    }
  }, [dataTable]);



  useEffect(() => {
    if (isToasterAdded) {
      // setUserAdded(true);
      dispatch(updateToasterAddandEdit(false));
      dispatch(getToaster());
    } else if (isToasterUpdated) {
      // setUserUpdated(true);
      dispatch(updateToasterAddandEdit(false));
      dispatch(getToaster());
    }else if (isToasterdelete) {
      dispatch(getToaster());
      dispatch(updateToasterAddandEdit(false));
      console.log(toastertemplatetoasterMsg , 'toastertemplatetoasterMsg');
      
      dispatch(updateToaster({ isToaster: true, toasterMsg: toastertemplatetoasterMsg }));
      setIsDeleteData("");
      setOption('')
   
   
    }
  }, [isToasterAdded, isToasterUpdated , isToasterdelete]);

  let columns: any = {
    table_name: "Toaster table",
    isAction: true,
    columns: [
      {
        Field: "ModuleName",
        FieldType: "string",
        DisplayName: "Module Name",
      },
      {
        Field: "Message",
        FieldType: "string",
        DisplayName: "Message",
      },
      {
        Field: "Activity",
        FieldType: "string",
        DisplayName: "Activity",
      },
      {
        Field: "IsActive",
        FieldType: "string",
        DisplayName: "IsActive",
      },
    ],
  };
  const handleAction = async (data: any, opt: string) => {
    if (opt === "edit") {
      setOption(opt);
      console.log(data, "dataData");
      setEditId(data?.ToasterId);
      setIsEditData(data);
      dispatch(updateLoader(true));
      const canEdit = await handleRecordLockCheck(data?.ToasterId);
      if (canEdit) {
        const dispatchData = handleAddRecordLock(data?.ToasterId);
        await dispatch(AddRecordLock(dispatchData));
        //setIsEditOpen(true);
        const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.ToasterId)));
        if (GetRecordLock.fulfilled.match(resultAction)) {
          const recordLockData = resultAction.payload;
          console.log(recordLockData, resultAction, "recordLockData");
          setGetRecordLock(recordLockData);
        }
        dispatch(updateLoader(false));
        setIsEdit(true);
        // Set timeout to close the popup after 2.5 minutes
        // setTimeout(() => {
        //   setIsEdit(false);
        //   const data = recordLockUpdate();
        //   if (data.length > 0) {
        //     dispatch(UpdateRecordLock(data.pop()));
        //   }
        // }, 150000);
      }
    }else if(opt === "view" ) {
      // setIsEdit(true);
      setOption(opt);
      setIsEditData(data);
    } else if(opt === "delete") {
      // setIsEdit(true);
      setOption(opt);
      setIsDeleteData(data);
    }
  };

 

  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(data, "dataLock");
      if (data.length > 0) {
        event.preventDefault();
        event.returnValue = "";
        try {
          await dispatch(UpdateRecordLock(data.pop())).unwrap();
        } catch (error) {
          console.error("Failed to update record lock:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);
  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permissionPage?.MenuId,
      ModuleName: "ToasterTemplate",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permissionPage?.MenuId,
      ModuleName: "ToasterTemplate",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        setIsEditing(true);
        dispatch(updateLoader(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      return [
        {
          RecordlockId: recordLock[0].RecordlockId,
          RecordId: recordLock[0].RecordId,
          IsActive: false,
        },
      ];
    }
    return [];
  };

  const inactiveTemplate = () => {
    console.log(isDeleteData);

    let JsonDataForDisplay: any = {
      "Template Name": isDeleteData.Message,
      "Status": isDeleteData.IsActive === "Active" ? "inactive" : "active",
      "Modified By": userLoginResponse?.UserName,
    }

 
    let inactiveFormData =   {
        "ModuleName": "Toaster Template",
        "Activity": isDeleteData.IsActive === "Active" ? "inactive" : "active" ,
        "UserId": userLoginResponse?.UserId ,
        "MenuId": 0,
        "Form_Id": 0,
        "ToasterId":isDeleteData.ToasterId,
        "IsActive": isDeleteData.IsActive === "Active" ? 0 : 1 ,
         CreatedBy: userLoginResponse?.UserName,
      }
    
    
    dispatch(deleteToaster(inactiveFormData));
  };
  useEffect(() => {
    if (!isEdit) {
      const data = recordLockUpdate();
      if (data.length > 0) {
        dispatch(UpdateRecordLock(data.pop()));
      }
    }
  }, [isEdit]);
  return (
    <>
      <CustomDialog
        show={addPopup}
        onHide={() => setAddPopup(false)}
        maxWidth={"md"}
        header={"Add Toaster"}
        contentNode={
          <AddToaster
            view={false}
            isAdded={(flag: boolean) => {
              setAddPopup(flag);
            }}
          />
        }
      />
      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option == "edit")
          // isAdded={(flag: boolean) => {
          //   setAddPopup(flag);
          // }}
          setIsEdit(false);
          const data = recordLockUpdate();
          if (data.length > 0) {
            dispatch(UpdateRecordLock(data.pop()));
          }
        }}
        //  maxWidth={Option === "edit" ? "Edit" : "View"}
        maxWidth={"md"}
        header={Option === "edit" ? "Update Toaster" : "View Toaster"}
        contentNode={
          <>
            {Option === "edit" && <AddToaster isEditData={isEditData} edit={true} view={false} isEdited={setIsEdit} />}
            {Option === "view" && <AddToaster view={true} isEditData={isEditData} edit={false} isEdited={setIsEdit} />}
          </>
        }
      />

      {/* <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem 0px",
        }}
      >
        <CustomButton
          variant="contained"
          name={"Add Toaster"}
          onClick={() => {
            setAddPopup(true);
          }}
        />
      </Grid> */}
      <Stack spacing={2}>
      <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <PageMainHeading title={`${permssionPage?.MenuName}`} />
               
            </Box>
     
      <MaterialDynamicGrid
        data={columns}
        rows={toaster}
        handleAction={handleAction}
        handleAdd={() => setAddPopup(true)}
        showAdd={true}
        enableZoomIcon={true}
        showhistory={false}
        showDelete={permssionPage?.IsDelete === 1}
        showView={permssionPage?.IsView === 1}
        showEdit={permssionPage?.IsEdit === 1}
        showExports={permssionPage?.IsExport === 1}
        userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
        pageName="User Management"
      />
       <ModalPopUp
        onDeleteMethod={() => inactiveTemplate()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Toaster Template`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
       </Stack>
    </>
  );
}
