import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { INDUSTRY_URL } from "./endpoints/commonMasters";
import { IAddIndustryResponse, IIndustryResponse, IParentIndustryResponse, IVHColumnRes } from "../../models/industry.type";

const getIndustryList = (reqparams: any): Promise<IIndustryResponse> =>
  axiosInstance
    .get(INDUSTRY_URL.GetIndustry)
    .then((response: AxiosResponse<IIndustryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const getParentIndustryList = (reqparams: any): Promise<IParentIndustryResponse> =>
    axiosInstance
        .get(INDUSTRY_URL.GetParentIndustry, {params:reqparams})
        .then((response: AxiosResponse<IParentIndustryResponse>) => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
            throw error;
        });

const addEditIndustry = (requestBody: any): Promise<IAddIndustryResponse> =>
  axiosInstance
    .post(INDUSTRY_URL.AddEditIndustry, requestBody)
    .then((response: AxiosResponse<IAddIndustryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteIndustry = (requestBody: any): Promise<IAddIndustryResponse> =>
  axiosInstance
    .post(INDUSTRY_URL.DeleteIndustry, requestBody)
    .then((response: AxiosResponse<IAddIndustryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryIndustry = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(INDUSTRY_URL.GetIndustryVersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const industryMasterService = {
    getIndustryList,
    addEditIndustry,
    deleteIndustry,
    getParentIndustryList,
    getVersionHistoryIndustry,
  
};

export default industryMasterService;
