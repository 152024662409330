import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Chip } from '@mui/material';
import { useAppDispatchThunk } from '../../store/store';
import { useForm } from 'react-hook-form';
import CustomButton from '../../common/dynamicButton/CustomButton';
import ProgressBar from '../../common/progressBar/progressBar';
import CustomChip from '../../common/dynamicChip/CustomChip';

interface saveFilterForm {
    FilterName : string;
}

export interface userProps {  
  closePopUp : ()=> void; 
  pageSettings : string[]
  saveSettings : (data: string[])=> void;
}

const SaveGridSettings: React.FC<userProps> = ({ closePopUp, pageSettings,saveSettings }) => {

  const dispatch = useAppDispatchThunk();
  const [loader, setLoader] = useState<boolean> (false);
  const [page_Settings, setPageSettings] = useState<string[]>(pageSettings);

  const handleSubmit = () => {        
    saveSettings && saveSettings(page_Settings);    
  };

  const deleteChip = (label: string) =>{
    setPageSettings(page_Settings.filter(page=> page !==label))
  } 

  useEffect(()=>{
    if(pageSettings.length>0){
      setPageSettings(pageSettings)
    }
  },[pageSettings])

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "auto",
        width: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {loader ? <ProgressBar showProgress={loader} /> : null}
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        <Grid
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              // px: {
              //   xs: 2,
              //   sm: 4,
              //   md: 2,
              //   lg: 2,
              //   xl: 2,
              // },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            
              <Grid container spacing={2}>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} sx={{mt:"20px", ml: "20px"}}>
                  {/* <CustomChip label={'Delete'} onDelete={()=>{deleteChip("delete")}}/>
                  <CustomChip label={'Delete'} onDelete={()=>{deleteChip("delete")}}/>
                  <CustomChip label={'Delete'} onDelete={()=>{deleteChip("delete")}}/> */}

                  {page_Settings.map((data)=>{
                    return(                     
                      <CustomChip label={data} onDelete={()=>{deleteChip(data)}}/>
                      
                    )
                  })}
                </Stack>
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "20px",
                  justifyContent: "right",
                  mt: 2,
                }}
              >
                <React.Fragment>                  
                  <CustomButton
                    type="submit"
                    name={"Save"}
                    onClick={()=>handleSubmit()}
                  />
                </React.Fragment>
              </Box>            
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SaveGridSettings;



