import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import workFlowService from "../services/workFlowService";
import customFormFieldsService from "../services/customFormFieldsService";
import { IFormConfigurationList } from "../../models/customFields.type";
import { IWorkflowData } from "../../models/workFlow.type";

export interface IWorkflow {
  // userName: string;
  isLoading: boolean;
  workFlowTypeOption: any[];
  workFlowProcessNext: any[];
  worFlowProcessType: any[];
  formConfiguration: IFormConfigurationList[];
  workFlowsData: IWorkflowData | null;
  ToasterMsg: string;
  isWorkFlowCreated: boolean;
  editWorkFlowData: any;
  currentStepWorkFlow: any;
  currentStep: any;
}

export const initialState: IWorkflow = {
  isLoading: false,
  workFlowTypeOption: [],
  workFlowProcessNext: [],
  worFlowProcessType: [],
  formConfiguration: [],
  workFlowsData: null,
  ToasterMsg: "",
  isWorkFlowCreated: false,
  editWorkFlowData: null,
  currentStepWorkFlow: [],
  currentStep: null
};

export const getAllFormsConfiguration = createAsyncThunk(
  "getFormConfiguration",
  async (requestParams, { rejectWithValue }) => {
    try {
      const response = await customFormFieldsService.getAllFormsConfiguration();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWorkFlowByCode = createAsyncThunk(
  "getWorkFlowByCode",
  async (reqParams: { workflowCode: string }, { rejectWithValue }) => {
    try {
      const response = await workFlowService.getWorkFlowByCode(reqParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateWorkFLowStep = createAsyncThunk(
  "updateWorkFLowStep",
  async (req:any, { rejectWithValue }) => {
    try {
      const response = await workFlowService.updateWorkFlowStep(req);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const workFlowExecutionSlice = createSlice({
  name: "workFlowExecution",
  initialState,
  reducers: {
    updateWorkFlowAddandEdit: (state, action) => {
      state.isWorkFlowCreated = false;
      // state.isVendorConsentUpdated = false;
      // state.isVendorConsentDeleted = false;
      state.ToasterMsg = "";
    },
    setWorkFlowData: (state, action) => {
      state.editWorkFlowData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFormsConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormsConfiguration.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.formConfiguration = action.payload?.formConfiguration;
      })
      .addCase(getAllFormsConfiguration.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getWorkFlowByCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkFlowByCode.fulfilled, (state, action: PayloadAction<any>) => {
        state.isWorkFlowCreated = true;
        let formConfig = [...state.formConfiguration];
        let data = action.payload;
        if(data?.WorkflowProcessAsJson){
          data.WorkflowProcessAsJson = JSON.parse(data?.WorkflowProcessAsJson);
        }
        if(data.WorkflowProcessAsJson && data.WorkflowProcessAsJson.length>0){
          data.WorkflowProcessAsJson = data.WorkflowProcessAsJson.map((processItem: any)=>{
            let formConfigurationItem = formConfig.filter(itemForm => itemForm.FormId === processItem.ProcessId)
            if(formConfigurationItem.length>0){
              processItem.ProcessId = formConfigurationItem[0].FormName
            }            
            return processItem
          })
        }
        state.currentStepWorkFlow = data.WorkflowProcessAsJson.map((item:any)=>{
          return item.ProcessName
        })
        data?.WorkflowProcessAsJson.every((curreItem:any) =>{
          if(!curreItem.IsProcessCompleted){
            state.currentStep = curreItem
            return false
          }else{
            return true
          }          
        })
        if(state.currentStep === null){
          state.currentStep= data.WorkflowProcessAsJson[data.WorkflowProcessAsJson.length-1]
        }
        state.workFlowsData = data;
        state.isLoading = false;
      })
      .addCase(getWorkFlowByCode.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});

export const { updateWorkFlowAddandEdit, setWorkFlowData } = workFlowExecutionSlice.actions;
export default workFlowExecutionSlice.reducer;
