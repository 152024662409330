import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AQMService from "../services/assessmentQuestionnaireMasterService";
import { IAQM, IVHAQMColumn } from "../../models/assessmentQuestionnaireMaster.type";
import { chkDate } from "../../utils/constants";
export interface IAQMData {
  AQMdataTable: any;
  isAQMLoading: boolean;
  isAddEditAQMLoading: boolean;
  isAQMAdded: boolean;
  isAQMUpdated: boolean;
  aQMversionHistory:{AQMRecords : any[] , AQMVHeader : IVHAQMColumn[]};
  isAQMtHistory: boolean;
  isAQMDeleted: boolean;
  aQMToasterMsg: string;
}

export const initialState: IAQMData = {
  AQMdataTable: [],
  isAQMLoading: false,
  isAddEditAQMLoading: false,
  isAQMAdded: false,
  isAQMUpdated: false,
  aQMversionHistory:{AQMRecords :[] , AQMVHeader : []},
  isAQMtHistory: false,
  isAQMDeleted: false,
  aQMToasterMsg: "",
};

export const getAQMList = createAsyncThunk(
  "getAQMList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await AQMService.getAQMList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAQM = createAsyncThunk("addAQM", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await AQMService.addAQM(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAQM = createAsyncThunk(
  "updateAQM",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await AQMService.updateAQM(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAQM = createAsyncThunk(
  "deleteAQM",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await AQMService.deleteAQM(reqbody);
      return response.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAQMHistory = createAsyncThunk("getAQMHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await AQMService.getVersionHistoryAQM(reqbody);
    const {AssessmentQuestionaireHistories , HeaderJson } =response.data;
    return {AQMHistories: AssessmentQuestionaireHistories , HeaderJson : HeaderJson.ColumnHeaders}
        
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const AQMDataSlice = createSlice({
  name: "assessmentQuestionnaireMaster",
  initialState,
  reducers: {
    updateAQMAddandEdit: (state, action) => {
      state.isAQMAdded = false;
      state.isAQMUpdated = false;
      state.isAQMDeleted = false;
      // state.aQMToasterMsg = '';
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.AQMdataTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.AQMdataTable];
        copyVendorData[indexVendor] = action.payload;
        state.AQMdataTable = copyVendorData;
      }
    },
    updateAQMVersionHistory:  (state, action)=>{            
      state.aQMversionHistory.AQMRecords = [];
      state.aQMversionHistory.AQMVHeader = [];
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAQMList.pending, (state) => {
        state.isAQMLoading = true;
      })
      .addCase(getAQMList.fulfilled, (state, action: PayloadAction<any>) => {
        state.AQMdataTable = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.IsEvidenceRequired = user.EvidenceRequired == true ? "Evidence Required" : "Evidence Not Required";
          return user;
        });
        state.isAQMLoading = false;
      })
      .addCase(getAQMList.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMLoading = false;
      })
      .addCase(addAQM.pending, (state) => {
        state.isAQMLoading = true;
        state.isAddEditAQMLoading = true;
      })
      .addCase(addAQM.fulfilled, (state, action: PayloadAction<any>) => {
        // state.AQMdataTable = [...state.AQMdataTable , ...action.payload  ]
        state.isAQMAdded = true;
        state.isAQMLoading = false;
        state.isAddEditAQMLoading = false;
        state.aQMToasterMsg = action.payload.Message;
      })
      .addCase(addAQM.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMLoading = false;
        state.isAddEditAQMLoading = false;
      })
      .addCase(updateAQM.pending, (state) => {
        state.isAQMLoading = true;
        state.isAddEditAQMLoading = true;
      })
      .addCase(updateAQM.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAQMUpdated = true;
        state.isAQMLoading = false;
        state.isAddEditAQMLoading = false;
        state.aQMToasterMsg = action.payload.Message;
      })
      .addCase(updateAQM.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMLoading = false;
        state.isAddEditAQMLoading = false;
      })
      .addCase(deleteAQM.pending, (state) => {
        state.isAQMLoading = true;
      })
      .addCase(deleteAQM.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAQMDeleted = true;
        state.isAQMLoading = false;
        state.aQMToasterMsg = action.payload.Message;
      })
      .addCase(deleteAQM.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMLoading = false;
      })      
      .addCase(getAQMHistory.pending, (state, action: PayloadAction<any>) => {
        state.isAQMLoading = false;
      })
      .addCase(getAQMHistory.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action);
        
        state.aQMversionHistory.AQMVHeader = action.payload.HeaderJson
        state.aQMversionHistory.AQMRecords = action.payload.AQMHistories.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.EvidenceRequired = user.EvidenceRequired === true ? "Evidence Required" : "Evidence Not Required";
          user.Approved_On = chkDate(user.Approved_On);

          return user;
        });

        state.isAQMLoading = false;
        // state.aQMToasterMsg = "AQM data deleted successfully";
      })
      .addCase(getAQMHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMLoading = false;
      })

  },
});

export const { updateAQMAddandEdit, updateUser, updateAQMVersionHistory } = AQMDataSlice.actions;
export default AQMDataSlice.reducer;
