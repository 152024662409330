import { AxiosError, AxiosResponse } from "axios";
import { MakerChecker_URL } from "./endpoints/makerChecker";

import { axiosInstance } from "./config/axiosConfig";
import { IMakerCheckerResponse } from "../../models/makerChecker";

const getMakerCheckerData = (request:any): Promise<IMakerCheckerResponse> =>
  axiosInstance
    .get(MakerChecker_URL.GET_MAKER_CHECKER, {params:request })
    .then((response: AxiosResponse<IMakerCheckerResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateMakerChecker = (requestBody: any): Promise<IMakerCheckerResponse> =>
  axiosInstance
    .post(MakerChecker_URL.UPDATE_MAKER_CHECKER,requestBody )
    .then((response: AxiosResponse<IMakerCheckerResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const makerCheckerService = {
  getMakerCheckerData,
  updateMakerChecker
};

export default makerCheckerService;
