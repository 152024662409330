import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { CONTRACT_TYPE_MASTER_URL } from "./endpoints/contractMaster";
import {
  IContractTypeMasterResponse,
  IVHColumnRes,
} from "../../../models/contractManagementModels/contractMasterModel";

const getContractTypeList = (): Promise<IContractTypeMasterResponse> =>
  axiosInstance
    .get(CONTRACT_TYPE_MASTER_URL.GetAllContractTypes)
    .then((response: AxiosResponse<IContractTypeMasterResponse>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const createContractType = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CONTRACT_TYPE_MASTER_URL.CreateContractType, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateContractType = (requestBody: any): Promise<any> =>
  axiosInstance
    .put(CONTRACT_TYPE_MASTER_URL.UpdateContractType, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const activeInactiveContractType = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(CONTRACT_TYPE_MASTER_URL.ActiveInactive, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVersionHistory = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(CONTRACT_TYPE_MASTER_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
        return response.data;
      }
      throw new Error("Error Occur " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const contractTypeMasterService = {
  getContractTypeList,
  createContractType,
  updateContractType,
  activeInactiveContractType,
  getVersionHistory,
};

export default contractTypeMasterService;
