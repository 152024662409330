export interface IDropDownOption {
  key: string;
  value: string;
  country?: string;
}

export const countryDropDown: IDropDownOption[] = [
  {
    key: "Australia",
    value: "Australia",
  },
  {
    key: "India",
    value: "India",
  },
];

export const stateDropDown: IDropDownOption[] = [
  {
    key: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
    country: "India",
  },
  {
    key: "Andhra Pradesh",
    value: "Andhra Pradesh",
    country: "India",
  },
  {
    key: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
    country: "India",
  },
  {
    key: "Assam",
    value: "Assam",
    country: "India",
  },
  {
    key: "Bihar",
    value: "Bihar",
    country: "India",
  },
  {
    key: "Chandigarh",
    value: "Chandigarh",
    country: "India",
  },
  {
    key: "Chhattisgarh",
    value: "Chhattisgarh",
    country: "India",
  },
  {
    key: "Dadra and Nagar Haveli",
    value: "Dadra and Nagar Haveli",
    country: "India",
  },
  {
    key: "Daman and Diu",
    value: "Daman and Diu",
    country: "India",
  },
  {
    key: "Delhi",
    value: "Delhi",
    country: "India",
  },
  {
    key: "Goa",
    value: "Goa",
    country: "India",
  },
  {
    key: "Gujarat",
    value: "Gujarat",
    country: "India",
  },
  {
    key: "Haryana",
    value: "Haryana",
    country: "India",
  },
  {
    key: "Himachal Pradesh",
    value: "Himachal Pradesh",
    country: "India",
  },
  {
    key: "Jammu and Kashmir",
    value: "Jammu and Kashmir",
    country: "India",
  },
  {
    key: "Jharkhand",
    value: "Jharkhand",
    country: "India",
  },
  {
    key: "Karnataka",
    value: "Karnataka",
    country: "India",
  },
  {
    key: "Kerala",
    value: "Kerala",
    country: "India",
  },
  {
    key: "Lakshadweep",
    value: "Lakshadweep",
    country: "India",
  },
  {
    key: "Madhya Pradesh",
    value: "Madhya Pradesh",
    country: "India",
  },
  {
    key: "Maharashtra",
    value: "Maharashtra",
    country: "India",
  },
  {
    key: "Manipur",
    value: "Manipur",
    country: "India",
  },
  {
    key: "Meghalaya",
    value: "Meghalaya",
    country: "India",
  },
  {
    key: "Mizoram",
    value: "Mizoram",
    country: "India",
  },
  {
    key: "Nagaland",
    value: "Nagaland",
    country: "India",
  },
  {
    key: "Odisha",
    value: "Odisha",
    country: "India",
  },
  {
    key: "Pondicherry",
    value: "Pondicherry",
    country: "India",
  },
  {
    key: "Punjab",
    value: "Punjab",
    country: "India",
  },
  {
    key: "Rajasthan",
    value: "Rajasthan",
    country: "India",
  },
  {
    key: "Sikkim",
    value: "Sikkim",
    country: "India",
  },
  {
    key: "Tamil Nadu",
    value: "Tamil Nadu",
    country: "India",
  },
  {
    key: "Telangana",
    value: "Telangana",
    country: "India",
  },
  {
    key: "Tripura",
    value: "Tripura",
    country: "India",
  },
  {
    key: "Uttar Pradesh",
    value: "Uttar Pradesh",
    country: "India",
  },
  {
    key: "Uttarakhand",
    value: "Uttarakhand",
    country: "India",
  },
  {
    key: "West Bengal",
    value: "West Bengal",
    country: "India",
  },
  {
    key: "Auckland",
    value: "Auckland",
    country: "Australia",
  },
  {
    value: "Australian Capital Territory",
    key: "Australian Capital Territory",
    country: "Australia",
  },
  {
    value: "Balgowlah",
    key: "Balgowlah",
    country: "Australia",
  },
  {
    value: "Balmain",
    key: "Balmain",
    country: "Australia",
  },
  {
    value: "Bankstown",
    key: "Bankstown",
    country: "Australia",
  },
  {
    value: "Baulkham Hills",
    key: "Baulkham Hills",
    country: "Australia",
  },
  {
    value: "Bonnet Bay",
    key: "Bonnet Bay",
    country: "Australia",
  },
  {
    value: "Camberwell",
    key: "Camberwell",
    country: "Australia",
  },
  {
    value: "Carole Park",
    key: "Carole Park",
    country: "Australia",
  },
  {
    value: "Castle Hill",
    key: "Castle Hill",
    country: "Australia",
  },
  {
    value: "Caulfield",
    key: "Caulfield",
    country: "Australia",
  },
  {
    value: "Chatswood",
    key: "Chatswood",
    country: "Australia",
  },
  {
    value: "Cheltenham",
    key: "Cheltenham",
    country: "Australia",
  },
  {
    value: "Cherrybrook",
    key: "Cherrybrook",
    country: "Australia",
  },
  {
    value: "Clayton",
    key: "Clayton",
    country: "Australia",
  },
  {
    value: "Collingwood",
    key: "Collingwood",
    country: "Australia",
  },
  {
    value: "Frenchs Forest",
    key: "Frenchs Forest",
    country: "Australia",
  },
  {
    value: "Hawthorn",
    key: "Hawthorn",
    country: "Australia",
  },
  {
    value: "Jannnali",
    key: "Jannnali",
    country: "Australia",
  },
  {
    value: "Knoxfield",
    key: "Knoxfield",
    country: "Australia",
  },
  {
    value: "Melbourne",
    key: "Melbourne",
    country: "Australia",
  },
  {
    value: "New South Wales",
    key: "New South Wales",
    country: "Australia",
  },
  {
    value: "Northern Territory",
    key: "Northern Territory",
    country: "Australia",
  },
  {
    value: "Perth",
    key: "Perth",
    country: "Australia",
  },
  {
    value: "Queensland",
    key: "Queensland",
    country: "Australia",
  },
  {
    value: "South Australia",
    key: "South Australia",
    country: "Australia",
  },
  {
    value: "Tasmania",
    key: "Tasmania",
    country: "Australia",
  },
  {
    value: "Templestowe",
    key: "Templestowe",
    country: "Australia",
  },
  {
    value: "Victoria",
    key: "Victoria",
    country: "Australia",
  },
  {
    value: "Werribee south",
    key: "Werribee south",
    country: "Australia",
  },
  {
    value: "Western Australia",
    key: "Western Australia",
    country: "Australia",
  },
  {
    value: "Wheeler",
    key: "Wheeler",
    country: "Australia",
  },
];
