import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IFormConfigurationList, CustomFieldList, LookupTablesList, LookupContent } from "../../models/customFields.type";
import customFormFieldsService from "../services/customFormFieldsService";

interface ICustomFormFieldsData {
  isLoading: boolean;
  formConfiguration: IFormConfigurationList[];
  isCustomFieldAdded: boolean;
  customFieldOptions: CustomFieldList[];
  lookupTables: LookupTablesList[];
  lookupContent: LookupContent[];
}

export const initialState: ICustomFormFieldsData = {
  isLoading: false,
  formConfiguration: [],
  isCustomFieldAdded: false,
  customFieldOptions: [],
  lookupTables: [],
  lookupContent: []
};

export const getAllFormsConfiguration = createAsyncThunk("getFormConfiguration", async (requestParams, { rejectWithValue }) => {
  try {
    const response = await customFormFieldsService.getAllFormsConfiguration();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCustomFieldOptions = createAsyncThunk("getCustomFieldOptions", async (requestParams, { rejectWithValue }) => {
  try {
    const response = await customFormFieldsService.getCustomFieldOptions();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getLookupTables = createAsyncThunk("getLookupTables", async (requestParams, { rejectWithValue }) => {
  try {
    const response = await customFormFieldsService.getLookupTables();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getLookupContent = createAsyncThunk("getLookupContent", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response = await customFormFieldsService.getLookupContent(requestParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addCustomField = createAsyncThunk("addCustomField", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await customFormFieldsService.addCustomField(requestParams);
    if (response === "" || response) {
      return requestParams;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const vendorDataSlice = createSlice({
  name: "customFormFields",
  initialState,
  reducers: {
    updateCustomAddandEdit:(state, action)=>{            
      state.isCustomFieldAdded = false      
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllFormsConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormsConfiguration.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.formConfiguration = action.payload?.formConfiguration;
      })
      .addCase(getAllFormsConfiguration.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getCustomFieldOptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomFieldOptions.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.customFieldOptions = action.payload;
      })
      .addCase(getCustomFieldOptions.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getLookupTables.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLookupTables.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.lookupTables = action.payload;
      })
      .addCase(getLookupTables.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getLookupContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLookupContent.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.lookupContent = action.payload;
      })
      .addCase(getLookupContent.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addCustomField.pending, (state) => {
        state.isCustomFieldAdded = false;
      })
      .addCase(addCustomField.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCustomFieldAdded = true;
        const { payload } = action;
        const { FormId } = payload;
        const index = state.formConfiguration.findIndex((form) => {
          return form.FormId === FormId;
        });
        if (index !== -1) state.formConfiguration[index].CustomFields.push(payload)
      })
      .addCase(addCustomField.rejected, (state, action: PayloadAction<any>) => {
        state.isCustomFieldAdded = false;
      }),
});

export const {updateCustomAddandEdit  } = vendorDataSlice.actions;
export default vendorDataSlice.reducer;
