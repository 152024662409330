import { createSlice } from "@reduxjs/toolkit";

export interface docsIdDataDetails {
  editorDocumentID: any;
}

export const initialState: docsIdDataDetails = {
  editorDocumentID: null,
};

export const editorDocSlice = createSlice({
  name: "editorDocsId",
  initialState,
  reducers: {
    setEditorDocsId: (state, action) => {
      state.editorDocumentID = action.payload;
    },
  },
});

export const { setEditorDocsId } = editorDocSlice.actions;
export default editorDocSlice.reducer;
