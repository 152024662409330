import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import securityParameterService from "../services/securityParameters";
import { ISecurityParameterData } from "../../models/securityParameters.types";
import { PARAMETER_NAME_FIELDS } from "../../constants/app.constants";
import { chkDate } from "../../utils/constants";

const initialState = {
  isLoading: false,
  isFetchingSecurityParameters: false,
  isAddingSecurityParams: false,
  securityParameters: [],
  SecurityParameterData: [],
  activeSecurityParameters: {
    [PARAMETER_NAME_FIELDS.MIN_LENGTH]: { Value: "", Description: "" },
    [PARAMETER_NAME_FIELDS.MAX_LENGTH]: { Value: "", Description: "" },
    [PARAMETER_NAME_FIELDS.MIN_LOWERCASE]: { Value: "", Description: "" },
    [PARAMETER_NAME_FIELDS.MIN_UPPERCASE]: { Value: "", Description: "" },
    [PARAMETER_NAME_FIELDS.MIN_SPECIAL_CHARACTERS]: { Value: "", Description: "" },
    [PARAMETER_NAME_FIELDS.MIN_NUMBERS]: { Value: "", Description: "" },
    [PARAMETER_NAME_FIELDS.SPECIAL_CHARACTERS]: { Value: "", Description: "" },
  },
  isAPLoading: false,
  isAPAdded: false,
  isAPUpdated: false,
  versionHistory: { HistoryRecords: [], SecuritySPHeader: [] },
  isAPDeleted: false,
  apToasterMsg: "",
};
export const getSecurityParameters = createAsyncThunk(
  "getSecurityParameters",
  async (requestParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await securityParameterService.getSecurityParameters();
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSecurityParameter = createAsyncThunk(
  "addSecurityParameter",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await securityParameterService.addSecurityParameter(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSecurityParameter = createAsyncThunk(
  "updateSecurityParameter",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await securityParameterService.updateSecurityParameter(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSecurityParameter = createAsyncThunk(
  "deleteSecurityParameter",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await securityParameterService.deleteSecurityParameter(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSecurityParameterHistory = createAsyncThunk(
  "getSecurityParameterHistory",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await securityParameterService.getSecurityParameterHistory(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const securityParameterSlice = createSlice({
  name: "securityParameter",
  initialState,
  reducers: {
    updateAPAddandEdit: (state, action) => {
      state.isAPAdded = false;
      state.isAPUpdated = false;
      state.isAPDeleted = false;
      state.apToasterMsg = "";
    },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSecurityParameters.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(getSecurityParameters.fulfilled, (state, action: PayloadAction<any>) => {
        state.SecurityParameterData = action?.payload?.Settings?.map((data: any) => {
          data.IsActive = data.IsActive === true ? "Active" : "Inactive";
          return data;
        });
        state.isAPLoading = false;
      })
      .addCase(getSecurityParameters.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(addSecurityParameter.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(addSecurityParameter.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAPAdded = true;
        state.isAPLoading = false;
        state.apToasterMsg = action.payload?.Message;
      })
      .addCase(addSecurityParameter.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(updateSecurityParameter.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(updateSecurityParameter.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAPUpdated = true;
        state.isAPLoading = false;
        state.apToasterMsg = action.payload?.Message;
      })
      .addCase(updateSecurityParameter.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(deleteSecurityParameter.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(deleteSecurityParameter.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAPDeleted = true;
        state.isAPLoading = false;
        state.apToasterMsg = action.payload.Message;
      })
      .addCase(deleteSecurityParameter.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(getSecurityParameterHistory.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(getSecurityParameterHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.SecuritySPHeader = action.payload.HeaderJson.ColumnHeaders;
        state.versionHistory.HistoryRecords = action?.payload?.SecurityParameters.map((user: any) => {
          user.Is_Multiple_Options = user.Is_Multiple_Options === true ? "Yes" : "No";
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.Approved_On = chkDate(user.Approved_On);
          return user;
        });
        state.isAPLoading = false;
        // state.ToasterMsg = "Department data deleted successfully";
      })
      .addCase(getSecurityParameterHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      });
  },
});
export const { updateAPAddandEdit } = securityParameterSlice.actions;
export default securityParameterSlice.reducer;

// import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import securityParameterService from "../services/securityParameters";
// import { ISecurityParameterData as ISecurityParameterDataRes , ISPColumnRes } from "../../models/securityParameters.types";

// export interface ISecurityParameterData {
//   SecurityParameterData: ISecurityParameterDataRes[];
//   isAPLoading: boolean;
//   isAPAdded: boolean;
//   isAPUpdated: boolean;
//   versionHistory: { HistoryRecords: ISecurityParameterDataRes[]; SecuritySPHeader: ISPColumnRes[] };
//   isAPDeleted: boolean;
//   apToasterMsg: string;
// }

// export const initialState: ISecurityParameterData = {
//   SecurityParameterData: [],
//   isAPLoading: false,
//   isAPAdded: false,
//   isAPUpdated: false,
//   versionHistory: { HistoryRecords: [], SecuritySPHeader: [] },
//   isAPDeleted: false,
//   apToasterMsg: "",
// };

// export const getSecurityParameterList = createAsyncThunk(
//   "getSecurityParameterList",
//   async (requestParams: { FormID: number }, { rejectWithValue }) => {
//     try {
//       const response = await securityParameterService.getSecurityParameters(requestParams);
//       const { data } = response;
//       return data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// export const addSecurityParameter = createAsyncThunk(
//   "addSecurityParameter",
//   async (requestParams: any, { rejectWithValue }) => {
//     try {
//       const response: any = await securityParameterService.addSecurityParameter(requestParams);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// export const updateSecurityParameter = createAsyncThunk(
//   "updateSecurityParameter",
//   async (requestParams: any, { rejectWithValue }) => {
//     try {
//       const response: any = await securityParameterService.UpdateSecurityParameter(requestParams);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// export const deleteSecurityParameter = createAsyncThunk(
//   "deleteSecurityParameter",
//   async (requestParams: any, { rejectWithValue }) => {
//     try {
//       const response: any = await securityParameterService.deleteSecurityParameter(requestParams);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// export const getSecurityParameterHistory = createAsyncThunk(
//   "getSecurityParameterHistory",
//   async (requestParams: any, { rejectWithValue }) => {
//     try {
//       const response: any = await securityParameterService.getSecurityParameterHistory(requestParams);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

// export const SecurityParameterSlice = createSlice({
//   name: "SecurityParameter",
//   initialState,
//   reducers: {
//     updateAPAddandEdit: (state, action) => {
//       state.isAPAdded = false;
//       state.isAPUpdated = false;
//       state.isAPDeleted = false;
//       state.apToasterMsg = "";
//     },
//     updateUser: (state, action) => {
//       if (action.payload.UserId) {
//         let indexVendor = state.SecurityParameterData.findIndex(
//           (data: any) => data.UserId === action.payload.UserId
//         );
//         let copyVendorData = [...state.SecurityParameterData];
//         copyVendorData[indexVendor] = action.payload;
//         state.SecurityParameterData = copyVendorData;
//       }
//     },
//     // Add reducer for updating version history if needed
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getSecurityParameterList.pending, (state) => {
//         state.isAPLoading = true;
//       })
//       .addCase(getSecurityParameterList.fulfilled, (state, action: PayloadAction<any>) => {
//         state.SecurityParameterData = action.payload.map((data: any) => {
//           data.IsActive = data.IsActive === true ? "Active" : "Inactive";
//           return data;
//         });
//         state.isAPLoading = false;
//       })
//       .addCase(getSecurityParameterList.rejected, (state, action: PayloadAction<any>) => {
//         state.isAPLoading = false;
//       })
//       .addCase(addSecurityParameter.pending, (state) => {
//         state.isAPLoading = true;
//       })
//       .addCase(addSecurityParameter.fulfilled, (state, action: PayloadAction<any>) => {
//         state.isAPAdded = true;
//         state.isAPLoading = false;
//         state.apToasterMsg = action.payload.Message;
//       })
//       .addCase(addSecurityParameter.rejected, (state, action: PayloadAction<any>) => {
//         state.isAPLoading = false;
//       })
//       .addCase(updateSecurityParameter.pending, (state) => {
//         state.isAPLoading = true;
//       })
//       .addCase(updateSecurityParameter.fulfilled, (state, action: PayloadAction<any>) => {
//         state.isAPUpdated = true;
//         state.isAPLoading = false;
//         state.apToasterMsg = action.payload.Message;
//       })
//       .addCase(updateSecurityParameter.rejected, (state, action: PayloadAction<any>) => {
//         state.isAPLoading = false;
//       })
//       .addCase(deleteSecurityParameter.pending, (state) => {
//         state.isAPLoading = true;
//       })
//       .addCase(deleteSecurityParameter.fulfilled, (state, action: PayloadAction<any>) => {
//         state.isAPDeleted = true;
//         state.isAPLoading = false;
//         state.apToasterMsg = action.payload.Message;
//       })
//       .addCase(deleteSecurityParameter.rejected, (state, action: PayloadAction<any>) => {
//         state.isAPLoading = false;
//       })
//       .addCase(getSecurityParameterHistory.pending, (state) => {
//         state.isAPLoading = true;
//       })
//       .addCase(getSecurityParameterHistory.fulfilled, (state, action: PayloadAction<any>) => {
//         state.versionHistory.SecuritySPHeader = action.payload.HeaderJson.ColumnHeaders;
//         state.versionHistory.HistoryRecords = action.payload.SecurityParameter.map((user: any) => {
//           user.IsMandatory = user.IsMandatory === true ? "Yes" : "No";
//           user.IsActive = user.IsActive === true ? "Active" : "Inactive";
//           return user;
//         });
//         state.isAPLoading = false;
//         // state.ToasterMsg = "Department data deleted successfully";
//       })
//       .addCase(getSecurityParameterHistory.rejected, (state, action: PayloadAction<any>) => {
//         state.isAPLoading = false;
//       });
//   },
// });

// export const { updateAPAddandEdit, updateUser } = SecurityParameterSlice.actions;
// export default SecurityParameterSlice.reducer;
