import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddVendorConsentResponse } from "../../models/vendorConsentMaster.type";
import { consent_URL } from "./endpoints/consentForm";

const getConsentFormList = (): Promise<any> =>

    axiosInstance
      .get(consent_URL.getVendorConsentList)
      .then((response: AxiosResponse<any>) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error("Error getting Data" + response);
      })
      .catch((error: AxiosError | string) => {
        throw error;
      });

const addVendorConsentForm = (requestBody: any): Promise<IAddVendorConsentResponse> =>
  axiosInstance.post(consent_URL.AddVendorConsentForm, requestBody).catch((error: AxiosError | string) => {
    throw error;
  });

const addWithdrawConsentForm = (requestBody: any): Promise<IAddVendorConsentResponse> =>
  axiosInstance
    .post(consent_URL.AddVendorConsentwithdraw, requestBody, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: AxiosResponse<IAddVendorConsentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const VendorConsentService = {
  addVendorConsentForm,
  addWithdrawConsentForm,
  getConsentFormList,
};

export default VendorConsentService;
