import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IServiceCatalogResponse, IServiceCatalog } from "../../models/serviceCatalog.type";
import { SERVICE_CATALOG_URL } from "./endpoints/serviceCatalog";

const getServiceCatalogList = (): Promise<IServiceCatalogResponse> =>
  axiosInstance
    .get(SERVICE_CATALOG_URL.GetAllServiceCatalog)
    .then((response: AxiosResponse<IServiceCatalogResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateServiceCatalog = (requestBody: IServiceCatalog): Promise<any> =>
  axiosInstance
    .post(SERVICE_CATALOG_URL.EditServiceCatalog, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error updating Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addServiceCatalog = (requestBody: IServiceCatalog): Promise<any> =>
  axiosInstance
    .post(SERVICE_CATALOG_URL.AddServiceCatalog, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error adding Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteServiceCatalog = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(SERVICE_CATALOG_URL.DeleteServiceCatalog, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error deleting Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const versionHistoryServiceCatalog = (params: any): Promise<any> =>
      axiosInstance
        .get(SERVICE_CATALOG_URL.versionHistoryServiceCatalog , { params })
        .then((response: AxiosResponse<any>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error deleting Service Catalog: " + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });
    
const serviceCatalogService = {
  getServiceCatalogList,
  updateServiceCatalog,
  addServiceCatalog,
  deleteServiceCatalog,
  versionHistoryServiceCatalog
};

export default serviceCatalogService;
