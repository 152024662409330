import React from "react";
import CustomDialog from "../dynamicDialog/CustomDialog";
import AddForm from "./AddForm";

import { generateFormFieldProps } from './types';

const GenerateFormField: React.FC<generateFormFieldProps> = (props) => {
  const { show, onHide, FormId, FormName } = props;

  if (!show) return null;

  return (
    <CustomDialog
      show={show}
      onHide={onHide}
      maxWidth={"md"}
      header={"Add Custom Field"}
      contentNode={<AddForm FormId={FormId} FormName={FormName} onHide={onHide} />}
    />
  );
};

export default GenerateFormField;
