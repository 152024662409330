import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IEmailTemplate, IColumnHeaders } from '../../models/authorize.type';
import emailTemplateService from '../services/emailTemplateService';
import moment from 'moment';
import { IMENU } from "../../models/roles.type";


export interface IEmailTemplateData {
    dataTable: { emailTemplate: IEmailTemplate[], emailTemplateHeader: IColumnHeaders[]; };
    isEmailLoading: boolean;
    isEmailTemplateAdded: boolean;
    isEmailTemplateUpdated: boolean;
    isEmailTemplatedeleted: boolean;
    allMenuData: IMENU[];
    emailtemplatetoasterMsg: string;
}

export const initialState: IEmailTemplateData = {
    dataTable: { emailTemplate: [], emailTemplateHeader: [] },
    isEmailLoading: false,
    isEmailTemplateAdded: false,
    isEmailTemplateUpdated: false,
    isEmailTemplatedeleted: false,
    allMenuData: [],
    emailtemplatetoasterMsg:'',
};

export const getEmailTemplateList = createAsyncThunk(
    'getEmailTemplates',
    async (_, { rejectWithValue }) => {
        try {
            const response = await emailTemplateService.getEmailTemplateList();
            const { EmailTemplates, HeaderJson } = response.data;
            // console.log("Email",EmailTemplates)
            return {
                EmailTemplates: EmailTemplates.ResponseEmailTemplates,
                HeaderJson: HeaderJson
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getAllMenuModulesList = createAsyncThunk(
    'GetAllMenus',
    async (_, { rejectWithValue }) => {
        try {
            const response = await emailTemplateService.getAllMenuModulesList();
            const data = response.data;
            // console.log("Email",EmailTemplates)
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const addEmailTemplate = createAsyncThunk('addEmailTemplate', async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response = await emailTemplateService.addEmailTemplate(requesrParams);
        return response;

    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateEmailTemplate = createAsyncThunk('updateEmailTemplate', async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        console.log("update", requesrParams);
        delete reqbody.registeredDate;
        delete reqbody.EndDate;
        const response = await emailTemplateService.updateEmailTemplate(reqbody);
        return response;

    } catch (error) {
        return rejectWithValue(error);
    }
});

export const DeleteEmailTemplete = createAsyncThunk('DeleteEmailTemplete', async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        console.log("update", requesrParams);
     
        const response = await emailTemplateService.DeleteEmailTemplete(reqbody);
        return response;

    } catch (error) {
        return rejectWithValue(error);
    }
});





export const emailTemplateDataSlice = createSlice({
    name: 'emailTemplateData',
    initialState,
    reducers: {
        updateAddandEdit: (state, action) => {
            state.isEmailTemplateAdded = false;
            state.isEmailTemplateUpdated = false;
            state.isEmailTemplatedeleted = false;
            state.emailtemplatetoasterMsg = ''
        },
        updateUser: (state, action) => {
            let indexVendor = state.dataTable.emailTemplate.findIndex(data => data.EmailTemplateId === action.payload.EmailTemplateId);
            let copyEmailData = [...state.dataTable.emailTemplate];
            copyEmailData[indexVendor] = action.payload;
            state.dataTable.emailTemplate = copyEmailData;
        },

        updateEmailTemplateRecords: (state, action) => {
            state.dataTable.emailTemplate = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEmailTemplateList.pending, (state) => {
            // state.isEmailLoading = true;
        })
            .addCase(getEmailTemplateList.fulfilled, (state, action: PayloadAction<any>) => {
                state.dataTable.emailTemplate = action.payload?.EmailTemplates && action.payload?.EmailTemplates.map((data: IEmailTemplate)=>{
                     data.IsActive =data.IsActive === 1 ? "Active" : "Inactive"; 
                     return data;
                });                
                state.dataTable.emailTemplateHeader = action.payload.HeaderJson;
                state.isEmailLoading = false;
            })
            .addCase(getEmailTemplateList.rejected, (state, action: PayloadAction<any>) => {

                state.isEmailLoading = false;
            })
            .addCase(addEmailTemplate.pending, (state) => {
                state.isEmailLoading = true;
            })
            .addCase(addEmailTemplate.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action , 'action');
                state.isEmailTemplateAdded = true;
                state.isEmailLoading = false;
                state.emailtemplatetoasterMsg = action.payload?.data?.Message;
           
                
            })
            .addCase(addEmailTemplate.rejected, (state, action: PayloadAction<any>) => {
                state.isEmailLoading = false;
            })
            .addCase(updateEmailTemplate.pending, (state) => {
                state.isEmailLoading = true;
            })
            .addCase(updateEmailTemplate.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action , 'action');
                state.isEmailTemplateUpdated = true;
                state.isEmailLoading = false;
                state.emailtemplatetoasterMsg = action.payload?.data?.Message;
            
            })
            .addCase(updateEmailTemplate.rejected, (state, action: PayloadAction<any>) => {
                state.isEmailLoading = false;
            })
            .addCase(DeleteEmailTemplete.pending, (state) => {
                state.isEmailLoading = true;
            })
            .addCase(DeleteEmailTemplete.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action , 'action');
             
                    state.isEmailLoading = false;
                    state.emailtemplatetoasterMsg = action.payload?.data?.Message;
                    state.isEmailTemplatedeleted = true;
            
            
            })
            .addCase(DeleteEmailTemplete.rejected, (state, action: PayloadAction<any>) => {
                state.isEmailLoading = false;
            })
            .addCase(getAllMenuModulesList.pending, (state) => {
                // state.isEmailLoading = true;
            })
            .addCase(getAllMenuModulesList.fulfilled, (state, action: PayloadAction<any>) => {
                state.allMenuData = action.payload;
                state.isEmailLoading = false;
            })
            .addCase(getAllMenuModulesList.rejected, (state, action: PayloadAction<any>) => {
                state.isEmailLoading = false;
            });

    }
});

export const { updateAddandEdit, updateEmailTemplateRecords } = emailTemplateDataSlice.actions;
export default emailTemplateDataSlice.reducer;