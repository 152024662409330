import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { DepartmentConfig, LocationConfig, LovConfig, MasterBulkUpload } from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import { getDepartmentList } from "../../../../store/slices/departmentData";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
import { getLocationList, getParentLocationList } from "../../../../store/slices/locationMasterData";
export const LocationImportdata: any = [
  {
    Field: "location_Type",
    FieldType: "string",
    DisplayName: "Location Type",
    config: {
      required: true,
    },
  },
  {
    Field: "location_name",
    FieldType: "string",
    DisplayName: "Location Name",
    config: {
      required: true,
    },
  },
  {
    Field: "parent_Location",
    FieldType: "string",
    DisplayName: "Parent Location",
    config: {
      required: true,
    },
  },
  {
    Field: "zone",
    FieldType: "string",
    DisplayName: "Zone",
    config: {
      required: true,
    },
  },
  {
    Field: "region",
    FieldType: "string",
    DisplayName: "Region",
    config: {
      required: true,
    },
  },
  {
    Field: "start_Pin",
    FieldType: "string",
    DisplayName: "Start Pin",
    config: {
      required: true,
    },
  },
  {
    Field: "end_Pin",
    FieldType: "string",
    DisplayName: "End Pin",
    config: {
      required: true,
    },
  },
];

const LocationMasterUploadSheet: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [locationList, setLocationList] = useState<any[]>([]);
  const [locationType, setLocationType] = useState<any[]>([]);
  const [locationZone, setLocationZone] = useState<any[]>([]);
  const [locationRegion, setLocationRegion] = useState<any[]>([]);

  useEffect(() => {
    dispatch(
      getLocationList({
        FormID: 2,
      })
    );
  }, []);
  useEffect(() => {
    const filteredLovTableZone = lovTable.filter((item: any) => item.Name === LovConfig.Zone);
    const filteredLovTableRegion = lovTable.filter((item: any) => item.Name === LovConfig.Region);
    const filteredLovTableLOCType = lovTable.filter((item: any) => item.Name === LovConfig.Location_Type);
    console.log(filteredLovTableZone, filteredLovTableRegion, filteredLovTableLOCType, "filteredLovTableLOCType");

    setLocationZone(filteredLovTableZone);
    setLocationRegion(filteredLovTableRegion);
    setLocationType(filteredLovTableLOCType);
  }, [lovTable]);

  useEffect(() => {
    setLocationList(locationTable);
  }, [locationTable]);

  const { setselectedValidation, uploaddata } = context || {};

  //validation the depatment Fields
  const department_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
      let ValidatedData: any[] = [];
      uploaddata.forEach((item: any) => {
        let error: string = "";
        let object: any = {
          ...item,
          location_Type: "",
          location_Type_Id: "",
          location_name: "",
          parent_Location: "",
          parent_Location_Id: "",
          zone: "",
          zone_Id: "",
          region: "",
          region_Id: "",
          start_Pin: "",
          end_Pin: "",
        };
        let findValidLocationtype = locationType.find((typeitem) => typeitem.Options == item["Location Type"]);
        console.log(findValidLocationtype, item["Location Type"], item, "loop item");

        let findValidZone = locationZone.find((typeitem) => typeitem.Options == item["Zone"]);
        let findValidRegion = locationRegion.find((typeitem) => typeitem.Options == item["Region"]);
        let hasOverlapError = false;
        LocationImportdata.map((header_item: any) => {
          //validation the depatment Type Fields
          if (header_item.Field === "location_Type") {
            if (findValidLocationtype?.LovId) {
              object[header_item.Field] = item[header_item.DisplayName];
              object.location_Type_Id = findValidLocationtype?.LovId;
            } else {
              if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Location Type,";
              } else {
                error += " Location Type is Required,";
              }
            }
          }
          // validation location name
          if (header_item.Field === "location_name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Location name is Required,";
            }
          }
          //validation the Parent Location
          if (header_item.Field === "parent_Location") {
            if (
              item["Location Type"] === LocationConfig?.Location_City ||
              item["Location Type"] === LocationConfig?.Location_State
            ) {
              if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                object[header_item.Field] = item[header_item.DisplayName];
                console.log(item[header_item.DisplayName], "enter Parent");

                let parentselectedvalue = item[header_item.DisplayName].split(" - ")[0];
                let findselectedParentObject = locationList.find(
                  (par_item: any) => par_item.Location_Name == parentselectedvalue
                );
                if (
                  findselectedParentObject &&
                  ((findselectedParentObject?.LocationTypeValue == LocationConfig?.Location_Country &&
                    item["Location Type"] === LocationConfig?.Location_State) ||
                    (findselectedParentObject?.LocationTypeValue == LocationConfig?.Location_State &&
                      item["Location Type"] === LocationConfig?.Location_City))
                ) {
                  object.parent_Location_Id = findselectedParentObject.Location_Id;
                } else {
                  error += " Invalid Parent Location,";
                }
              } else {
                error += " Parent Location is Required,";
              }
            }
          }

          // if city validation
          if (item["Location Type"] === LocationConfig?.Location_City) {
            if (header_item.Field === "zone") {
              if (findValidZone?.LovId) {
                object[header_item.Field] = item[header_item.DisplayName];
                object.zone_Id = findValidZone?.LovId;
              } else {
                if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                  object[header_item.Field] = item[header_item.DisplayName];
                  error += " Invalid Zone,";
                } else {
                  error += " Zone is Required,";
                }
              }
            }

            if (header_item.Field === "region") {
              if (findValidRegion?.LovId) {
                object[header_item.Field] = item[header_item.DisplayName];
                object.region_Id = findValidRegion?.LovId;
              } else {
                if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                  object[header_item.Field] = item[header_item.DisplayName];
                  error += " Invalid Region,";
                } else {
                  error += " Region is Required,";
                }
              }
            }

            if (header_item.Field === "start_Pin" || header_item.Field === "end_Pin") {
              if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                object[header_item.Field] = item[header_item.DisplayName];
                let startPin = item["Start Pin"];
                let endPin = item["End Pin"];

                for (const otherItem of uploaddata) {
                  if (otherItem !== item) {
                    const otherStartPin = otherItem["Start Pin"];
                    const otherEndPin = otherItem["End Pin"];
                    if (
                      (startPin >= otherStartPin && startPin <= otherEndPin) ||
                      (endPin >= otherStartPin && endPin <= otherEndPin) ||
                      (endPin <= otherStartPin && endPin >= otherEndPin)
                    ) {
                      hasOverlapError = true;
                      break;
                    }
                  }
                }
              } else {
                error += ` ${header_item.DisplayName} is Required`;
              }
            }
          }
        });
        if (hasOverlapError) {
          error += `Pin range overlaps with another city.`;
        }
        object.error = error;
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      department_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const hiddenSheet = workbook.addWorksheet("HiddenSheet"); // Hidden sheet for large data lists
      const noteSheet = workbook.addWorksheet("Note"); // Hidden sheet for large data lists
      let sheet_Column: any = LocationImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);
      noteSheet.getCell(`A${1}`).value = `Note`;
      noteSheet.getCell(`A${3}`).value = `Location Type Country Only Need Location Name`;
      noteSheet.getCell(`A${4}`).value = `Location Type State Need Location Name and Parent Location`;
      noteSheet.getCell(
        `A${5}`
      ).value = `Location Type City Need Location Name,Parent Location, Zone, Region, Start Pin, End Pin (startpin and endpin not overlap in other Location pin) `;
      let cityLocation_TypeId = locationType.find((item) => item.Options == LocationConfig.Location_City)?.LovId;
      let LocType = locationType?.map((item) => item.Options);
      let LocZone = locationZone?.map((item) => item.Options);
      let CouList = locationList
        ?.filter((item) => item.Location_TypeId != cityLocation_TypeId)
        .map((item) => item.Location_Name + " - " + item.LocationTypeValue);
      let LocRegion = locationRegion?.map((item) => item.Options);

      CouList.forEach((item, index) => {
        hiddenSheet.getCell(`A${index + 1}`).value = item;
      });
      hiddenSheet.state = "hidden";

      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        worksheet.getCell(`A${row}`).dataValidation = {
          type: "list",
          operator: "lessThan",
          allowBlank: true,
          formulae: [`"${LocType.join(",")}"`],
          showErrorMessage: true,
          errorTitle: "Invalid Location Type for",
          error: "Please select an Location Type from the list",
        };

        worksheet.getCell(`C${row}`).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [`HiddenSheet!$A$1:$A$${CouList.length}`],
        };
        worksheet.getCell(`D${row}`).dataValidation = {
          type: "list",
          operator: "lessThan",
          allowBlank: true,
          formulae: [`"${LocZone.join(",")}"`],
          showErrorMessage: true,
          errorTitle: "Invalid r",
          error: "Please select from the list",
        };
        worksheet.getCell(`E${row}`).dataValidation = {
          type: "list",
          operator: "lessThan",
          allowBlank: true,
          formulae: [`"${LocRegion.join(",")}"`],
          showErrorMessage: true,
          errorTitle: "Invalid",
          error: "Please select from the list",
        };
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "Location_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <CustomButton type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default LocationMasterUploadSheet;
