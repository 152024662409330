import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';

import { useSelector } from 'react-redux';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import CustomFileUploader from '../../../common/dynamicFileUploader/CustomFileUploader';
import CustomButton from '../../../common/dynamicButton/CustomButton';
import { RootState, useAppDispatchThunk } from '../../../store/store';
import { UploadAFile } from '../../../store/slices/fileHandler';

export interface uploadFileProps {
    view?: boolean;
    closePopUp?: () => void;
    onSubmit: (url:string) => void;
    fileFormats?: string[];
    edit?: boolean;
    isEditData?: any;
}

export const UploadFiles: React.FC<uploadFileProps> = ({
    view, closePopUp, edit, isEditData,onSubmit,fileFormats }) => {

    const dispatch = useAppDispatchThunk();
    const [uploadedFile, setUploadedFile] = useState<null|File>(null);

    const handleSubmit = async () => {
        if(uploadedFile) {
            const formData = new FormData();
              formData.append("fileUploadRequest", uploadedFile);
              const getUploadUrl = await dispatch(UploadAFile(formData));
            onSubmit(getUploadUrl.payload)
        }
    };

    return (
        <Grid
            container
            component="main"
            sx={{ height: "auto", width: "100%", overflow: "hidden" }}
        >
            <Grid
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >
                <Grid sx={{width: "100%"}}>
                    <Box
                        sx={{
                            px: {xs: 2,sm: 2,md: 2,lg: 2, xl: 2,},
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box>
                                        <CustomFileUploader
                                            onFileUpload={(file)=>{setUploadedFile(file?.[0] || null)}}
                                            maxFileSize={1048576}
                                            acceptFormats={ ( fileFormats && fileFormats?.length > 0) ? fileFormats?.map((i)=>`.${i.trim().toLowerCase()}`):[".pdf", ".png", ".xlsx", ".docx", ".ppt", ".ppts", ".csv"] }
                                        />
                                    </Box>

                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "right",
                                    mt: 2,
                                }}
                            >
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                setUploadedFile(null);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton type="reset" name={"Upload"} onClick={handleSubmit} />
                                    </React.Fragment>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
