import { Box, FormHelperText, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface CustomProfileUploaderProps {
  onFileUpload: (files: File[] | null) => void;
  acceptFormats: string[];
  maxFileSize: number;
  title?: string;
  setImage?: (file: any) => void;
  // acceptFormats :
}

const CustomProfileUploader: React.FC<CustomProfileUploaderProps> = ({
  onFileUpload,
  acceptFormats,
  maxFileSize,
  title,
  setImage,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { user } = useSelector((state: RootState) => state.loggerData);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Update state with accepted files
      onFileUpload(null);
      setFiles([]);
      if (validateFile(acceptedFiles)) {
        setFiles(acceptedFiles);
        if (setImage) {
          setImage(URL.createObjectURL(acceptedFiles[0]));
        }
        onFileUpload(acceptedFiles);
      }

      // Call the onFileUpload callback with the accepted files
    },
    [onFileUpload]
  );

  const removeFile = () => {
    setFiles([]);
    setError("");
    onFileUpload(null);
  };

  const validateFile = (file: File[]): boolean => {
    // Check file format
    console.log(file);

    // const isValidFormat = acceptFormat.some(format => file.type.startsWith(format));
    const isValidFormat = acceptFormats.some((format) => file[0].name.endsWith(format));
    if (!isValidFormat) {
      setError(`Invalid file format. Accepted formats: ${acceptFormats.join(", ")}`);
      return false;
    }

    // Check file size
    if (file[0].size > maxFileSize) {
      setError(`File size exceeds the maximum limit of ${maxFileSize / (1024 * 1024)} MB`);
      return false;
    }
    setError("");
    return true;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Box
        sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}
      >
        <Box
          {...getRootProps()}
          style={dropzoneStyles}
          sx={{ border: error ? "1px dashed #D70000" : "1px dashed #6A6A6A" }}
        >
          <input {...getInputProps()} />

          {/* <CustomSvg name={"upload-cloud"} size={"20px"} />

          <Typography sx={{ fontSize: "16px" }} variant="h6" color={"text.primary"}>
            {`Drag and drop the ${title ? title : "file"} here`}
          </Typography> */}
          <Avatar
            alt={user?.UserName}
            sx={{ width: 200, height: 200, fontSize: 100 }}
            src="/static/images/avatar/2.jpg"
          />

          {/* <Typography sx={{fontSize:'16px'}} variant="h6" color={"text.primary"}>
          or{" "}
        </Typography>

        <CustomButton
          variant="text"
          name={`Browse file`}
          size="small"
          onClick={() => {
            getInputProps();
          }}
        /> */}
        </Box>

        {files.map((file, index) => (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box key={index} sx={{ display: "flex" }}>
              <Typography sx={{ color: "#909090" }}>Selected file : </Typography>{" "}
              <Typography color={"text.primary"}>
                {" "}
                {file.name} - {formatBytes(file.size)}
              </Typography>
            </Box>

            <Typography sx={{ color: "#1D6DE5", cursor: "pointer" }} onClick={() => removeFile()}>
              Remove file
            </Typography>
          </Box>
        ))}
        <FormHelperText error={Boolean(error)} id="my-helper-text">
          {error}
        </FormHelperText>
      </Box>
    </>
  );
};

// Style for the dropzone
// const dropzoneStyles: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",

//   borderRadius: "6px",
//   padding: "10px",
//   textAlign: "center",
//   cursor: "pointer",
//   marginBottom: "8px",
//   height: "100%",
//   minHeight: "70px",
//   // display:'flex',
//   // alignItems:'center',
//   // justifyContent:'center' ,
//   // flexDirection:'column'
// };
const dropzoneStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  padding: "10px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "8px",
  height: "200px",
  width: "200px",
};

// Style for images
const imageStyles: React.CSSProperties = {
  maxWidth: "100%",
  maxHeight: "200px",
};

// Function to format file size
const formatBytes = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export default CustomProfileUploader;
