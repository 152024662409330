import {
  Box,
  Collapse,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  MenuProps,
  Backdrop,
  keyframes,
  Drawer,
} from "@mui/material";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSvg from "../../common/CustomSvg";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { updateMenu } from "../../store/slices/menu";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import FeatherIcon from "feather-icons-react";
import { menuIcons } from "../../utils/constants";
import menuStyle from "./sideNavBar.module.scss";
import { addFavouriteNav, getFavouriteNavMenu, updateFavouriteNavAddandEdit } from "../../store/slices/FavouriteNavData";
import { getMenuByRolesId } from "../../store/slices/authorization";
import { updateToaster } from "../../store/slices/loader";

interface ISearchNavBarProps {
  handleOpen: (data: any) => void;
  setIsBackDropIsOpen: any;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "10px 0px 0px 0px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  border: "1px solid",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  //   boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset",
  borderColor: alpha(theme.palette.common.black, 0.15),

  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const SearchIconWrapperIcon = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 1,
}));
const SearchIconWrapperend = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  // pointerEvents: "none",
  right: 1,
  top: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    // transition: theme.transitions.create("width"),
    width: "90%",
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
  },
}));
const slideIn = keyframes`
  from {
    height: 0;
  }
  to {
    height: 25px;
  }
`;

const getIndicatorStyle = (isActive: any) => {
  return isActive
    ? {
      position: "relative",
      "::before": {
        background: "var(--primary-color)",
        height: "25px",
        width: "2px !important",
        content: '""',
        display: "inline-block",
        // animation: `${slideIn} 0.5s ease-out`,
        // position: 'absolute',
        // left: '-8px' // Adjust the position if necessary
      },
    }
    : {};
};

const getIndicatorClassName = (isActive: any) => {
  return isActive ? "indicate" : "";
};

const StyledBackdrop = styled(Backdrop)`
  left: 70px;
`;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    // anchorOrigin={{
    //   vertical: "bottom",
    //   horizontal: "right",
    // }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    // BackdropProps={{
    //   components: {
    //     Root: StyledBackdrop
    //   }
    // }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiBackdrop-root": {
    left: "70px",
  },
  "& .MuiPaper-root": {
    // borderRadius: 6,
    // minWidth: 250,
    // backgroundColor: "#fff", // Change background color to white
    // color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    // boxShadow:
    //   "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    // "& .MuiMenu-list": {
    //   padding: "0 0",
    // },
    // "& .MuiMenuItem-root": {
    //   "& .MuiSvgIcon-root": {
    //     fontSize: 18,
    //     color: theme.palette.text.secondary,
    //     marginRight: theme.spacing(1.5),
    //   },
    //   "&:active": {
    //     backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    //   },
    // },
    minWidth: 0,
  },
}));

const menuItems = [
  { name: "users", label: "Users" },
  { name: "file-text", label: "Files" },
  { name: "bar-chart", label: "Charts" },
  { name: "settings", label: "Settings" },
  { name: "shield", label: "Security" },
];

const Sidenavbar: React.FC<ISearchNavBarProps> = ({ handleOpen, setIsBackDropIsOpen }) => {
  let side_menus = [
    {
      RoleId: 2,
      RoleName: "Admin",
      MenuId: 2,
      MenuName: "Favourites",
      icon: "star",
      Url: null,
      ParentId: 0,
      IsParent: 1,
      IsAdd: 1,
      IsEdit: 1,
      IsDelete: 1,
      IsView: 1,
      IsExport: 1,
      IsImport: 1,
      SubMenus: [],
    },
    {
      RoleId: 0,
      RoleName: "",
      MenuId: 35,
      MenuName: "Queue Management",
      Icon: null,
      Url: null,
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 0,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 0,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [
        {
          RoleId: 0,
          RoleName: "",
          Icon: null,
          MenuId: 36,
          MenuName: "Masters",
          Url: null,
          ParentId: 35,
          IsParent: 2,
          FormId: 0,
          IsAdd: 0,
          IsEdit: 0,
          IsDelete: 0,
          IsView: 0,
          IsExport: 0,
          IsImport: 0,
          IsApproval: 0,
          SubMenus: [
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 38,
              MenuName: "Pending Approval",
              Url: "/pendingApproval",
              ParentId: 36,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
          ],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 0,
          RoleName: "",
          Icon: null,
          MenuId: 37,
          MenuName: "WorkFlow",
          Url: null,
          ParentId: 35,
          IsParent: 2,
          FormId: 0,
          IsAdd: 0,
          IsEdit: 0,
          IsDelete: 0,
          IsView: 0,
          IsExport: 0,
          IsImport: 0,
          IsApproval: 0,
          SubMenus: [
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 39,
              MenuName: "Pending Actions",
              Url: "/pendingActions",
              ParentId: 37,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
          ],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
      ],
    },
    {
      RoleId: 0,
      RoleName: "",
      MenuId: 13,
      MenuName: "Vendor Management",
      Icon: null,
      Url: "/vendor_managementt",
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 0,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 0,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [
        {
          RoleId: 0,
          RoleName: "",
          Icon: null,
          MenuId: 27,
          MenuName: "Vendor Onboarding",
          Url: null,
          ParentId: 13,
          IsParent: 2,
          FormId: 0,
          IsAdd: 0,
          IsEdit: 0,
          IsDelete: 0,
          IsView: 0,
          IsExport: 0,
          IsImport: 0,
          IsApproval: 0,
          SubMenus: [
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 51,
              MenuName: "Vendor Assessment",
              Url: "/vendorassessment",
              ParentId: 27,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 52,
              MenuName: "Assessment History",
              Url: "/assessmentHistory",
              ParentId: 27,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
          ],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 0,
          RoleName: "",
          Icon: null,
          MenuId: 5,
          MenuName: "Masters",
          Url: "",
          ParentId: 13,
          IsParent: 2,
          FormId: 0,
          IsAdd: 0,
          IsEdit: 0,
          IsDelete: 0,
          IsView: 0,
          IsExport: 0,
          IsImport: 0,
          IsApproval: 0,
          SubMenus: [
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 28,
              MenuName: "Service Catalog Master",
              Url: "/serviceCatalog",
              ParentId: 5,
              IsParent: 0,
              FormId: 1,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 40,
              MenuName: "Assessment Questionnaire Master",
              Url: "/assessmentQuestionnaireMaster",
              ParentId: 5,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 48,
              MenuName: "Vendor Master",
              Url: "/vendorMaster",
              ParentId: 5,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 49,
              MenuName: "Risk Category Master",
              Url: "/riskcategorymaster",
              ParentId: 5,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 53,
              MenuName: "Metrics Master",
              Url: "/metricsMaster",
              ParentId: 5,
              IsParent: 0,
              FormId: 25,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 55,
              MenuName: "Work Flow History",
              Url: "/workflowhistory",
              ParentId: 5,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
          ],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
      ],
    },    
    {
      RoleId: 0,
      RoleName: "",
      MenuId: 41,
      MenuName: "Contract Management",
      Icon: null,
      Url: null,
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 0,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 0,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 54,
          MenuName: "Text Editor",
          Url: "/textEditor",
          ParentId: 41,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 54,
          MenuName: "Add New Clause",
          Url: "/clauseTypeLibrary",
          ParentId: 41,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 54,
          MenuName: "Add New Contract Template",
          Url: "/contract-templates/",
          ParentId: 41,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 54,
          MenuName: "Add New Contract Template",
          Url: "/contract-templates/",
          ParentId: 41,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
       
      ],
    },
    {
      RoleId: 1,
      RoleName: "admin",
      MenuId: 42,
      MenuName: "Performance Management",
      Icon: null,
      Url: null,
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 1,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 1,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [],
    },
    {
      RoleId: 0,
      RoleName: "",
      MenuId: 43,
      MenuName: "Configuration Management",
      Icon: null,
      Url: null,
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 0,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 0,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [
        {
          RoleId: 0,
          RoleName: "",
          Icon: null,
          MenuId: 44,
          MenuName: "Masters",
          Url: null,
          ParentId: 43,
          IsParent: 2,
          FormId: 0,
          IsAdd: 0,
          IsEdit: 0,
          IsDelete: 0,
          IsView: 0,
          IsExport: 0,
          IsImport: 0,
          IsApproval: 0,
          SubMenus: [
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 30,
              MenuName: "Company Master",
              Url: "/companymaster",
              ParentId: 44,
              IsParent: 0,
              FormId: 19,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 31,
              MenuName: "Branch Master",
              Url: "/branchmaster",
              ParentId: 44,
              IsParent: 0,
              FormId: 14,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 17,
              MenuName: "Location Master",
              Url: "/location",
              ParentId: 44,
              IsParent: 0,
              FormId: 9,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 14,
              MenuName: "Department Master",
              Url: "/departmentmaster\r\n",
              ParentId: 44,
              IsParent: 0,
              FormId: 7,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 16,
              MenuName: "Industry Master",
              Url: "/industry",
              ParentId: 44,
              IsParent: 0,
              FormId: 8,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 18,
              MenuName: "Document Master",
              Url: "/documentmaster",
              ParentId: 44,
              IsParent: 0,
              FormId: 12,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 20,
              MenuName: "Currency Master",
              Url: "/currencymaster",
              ParentId: 44,
              IsParent: 0,
              FormId: 13,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 33,
              MenuName: "Third Party Master",
              Url: "/thirdpartymaster",
              ParentId: 44,
              IsParent: 0,
              FormId: 10,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 26,
              MenuName: "Content Master",
              Url: "/vendorConsentForm",
              ParentId: 44,
              IsParent: 0,
              FormId: 15,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 25,
              MenuName: "Bulk upload",
              Url: "/bulkupload",
              ParentId: 44,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
          ],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 0,
          RoleName: "",
          Icon: null,
          MenuId: 21,
          MenuName: "Configuration",
          Url: null,
          ParentId: 43,
          IsParent: 2,
          FormId: 0,
          IsAdd: 0,
          IsEdit: 0,
          IsDelete: 0,
          IsView: 0,
          IsExport: 0,
          IsImport: 0,
          IsApproval: 0,
          SubMenus: [
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 15,
              MenuName: "List Of Values",
              Url: "/listofvalues",
              ParentId: 21,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 32,
              MenuName: "Application Parameter",
              Url: "/applicationparameter",
              ParentId: 21,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 34,
              MenuName: "Regional Settings",
              Url: "/regionalsettings",
              ParentId: 21,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 22,
              MenuName: "Maker Checker Configuration",
              Url: "/makerChecker",
              ParentId: 21,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 45,
              MenuName: "Workflow Manager",
              Url: "/workFlows",
              ParentId: 21,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
          ],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
      ],
    },
    {
      RoleId: 0,
      RoleName: "",
      MenuId: 46,
      MenuName: "Security Management",
      Icon: null,
      Url: null,
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 0,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 0,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [
        {
          RoleId: 0,
          RoleName: "",
          Icon: null,
          MenuId: 47,
          MenuName: "Masters",
          Url: null,
          ParentId: 46,
          IsParent: 2,
          FormId: 0,
          IsAdd: 0,
          IsEdit: 0,
          IsDelete: 0,
          IsView: 0,
          IsExport: 0,
          IsImport: 0,
          IsApproval: 0,
          SubMenus: [
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 3,
              MenuName: "User Master",
              Url: "/users",
              ParentId: 47,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 1,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 4,
              MenuName: "Role Master",
              Url: "/roles",
              ParentId: 47,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 1,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
            {
              RoleId: 1,
              RoleName: "admin",
              Icon: null,
              MenuId: 29,
              MenuName: "Security Parameters",
              Url: "/security_parameter",
              ParentId: 47,
              IsParent: 0,
              FormId: 0,
              IsAdd: 1,
              IsEdit: 1,
              IsDelete: 1,
              IsView: 1,
              IsExport: 1,
              IsImport: 0,
              IsApproval: 1,
              SubMenus: null,
              Id: 0,
              IsFavourite: 0,
              Source: null,
            },
          ],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
      ],
    },
    {
      RoleId: 0,
      RoleName: "",
      MenuId: 10,
      MenuName: "Logs",
      Icon: null,
      Url: "",
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 0,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 0,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 11,
          MenuName: "Event Logs",
          Url: "/eventLogs",
          ParentId: 10,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 12,
          MenuName: "Error Logs",
          Url: "/errorlog",
          ParentId: 10,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
      ],
    },
    {
      RoleId: 0,
      RoleName: "",
      MenuId: 7,
      MenuName: "Template Configuration",
      Icon: null,
      Url: "",
      ParentId: 0,
      IsParent: 1,
      FormId: 0,
      IsAdd: 0,
      IsEdit: 0,
      IsDelete: 0,
      IsView: 0,
      IsExport: 0,
      IsApproval: 0,
      IsImport: 0,
      SubMenus: [
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 8,
          MenuName: "Email Template",
          Url: "/emailtemplate",
          ParentId: 7,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
        {
          RoleId: 1,
          RoleName: "admin",
          Icon: null,
          MenuId: 9,
          MenuName: "Toaster Template",
          Url: "/toasterTemplate",
          ParentId: 7,
          IsParent: 0,
          FormId: 0,
          IsAdd: 1,
          IsEdit: 1,
          IsDelete: 1,
          IsView: 1,
          IsExport: 1,
          IsImport: 0,
          IsApproval: 1,
          SubMenus: [],
          Id: 0,
          IsFavourite: 0,
          Source: null,
        },
      ],
    },
  ];
  const theme = useTheme();
  const navigate = useNavigate();
  const styledMenuRef = useRef<HTMLDivElement>(null);
  const [activeItem, setActiveItem] = useState(null);
  const [ActiveIconItem, setActiveIconItem] = useState(null);
  const [activeItem_sub, setActiveItem_sub] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [activeItem_open, setActiveItem_open] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [Admin_Main_sidenav, setAdmin_Main_sidenav] = useState<any>([]);
  const [filteredAdmin_Main_sidenav, setfilteredAdmin_Main_sidenav] = useState<any>([]);
  const [sideBarWidth, setsideBarWidth] = useState(70);
  const [NavigateCount, setNavigateCount] = useState(0);
  const [addSideBar, setAddSideBar] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [Filtermenu, setFiltermenu] = useState<any>([]);
  const newRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const currentPath = useLocation().pathname;
  const { themeMode, show } = useSelector((state: RootState) => state.menu);
  const { user, menuData, userLoginResponse, currentPagePermissionData} = useSelector((state: RootState) => state.loggerData);
  const { isFavAdded, FavouriteNavMenuData, ToasterMsg } = useSelector((state: RootState) => state.FavouriteNavData);
  const { isShown } = useSelector((state: RootState) => state.menuShown);
  
  useEffect(() => {
    if (menuData) {

      //Server menu to load menu from backend enable this section
      let menuDataCopy = [...menuData];
      let unique = menuDataCopy.filter((obj, index) => {
        return index === menuDataCopy.findIndex((o) => obj.MenuName === o.MenuName);
      });


      // To load local menu enable this section
      // let menuDataCopy = [...side_menus]; 
      // let unique:any = menuDataCopy.filter((obj, index) => {
      //   return index === menuDataCopy.findIndex((o) => obj.MenuName === o.MenuName);
      // });

      unique = unique.map((obj:any, index:number) => {
        let filteredData = menuIcons.filter((menItem) => obj.MenuName === menItem.MenuName);
        if (filteredData.length > 0) {
          obj = { ...obj, ...filteredData[0] };
        }
        return obj;
      });
      setAdmin_Main_sidenav(unique);
      
    }
  }, [menuData]);
  useEffect(() => {
    const findActiveItem = (menu: any, parentMenuName: string | null = null) => {
      menu.SubMenus?.forEach((sublink: any) => {
        if (sublink.Url?.trim() === currentPath.trim() && sublink.Url !== "") {
          setActiveIconItem(parentMenuName || menu.MenuName);
        }

        if (sublink.SubMenus) {
          findActiveItem(sublink, menu.MenuName);
        }
      });
    };

    if (Admin_Main_sidenav) {
      Admin_Main_sidenav.forEach((link: any) => {
        findActiveItem(link);
      });
    }
  }, [Admin_Main_sidenav, currentPath]);  

  const favmenu = {
    "RoleId": 1,
    "RoleName": "admin",
    "MenuId": 41,
    "MenuName": "Favourites",
    "Icon": null,
    "Url": null,
    "ParentId": 0,
    "IsParent": 1,
    "FormId": 0,
    "IsAdd": 1,
    "IsEdit": 0,
    "IsDelete": 0,
    "IsView": 1,
    "IsExport": 0,
    "IsApproval": 0,
    "IsImport": 0,
    "SubMenus": FavouriteNavMenuData,
    "icon": "star"
  };
  const searchMenu = {
    "RoleId": 1,
    "RoleName": "admin",
    "MenuId": 41,
    "MenuName": "Search",
    "Icon": null,
    "Url": null,
    "ParentId": 0,
    "IsParent": 1,
    "FormId": 0,
    "IsAdd": 1,
    "IsEdit": 0,
    "IsDelete": 0,
    "IsView": 1,
    "IsExport": 0,
    "IsApproval": 0,
    "IsImport": 0,
    "SubMenus": [],
    "icon": "search"
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>, item: any, openORNot: any) => {
    // event.preventDefault();

    let filteredMenu = [];

    if (item.MenuName === "Favourites") {
      filteredMenu = [favmenu];
    } else {
      filteredMenu = Admin_Main_sidenav.filter((menu: any) => menu.MenuName === item.MenuName);

    }

    setFiltermenu(filteredMenu.flatMap((menu: any) => menu.SubMenus));
    setSearchValue("");
    setfilteredAdmin_Main_sidenav([])
    if (item.MenuName === ActiveIconItem) {
      if (openORNot) {
        setAnchorElUser(null);
      } else {
        setAnchorElUser(event.currentTarget);
      }
      setActiveIconItem(item.MenuName);
      setActiveItem_open((prev) => !prev);
    } else {
      setActiveIconItem(item.MenuName);
      setAnchorElUser(event.currentTarget);
      setActiveItem_open(true);
      if (currentPath && item.SubMenus) {
        const matchedSubMenu = item.SubMenus.find((subMenu: any) =>
          subMenu.SubMenus?.some((data: any) => data.Url === currentPath)
        );
        // const textdata = item.SubMenus.map((subMenu: any) => {
        //   if (subMenu.SubMenus) {
        //     return subMenu.SubMenus.map((data: any) => ({ Url: data.Url, MenuName: data.MenuName }));
        //   } else {
        //     return [];
        //   }
        // });
        // console.log("matchedSubMenu", matchedSubMenu, matchedSubMenu?.MenuName, currentPath, textdata, item.SubMenus);
        setActiveItem_sub(
          matchedSubMenu ? matchedSubMenu.SubMenus.find((data: any) => data.Url === currentPath)?.MenuName : null
        );
      } else {
        // setIsExpanded(false);
        setActiveItem_sub(null);
      }
    }
  };

  const handleCloseUserMenu = () => {
    // console.log(activeItem, activeItem_open, anchorElUser, "colse");
    setActiveItem_open(false);
    setSearchValue("");
    setAnchorElUser(null);
  };

  const handleItemClick = (item: any) => {
    // setsideBarWidth(270);
    // setAnchorElUser(event.currentTarget);

    if (item.SubMenus && item.SubMenus.length > 0) {
      if (item.MenuName === activeItem) {
        setActiveItem_open(true);
        setActiveItem(null);
      } else {
        setActiveItem(item.MenuName);
        setActiveItem_open(true);
        if (currentPath && item.SubMenus) {
          const matchedSubMenu = item.SubMenus.find((subMenu: any) => subMenu.Url == currentPath);
          setActiveItem_sub(matchedSubMenu?.MenuName);
          if (matchedSubMenu) {
          }
        } else {
          setActiveItem_sub(null);
        }
      }
    } else {
      setActiveItem(item.MenuName);
      setActiveItem_open(true);
      if (item.Url) {
        navigate(item.Url);
        setSearchValue("");

        // if(NavigateCount == 1){
        //   setSearchValue("")
        //   setNavigateCount(0)
        // }
      }
      if (currentPath && item.SubMenus) {
        const matchedSubMenu = item.SubMenus.find((subMenu: any) => subMenu.Url == currentPath);
        setActiveItem_sub(matchedSubMenu?.MenuName);
        if (matchedSubMenu) {
        }
      }
    }
  };

  const handleItemClick_sub = (event: React.MouseEvent<HTMLElement>, SubItem: any, item: any) => {
    setActiveItem_sub(SubItem.MenuName);
    setActiveItem(item.MenuName);
    if (SubItem.Url) {
      navigate(SubItem.Url);

      setSearchValue("");
    }
  };

  const handleFaviconClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    let formData = {
      
      UserId: userLoginResponse?.UserId,
      Id: item.Id,
      Source: "Role",
      // CreatedBy: userLoginResponse?.UserName,
      CreatedBy: userLoginResponse?.UserId,
      ModuleName: "Favourites",
      Activity: "add",
      MenuId: item?.MenuId,
      Form_Id: 0,
      
    };
    dispatch(addFavouriteNav(formData));
    setActiveItem_open(false);

  };

  useEffect(() => {
    if (isFavAdded) {
      dispatch(updateFavouriteNavAddandEdit(false));
      dispatch(getMenuByRolesId({
        RoleId: userLoginResponse && userLoginResponse.RoleId,
        UserId: userLoginResponse && userLoginResponse.UserId
      }));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === false ? true : false }));

      dispatch(
        getFavouriteNavMenu({
          UserId: userLoginResponse && userLoginResponse.UserId,
        })
      );
    }
  }, [isFavAdded]);

  const handleFaviconRemove = (event: React.MouseEvent<HTMLElement>, item: any) => {

    let formData = {
      
      UserId: userLoginResponse?.UserId,
      Id: item.Id,
      Source: "Role",
      // CreatedBy: userLoginResponse?.UserName,
      CreatedBy: userLoginResponse?.UserId,
      ModuleName: "Favourites",
      Activity: "delete",
      MenuId: item?.MenuId,
      Form_Id: 0,
      
    };
    dispatch(addFavouriteNav(formData));
    setActiveItem_open(false);
    setSearchValue("");
    setAnchorElUser(null);

  };

  // const handleCloseUserMenu = () => {
  //    // console.log("close");

  //   setAnchorElNav(null);
  // };

  useEffect(() => {
    // if(show){
    //   setsideBarWidth(270);
    // }else{
    //   setsideBarWidth(70);
    // }
  }, [show]);

  const sidenavclose = (value: any) => {
    // dispatch(updateMenu(!show));
    setsideBarWidth(value);
  };

  const serachMenu = (value: any) => {
    // dispatch(updateMenu(!show));
    setfilteredAdmin_Main_sidenav([])
    setSearchValue(value);
  };

  function searchMenuByName(menuData: any[], query: string): any[] {
    const filteredMenus: any[] = [];

    menuData.forEach((menu) => {
      const changedMenu = { ...menu };

      if (menu.SubMenus) {
        changedMenu.SubMenus = [];
      }

      if (menu.MenuName.toLowerCase().includes(query.toLowerCase())) {
        filteredMenus.push(changedMenu);
      }

      if (menu.SubMenus) {
        menu.SubMenus.forEach((subMenu: any) => {
          if (subMenu.MenuName.toLowerCase().includes(query.toLowerCase())) {
            const parentIndex = filteredMenus.findIndex((item) => item.MenuId === menu.MenuId);
            if (parentIndex !== -1) {
              filteredMenus[parentIndex].SubMenus.push(subMenu);
            } else {
              changedMenu.SubMenus.push(subMenu);
              filteredMenus.push(changedMenu);
            }
          }
        });
      }
    });

    return filteredMenus;
  }

  useEffect(() => {
    if (!searchValue) {
      setfilteredAdmin_Main_sidenav(Filtermenu);
      //setfilteredAdmin_Main_sidenav(side_menus);
    } else {
      // console.log(Admin_Main_sidenav);
      let filteredValue 
      if(ActiveIconItem === 'Search'){
        const globalFiltermenu = Admin_Main_sidenav.flatMap((menu: any) => menu.SubMenus)
        filteredValue = searchMenuByName(globalFiltermenu, searchValue);
      }
      else
        filteredValue = searchMenuByName(Filtermenu, searchValue)
    
      setfilteredAdmin_Main_sidenav(filteredValue);
    }
  }, [searchValue, Filtermenu]);

  useEffect(() => {
    let matched = false;

    filteredAdmin_Main_sidenav?.some((link: any) => {
      if (matched) return true;

      // if (currentPath === link.Url && link.Url !== "") {
      //   setActiveItem(link.MenuName);
      //   // setActiveItem_open(true);
      //   matched = true;
      //   return true;
      // }

      link.SubMenus?.some((sublink: any) => {
        if (sublink.Url.trim() === currentPath.trim() && sublink.Url !== "") {
          setActiveItem(link.MenuName);
          setActiveItem_sub(sublink.MenuName);
          // setActiveItem_open(true);
          matched = true;

          return true;
        } else {
          setActiveItem(null);
          setActiveItem_sub(null);
        }
        return false;
      });

      return false;
    });
  }, [currentPath, filteredAdmin_Main_sidenav]);
  // useEffect(() => {
  //   console.log("currentPath", currentPath);

  //   filteredAdmin_Main_sidenav?.forEach((link: any) => {
  //     if (currentPath === link.Url && link.Url !== "") {
  //       console.log("currentPathmatchh", link.Url);

  //       setActiveItem(link.MenuName);
  //       setActiveItem_open(true);
  //     }
  //     link.SubMenus?.forEach((sublink: any) => {
  //       if (sublink.Url.trim() === currentPath.trim() && sublink.Url !== "") {
  //         setActiveItem(link.MenuName);
  //         setActiveItem_sub(sublink.MenuName);
  //         setActiveItem_open(true);
  //       }
  //     });
  //   });
  // }, [currentPath, Admin_Main_sidenav]);

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        setActiveItem_open(false);
        setIsBackDropIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setActiveItem_open]);
  // useEffect(() => {
  //   handleOpen(activeItem_open);
  // }, [activeItem_open]);
  // useEffect(() => {
  //   if (activeItem_open) {
  //     setIsBackDropIsOpen(true);
  //   } else {
  //     setIsBackDropIsOpen(false);
  //   }
  // }, [activeItem_open]);

  const SearchIconSidenav = () => {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Tooltip title={searchMenu.MenuName} placement="right-start">
            <Box
              onClick={(e) => {
                handleOpenUserMenu(e, searchMenu, activeItem_open);
              }}
              sx={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50px",
                cursor: "pointer",
                backgroundColor: `${ActiveIconItem === searchMenu.MenuName ? `${theme.palette.primary.main}` : "background.default"
                  }`,
                boxShadow: `${ActiveIconItem === searchMenu.MenuName ? `1px 3px 6px 0px ${theme.palette.primary.main}24` : ""}`,
                color: `${ActiveIconItem === searchMenu.MenuName ? "white" : `${theme.palette.primary.main}`}`,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomSvg
                  color={ActiveIconItem === searchMenu.MenuName ? "white" : `${theme.palette.primary.main}`}
                  size="22px"
                  // key={"Fav_star_l1"}
                  name={searchMenu.icon}
                />
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </>
    )
  }

  const FavIconSidenav = () => {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Tooltip title={favmenu.MenuName} placement="right-start">
            <Box
              onClick={(e) => {
                handleOpenUserMenu(e, favmenu, activeItem_open);
              }}
              sx={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "50px",
                cursor: "pointer",
                backgroundColor: `${ActiveIconItem === favmenu.MenuName ? `${theme.palette.primary.main}` : "background.default"
                  }`,
                boxShadow: `${ActiveIconItem === favmenu.MenuName ? `1px 3px 6px 0px ${theme.palette.primary.main}24` : ""}`,
                color: `${ActiveIconItem === favmenu.MenuName ? "white" : `${theme.palette.primary.main}`}`,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomSvg
                  color={ActiveIconItem === favmenu.MenuName ? "white" : `${theme.palette.primary.main}`}
                  size="22px"
                  // key={"Fav_star_l1"}
                  name={favmenu.icon}
                />
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </>
    )
  }
  const memoizedSidenav = () => {
    return Admin_Main_sidenav?.map((l: any) => {
      return (
        <>
          <Box key={l.MenuName + l.RoleId + l.RoleName} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Tooltip title={l.MenuName} placement="right-start">
              <Box
                onClick={(e) => {
                  handleOpenUserMenu(e, l, activeItem_open);
                }}
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "50px",
                  cursor: "pointer",
                  backgroundColor: `${ActiveIconItem === l.MenuName ? `${theme.palette.primary.main}` : "background.default"
                    }`,
                  boxShadow: `${ActiveIconItem === l.MenuName ? `1px 3px 6px 0px ${theme.palette.primary.main}24` : ""}`,
                  color: `${ActiveIconItem === l.MenuName ? "white" : `${theme.palette.primary.main}`}`,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CustomSvg
                    color={ActiveIconItem === l.MenuName ? "white" : `${theme.palette.primary.main}`}
                    size="22px"
                    name={l.icon}
                  />
                </Box>
              </Box>
            </Tooltip>
            {l.SubMenus && (
              <Collapse in={activeItem === l.MenuName}>
                {l.SubMenus.map((sl: any) => (
                  <Tooltip key={l.MenuName + "_tooltip_l2 menu" + sl.MenuName} title={sl.MenuName} placement="right-start">
                    <Box
                     key={l.MenuName + "_" + sl.MenuName}
                      onClick={(e) => handleItemClick_sub(e, sl, l)}
                      sx={{
                        cursor: "pointer",
                        ml: 2,
                        paddingY: "4px",
                        boxSizing: "border-box",
                        display: "flex",
                        alignItems: "center",
                        minHeight: "34px",
                        margin: "5px",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #E4E4E4",
                        color: `${sl.MenuName === activeItem_sub && activeItem === l.MenuName
                          ? theme.palette.primary.main
                          : theme.palette.primary.main
                          }`,
                      }}
                    >
                      <Typography
                        textAlign="left"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: `${sl.MenuName === activeItem_sub && activeItem === l.MenuName ? "9px" : "11px"}`,
                        }}
                      >
                        {sl.MenuName}
                      </Typography>
                      <Box onClick={(e) => handleFaviconClick(e, sl)}>
                        <CustomSvg
                          color={
                            sl.MenuName === activeItem_sub && activeItem === l.MenuName
                              ? "white"
                              : `${theme.palette.text.secondary}`
                          }
                          size="17px"
                          name="Fav_star"
                        />
                      </Box>
                    </Box>
                  </Tooltip>
                ))}
              </Collapse>
            )}
          </Box>
        </>
      );
    });
  };
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = () => {
    return (
      <Box
        sx={{
          width: activeItem_open === false ? 50 : 310,
          height: "100%",
          boxShadow: "2px solid #ccc",
          bgcolor: "background.default",
          display: "flex",
        }}
        ref={newRef}
      >
        <Box
          sx={{
            width: activeItem_open === false ? "100%" : 50,
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #C0C0C0",
          }}
        >
          {SearchIconSidenav()}
          {FavIconSidenav()}
          {memoizedSidenav()}
        </Box>
        {activeItem_open && (
          <Box
            sx={{
              flex: 1,
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              // backgroundColor: '#fff',
              position: "relative",
              height: "calc(100vh - 60px)",
              overflowY: "scroll",
              overflowX: "hidden",
              display: styledMenuRef === null ? "none " : "block",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography sx={{ color: "#6A6A6A", fontSize: "16px" }}>{ActiveIconItem}</Typography>
              <Box onClick={handleCloseUserMenu}>
                <FeatherIcon icon="x" size="18" />
              </Box>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Search sx={{ fontSize: "20px", color: "text.primary" }}>
                <SearchIconWrapperIcon onClick={() => setsideBarWidth(270)}>
                  <SearchIcon />
                </SearchIconWrapperIcon>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchValue}
                  onChange={(e) => {
                    serachMenu(e.target?.value || "");
                    setActiveItem_open(true);
                  }}
                />
                {searchValue && sideBarWidth === 270 && (
                  <SearchIconWrapperend
                    onClick={() => {
                      setSearchValue("");
                      setActiveItem_open(false);
                    }}
                  >
                    <CloseIcon />
                  </SearchIconWrapperend>
                )}
              </Search>
            </Box>
            <Box sx={{ maxHeight: "calc(100vw - 500px)", overflowY: "auto", overflowX: "hidden" }}>
              <Box>
                {filteredAdmin_Main_sidenav?.map((l: any) => {
                  return (
                    <Box
                      key={l.MenuName + l.RoleId + l.RoleName}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Tooltip title={l.MenuName} placement="right-start">
                        <Box
                          onClick={() =>{handleItemClick(l);
                            if(l?.SubMenus?.length == 0 || l?.SubMenus === null){
                              setActiveItem_open(false);
                            }
                             } }
                          sx={{
                            width: "100%",
                            marginTop: "7px",
                            boxSizing: "border-box",
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            minHeight: "40px",
                            cursor: "pointer",
                            backgroundColor: `${activeItem === l.MenuName ? "background.default" : "background.default"
                              }`,
                            color: `${activeItem === l.MenuName ? "text.primary" : "text.primary"}`,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {l.SubMenus && l.SubMenus.length > 0 && (
                                <>
                                  {(activeItem_open && activeItem === l.MenuName) ||
                                    (searchValue && searchValue.length > 0 && searchValue !== "") ? (
                                    <Box sx={{ mr: 0.5, mt: 0.5 }}>
                                      <FeatherIcon icon="chevron-down" size="16" />
                                    </Box>
                                  ) : (
                                    <Box sx={{ mr: 0.5, mt: 0.5 }}>
                                      <FeatherIcon icon="chevron-up" size="16" />
                                    </Box>
                                  )}
                                </>
                              )}
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    textAlign: "left",
                                    marginRight: "55px",
                                    fontStyle: "normal",
                                  }}
                                >
                                  {l.MenuName}
                                </Typography>
                              </Box>
                            </Box>
                            {!(l.SubMenus && l.SubMenus?.length > 0) && (
                              (l.IsFavourite && l.IsFavourite === 1) ?
                                <Box sx={{pt: 1}} onClick={(e) => handleFaviconRemove(e, l)}>
                                  <CustomSvg size="16px" name="active_star" />
                                </Box>
                                : <Box sx={{pt: 1}} onClick={(e) => handleFaviconClick(e, l)}>
                                  <CustomSvg color={`${theme.palette.text.secondary}`} size="16px" name="Fav_star" />
                                </Box>
                            )}
                          </Box>
                        </Box>
                      </Tooltip>
                      {l.SubMenus && l.SubMenus.length > 0 && (
                        <>
                          <Collapse
                            sx={{ width: "100%" }}
                            in={
                              activeItem === l.MenuName ||
                              (searchValue && searchValue.length > 0 && searchValue !== "" ? true : false)
                            }
                          >
                            <Box sx={{ display: "flex", width: "100%", borderBottom: "1px solid #E4E4E4" }}>
                              <Box sx={{ width: "20px", marginTop: "31px", marginBottom: "17px" }}></Box>
                              <Box sx={{ flex: 1 }}>
                                {l.SubMenus.map((sl: any, slindex: number) => (
                                  <Tooltip
                                    key={l.MenuName + "_" + sl.MenuName}
                                    title={sl.MenuName}
                                    placement="right-start"
                                  >
                                    <Box
                                      onClick={(e) => {
                                        handleItemClick_sub(e, sl, l);
                                        setActiveItem_open(false);
                                      }}
                                      sx={{
                                        cursor: "pointer",
                                        ml: 2,
                                        paddingY: "4px",
                                        boxSizing: "border-box",
                                        display: "flex",
                                        justifyItems: "center",
                                        minHeight: "34px",
                                        margin: "5px",
                                        // width : '100%',
                                        justifyContent: "space-between",
                                        borderBottom: `${l.SubMenus.length - 1 === slindex ? "" : "1px solid #E4E4E4"}`,
                                        color: `${sl.MenuName === activeItem_sub && activeItem === l.MenuName
                                          ? theme.palette.primary.main
                                          : theme.palette.primary.main
                                          }`,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyItems: "center",
                                          ...getIndicatorStyle(
                                            sl.MenuName === activeItem_sub && activeItem === l.MenuName
                                          ),
                                        }}
                                      // className={getIndicatorClassName(sl.MenuName === activeItem_sub && activeItem === l.MenuName)}
                                      >
                                        <Typography
                                          textAlign="left"
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            marginLeft: `${sl.MenuName === activeItem_sub && activeItem === l.MenuName
                                              ? "6px"
                                              : "8px"
                                              }`,
                                          }}
                                        >
                                          {sl.MenuName}
                                        </Typography>
                                      </Box>
                                      {(sl.IsFavourite && sl.IsFavourite === 1) ?
                                        <Box onClick={(e) => handleFaviconRemove(e, sl)}>
                                          <CustomSvg
                                            color={
                                              sl.MenuName === activeItem_sub && activeItem === l.MenuName
                                                ? `${theme.palette.text.secondary}`
                                                : `${theme.palette.text.secondary}`
                                            }
                                            size="17px"
                                            name="active_star"
                                          />
                                        </Box>
                                        : <Box onClick={(e) => handleFaviconClick(e, sl)}>
                                          <CustomSvg
                                            color={
                                              sl.MenuName === activeItem_sub && activeItem === l.MenuName
                                                ? `${theme.palette.text.secondary}`
                                                : `${theme.palette.text.secondary}`
                                            }
                                            size="17px"
                                            name="Fav_star"
                                          />
                                        </Box>}
                                      {/* <Box onClick={(e) => handleFaviconClick(e, sl)}>
                                        <CustomSvg
                                          color={
                                            sl.MenuName === activeItem_sub && activeItem === l.MenuName
                                              ? `${theme.palette.text.secondary}`
                                              : `${theme.palette.text.secondary}`
                                          }
                                          size="17px"
                                          name="star"
                                        />
                                      </Box> */}
                                    </Box>
                                  </Tooltip>
                                ))}
                              </Box>
                            </Box>
                          </Collapse>
                        </>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return <Box>{isShown && drawer()}</Box>;
};

export default Sidenavbar;
