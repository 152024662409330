import React, { memo, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import CustomSvg from "../CustomSvg";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { setTabs } from "../../store/slices/tabsData";
import { useSelector } from "react-redux";
interface TabProps {
  steps: any[];
  handleTabNavigation?: any;
  disableTabNavigation?: boolean;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = memo((props: TabProps) => {
  const {steps, handleTabNavigation ,disableTabNavigation = false} = props
  const theme = useTheme();
  const dispatch = useAppDispatchThunk();

  const [variant, setVariant] = useState<string>("fullWidth");
  const [value, setValue] = useState<number>(0);

  const { active } = useSelector((state: RootState) => state.tabData);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(!disableTabNavigation) dispatch(setTabs(newValue));
  };

  useEffect(() => {
    setValue(active);
  }, [active]);

  const calculateTotalWidth = () => {
    const tabWidth = 200;
    const totalWidth = steps.length * tabWidth;
    return totalWidth;
  };

  useEffect(() => {
    const totalWidth = calculateTotalWidth();
    const containerWidth = window.innerWidth;

    if (totalWidth > containerWidth) {
      setVariant("scrollable");
    } else {
      setVariant("fullWidth");
    }
  }, [steps]);

  return (
    <Box sx={{ width: "100%" }}>
    <Box >
        <Tabs
          onChange={ handleTabNavigation ? handleTabNavigation : handleChange }
          sx={{
            "& .MuiTabs-indicator": {
              height: "2px",
            },
          }}
          variant={variant === "scrollable" ? "scrollable" : "standard"}
          // variant={ "fullWidth"}
          value={value}
          aria-label="basic tabs example"
        >
           
          {steps.map((item, index) => (
            <Tab
              sx={{
                padding: variant === "scrollable" ? "4px 22px" : "4px 16px",
                width: variant === "scrollable" ? 200 : 'fit-content',
                minWidth: variant === "scrollable" ? 200 : 'fit-content',
                // maxWidth: 200,
                // minHeight: "55px",
                maxHeight: "55px",
                textAlign: "left",
                color: index === value
                ? `${theme.palette.primary.main}`
                : `${theme.palette.text.primary}`,
                // borderRight:`1px solid #00000030`
              }}
              icon={
                item.icon ? (
                  <CustomSvg
                    name={item.icon}
                    color={
                      index === value
                        ? `${theme.palette.primary.main}`
                        : `${theme.palette.text.primary}`
                    }
                    size="22px"
                  />
                ) : undefined
              }
              disabled={item.disabled}
              iconPosition="start"
              label={item.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
});

export default CustomTabs;
