import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import { DepartmentConfig, LocationConfig, LovConfig, MasterBulkUpload } from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import { getDepartmentList } from "../../../../store/slices/departmentData";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
import { getParentLocationList } from "../../../../store/slices/locationMasterData";
export const DocumentImportdata: any = [
  {
    Field: "document_Name",
    FieldType: "string",
    DisplayName: "Document Name",
    config: {
      required: true,
    },
  },
  {
    Field: "short_Name",
    FieldType: "string",
    DisplayName: "Short Name",
    config: {
      required: true,
    },
  },
  {
    Field: "purpose",
    FieldType: "string",
    DisplayName: "Purpose",
    config: {
      required: true,
    },
  },
  {
    Field: "document_Extension",
    FieldType: "string",
    DisplayName: "Document Extension",
    config: {
      required: true,
    },
  },
  {
    Field: "assessment_Type",
    FieldType: "string",
    DisplayName: "Assessment Type",
    config: {
      required: true,
    },
  },
  {
    Field: "is_Perpetual",
    FieldType: "string",
    DisplayName: "Is Perpetual",
    config: {
      required: true,
    },
  },
  {
    Field: "is_Mandatory",
    FieldType: "string",
    DisplayName: "Is Mandatory",
    config: {
      required: true,
    },
  },
];

const DocumentMasterUploadSheet: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const [purposeList, setPurposeList] = useState<any[]>([]);
  const [assessmentTypeList, setassessmentTypeList] = useState<any[]>([]);
  const [documentExtensionList, setDocumentExtensionList] = useState<any[]>([]);

  useEffect(() => {
    setPurposeList(lovTable.filter((item: any) => item.Name === LovConfig.Purpose) || []);
    setassessmentTypeList(lovTable.filter((item: any) => item.Name === LovConfig.Assessment_Type) || []);
    setDocumentExtensionList(lovTable.filter((item: any) => item.Name === LovConfig.Document_Ext) || []);
  }, [lovTable]);

  const { setselectedValidation, uploaddata } = context || {};

  //validation the depatment Fields
  const department_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
      let ValidatedData: any[] = [];
      uploaddata.forEach((item: any) => {
        let error: string = "";
        let object: any = {
          ...item,
          document_Name: "",
          short_Name: "",
          purpose: "",
          purpose_Id: "",
          is_Perpetual: "",
          is_Perpetual_Id: "",
          is_Mandatory: "",
          is_Mandatory_Id: "",
          assessment_Type: "",
          assessment_Type_Id: "",
          document_Extension: "",
          document_Extension_Id: "",
        };
        DocumentImportdata.map((header_item: any) => {
          if (header_item.Field === "document_Name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Document Name is Required,";
            }
          }
          if (header_item.Field === "short_Name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " short Name is Required,";
            }
          }

          //validation the depatment Type Fields
          if (header_item.Field === "purpose") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
              const findSelectCountry = purposeList.find(
                (locitem: any) => locitem.Options == item[header_item.DisplayName]
              );

              if (findSelectCountry && findSelectCountry?.LovId) {
                object.purpose_Id = findSelectCountry?.LovId;
              } else {
                error += " Invalid Purpose,";
              }
            } else {
              error += " Purpose is Required,";
            }
          }
          //validation the depatment Name Fields
          if (header_item.Field === "is_Perpetual") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
              if (item[header_item.DisplayName] == "Yes" || item[header_item.DisplayName] == "No") {
                object.is_Perpetual_Id = item[header_item.DisplayName] == "Yes" ? true : false;
              } else {
                error += "Is Perpetual is Invalid,";
              }
            } else {
              object.is_Perpetual_Id = false;
              object[header_item.Field] = "No";
            }
          }

          if (header_item.Field === "is_Mandatory") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
              if (item[header_item.DisplayName] == "Yes" || item[header_item.DisplayName] == "No") {
                object.is_Mandatory_Id = item[header_item.DisplayName] == "Yes" ? true : false;
              } else {
                error += "Is Mandatory is Invalid,";
              }
            } else {
              object.is_Mandatory_Id = false;
              object[header_item.Field] = "No";
            }
          }

          // assessment type validation

          if (header_item.Field === "assessment_Type") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              let enteredAssesment = item[header_item.DisplayName];
              object[header_item.Field] = enteredAssesment;
              let assessmentArray = enteredAssesment.split(",");
                 let invalidValue : any = "" 
              let matchingIds = assessmentArray.map((assessValue: any) => {
                let trimmedValue = assessValue?.trim();
                let matchedItem = assessmentTypeList.find((asseitem: any) => asseitem.Options?.trim() === trimmedValue);
                if(!matchedItem){
                    invalidValue = invalidValue + " "  + trimmedValue
                }
                return matchedItem ? matchedItem.LovId : null;
              });
              matchingIds = matchingIds.filter((id: any) => id !== null);

              if (matchingIds && matchingIds?.length > 0 && !invalidValue) {
                let companyFinal : any[] = matchingIds?.map((comVal : any) => {
                  let findObj = assessmentTypeList.find((astypeitem: any) => astypeitem.LovId == comVal)
                      return {
                        LovId : findObj?.LovId ,
                        Options : findObj?.Options
                      }
              })
                object.assessment_Type_Id = companyFinal
              } else {
                error += `${invalidValue} - Invalid Assessment Type,`;
              }
            } else {
              error += " Assessment Type is Required,";
            }
          }

          // Doc Extension type validation 

          if (header_item.Field === "document_Extension") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              let enteredAssesment = item[header_item.DisplayName];
              object[header_item.Field] = enteredAssesment;
              let assessmentArray = enteredAssesment.split(",");
                 let invalidValue : any = "" 
              let matchingIds = assessmentArray.map((assessValue: any) => {
                let trimmedValue = assessValue?.trim();
                let matchedItem = documentExtensionList.find((asseitem: any) => asseitem.Options?.trim() === trimmedValue);
                if(!matchedItem){
                    invalidValue = invalidValue + " "  + trimmedValue
                }
                return matchedItem ? matchedItem.LovId : null;
              });
              matchingIds = matchingIds.filter((id: any) => id !== null);

              if (matchingIds && matchingIds?.length > 0 && !invalidValue) {
                let companyFinal : any[] = matchingIds?.map((comVal : any) => {
                  let findObj = documentExtensionList.find((astypeitem: any) => astypeitem.LovId == comVal)
                      return {
                        LovId : findObj?.LovId ,
                        Options : findObj?.Options
                      }
              })
                object.document_Extension_Id = companyFinal
              } else {
                error += `${invalidValue} - Invalid Document Extension,`;
              }
            } else {
              error += " Document Extension is Required,";
            }
          }

          object.error = error;
        });
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      department_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const hiddenSheet = workbook.addWorksheet("Note"); // Hidden sheet for large data lists
      let sheet_Column: any = DocumentImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);

      let purposList = purposeList?.map((item) => item.Options);
      let assList = assessmentTypeList?.map((item) => item.Options);
      let extList = documentExtensionList?.map((item) => item.Options);

      hiddenSheet.getCell(
        `A${1}`
      ).value = `Note : Assessment Type is Multi Select data, set Comma sepertated Value only Given below, Example : ${assList.join(
        ","
      )}`;
      hiddenSheet.getCell(
        `B${1}`
      ).value = `Note : Document Extension is Multi Select data, set Comma sepertated Value only Given below, Example : ${extList.join(
        ","
      )}`;
      assList.forEach((item, index) => {
        hiddenSheet.getCell(`A${index + 3}`).value = item;
      });
      extList.forEach((item, index) => {
        hiddenSheet.getCell(`B${index + 3}`).value = item;
      });

      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        worksheet.getCell(`A${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: true,
          formulae: [55],
        };
        worksheet.getCell(`B${row}`).dataValidation = {
          type: "textLength",
          operator: "lessThan",
          allowBlank: true,
          formulae: [55],
        };

        worksheet.getCell(`C${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${purposList.join(",")}"`],
          showErrorMessage: true,
          errorTitle: "Invalid Document Purpose for",
          error: "Please select an Document Purpose from the list",
        };

        worksheet.getCell(`F${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: ['"Yes,No"'],
          showErrorMessage: true,
          errorTitle: "Invalid",
          error: "Please select from the list",
        };
        worksheet.getCell(`G${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: ['"Yes,No"'],
          showErrorMessage: true,
          errorTitle: "Invalid",
          error: "Please select from the list",
        };

        if (assList) {
          worksheet.getCell(`E${2}`).value = `${assList.join(",")}`;
        }
        if (extList) {
          worksheet.getCell(`D${2}`).value = `${extList.join(",")}`;
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "Document_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  return (
    <>
      <CustomButton type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default DocumentMasterUploadSheet;
