import React, { useEffect, useState } from "react";
import PluginStepper from "./PluginStepper/PluginStepper";
import { useAppDispatchThunk } from "../../../store/store";
import { setPluginData } from "../../../store/contractManagement/slices/pluginDataSlice";

// declare global {
//   interface Window {
//     Asc: any;
//   }
// }

const ONLYOFFICEPlugin = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatchThunk();

  useEffect(() => {
    //  const script1 = document.createElement("script");
    //   script1.src = "https://onlyoffice.github.io/sdkjs-plugins/v1/plugins.js";
    //  document.head.appendChild(script1);
    // <React.Fragment>
    //  <script
    //   type="text/javascript"
    //   src="https://onlyoffice.github.io/sdkjs-plugins/v1/plugins.js"
    // ></script> 
    //  <script
    //   type="text/javascript"
    //   src="https://onlyoffice.github.io/sdkjs-plugins/v1/plugins-ui.js"
    // ></script> 
    // </React.Fragment>
    // setIsLoaded(true);
    // setError(null);
  }, []);

 

  // useEffect(() => {
  //   (async function (window, undefined) {
  //     console.log(Asc, window.Asc.plugin.callCommand, "ASCContent");

  //     const pluginData = window?.Asc;

  //     console.log(pluginData, "pluginData~dispatch");
  //     dispatch(setPluginData(pluginData));
  //   })(window);
  // }, []);

  return (
    <React.Fragment>
      {/* {error && <div className="error-message">{error}</div>} */}
      {<PluginStepper />}
    </React.Fragment>
  );
};

export default ONLYOFFICEPlugin;
