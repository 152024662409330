import { Box, Divider, Stack, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React, { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import SigningAuthority from "./SigningAuthority";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const NotifySigningAuthority = () => {
  const [contractData, setContractData] = useState<any>({
    "Company Name": "C-Life. Private Limited, Bangalore",
    "Vendor Name": "ABOD, Private Limited, Bangalore",
    "Contract Number": "SOFTWARE0012023",
  });
  const [signingData, setSigningData] = useState<any[]>([
    {
      authority: "Company",
      data: [
        {
          order: 1,
          name: "Lorem ipsum dolor sit amet",
          designation: "Biz Admin",
          email: "loremipsum1@email.com",
        },
        {
          order: 2,
          name: "Lorem ipsum dolor sit amet",
          designation: "Biz Admin",
          email: "loremipsum2@email.com",
        },
        {
          order: 3,
          name: "Lorem ipsum dolor sit amet",
          designation: "Biz Admin",
          email: "loremipsum3@email.com",
        },
      ],
    },
    {
      authority: "Vendor",
      data: [
        {
          order: 1,
          name: "Lorem ipsum dolor sit amet",
          designation: "Biz Admin",
          email: "loremipsum@email.com",
        },
        {
          order: 2,
          name: "Lorem ipsum dolor sit amet",
          designation: "Biz Admin",
          email: "loremipsum@email.com",
        },
        {
          order: 3,
          name: "Lorem ipsum dolor sit amet",
          designation: "Biz Admin",
          email: "loremipsum@email.com",
        },
      ],
    },
  ]);

  const [expandSummary, setExpandSummary] = useState<boolean>(false);

  const placeHolder = () => {};

  return (
    <Box sx={{ width: "100%", px: 2 }}>
      <Box pt={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ChevronLeftIcon />
          <Typography variant="h6" color={"text.primary"} fontWeight={700}>
            Notify Signing Authority
          </Typography>
        </Stack>
        <Divider sx={{ my: 4 }} variant="fullWidth" />
      </Box>
      <Box>
        <Stack spacing={8} direction="row">
          {Object.entries(contractData).map(([label, value]: any) => (
            <Box key={label}>
              <Typography textAlign="left" fontWeight="bold">
                {label} -{" "}
              </Typography>
              <Typography textAlign="left">{value}</Typography>
            </Box>
          ))}
        </Stack>
        <Stack direction="row" justifyContent="space-between" mt={4}>
          <Box display="flex" alignItems="center" gap={4}>
            <Typography fontWeight="bold">Contract Summary</Typography>
            {expandSummary ? (
              <ExpandLess onClick={() => setExpandSummary(false)} sx={{ cursor: "pointer" }} />
            ) : (
              <ExpandMore onClick={() => setExpandSummary(true)} sx={{ cursor: "pointer" }} />
            )}
          </Box>
          <CustomButton variant="outlined" color="secondary" name={"View Contract"} onClick={() => placeHolder()} />
        </Stack>
        <Box display={expandSummary ? "block" : "none"}>
          <Typography textAlign="left" mt={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla.
          </Typography>
        </Box>
        <Divider sx={{ my: 4 }} />
        {signingData.map((e) => (
          <SigningAuthority authority={e.authority} tableData={e.data} view={false} />
        ))}
      </Box>
      <Stack direction="row" spacing={2} justifyContent="space-between" pt={2}>
        <CustomButton variant="outlined" name={"Cancel"} onClick={() => {}} />
        <CustomButton variant="contained" name={"Send for E-Sign"} endIcon={<ChevronRightIcon />} onClick={() => {}} />
      </Stack>
    </Box>
  );
};

export default NotifySigningAuthority;
