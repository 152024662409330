import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { msalConfig } from './authConfig/authConfig';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import {persistor, store} from './store/store' ;
import { PersistGate } from 'redux-persist/integration/react';

export const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if(msalInstance.getActiveAccount () && msalInstance.getAllAccounts().length>0){
    msalInstance.setActiveAccount(msalInstance.getActiveAccount());
}

msalInstance.addEventCallback((event:any)=>{
  if(event.eventType === EventType.LOGIN_SUCCESS && event?.payload){
      msalInstance.setActiveAccount(event.payload.account);
  }
})
root.render(
  <>    
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <CssBaseline/>
    <App instance ={msalInstance}/>
    </PersistGate>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
