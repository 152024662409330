import axios from "axios";
import { textEditorPortVersion } from "./constant";

const { REACT_APP_TEXT_EDITOR } = process.env;

export const axiosTextEditorInstance = axios.create({
  baseURL: `${REACT_APP_TEXT_EDITOR}`,
});

axiosTextEditorInstance.interceptors.response.use(async (response: any) => {
  if (response.status !== 400 && response.status !== 404 && response.status !== 403) {
    console.log("Authenicate fails", response);
  } else {
  }
  return response;
});
