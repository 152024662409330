import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddLocation } from "./AddLocation";
import {
  deleteLocation,
  getLocationHistory,
  getLocationList,
  updateLocationAddandEdit,
  updateVersionHistory,
} from "../../../store/slices/locationMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { Edit_Enable_tosterMSG, LovConfig } from "../../../config/config";
import { getLovList } from "../../../store/slices/lovMasterData";
import { HandleRecordLockCheck, recordLockGet } from "../../recordLock/RecordLock";
import masterEditEnableService from "../../../store/services/masterEditEnableService";

export default function Location() {
  const dispatch = useAppDispatch();
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [LocationData, setLocationData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [selectedValue, setselectedValue] = useState(null);
  const [FilterData, setFilterData] = useState<any[]>([]);
  const [versionHistoryListData, setversionHistoryListData] = useState<any[]>([]);
  const [filteredLovTableLocationType, setFilteredLovTableLocationType] = useState<any[]>([]);
  const [OpenModalServerFilter, setOpenModalServerFilter] = React.useState(false);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  useEffect(() => {
    setFilteredLovTableLocationType(lovTable.filter((item: any) => item.Name === LovConfig?.Location_Type));
  }, [lovTable]);
  const columns_version: any = {
    table_name: "Location History Records",
    isAction: false,
    columns: [],
  };

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const { locationTable, islocationAdded, locationVersionHistory, islocationDeleted, ToasterMsg, isLoading } =
    useSelector((state: RootState) => state.locationMasterData);

  const allOption = {
    LovId: 0,
    Options: "All",
  };

  const modifiedLovNames = [allOption, ...filteredLovTableLocationType];

  const columns: any = {
    table_name: "Location",
    isAction: true,
    columns: [
      // {
      //     Field: "LocationId",
      //     DisplayName: "Location Id",
      //     FieldType: "string",
      // },

      {
        Field: "Location_Name",
        DisplayName: "Location Name",
        FieldType: "string",
      },
      {
        Field: "LocationTypeValue",
        DisplayName: "Location Type",
        FieldType: "string",
      },
      {
        Field: "ParentLocationName",
        DisplayName: "Parent",
        FieldType: "string",
      },
      {
        Field: "Start_Pin",
        DisplayName: "Start PIN",
        FieldType: "string",
      },
      {
        Field: "End_Pin",
        DisplayName: "End PIN",
        FieldType: "string",
      },
      {
        Field: "RegionIdValue",
        DisplayName: "Region",
        FieldType: "string",
      },
      {
        Field: "ZoneIdValue",
        DisplayName: "Zone",
        FieldType: "string",
      },

      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "Updated_By",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getLocationList({
        FormID: 2,
      })
    );
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    console.log(locationTable, "dataTable");
    if (locationTable) {
      let changedValue = locationTable.map((item: any) => {
        return {
          ...item,
          End_Pin: item.End_Pin === 0 ? "" : item.End_Pin,
          Start_Pin: item.Start_Pin === 0 ? "" : item.Start_Pin,
        };
      });
      setLocationData(changedValue);
    }
  }, [locationTable]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (locationVersionHistory.LocationVHeader.length > 0 && locationVersionHistory.LocationVHeader.length > 0) {
      if (locationVersionHistory.LocationVHeader && locationVersionHistory.LocationVHeader.length > 0) {
        // setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: locationVersionHistory.LocationVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(locationVersionHistory, "locationVersionHistory ");
      }
    }

    if (locationVersionHistory.LocationRecords && locationVersionHistory.LocationRecords?.length > 0) {
      let locationTableVersion = locationVersionHistory.LocationRecords;
      let changedValue = locationTableVersion.map((item: any) => {
        return {
          ...item,
          End_Pin: item.End_Pin === 0 ? "" : item.End_Pin,
          Start_Pin: item.Start_Pin === 0 ? "" : item.Start_Pin,
        };
      });
      setversionHistoryListData(changedValue);
    }
  }, [locationVersionHistory]);

  // const recordLockGet = (id: any) => {
  //   const recordLockData = {
  //     RecordId: id,
  //     MenuId: permssionPage?.MenuId,
  //     ModuleName: "Location",
  //   };
  //   return recordLockData;
  // };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Location",
      Activity: "edit",
    };
    return recordLockData;
  };

  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      const data = {
        RecordlockId: recordLock[0].RecordlockId,
        RecordId: recordLock[0].RecordId,
        IsActive: false,
      };
      dispatch(UpdateRecordLock(data));
    }
    return recordLock;
  };

  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {
      console.log(data, Option, "Option");
      //   setOption(Option);
      //   setIsEditData(data);
      //   setIsEdit(true);
      setIsEditData(data);
      // setIsEdit(true);
      setOption(Option);
      dispatch(updateLoader(true));

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Location_Id, FormID: 9 });
      const Edit_data: any = response;

      const canEdit = await HandleRecordLockCheck(data?.Location_Id, permssionPage, "Location Master", dispatch);
      if (Edit_data?.data === true) {
        if (canEdit) {
          setIsEdit(true);
          const dispatchData = handleAddRecordLock(data?.Location_Id);
          await dispatch(AddRecordLock(dispatchData));
          //setIsEditOpen(true);
          const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.Location_Id, permssionPage, "Location Master")));
          if (GetRecordLock.fulfilled.match(resultAction)) {
            const recordLockData = resultAction.payload;
            console.log(recordLockData, resultAction, isEditing, "recordLockData");
            setGetRecordLock(recordLockData);
          }
          dispatch(updateLoader(false));
          //  Set timeout to close the popup after 2.5 minutes
          //  setTimeout(() => {
          //   setIsEdit(false);
          //   const data = recordLockUpdate();
          //   if (data.length > 0) {
          //     dispatch(UpdateRecordLock(data.pop()));
          //   }
          // }, 150000);
        }
      } else {

        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }


      // setUserId(data.UserId);
    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    }
    // else if (Option === "history") {
    //   setOption(Option);
    //   dispatch(
    //     getLocationHistory({
    //       LocationId: data.Location_Id,
    //       formID: 9,
    //       // Approved_By: ,
    //     })
    //   );
    //   // setIslocationVersionHistory(true);
    // }
    else if (Option === "history") {
      setIsVersionHistory(true);
      // setIsEdit(true);
      setOption(Option);
      setIsEditData(data);
      dispatch(
        getLocationHistory({
          LocationId: data.Location_Id,
          formID: 9,
          // Approved_By: ,
        })
      );
    } else if (Option === "delete") {

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Location_Id, FormID: 9 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        setOption(Option);
        setIsDeleteData(data);
      } else {

        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }
    }
  };

  useEffect(() => {
    if (islocationAdded) {
      dispatch(updateLocationAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      dispatch(
        getLocationList({
          LocationTypeId: selectedValue,
        })
      );
    } else if (islocationDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      setTimeout(() => {
        dispatch(updateLocationAddandEdit(false));
      }, 1000);
      dispatch(
        getLocationList({
          LocationTypeId: selectedValue,
        })
      );
    }
  }, [islocationAdded, islocationDeleted]);

  const deleteDepartmentList = () => {
    let formData: any = {};

    let JsonDataForDisplay: any = {
      "Location Name": isDeleteData.Location_Name,
      "Location Type":isDeleteData.LocationTypeValue,
      "Parent":isDeleteData.ParentLocationName,
      "Start PIN": isDeleteData.Start_Pin,
      "End PIN": isDeleteData.End_Pin,
      "Region": isDeleteData.RegionIdValue,
      "Zone": isDeleteData.ZoneIdValue,
      "Status": isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    }
    formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);
    formData.IsActive = isDeleteData.IsActive === "Active" ? false : true;
    formData.Activity = isDeleteData.IsActive === "Active" ? "inactive" : "active";
    formData.LocationId = isDeleteData.Location_Id;
    formData.UserId = userLoginResponse?.UserId;
    formData.LocationName = isDeleteData.Location_Name;
    formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 9;
    formData.ModuleName = "Location Master";
    formData.CreatedBy = userLoginResponse?.UserName;
    // formData.DataForDisplay = "";
    dispatch(deleteLocation(formData));
  };



  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(Object.entries(data).length > 0, Object.entries(data), "data");

      if (Object.entries(data).length > 0) {
        // Show the confirmation dialog
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";

        // Handle the case when the user confirms to leave (reload the page)
        const handleUnload = async () => {
          try {
            await dispatch(UpdateRecordLock(data));
          } catch (error) {
            console.error("Failed to update record lock:", error);
          }
        };

        window.addEventListener("unload", handleUnload);
      }
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);


  const handleChange = (event: any) => {
    console.log(event, "event");

    setselectedValue(event);
  };

  const handleServerFilter = () => {
    if (selectedValue === null) {
      setOpenModalServerFilter(true);
    }

    if (selectedValue !== null) {
      dispatch(
        getLocationList({
          LocationTypeId: selectedValue,
        })
      );
      // setformDisplay(true);
    }
    // console.log(FilterData, "FilterData");
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentPagePermissionData?.MenuName}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", width: "250px" }}>
                <CustomSelect
                  name={"Location_TypeId"}
                  valueKey={"LovId"}
                  optionLabelKey="Options"
                  options={modifiedLovNames}
                  value={selectedValue}
                  onChange={handleChange}
                  disabled={false}
                  required={true}
                  label={"Location Type"}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <CustomButton variant="contained" name={"Submit"} onClick={() => handleServerFilter()} size="small" />
              </Box>
            </Grid>
          </Box>
          <Box>
            {/* {!formDisplay ? (
              ""
            ) : ( */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={LocationData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Location"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
            {/* )} */}
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={"Add Location"}
        contentNode={
          // <></>
          <AddLocation
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
          if (Option === "edit") {
            recordLockUpdate();
          }
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={Option === "view" ? "View Location" : "Update Location"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddLocation
                view={false}
                closePopUp={() => {
                  recordLockUpdate();
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddLocation
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Location Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistoryListData}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Location Version History"
            />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => deleteDepartmentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
          } the selected Location`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />

      <ModalPopUp
        header="Location Type"
        text="Please Select a Location Type "
        onHide={() => {
          setOpenModalServerFilter(false);
        }}
        show={OpenModalServerFilter}
      />
    </>
  );
}
