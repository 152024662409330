import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../common/dynamicButton/CustomButton";
import {
  AQMResponder,
  AssessmentCategoryConfig,
  DepartmentConfig,
  LocationConfig,
  LovConfig,
  MasterBulkUpload,
} from "../../../../config/config";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { BulkuploadValidationContext } from "../CommonMasterBulkUpload";
import { getParentLocationList } from "../../../../store/slices/locationMasterData";
import { getLovList } from "../../../../store/slices/lovMasterData";
import { getDepartmentList } from "../../../../store/slices/departmentData";
import { getRiskRateList } from "../../../../store/slices/riskRateMasterData";
import { getAssessmentCategoryList } from "../../../../store/slices/assessmentCategoryMasterData";
import { getServiceCatalogList } from "../../../../store/slices/serviceCatalog";
export const AQMasterImportdata: any = [
  {
    Field: "Category_name",
    FieldType: "string",
    DisplayName: "Category",
    config: {
      required: true,
    },
  },
  {
    Field: "sub_Category_nameII",
    FieldType: "string",
    DisplayName: "Sub Category II",
    config: {
      required: true,
    },
  },
  {
    Field: "sub_Category_nameIII",
    FieldType: "string",
    DisplayName: "Sub Category III",
    config: {
      required: true,
    },
  },
  {
    Field: "applicable_for",
    FieldType: "string",
    DisplayName: "Applicable For",
    config: {
      required: true,
    },
  },
  {
    Field: "question_title",
    FieldType: "string",
    DisplayName: "Question Title",
    config: {
      required: true,
    },
  },
  {
    Field: "question",
    FieldType: "string",
    DisplayName: "Question",
    config: {
      required: true,
    },
  },
  {
    Field: "question_type",
    FieldType: "string",
    DisplayName: "Answer Type",
    config: {
      required: true,
    },
  },
  {
    Field: "applicable_risks",
    FieldType: "array",
    DisplayName: "Applicable Risks (Comma Seperated)",
    config: {
      required: false,
    },
  },
  {
    Field: "choices",
    FieldType: "array",
    DisplayName: "Choices (Comma Seperated)",
    config: {
      required: false,
      info: "Required if question type is 'Multiple Choice'",
    },
  },
  {
    Field: "score",
    FieldType: "array",
    DisplayName: "Score (Comma Seperated)",
    config: {
      required: false,
      info: "Corresponds to each choice, required for 'Multiple Choice'",
    },
  },

  {
    Field: "evidence_required",
    FieldType: "boolean",
    DisplayName: "Evidence Required",
    config: {
      required: false,
      default: false,
    },
  },
  {
    Field: "assessment_type",
    FieldType: "string",
    DisplayName: "Assessment Type (Comma Seperated)",
    config: {
      required: true,
    },
  },
  {
    Field: "responder",
    FieldType: "string",
    DisplayName: "Responder",
    config: {
      required: true,
    },
  },
  {
    Field: "department",
    FieldType: "string",
    DisplayName: "Department",
    config: {
      required: true,
    },
  },
  {
    Field: "sme_verified",
    FieldType: "string",
    DisplayName: "SME verification is needed",
    config: {
      required: true,
    },
  },
  {
    Field: "Service_Mapping",
    FieldType: "string",
    DisplayName: "Service Mapping (Comma Seperated)",
    config: {
      required: true,
    },
  },
];

const AQMasterUploadSheet: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const context = useContext(BulkuploadValidationContext);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { dataTable: assessmentCategoryMasterData } = useSelector(
    (state: RootState) => state.assessmentCategoryMasterData
  );
  const { dataTable: departmetMasterData } = useSelector((state: RootState) => state.departmentData);
  const { RiskRateDataActive } = useSelector((state: RootState) => state.RiskRateData);

  const [question_typeList, setquestion_typeList] = useState<any[]>(["Multiple Choice", "Free Text"]);
  const [checkbokvalue, setcheckbokvalue] = useState<any[]>(["Yes", "No"]);
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);

  const [AssesmentTypeLV, setAssesmentTypeLV] = useState<any[]>([]);
  const [ResponderLV, setResponderLV] = useState<any[]>([]);
  const [lovActiveStatusId, setLovActiveStatusId] = useState<any>(null);
  const [applicableRiskData, setApplicableRiskData] = useState<any[]>([]);
  const [applicableForLV, setApplicableForLV] = useState<any[]>([]);
  const [catagoryDataLevel1, setcatagoryDataLevel1] = useState<any[]>([]);
  const [catagoryDataLevel2, setcatagoryDataLevel2] = useState<any[]>([]);
  const [catagoryDataLevel3, setcatagoryDataLevel3] = useState<any[]>([]);
  const [DepartmentList, setDepartmentList] = useState<any[]>([]);
  const [serviceList, setServiceList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
    dispatch(getServiceCatalogList());
    dispatch(getAssessmentCategoryList({ FormID: 40 }));
  }, []);
  useEffect(() => {
    setLovActiveStatusId(
      lovTable.find((item: any) => item.Name === LovConfig.Status && item.Description === "Active")?.LovId || 0
    );

    setResponderLV(lovTable.filter((item: any) => item.Name === LovConfig.AQ_Responder ) || []);
    setAssesmentTypeLV(lovTable.filter((item: any) => item.Name === LovConfig.Assessment_Type) || []);
    setApplicableForLV(lovTable.filter((item: any) => item.Name === LovConfig.Assessment_TypeII ) || []);

  }, [lovTable]);

  useEffect(() => {
    if (RiskRateDataActive) {
      setApplicableRiskData(RiskRateDataActive || []);
    }
  }, [RiskRateDataActive]);

  useEffect(() => {
    if (departmetMasterData) {
      setDepartmentList(departmetMasterData);
    }
  }, [departmetMasterData]);

  useEffect(() => {
    if (ActiveServiceCatlogdata) {
      console.log(ActiveServiceCatlogdata, "ActiveServiceCatlogdata");

      setServiceList(ActiveServiceCatlogdata);
    }
  }, [ActiveServiceCatlogdata]);

  useEffect(() => {
    if (assessmentCategoryMasterData) {
      let filteredActiveList: any[] = assessmentCategoryMasterData.filter((item: any) => item.IsActive === "Active");
      setcatagoryDataLevel1(
        filteredActiveList?.filter((item: any) => item.AQ_Category_Type_Value === AssessmentCategoryConfig?.Category) ||
          []
      );
      setcatagoryDataLevel2(
        filteredActiveList?.filter(
          (item: any) =>
            item.AQ_Category_Type_Value === AssessmentCategoryConfig?.Sub_Category &&
            item.Parent_Level_Value === AssessmentCategoryConfig?.Category_Level_2
        ) || []
      );
      setcatagoryDataLevel3(
        filteredActiveList?.filter(
          (item: any) =>
            item.AQ_Category_Type_Value === AssessmentCategoryConfig?.Sub_Category &&
            item.Parent_Level_Value === AssessmentCategoryConfig?.Category_Level_3
        ) || []
      );
    }
  }, [assessmentCategoryMasterData]);

  const { setselectedValidation, uploaddata } = context || {};


  const validateChoicesAndScores = (choicesStr: string, scoresStr: string , value : boolean) => {
    // Split choices and scores by comma
    const choices = choicesStr?.split(",");
    const scores = scoresStr?.split(",");
    const choicesArray: any[] = [];
    const scoresArray: any[] = [];

    if (choices.length !== scores.length ) {
      return { isValid: false, message: "Choices and scores must have the same length." };
    }
  
    const uniqueChoices = new Set(choices);
    if (uniqueChoices.size !== choices.length && value) {
      return { isValid: false, message: "Choices contain duplicates." };
    }

    const uniqueScores = new Set(scores);
    if (uniqueScores.size !== scores.length && !value) {
      return { isValid: false, message: "Scores contain duplicates." };
    }
  
    const expectedScores = Array.from({ length: choices.length }, (_, i) => (i + 1).toString());

    const sortedScores = [...scores].sort();
    const sortedExpectedScores = [...expectedScores].sort();
  
    if (sortedScores.join() !== sortedExpectedScores.join()) {
      return { isValid: false, message: `Scores must contain exactly the numbers from 1 to ${choices.length} in any order.` };
    }
  
    if (choices) {
        choices.forEach(choice => choicesArray.push(choice.trim()));
    }

    if (scores) {
        scores.forEach(choice => scoresArray.push(choice.trim())); 
  }
    // If all checks pass
   
    
    return { isValid: true, message: "Validation passed." ,  values : value ? choicesArray : scoresArray };
  };


  const department_uploadsheet_validation = () => {
    if (setselectedValidation && uploaddata) {
      let ValidatedData: any[] = [];
      uploaddata.forEach((item: any) => {
        let error: string = "";
       
        let object: any = {
          ...item,
          Category_name : "",
          Category_name_id : "",
          sub_Category_nameII : "",
          sub_Category_nameII_id : "",
          sub_Category_nameIII : "",
          sub_Category_nameIII_id : "",
          applicable_for: "",
          applicable_for_Id: "",
          question_title: "",
          question: "",
          question_type: "",
          question_type_Id: "",
          applicable_risks: "",
          applicable_risks_Id: "",
          choices:"",
          choices_Value:"",
          score:"",
          score_Value:"",
          evidence_required: "",
          evidence_required_Id: "",
          assessment_type: "",
          assessment_type_Id: "",
          responder: "",
          responder_Id: "",
          department: "",
          department_Id: "",
          sme_verified: "",
          sme_verified_Id: "",
          Service_Mapping: "",
          Service_Mapping_Id: "",
        };
        let selectedCatagory1 = item['Category']?.split(" - ")[0];
        let findValidCategorytype1 = catagoryDataLevel1.find(
          (typeitem) => typeitem.AQ_Category_Name == selectedCatagory1
        );
        let selectedCatagory2 = item['Sub Category II']?.split(" - ")[0];
        let findValidCategorytype2 = catagoryDataLevel2.find(
          (typeitem) => typeitem.AQ_Category_Name == selectedCatagory2
        );

        let selectedCatagory3 = item['Sub Category III']?.split(" - ")[0];
        let findValidCategorytype3 = catagoryDataLevel3.find(
          (typeitem) => typeitem.AQ_Category_Name == selectedCatagory3
        );
      
        let findValidAssessmenttype = AssesmentTypeLV.find(
          (typeitem : any) => typeitem.Options == item["Assessment Type (Comma Seperated)"]
        );

        let findValidapplicableForLV = applicableForLV.find(
          (typeitem : any) => typeitem.Options == item["Applicable For"]
        );

        
        let findValidResponder = ResponderLV.find(
          (typeitem : any) => typeitem.Options == item["Responder"]
        );

        let findValidDepartment = DepartmentList.find(
          (typeitem : any) => typeitem.DepartmentName == item["Department"]
        );

        AQMasterImportdata.map((header_item: any) => {
       
          if (header_item.Field === "Category_name") {

            if(item[header_item.DisplayName] && item[header_item.DisplayName] != undefined){
              object[header_item.Field] = item[header_item.DisplayName];
            if (findValidCategorytype1 && findValidCategorytype1?.AQ_Category_Id) {
              object[header_item.Field] = findValidCategorytype1.AQ_Category_Name;
              object.Category_name_id = findValidCategorytype1.AQ_Category_Id;
            }else{
              error += " Invalid Category,";
            }
          } else {
              error += " Category is Required,";
            }
          }
          if (header_item.Field === "sub_Category_nameII") {

            if(item[header_item.DisplayName] && item[header_item.DisplayName] != undefined){
              object[header_item.Field] = item[header_item.DisplayName];
            if (findValidCategorytype2 && findValidCategorytype2?.AQ_Category_Id && findValidCategorytype2?.Parent_AQ_Category_Id == findValidCategorytype1?.AQ_Category_Id ) {
              object[header_item.Field] = findValidCategorytype2.AQ_Category_Name;
              object.sub_Category_nameII_id = findValidCategorytype2.AQ_Category_Id;
            }else{
              error += " Invalid Sub Category II,";
            }
          } else {
               // this is optional 
            }
          }

          if (header_item.Field === "sub_Category_nameIII") {

            if(item[header_item.DisplayName] && item[header_item.DisplayName] != undefined){
              object[header_item.Field] = item[header_item.DisplayName];
            if (findValidCategorytype3 && findValidCategorytype3?.AQ_Category_Id && findValidCategorytype3?.Parent_AQ_Category_Id == findValidCategorytype2?.AQ_Category_Id ) {
              object[header_item.Field] = findValidCategorytype3.AQ_Category_Name;
              object.sub_Category_nameIII_id = findValidCategorytype3.AQ_Category_Id;
            }else{
              error += " Invalid Sub Category III,";
            }
          } else {
               // this is optional 
            }
          }

          if (header_item.Field === "applicable_for") {
            if (findValidapplicableForLV?.LovId) {
              object[header_item.Field] = item[header_item.DisplayName];
              object.applicable_for_Id = findValidapplicableForLV?.LovId;
            } else {
              if (item[header_item.DisplayName]) {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Applicable For,";
              } else {
                error += " Applicable For is Required,";
              }
            }
          }

          if (header_item.Field === "question_title") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Question Title is Required,";
            }
          }

          if (header_item.Field === "question") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Question is Required,";
            }
          }

          if (header_item.Field === "currency_name") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
            } else {
              error += " Currency Name is Required,";
            }
          }


          if (header_item.Field === "question_type") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
                   object[header_item.Field] = item[header_item.DisplayName];
                   if(object[header_item.Field] == "Multiple Choice" || object[header_item.Field] == "Free Text"){
                                if(object[header_item.Field] == "Multiple Choice"){
                                  object.question_type_Id = true
                                } 
                                if(object[header_item.Field] == "Free Text"){
                                  object.question_type_Id = false
                                }     
                              }else{
                                error += " Invalid Answer Type is Required,";
                              }          
            } else {
              error += " Answer Type is Required,";
            }
          }
      if(object["Answer Type"] == "Multiple Choice"){
          if (header_item.Field === "applicable_risks") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              let enteredapplicablerisk = item[header_item.DisplayName];
              object[header_item.Field] = enteredapplicablerisk;
              let riskArray = enteredapplicablerisk.split(",");
                 let invalidValue : any = "" 
              let matchingIds = riskArray.map((riskValue: any) => {
                let trimmedValue = riskValue?.trim();
                let matchedItem = applicableRiskData.find((riskitem: any) => riskitem.RiskCategory?.trim() === trimmedValue);
                if(!matchedItem){
                    invalidValue = invalidValue + " "  + trimmedValue
                }
                return matchedItem ? matchedItem.RiskCategory_Id : null;
              });
              matchingIds = matchingIds.filter((id: any) => id !== null);

              if (matchingIds && matchingIds?.length > 0 && !invalidValue) {
                   console.log(matchingIds , "matchingIds");
                   
                      object.applicable_risks_Id = matchingIds
              } else {
                error += `${invalidValue} - Invalid Applicable Risk,`;
              }
            } else {
              error += " Applicable Risk is Required,";
            }
          }

          if(header_item.Field === "choices"){
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
              let ScoreVal = item["Score (Comma Seperated)"]
              if(ScoreVal){
              let choiceValidation = validateChoicesAndScores(item[header_item.DisplayName], ScoreVal  , true)
              console.log( choiceValidation , 'choicesArray');
                     if(choiceValidation && choiceValidation?.isValid){
                      object.choices_Value = choiceValidation?.values;
                     }else{
                      error += `${choiceValidation?.message}`;
                          
                     }
                    }
            }else{
              error += " Choices is Required,";
            }
                 
          } 
        
          if(header_item.Field === "score"){
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];
              let choiceVal = item["Choices (Comma Seperated)"]
              if(choiceVal){
              let choiceValidation = validateChoicesAndScores(choiceVal, item[header_item.DisplayName]  , false)
              console.log( choiceValidation , 'choicesArray score');
                     if(choiceValidation && choiceValidation?.isValid){
                      object.score_Value = choiceValidation?.values;
                     }else{
                      error += `${choiceValidation?.message}`;
                          
                     }
                    }
            }else{
              error += " Score is Required,";
            }
                 
          } 
        }
          if(header_item.Field === "evidence_required"){
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];

                     if(item[header_item.DisplayName] == "Yes"){
                      object.evidence_required_Id = true;
                     }else if(item[header_item.DisplayName] == "No"){
                      object.evidence_required_Id = false;
                     }else{  
                      error += " Invalid Evidence Required Value,";      
                     }
            }else{
              object[header_item.Field] = "No";
              object.evidence_required_Id = false;
            }   
          } 

         

          if (header_item.Field === "assessment_type") {
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              let enteredassessment_type = item[header_item.DisplayName];
              object[header_item.Field] = enteredassessment_type;
              let riskArray = enteredassessment_type.split(",");
                 let invalidValue : any = "" 
              let matchingIds = riskArray.map((assessment_typeValue: any) => {
                let trimmedValue = assessment_typeValue?.trim();
                let matchedItem = AssesmentTypeLV.find((assessitem: any) => assessitem.Options?.trim() === trimmedValue);
                if(!matchedItem){
                    invalidValue = invalidValue + " "  + trimmedValue
                }
                return matchedItem ? matchedItem.LovId : null;
              });
              matchingIds = matchingIds.filter((id: any) => id !== null);

              if (matchingIds && matchingIds?.length > 0 && !invalidValue) {
                 
                      object.assessment_type_Id = matchingIds
              } else {
                error += `${invalidValue} - Invalid Assessment,`;
              }
            } else {
              error += "  Assessment Type is Required,";
            }
          }

          if (header_item.Field === "responder") {
            if (findValidResponder?.LovId) {
              object[header_item.Field] = item[header_item.DisplayName];
              object.responder_Id = findValidResponder?.LovId;
            } else {
              if (item[header_item.DisplayName]) {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Responder,";
              } else {
                error += " Responder is Required,";
              }
            }
          }
          if (header_item.Field === "department") {
            if(findValidResponder && findValidResponder?.Options !== AQMResponder.SO ){
            if (findValidDepartment?.DepartmentId) {
              object[header_item.Field] = item[header_item.DisplayName];
              object.department_Id = findValidDepartment?.DepartmentId;
            } else {
              if (item[header_item.DisplayName]) {
                object[header_item.Field] = item[header_item.DisplayName];
                error += " Invalid Department,";
              } else {
                error += " Department is Required,";
              }
            }
          }
          }

          if(header_item.Field === "sme_verified"){
            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              object[header_item.Field] = item[header_item.DisplayName];

                     if(item[header_item.DisplayName] == "Yes"){
                      object.sme_verified_Id = true;
                     }else if(item[header_item.DisplayName] == "No"){
                      object.sme_verified_Id = false;
                     }else{  
                      error += " Invalid Evidence Required Value,";      
                     }
            }else{
            
              if(findValidResponder && findValidResponder?.Options !== AQMResponder.Vendor ){
                object[header_item.Field] = "Yes";
                object.sme_verified_Id = true;     
              }else{
                object[header_item.Field] = "No";
                object.sme_verified_Id = false;
              }
            }   
          } 


          if(header_item.Field === "Service_Mapping" && findValidCategorytype1 && findValidCategorytype1?.Map_Service_Catalogue == true){

            if (item[header_item.DisplayName] && item[header_item.DisplayName] != undefined) {
              let enteredService = item[header_item.DisplayName];
              object[header_item.Field] = enteredService;
              let ServiceArray = enteredService.split(",");
                 let invalidValue : any = "" 
              let matchingIds = ServiceArray.map((ServiceValue: any) => {
                let trimmedValue = ServiceValue?.trim();
                let matchedItem = serviceList.find((asseitem: any) => asseitem.ServiceName?.trim() === trimmedValue);
                if(!matchedItem){
                    invalidValue = invalidValue + " "  + trimmedValue
                }
                return matchedItem ? matchedItem.ServiceName : null;
              });
              matchingIds = matchingIds.filter((id: any) => id !== null);

              if (matchingIds && matchingIds?.length > 0 && !invalidValue) {
                let companyFinal : any[] = matchingIds?.map((comVal : any) => {
                  let findObj = serviceList.find((serviceitem: any) => serviceitem.ServiceName == comVal)
                      return {
                        ServiceCatalog_Id: findObj?.ServiceCatalogId,
                        ServiceCatalog_Name : findObj?.ServiceCatalogId,
                        Status: lovActiveStatusId,
                      }
              })
                object.Service_Mapping_Id = companyFinal
              } else {
                error += `${invalidValue} - Invalid Service,`;
              }
            } else {
              error += " Service is Required,";
            }
            
          }
          
          object.error = error;
        });
        ValidatedData.push(object);
      });
      // pass parend component
      setselectedValidation(ValidatedData);
    }
  };

  useEffect(() => {
    if (uploaddata) {
      department_uploadsheet_validation();
    }
  }, [uploaddata]);

  const generateExcelWorkbook = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");
      const hiddenSheet = workbook.addWorksheet("HiddenSheet"); // Hidden sheet for large data lists
      const NoteSheet = workbook.addWorksheet("Note"); // Hidden sheet for large data lists

      // Define worksheet columns based on AQMasterImportdata
      let sheet_Column: any = AQMasterImportdata.map((col_item: any) => {
        return { header: col_item.DisplayName, key: col_item.Field, width: 30 };
      });
      worksheet.columns = sheet_Column;
      worksheet.addRow(["", "", "", "", ""]);

      // Map the category data
      let catagoryDataLevel1Sheet =
        catagoryDataLevel1?.map(
          (item: any) =>
            item.AQ_Category_Name +
            " - " +
            `[${item?.Map_Service_Catalogue == true ? "Need Service Mapping" : "No Need Service Mapping"}]`
        ) || [];
      let catagoryDataLevel2Sheet =
        catagoryDataLevel2?.map((item: any) => item.AQ_Category_Name + " - " + `[${item.Parent_AQ_Category_Name}]`) ||
        [];
      let catagoryDataLevel3Sheet =
        catagoryDataLevel3?.map((item: any) => item.AQ_Category_Name + " - " + `[${item.Parent_AQ_Category_Name}]`) ||
        [];
      let applicableRiskDataSheet = applicableRiskData?.map((item: any) => item.RiskCategory) || [];
      let AssesmentTypeLVDataSheet = AssesmentTypeLV?.map((item: any) => item.Options);
      let applicableForLVDataSheet = applicableForLV?.map((item: any) => item.Options);
      let ResponderLVDataSheet = ResponderLV?.map((item: any) => item.Options);
      let DepartmentListDataSheet = DepartmentList?.map((item: any) => item.DepartmentName);
      let serviceListListDataSheet = serviceList?.map((item: any) => item.ServiceName);
      const firstFiveServiceItems: any = serviceListListDataSheet?.slice(0, 5).join(",");
      console.log(catagoryDataLevel1Sheet, catagoryDataLevel2Sheet, catagoryDataLevel3Sheet);
      NoteSheet.getCell(
        `A${1}`
      ).value = `Note : Applicable Risk List is Multi Select data, set Comma sepertated Value only Given below, Example : ${applicableRiskDataSheet.join(
        ","
      )}`;
      applicableRiskDataSheet.forEach((item, index) => {
        NoteSheet.getCell(`A${index + 3}`).value = item;
      });

      NoteSheet.getCell(
        `B${1}`
      ).value = `Note : Assessment List is Multi Select data, set Comma sepertated Value only Given below, Example : ${AssesmentTypeLVDataSheet.join(
        ","
      )}`;
      AssesmentTypeLVDataSheet.forEach((item, index) => {
        NoteSheet.getCell(`B${index + 3}`).value = item;
      });
      NoteSheet.getCell(
        `C${1}`
      ).value = `Note : Service List is Multi Select data, set Comma sepertated Value only Given below, Example : ${firstFiveServiceItems}`;

      NoteSheet.getCell(`C3`).value = "Service Name";
      NoteSheet.getCell(`D3`).value = "Summary";
      NoteSheet.getCell(`E3`).value = "Service Group";
      NoteSheet.getCell(`F3`).value = "Service Type";

      // Set header background color (for B3:E3)
      ["C3", "D3", "E3", "F3"].forEach((cell) => {
        NoteSheet.getCell(cell).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
        NoteSheet.getCell(cell).font = { bold: true };
      });

      serviceList.forEach((item, index) => {
        let row = index + 5;

        NoteSheet.getCell(`C${row}`).value = item?.ServiceName;
        NoteSheet.getCell(`D${row}`).value = item?.Summary;
        NoteSheet.getCell(`E${row}`).value = item?.ServiceGroupName;
        NoteSheet.getCell(`F${row}`).value = item?.ServicetypeName;

        ["C", "D", "E", "F"].forEach((col) => {
          NoteSheet.getCell(`${col}${row}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "E0E0E0" },
          };
        });
      });

      if (catagoryDataLevel1Sheet.length > 0) {
        catagoryDataLevel1Sheet.forEach((item, index) => {
          hiddenSheet.getCell(`A${index + 1}`).value = item;
        });
      }

      if (catagoryDataLevel2Sheet.length > 0) {
        catagoryDataLevel2Sheet.forEach((item, index) => {
          hiddenSheet.getCell(`B${index + 1}`).value = item;
        });
      }

      if (catagoryDataLevel3Sheet.length > 0) {
        catagoryDataLevel3Sheet.forEach((item, index) => {
          hiddenSheet.getCell(`C${index + 1}`).value = item;
        });
      }

      if (applicableRiskDataSheet.length > 0) {
        applicableRiskDataSheet.forEach((item, index) => {
          hiddenSheet.getCell(`D${index + 1}`).value = item;
        });
      }
      if (DepartmentListDataSheet.length > 0) {
        DepartmentListDataSheet.forEach((item, index) => {
          hiddenSheet.getCell(`E${index + 1}`).value = item;
        });
      }

      hiddenSheet.state = "hidden"; // Hide the sheet

      // Loop to add data validation to cells
      for (let row = 2; row <= MasterBulkUpload.ValidationRowCount; row++) {
        // Apply data validation for Category Level 1 only if data is available
        if (catagoryDataLevel1Sheet.length > 0) {
          worksheet.getCell(`A${row}`).dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`HiddenSheet!$A$1:$A$${catagoryDataLevel1Sheet.length}`],
          };
        }

        // Apply data validation for Category Level 2 only if data is available
        if (catagoryDataLevel2Sheet.length > 0) {
          worksheet.getCell(`B${row}`).dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`HiddenSheet!$B$1:$B$${catagoryDataLevel2Sheet.length}`],
          };
        }

        if (catagoryDataLevel3Sheet.length > 0) {
          worksheet.getCell(`C${row}`).dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`HiddenSheet!$C$1:$C$${catagoryDataLevel3Sheet.length}`],
          };
        }

        if (catagoryDataLevel3Sheet.length > 0) {
          worksheet.getCell(`C${row}`).dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`HiddenSheet!$C$1:$C$${catagoryDataLevel3Sheet.length}`],
          };
        }

        
        worksheet.getCell(`D${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${applicableForLVDataSheet.join(",")}"`],
          errorTitle: "Applicable For Required",
          error: "Please select a Applicable For from the list",
        };

        worksheet.getCell(`G${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${question_typeList.join(",")}"`],
          errorTitle: "Invalid Evidence Required",
          error: "Please select a Evidence Required from the list",
        };

        // if (applicableRiskDataSheet.length > 0) {
        //   worksheet.getCell(`G${2}`).value = `${applicableRiskDataSheet.join(",")}`;
        // }

        worksheet.getCell(`K${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${checkbokvalue.join(",")}"`],
          errorTitle: "Invalid Evidence Required",
          error: "Please select a Evidence Required from the list",
        };
        // worksheet.getCell(`L${row}`).dataValidation = {
        //   type: "list",
        //   allowBlank: false,
        //   formulae: [`"${AssesmentTypeLVDataSheet.join(",")}"`],
        //   errorTitle: "Invalid Assessment List",
        //   error: "Please select a Assessment List from the list",
        // };
        worksheet.getCell(`M${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${ResponderLVDataSheet.join(",")}"`],
          errorTitle: "Invalid Responder",
          error: "Please select a Responder from the list",
        };

        worksheet.getCell(`O${row}`).dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [`"${checkbokvalue.join(",")}"`],
          errorTitle: "Invalid SME verification is needed",
          error: "Please select a SME verification is needed from the list",
        };

        if (DepartmentListDataSheet.length > 0) {
          worksheet.getCell(`N${row}`).dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: [`HiddenSheet!$E$1:$E$${DepartmentListDataSheet.length}`],
          };
        }

        if (AssesmentTypeLVDataSheet) {
          worksheet.getCell(`L${2}`).value = `${AssesmentTypeLVDataSheet.join(",")}`;
        }
      }

      // Generate Excel file and trigger download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      FileSaver.saveAs(blob, "Assessment_Questionnaire_master_bulk_upload_template.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };



  return (
    <>
      <CustomButton type="reset" name={MasterBulkUpload.exportButtonName} onClick={() => generateExcelWorkbook()} />
    </>
  );
};

export default AQMasterUploadSheet;
