import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import fileHandlerService from "../services/fileHandlerService";

const initialState = {
  isFileUpload: false,
  isFileUploadLoading: false,
  isFileDownload: false,
  uploadData: "",
  downloadFile: "",
};

export interface addEventLogRequestParam {
  ModuleName: string;
  Action: string;
  Description: string;
  UserId: number;
  CreatedBy: string;
}

export const UploadAFile = createAsyncThunk("UploadAFile", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await fileHandlerService.UploadAFile(requestParams);
    if (response) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const DownLoadAFile = createAsyncThunk("DownLoadAFile", async (requestParams: any, { rejectWithValue }) => {
  try {
    const response: any = await fileHandlerService.DownLoadAFile(requestParams);
    const { data } = response;
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fileHandler = createSlice({
  name: "fileHandler",
  initialState,
  reducers: {
    fileHandlerUpdate: (state, action) => {
      state.isFileUpload = false;
      state.isFileDownload = false;
      state.isFileUploadLoading = false;
      
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(UploadAFile.pending, (state) => {
        state.isFileUpload = false;
        state.isFileUploadLoading = true;
        state.isFileDownload = false;
      })
      .addCase(UploadAFile.fulfilled, (state, action: PayloadAction<any>) => {
        state.isFileUpload = true;
        state.isFileUploadLoading = false;
        state.isFileDownload = false;
        state.uploadData = action.payload;
      })
      .addCase(UploadAFile.rejected, (state, action: PayloadAction<any>) => {
        state.isFileUpload = false;
        state.isFileUploadLoading = false;
        state.isFileDownload = false;
      })
      .addCase(DownLoadAFile.pending, (state) => {
        state.isFileUpload = false;
        state.isFileDownload = false;
      })
      .addCase(DownLoadAFile.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload,"DownLoadAFile ~ Slices")
        state.isFileUpload = false;
        state.isFileDownload = true;
        state.downloadFile = action.payload;
      })
      .addCase(DownLoadAFile.rejected, (state, action: PayloadAction<any>) => {
        state.isFileUpload = false;
        state.isFileDownload = true;
      }),
});

export const { fileHandlerUpdate } = fileHandler.actions;
export default fileHandler.reducer;
