export const FIELD_WIDTH = {
  xs: 12, // Full width on extra small screens
  sm: 4, // Full width on small screens
  md: 4, // Half width on medium screens
  lg: 4, // One-third width on large screens
  xl: 4, // One-fourth width on extra large screens
  margin: "8px", // One-fourth width on extra large screens
};

export const FIELD_TYPES = {
  TEXT: "Text",
  DROPDOWN: "Dropdown",
};

export const FIELD_TYPE_OPTIONS = { id: "", lable: "Select Type" };

export const LOOKUP_CONTENT_OPTIONS = { id: "", lable: "Select Options" };

export const COUNTRY_OPTIONS = [
  { id: "", lable: "Select Country" },
  { id: "india", lable: "iNDIA" },
  { id: "usa", lable: "USA" },
];

export const STATE_OPTIONS = [
  { id: "", lable: "Select State" },
  { id: "tn", lable: "Tamil Nadu" },
  { id: "kerala", lable: "Kerala" },
];

export const LOOKUP_OPTIONS = { id: "", lable: "Select Lookup" }
