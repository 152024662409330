import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatchThunk } from "../../store/store";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import {
  getMakerCheckerApprovalById,
  getMakerCheckerStatus,
  getPendingApprovalList,
  updateApproveorRejectFlag,
} from "../../store/slices/pendingApprovals";
import { IPendingApproval } from "../../models/pendingApproval.type";
import ApproveRejectPendingRecord from "../approveRejectPendingRecord/ApproveRejectPendingRecord";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import { IMenu } from "../../models/loggin.type";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { getLovList } from "../../store/slices/lovMasterData";
import CompanyMasterApproveReject from "../approveRejectPendingRecord/CompanyMasterApproveRejectPendingRecord";
import { ModuleNames } from "../../config/config";
import VendorApproveRejectPendingRecord from "../approveRejectPendingRecord/VendorApproveRejectPendingRecords";
import AQMasterApproveReject from "../approveRejectPendingRecord/AqMasterApproveRejectPendingRecord";
import UserMasterApproveRejectPendingRecord from "../approveRejectPendingRecord/UserMasterApproveRejectPendingRecord";
import RoleMasterApproveRejectPendingRecord from "../approveRejectPendingRecord/RoleMasterApproveRejectPendingRecord";
import ServiceCatalogApproveRejectPendingRecord from "../approveRejectPendingRecord/ServiceCatalogApproveRejectPendingRecord";

const PendingApproval: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [selectedPendingApprovalRecord, setSelectedPendingApprovalRecord] = useState<any>();
  const [selectedPendingApprovalRecordData, setSelectedPendingApprovalRecordData] = useState<any>();
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const { pendingApprovalRecords, pendingApprovalByID, isApprovedorReject, ToasterMsg } = useSelector(
    (state: RootState) => state.pendingApproval
  );
  const [Option, setOption] = useState("");

  const dispatch = useAppDispatchThunk();
  const [pendingApprovalRecorData, setPendingApprovalRecorData] = useState<IPendingApproval[]>([]);

  const columns: any = {
    table_name: "Pending Approval",
    isAction: true,
    columns: [
      {
        Field: "ModuleName",
        DisplayName: "Module Name",
        FieldType: "string",
      },
      {
        Field: "Name",
        DisplayName: "Primary Name",
        FieldType: "string",
      },

      {
        Field: "Action",
        DisplayName: "Activity",
        FieldType: "string",
      },
      {
        Field: "Status",
        DisplayName: "Status",
        FieldType: "string",
      },
    ],
  };

  // useEffect(() => {
  //   if (
  //     menuData &&
  //     menuData.length > 0 &&
  //     menuData.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Configure Settings").length > 0
  //   ) {
  //     let menuPage = menuData.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Configure Settings")[0];
  //     if (
  //       menuPage.SubMenus &&
  //       menuPage.SubMenus.length > 0 &&
  //       menuPage.SubMenus.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Pending Approval").length >
  //         0
  //     ) {
  //       setPermissionForpage(
  //         menuPage.SubMenus.filter((menuItem: { MenuName: string }) => menuItem.MenuName === "Pending Approval")[0]
  //       );
  //     }
  //   }
  // }, [menuData]);
  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permssionPage?.MenuId,
      ModuleName: "Pending Approval",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Pending Approval",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        setIsEditing(true);
        dispatch(updateLoader(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      const data = {
        RecordlockId: recordLock[0].RecordlockId,
        RecordId: recordLock[0].RecordId,
        IsActive: false,
      };
      dispatch(UpdateRecordLock(data));
    }
    return recordLock;
  };
  // useEffect(() => {
  //   if (!view) {
  //     const data = recordLockUpdate();
  //     if (data.length > 0) {
  //       dispatch(UpdateRecordLock(data.pop()));
  //     }
  //   }
  // }, [view]);

  const handleOpen = async (data: IPendingApproval, Option: string) => {
    setSelectedPendingApprovalRecord(null);
    setSelectedPendingApprovalRecordData(null);
    setOption(Option);
    if (Option === "edit") {
      setSelectedPendingApprovalRecord(data);
      dispatch(updateLoader(true));
      const canEdit = await handleRecordLockCheck(data?.Id);
      if (canEdit || true) {
        setEdit(true);
        setSelectedPendingApprovalRecord(data);
        dispatch(
          getMakerCheckerApprovalById({
            TempId: data.TempId,
            Id: data.Id,
            ModuleId: data.ModuleId,
          })
        );
        const dispatchData = handleAddRecordLock(data?.Id);
        await dispatch(AddRecordLock(dispatchData));
        //setIsEditOpen(true);
        const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.Id)));
        if (GetRecordLock.fulfilled.match(resultAction)) {
          const recordLockData = resultAction.payload;
          console.log(recordLockData, resultAction, "recordLockData");
          setGetRecordLock(recordLockData);
        }
        dispatch(updateLoader(false));
        // Set timeout to close the popup after 2.5 minutes
        // setTimeout(() => {
        //   setView(false);
        //   const data = recordLockUpdate();
        //   if (data.length > 0) {
        //     dispatch(UpdateRecordLock(data.pop()));
        //   }
        // }, 150000);
      }
    } else if (Option === "view") {
      setSelectedPendingApprovalRecord(data);
      dispatch(updateLoader(true));
      dispatch(
        getMakerCheckerApprovalById({
          TempId: data.TempId,
          Id: data.Id,
          ModuleId: data.ModuleId,
        })
      );
      dispatch(updateLoader(false));
      setEdit(true);
    }
  };

  useEffect(() => {
    dispatch(getPendingApprovalList({ category: "master" }));
    dispatch(getMakerCheckerStatus({ Name: "MakerChecker" }));
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    setPendingApprovalRecorData(pendingApprovalRecords);
  }, [pendingApprovalRecords]);

  useEffect(() => {
    if (pendingApprovalByID) {
      console.log(pendingApprovalByID, "pendingApprovalByID");

      const newValues = pendingApprovalByID?.NewValuesForDisplay || "";
      const oldValues = pendingApprovalByID.OldValues || "";

      if (newValues.length > 0 || oldValues.length > 0) {
        let value = {
          NewValues: {},
          OldValues: {},
        };

        value.NewValues = newValues !== "" ? JSON.parse(newValues) : [];
        value.OldValues = oldValues !== "" ? JSON.parse(oldValues) : [];

        setSelectedPendingApprovalRecordData(value);
        setView(true);
      }
    }
  }, [pendingApprovalByID]);

  useEffect(() => {
    if (isApprovedorReject) {
      dispatch(getPendingApprovalList({ category: "master" }));
      dispatch(updateLoader(false));
      dispatch(updateApproveorRejectFlag(false));
      // dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === false ? true : false }));
    }
  }, [isApprovedorReject]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              Pending Approval
            </Typography>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={pendingApprovalRecorData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                showhistory={false}
                showAdd={false}
                showDelete={false}
                // showDelete={false}
                showView={permssionPage?.IsView === 1}
                showEdit={true}
                showExports={permssionPage?.IsExport === 1}
                pageName="Pending Approval"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={edit}
        onHide={() => {
          if (Option === "edit") {
            recordLockUpdate();
          }
          dispatch(updateApproveorRejectFlag(false));
          setEdit(false);
        }}
        maxWidth={"md"}
        minHeight={"80vh"}
        // header={Option === "view" ? "View Pending Approval" : (selectedPendingApprovalRecordData && Object.keys(selectedPendingApprovalRecordData.OldValues).length > 0
        // ? "Pending Approval Update"
        // : "Pending Approval Create")}
        header={Option === "view" ? "View Pending Approval" : (selectedPendingApprovalRecord && selectedPendingApprovalRecord.Action === "Edit"
          ? "Pending Approval Update"
          : "Pending Approval Create")}
        contentNode={
          <>
            {Option === "edit" && (
              <>
                {ModuleNames.CompanyMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.VendorMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.AQMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.UserMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.RoleMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.ServiceCatalog != selectedPendingApprovalRecord.ModuleName && <ApproveRejectPendingRecord
                  closePopUp={() => {
                    recordLockUpdate();
                    dispatch(updateApproveorRejectFlag(false));
                    setEdit(false);
                  }}
                  view={false}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData}
                  setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                />}
                {ModuleNames.CompanyMaster == selectedPendingApprovalRecord.ModuleName && <CompanyMasterApproveReject closePopUp={() => {
                  recordLockUpdate();
                  dispatch(updateApproveorRejectFlag(false));
                  setEdit(false);
                }}
                  view={false} selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData} />}

                {ModuleNames.VendorMaster == selectedPendingApprovalRecord.ModuleName && <VendorApproveRejectPendingRecord closePopUp={() => {
                  recordLockUpdate();
                  dispatch(updateApproveorRejectFlag(false));
                  setEdit(false);
                }}
                  view={false}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData} />}

                {ModuleNames.AQMaster == selectedPendingApprovalRecord.ModuleName && <AQMasterApproveReject closePopUp={() => {
                  recordLockUpdate();
                  dispatch(updateApproveorRejectFlag(false));
                  setEdit(false);
                }}
                  view={false} selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData} />}

                {ModuleNames.UserMaster == selectedPendingApprovalRecord.ModuleName && <UserMasterApproveRejectPendingRecord
                  closePopUp={() => {
                    recordLockUpdate();
                    dispatch(updateApproveorRejectFlag(false));
                    setEdit(false);
                  }}
                  view={false}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData}
                  setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                />
                }

                {ModuleNames.RoleMaster == selectedPendingApprovalRecord.ModuleName && <RoleMasterApproveRejectPendingRecord
                  closePopUp={() => {
                    recordLockUpdate();
                    dispatch(updateApproveorRejectFlag(false));
                    setEdit(false);
                  }}
                  view={false}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData}
                  setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                />
                }

                {ModuleNames.ServiceCatalog == selectedPendingApprovalRecord.ModuleName && <ServiceCatalogApproveRejectPendingRecord
                  closePopUp={() => {
                    recordLockUpdate();
                    dispatch(updateApproveorRejectFlag(false));
                    setEdit(false);
                  }}
                  view={false}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData}
                  setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                />
                }
              </>
            )}


            {Option === "view" && (
              <>
                {ModuleNames.CompanyMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.VendorMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.AQMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.UserMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.RoleMaster != selectedPendingApprovalRecord.ModuleName && ModuleNames.ServiceCatalog != selectedPendingApprovalRecord.ModuleName && 
                  <ApproveRejectPendingRecord
                    closePopUp={() => {
                      dispatch(updateApproveorRejectFlag(false));
                      setEdit(false);
                    }}
                    view={true}
                    selectedGridRecord={selectedPendingApprovalRecord}
                    selectedRecordDetails={selectedPendingApprovalRecordData}
                    setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                  />}
                {ModuleNames.VendorMaster == selectedPendingApprovalRecord.ModuleName && <VendorApproveRejectPendingRecord closePopUp={() => {
                  dispatch(updateApproveorRejectFlag(false));
                  setEdit(false);
                }}
                  view={true}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData} />}

                {ModuleNames.CompanyMaster == selectedPendingApprovalRecord.ModuleName && <CompanyMasterApproveReject closePopUp={() => {
                  dispatch(updateApproveorRejectFlag(false));
                  setEdit(false);
                }}
                  view={false} selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData} />}

                {ModuleNames.AQMaster == selectedPendingApprovalRecord.ModuleName && <AQMasterApproveReject closePopUp={() => {
                  recordLockUpdate();
                  dispatch(updateApproveorRejectFlag(false));
                  setEdit(false);
                }}
                  view={true} selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData} />}

                {ModuleNames.UserMaster == selectedPendingApprovalRecord.ModuleName && <UserMasterApproveRejectPendingRecord
                  closePopUp={() => {
                    recordLockUpdate();
                    dispatch(updateApproveorRejectFlag(false));
                    setEdit(false);
                  }}
                  view={true}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData}
                  setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                />
                }

                {ModuleNames.RoleMaster == selectedPendingApprovalRecord.ModuleName && <RoleMasterApproveRejectPendingRecord
                  closePopUp={() => {
                    recordLockUpdate();
                    dispatch(updateApproveorRejectFlag(false));
                    setEdit(false);
                  }}
                  view={true}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData}
                  setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                />
                }

                {ModuleNames.ServiceCatalog == selectedPendingApprovalRecord.ModuleName && <ServiceCatalogApproveRejectPendingRecord
                  closePopUp={() => {
                    recordLockUpdate();
                    dispatch(updateApproveorRejectFlag(false));
                    setEdit(false);
                  }}
                  view={true}
                  selectedGridRecord={selectedPendingApprovalRecord}
                  selectedRecordDetails={selectedPendingApprovalRecordData}
                  setSelectedRecordDetails={setSelectedPendingApprovalRecordData}
                />
                }
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default PendingApproval;
