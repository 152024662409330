import { FormIdConfig } from "../../../config/config";
import { getDateOrConvertSameTime } from "../../../utils/constants";


export const documentMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
    let modifiedValidExcelData: any[] = [];
  
    data?.forEach((item: any) => {
      let JsonDataForDisplay: any = {
        "Document Name": item.document_Name,
        "Short Name": item.short_Name,
        "Document ExtType": item.document_Extension_Id?.map((inneritem: any) => inneritem?.Options).join(","),
        "Assessment Type": item.assessment_Type_Id?.map((inneritem: any) => inneritem?.Options).join(","),
        "Is Perpetual": item.is_Perpetual_Id === true ? "Yes" : "No",
        "Is Mandatory": item.is_Mandatory_Id === true ? "Yes" : "No",
        Status: "Active",
        "Modified By": userCreatedBy,
      };
  
      let modifiedItem = {
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        assessment_Type: JSON.stringify(item.assessment_Type_Id) || "[]",
        DocType_Extn_Id: JSON.stringify(item.document_Extension_Id) || "[]",
        Document_Purpose: item.purpose_Id,
        IsMandatory: item.is_Mandatory_Id === true ? 1 : 0,
        IsPerpetual: item.is_Perpetual_Id === true ? 1 : 0,
        document_Name: item.document_Name,
        ShortName: item.short_Name,
        Updated_By: userCreatedBy,
        Updated_On: getDateOrConvertSameTime(),
        UserId: userCreatedById,
        Status: 1,
        ActionTaken: "create",
        IsActive: 1,
        id:item.id
      };
  
      modifiedValidExcelData.push(modifiedItem); // Add the new object with only the required fields
    });
  
    return modifiedValidExcelData || [];
  };
  

export const departmentMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
    let modifiedValidExcelData: any[] = [];
  
    data.forEach((item: any) => {
      let JsonDataForDisplay = {
        "Department Type": item?.department_Type,
        "Department Name": item?.department_Name,
        "Department Code": item?.department_Code,
        "Parent Level": item?.department_Level,
        "Parent Department Name": item?.parent_Department,
        Status: "Active",
        "Modified By": userCreatedBy,
      };
  
      let modifiedItem = {
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        department_Type: item?.department_Type_Id,
        department_Code: item?.department_Code,
        DepartmentName: item?.department_Name,
        Parent_Level: item?.department_Level_Id,
        parent_Department_id: item?.parent_Department_id ? item?.parent_Department_id : 0,

        Status: 1,
        CreatedBy: userCreatedBy,
        UpdatedBy: userCreatedBy,
        Updated_On: getDateOrConvertSameTime(),
        IsActive: 1,
        ActionTaken : 'Create',
        UserId: userCreatedById,
        id:item.id
      };
  
      modifiedValidExcelData.push(modifiedItem); // Add the new object with the transformed data
    });
  
    return modifiedValidExcelData || [];
  };
  


export const currencyMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
    let modifiedValidExcelData: any[] = [];
  
    data.forEach((item: any) => {
      let JsonDataForDisplay: any = {
        "Currency Code": item.currency_code,
        "Currency Name": item.currency_name,
        "Symbol": item.symbol,
        "Country": item?.country,
        "Status": "Active",
        "Modified By": userCreatedBy,
      };
  
      let modifiedItem = {
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        currency_code : item?.currency_code ,
        currency_name :  item?.currency_name ,
        symbol : item?.symbol ,
        country_Id : item?.country_Id ,

        Status: 1,
        CreatedBy: userCreatedBy,
        Updated_By: userCreatedBy,
        Updated_On: getDateOrConvertSameTime(),
        IsActive: 1,
        UserId: userCreatedById,
        ActionTaken: "",
        id:item.id
      };
  
      modifiedValidExcelData.push(modifiedItem); // Add the new object with only the relevant fields
    });
  
    return modifiedValidExcelData || [];
  };
  

export const branchMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
    let modifiedValidExcelData: any[] = [];
  
    data.forEach((item: any) => {
        let JsonDataForDisplay: any = {
            "Branch Code": item.branch_code || "",
            "Branch Name": item.branch_name || "",
            "Branch Type": item.branch_type || "",
            "Company": item.company || "",
            "Branch City": item.branch_city || "",
            "Branch State": item.branch_zone || "",
            "Branch Region": item.branch_region || "",
            "Branch Zone": item.branch_region || "",
            "Status": "Active",
            "Modified By": userCreatedBy,
          };

      let modifiedItem = {
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        BranchType: item.branch_type_Id,
        CompanyId: JSON.stringify(item.company_Id) || "",
        BranchCode: JSON.stringify(item.branch_code),
        BranchName: item.branch_name,
        City: item.branch_city,
        Region: item.branch_zone,
        State: item.branch_state,
        District: item.branch_region,

        UpdatedBy: userCreatedBy,
        CreatedBy: userCreatedBy,
        UserId: userCreatedById,
        Updated_On: getDateOrConvertSameTime(),
        Status: 1,
        ActionTaken: "create",
        IsActive: 1,
        id:item.id
      };
  
      modifiedValidExcelData.push(modifiedItem); // Add the new object with the transformed data
    });
  
    return modifiedValidExcelData || [];
  };
  

export const industoryMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
  let modifiedValidExcelData: any[] = [];

  data.forEach((item: any) => {
    let JsonDataForDisplay: any = {
      "Industry Type": item?.industory_Type || "",
      "Industry Name": item?.industory_Name || "",
      "Parent Level": item?.industory_Level || "",
      "Parent Industry": item?.parent_Industory || "",
      "Status": "Active",
      "Modified By": userCreatedBy,
    };

    let modifiedItem = {
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
      IndustryType: item?.industory_Type_Id,
      Industry_Name: item?.industory_Name,
      Parent_Level: item?.industory_Level_Id,
      Parent_Industry_Id: item?.parent_Industory_Id ? item?.parent_Industory_Id : 0,

      Status: 1,
      CreatedBy: userCreatedBy,
      Updated_By: userCreatedBy,
      Updated_On: getDateOrConvertSameTime(),
      ActionTaken: "create",
      IsActive: 1,
      UserId: userCreatedById,
      id: item?.id
    };

    modifiedValidExcelData.push(modifiedItem); 
  });

  return modifiedValidExcelData || [];
};


export const locationMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
  let modifiedValidExcelData: any[] = [];

  data.forEach((item: any) => {
    let JsonDataForDisplay: any = {
      "Location Name": item?.location_name || "",
      "Location Type": item?.location_Type || "",
      "Parent Location": item?.parent_Location || "",
      "Start Pin": item?.start_Pin || "",
      "End Pin": item?.end_Pin || "",
      "Zone": item?.zone || "",
      "Region": item?.region || "",
      "Status": "Active",
      "Modified By": userCreatedBy,
    };

    let modifiedItem = {
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
      location_name: item?.location_name,
      Location_TypeId: item?.location_Type_Id,
      ParentLocation_Id: item?.parent_Location_Id ? item?.parent_Location_Id : 0,
      start_Pin: item?.start_Pin || 0,
      end_Pin: item?.end_Pin || 0,
      ZoneId: item?.zone_Id || 0,
      RegionId: item?.region_Id || 0,

      Status: 1,
      Updated_By: userCreatedBy,
      Updated_On: getDateOrConvertSameTime(),
      ActionTaken: "create",
      IsActive: 1,
      UserId: userCreatedById,
      id: item?.id,
    };

    modifiedValidExcelData.push(modifiedItem); // Add the modified item to the array
  });

  return modifiedValidExcelData || [];
};


export const thirdPartyMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
  let modifiedValidExcelData: any[] = [];

  data.forEach((item: any) => {

    let JsonDataForDisplay: any = {
      "Third Party Type": item?.third_Party_type || "",
      "Third Party Name": item?.name || "",
      "Short Name": item?.short_Name || "",
      "Pincode": item?.pincode ? item.pincode.toString() : "",
      "Contact Number": item?.ContactNumber ? item.ContactNumber.toString() : "",
      "Address": item?.address || "",
      "City": item?.city_Name || "",
      "Contact Person": item?.contact_Person || "",
      "Email ID": item?.email_id || "",
      "Status": "Active",
      "Modified By": userCreatedBy,
    };

    let modifiedItem = {
      DataForDisplay: JSON.stringify(JsonDataForDisplay),
      ThirdParty_Type: item?.third_Party_type_Id,
      ThirdParty_Name: item?.name,
      ShortName: item?.short_Name,
      pincode: item?.pincode ? item.pincode.toString() : "",
      ContactNumber: item?.ContactNumber ? item.ContactNumber.toString() : "",
      ThirdParty_Address: item?.address || "",
      city: item?.city_Name || "",
      ContactPerson: item?.contact_Person || "",
      EmailId: item?.email_id || "",

      Status: 1,
      Updated_By: userCreatedBy,
      Updated_On: getDateOrConvertSameTime(),
      ActionTaken: "create",
      IsActive: 1,
      UserId: userCreatedById,
      id: item?.id,
    };

    modifiedValidExcelData.push(modifiedItem); // Add modified item to array
  });

  return modifiedValidExcelData || [];
};


export const AQMasterDataMaker = (data: any[], userCreatedBy: any, userCreatedById: any): any[] => {
  let modifiedValidExcelData: any[] = [];

  data.forEach((item: any) => {
     

 let AssessmentQuestionaireServiceMappingsvalue : any = []

 if(item?.Service_Mapping_Id){
  item.Service_Mapping_Id?.forEach((seritem : any , index : any) => {
         let obj = {
          "ServiceMapping_Id": 0,
          "Assessment_Questionaire_Id": 0,
          "ServiceCatalog_Id": seritem?.ServiceCatalog_Id,
          "Status": seritem?.Status,
          "Updated_By": userCreatedBy
        }
        AssessmentQuestionaireServiceMappingsvalue.push(obj)
  })
}
    
    let assessmentQuestionairebestScoresvalues : any = []
    if(item?.applicable_risks_Id){
      item.applicable_risks_Id?.forEach((riskitem : any , index : any) => {
        let obj = {
          "Assessment_Questionaire_BestScoreId": 0,
          "Assessment_Questionaire_Id": 0,
          "RiskId": riskitem,
          "Score": 1,
          "Status": 1,
          "Updated_By": userCreatedBy,
          "Updated_On": getDateOrConvertSameTime(),
          "IsActive": true,
          "Active": true,
          "Approved_By": userCreatedBy,
          "Approved_On": getDateOrConvertSameTime()
        }
        assessmentQuestionairebestScoresvalues.push(obj)
      })
    }
    let assessmentQuestionaireRatingsvalues : any = []
    if(item.choices_Value && item.score_Value){
      item.choices_Value?.forEach((choiceitem : any , index : any) => {
          let obj = {
            "Assessment_Questionaire_RatingId": 0,
            "Assessment_Questionaire_Id": 0,
            "Attribute_Response": choiceitem.toString(),
            "Score": item.score_Value[index],
            "Status": 1,
            "Updated_By": userCreatedBy,
            "Updated_On": getDateOrConvertSameTime(),
            "IsActive": true,
            "Approved_By": userCreatedBy ,
            "Approved_On": getDateOrConvertSameTime()
          }
          assessmentQuestionaireRatingsvalues.push(obj)
      }) 
    }


    let JsonDataForDisplay: any = {

      "Category I" : item?.Category_name,
      "Category II" : item?.sub_Category_nameII,
      "Category III" : item?.sub_Category_nameIII,
      "Applicable For" : item?.applicable_for ,
      "Answer Type" : item?.question_type ,
      "Question Title": item?.question_title,
      "Question": item?.question,
      "Evidence Required": item.evidence_required_Id == true ? "Evidence Required" : "Evidence Not Required",
      "Applicale Risk": item?.applicable_risks ,
      "Assessment Type": item?.assessment_type ,
      "Respondor": item?.responder ,
      "Department": item?.department ,
      "SME verification": item?.sme_verified ,
      "Service Mapping": item?.Service_Mapping || "[]" ,
      "Score": JSON.stringify(assessmentQuestionaireRatingsvalues) || "" ,
      "Status": "Active",
      "Modified By": userCreatedBy ,
      };

    let modifiedItem =  {
      "Assessment_Questionaire_Id": 0,
      "Question_CategoryId": item.Category_name_id,
      "Question_CategoryId_Level2": item?.sub_Category_nameII_id ? item.sub_Category_nameII_id : 0,
      "Question_CategoryId_Level3": item?.sub_Category_nameIII_id ? item.sub_Category_nameIII_id : 0,
      "Applicable_For": item?.applicable_for_Id,
      "Question_Title": item?.question_title,
      "Question": item?.question,
      "EvidenceRequired": item?.evidence_required_Id || false ,
      "Answer_Type": item.question_type_Id,
      "Responder": item?.responder_Id ,
      "Assessment_TypeID": JSON.stringify(item?.assessment_type_Id) || "[]",
      "SME":  item?.department_Id ,
      "SMEVerified": item?.sme_verified_Id ,
      "ApplicableRisks": JSON.stringify(item?.applicable_risks_Id) || "[]",
      "Status": 1,
      "Updated_By": userCreatedBy,
      "Updated_On": getDateOrConvertSameTime(),
      "IsActive": true,
      "Approved_By": userCreatedBy ,
      "Approved_On": getDateOrConvertSameTime(),
      "assessmentQuestionaireRatings": assessmentQuestionaireRatingsvalues || [],
      "assessmentQuestionairebestScores": assessmentQuestionairebestScoresvalues || [] ,
      "AssessmentQuestionaireServiceMappings": AssessmentQuestionaireServiceMappingsvalue || []
    }
  

    let FinalModifiedItem = {
        ...modifiedItem  ,
        ModuleName: "AssessmentQuestionaire",
        UserId: userCreatedById ,
        Activity: "create" ,
        "DataForDisplay" : JSON.stringify(JsonDataForDisplay) , 
        UpdatedBy : userCreatedBy,
        CreatedBy : userCreatedBy, 
        id: item?.id,
        ActionTaken : "create",
    }
    

    modifiedValidExcelData.push(FinalModifiedItem) // Add the new object with the transformed data
  })

  return modifiedValidExcelData || [];
};