import { Box, Chip, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface PageMainHeadingProps {
    lable : string;
  Value : any;
 
}

const LableandValuesPreview : React.FC<PageMainHeadingProps> = (props) => {
  const {
    lable ="",
    Value ="",
  } = props;

  return (
    <>
    <Box sx={{textAlign:'left'}}>

            <Typography  variant="caption" gutterBottom sx={{ height:'12px', color : '#959595' , display: 'block'  , fontSize:'13px' }}>
        {lable}
      </Typography>
      <Typography  variant="subtitle2" gutterBottom sx={{ display: 'block'  , fontSize:'16px' ,height:'16px', }}>
        {Value}
      </Typography>
    </Box>
      
    
    </>
  );
};

export default LableandValuesPreview;
