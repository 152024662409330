import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APService from "../services/applicationParameterService";
import { IApplicationParameter, IVHColumnRes } from "../../models/applicationParameter.type";
import { chkDate } from "../../utils/constants";

export interface IApplicationParameterData {
  applicationParameterData: IApplicationParameter[];
  isAPLoading: boolean;
  isAPAdded: boolean;
  isAPUpdated: boolean;
  versionHistory: { DocumentMaster: IApplicationParameter[]; departmentVHeader: IVHColumnRes[] };
  isAPDeleted: boolean;
  apToasterMsg: any;
}

export const initialState: IApplicationParameterData = {
  applicationParameterData: [],
  isAPLoading: false,
  isAPAdded: false,
  isAPUpdated: false,
  versionHistory: { DocumentMaster: [], departmentVHeader: [] },
  isAPDeleted: false,
  apToasterMsg: { Message: "", Status: "" },
};

export const getApplicationParameterList = createAsyncThunk(
  "getApplicationParameterList",
  async (requestParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await APService.getApplicationParameterList(requestParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addApplicationParameter = createAsyncThunk(
  "addApplicationParameter",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await APService.addApplicationParameter(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateApplicationParameter = createAsyncThunk(
  "updateApplicationParameter",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await APService.updateApplicationParameter(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteApplicationParameter = createAsyncThunk(
  "deleteApplicationParameter",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await APService.deleteApplicationParameter(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getApplicationParameterHistory = createAsyncThunk(
  "getApplicationParameterHistory",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await APService.getApplicationParameterHistory(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const applicationParameterSlice = createSlice({
  name: "applicationParameter",
  initialState,
  reducers: {
    updateAPAddandEdit: (state, action) => {
      state.isAPAdded = false;
      state.isAPUpdated = false;
      state.isAPDeleted = false;
      state.apToasterMsg = { Message: "", Status: "" };
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.applicationParameterData.findIndex(
          (data: any) => data.UserId === action.payload.UserId
        );
        let copyVendorData = [...state.applicationParameterData];
        copyVendorData[indexVendor] = action.payload;
        state.applicationParameterData = copyVendorData;
      }
    },
    // Add reducer for updating version history if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationParameterList.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(getApplicationParameterList.fulfilled, (state, action: PayloadAction<any>) => {
        state.applicationParameterData = action.payload.map((data: any) => {
          data.IsActive = data.IsActive === true ? "Active" : "Inactive";
          return data;
        });
        state.isAPLoading = false;
      })
      .addCase(getApplicationParameterList.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(addApplicationParameter.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(addApplicationParameter.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAPAdded = true;
        state.isAPLoading = false;
        // state.apToasterMsg = action.payload.Message;
        state.apToasterMsg.Message = action.payload.Message;
        state.apToasterMsg.Status = action.payload.Status;
      })
      .addCase(addApplicationParameter.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(updateApplicationParameter.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(updateApplicationParameter.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAPUpdated = true;
        state.isAPLoading = false;
        // state.apToasterMsg = action.payload.Message;
        state.apToasterMsg.Message = action.payload.Message;
        state.apToasterMsg.Status = action.payload.Status;
      })
      .addCase(updateApplicationParameter.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(deleteApplicationParameter.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(deleteApplicationParameter.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAPDeleted = true;
        state.isAPLoading = false;
        // state.apToasterMsg = action.payload.Message;
        state.apToasterMsg.Message = action.payload.Message;
        state.apToasterMsg.Status = action.payload.Status;
      })
      .addCase(deleteApplicationParameter.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      })
      .addCase(getApplicationParameterHistory.pending, (state) => {
        state.isAPLoading = true;
      })
      .addCase(getApplicationParameterHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.departmentVHeader = action.payload.HeaderJson.ColumnHeaders;
        state.versionHistory.DocumentMaster = action.payload.ApplicationParameter.map((user: any) => {
          user.IsMandatory = user.IsMandatory === true ? "Yes" : "No";
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.Approved_On = chkDate(user.Approved_On);

          return user;
        });
        state.isAPLoading = false;
        // state.ToasterMsg = "Department data deleted successfully";
      })
      .addCase(getApplicationParameterHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isAPLoading = false;
      });
  },
});

export const { updateAPAddandEdit, updateUser } = applicationParameterSlice.actions;
export default applicationParameterSlice.reducer;
