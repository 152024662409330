import React, { useEffect, useState } from "react";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import {
  ProcessDetailContext,
  RolesandResponsibility,
} from "../../vendorOnBoarding/rolesAndResponsibility/RolesAndResponsibility";
import { ProcessDetails } from "../../vendorOnBoarding/rolesAndResponsibility/ProcessDetails";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { getWorkList, setWorkFlowData } from "../../../store/slices/workFlowSlice";
import { getLovList } from "../../../store/slices/lovMasterData";
import { Box } from "@mui/material";

interface ValidationErrors {
  [key: string]: string;
}

const RandRSetup = () => {
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [workflowProcess, setWorkflowProcess] = useState<any[]>([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const [ischangeForm, setischangeForm] = useState<boolean>(false);
  const dispatch = useAppDispatchThunk();
  const [selectedData, setSelectedData] = useState<any>(null);
  const { workFlowsData, WorkflowToasterMsg, isWorkFlowCreated, isWorkFlowDeleted } = useSelector(
    (state: RootState) => state.workFlow
  );

  useEffect(() => {
    dispatch(getWorkList());
    dispatch(getLovList({ Name: "" }));
  }, []);

  useEffect(() => {
    if (workFlowsData) {
      let ActiveWorkflowList = workFlowsData.filter((item: any) => item?.IsActive === "Active");
      setWorkFlowData(ActiveWorkflowList);
    }
  }, [workFlowsData]);

  const Validatedepartmentuser = (selDepartments: any, selPrimaryusers: any) => {
    if (!selDepartments || !selPrimaryusers) return false;

    try {
      return selDepartments.some((dep_item: any) => {
        return selPrimaryusers.every((user_item: any) => {
          const userData = allUsers.find((user: any) => user.UserId === user_item);
          return userData?.DepartmentId !== dep_item;
        });
      });
    } catch {
      return true;
    }
  };

  const validateField = (index: number, field: string, value: any) => {
    let errors: { [key: string]: string } = { ...validationErrors };

    switch (field) {
      case "RoleId":
        if (!value) {
          errors[`${index}-RoleId`] = "Role is required";
        } else {
          delete errors[`${index}-RoleId`];
        }
        break;

      case "TAT":
        if (!value || isNaN(value)) {
          errors[`${index}-TAT`] = "Estimated Time is required";
        } else {
          if (value && value <= 0) {
            errors[`${index}-TAT`] = "Please enter valid Estimated Time";
          } else if (value && value > 90) {
            errors[`${index}-TAT`] = "Please enter below 90 days";
          } else {
            delete errors[`${index}-TAT`];
          }
        }
        break;
      case "PrimaryUser":
        if (!value || value?.length === 0) {
          errors[`${index}-PrimaryUser`] = "At least one primary user is required";
        } else {
          const CheckWorkflowProcess = [...workflowProcess];
          const department = CheckWorkflowProcess[index]?.DepartmentName || [];
          if (value && Validatedepartmentuser(department, value)) {
            errors[`${index}-PrimaryUser`] = "At least one primary user in selected department";
          } else {
            delete errors[`${index}-PrimaryUser`];
          }
        }
        break;
      default:
        break;
    }

    setValidationErrors(errors);
    return errors;
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    const newWorkflowProcess = [...workflowProcess];
    newWorkflowProcess[index][field] = value;

    if (field === "DepartmentName") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "RoleId") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "PrimaryUser") {
      let SecoundaryUserData = newWorkflowProcess[index].SecondaryUser;
      if (SecoundaryUserData && SecoundaryUserData?.length > 0) {
        const filteredSecondaryUsers = SecoundaryUserData.filter((user: any) => !value.includes(user));
        newWorkflowProcess[index].SecondaryUser = filteredSecondaryUsers;
      }
    }
    if (field === "PrimaryUser" || field === "SecondaryUser") {
      const escalateToSet = new Set();

      const usersList = [...newWorkflowProcess[index].SecondaryUser, ...newWorkflowProcess[index].PrimaryUser];

      usersList.forEach((userId: any) => {
        const managerId = allUsers.find((user: any) => user.UserId === userId)?.ManagerId;
        if (managerId) {
          escalateToSet.add(managerId);
        }
      });

      newWorkflowProcess[index].EscalateTo = Array.from(escalateToSet);
    }

    // setValue(
    //   "Tat",
    //   newWorkflowProcess.reduce((n, { TAT, IsActive }) => (IsActive ? n + Number(TAT) : n), 0)
    // );
    setischangeForm(true);
    setWorkflowProcess(newWorkflowProcess);
    validateField(index, field, value);
  };

  useEffect(() => {
    if (selectedData) {
      if (selectedData.WorkflowProcessInstance) {
        let parsedWorkflowProcess = JSON.parse(selectedData.WorkflowProcessInstance);
        if (parsedWorkflowProcess) {
          const updatedDataArray = parsedWorkflowProcess?.map((item: any) => ({
            ...item,
            ProcessName: item?.Process_Name,
            PrimaryUser: item.Primary_User ? item.Primary_User.split(",").map(Number) : [],
            SecondaryUser: item.Secondary_User ? item.Secondary_User.split(",").map(Number) : [],
            EscalateTo: item.Escalate_To ? item.Escalate_To.split(",").map(Number) : [],
            DepartmentName: item.Department ? item.Department.split(",").map(Number) : [],
            RoleId: item.Role ? item.Role : "",
          }));
          console.log(updatedDataArray, "parsedWorkflowProcessPreview");
          setWorkflowProcess(updatedDataArray);
        }
      } else {
        try {
          let parsedWorkflowProcess = JSON.parse(selectedData.WorkflowProcessAsJson);
          setWorkflowSteps(parsedWorkflowProcess);
        } catch (error) {
          console.error("Error parsing WorkflowProcessAsJson:", error);
        }
      }
    }
  }, [selectedData]);

  const setWorkflowSteps = (Allsteps: any) => {
    try {
      let parsedWorkflowProcess = Allsteps;
      if (Array.isArray(parsedWorkflowProcess)) {
        parsedWorkflowProcess = parsedWorkflowProcess.reduce((acc: any, item: any) => {
          if (item.IsActive)
            acc.push({
              ...item,
              Process_Name: item?.ProcessName,
              DepartmentName: item?.DepartmentId
                ? item?.DepartmentId?.split(",").map((ditem: any) => parseInt(ditem, 10))
                : [],
              Role: item?.RoleId,
              PrimaryUser: [],
              SecondaryUser: [],
              EscalateTo: [],
            });
          return acc;
        }, []);
      }
      setWorkflowProcess(parsedWorkflowProcess);
    } catch (error) {
      console.error("Error parsing WorkflowProcessAsJson:", error);
    }
  };

  return (
    <>
      <Box style={{ width: "50%" }}>
        <CustomSelect
          name={"WorkflowName"}
          valueKey={"Id"}
          optionLabelKey="WorkflowName"
          options={workFlowsData}
          value={selectedWorkflow}
          onChange={(val) => {
            setSelectedWorkflow(val);
            setSelectedData(workFlowsData.find((item) => item.Id === val));
          }}
          required={true}
          label={"WorkFlow"}
        />
      </Box>
      <ProcessDetailContext.Provider value={{ workflowProcess, validationErrors, handleInputChange }}>
        <ProcessDetails view={false} />
      </ProcessDetailContext.Provider>
    </>
  );
};

export default RandRSetup;
