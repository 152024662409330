import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { useAppDispatch } from "../../../../store/store";
import { CustomBasicAccordion } from "../../../../common/dynamicAccordion/CustomBasicAccordion";
import { textFieldWidth } from "../../../../utils/constants";
import LableandValuesPreview from "../../../../common/CommonPreview/LableandValuesPreview";


interface VendorDetailsCXOsignoffprops {
    ProjectDetails:any
    VendorDetails:any
}

export const VendorDetailsCXOsignoff: React.FC<VendorDetailsCXOsignoffprops> = ({ProjectDetails , VendorDetails}) => {
  const [expanded, setExpanded] = useState<any>({ 0: true, 1: true, 2: true, 3: true, 4: true });
  const [natureServiceDetails, setnatureServiceDetails] = useState<any>({});
  const [ProjectDetail, setProjectDetail] = useState<any>({});
  const [VendorBasicDetails, setVendorBasicDetails] = useState<any>({});
  const dispatch = useAppDispatch();


  const handleAccordionChange = (index: number) => {
    setExpanded((prevExpanded: any[]) => ({ ...prevExpanded, [index]: !prevExpanded[index] }));
  };

 useEffect(() => {
    if(ProjectDetails){
        console.log(ProjectDetail , 'ProjectDetail');
        
        setProjectDetail(ProjectDetails)
    }
 },[ProjectDetails])

 useEffect(() => {
    if(VendorDetails && VendorDetails?.VendorDetails){
        setVendorBasicDetails(VendorDetails?.VendorDetails);
    }
  
 },[])



  return (
    <>
      <CustomBasicAccordion
        expanded={expanded[0]}
        handleChange={() => handleAccordionChange(0)}
        title="NATURE OF SERVICES"
        details={<>
                   <Box>
              <Grid container spacing={2}>
                <Grid
                 item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <LableandValuesPreview lable="Service 1" Value={`${natureServiceDetails?.Assessment_Number || '-'}`} />
                </Grid>
          
             
              </Grid>
            </Box>
        
        </>}
      />
      
      <CustomBasicAccordion
        expanded={expanded[1]}
        handleChange={() => handleAccordionChange(1)}
        title="PROJECTED EXPENSES"
        details={<>   <Box>
            <Grid container spacing={2}>
              <Grid
               item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Project Cost" Value={`${ProjectDetail?.Estimated_Spend || '-'}`} />
              </Grid>
             
           
            </Grid>
          </Box></>}
      />
       
      <CustomBasicAccordion
        expanded={expanded[2]}
        handleChange={() => handleAccordionChange(2)}
        title="TERM OF SERVICES"
        details={<>   <Box>
            <Grid container spacing={2} >
              <Grid
               item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Start Date" Value={`${ProjectDetail?.Project_Start_Date || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                    <LableandValuesPreview lable="End Date" Value={`${ProjectDetail?.Project_End_Date || '-'}`} />
              </Grid>
             
           
            </Grid>
          </Box></>}
      />
       
      <CustomBasicAccordion
        expanded={expanded[3]}
        handleChange={() => handleAccordionChange(3)}
        title="VENDOR BACKGROUND"
        details={<>   <Box>
            <Grid container spacing={2} >
              <Grid
               item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Key Customer" Value={`${VendorBasicDetails?.KeyCustomer || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                    <LableandValuesPreview lable="Internal ThirdParty Code" Value={`${VendorBasicDetails?.Internal_VendorCode || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                         <LableandValuesPreview lable="Industries Catered" Value={`${VendorBasicDetails?.Industries_Served || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                         <LableandValuesPreview lable="Volume Handle" Value={`${VendorBasicDetails?.VolumeHandled || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                         <LableandValuesPreview lable="Competitiors" Value={`${VendorBasicDetails?.Competitor_Details || '-'}`} />
              </Grid>
           
            </Grid>
          </Box></>}
      />
      {/* <Divider/> */}
      <CustomBasicAccordion
        expanded={expanded[4]}
        handleChange={() => handleAccordionChange(4)}
        title="VENDOR RELATION"
        details={<> <Box>
            <Grid container spacing={2}>
              <Grid
               item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <LableandValuesPreview lable="Vendor Type" Value={`${VendorBasicDetails?.Type_Of_Vendor2_Value || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                    <LableandValuesPreview lable="No.of existing Services" Value={`${natureServiceDetails?.Assessment_Type_1 || '-'}`} />
              </Grid>
              <Grid
                    item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                         <LableandValuesPreview lable="Existing Services 1" Value={`${natureServiceDetails?.Assessment_Type_2 || '-'}`} />
              </Grid>
             
           
           
            </Grid>
          </Box></>}
      />
    </>
  );
};
