import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import axios from "axios";

import { localConfig, localhostConfig } from "./mockData";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { createDocument, deleteDocument, getDocument, resetDocumentState } from "../../store/slices/textEditorSlice";
import { textEditorPortVersion } from "../../store/services/config/constant";
import "./OnlyOfficeTextEditor.css";
import PluginStepper from "./plugin/PluginStepper/PluginStepper";
import { updateLoader } from "../../store/slices/loader";
interface IContractTemplate {
  templateId?: any;
  fileName?: any;
  editorsMode?: "edit" | "view" | "filter" | "blockContent" | "review";
  isNewContract?: boolean;
  setUpdatedTemplatedId?: any;
  isOpenDocument?: boolean;
}
const onDocumentReady = async (event: any, setUpdatedTemplatedId: any) => {
  window.addEventListener("message", (message) => {
    if (
      (message.origin === "http://localhost:3000" ||
        message.origin === "https://dev.clife.ai" ||
        message.origin === "https://qa.clife.ai") &&
      message.data === "message"
    ) {
      const iframe = document.querySelector('iframe[name="frameEditor"]') as HTMLIFrameElement | null;
      if (iframe && iframe.contentWindow) {
        if (iframe) {
          let clauseData = localStorage.getItem("ClauseData");
          let loginData = localStorage.getItem("persist:root");
          let USER_ROLE_KEY = localStorage.getItem("USER_ROLE_KEY");
          let userData = localStorage.getItem("userData");
          let contract_Tempate_Id = localStorage.getItem("contract_Tempate_Id");
          let object = {
            ClauseData: clauseData,
            loginData: loginData,
            USER_ROLE_KEY: USER_ROLE_KEY,
            userData: userData,
            contract_Tempate_Id: contract_Tempate_Id,
          };
          if (iframe) {
            iframe?.contentWindow?.postMessage(JSON.stringify(object), "https://onlyoffice.clife.ai");
          }
        }
      }
    } else if (
      (message.origin === "http://localhost:3000" ||
        message.origin === "https://dev.clife.ai" ||
        message.origin === "https://qa.clife.ai") &&
      typeof message.data === "string" &&
      message.data.includes("pluginKey")
    ) {
      const messages = JSON.parse(localStorage.getItem("pluginData") || "[]");
      if (!messages.includes(message.data)) {
        messages.push(message.data);
        localStorage.setItem("pluginData", JSON.stringify(messages));
      }
    }

    const parsedData = JSON.parse(localStorage.getItem("pluginData") || "[]");
    console.log(parsedData, "allPluginDataallPluginData");
    const updatedTemplate = parsedData?.find(
      (item: any) => JSON.parse(item)?.pluginKey === "updated_contract_template_id"
    );
    const updatedTemplateId = updatedTemplate ? JSON.parse(updatedTemplate).value : null;
    if (setUpdatedTemplatedId) {
      setUpdatedTemplatedId(updatedTemplateId);
    }
  });
};

const onLoadComponentError = (errorCode: number, errorDescription: string) => {
  switch (errorCode) {
    case -1: // Unknown error loading component
    case -2: // Error loading DocsAPI from document server
    case -3: // DocsAPI is not defined
      console.log(errorDescription);
      break;
    default:
      console.log("Unhandled error: ", errorDescription);
  }
};

const OnlyOfficeTextEditor: React.FC<IContractTemplate> = ({
  templateId,
  fileName,
  editorsMode,
  isNewContract,
  setUpdatedTemplatedId,
  isOpenDocument,
}) => {
  const dispatch = useAppDispatchThunk();
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { REACT_APP_TEXT_EDITOR, REACT_APP_TEXT_EDITOR_SERVER } = process.env;
  const { editorDocumentID } = useSelector((state: any) => state.contractManagementReducer.editorDoc);
  const { isLoading } = useSelector((state: RootState) => contractManagementReducer.textEditor);
  const [selectedFileConfig, setSelectedFileConfig] = useState<any>(null);
  const serviceUrl = `${REACT_APP_TEXT_EDITOR}`;
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleCreateNewFile = async () => {
    if (!fileName) return;
    const req = {
      filename: fileName,
      directUrl: false,
      fileExt: "docx",
      templateId: templateId,
      userid: userLoginResponse?.UserId,
      editorsMode: editorsMode ? editorsMode : "review",
    };
    try {
      const response = await dispatch(createDocument(req)).unwrap();
      setSelectedFileConfig(response);
    } catch (error) {
      console.error("Config not found in response.");
    }
  };

  const handleOpenFile = async () => {
    if (!fileName) return;
    try {
      const getReq = {
        filename: fileName,
        editorsMode: "review",
        userid: userLoginResponse?.UserId,
      };
      const response = await dispatch(getDocument(getReq)).unwrap();
      setSelectedFileConfig(response);
      dispatch(updateLoader(false));
    } catch (error) {
      console.error("Config not found in response.");
    }
  };
  const handleDeletePreview = async (fileName: any) => {
    if (!fileName) return;

    const req = {
      filename: fileName,
    };

    try {
      const response = await dispatch(deleteDocument(req)).unwrap();
      return true;
    } catch (error) {
      console.error("Config not found in response.");
    }
  };

  useEffect(() => {
    if (!isOpenDocument) {
      handleCreateNewFile();
    } else {
      handleOpenFile();
    }
  }, []);

  const onRequestHistory = async (event: any) => {
    const fileName = selectedFile?.title || null;
    const directUrl = null;

    try {
      const response = await axios.post(`${serviceUrl}/historyObj`, {
        fileName,
        directUrl,
      });
      window.DocEditor.instances.docxEditor.refreshHistory({
        currentVersion: response.data.countVersion,
        history: response.data.history,
      });

      setSelectedFile((prev: any) => ({ ...prev, ...response.data }));
    } catch (error) {
      console.error("Error requesting history:", error);
    }
  };

  const onRequestHistoryData = async (event: any) => {
    const version = event.data;
    const historyData = selectedFile?.historyData[version - 1] || null;
    window.DocEditor.instances.docxEditor.setHistoryData(historyData);
  };

  const onRequestHistoryClose = () => {
    document.location.reload();
  };
  //localStorage.setItem("USER_ROLE_KEY", "SO");
  console.log(selectedFileConfig, "selectedFileConfigselectedFileConfig");
  return (
    <>
      <div style={{ height: "100vh" }}>
        {selectedFileConfig && (
          <DocumentEditor
            id="docxEditor"
            documentServerUrl={`${REACT_APP_TEXT_EDITOR_SERVER}`}
            config={selectedFileConfig}
            events_onDocumentReady={(event) => {
              onDocumentReady(event, setUpdatedTemplatedId);
            }}
            onLoadComponentError={onLoadComponentError}
            events_onRequestHistory={onRequestHistory}
            events_onRequestHistoryClose={onRequestHistoryClose}
            events_onRequestHistoryData={onRequestHistoryData}
          />
        )}

        {isLoading && <p>Loading...</p>}
      </div>
    </>
  );
};

export default OnlyOfficeTextEditor;
