import { Box, Dialog, DialogContent, Stack, Typography, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddUser from "./AddUser";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";

import { updateAddandEdit, updateVersionHistory } from "../../store/slices/vendorData";
import Close from "@mui/icons-material/Close";
import CustomButton from "../../common/dynamicButton/CustomButton";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { IUser } from "../../models/user.type";
import {
  deleteUser,
  getAllRoleMasterList,
  getUserHistory,
  getUsersList,
  updateUserAddandEdit,
} from "../../store/slices/userData";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { IMenu } from "../../models/loggin.type";
import ToasterCompnent from "../../toast/Toaster";
import { updateToaster,updateLoader } from "../../store/slices/loader";

import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { getDefaultValues, updateDefualtAddAndEdit } from "../../store/slices/defaultValues";
import { getALLMenus, getRolesList } from "../../store/slices/roleData";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { getDepartmentList } from "../../store/slices/departmentData";
import { getLovList } from "../../store/slices/lovMasterData";
import { getLocationList, getParentLocationList } from "../../store/slices/locationMasterData";
import { userConfig } from "../../config/config";
import { GetBranchByCompanyList } from "../../store/slices/branchMasterData";
import { Edit_Enable_tosterMSG, LocationConfig, LovConfig } from "../../config/config";


const UserManagement = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [addDefault, setAddDefault] = useState(false);
  const [Option, setOption] = useState("");
  const [isEditUserData, setisEditUserData] = useState<IUser | null>(null);
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const dispatch = useAppDispatch();
  const { allUsers, isUserAdded, isUserUpdated, isUserDeleted, ToasterMsg, versionHistory } = useSelector(
    (state: RootState) => state.userData
  );

  const { DefaultId, DefaultData, isDefaultValuedAddedd, isDefaultValuedUpdated } = useSelector(
    (state: RootState) => state.defaultData
  );

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const columns_version: any = {
    table_name: "User History Records",
    isAction: false,
    columns: [],
  };
  const [defaultData, setDefaultData] = useState<IUser | null>(null);

  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [userAdded, setUserAdded] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [deleteUserPopUp, setDeleteUserPopUp] = useState<boolean>(false);
  const [historyPopUp, setHistoryPopUp] = useState<boolean>(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [vendorHisHeaderColumn, setVendorHisHeaderColumn] = useState(columns_version);
  const [versionHistoryData, getVersionHistoryData] = useState<any>();

  const columns: any = {
    table_name: "User",
    isAction: true,
    columns: [
      // {
      //   Field: "UserId",
      //   DisplayName: "UserId",
      //   FieldType: "number",
      // },
      {
        Field: "FirstName",
        DisplayName: "First Name",
        FieldType: "string",
      },
      {
        Field: "LastName",
        DisplayName: "Last Name",
        FieldType: "string",
      },
      {
        Field: "EmailId",
        DisplayName: "Email",
        FieldType: "string",
      },
      {
        Field: "Mobile",
        DisplayName: "Phone Number",
        FieldType: "number",
      },
      {
        Field: "Country",
        DisplayName: "Country",
        FieldType: "string",
      },
      {
        Field: "DepartmentName",
        DisplayName: "Department Name",
        FieldType: "string",
      },
      {
        Field: "ManagerEmail",
        DisplayName: "Reporting To",
        FieldType: "string",
      },
      {
        Field: "OfficeAddress",
        DisplayName: "Address",
        FieldType: "string",
      },
      {
        Field: "BranchName",
        DisplayName: "Branch",
        FieldType: "string",
      }, 
      {
        Field: "DesignationName",
        DisplayName: "Designation",
        FieldType: "string",
      },
      {
        Field: "RoleName",
        DisplayName: "Role",
        FieldType: "string",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "Updated_By",
        FieldType: "string",
        DisplayName: "Modified By",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getUsersList({
        FormID: 2,
      })
    );
    dispatch(getLovList({ Name: "" }));
    // dispatch(getParentLocationList({ LocationTypeId: userConfig.Country }));
    // dispatch(getParentLocationList({ LocationTypeId: userConfig.State }));
    dispatch(
      getLocationList({
        FormID: 2,
      })
    );
    dispatch(getAllRoleMasterList({ get: "roles" }));
    dispatch(
      getDefaultValues({
        ModuleName: "User Management",
        ScreenName: "User Management",
      })
    );
    dispatch(
      getDepartmentList({
        FormID: 2,
      })
    );
    dispatch(
      GetBranchByCompanyList({
        CompanyId: 0,
      })
    );
  }, []);

  // useEffect(() => {
  //   if (versionHistory?.userHistories?.length > 0 && versionHistory?.UserVHeader?.length > 0) {
  //     setIsEdit(true);
  //     setOption("history");
  //     if (versionHistory.UserVHeader && versionHistory.UserVHeader.length > 0) {
  //       let re_columns = {
  //         ...columns_version,
  //         columns: versionHistory.UserVHeader,
  //       };
  //       console.log(versionHistory, versionHistory.UserVHeader, "versionHistoryversionHistory");
  //       setVendorHisHeaderColumn(re_columns);
  //     }
  //   }
  // }, [versionHistory]);

  useEffect(() => {
    const isVersionHistoryClick = () => {
      if (versionHistory?.userHistories?.length > 0 && versionHistory?.UserVHeader?.length > 0) {
        // setIsEdit(true);
        // setOption("history");
        if (versionHistory.UserVHeader && versionHistory.UserVHeader.length > 0) {
          let re_columns = {
            ...columns_version,
            columns: versionHistory.UserVHeader,
          };
          setVendorHisHeaderColumn(re_columns);
        }
      }
    };
    isVersionHistoryClick();
  }, [versionHistory]);

  useEffect(() => {
    if (allUsers.length > 0) {
      setUsersData(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (isUserAdded) {
      // setUserAdded(true);
      dispatch(updateUserAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      dispatch(
        getUsersList({
          FormID: 2,
        })
      );
    } else if (isUserUpdated) {
      // setUserUpdated(true);
      dispatch(updateUserAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      dispatch(
        getUsersList({
          FormID: 2,
        })
      );
    } else if (isUserDeleted) {
      //setUserDeleted(true);
      setDeleteUserPopUp(false);
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      setTimeout(() => {
        dispatch(updateUserAddandEdit(false));
      }, 1000);
      dispatch(
        getUsersList({
          FormID: 2,
        })
      );
    } else if (isDefaultValuedAddedd) {
      dispatch(
        getDefaultValues({
          ModuleName: "User Management",
          ScreenName: "User Management",
        })
      );
      dispatch(updateToaster({ isToaster: true, toasterMsg: "Default Values Update Successfully" }));
      dispatch(updateDefualtAddAndEdit(false));
    } else if (isDefaultValuedUpdated) {
      dispatch(
        getDefaultValues({
          ModuleName: "User Management",
          ScreenName: "User Management",
        })
      );
      dispatch(updateToaster({ isToaster: true, toasterMsg: "Default Values Update Successfully" }));
      dispatch(updateDefualtAddAndEdit(false));
    }
  }, [isUserAdded, isUserUpdated, isUserDeleted, isDefaultValuedAddedd, isDefaultValuedUpdated]);

  useEffect(() => {
    dispatch(
      getRolesList({
        formId: 3,
      })
    );
    dispatch(getALLMenus(null));
  }, []);
  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (DefaultData && DefaultData !== "") {
      setDefaultData(JSON.parse(DefaultData));
    }
  }, [DefaultData]);
  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(data, "dataLock");
      if (data.length > 0) {
        event.preventDefault();
        event.returnValue = "";
        try {
          await dispatch(UpdateRecordLock(data.pop())).unwrap();
        } catch (error) {
          console.error("Failed to update record lock:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const deleteRoleId = () => {
    dispatch(
      deleteUser({
        UserId: userId,
        CreatedBy: user?.UserName,
        Activity: "delete",
        ModuleName: "User Management",
      })
    );
  };

  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permssionPage?.MenuId,
      ModuleName: "User Management",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "User Management",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        setIsEditing(true);
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      return [
        {
          RecordlockId: recordLock[0].RecordlockId,
          RecordId: recordLock[0].RecordId,
          IsActive: false,
        },
      ];
    }
    return [];
  };
  useEffect(() => {
    if (!isEdit) {
      const data = recordLockUpdate();
      if (data.length > 0) {
        dispatch(UpdateRecordLock(data.pop()));
      }
    }
  }, [isEdit]);

  const deleteUserFunc = () => {
    let formData = { ...isDeleteData };

    let JsonDataForDisplay: any = {
      "First Name": formData.FirstName,
      "Email Id": formData.EmailId,
      "Role": formData.RoleId,
      "Status": formData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    }
    const expectedPayload = {
      UserId: formData.UserId,
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      IsActive: formData.IsActive === "Active" ? 0 : 1,
      Updated_By: userLoginResponse?.UserName,
      CreatedBy: userLoginResponse?.UserName,
      ModuleName: "User Management",
      Activity: formData.IsActive === "Active" ? "inactive" : "active",
      MenuId: permssionPage?.MenuId,
      Form_Id: 7,
      DataForDisplay: JSON.stringify(JsonDataForDisplay),

    };
    dispatch(deleteUser(expectedPayload));
  };

  const handleOpen = async (data: any, option: string) => {
    if (option === "delete") {
      setUserId(data.UserId);
      setIsDeleteData(data);
     setDeleteUserPopUp(true);
    } else if (option === "history") {
      dispatch(
        getUserHistory({
          userId: data.UserId,
          formID: 2,
        })
      );

      setIsVersionHistory(true);
    } else if (option === "edit") {
      setOption(option);
      setisEditUserData(data);
      dispatch(updateLoader(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
        
      }
    
      
      const canEdit = await handleRecordLockCheck(data?.UserId);
     if (canEdit) {
        setIsEdit(true);
        const dispatchData = handleAddRecordLock(data?.UserId);
        await dispatch(AddRecordLock(dispatchData));
        //setIsEditOpen(true);
        const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.UserId)));
        if (GetRecordLock.fulfilled.match(resultAction)) {
          const recordLockData = resultAction.payload;
          setGetRecordLock(recordLockData);
}
        // // Set timeout to close the popup after 2.5 minutes
        // setTimeout(() => {
        //   setIsEdit(false);
        //   const data = recordLockUpdate();
        //   if (data.length > 0) {
        //     dispatch(UpdateRecordLock(data.pop()));
        //   }
        // }, 150000);
      //}
    }  else {

      dispatch(updateLoader(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

  }

  };

  // const handleChange = (value : any , fieldName : string ) => {

  //   let get_value : any = value || ''

  //     setFormData((prevState) => ({
  //         ...prevState,
  //         [fieldName]: get_value,
  //     }));
  // };

  // const isFormValid = () => {
  //     return (
  //         formData.location !== "" ||
  //         (formData.registeredDate !== null && formData.EndDate !== null)
  //     );
  // };

  // const onProcess = (e: React.FormEvent<HTMLFormElement>) => {
  //     e.preventDefault();
  //     if (isFormValid()) {
  //         dispatch(updateLoader(true));
  //         let params: any = {};
  //         if (
  //             formData.location !== "" &&
  //             formData.registeredDate !== null &&
  //             formData.EndDate !== null
  //         ) {
  //           if(new Date(formData.EndDate).getTime()>new Date(formData.registeredDate).getTime() ){
  //             params["location"] = formData.location;
  //             params["startDate"] = dayjs(formData.registeredDate).format(
  //                 "YYYY-MM-DD"
  //             );
  //             params["endDate"] = dayjs(formData.EndDate).format(
  //                 "YYYY-MM-DD"
  //             );
  //             setErrorMsg(false);
  //             dispatch(getVendorList(params));
  //           }else{
  //             setErrorMsg(true)
  //             dispatch(updateLoader(false));
  //           }
  //         } else if (
  //             formData.registeredDate !== null &&
  //             formData.EndDate !== null
  //         ) {
  //           if (
  //             new Date(formData.EndDate).getTime() > new Date(formData.registeredDate).getTime()
  //           ) {
  //             params["startDate"] = dayjs(formData.registeredDate).format(
  //               "YYYY-MM-DD"
  //             );
  //             params["endDate"] = dayjs(formData.EndDate).format(
  //               "YYYY-MM-DD"
  //             );
  //             setErrorMsg(false);
  //             dispatch(getVendorList(params));
  //           }
  //           else{
  //             setErrorMsg(true);
  //             dispatch(updateLoader(false));
  //           }

  //         }
  //         else if (formData.location !== "") {
  //             params["location"] = formData.location;
  //             dispatch(getVendorList(params));
  //             setErrorMsg(false);
  //         }
  //     }else{
  //       dispatch(updateLoader(true));
  //     }
  // };

  // const handleClear = () => {
  //     setFormData({
  //         location: "",
  //         registeredDate: null,
  //         EndDate: null,
  //     })
  //     setformDisplay(false)
  // }
  return (
    <React.Fragment>
      <>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                {" "}
                User Management
              </Typography>
            </Box>

            {/* <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.08)",
                padding: "16px",
                borderRadius: "10px",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <CustomSelect name={'location'} valueKey={'id'} optionLabelKey='lable' options={location_Data} value={formData.location}
                    onChange={handleChange}  required={true} label={'Location'}    />
                  
                </Grid>
           
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <CustomDatePicker    label={'Registered Date From'} value={
                          formData.registeredDate
                            ? dayjs(formData.registeredDate)
                            : null
                        }
                        onChange={(newValue: any) => {
                          setFormData({
                            ...formData,
                            registeredDate: newValue,
                          });
                        }}  />

                   
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                   
                    <CustomDatePicker    label={'Registered Date To'} 
                           value={
                            formData.EndDate ? dayjs(formData.EndDate) : null
                          }
                          onChange={(newValue: any) => {
                            setFormData({ ...formData, EndDate: newValue });
                          }}  />
                  </Grid>
       
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10px" }}>
                    <CustomButton    variant="contained" name={'Search'}  onClick={(e: any) => {
                          onProcess(e);
                        }}/>
                       <CustomButton color="inherit"  variant="contained" name={'Clear'}  onClick={() => handleClear()}/>
                       
                     
                      
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}

            {/* {errorMsg ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5">
                    {" "}
                    Registered Date To should be greater than Registered Date
                    From
                  </Typography>
                </Grid>
              </Box>
            ) : null} */}

            {permssionPage?.IsAdd ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    columnGap: "5px",
                  }}
                >
                  <CustomButton variant="contained" name={"Set Default values"} onClick={() => setAddDefault(true)} />
                  {/* <CustomButton variant="contained" name={"Add User"} onClick={() => setAddForm(true)} /> */}
                </Grid>
              </Box>
            ) : null}

            <Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* <DevExpDynamicGrid
                  data={columns}
                  rows={usersData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  uniqueIdKey="id"
                  hideVersionhistory={true}
                /> */}

                <MaterialDynamicGrid
                  data={columns}
                  rows={usersData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  handleAdd={() => setAddForm(true)}
                  showAdd={true}
                  showhistory={true}
                  showDelete={true}
                  showView={permssionPage?.IsView === 1 ? true : false}
                  showEdit={permssionPage?.IsEdit === 1 ? true : false}
                  showExports={permssionPage?.IsExport === 1 ? true : false}
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                  pageName="User"
                />
              </Box>
            </Box>
          </Stack>
        </Box>
        {/* <Dialog
          open={addForm}
          onClose={() => setAddForm(false)}
          maxWidth={"lg"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent>

            <AddUser
              view={false}
              closePopUp={() => {
                setAddForm(false);
              }}
            />
          </DialogContent>
        </Dialog> */}
        <CustomDialog
          show={addForm}
          onHide={() => setAddForm(false)}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={"Create User"}
          contentNode={
            <AddUser
              view={false}
              closePopUp={() => {
                setAddForm(false);
              }}
              defaultdata={defaultData}
            />
          }
        />

        <CustomDialog
          show={addDefault}
          onHide={() => setAddDefault(false)}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={"User Default Values"}
          contentNode={
            <AddUser
              view={false}
              closePopUp={() => {
                setAddDefault(false);
              }}
              isDefault={true}
              defaultdata={defaultData}
              defaultId={DefaultId}
            />
          }
        />

        <CustomDialog
          show={isEdit}
          onHide={() => {
            if (Option === "history") {
              dispatch(updateVersionHistory([]));
            }
            setIsEdit(false);
          }}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={Option === "view" ? "View User" : Option === "history" ? " Version History" : "Update User"}
          contentNode={
            <>
              {Option === "edit" && (
                <AddUser
                  closePopUp={() => {
                    setIsEdit(false);
                  }}
                  edit={isEdit}
                  userdata={
                    isEditUserData && isEditUserData !== undefined && isEditUserData !== null ? isEditUserData : null
                  }
                />
              )}
              {Option === "view" && (
                <AddUser
                  view={true}
                  closePopUp={() => {
                    setIsEdit(false);
                  }}
                  userdata={
                    isEditUserData && isEditUserData !== undefined && isEditUserData !== null ? isEditUserData : null
                  }
                />
              )}
              {/* {Option === "history" && (
                <MaterialDynamicGrid
                  data={vendorHisHeaderColumn}
                  rows={versionHistory.userHistories}
                  handleAction={handleOpen}
                  enableZoomIcon={false}
                  showhistory={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showView={permssionPage?.IsView === 1}
                  showEdit={permssionPage?.IsEdit === 1}
                  showExports={permssionPage?.IsExport === 1}
                  pageName="User Management History"
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                />
              )} */}
            </>
          }
        />
        {versionHistory.userHistories && (
          <CustomDialog
            show={isVersionHistory}
            onHide={() => {
              setIsVersionHistory(false);
            }}
            maxWidth={"lg"}
            header={"User Management History"}
            contentNode={
              <>
                {" "}
                <MaterialDynamicGrid
                  data={vendorHisHeaderColumn}
                  rows={versionHistory.userHistories}
                  handleAction={handleOpen}
                  enableZoomIcon={false}
                  showhistory={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showView={permssionPage?.IsView === 1}
                  showEdit={permssionPage?.IsEdit === 1}
                  showExports={permssionPage?.IsExport === 1}
                  pageName="User History"
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                />
              </>
            }
          />
        )}
        {/* <ModalPopUp
          header="User Added"
          text="User Added SuccessFully"
          onHide={() => {
            setUserAdded(false);
            dispatch(updateAddandEdit(false));
          }}
          show={userAdded}
        /> */}
        {/* <ModalPopUp
          header="User Updated"
          text="User Updated SuccessFully"
          onHide={() => {
            setUserUpdated(false);
            dispatch(updateAddandEdit(false));
          }}
          show={userUpdated}
        /> */}
        {/* <ModalPopUp
          header="Role Deleted"
          text="Role Deleted SuccessFully"
          onHide={() => {
            setUserDeleted(false);
            dispatch(updateAddandEdit(false));
          }}
          show={userDeleted}
        /> */}
        {/* <ModalPopUp
          header="Delete User Confirmation"
          text={`Are you sure you want to deactivate the selected user`}
          isDelete={deleteUserPopUp}
          onHide={() => {
            setDeleteUserPopUp(false);
          }}
          onDeleteMethod={() => {
            deleteRoleId();
          }}
          show={deleteUserPopUp}
        /> */}
        <ModalPopUp
          onDeleteMethod={() => deleteUserFunc()}
          isDelete={true}
          onHide={() => setIsDeleteData(null)}
          show={isDeleteData ? true : false}
          header={"Confirm"}
          text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
            } the selected User`}
          ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
        />
        {/* {isUserAdded ? <ToasterCompnent
          msg = {ToasterMsg}
      />:null
    }
   
        {isUserUpdated ? <ToasterCompnent
          msg = {ToasterMsg}
      />:null
    } */}
      </>
    </React.Fragment>
  );
};

export default UserManagement;
