import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { textFieldWidth } from "../../utils/constants";
import CustomTextField from "../../common/dynamicInputs/CustomTextField";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomButton from "../../common/dynamicButton/CustomButton";
import { countryDropDown, stateDropDown } from "../../mockData/dropDownJson";
import { addUser, getUsersList, updateUserDetails } from "../../store/slices/userData";
import ProgressBar from "../../common/progressBar/progressBar";
import { addDefaultValue, updateDefaultValue } from "../../store/slices/defaultValues";
import AddRole from "../rolesManagement/AddRole";
import { IRole } from "../../models/roles.type";

export interface responseFormData {
  response: string;
  response_ord: string;
  score: number;
}

interface IAddResponseProps {
  closePopUp: any;
  addData: any;
}

const AddResponse: React.FC<IAddResponseProps> = ({ closePopUp, addData }) => {
  const dispatch = useAppDispatchThunk();
  const { isUserAdded, roleMaster, isUserUpdated } = useSelector((state: RootState) => state.userData);
  const { isDefaultValuedAddedd, isDefaultValuedUpdated } = useSelector((state: RootState) => state.defaultData);
  const { allRoles, isRoleAdded, isRoleUpdated, isRoleDeleted, ToasterMsg, errormessage } = useSelector(
    (state: RootState) => state.roleData
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isEditRoleData, setisEditRoleData] = useState<IRole | null>(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState(false);
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const [loader, setLoader] = useState<boolean>(false);
  let formData: responseFormData = {
    response: "",
    response_ord: "",
    score: 0,
  };
  const {
    handleSubmit: handleUserForm,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<responseFormData>({
    defaultValues: formData,
    mode: "onChange",
  });
  const handleSubmit = () => {
    if (closePopUp && addData) {
      addData(getValues())
      closePopUp();
      setLoader(false);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
      {loader ? <ProgressBar showProgress={loader} /> : null}
      <Grid
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            px: {
              xs: 2,
              sm: 4,
              md: 2,
              lg: 2,
              xl: 2,
            },

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="response"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Response Name is Required",
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"response"}
                      required={true}
                      label={"Response"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.response)}
                      helperText={errorsloginform.response && errorsloginform.response.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="response_ord"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: "Response order is Required",                    
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"response_ord"}
                      required={true}
                      label={"Response Ord"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.response_ord)}
                      helperText={errorsloginform.response_ord && errorsloginform.response_ord.message?.toString()}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
              >
                <Controller
                  name="score"
                  control={control}
                  rules={{
                    required: "score is Required",
                  }}
                  render={({ field }) => (
                    <CustomTextField
                      name={"score"}
                      required={true}
                      type={"number"}
                      label={"score"}
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errorsloginform.score)}
                      helperText={errorsloginform.score && errorsloginform.score.message?.toString()}
                    />
                  )}
                />
              </Grid>

              {/* <Grid
                item
                xs={textFieldWidth.xs}
                sm={textFieldWidth.sm}
                md={textFieldWidth.md}
                lg={textFieldWidth.lg}
                xl={textFieldWidth.xl}
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}    
              >
                <Controller
                  name="IsActive"
                  control={control}
                  render={({ field }) => (
                    <CustomToogleSwitch
                      label={"User Status"}
                      dynamicLabel={field.value ? ACTIVE : INACTIVE}
                      value={field.value}
                      disabled={view}
                      onChange={(data) => {
                        setValue("IsActive", data);
                      }}
                    />
                  )}
                />
              </Grid> */}
            </Grid>
            
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                mt: 2,
              }}
            >
              <React.Fragment>
                <CustomButton
                  type="reset"
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    reset(formData);
                    closePopUp && closePopUp();
                  }}
                />
                <CustomButton type="reset" name={"Submit"} onClick={handleUserForm(handleSubmit)} />
              </React.Fragment>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddResponse;