import React, { useMemo, useState } from "react";
import { Box, FormControl, IconButton, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { MRT_TableContainer, useMaterialReactTable, type MRT_ColumnDef, type MRT_Row } from "material-react-table";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import AddClause from "../ClauseLibrary/AddClause";
import { ContractClause } from "./ContractTemplate";
import AddClauseStepper from "../ClauseLibrary/AddClauseStepper";

interface ReviewClausesProps {
  data: ContractClause[];
  setData: (data: ContractClause[]) => void;
  view?: boolean;
}

const ReviewClauses: React.FC<ReviewClausesProps> = ({ data, setData, view }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedClause, setSelectedClause] = useState<ContractClause | null>(null);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const col = useMemo<MRT_ColumnDef<ContractClause>[]>(
    () => [
      {
        accessorKey: "order",
        header: "S.NO.",
        size: 50,
      },
      {
        accessorKey: "clauseType.clauseType_Name",
        header: "CLAUSE TYPE",
      },
      {
        accessorKey: "clause_Header",
        header: "CLAUSE HEADER",
      },
      {
        accessorKey: "serviceMappings",
        header: "SERVICES",
        muiTableBodyCellProps: {
          sx: {
            borderColor: "transparent",
            whiteSpace: "nowrap",
            maxWidth: "150px",
          },
        },
        Cell: ({ renderedCellValue, row }: any) => {
          return (
            <Box>
              {renderedCellValue ? (
                <span>
                  {renderedCellValue[0]?.services?.service_Name}{" "}
                  {renderedCellValue.length > 1 ? `+ ${renderedCellValue.length - 1}` : ""}
                </span>
              ) : (
                ""
              )}
            </Box>
          );
        },
      },
      {
        accessorKey: "department.departmentName",
        header: "SME",
      },
    ],
    []
  );

  const handleDeleteClause = (row: MRT_Row<ContractClause>) => {
    setData(data.filter((_, index) => index !== row.index).map((e, i) => ({ ...e, order: i + 1 })));
  };

  const handleViewClause = (row: MRT_Row<ContractClause>) => {
    setSelectedClause(row.original);
    setShowDialog(true);
  };

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns: col,
    data,
    enableRowOrdering: !view,
    enableSorting: false,
    // enableRowNumbers: true,
    enableRowActions: !view,
    enableColumnActions: false,
    positionActionsColumn: "last",
    enablePagination: false,
    icons: {
      DragHandleIcon: () => <DragIndicatorIcon fontSize="small" />,
    },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice((hoveredRow as MRT_Row<ContractClause>).index, 0, data.splice(draggingRow.index, 1)[0]);
          setData(data.map((e, i) => ({ ...e, order: i + 1 })));
        }
      },
    }),
    muiTableBodyCellProps: {
      sx: {
        borderBottomColor: "transparent",
      },
      align: "center",
    },
    muiTableHeadCellProps: {
      sx: {
        borderBottomColor: "transparent",
      },
      align: "center",
    },
    muiTableContainerProps: {
      sx: {
        borderRadius: "5px",
        border: "1px solid rgba(0, 0, 0, 0.10)",
      },
    },
    localization: {
      move: "",
      rowNumber: "S.NO.",
      actions: "VIEW / REMOVE",
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => console.info("Edit")} onClickCapture={() => handleViewClause(row)}>
          <RemoveRedEyeOutlinedIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={() => handleDeleteClause(row)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
    muiTablePaperProps: ({ table }) => ({
      style: {
        borderRadius: "5px",
        border: "1px solid rgba(0, 0, 0, 0.10)",
      },
    }),
  });

  return (
    <>
      <MRT_TableContainer table={table} />
      <CustomDialog
        show={showDialog}
        onHide={closeDialog}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={"View Clause"}
        style={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              overflowY: "hidden",
            },
          },
        }}
        contentNode={
          <AddClauseStepper view={true} isEditData={selectedClause} isDisableBreadCrums={true} isDisableActionButton={true} />
        }
      />
    </>
  );
};

export default ReviewClauses;
