import { AxiosError, AxiosResponse } from "axios";
import { axiosSingnzyInstance } from "./config/axiosConfig";
import { SIGNZY_URL } from "./endpoints/signzyService";

const SimpleSearchByCin = (requestBody: any): Promise<any> =>
  axiosSingnzyInstance
    .post(SIGNZY_URL.SimpleSearchByCin, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error while get CIN " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const GSTSearch = (requestBody: any): Promise<any> =>
  axiosSingnzyInstance
    .post(SIGNZY_URL.GSTSearch, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error while get CIN " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const GSTDetails = (requestBody: any): Promise<any> =>
  axiosSingnzyInstance
    .post(SIGNZY_URL.GSTDetails, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error while get CIN " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const PanToGst = (requestBody: any): Promise<any> =>
  axiosSingnzyInstance
    .post(SIGNZY_URL.PanToGst, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error while get CIN " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const PantoCin = (requestBody: any): Promise<any> =>
  axiosSingnzyInstance
    .post(SIGNZY_URL.PantoCin, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error while get Pan to Cin" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const SignzyService = {
  SimpleSearchByCin,
  GSTSearch,
  GSTDetails,
  PanToGst,
  PantoCin,
};

export default SignzyService;
