import { AxiosError, AxiosResponse } from "axios";
import { USERS_URL } from "./endpoints/users";
import { IAddUserResponse, IRoleMasterResponse, IUserResponse, IVHColumnRes } from "../../models/user.type";
import { axiosInstance } from "./config/axiosConfig";
import { Roles_URL } from "./endpoints/roles";

const getUser = (reqparams: any): Promise<IUserResponse> =>
  axiosInstance
    .get(USERS_URL.GetUser, {
      params: reqparams,
    })
    .then((response: AxiosResponse<IUserResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getUsersList = (reqparams: any): Promise<IUserResponse> =>
  axiosInstance
    .get(USERS_URL.GetUsers, {
      params: reqparams,
    })
    .then((response: AxiosResponse<IUserResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addUser = (requestBody: any): Promise<IAddUserResponse> =>
  axiosInstance
    .post(USERS_URL.SaveUser, requestBody)
    .then((response: AxiosResponse<IAddUserResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const saveUserMenu = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(USERS_URL.saveUserMenu, requestBody)
    .then((response: AxiosResponse<IAddUserResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateUser = (requestBody: any): Promise<IAddUserResponse> =>
  axiosInstance
    .post(USERS_URL.UpdateUser, requestBody)
    .then((response: AxiosResponse<IAddUserResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteUser = (requestBody: any): Promise<IAddUserResponse> =>
  axiosInstance
    .post(USERS_URL.DeleteUser, requestBody)
    .then((response: AxiosResponse<IAddUserResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getRoleMasterList = (): Promise<IRoleMasterResponse> =>
  axiosInstance
    .get(Roles_URL.GetMasterRole)
    .then((response: AxiosResponse<IRoleMasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVersionHistoryUser = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(USERS_URL.GetUserHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        console.log(response.data, "responseData");
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const userService = {
  getRoleMasterList,
  getUsersList,
  addUser,
  saveUserMenu,
  updateUser,
  deleteUser,
  getVersionHistoryUser,
  getUser
};

export default userService;
