export const USERS_URL = {
  AddUser: `/addUser`,
  SaveUser:`/SaveUser`,
  saveUserMenu:`/saveUserMenusAccessrights`,
  GetUser: `/getUser`,
  GetUsers: `/getAllUsers`,
  UpdateUser: `/editUser`,
  DeleteUser: `/deleteUser`,
  GetUserHistory: `/getUserHistory`,
};
