import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import moment from "moment";
import LogoExcle from "../../assets/clifelogo.png";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import CustomButton from "../dynamicButton/CustomButton";
interface ExcelDataProps {
  header: any;
  data: any;
  button_title?: any;
  table_name: any;
  otherheaders? : any[]
  otherdatas? : any[]
  note? : string
}
const CommonDataExport: React.FC<ExcelDataProps> = ({ header, data, button_title, table_name , otherheaders , otherdatas , note }) => {
  const { isAuthenticated, user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);

  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);

  const [dataFormate, SetDateFormate] = useState("");
 console.log(otherheaders , otherdatas , 'otherdatas' );
 
  useEffect(() => {
    if (applicationParameterData.length > 0) {
      let dateFormat = applicationParameterData?.find(
        (item: any) => item.Parameter_Name === "Date Time Format" && item.Parameter_Group === "Date and Time"
      )?.Parameter_Value;
      if (dateFormat) SetDateFormate(dateFormat);
    } else SetDateFormate("DD-MM-YYYY hh:mm");
  }, [applicationParameterData]);

  function reorderObjects(dataArray: any[], keyOrder: any[]): any[] {
    const tableData = dataArray.map((row) => {
      const reorderedRow = keyOrder.map((key) => row[key.Field]);
      return reorderedRow;
    });
    return tableData;
  }
  const rearangeData = (dataval : any , headerval : any) => {
    let transformedData: any[] = [];
    dataval?.forEach((rowData: any) => {
      let rowData_recreate = { ...rowData };
      headerval.forEach((column_t: any) => {
        const columnName = column_t.Field;
        const columnType = column_t?.FieldType;
        if (columnType === "date") {
          try {
            if (rowData_recreate[columnName]) {
              rowData_recreate[columnName] = moment(rowData_recreate[columnName]).format(dataFormate);
            } else {
              rowData_recreate[columnName] = "";
            }
          } catch {
            rowData_recreate[columnName] = rowData_recreate[columnName];
          }
        } else if (columnType === "number") {
          try {
            if (rowData_recreate[columnName]) {
              rowData_recreate[columnName] = rowData_recreate[columnName].toString();
            } else {
              rowData_recreate[columnName] = "";
            }
          } catch {
            rowData_recreate[columnName] = rowData_recreate[columnName];
          }
        }
      });
      transformedData.push(rowData_recreate);
    });

    const reorderedDataArray = reorderObjects(transformedData, headerval);
    return reorderedDataArray || [];
  };

  const exportExcelNew = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(table_name, { views: [{ showGridLines: false }] });

    const currentDate = new Date();
    // const dateTimeString = currentDate.toISOString().split("T")[0] + "_" + currentDate.toLocaleTimeString();
    const dateTimeString = moment(currentDate).format("YYYY-MM-DD_HH-mm-ss");



    const fetchImage = async (imageUrl: string): Promise<string> => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const reader = new FileReader();

        return new Promise<string>((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Error fetching image:", error);
        throw error;
      }
    };

    const imageBuffer = await fetchImage(LogoExcle);
    const imageId = workbook.addImage({ base64: imageBuffer, extension: "jpeg" });

    worksheet.addImage(imageId, "B2:B5");
    worksheet.addRow(["", "Module Name :", table_name]);
    worksheet.addRow(["", "Printed By :", user?.UserName]);
    worksheet.addRow(["", "Printed On :", dateTimeString]);
    if(note){
      worksheet.addRow(["", "Note :", note]);
    }
 

    worksheet.addRow([]);

    const columnWidths = header.map((eachheader  : any) => ({ width: 20 }));
    const extraColumnWidth = { width: 20 };
    columnWidths.push(extraColumnWidth);
    worksheet.columns = columnWidths;
    let tablestartRow = 13
    worksheet.addTable({
      name: "MyTable",
      ref: "B13",
      headerRow: true,
      style: {
        theme: "TableStyleLight19",
        showRowStripes: false,
      },
      columns: header.map((eachheader : any) => ({
        name: eachheader.DisplayName,
        header: header.DisplayName,
        key: header.Field,
        width: 20,
        style: { alignment: { horizontal: "left" } },
      })),
      rows: rearangeData(data , header ),
    });

    const applyCellStyle = (worksheet: ExcelJS.Worksheet, endCell: string) => {
      worksheet.getCell(endCell).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ff321149" },
        bgColor: { argb: "ff321149" },
      };
      worksheet.getCell(endCell).font = {
        color: { argb: "ffffffff" },
      };
    };

    header?.forEach((fill_item : any, index : any) => {
      const endCell = String.fromCharCode(65 + index + 1) + tablestartRow;
      applyCellStyle(worksheet, endCell);
    });

  

    if (otherheaders && otherdatas) {
      otherheaders.forEach((each_table, index) => {
        if (!Array.isArray(each_table) || !Array.isArray(otherdatas[index])) {
          console.warn(`Invalid table or data structure at index ${index}. Skipping table generation.`);
          return;
        }

        // Calculate start row
        tablestartRow += otherdatas[index].length + 2;
        try {
          worksheet.addTable({
            name: `MyTable_${index + 1}`,
            ref: `B${tablestartRow}`,
            headerRow: true,
            style: { theme: "TableStyleLight19", showRowStripes: false },
            columns: each_table.map((eachheader) => ({
              name: eachheader?.DisplayName || 'Default Name',
              header: eachheader?.DisplayName || 'Default Header',
              key: eachheader?.Field || 'defaultKey',
              width: 20,
              style: eachheader?.style ? { alignment: { horizontal: "left" } } : undefined,
            })),
            rows: rearangeData(otherdatas[index] , each_table ),
          });
        } catch (error) {
          console.error("Error adding table at index", index, ":", error);
        }
        
      
        each_table.forEach((_, idx) => {
          const endCell = String.fromCharCode(65 + idx + 1) + tablestartRow;
          applyCellStyle(worksheet, endCell);
        });
      });
    }
    
    
    

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${table_name}_${dateTimeString}.xlsx`);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  return (
    <>
      <CustomButton variant="text" name={button_title ? button_title : "Download"} onClick={() => exportExcelNew()} />
    </>
  );
};
export default CommonDataExport;
