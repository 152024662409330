import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IVendorConsent, IVHVendorConsentColumn } from "../../models/vendorConsentMaster.type";

import { chkDate } from "../../utils/constants";
import ContentMasterService from "../services/contentMaster";
export interface IVendorConsentData {
  vendorConentDataTable: any;
  vendorConentPurposes: any;
  isVendorConsentLoading: boolean;
  isVendorConsentAdded: boolean;
  isVendorConsentUpdated: boolean;
  versionHistory:{VendorConsentRecords :IVendorConsent[] , VendorConsentVHeader : IVHVendorConsentColumn[]};
  isVendorConsenttHistory: boolean;
  isVendorConsentDeleted: boolean;
  vendorConsentToasterMsg: string;
}

export const initialState: IVendorConsentData = {
  vendorConentDataTable: [],
  vendorConentPurposes:[],
  isVendorConsentLoading: false,
  isVendorConsentAdded: false,
  isVendorConsentUpdated: false,
  versionHistory:{VendorConsentRecords :[] , VendorConsentVHeader : []},
  isVendorConsenttHistory: false,
  isVendorConsentDeleted: false,
  vendorConsentToasterMsg: "",
};
export const getContentForm = createAsyncThunk("getContentForm", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await ContentMasterService.getContentForm(reqbody);
    return response.data;   
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getContentList = createAsyncThunk(
  "getContentList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await ContentMasterService.getcontentList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addContentMaster = createAsyncThunk("addContentMaster", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await ContentMasterService.addcontentMaster(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatecontentMaster = createAsyncThunk(
  "updatecontentMaster",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await ContentMasterService.updateContentMaster(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletecontentMaster = createAsyncThunk(
  "deletecontentMaster",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await ContentMasterService.deleteContentMaster(reqbody);
      return response.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getContentHistory = createAsyncThunk("getContentHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await ContentMasterService.getVersionHistorycontent(reqbody);
    const {ContentHistories , HeaderJson } =response.data;
    return {VendorConsentHistories: ContentHistories , HeaderJson : HeaderJson.ColumnHeaders}
        
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const VendorCotentDataSlice = createSlice({
  name: "vendorContenttMaster",
  initialState,
  reducers: {
    
    updateContentAddandEdit: (state, action) => {

      state.isVendorConsentAdded = false;
      state.isVendorConsentUpdated = false;
      state.isVendorConsentDeleted = false;
      state.vendorConsentToasterMsg = "";
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.vendorConentDataTable.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.vendorConentDataTable];
        copyVendorData[indexVendor] = action.payload;
        state.vendorConentDataTable = copyVendorData;
      }
    },
    updateVersionHistory:  (state, action)=>{            
      state.versionHistory.VendorConsentRecords = [];
      state.versionHistory.VendorConsentVHeader = [];
    }, 
  },
  extraReducers: (builder) => {
    builder
    .addCase(getContentForm.pending, (state) => {
      state.isVendorConsentLoading = true;
    })
    .addCase(getContentForm.fulfilled, (state, action: PayloadAction<any>) => {
     
      state.vendorConentPurposes = action.payload;
      state.isVendorConsentLoading = false;
    })
    .addCase(getContentForm.rejected, (state, action: PayloadAction<any>) => {
      state.isVendorConsentLoading = false;
    })
      .addCase(getContentList.pending, (state) => {
        state.isVendorConsentLoading = true;
      })
      .addCase(getContentList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");

        state.vendorConentDataTable = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          return user;
        });
        state.isVendorConsentLoading = false;
      })
      .addCase(getContentList.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })
      .addCase(addContentMaster.pending, (state) => {
        state.isVendorConsentLoading = true;
      })
      .addCase(addContentMaster.fulfilled, (state, action: PayloadAction<any>) => {
        // state.dataTable = [...state.dataTable , ...action.payload  ]
        state.isVendorConsentAdded = true;
        state.isVendorConsentLoading = false;
        state.vendorConsentToasterMsg = action.payload.Message;
        
      })
      .addCase(addContentMaster.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })
      .addCase(updatecontentMaster.pending, (state) => {
        state.isVendorConsentLoading = true;
      })
      .addCase(updatecontentMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorConsentUpdated = true;
        state.isVendorConsentLoading = false;
        state.vendorConsentToasterMsg = action.payload.Message;
      })
      .addCase(updatecontentMaster.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })
      .addCase(deletecontentMaster.pending, (state) => {
        state.isVendorConsentLoading = true;
      })
      .addCase(deletecontentMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorConsentDeleted = true;
        state.isVendorConsentLoading = false;
        state.vendorConsentToasterMsg = action.payload.Message;
      })
      .addCase(deletecontentMaster.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })      
      .addCase(getContentHistory.pending, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })
      .addCase(getContentHistory.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload , 'versionHistory1');
        
        state.versionHistory.VendorConsentVHeader = action.payload.HeaderJson
        state.versionHistory.VendorConsentRecords = action.payload.VendorConsentHistories.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.Approved_On = chkDate(user.Approved_On);
          return user;
        });
        state.isVendorConsentLoading = false;
        // state.ToasterMsg = "VendorConsent data deleted successfully";
      })
      .addCase(getContentHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })
    

  },
});

export const { updateContentAddandEdit , updateUser, updateVersionHistory } = VendorCotentDataSlice.actions;
export default VendorCotentDataSlice.reducer;
