import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clauseMasterService from "../services/clauseMasterService";
import {
  ICaluseMasterTypeResponse,
  IClauseMasterType,
  IVHColumn,
  IVHColumnRes,
} from "../../../models/contractManagementModels/clauseMasterModel";
import { ACTIVE, chkDate, INACTIVE } from "../../../utils/constants";

export interface IClauseMasterDetailsData {
  clauseMasterDetailsData: ICaluseMasterTypeResponse[];
  isclauseMasterDetailsLoading: boolean;
  isclauseMasterDetailsAdded: boolean;
  clauseToasterStatus: boolean | null;
  isclauseMasterDetailsUpdated: boolean;
  isclauseMasterDetailsDeleted: boolean;
  ToasterMsg: { Message: string; Status: string };
  versionHistory: { ClauseMasterRecords: IVHColumnRes[]; ClauseMasterVHeader: IVHColumn[] };
  clauseData: any;
}

export const initialState: IClauseMasterDetailsData = {
  clauseMasterDetailsData: [],
  isclauseMasterDetailsLoading: false,
  isclauseMasterDetailsAdded: false,
  clauseToasterStatus: false,
  isclauseMasterDetailsUpdated: false,
  isclauseMasterDetailsDeleted: false,
  ToasterMsg: { Message: "", Status: "" },
  versionHistory: { ClauseMasterRecords: [], ClauseMasterVHeader: [] },
  clauseData: null,
};

export const getClauseMasterTypeList = createAsyncThunk("getClauseMasterTypeList", async (_, { rejectWithValue }) => {
  try {
    const response: any = await clauseMasterService.getClauseMasterTypeList();
    return response.masterClauseTypeList;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createMasterClause = createAsyncThunk(
  "createClaseTypeMaster",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response: any = await clauseMasterService.createClauseTypeMaster(reqBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const updateMasterClause = createAsyncThunk(
  "updateClauseTypeMaster",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response: any = await clauseMasterService.updateClauseTypeMaster(reqBody);
      return response;
    } catch (e: any) {
      return rejectWithValue(e?.response?.data?.errors[0]);
    }
  }
);

export const activeInactiveClauseMaster = createAsyncThunk(
  "activeInactiveClauseTypeMaster",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response: any = await clauseMasterService.activeInactiveClauseTypeMaster(reqBody);
      return response;
    } catch (error: any) {
      console.error("Error in activeInactiveClauseMaster:", error);
      return rejectWithValue(error.message || "Failed to update status");
    }
  }
);

export const getVersionHistory = createAsyncThunk(
  "getVersionHistory",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response: any = await clauseMasterService.getVersionHistory(requestParams);
      const { Records, HeaderJson } = response.data;
      return { History: Records, HeaderJson: HeaderJson.ColumnHeaders };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clauseMasterDataSlice = createSlice({
  name: "clauseMasterDetails",
  initialState,
  reducers: {
    updateClauseMasterDetailsAddandEdit: (state, action: PayloadAction<any>) => {
      state.isclauseMasterDetailsAdded = false;
      state.isclauseMasterDetailsUpdated = false;
      state.isclauseMasterDetailsDeleted = false;
      state.ToasterMsg.Message = action?.payload?.Message;
      state.ToasterMsg.Status = action?.payload?.Status;
    },
    clearData: (state) => {
      state.clauseData = null;
    },
    updateVersionHistory: (state, action) => {
      console.log(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClauseMasterTypeList.pending, (state) => {
        state.isclauseMasterDetailsLoading = true;
      })
      .addCase(getClauseMasterTypeList.fulfilled, (state, action: PayloadAction<ICaluseMasterTypeResponse[]>) => {
        // state.clauseMasterDetailsData = action?.payload;
        state.clauseMasterDetailsData = action?.payload.map((data: any) => {
          data.isActive = data.isActive === true ? ACTIVE : INACTIVE;
          return data;
        });
        state.isclauseMasterDetailsLoading = false;
      })
      .addCase(getClauseMasterTypeList.rejected, (state) => {
        state.isclauseMasterDetailsLoading = false;
      })
      .addCase(createMasterClause.pending, (state) => {
        state.isclauseMasterDetailsLoading = true;
      })
      .addCase(createMasterClause.fulfilled, (state, action: PayloadAction<any>) => {
        state.isclauseMasterDetailsLoading = false;
        state.isclauseMasterDetailsAdded = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message;
        state.ToasterMsg.Status = action?.payload?.toaster?.status;
        state.clauseData = action?.payload;
      })
      .addCase(createMasterClause.rejected, (state, action: PayloadAction<any>) => {
        state.isclauseMasterDetailsLoading = false;
        state.isclauseMasterDetailsAdded = true;
        state.ToasterMsg.Message = action?.payload;
        state.ToasterMsg.Status = "false";
      })
      .addCase(updateMasterClause.pending, (state) => {
        state.isclauseMasterDetailsLoading = true;
      })
      .addCase(updateMasterClause.fulfilled, (state, action: PayloadAction<any>) => {
        state.isclauseMasterDetailsLoading = false;
        state.isclauseMasterDetailsUpdated = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message;
        state.ToasterMsg.Status = action?.payload?.toaster?.status;
      })
      .addCase(updateMasterClause.rejected, (state, action: PayloadAction<any>) => {
        state.isclauseMasterDetailsLoading = false;
        state.isclauseMasterDetailsUpdated = true;
        state.ToasterMsg = action?.payload;
        state.ToasterMsg.Status = "false";
      })
      .addCase(activeInactiveClauseMaster.pending, (state) => {
        state.isclauseMasterDetailsLoading = true;
      })
      .addCase(activeInactiveClauseMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.isclauseMasterDetailsLoading = false;
        state.isclauseMasterDetailsDeleted = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message;
        state.ToasterMsg.Status = action?.payload?.toaster?.status;
      })
      .addCase(activeInactiveClauseMaster.rejected, (state, action: PayloadAction<any>) => {
        state.isclauseMasterDetailsLoading = false;
        state.isclauseMasterDetailsDeleted = true;
        state.ToasterMsg = action?.payload;
        state.ToasterMsg.Status = "false";
      })
      .addCase(getVersionHistory.pending, (state) => {
        state.isclauseMasterDetailsLoading = true;
      })
      .addCase(getVersionHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.ClauseMasterVHeader = action?.payload?.HeaderJson;
        state.versionHistory.ClauseMasterRecords = action?.payload?.History.map(
          ({ IsActive, Approved_On, ...rest }: any) => ({
            ...rest,
            IsActive: IsActive ? "Active" : "Inactive",
            Approved_On: chkDate(Approved_On),
          })
        );
        state.isclauseMasterDetailsLoading = false;
      })
      .addCase(getVersionHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isclauseMasterDetailsLoading = false;
        state.ToasterMsg = action?.payload;
        state.ToasterMsg.Status = "false";
      });
  },
});

export const { updateClauseMasterDetailsAddandEdit, updateVersionHistory, clearData } = clauseMasterDataSlice.actions;
export default clauseMasterDataSlice.reducer;
