import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';

import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { RootState, useAppDispatchThunk } from '../../../store/store';
import { textFieldWidth } from '../../../utils/constants';
import CustomSelect from '../../../common/dynamicInputs/CustomSelect';
import CustomTextField from '../../../common/dynamicInputs/CustomTextField';
import CustomNumberField from '../../../common/dynamicInputs/CustomNumberField';
import CustomToogleSwitch from '../../../common/dynamicInputs/CustomToogleSwitch';
import CustomButton from '../../../common/dynamicButton/CustomButton';
import ProgressBar from '../../../common/progressBar/progressBar';
import { getLovList } from '../../../store/slices/lovMasterData';
import { addEditLocation, getLocationList, getParentLocationList, updateLocationAddandEdit } from '../../../store/slices/locationMasterData';


interface FormData {
    RiskScaleID?: number;
    ModuleName: string;
    From: string;
    To: string;
    Risk: string;
    IsActive: string;

}

export interface RiskScaleProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    isEditData?: any;
}

export const AddRiskScaleMaster: React.FC<RiskScaleProps> = ({
    view, closePopUp, edit, isEditData
}) => {

    const dispatch = useAppDispatchThunk();
    const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
    const [message, setMessage] = useState<boolean>(false);
    const [ParentLocationData, setParentLocationData] = useState<any[]>([]);
    const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);


    const {
        lovTable,
    } = useSelector((state: RootState) => state.lovMasterData);

    useEffect(() => {

        dispatch(
            getLovList({ Name: "Risk Category I" })
        );

    }, []);

    console.log(isEditData, "isEditData");

    let formData: any = {
        RiskScaleID: isEditData ? isEditData?.id : 0,
        ModuleName: isEditData ? isEditData?.Module : "",
        From: isEditData ? isEditData?.From : "",
        To: isEditData ? isEditData?.To : "",
        Risk: isEditData ? isEditData?.Risk : "",
        IsActive: isEditData ? isEditData?.IsActive === "Active" ? true : false : false,
    };

    const {
        handleSubmit: handleRiskScaleForm,
        reset,
        setValue,
        formState: { errors: errorsloginform },
        control,
        watch,
        getValues,
    } = useForm<FormData>({
        defaultValues: formData,
        mode: "onChange",
    });


    const handleSubmit = (data: any) => {
        console.log(getValues())

        let formData = data;
        // setLoader(true);

        formData.MenuId = 15;
        formData.Form_Id = 7;
        formData.ModuleName = "Location";
        formData.Remarks = "";
        formData.CreatedBy = user?.UserName;
        formData.LocationType = formData.LocationType ? `${formData.LocationType}` : "";
        formData.StartPin = formData.LocationType ? formData.LocationType : 0;
        formData.ParentLocationId = formData.ParentLocationId ? formData.ParentLocationId : 0;
        formData.EndPin = formData.EndPin ? formData.EndPin : 0;

        if (edit) {
            formData.UserId = userLoginResponse?.UserId;
            formData.IsActive = formData.IsActive ? true : false;
            formData.Activity = "Edit";
            console.log(formData, " edit formData");


            // dispatch(addEditLocation(formData));
        } else {
            formData.Activity = "Create";
            formData.IsActive = true;
            formData.UserId = userLoginResponse?.UserId;
            console.log(formData, "formData");
            // dispatch(addEditLocation(formData));
        }
    };

    // useEffect(() => {
    //     if (islocationAdded) {
    //         dispatch(
    //             getLocationList({
    //                 FormID: 2,
    //             })
    //         );
    //         dispatch(updateLocationAddandEdit(false));
    //         reset(formData);
    //         if (closePopUp) {
    //             closePopUp();
    //             // setLoader(false);
    //         }
    //     }
    // }, [islocationAdded]);



    return (
        <Grid
            container
            component="main"
            sx={{ height: "auto", width: "100%", overflow: "hidden" }}
        >
            <Grid
                // item
                // item xs={12} sm={12} md={6} lg={6} xl={6}
                sx={{
                    backgroundImage: `url()`,
                    backgroundRepeat: "no-repeat",
                    // backgroundColor: (t) =>
                    //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
                    // backgroundSize: "cover",
                    backgroundPosition: "center",
                    // height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    margin: "0",
                }}
            >

                <Grid
                    sx={{
                        height: "auto", width: "100%", overflow: "hidden", position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                                lg: 2,
                                xl: 2,
                            },

                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
                        <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                            <Grid container spacing={2}>

                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="ModuleName"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Module Name is Required",
                                            validate: (data) => {
                                                return (
                                                    data.length >= 4 || "Minimal 4 characters requires"
                                                );
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomTextField
                                                name={"ModuleName"}
                                                required={true}
                                                disabled={view}
                                                label={"Module Name"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.ModuleName)}
                                                helperText={
                                                    errorsloginform.ModuleName &&
                                                    errorsloginform.ModuleName.message?.toString()
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="From"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Score From Range Field is Required",
                                            validate: (data) => {
                                                const number = parseInt(data, 10);
                                                // Check if it's a valid number and falls within the specified range
                                                if (isNaN(number) || number < 0 || number > 99999999) {
                                                    return "Please enter a number between 0 and 99999999";
                                                }
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomNumberField
                                                name={"From"}
                                                required={true}
                                                disabled={view}
                                                label={"Score From Range"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.From)}
                                                helperText={errorsloginform.From && errorsloginform.From.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Controller
                                        name="To"
                                        control={control}
                                        defaultValue={""}
                                        rules={{
                                            required: "Score To Range Field is Required",
                                            validate: (data) => {
                                                const number = parseInt(data, 10);
                                                // Check if it's a valid number and falls within the specified range
                                                if (isNaN(number) || number < 0 || number > 99999999) {
                                                    return "Please enter a number between 0 and 99999999";
                                                }
                                            },
                                        }}
                                        render={({ field }) => (
                                            <CustomNumberField
                                                name={"To"}
                                                required={true}
                                                disabled={view}
                                                label={"Score To Range"}
                                                value={field.value}
                                                onChange={field.onChange}
                                                error={Boolean(errorsloginform.To)}
                                                helperText={errorsloginform.To && errorsloginform.To.message?.toString()}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid
                                        item
                                        xs={textFieldWidth.xs}
                                        sm={textFieldWidth.sm}
                                        md={textFieldWidth.md}
                                        lg={textFieldWidth.lg}
                                        xl={textFieldWidth.xl}
                                    >
                                        <Controller
                                            name="Risk"
                                            control={control}
                                            defaultValue=""
                                            rules={{ required: "Risk is required" }}
                                            render={({ field }) => (
                                                <CustomSelect
                                                    name={"Risk"}
                                                    valueKey={"LovId"}
                                                    optionLabelKey="Options"
                                                    options={lovTable}
                                                    disabled={view}
                                                    required={true}
                                                    label={"Risk"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    error={Boolean(errorsloginform.Risk)}
                                                    helperText={
                                                        errorsloginform.Risk &&
                                                        errorsloginform.Risk.message?.toString()
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>

                                {edit || view ? (<Grid
                                    item
                                    xs={textFieldWidth.xs}
                                    sm={textFieldWidth.sm}
                                    md={textFieldWidth.md}
                                    lg={textFieldWidth.lg}
                                    xl={textFieldWidth.xl}
                                >
                                    <Box sx={{ mt: 1 }}>
                                        <Controller
                                            name="IsActive"
                                            control={control}
                                            render={({ field }) => (
                                                <CustomToogleSwitch
                                                    label={"User Status"}
                                                    dynamicLabel={field.value ? 'ACTIVE' : 'INACTIVE'}

                                                    disabled={view}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    </Box>

                                </Grid>) : null}

                            </Grid>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                    justifyContent: "right",
                                    mt: 2,
                                }}
                            >
                                {view ? (
                                    <CustomButton
                                        type="reset"
                                        name={"Cancel"}
                                        onClick={() => {
                                            closePopUp && closePopUp();
                                        }}
                                    />
                                ) : edit ? (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton type="reset" name={"Update"} onClick={handleRiskScaleForm(handleSubmit)} />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <CustomButton
                                            type="reset"
                                            variant="outlined"
                                            name={"Cancel"}
                                            onClick={() => {
                                                reset(formData);
                                                closePopUp && closePopUp();
                                            }}
                                        />
                                        <CustomButton type="reset" name={"Submit"} onClick={handleRiskScaleForm(handleSubmit)} />
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
        </Grid>
    );
};
