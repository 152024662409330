import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, Grid, Typography, Stack, FormHelperText } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomProfileUploader from "../../../common/dynamicFileUploader/CustomProfileUploader";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  addProfilePicture,
  deleteProfilePicture,
  updateProfileAddandEdit,
  updateProfilePicture,
} from "../../../store/slices/profilePicture";
import { useDropzone } from "react-dropzone";
import { updateToaster } from "../../../store/slices/loader";

export interface IImageHandler {
  imageData?: any;
  profileUrl?: any;
  onClose: () => void;
}

const ImageHandler: React.FC<IImageHandler> = ({ imageData, profileUrl, onClose }) => {
  const [image, setImage] = useState<any>(imageData == "" ? null : imageData);
  const [isImageRemoved, setIsImageRemoved] = useState<boolean>(false);
  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);
  const [isImageModified, setIsImageModified] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { isProfileAdded, isProfileDeleted, isProfileUpdated, ToasterMsg } = useSelector(
    (state: RootState) => state.profilePicture
  );
  const profilePicture = sessionStorage.getItem("profilePicture");
  const dispatch = useAppDispatch();
  const {
    handleSubmit: handleImageUpload,
    control,
    setValue,
    setError,
    formState: { errors, isDirty },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      profile_Image: imageData == "" ? null : imageData || null,
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const maxFileSize = 1048576; // 1MB
  const acceptFormats = [".png", ".jpeg", ".jpg"];

  useEffect(() => {
    if (imageData) {
      setImage(imageData);
      setValue("profile_Image", imageData == "" ? null : imageData);
    }
  }, [imageData, setValue]);

  const handleImageChange = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > maxFileSize) {
        setErrorMessage(`File size should not exceed ${maxFileSize / 1048576}MB`);
        return;
      }

      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!acceptFormats.includes(`.${fileExtension}`)) {
        setErrorMessage(`Only ${acceptFormats.join(", ")} formats are accepted`);
        return;
      }

      setErrorMessage("");
      handleImageChange(file);
      setValue("profile_Image", file);
    }
  };
  const handleApiCall: any = (data: any) => {
    if (userLoginResponse) {
      const createPayload = {
        UserId: userLoginResponse.UserId,
        UserProfile: data?.profile_Image,
        ModuleName: "Profile Picture",
        Activity: "create",
      };
      const deletePayload = {
        UserId: userLoginResponse.UserId,
        ProfileUrl: profilePicture,
        ModuleName: "Profile Picture",
        Activity: "delete",
      };
      const updatePayload = {
        UserId: userLoginResponse.UserId,
        UserProfile: data?.profile_Image,
        ModuleName: "Profile Picture",
        Activity: "edit",
      };

      if (image && !imageData) {
        dispatch(addProfilePicture(createPayload));
      } else if (image && imageData && image !== imageData) {
        dispatch(updateProfilePicture(updatePayload));
      } else if (profilePicture && isImageRemoved) {
        dispatch(deleteProfilePicture(deletePayload));
      }
    }
  };

  // const handleApiCall: any = (data: any) => {
  //   if (userLoginResponse) {
  //     const createPayload = {
  //       UserId: userLoginResponse.UserId,
  //       UserProfile: data?.profile_Image,
  //       ModuleName: "Profile Picture",
  //       Activity: "create",
  //     };
  //     const deletePayload = {
  //       UserId: userLoginResponse.UserId,
  //       ProfileUrl: profilePicture,
  //       ModuleName: "Profile Picture",
  //       Activity: "delete",
  //     };
  //     const updatePayload = {
  //       UserId: userLoginResponse.UserId,
  //       ProfileUrl: profilePicture,
  //       ModuleName: "Profile Picture",
  //       Activity: "edit",
  //     };
  //     if (image) {
  //       dispatch(addProfilePicture(createPayload));
  //     } else if (profilePicture && !isImageRemoved) {
  //       dispatch(updateProfilePicture(updatePayload));
  //     } else if (profilePicture && isImageRemoved) {
  //       dispatch(deleteProfilePicture(deletePayload));
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (isProfileAdded) {
  //     updateProfileAddandEdit(false);
  //     dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
  //     onClose();
  //   } else if (isProfileUpdated) {
  //     updateProfileAddandEdit(false);
  //     dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
  //   } else if (isProfileDeleted) {
  //     updateProfileAddandEdit(false);
  //     sessionStorage.removeItem("profilePicture");
  //     dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
  //   }
  // }, [isProfileAdded, isProfileDeleted, isProfileUpdated]);
  useEffect(() => {
    if (isProfileAdded || isProfileUpdated || isProfileDeleted) {
      dispatch(updateProfileAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      onClose();
      if (isProfileDeleted) {
        sessionStorage.removeItem("profilePicture");
      }
    }
  }, [isProfileAdded, isProfileDeleted, isProfileUpdated, dispatch, ToasterMsg, onClose]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (validateFile(acceptedFiles)) {
        const file = acceptedFiles[0];
        setImage(URL.createObjectURL(file));
        handleImageChange(file);
        setValue("profile_Image", file);
      }
    },
    [setValue]
  );

  const validateFile = (file: File[]): boolean => {
    const isValidFormat = acceptFormats.some((format) => file[0].name.endsWith(format));
    if (!isValidFormat) {
      setError("profile_Image", { message: `Invalid file format. Accepted formats: ${acceptFormats.join(", ")}` });
      return false;
    }

    if (file[0].size > maxFileSize) {
      setError("profile_Image", {
        message: `File size exceeds the maximum limit of ${maxFileSize / (1024 * 1024)} MB`,
      });
      return false;
    }

    return true;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box>
      <Stack spacing={3}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              {!image && (
                <Box>
                  <Controller
                    name="profile_Image"
                    control={control}
                    // rules={{ required: "Profile Image is required" }}
                    render={({ field }) => (
                      <CustomProfileUploader
                        title={"Profile"}
                        onFileUpload={(file) => {
                          if (file && file.length > 0) {
                            const uploadedFile = file[0];
                            handleImageChange(uploadedFile);
                            field.onChange(uploadedFile);
                          }
                        }}
                        setImage={setImage}
                        maxFileSize={maxFileSize}
                        acceptFormats={acceptFormats}
                      />
                    )}
                  />
                  {errors.profile_Image && (
                    <FormHelperText error>{errors.profile_Image.message?.toString()}</FormHelperText>
                  )}
                </Box>
              )}
              <Box>
                {image && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <img src={image} style={dropzoneStyles} alt={""} onClick={() => fileInputRef.current?.click()} />

                    <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />

                    {errorMessage && (
                      <Typography color="error" variant="body2">
                        {errorMessage}
                      </Typography>
                    )}

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Grid
                        sx={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "end",
                        }}
                      >
                        <CustomButton
                          variant="contained"
                          name={"Upload"}
                          disabled={isImageUploaded || imageData === image}
                          onClick={() => {
                            // handleImageUpload(handleApiCall);
                            setIsImageRemoved(false);
                            setIsImageUploaded(true);
                            setIsImageModified(true);
                          }}
                          size="small"
                        />
                        <CustomButton
                          variant="outlined"
                          disabled={!image}
                          name={"Remove"}
                          onClick={() => {
                            if (imageData === image) {
                              setImage(null);
                            } else if (imageData !== image) {
                              setImage(imageData);
                            }
                            setIsImageRemoved(true);
                            setIsImageUploaded(false);
                            setIsImageModified(true);
                            setValue("profile_Image", profileUrl);
                          }}
                          size="small"
                        />
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "end",
            }}
          >
            <CustomButton
              variant="outlined"
              name={"Cancel"}
              onClick={() => {
                onClose();
                setImage(imageData || null);
              }}
              size="small"
            />
            <CustomButton
              variant="contained"
              name={imageData ? "Update" : "Save"}
              // disabled={!isImageUploaded || !isImageRemoved}
              disabled={!isImageModified}
              onClick={handleImageUpload(handleApiCall)}
              size="small"
            />
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

const dropzoneStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  padding: "10px",
  textAlign: "center",
  marginBottom: "8px",
  height: "200px",
  cursor: "pointer",
  width: "200px",
  objectFit: "cover",
  position: "relative",
};

export default ImageHandler;
