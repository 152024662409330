import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import CustomButton from "../../../dynamicButton/CustomButton";
import CustomSvg from "../../../CustomSvg";
import CustomDialog from "../../../dynamicDialog/CustomDialog";
import AddExistingClause from "../../../../components/contractManagement/ContractTemplate/AddExistingClause";

const VendorOnboardingDeviation: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogBox, setDialogBox] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ maxHeight: "calc(100vh - 200px)", px: 2, py: 0 }}>
      <Stack direction={"column"} spacing={2}>
        <Box sx={{ textAlign: "left" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
            <Typography variant="h6" fontWeight="bold">
              Vendor Onboarding Deviation
            </Typography>
            <IconButton onClick={handleClick}>
              <CustomSvg color={`${theme.palette.text.secondary}`} name={"more-vertical"} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem
                onClick={() => {
                  setDialogBox(true);
                  setAnchorEl(null);
                }}
                sx={{ display: "flex", gap: "10px" }}
              >
                <CustomSvg color={`${theme.palette.text.secondary}`} name={"plus"} />
                Add Existing Clause
              </MenuItem>
              {false && (
                <MenuItem onClick={handleClose} sx={{ display: "flex", gap: "10px" }}>
                  <CustomSvg color={`${theme.palette.text.secondary}`} name={"plus"} /> Existing Clause
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Typography textAlign={"justify"} lineHeight={"22px"} fontWeight={"500"}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo esse blanditiis, debitis, ipsam nisi natus
            quaerat et mollitia veritatis dignissimos vel ea? A quo eum debitis natus velit voluptatem quibusdam. Earum
            eaque ab sed ducimus animi ut voluptate aperiam? Obcaecati eaque error in, porro itaque perferendis,
            voluptatem dicta incidunt, dolor atque ut eum? A unde assumenda minima provident veniam impedit! Itaque
            expedita ducimus laborum, facilis quasi numquam beatae iure sint ipsam fugiat, ratione, sit ea cum nobis a
            dolore et sapiente. Ipsam illo earum ducimus quis voluptatem tempora neque reprehenderit? Corrupti corporis
            repellendus consequatur numquam porro facere voluptatem iste ab neque saepe quibusdam dolores enim dolorem,
            quod cumque magnam aliquid sit aliquam itaque animi qui? Dolor a recusandae numquam iure. Sint, illum ea ab
            reiciendis natus sequi iste fugiat quo minima possimus enim tempore magnam inventore pariatur distinctio
            veritatis nostrum aut repellat in ipsam cupiditate dignissimos aliquid! Porro, culpa explicabo?
          </Typography>
        </Box>
      </Stack>

      <CustomDialog
        show={dialogBox}
        onHide={() => setDialogBox(false)}
        maxWidth={"xl"}
        header={`Add Existing Clause`}
        contentNode={<AddExistingClause onClose={() => setDialogBox(false)} onAdd={() => {}} />}
      />
    </Box>
  );
};

export default VendorOnboardingDeviation;
