import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomToogleSwitch from "../../../common/dynamicInputs/CustomToogleSwitch";
import { ACTIVE, textFieldWidth } from "../../../utils/constants";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { createContractType, updateContractType } from "../../../store/contractManagement/slices/contractMasterSlice";

export interface ContractTypeMaster {
  contractType_Id: any;
  contractType_Name: any;
  isParent: boolean;
  isActive: any;
  updated_By: string;
}

interface AddClauseTypeMasterProps {
  existingData?: any;
  isView?: boolean;
  isEdit?: any;
  onClose: () => void;
}

const AddContractTypeMaster: React.FC<AddClauseTypeMasterProps> = ({ existingData, isEdit, isView, onClose }) => {
  const dispatch = useAppDispatchThunk();

  const initialData: ContractTypeMaster = {
    contractType_Id: 0,
    contractType_Name: "",
    isParent: false,
    isActive: ACTIVE,
    updated_By: "",
  };
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const {
    contractTypeMasterData,
    isContractTypeMasterAdded,
    isContractTypeMasterUpdated,
    isContractTypeMasterDeleted,
    ToasterMsg,
  }: any = contractManagementReducer.contractMasterData;
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);

  const defaultValues = existingData ? existingData : initialData;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<ContractTypeMaster>({
    defaultValues,
  });
  const isContractTypeActive = watch("isActive") === ACTIVE ? true : false;
  const handleSave = async (data: any) => {
    let updatedFormData = {
      contractType_Id: data?.contractType_Id ? data.contractType_Id : 0,
      contractType_Name: data?.contractType_Name,
      updated_By: user?.UserName,
      uppdated_On: new Date().toISOString(),
      actionTaken: "create",
      isActive: data.isActive === ACTIVE ? true : false,
      isParent: data.isParent,
      UserId: userLoginResponse?.UserId,
      moduleName: "Contract Type Master",
      activity: "create",
      createdBy: user?.UserName,
    };

    try {
      dispatch(updateLoader(true));

      if (updatedFormData.contractType_Id === 0) {
        await dispatch(createContractType(updatedFormData));
      } else {
        updatedFormData = { ...updatedFormData, actionTaken: "edit" ,activity: "edit"};
        await dispatch(updateContractType(updatedFormData));
      }
    } catch (error) {
      console.error("Error saving contract type:", error);
      // dispatch(
      //   updateToaster({
      //     isToaster: true,
      //     toasterMsg: `Failed to save contract type`,
      //     isTosterFailed: true,
      //   })
      // );
    } finally {
      dispatch(updateLoader(false));
    }
  };
  useEffect(() => {
    const handleFunc = () => {
      if (isContractTypeMasterAdded || isContractTypeMasterUpdated) {
        onClose();
      }
    };
    handleFunc();
  }, [isContractTypeMasterAdded, isContractTypeMasterUpdated]);

  return (
    <Box>
      <Grid container sx={{ display: "flex", gap: "10px" }}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="contractType_Name"
            control={control}
            rules={{
              required: "Contract Type is Required",
              validate: {
                noLeadingSpaces: (value) => !/^\s/.test(value) || "Leading spaces are not allowed",
                minLength: (value) => value.length >= 1 || "Minimum 1 characters required",
                maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                ValueLimitation: (value) => {
                  const trimmedValue = value.trim();
                  if (!trimmedValue) return true;
                  return /^[a-zA-Z0-9,.\- ]+$/.test(trimmedValue) || "Cannot contain special characters";
                },
              },
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                label={"Contract Type"}
                required
                readOnly={isView || !isContractTypeActive}
                error={Boolean(errors.contractType_Name)}
                helperText={errors.contractType_Name?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="isParent"
            control={control}
            render={({ field }) => (
              <Box sx={{ whiteSpace: "pre", marginRight: "10px", marginTop: "10px" }}>
                <CustomToogleSwitch
                  {...field}
                  label={"Is This a Parent Contract?"}
                  readOnly={isView || !isContractTypeActive}
                  disabled={isView || !isContractTypeActive}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(errors.isParent)}
                  helperText={errors.isParent?.message?.toString()}
                />
              </Box>
            )}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} py={2}>
        <CustomButton
          variant="outlined"
          name={"Cancel"}
          size="small"
          // disabled={isView || !isContractTypeActive}
          onClick={() => {
            onClose();
          }}
        />
        <CustomButton
          variant="contained"
          name={isEdit ? "Update" : "Submit"}
          type="submit"
          size="small"
          disabled={isView || !isContractTypeActive || !isDirty}
          onClick={handleSubmit(handleSave)}
        />
      </Stack>
    </Box>
  );
};

export default AddContractTypeMaster;
