import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CurrencyService from "../services/currencyMasterService";
import { ICurrency, IVHCurrencyColumn } from "../../models/currency.type";
import { chkDate } from "../../utils/constants";
export interface ICurrencyData {
  CurrencyData: any;
  isLoading: boolean;
  isCurrencyAdded: boolean;
  isCurrencyUpdated: boolean;
  versionHistory:{CurrencyRecords :ICurrency[] , CurrencyVHeader : IVHCurrencyColumn[]};
  isCurrencytHistory: boolean;
  isCurrencyDeleted: boolean;
  ToasterMsg: string;
}

export const initialState: ICurrencyData = {
  CurrencyData: [],
  isLoading: false,
  isCurrencyAdded: false,
  isCurrencyUpdated: false,
  versionHistory:{CurrencyRecords :[] , CurrencyVHeader : []},
  isCurrencytHistory: false,
  isCurrencyDeleted: false,
  ToasterMsg: "",
};

export const getCurrencyList = createAsyncThunk(
  "getCurrencyList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await CurrencyService.getCurrencyList(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCurrency = createAsyncThunk("addCurrency", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await CurrencyService.addCurrency(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurrency = createAsyncThunk(
  "updateCurrency",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await CurrencyService.updateCurrency(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCurrency = createAsyncThunk(
  "deleteCurrency",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await CurrencyService.deleteCurrency(reqbody);
      return response.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurrencyHistory = createAsyncThunk("getCurrencyHistory", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await CurrencyService.getVersionHistoryCurrency(reqbody);
    const {CurrencyMaster , HeaderJson } =response.data;
    return {CurrencyHistories: CurrencyMaster , HeaderJson : HeaderJson.ColumnHeaders}
        
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const CurrencyDataSlice = createSlice({
  name: "CurrencyMaster",
  initialState,
  reducers: {
    updateCurrencyAddandEdit: (state, action) => {
      state.isCurrencyAdded = false;
      state.isCurrencyUpdated = false;
      state.isCurrencyDeleted = false;
      state.ToasterMsg = "";
    },
    updateUser: (state, action) => {
      if (action.payload.UserId) {
        let indexVendor = state.CurrencyData.findIndex((data: any) => data.UserId === action.payload.UserId);
        let copyVendorData = [...state.CurrencyData];
        copyVendorData[indexVendor] = action.payload;
        state.CurrencyData = copyVendorData;
      }
    },
    updateVersionHistory:  (state, action)=>{            
      state.versionHistory.CurrencyRecords = [];
      state.versionHistory.CurrencyVHeader = [];
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrencyList.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload, "action.payload");

        state.CurrencyData = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          return user;
        });
        state.isLoading = false;
      })
      .addCase(getCurrencyList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(addCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCurrency.fulfilled, (state, action: PayloadAction<any>) => {
        // state.CurrencyData = [...state.CurrencyData , ...action.payload  ]
        state.isCurrencyAdded = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
      })
      .addCase(addCurrency.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCurrency.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCurrencyUpdated = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
      })
      .addCase(updateCurrency.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCurrency.fulfilled, (state, action: PayloadAction<any>) => {
        state.isCurrencyDeleted = true;
        state.isLoading = false;
        state.ToasterMsg = action.payload.Message;
      })
      .addCase(deleteCurrency.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })      
      .addCase(getCurrencyHistory.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getCurrencyHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.CurrencyVHeader = action.payload.HeaderJson
        state.versionHistory.CurrencyRecords = action.payload.CurrencyHistories.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.ApprovedOn = chkDate(user.ApprovedOn);

          return user;
        });
        state.isLoading = false;
        // state.ToasterMsg = "Currency data deleted successfully";
      })
      .addCase(getCurrencyHistory.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })

  },
});

export const { updateCurrencyAddandEdit, updateUser, updateVersionHistory } = CurrencyDataSlice.actions;
export default CurrencyDataSlice.reducer;
