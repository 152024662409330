import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import accordianStyle from "./customAccordian.module.scss";
import CustomSvg from '../CustomSvg';



interface AccordionProps {
 
    expanded : boolean ;
     handleChange : any ;
    title : string ;
     secondaryText? : string 
     details: any ;
   
}

const CustomAccordion = (props :AccordionProps ) => {

    const {
        expanded,
        handleChange,
        title,
        secondaryText,
        details  
    } = props

    // const classes = useStyles();

    return (
      <Accordion expanded={expanded} onChange={handleChange} className={accordianStyle.accordion}>
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={accordianStyle.summary}
        >
          <span className={accordianStyle.dot}><span className={accordianStyle.innerdot}></span></span>
          <Typography className={accordianStyle.title}>
            {title}
          </Typography>
          <Box sx={{ml:1 }}>
         {expanded ? <CustomSvg size={"20px"} name={"chevron-down"} /> : <CustomSvg size={"20px"} name={"chevron-up"} />}
          </Box>
         
          {secondaryText ? (
            <Typography className={accordianStyle.secondaryText}>
              {secondaryText}
            </Typography>
          ) : null}
        </AccordionSummary>
        <AccordionDetails className={accordianStyle.details}>
          <Box sx={{display :'flex' , width:'100%' , height :'100%'}}>
          <Box sx={{  width:'10px' , borderLeft :'2px dashed #909090' , marginLeft :'8px' , marginRight :'16px'}}></Box>
          {typeof details === 'string' ? (
            <Typography>
              {details}
            </Typography>
          ) : (
            details // Render the component directly
          )}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
}

export default CustomAccordion;
