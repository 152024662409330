import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';

import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { RootState, useAppDispatchThunk } from '../../../store/store';
import { textFieldWidth } from '../../../utils/constants';
import CustomTextField from '../../../common/dynamicInputs/CustomTextField';
import CustomSelect from '../../../common/dynamicInputs/CustomSelect';
import CustomToogleSwitch from '../../../common/dynamicInputs/CustomToogleSwitch';
import CustomButton from '../../../common/dynamicButton/CustomButton';
import { addEditLov, getLovList, getLovNamesList, updateLovAddandEdit } from '../../../store/slices/lovMasterData';
import ProgressBar from '../../../common/progressBar/progressBar';
import { updateLoader } from '../../../store/slices/loader';


interface FormData {
  LovId?: number;
  Name: string;
  Code: string;
  Options: string;
  Description: string;
  IsActive?: any;
}

export interface listProps {
  view?: boolean;
  closePopUp?: () => void;
  edit?: boolean;
  lovID?: any;
  isEditData?: any;
}

export const AddListValues: React.FC<listProps> = ({
  view, closePopUp, edit, lovID, isEditData
}) => {

  const dispatch = useAppDispatchThunk();
  const { formConfiguration } = useSelector((state: RootState) => state.customFormFields);
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const [Namedisabled, setNamedisabled] = useState<boolean>(false);
  const {
    lovTable,
    islovAdded,
    LovNames,
    islovDeleted,
    ToasterMsg,
    isLoading,
  } = useSelector((state: RootState) => state.lovMasterData);

  let location_Data = [
    { id: "", lable: "Select your Location" },
    { id: "Vendor", lable: "Vendor Type" },
    { id: "Rolls", lable: "Rolls" },
    { id: "Blood", lable: "Blood Group" },
  ];
  // extracting dynamic fields for edit - prepopulate
  console.log(isEditData, lovID, edit, view,"isEditData");


  let formData: any = {
    LovId: edit ? isEditData?.LovId : 0,
    // Name: isEditData ? isEditData?.Name : lovID,
    Name: isEditData ?  isEditData?.Name : "",
    Code: isEditData ? isEditData?.Code : "",
    Options: isEditData ? isEditData?.Options : "",
    Description: isEditData ? isEditData?.Description : "",
    IsActive: isEditData ? isEditData?.IsActive === "Active" ? true : false : true,

  };

  const {
    handleSubmit: handleListForm,
    reset,
    setValue,
    formState: { errors: errorsloginform, isDirty },
    control,
    watch,
    getValues,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(
      getLovNamesList({
        FormID: 2,
      })
    );
  }, []);

  const isActiveDisabled = watch("IsActive");

  useEffect(() => {

   if(lovID === "All" && !edit ){
    setValue("Name", "");
    setNamedisabled(true)
   } 

   if(lovID !== "All" ){
    setValue("Name", lovID);
    setNamedisabled(false)
   }

  }, [lovID , edit]);

  const handleSubmit = (data: any) => {
    console.log(data);
    dispatch(updateLoader(true));

    let formData = data;
    // setLoader(true);
    formData.Status = formData.Status ? "true" : "false";
    formData.IsActive = formData.IsActive ? true : false;

    // formData.Description = formData.Options;
    formData.MenuId = 15;
    formData.Form_Id = 9;
    formData.ModuleName = "List Of Values";

    let JsonDataForDisplay : any = {
      "Name" : formData.Name,
      "Options" : formData.Options,
      "Description" : formData.Description,
      "Is Active" : formData.IsActive,
      "Modified By" : user?.UserName,
    }

    if (edit) {
      formData.CreatedBy = user?.UserName;
      formData.UserId = userLoginResponse?.UserId;
      formData.Activity = "Edit";
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay) ;

      dispatch(addEditLov(formData));
    } else {
      formData.CreatedBy = user?.UserName;
      formData.IsActive = true;
      formData.Status = true;
      formData.Activity = "Create";
      formData.UserId = userLoginResponse?.UserId;
      formData.DataForDisplay = JSON.stringify(JsonDataForDisplay) ;

      dispatch(addEditLov(formData));
    }
  };


  useEffect(() => {
    if (islovAdded) {
      dispatch(
        getLovList({ Name: lovID })
      );
      dispatch(updateLovAddandEdit(false));
      dispatch(updateLoader(false));
      if (closePopUp) {
        closePopUp();
        // setLoader(false);
      }
      reset(formData);
    }
  }, [islovAdded]);


  return (
    <Grid
      container
      component="main"
      sx={{ height: "auto", width: "100%", overflow: "hidden" }}
    >
      <Grid
        // item
        // item xs={12} sm={12} md={6} lg={6} xl={6}
        sx={{
          backgroundImage: `url()`,
          backgroundRepeat: "no-repeat",
          // backgroundColor: (t) =>
          //   t.palette.mode === "light" ? "#F5F5F5" : "#F5F5F5",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          margin: "0",
        }}
      >
        {false && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              margin: "0",
            }}
          >
            {!view ? (
              <>
                {!edit ? (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Add List Values
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ m: 1 }}>
                    Edit Add List Values
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="h6" sx={{ m: 1 }}>
                View Add List Values
              </Typography>
            )}
          </Box>
        )}
        <Grid
          sx={{
            height: "auto", width: "100%", overflow: "hidden", position: "relative"
          }}
        >
          <Box
            sx={{
              px: {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 2,
              },

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isLoading ? <ProgressBar showProgress={isLoading} /> : null} */}
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Name is Required",

                    }}
                    render={({ field }) => (
                      // <CustomTextField
                      //   name={"Name"}
                      //   disabled={true || view}
                      //   label={"Name"}
                      //   value={field.value}
                      //   onChange={field.onChange}
                      //   error={Boolean(errorsloginform.Name)}
                      //   helperText={
                      //     errorsloginform.Name &&
                      //     errorsloginform.Name.message?.toString()
                      //   }
                      // />
                      <CustomSelect
                        name={"Name"}
                        valueKey={"Name"}
                        optionLabelKey="Name"
                        options={LovNames}
                        readOnly = {Namedisabled ? false : true}
                        required={true}
                        // addField={true}
                        label={"Name"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Name)}
                        helperText={
                          errorsloginform.Name &&
                          errorsloginform.Name.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Options"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "List Option is Required",
                      validate: {
                        // noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                        minLength: (value) => (value ?? "").length >= 2 || "Minimum 2 characters required",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                        ValueLimitation: (value) => /^[a-zA-Z0-9,.\-&() ]+$/.test(value.trim()) || "Options cannot contain special characters except for , . - and spaces",     
                      }
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Options"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"Option"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Options)}
                        helperText={
                          errorsloginform.Options &&
                          errorsloginform.Options.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Description"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "List Description is Required",
                      validate: {
                        // noLeadingSpaces: (value) => !/^\s/.test(value ?? "") || "Leading spaces are not allowed",
                        minLength: (value) => (value ?? "").length >= 2 || "Minimum 2 characters required",
                        maxLength: (value) => value.length < 100 || "Maximum 100 characters allowed",
                        // ValueLimitaion: (value) => /^[a-zA-Z0-9,.-]+(?:\s[a-zA-Z0-9-,.]+)*$/.test(value) || "Name cannot contain special characters and empty value",
                        ValueLimitation: (value) => /^[a-zA-Z0-9,.\-&() ]+$/.test(value.trim()) || "Description cannot contain special characters except for , . - and spaces",  
                      }
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"Description"}
                        required={true}
                        readOnly={view || !isActiveDisabled}
                        label={"Description"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorsloginform.Description)}
                        helperText={
                          errorsloginform.Description &&
                          errorsloginform.Description.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                {/* {edit || view ? (<Grid
                  item
                  xs={12}
                // sm={textFieldWidth.sm}
                // md={textFieldWidth.md}
                // lg={textFieldWidth.lg}
                // xl={textFieldWidth.xl}
                >
                  <Box sx={{ mt: 1 }}>
                    <Controller
                      name="IsActive"
                      control={control}
                      render={({ field }) => (
                        <CustomToogleSwitch
                          label={"User Status"}
                          dynamicLabel={field.value ? 'Active' : 'Inactive'}

                          disabled={view}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Box>

                </Grid>) : null} */}

              </Grid>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "end",
                  mt: 2,
                }}
              >
                {view ? (
                  <CustomButton
                    type="reset"
                    name={"Cancel"}
                    onClick={() => {
                      closePopUp && closePopUp();
                    }}
                  />
                ) : edit ? (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                       
                        closePopUp && closePopUp();
                        reset(formData);
                      }}
                    />
                    <CustomButton type="reset" disabled={!isDirty} name={"Update"} onClick={handleListForm(handleSubmit)} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                       
                        closePopUp && closePopUp();
                        reset(formData);
                      }}
                    />
                    <CustomButton type="reset" disabled={!isDirty} name={"Submit"} onClick={handleListForm(handleSubmit)} />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <ToastContainer 
      position='top-right'
      autoClose={3000}
      /> */}
    </Grid >
  );
};
