import { Box, Stack, Typography } from "@mui/material";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddVendorDetails } from "./AddVendor/index";
import { getVendorDetailsHistory, getVendorDetailsList, updateVendorDetailsAddandEdit, updateVersionHistory } from "../../store/slices/vendorDetails";
import { updateLoader, updateToaster } from "../../store/slices/loader";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { getAllSaveandDraftList, updateSaveasDraftAddandEdit } from "../../store/slices/saveasdraft";
import vendorDetailsService from "../../store/services/vendorDetails";
import { clearSignzyData } from "../../store/slices/signzySlice";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { clearProbeData } from "../../store/slices/ProbeData";
import masterEditEnableService from "../../store/services/masterEditEnableService";
import { Edit_Enable_tosterMSG } from "../../config/config";
//import { SignzyData } from "./AddVendor/VendorDetails/mockData";

const columns = {
  table_name: "Third Party Master",
  isAction: true,
  columns: [
    // {
    //   Field: "Vendor_Id",
    //   FieldType: "string",
    //   DisplayName: "Vendor Id  (testing purpose only)",
    // },
    {
      Field: "Legal_Name",
      FieldType: "string",
      DisplayName: "Third Party Name",
    },

    {
      Field: "Group_Name",
      FieldType: "string",
      DisplayName: "Third Party Group Name",
    },

    {
      Field: "Contact_Number",
      FieldType: "string",
      DisplayName: "Registered Contact No",
    },
    {
      Field: "EmailId",
      FieldType: "string",
      DisplayName: "Registered Email Id",
    },
    {
      Field: "isWorkflowInitiated",
      FieldType: "string",
      DisplayName: "Is Work flow Initiated",

    },
    {
      Field: "PAN",
      FieldType: "string",
      DisplayName: "PAN",
    },
    {
      Field: "TAN",
      FieldType: "string",
      DisplayName: "TAN",
    },
    {
      Field: "CIN",
      FieldType: "string",
      DisplayName: "CIN",
    },
    {
      Field: "Jurisdiction_Value",
      FieldType: "string",
      DisplayName: "Applicable Jurisdiction",
    },
    {
      Field: "Constitution_Value",
      FieldType: "string",
      DisplayName: "Constitution",
    },
    {
      Field: "IsListedValue",
      FieldType: "string",
      DisplayName: "Is Listed",
    },
    {
      Field: "IsRegulatedValue",
      FieldType: "string",
      DisplayName: "Is Regulated",
    },
    {
      Field: "Status_Value",
      DisplayName: "Status",
      FieldType: "string",
    },
    {
      Field: "Updated_By",
      FieldType: "string",
      DisplayName: "Modified By",
    }
  ],
};

const VendorDetailsMaster = () => {
  const dispatch = useAppDispatch();
  const {
    vendorDetailsdata,
    isVendorDetailsAdded,
    isVendorDetailsUpdated,
    isVendorDetailsDeleted,
    vendorDetailsToasterMsg,
    vendorDetailsToasterStatus,
    vendorDetailsToasterOncloseStatus,
    versionHistory,
  } = useSelector((state: RootState) => state.vendorDetails);
  const { SignzyData, isLoading } = useSelector((state: RootState) => state.signzySlice);
  const { draftdata, isLoadingDraft, isDraftAdded, isDraftUpdated, DraftToasterMsg } = useSelector(
    (state: RootState) => state.saveAsDraft
  );
  const { menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const [HeaderColumn] = useState(columns);
  const [vendorDetails, setVendorDetails] = useState<any[]>([]);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [Option, setOption] = useState("");
  const [isAddorEdit, setIsAddorEdit] = useState(false);
  const [isEditData, setIsEditDate] = useState<any>(null);
  const [isShowDraft, setisShowDraft] = useState<any>(1);
  const [vendorDetailsDraftData, setVendorDetailsDraftData] = useState<any[]>([]);
  const [isDraftData, setIsDraftData] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();

  const columns_version: any = {
    table_name: "Third Party History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    initializeData();
  }, []);
  useEffect(() => {
    dispatch(clearSignzyData());
  }, []);

  const getDraftData = () => {
    const dataObj = {
      UserId: userLoginResponse?.UserId,
      ModuleName: "Vendor Master",
      ScreenName: "Vendor Master",
    };
    dispatch(getAllSaveandDraftList(dataObj));
  };

  const initializeData = () => {
    dispatch(getVendorDetailsList());
    getDraftData();
  };

  useEffect(() => {
    if (isVendorDetailsAdded === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg, isTosterFailed: vendorDetailsToasterOncloseStatus == "false" ? true : false }));
      dispatch(updateVendorDetailsAddandEdit(false));
      dispatch(clearProbeData())
      dispatch(updateLoader(false));
      if (vendorDetailsToasterStatus != false) {
        initializeData();
        setisShowDraft(1)
      }
    }
    else if (isVendorDetailsUpdated === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg, isTosterFailed: vendorDetailsToasterOncloseStatus == "false" ? true : false }));
      dispatch(updateVendorDetailsAddandEdit(false));
      dispatch(clearProbeData())
      initializeData();
      setisShowDraft(1)
      dispatch(updateLoader(false));

    }
    else if (isVendorDetailsDeleted === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorDetailsToasterMsg }));
      dispatch(updateVendorDetailsAddandEdit(false));
      initializeData();
      setisShowDraft(1)
    }
  }, [isVendorDetailsAdded, vendorDetailsToasterStatus, isVendorDetailsUpdated, isVendorDetailsDeleted]);

  useEffect(() => {
    if (isDraftAdded) {
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
      initializeData();
      setisShowDraft(0)
    } else if (isDraftUpdated) {
      dispatch(updateSaveasDraftAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: DraftToasterMsg }));
      initializeData();
      setisShowDraft(0)
    }
  }, [isDraftAdded, isDraftUpdated]);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory.VendorDetailsVHeader.length > 0 && versionHistory.VendorDetailsVHeader.length > 0) {
      if (versionHistory.VendorDetailsVHeader && versionHistory.VendorDetailsVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.VendorDetailsVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(versionHistory, "versionHistory ");

      }
    }
  }, [versionHistory]);

  useEffect(() => {
    setVendorDetails(vendorDetailsdata);
  }, [vendorDetailsdata]);

  useEffect(() => {
    let modifiedData: any[] = [];
    if (draftdata && Array.isArray(draftdata)) {
      draftdata &&
        draftdata.forEach((item: any) => {
          let JsonData = JSON.parse(item.DraftData);
          modifiedData.push({
            ...JsonData.VendorDetails,
            IsListedValue: JsonData.VendorDetails?.IsListed ? "Yes" : "No",
            IsRegulatedValue: JsonData.VendorDetails?.IsRegulated ? "Yes" : "No",
            DraftStatus: item.DraftStatus,
            DraftId: item.DraftId,
          });
        });
    }
    setVendorDetailsDraftData(modifiedData);
  }, [draftdata]);

  const handleOpen = async (data: any, option: string) => {
    if (option === "history") {
      setOption(option);
      dispatch(
        getVendorDetailsHistory({
          Vendor_Id: data.Vendor_Id,
          formID: 22,
        })
      );
      setIsAddorEdit(false);
      // setIsVersionHistory(true);
    }
    else if (option === "edit" && isShowDraft) {
      console.log(data, "data");

      const response: any = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Vendor_Id, FormID: 22 });
      const Edit_data: any = response;
      console.log(Edit_data?.data, "Edit_data");

      if (Edit_data?.data === true ) {
        if (isShowDraft) {
          const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
          setIsAddorEdit(true);
          setOption(option);
          setIsEditDate(res.data);
          setIsDraftData(false);
        }
      } else {

        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }
    }
    else {
      if (isShowDraft) {
        const res = await vendorDetailsService.getVendorDetailsById({ Vendor_Id: data.Vendor_Id });
        setIsAddorEdit(true);
        setOption(option);
        setIsEditDate(res.data);
        setIsDraftData(false);
      }
      else {
        let filteredData = draftdata.find((item: any) => item.DraftId === data.DraftId);
        let JsonData = JSON.parse(filteredData?.DraftData);
        setIsAddorEdit(true);
        setOption(option);
        setIsEditDate({ ...JsonData, DraftStatus: filteredData.DraftStatus, DraftId: filteredData.DraftId });
        setIsDraftData(true);
      }
    }
  };

  const setAddFormcloseoropen = (value: any) => {
    setIsAddorEdit(value);
    setIsEditDate(null);
    // setIsDraftDate(null)
    setOption("");
    setIsDraftData(false);
  };

  return (
    <>
      {!isAddorEdit ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Typography variant="h6" color={"text.primary"}>
                Third Party Master
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={HeaderColumn}
                rows={isShowDraft ? vendorDetails : vendorDetailsDraftData}
                handleAction={handleOpen}
                handleAdd={() => setAddFormcloseoropen(true)}
                enableZoomIcon={true}
                showhistory={true}
                isDraftView={true}
                draftCount={vendorDetailsDraftData.length}
                isEnableDraft={isShowDraft}
                handleIsDraft={(val: any) => setisShowDraft(val)}
                showAdd={permssionPage?.IsAdd}
                showDelete={false}
                showView={permssionPage?.IsView}
                showEdit={permssionPage?.IsEdit}
                showExports={permssionPage?.IsExport}
                userId={userLoginResponse?.UserId || 0}
                pageName="Third Party Master"
              />
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <AddVendorDetails
            view={Option == "view" ? true : false}
            edit={Option == "edit" ? true : false}
            isEditData={isEditData}
            SignzyData={SignzyData}
            onClose={() => {
              setAddFormcloseoropen(false);
              dispatch(clearProbeData())
            }}
            isDraftData={isDraftData}
          />
        </Box>
      )}

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Third Party Master Version History"}
        contentNode={
          <> <MaterialDynamicGrid
            data={versionHistoryData}
            rows={versionHistory.VendorDetailsRecords}
            handleAction={handleOpen}
            enableZoomIcon={false}
            showhistory={false}
            showDelete={permssionPage?.IsDelete === 1}
            showView={permssionPage?.IsView === 1}
            showEdit={permssionPage?.IsEdit === 1}
            showExports={permssionPage?.IsExport === 1}
            userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
            pageName="Third Party Master Version History"
          />
          </>
        }
      />
    </>
  );
};

export default VendorDetailsMaster;
