import { AxiosError, AxiosResponse } from "axios";
import { IDefaultResponse } from "../../models/defaultValues.type";
import { axiosInstance } from "./config/axiosConfig";
import { DEFAULT_VALUES_URL } from "./endpoints/defaultValues";

const getDefaultValues = (reqparams: any): Promise<IDefaultResponse> =>
  axiosInstance
    .get(DEFAULT_VALUES_URL.DefaultUserValues, {
      params: reqparams,
    })
    .then((response: AxiosResponse<IDefaultResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addDefault = (requestBody: any): Promise<IDefaultResponse> =>
  axiosInstance
    .post(DEFAULT_VALUES_URL.AddDefaultValues, requestBody)
    .then((response: AxiosResponse<IDefaultResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateDefault = (requestBody: any): Promise<IDefaultResponse> =>
  axiosInstance
    .post(DEFAULT_VALUES_URL.EditDefaultValus, requestBody)
    .then((response: AxiosResponse<IDefaultResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const defaultValueService = {
  getDefaultValues,
  addDefault,
  updateDefault,
};

export default defaultValueService;
