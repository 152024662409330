import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { MASTEREDITENABLE_URL } from "./endpoints/masterEditEnable";
import { IMasterEditEnableResponse } from "../../models/masterEditEnable.type";

const GetMasterEditEnable = (requesrParams: { ItemID: number, FormID: number  }): Promise<IMasterEditEnableResponse[]> =>

  axiosInstance
    .get(MASTEREDITENABLE_URL.GetEditEnable, {params: requesrParams})
    .then((response: AxiosResponse<any>) => {
      console.log(requesrParams, "requestBody");
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError) => {
      throw error;
    });

const masterEditEnableService = {
  
  GetMasterEditEnable,

};

export default masterEditEnableService;
