export const Roles_URL={
    AddRole: `/SaveRoleAndMenus`,    
    UpdateRole:`/UpdateRolesMenusAccessrights`,
    DeleteRole:`/deleteRole`,
    GetRole:`/getRole`,
    GetRoles:`/getAllRole`,
    GetMasterRole: `/getRoleMaster`,
    GetALLMenus: `/GetAllMenus`,
    GetRolesById: `/getrolesById`,
    GetRolesByMenu: `/GetRolesByMenu`,
    VersionHistory: `/getRoleHistoryById`
}