import { Box, Dialog, DialogContent, Stack, Typography, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppDispatchThunk } from "../../store/store";
import { deleteRole, getALLMenus, getRoleHistory, getRolesById, getRolesList, updateRoleAddandEdit, updateVersionHistory } from "../../store/slices/roleData";
import Close from "@mui/icons-material/Close";
import CustomButton from "../../common/dynamicButton/CustomButton";
import ModalPopUp from "../../common/modalComponent/ModalPopUp";
import { IRole, IRoleFormData } from "../../models/roles.type";
import AddRole from "./AddRole";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { IMenu } from "../../models/loggin.type";
import { updateToaster } from "../../store/slices/loader";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";

const RolesManagement = () => {
  const columns: any = {
    table_name: "Role",
    isAction: true,
    columns: [],
  };
  const [isEdit, setIsEdit] = useState(false);
  const [addForm, setAddForm] = useState(false);
  //const [formDisplay, setformDisplay] = useState(false);
  const [Option, setOption] = useState("");
  const [isEditRoleData, setisEditRoleData] = useState<IRole | null>(null);
  const dispatch = useAppDispatchThunk();
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [editId, setEditId] = useState<any>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  //const [errorMsg, setErrorMsg] = useState(false);

  const { allRoles, isRoleAdded, isRoleUpdated, isRoleDeleted, ToasterMsg, errormessage, roleVersionHistory } = useSelector(
    (state: RootState) => state.roleData
  );

  const { user, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);

  const [rolesData, setRolesData] = useState<IRole[]>([]);

  const [roleAdded, setRoleAdded] = useState(false);
  const [roleUpdated, setRoleUpdated] = useState(false);
  const [roleDeleted, setRoleDeleted] = useState(false);
  const [roleId, setRoleId] = useState<number>();
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [roleDeletedFail, setRoleDeletedFail] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();


  const columns_version: any = {
    table_name: "Role History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    if (errormessage !== "") {
      setRoleDeletedFail(true);
      setDeletePopUp(false);
    }
  }, [errormessage]);
  useEffect(() => {
    dispatch(
      getRolesList({
        formId: 3,
      })
    );
    dispatch(getALLMenus(null));
  }, []);

  useEffect(() => {
    if (allRoles.roles.length > 0) {
      setRolesData(allRoles.roles);
    }

    if (allRoles.headers && allRoles.headers.length > 0) {
      let re_columns = {
        ...columns,
        columns: allRoles.headers,
      };
      setHeaderColumn(re_columns);
    }
  }, [allRoles]);

  useEffect(() => {
    if (isRoleAdded) {
      //setRoleAdded(true);
      dispatch(updateRoleAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      dispatch(
        getRolesList({
          formId: 3,
        })
      );
    } else if (isRoleUpdated) {
      //setRoleUpdated(true);
      dispatch(updateRoleAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));

      dispatch(
        getRolesList({
          formId: 3,
        })
      );
    } else if (isRoleDeleted) {
      // setRoleDeleted(true);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));
      setDeletePopUp(false);
      dispatch(updateRoleAddandEdit(false));
      dispatch(
        getRolesList({
          formId: 3,
        })
      );
    }
  }, [isRoleAdded, isRoleUpdated, isRoleDeleted]);

  const deleteRoleId = () => {
    let JsonDataForDisplay: any = {
      "Role Name": isDeleteData.RoleName,      
      "Status": isDeleteData.IsActive  === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    }

    dispatch(
      deleteRole({
        RoleId: isDeleteData.RoleId,
        RoleName: isDeleteData.RoleName,
        CreatedBy: userLoginResponse?.UserId,
        ModuleName: "Role Master",
        Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
        Is_Active: isDeleteData.IsActive === "Active" ? false : true,
        UserId: userLoginResponse?.UserId,
        MenuId: currentPagePermissionData?.MenuId,
        Form_Id: 3,
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
      })
    );
  };
  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permssionPage?.MenuId,
      ModuleName: "Department Master",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Department Master",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        setIsEditing(true);
        console.log(recordLock, "recordLock");
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      return [
        {
          RecordlockId: recordLock[0].RecordlockId,
          RecordId: recordLock[0].RecordId,
          IsActive: false,
        },
      ];
    }
    return [];
  };
  useEffect(() => {
    if (!isEdit) {
      const data = recordLockUpdate();
      if (data.length > 0) {
        dispatch(UpdateRecordLock(data.pop()));
      }
    }
  }, [isEdit]);
  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(data, "dataLock");
      if (data.length > 0) {
        event.preventDefault();
        event.returnValue = "";
        try {
          await dispatch(UpdateRecordLock(data.pop())).unwrap();
        } catch (error) {
          console.error("Failed to update record lock:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (roleVersionHistory.roleVHeader.length > 0 && roleVersionHistory.roleVHeader.length > 0) {
      if (roleVersionHistory.roleVHeader && roleVersionHistory.roleVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: roleVersionHistory.roleVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(roleVersionHistory, "versionHistory ");
      }
    }
  }, [roleVersionHistory]);

  const handleOpen = async (data: IRole, option: string) => {
    if (option === "delete") {
      console.log(data, "data");

      setRoleId(data.RoleId);
      setIsDeleteData(data);
      setDeletePopUp(true);
    } else if (option === "edit") {
           console.log(data , 'datassss');
           
      setisEditRoleData(data);
      setOption(option);
      // setIsEdit(true);

      const canEdit = await handleRecordLockCheck(data?.RoleId);
      if (canEdit) {
        const dispatchData = handleAddRecordLock(data?.RoleId);
        await dispatch(AddRecordLock(dispatchData));
        //setIsEditOpen(true);
        const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.RoleId)));
        if (GetRecordLock.fulfilled.match(resultAction)) {
          const recordLockData = resultAction.payload;
          console.log(recordLockData, resultAction, "recordLockData");
          setGetRecordLock(recordLockData);
        }
        setIsEdit(true);
        // Set timeout to close the popup after 2.5 minutes
        setTimeout(() => {
          setIsEdit(false);
          const data = recordLockUpdate();
          if (data.length > 0) {
            dispatch(UpdateRecordLock(data.pop()));
          }
        }, 150000);
      }

      console.log("sadsadasdasda", data);
    } else if (option === "history") {
      // setIsVersionHistory(true);
      // setIsEdit(true);
      setOption(option);
      dispatch(
        getRoleHistory({
          roleId: data.RoleId,
          formId: 6,
          // Approved_By: ,
        })
      );

    } else if (option === "view") {
      setisEditRoleData(data);
      setOption(option);
      setIsEdit(true);
    }
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              Role Management
            </Typography>
          </Box>

          {/* {permssionPage?.IsAdd ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton variant="contained" name={"Add Role"} onClick={() => setAddForm(true)} />
              </Grid>
            </Box>
          ) : null} */}

          <Box>
            <>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {/* <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        sx={{ marginBottom: 1, padding: "10px 20px" }}
                        variant="contained"
                        onClick={() => setAddForm(true)}
                      >
                        ADD Vendor
                      </Button>
                    </Grid> */}
                {/* <DevExpDynamicGrid
                  data={columns}
                  rows={usersData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  uniqueIdKey="RoleId"
                  hideVersionhistory={true}
                /> */}
                <MaterialDynamicGrid
                  data={HeaderColumn}
                  rows={rolesData}
                  handleAction={handleOpen}
                  enableZoomIcon={true}
                  handleAdd={() => setAddForm(true)}
                  showAdd={true}
                  showDelete={permssionPage?.IsDelete === 1}
                  showhistory={true}
                  showView={permssionPage?.IsView === 1 ? true : false}
                  showEdit={permssionPage?.IsEdit === 1 ? true : false}
                  showExports={permssionPage?.IsExport === 1 ? true : false}
                  pageName="Role"
                  userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
                />
              </Box>
            </>
          </Box>
        </Stack>
      </Box>
      {/* <Dialog
        open={addForm}
        onClose={() => setAddForm(false)}
        maxWidth={"xl"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>          
          <Box
            sx={{
              width: "80vw",
            }}
          >
            <AddRole
              view={false}
              closePopUp={() => {
                setAddForm(false);
              }}
            />
          </Box>
        </DialogContent>
      </Dialog> */}

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={"Add Role"}
        contentNode={
          <AddRole
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      {/* <Dialog
        open={isEdit}
        onClose={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"xl"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>          
          <Box
            sx={{
              width: "80vw",
            }}
          >
            {Option === "edit" && (
              <AddRole
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                roleData={
                  isEditRoleData &&
                  isEditRoleData !== undefined &&
                  isEditRoleData !== null
                    ? isEditRoleData
                    : null
                }
              />
            )}
            {Option === "view" && (
              <AddRole
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                roleData={
                  isEditRoleData &&
                  isEditRoleData !== undefined &&
                  isEditRoleData !== null
                    ? isEditRoleData
                    : null
                }
              />
            )}
          </Box>
        </DialogContent>
      </Dialog> */}

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Role Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={roleVersionHistory.roleRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Role History"
            />
          </>
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"lg"}
        minHeight={"50vh"}
        header={Option === "view" ? "View Role" : "Update Role"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddRole
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                roleData={
                  isEditRoleData && isEditRoleData !== undefined && isEditRoleData !== null ? isEditRoleData : null
                }
              />
            )}
            {Option === "view" && (
              <AddRole
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                roleData={
                  isEditRoleData && isEditRoleData !== undefined && isEditRoleData !== null ? isEditRoleData : null
                }
              />
            )}
          </>
        }
      />
      <React.Fragment>
        <ModalPopUp
          header="Role Added"
          text="Role Created SuccessFully"
          onHide={() => {
            setRoleAdded(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleAdded}
        />

        <ModalPopUp
          text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
            } the selected Role?`}
          ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
          header="Confirm"
          isDelete={deletePopUp}
          onHide={() => {
            setDeletePopUp(false);
          }}
          onDeleteMethod={() => {
            deleteRoleId();
          }}
          show={deletePopUp}
        />

        <ModalPopUp
          header="Role Updated"
          text="Role Updated SuccessFully"
          onHide={() => {
            setRoleUpdated(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleUpdated}
        />

        <ModalPopUp
          header="Role Deleted"
          text="Role Deleted SuccessFully"
          onHide={() => {
            setRoleDeleted(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleDeleted}
        />
        <ModalPopUp
          header="info"
          text={errormessage}
          onHide={() => {
            setRoleDeletedFail(false);
            dispatch(updateRoleAddandEdit(false));
          }}
          show={roleDeletedFail}
        />
      </React.Fragment>
    </>
  );
};

export default RolesManagement;
