import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RiskRateService from "../services/riskRateService";
import { IRiskRate, IVHColumnRes } from "../../models/riskRating.type";
import { chkDate } from "../../utils/constants";
export interface IRiskRateData {
    RiskRateData: any;
    RiskRateDataActive: any;
    isLoading: boolean;
    isRiskRateAdded: boolean;
    versionHistory: { RiskRateRecords: IRiskRate[], RiskRateVHeader: IVHColumnRes[] };
    isRiskRatetHistory: boolean;
    isRiskRateDeleted: boolean;
    ToasterMsg: any;
}

export const initialState: IRiskRateData = {
    RiskRateData: [],
    RiskRateDataActive: [],
    isLoading: false,
    isRiskRateAdded: false,
    versionHistory: { RiskRateRecords: [], RiskRateVHeader: [] },
    isRiskRatetHistory: false,
    isRiskRateDeleted: false,
    ToasterMsg: { Message: "", Status: "" },
};

export const getRiskRateList = createAsyncThunk(
    "getRiskRateList",
    async (requesrParams: { FormID: number }, { rejectWithValue }) => {
        try {
            const response = await RiskRateService.getRiskRateList(requesrParams);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addEditRiskRate = createAsyncThunk("addEditRiskRate", async (requesrParams: any, { rejectWithValue }) => {
    try {
        const response: any = await RiskRateService.addEditRiskRate(requesrParams);
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteRiskRate = createAsyncThunk(
    "deleteRiskRate",
    async (requesrParams: any, { rejectWithValue }) => {
        try {
            let reqbody = { ...requesrParams };
            const response: any = await RiskRateService.deleteRiskRate(reqbody);
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getRiskRateHistory = createAsyncThunk("getRiskRateHistory", async (requesrParams: any, { rejectWithValue }) => {
    try {
        let reqbody = { ...requesrParams };
        const response: any = await RiskRateService.getVersionHistoryRiskRate(reqbody);
        const { RiskCategoryHistories, HeaderJson } = response.data;
        return { RiskCategoryHistories: RiskCategoryHistories, HeaderJson: HeaderJson.ColumnHeaders }

    } catch (error) {
        return rejectWithValue(error);
    }
});

export const RiskRateDataSlice = createSlice({
    name: "RiskRateMaster",
    initialState,
    reducers: {
        updateRiskRateAddandEdit: (state, action) => {
            state.isRiskRateAdded = false;
            state.isRiskRateDeleted = false;
            state.ToasterMsg = { Message: "", Status: "" };
        },
        updateUser: (state, action) => {
            if (action.payload.UserId) {
                let indexVendor = state.RiskRateData.findIndex((data: any) => data.UserId === action.payload.UserId);
                let copyVendorData = [...state.RiskRateData];
                copyVendorData[indexVendor] = action.payload;
                state.RiskRateData = copyVendorData;
            }
        },
        updateVersionHistory: (state, action) => {
            state.versionHistory.RiskRateRecords = [];
            state.versionHistory.RiskRateVHeader = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRiskRateList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRiskRateList.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");
                state.RiskRateDataActive = action.payload.filter((risk: any) => {
                    return risk.IsActive === true;
                 });
                state.RiskRateData = action.payload.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    return user;
                });
              
                state.isLoading = false;
            })
            .addCase(getRiskRateList.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(addEditRiskRate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEditRiskRate.fulfilled, (state, action: PayloadAction<any>) => {
                // state.CurrencyData = [...state.CurrencyData , ...action.payload  ]
                state.isRiskRateAdded = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action?.payload?.Message;
                state.ToasterMsg.Status = action?.payload?.IsSuccess;
            })
            .addCase(addEditRiskRate.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

            .addCase(deleteRiskRate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteRiskRate.fulfilled, (state, action: PayloadAction<any>) => {
                state.isRiskRateDeleted = true;
                state.isLoading = false;
                state.ToasterMsg.Message = action?.payload?.Message;
                state.ToasterMsg.Status = action?.payload?.IsSuccess;
            })
            .addCase(deleteRiskRate.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(getRiskRateHistory.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })
            .addCase(getRiskRateHistory.fulfilled, (state, action: PayloadAction<any>) => {
                console.log(action.payload, "action.payload");

                state.versionHistory.RiskRateVHeader = action.payload.HeaderJson
                state.versionHistory.RiskRateRecords = action.payload.RiskCategoryHistories.map((user: any) => {
                    user.IsActive = user.IsActive === true ? "Active" : "Inactive";
                    user.Approved_On = chkDate(user.Approved_On);
                    return user;
                });
                state.isLoading = false;
                // state.ToasterMsg = "Currency data deleted successfully";
            })
            .addCase(getRiskRateHistory.rejected, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
            })

    },
});

export const { updateRiskRateAddandEdit, updateUser, updateVersionHistory } = RiskRateDataSlice.actions;
export default RiskRateDataSlice.reducer;
