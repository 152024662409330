import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddThirdPartyResponse, IThirdPartyResponse, IVHColumnRes, } from "../../models/thirdParty.type";
import { THIRDPARTY_URL } from "./endpoints/commonMasters";


const getThirdPartyList = (reqparams: any): Promise<IThirdPartyResponse> =>
  axiosInstance
    .get(THIRDPARTY_URL.GetAllThirdParty)
    .then((response: AxiosResponse<IThirdPartyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateThirdParty = (requestBody: any): Promise<IAddThirdPartyResponse> =>
  axiosInstance
    .post(THIRDPARTY_URL.UpdateThirdParty, requestBody)
    .then((response: AxiosResponse<IAddThirdPartyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addThirdParty = (requestBody: any): Promise<IAddThirdPartyResponse> =>
  axiosInstance
    .post(THIRDPARTY_URL.AddThirdParty, requestBody)
    .then((response: AxiosResponse<IAddThirdPartyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteThirdParty = (requestBody: any): Promise<IAddThirdPartyResponse> =>
  axiosInstance
    .post(THIRDPARTY_URL.DeleteThirdParty, requestBody)
    .then((response: AxiosResponse<IAddThirdPartyResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getVersionHistoryThirdParty = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(THIRDPARTY_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const ThirdPartyService = {
  getThirdPartyList,
  updateThirdParty,
  addThirdParty,
  deleteThirdParty,
  getVersionHistoryThirdParty,
};

export default ThirdPartyService;
