import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import contractTypeMasterService from "../services/contractTypeMasterService";
import {
  IContractTypeMasterResponse,
  IContractTypeMaster,
  IVHColumn,
  IVHColumnRes,
} from "../../../models/contractManagementModels/contractMasterModel";
import { ACTIVE, chkDate, INACTIVE } from "../../../utils/constants";

export interface IContractTypeMasterData {
  contractTypeMasterData: IContractTypeMasterResponse[];
  isContractTypeMasterLoading: boolean;
  isContractTypeMasterAdded: boolean;
  contractToasterStatus: boolean | null;
  isContractTypeMasterUpdated: boolean;
  isContractTypeMasterDeleted: boolean;
  ToasterMsg: { Message: string; Status: string };
  versionHistory: { ContractTypeRecords: IVHColumnRes[]; ContractTypeVHeader: IVHColumn[] };
  contractData: any;
}

export const initialState: IContractTypeMasterData = {
  contractTypeMasterData: [],
  isContractTypeMasterLoading: false,
  isContractTypeMasterAdded: false,
  contractToasterStatus: false,
  isContractTypeMasterUpdated: false,
  isContractTypeMasterDeleted: false,
  ToasterMsg: { Message: "", Status: "" },
  versionHistory: { ContractTypeRecords: [], ContractTypeVHeader: [] },
  contractData: null,
};

export const getContractTypeList = createAsyncThunk("getContractTypeList", async (_, { rejectWithValue }) => {
  try {
    const response: any = await contractTypeMasterService.getContractTypeList();
    return response.contractTypeList;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const createContractType = createAsyncThunk("CreateContractType", async (reqBody: any, { rejectWithValue }) => {
  try {
    const response: any = await contractTypeMasterService.createContractType(reqBody);
    return response;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const updateContractType = createAsyncThunk("updateContractType", async (reqBody: any, { rejectWithValue }) => {
  try {
    const response: any = await contractTypeMasterService.updateContractType(reqBody);
    return response;
  } catch (e: any) {
    return rejectWithValue(e?.response?.data?.errors[0]);
  }
});

export const activeInactiveContractType = createAsyncThunk(
  "activeInactiveContractTypeMaster",
  async (reqBody: any, { rejectWithValue }) => {
    try {
      const response: any = await contractTypeMasterService.activeInactiveContractType(reqBody);
      return response;
    } catch (error: any) {
      console.error("Error in activeInactiveContractType:", error);
      return rejectWithValue(error.message || "Failed to update status");
    }
  }
);
export const getVersionHistory = createAsyncThunk(
  "getVersionHistory",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const reqbody = { ...requestParams };
      const response: any = await contractTypeMasterService.getVersionHistory(reqbody);
      const { Records, HeaderJson } = response.data;
      return { History: Records, HeaderJson: HeaderJson.ColumnHeaders };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const contractTypeMasterSlice = createSlice({
  name: "contractTypeMasterDetails",
  initialState,
  reducers: {
    updateContractTypeDetailsAddAndEdit: (state, action) => {
      state.isContractTypeMasterAdded = false;
      state.isContractTypeMasterUpdated = false;
      state.isContractTypeMasterDeleted = false;
      state.ToasterMsg.Message = action?.payload?.Message;
      state.ToasterMsg.Status = action?.payload?.Status;
    },
    clearData: (state) => {
      state.contractData = null;
    },
    updateVersionHistory: (state, action) => {
      console.log(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractTypeList.pending, (state) => {
        state.isContractTypeMasterLoading = true;
      })
      .addCase(getContractTypeList.fulfilled, (state, action: PayloadAction<any>) => {
        state.contractTypeMasterData = action?.payload?.map((data: any) => {
          data.isActive = data.isActive ? ACTIVE : INACTIVE;
          return data;
        });
        state.isContractTypeMasterLoading = false;
      })
      .addCase(getContractTypeList.rejected, (state) => {
        state.isContractTypeMasterLoading = false;
      })
      .addCase(createContractType.pending, (state) => {
        state.isContractTypeMasterLoading = true;
      })
      .addCase(createContractType.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractTypeMasterLoading = false;
        state.isContractTypeMasterAdded = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message;
        state.ToasterMsg.Status = action?.payload?.toaster?.status;
        state.contractData = action?.payload;
      })
      .addCase(createContractType.rejected, (state) => {
        state.isContractTypeMasterLoading = false;
      })
      .addCase(updateContractType.pending, (state) => {
        state.isContractTypeMasterLoading = true;
      })
      .addCase(updateContractType.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractTypeMasterLoading = false;
        state.isContractTypeMasterUpdated = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message;
        state.ToasterMsg.Status = action?.payload?.toaster?.status;
      })
      .addCase(updateContractType.rejected, (state) => {
        state.isContractTypeMasterLoading = false;
      })
      .addCase(activeInactiveContractType.pending, (state) => {
        state.isContractTypeMasterLoading = true;
      })
      .addCase(activeInactiveContractType.fulfilled, (state, action: PayloadAction<any>) => {
        state.isContractTypeMasterLoading = false;
        state.isContractTypeMasterDeleted = true;
        state.ToasterMsg.Message = action?.payload?.toaster?.message;
        state.ToasterMsg.Status = action?.payload?.toaster?.status;
      })
      .addCase(activeInactiveContractType.rejected, (state) => {
        state.isContractTypeMasterLoading = false;
      })
      .addCase(getVersionHistory.pending, (state) => {
        state.isContractTypeMasterLoading = false;
      })
      .addCase(getVersionHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.versionHistory.ContractTypeVHeader = action?.payload?.HeaderJson;
        state.versionHistory.ContractTypeRecords = action?.payload?.History?.map((data: any) => {
          data.IsActive = data?.IsActive ? "Active" : "Inactive";
          data.Approved_On = chkDate(data.Approved_On);
          return data;
        });
        state.isContractTypeMasterLoading = false;
      });
  },
});

export const { updateContractTypeDetailsAddAndEdit, updateVersionHistory, clearData } = contractTypeMasterSlice.actions;
export default contractTypeMasterSlice.reducer;
