import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../common/dynamicAccordion/CustomAccordion";
import { Box, Typography } from "@mui/material";
import DynamicBasicTable from "../../../common/dynamicbasicTable/DynamicBasicTable";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddressForm } from "./AddressForm";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { LovConfig, StatusConfig } from "../../../config/config";
import { RepresentativeForm } from "./RepresentativeForm";

interface CompanyAddressProps {
  view?: boolean;
  isEditData?: any;
  edit?: boolean;
  masterId?: string;
  onClose?: any;
  ModuleId?: number | undefined;
  isDraftData?: any;
  onFormSubmit?: any;
  onFormCancel?: any;

}

export const CompanyAddress: React.FC<CompanyAddressProps> = ({
  view,
  isEditData,
  edit,
  masterId,
  onClose,
  ModuleId,
  isDraftData,
  onFormSubmit,
  onFormCancel,
}) => {
  const dispatch = useAppDispatchThunk();
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isNewRepresentative, setIsNewRepresentative] = useState(false);
  const [isNewEsc, setIsNewEsc] = useState(false);
  const [option, setOption] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [IsEditData, setIsEditData] = useState<any>({});
  const [isEditOrViewAddress, setIsEditOrViewAddress] = useState(false);
  const [isEditOrViewRepresentative, setIsEditOrViewRepresentative] = useState(false);
  const [isEditOrViewEsc, setIsEditOrViewEsc] = useState(false);
  const [isDeleteAddress, setIsDeleteAddress] = useState<any>(null);
  const [isDeleteRepresentative, setIsDeleteRepresentative] = useState<any>(null);
  const [isDeleteEsc, setIsDeleteEsc] = useState<any>(null);
  const [expanded, setExpanded] = useState<any>(0);
  const [addressTypeOptions, setAddressTypeOptions] = useState<any[]>([]);
  const [addressTypeFilteredOptions, setAddressTypeFilteredOptions] = useState<any[]>([]);
  const [purposeType, SetPurposeType] = useState<any[]>([]);

  const [addressType, setAddressType] = useState<any[]>([]);
  const [statusIdLabelMapping, setStatusIdLabelMapping] = useState<any>({});
  const [lovActiveStatusId, setLovActiveStatusId] = useState<any>("");

  const [Purpose, SetPurpose] = useState<any[]>([]);

  
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const AddressColumns = [
    { Field: "Address_Number", DisplayName: "Address No" },
    // { Field: "Address_Type", DisplayName: "Address Type ID", DisplayValue: addressType },
    { Field: "Address_Type_Value", DisplayName: "Address Type" },
    { Field: "Address", DisplayName: "Address" },
    { Field: "Pin", DisplayName: "Pincode" },
    "City",
    "State",
    "Country",
  ];
  const RepresentativeColumns = [
    { Field: "Representative_Number", DisplayName: "Representative No" },
    { Field: "Purpose_value", DisplayName: "Purpose", DisplayValue: Purpose },
    "Name",
    "Designation",
    { Field: "Contact_Number", DisplayName: "Contact No" },
    { Field: "EmailId", DisplayName: "Email Id" },
  ];

  const {
    control,
    formState: { errors },
    clearErrors,
    getValues,
  } = useFormContext();

  const {
    fields: addressFields,
    append: appendAddress,
    update: updateAddress,
    remove: removeAddress,
    replace: replaceAddress,
  } = useFieldArray({
    control,
    name: "address",
    rules: {
      required: "Atleast one Address is required",
      validate: (i) => {
        let seen = new Set();
        let findRegiterAddress: any = addressTypeOptions.find((item) => item.Options === "Registered Address");

        let res = i.filter((currentObject: any) => currentObject.Status === lovActiveStatusId);
 
        let RegisterAddressMandatory = i.filter(
          (item: any) => item.Status === lovActiveStatusId && item.Address_Type === findRegiterAddress.LovId
        );

        if (RegisterAddressMandatory?.length === 0) return "Registered Address is required";

        if (res.length === 0) return "Atleast one active Address is required";

        let hasDuplicates = i.some((currentObject: any) => {
          if (currentObject.Status === lovActiveStatusId)
            return seen.size === seen.add(currentObject.Address_Type).size;
        });
        if (!hasDuplicates) {
          clearErrors("address");
          return undefined;
        } else return "Address type should be unique";
      },
    },
  });
  const {
    fields: RepresentativesFields,
    append: appendRepresentatives,
    update: updateRepresentatives,
    remove: removeRepresentatives,
    replace: replaceRepresentatives,
  } = useFieldArray({
    control,
    name: "representatives",
    rules: {
      required: "Atleast one Representative is required",
      validate: (i) => {
        let res = i.filter((currentObject: any) => currentObject.Status === lovActiveStatusId);
        if (res.length === 0) return "Atleast one active Representative is required";
      },
    },
  });

  const getDisplayValue = (type: string) => {
    let res = lovTable.filter((item: any) => item.Name === type) || [];
    return res.reduce((agg: any, i: any) => {
      return { ...agg, [i.LovId]: i["Options"] };
    }, {});
  };

  useEffect(() => {
    setAddressType(getDisplayValue("Address Type"));
    let res = lovTable.filter((item: any) => item.Name === "Address Type") || [];
    setAddressTypeOptions(res);
    setAddressTypeFilteredOptions(res);
    SetPurpose(getDisplayValue("Purpose"));
    setStatusIdLabelMapping(getDisplayValue(LovConfig.Status));
    setLovActiveStatusId(
      lovTable.find((item: any) => item.Name === LovConfig.Status && item.Options === StatusConfig?.Active).LovId || 0
    );
      SetPurposeType(lovTable.filter((item: any) => item.Name === "Purpose") || []);
  }, [lovTable]);
  

  useEffect(() => {
    let filtered = addressTypeOptions.filter((item) => {
      return !addressFields.find(
        (item1: any) => item.LovId === item1.Address_Type && statusIdLabelMapping[item1.Status] == "Active"
      );
    });
    setAddressTypeFilteredOptions(filtered);
  }, [addressFields, addressTypeOptions]);

  const handleOpen = (data: any, option: string, index?: number) => {
    console.log(data, option, index, "data, option, index");
    setOption(option);
    if (index || index === 0) setSelectedIndex(index);
    if (expanded === 1) {
      handleAddressOption(data, option, index);
    } else if (expanded === 2) {
      handleRepresentativeOption(data, option, index);
    }
  };

  const transformData = (data: any) => {
    return data.map((Addressitem: any) => {
      const AddressTypeID = Addressitem?.Address_Type ? Addressitem?.Address_Type : 0;
      const addressDatas = addressTypeOptions.find((item: any) => item?.LovId === AddressTypeID)?.Options;
    
      return {
        ...Addressitem,
        Address_Type_Value: addressDatas !== undefined ? addressDatas : "",
      };
    });
  };

  const transformDataRepres = (data: any) => {
    return data.map((Repitem: any) => {
      const purposeTypeID = Repitem?.Purpose ? Repitem?.Purpose : 0;
      const RepDatas = purposeType.find((item: any) => item?.LovId === purposeTypeID)?.Options;

      return {
        ...Repitem,
        Purpose_value: RepDatas !== undefined ? RepDatas : "",
      };
    });
  };

  function getKeyByValue(object: Record<string, string>, value: string) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const handleAddressOption = (data: any, option: string, index?: number) => {
    setIsEditOrViewEsc(false);
    setIsEditOrViewRepresentative(false);
    if (index || index === 0) {
      if (option === "edit" || option === "view") {
        setIsEditOrViewAddress(data);
        setIsNewAddress(false);
      } else if (option === "delete") {
        let updated = addressFields.reduce((acc: any, item: any, i: number) => {
          if (i != index) acc.push({ ...item, Address_Number: i < index ? i + 1 : i });
          return acc;
        }, []);
        replaceAddress(updated);
      } else if (option === "InActivate") {
        const id = getKeyByValue(statusIdLabelMapping, "In Active");
        let obj = { ...data, Status: parseInt(id as string) };
        updateAddress(index, obj);
      } else if (option === "Activate") {
        const id = getKeyByValue(statusIdLabelMapping, "Active");
        let obj = { ...data, Status: parseInt(id as string) };
        updateAddress(index, obj);
      }
    }
  };

  const handleRepresentativeOption = (data: any, option: string, index?: number) => {
    setIsEditOrViewAddress(false);
    setIsEditOrViewEsc(false);
    if (index || index === 0) {
      if (option === "edit" || option === "view") {
        console.log(option, data, index, "contentDatadDd");
        setIsEditOrViewRepresentative(data);
        setIsNewRepresentative(false);
      } else if (option === "delete") {
        let updated = RepresentativesFields.reduce((acc: any, item: any, i: number) => {
          if (i != index) acc.push({ ...item, Representative_Number: i < index ? i + 1 : i });
          return acc;
        }, []);
        replaceRepresentatives(updated);
      } else if (option === "InActivate") {
        const id = getKeyByValue(statusIdLabelMapping, "In Active");
        let obj = { ...data, Status: parseInt(id as string) };
        updateRepresentatives(index, obj);
      } else if (option === "Activate") {
        const id = getKeyByValue(statusIdLabelMapping, "Active");
        let obj = { ...data, Status: parseInt(id as string) };
        updateRepresentatives(index, obj);
      }
    }
  };

  return (
    <div>
      <CustomAccordion
        expanded={expanded === 1}
        handleChange={() => setExpanded((pre: any) => (pre === 1 ? null : 1))}
        title="Company Address"
        details={
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <CustomButton
                variant="outlined"
                onClick={() => {
                  setIsNewAddress(!isNewAddress);
                }}
                disabled={view || addressTypeFilteredOptions.length < 1}
                name="Add Address"
              />
            </Box>
            <Box component="form" noValidate sx={{ width: "100%" }}>
              <DynamicBasicTable
                columns={AddressColumns}
                rowData={transformData(addressFields)}
                style={{ border: "1px solid #f1f1f1" }}
                handleAction={handleOpen}
                isEdit={true}
                isDelete={view || edit}
                showDeleteButton={!view && !edit}
                disableAction={view}
                statusIdMapping={statusIdLabelMapping}
              />
            </Box>
          </Box>
        }
      />
      {errors.address?.root?.message && (
        <Typography variant="subtitle1" sx={{ color: "#D70000", textAlign: "left", fontSize: "15px" }}>
          {errors.address?.root?.message as any}
        </Typography>
      )}
      <CustomAccordion
        expanded={expanded === 2}
        handleChange={() => setExpanded((pre: any) => (pre === 2 ? null : 2))}
        title="Company Representative"
        details={
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <CustomButton
                variant="outlined"
                onClick={() => {
                  setIsNewRepresentative(!isNewRepresentative);
                }}
                name="Add Representative"
                disabled={view}
              />
            </Box>

            <Box component="form" noValidate sx={{ width: "100%" }}>
              <DynamicBasicTable
                columns={RepresentativeColumns}
                rowData={transformDataRepres(RepresentativesFields)}
                style={{ border: "1px solid #f1f1f1" }}
                handleAction={handleOpen}
                isEdit={true}
                isDelete={view || edit}
                showDeleteButton={!view && !edit}
                disableAction={view}
                statusIdMapping={statusIdLabelMapping}
              />
            </Box>
          </Box>
        }
      />
      {errors.representatives?.root?.message && (
        <Typography variant="subtitle1" sx={{ color: "#D70000", textAlign: "left", fontSize: "15px" }}>
          {errors.representatives?.root?.message as any}
        </Typography>
      )}

      {/* New Address */}
      <CustomDialog
        show={isNewAddress}
        onHide={() => setIsNewAddress(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Add Address"}
        contentNode={
          <AddressForm
            view={false}
            
            onClose={() => {
              setIsNewAddress(false);
            }}
            addressType={addressTypeFilteredOptions}
            addressTableValue={addressFields}
            onSubmit={(data) => {
              appendAddress({ ...data, Address_Number: addressFields.length + 1, Status: lovActiveStatusId });
              setIsNewAddress(false);
            }}
          />
        }
      />
      {/*Edit || View Address */}
      <CustomDialog
        show={isEditOrViewAddress}
        onHide={() => setIsEditOrViewAddress(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={option === "edit" ? "Edit Address" : "View Address"}
        contentNode={
          <>
            {option === "edit" && (
              <AddressForm
                view={false}
                
                onClose={() => {
                  setIsEditOrViewAddress(false);
                }}
                addressType={addressTypeOptions}
                isEditData={isEditOrViewAddress}
                addressTableValue={addressFields}
                onSubmit={(data) => {
                  if (selectedIndex || selectedIndex === 0) updateAddress(selectedIndex, data);
                  setIsEditOrViewAddress(false);
                }}
              />
            )}
          </>
        }
      />

      {/*New Representative */}
      <CustomDialog
        show={isNewRepresentative}
        onHide={() => setIsNewRepresentative(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={"Add Representative"}
        contentNode={
          <RepresentativeForm
            view={false}
            onClose={() => {
              setIsNewRepresentative(false);
            }}
            onSubmit={(data) => {
              appendRepresentatives({
                ...data,
                Representative_Number: RepresentativesFields.length + 1,
                Status: lovActiveStatusId,
              });
              setIsNewRepresentative(false);
            }}
          />
        }
      />
      {/*Edit || View Representative */}
      <CustomDialog
        show={isEditOrViewRepresentative}
        onHide={() => setIsEditOrViewRepresentative(false)}
        maxWidth={"sm"}
        minHeight={"50vh"}
        header={option === "edit" ? "Edit Representative" : "View Representative"}
        contentNode={
          <>
            {option === "edit" && (
              <RepresentativeForm
                view={false}
                onClose={() => {
                  setIsEditOrViewRepresentative(false);
                }}
                isEditData={isEditOrViewRepresentative}
                onSubmit={(data: any) => {
                  if (selectedIndex || selectedIndex === 0) updateRepresentatives(selectedIndex, data);
                  setIsEditOrViewRepresentative(false);
                }}
              />
            )}
          </>
        }
      />
    </div>
  );
};

export default CompanyAddress;
