import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RolesAndResponsibilityService from "../services/rolesAndResponsibilityService";
import { AnyMxRecord } from "dns";

export interface rolesAndResponsibilityData {
  rolesAndResponsibilityData: any[];
  isLoading: boolean;
  isAddEditRolesAndResonsibilityLoading: boolean;
  isRolesAndResponsibilityAdded: boolean;
  isRolesAndResponsibilityUpdated: boolean;
  isRolesAndResponsibilityDeleted: boolean;
  RolesAndResonsibilitytoasterMsg: string;
  RolesAndResonsibilityInstanceId: any;
  RolesAndResponsibilityversionHistory: {
    RolesAndResponsibilityversionHistory: any[];
    RolesAndResponsibilityVHeader: any[];
  };
}

export const initialState: rolesAndResponsibilityData = {
  rolesAndResponsibilityData: [],
  isLoading: false,
  isAddEditRolesAndResonsibilityLoading: false,
  isRolesAndResponsibilityAdded : false,
  isRolesAndResponsibilityUpdated : false,
  isRolesAndResponsibilityDeleted: false,
  RolesAndResonsibilitytoasterMsg: "",
  RolesAndResonsibilityInstanceId: "",
  RolesAndResponsibilityversionHistory: { RolesAndResponsibilityversionHistory: [], RolesAndResponsibilityVHeader: [] },
};

export const getRolesAndResponsibilityList = createAsyncThunk(
  "getRolesAndResponsibilityList",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await RolesAndResponsibilityService.getRolesAndResponsiblityList(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addRolesAndResponsibility = createAsyncThunk(
  "addRolesAndResponsibility",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await RolesAndResponsibilityService.addRoleAndResponsiblity(requesrParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRolesAndResponsibility = createAsyncThunk(
  "updateRolesAndResponsibility",
  async (requestParams: any, { rejectWithValue }) => {
    try {
      const response = await RolesAndResponsibilityService.updateRoleAndResponsiblity(requestParams);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteRolesAndResponsibility = createAsyncThunk(
  "deleteRolesAndResponsibility",
  async (RolesAndResponsibilityId: any, { rejectWithValue }) => {
    try {
      const response = await RolesAndResponsibilityService.deleteRoleAndResponsiblity(RolesAndResponsibilityId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const rolesAndResponsibilitySlice = createSlice({
  name: "rolesAndResponsibility",
  initialState,
  reducers: {
    updaterolesandresponsAddandEdit: (state, action) => {
      
      state.isRolesAndResponsibilityAdded = false ;
      state.isRolesAndResponsibilityUpdated = false ;
      state.isRolesAndResponsibilityDeleted = false ;
      state.RolesAndResonsibilityInstanceId = "" ;
      
    },

    updaterolesandresponsresposeData: (state, action) => {

      state.RolesAndResonsibilityInstanceId = "" ;
      
    },
 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesAndResponsibilityList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRolesAndResponsibilityList.fulfilled, (state, action: PayloadAction<any>) => {
        state.rolesAndResponsibilityData = action.payload
          ? action.payload.map((user: any) => {
              user.IsActive = user.IsActive === true ? "Active" : "Inactive";
              return user;
            })
          : [];
        state.isLoading = false;
      })
      .addCase(getRolesAndResponsibilityList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
        state.isAddEditRolesAndResonsibilityLoading = true;
      })
      .addCase(addRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action , 'rraction');
        
        state.isRolesAndResponsibilityAdded = true;
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload?.ToasterMessage?.Message;
        state.RolesAndResonsibilityInstanceId = action.payload?.ResponseProjectDetails;
      })
      .addCase(addRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
      })
      .addCase(updateRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
        state.isAddEditRolesAndResonsibilityLoading = true;
      })
      .addCase(updateRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityUpdated = true;
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload.Message;
      })
      .addCase(updateRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
        state.isAddEditRolesAndResonsibilityLoading = false;
      })
      .addCase(deleteRolesAndResponsibility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRolesAndResponsibility.fulfilled, (state, action: PayloadAction<any>) => {
        state.isRolesAndResponsibilityDeleted = true;
        state.isLoading = false;
        state.RolesAndResonsibilitytoasterMsg = action.payload.Message;
      })
      .addCase(deleteRolesAndResponsibility.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updaterolesandresponsAddandEdit , updaterolesandresponsresposeData } = rolesAndResponsibilitySlice.actions;
export default rolesAndResponsibilitySlice.reducer;
