export const LovConfig = {
  All: "All",
  AQ_Catagory: "AQ Catagory",
  AQ_Group: "AQ Group",
  AQ_Responder: "AQ Responder",
  AQ_Responder_Type: "AQ Responder Type",
  AQ_Response_Type: "AQ Response Type",
  AQ_Type: "AQ Type",
  Assessment_Type: "Assessment Type",
  Assessment_TypeII: "Assessment Type II",
  Branch_Type: "Branch Type",
  Company_Constitution: "Company Constitution",
  Company_Type: "Company Type",
  Data_Type: "Data Type",
  Department_Level: "Department Level",
  Department_Type: "Department Type",
  AQ_Category_Level:"Assessment Questionaire Category Level",
  AQ_Category_Type:"Assessment Questionaire Category Type",
  Document_Ext: "Document Ext",
  Industry_Level: "Industry Level",
  Industry_Type: "Industry Type",
  LOB: "LOB",
  Location_Type: "Location Type",
  Maker_Checker: "Maker Checker",
  Process_Type: "ProcessType",
  Process_Workflow: "ProcessWorkflow",
  Purpose: "Purpose",
  Record_Status: "Record Status",
  Region: "Region",
  RiskCategoryI: "Risk Category I",
  RiskCategoryII: "Risk Category II",
  RiskCategoryIII: "Risk Category III",
  Sector: "Sector",
  ServiceType: "Service Type",
  ServiceGroup: "Service Group",
  Status: "Status",
  ThirdPartyType: "Third Party Type",
  UniqueIDType: "Unique Id Type",
  WorkFlowType: "WorkflowType",
  WorkflowCategory: "Workflow Category",
  Zone: "Zone",
  Regulator: "Regulator",
  VendorStatus: "Vendor Status",
  VendorTypeI: "Vendor Type I",
  VendorTypeII: "Vendor Type II",
  VOBWorkflowStatus: "VOB Workflow Status",
  COBWorkflowStatus: "COB Workflow Status",
  CMWorkflowStatus: "CM Workflow Status",
  Pre_Approval: "Pre Approval",
  Designation: "Designation",
  Vendor_Contract_Process_Type: "Vendor Conract Process Type",
  Address_Type : "Address Type",
  Email_template_name : "Email Template Name",
};
export const StatusConfig = {
  Active : "Active",
  Inactive : 'Inactive'
}
export const IndustryConfig = {
  Industry: "Industry",
  Sub_Industry: "Sub Industry",
  Industry_Level_1: "Level 1",
};

export const DepartmentConfig = {
  Department: "Department",
  Sub_Department: "Sub Department",
  Department_Level_1: "Level 1",
};

export const MetrixMasterConfig = {
  Deviation_Matrix : "Deviation Matrix",
  AQ_Response_Type : "AQ Response Type",
  Service_Scoringe : "Service Scoring"
}

export const AssessmentCategoryConfig = {
  Category: "Category",
  Sub_Category: "Sub Category",
  Category_Level_1: "Level 1",
  Category_Level_2: "Level 2",
  Category_Level_3: "Level 3",
};

export const LocationConfig = {
  Location_Country: "Country",
  Location_State: "State",
  Location_City: "City",
};

export const AQMConfig = {
  Regulatory: "Regulatory",
  High: "High",
  Medium: "Medium",
  Low: "Low",
};

export const ToasterActivityConfig = {
  create: "create",
  view: "view",
  Edit: "Edit",
  export: "export",
  default: "default",
  approved: "approved",
  rejected: "rejected",
  active: "active",
  inactive: "inactive",
  createDraft: "Create Draft",
  editDraft: "Edit Draft",
  deleteDraft: "Delete Draft",
  Withdraw: "withdraw",
};

export const option_ToasterActivityConfig: any[] = [
  { id: ToasterActivityConfig.create, lable: "Create" },
  { id: ToasterActivityConfig.view, lable: "View" },
  { id: ToasterActivityConfig.Edit, lable: "Edit" },
  { id: ToasterActivityConfig.export, lable: "Export" },
  { id: ToasterActivityConfig.default, lable: "Default" },
  { id: ToasterActivityConfig.approved, lable: "Approved" },
  { id: ToasterActivityConfig.rejected, lable: "Rejected" },
  { id: ToasterActivityConfig.active, lable: "Active" },
  { id: ToasterActivityConfig.inactive, lable: "Inactive" },
  { id: ToasterActivityConfig.createDraft, lable: "Create Draft" },
  { id:ToasterActivityConfig.editDraft, lable: "Edit Draft" },
  { id: ToasterActivityConfig.deleteDraft , lable: "Delete Draft" },
  { id: ToasterActivityConfig.Withdraw , lable: "Withdraw" },
];

export const userConfig = {
  Country: 39,
  State: 38,
};

export const vendorAssetStatus = {
  approve: "Approve",
  reject: "Reject",
};

export const vendorassetview = {
  vendor: 1,
  SME: 2,
  SO: 3,
  DO: 4,
  SMEE: 5,
  Cxo: 6,
};

export const vendorassetroles = {
  SME: "SME",
  SO: "Service Owner",
  DO: "Deviation Officer",
  SMEE: "SME",
};

export const AQMResponder = {
  SME: "SME",
  SO: "Service Owner",
  Vendor: "Vendor",
};

export const WorkflowCondition = {
  doApproval: "IsDOApprovalNeeded",
};

export const recordLockTimeOut = 900000; //15 mins
export const ignoreDate = "01-01-0001";

//this id use for notification show for vendor portal login use
export const MenuIdForVendoruse = 39;

export const VOBWorflowPages = {
  RR: "Roles and Responsibility",
  VendorDetail: "Vendor Details",
  AQMMapping: "Assessment Questionnaire Mapping",
  ConsentForm: "Consent Form",
  VendorResponse: "Vendor Assessment",
  SMEResponse: "SME Response",
  SMEReview: "SME Review",
  SOReview: "So Review",
  DoReview: "Do Review",
  CXO: "Sign Off",
};

export const CMWorflowPages = {
  RR: "Roles and Responsibility", 
  InitiateContract: "Initiate Contract" ,
  CostApproval:"Cost Approval",
  SMEReview: "SME Review",
  SOReview: "So Review",
  DoReview: "Do Review",
  VendorReview: "Send for Vendor Review",
  BoardApproval: "Board Approval"
};

export const Edit_Enable_tosterMSG = {
  message: "This item wait for Approval",
};

export const Default_threshold = 100;

export const WorkflowCategory = (data: string): string => {
  switch (data) {
    case "Vendor Onboarding":
      return LovConfig.VOBWorkflowStatus;
    case "Customer Onboarding":
      return LovConfig.COBWorkflowStatus;
    case "Contract Management":
      return LovConfig.CMWorkflowStatus;
    default:
      return "";
  }
};

export const WorkFlowType = {
  vendorOnboarding: "Vendor Onboarding",
  customerOnboarding: "Customer Onboarding",
  contractManagement: "Contract Management",
};

export const COBWorflowPages = {
  ApplicationParameter: "Application Parameter",
  RegionalSettings: "Regional Settings",
  MakerChecker: "Maker Checker",
  CompanyMaster: "Company Master",
};

export const serviceCriticality = {
  material: "Materials",
  critical: "Critical",
  low_critical : "Low Critical",
  
};
export const ScoreDeviation = {
  low : "Low",
  critical: "Medium",
  low_critical : "Poor",
  
};

export const Assessment_Type ={ 
  Advanced : 'Advanced',
  Basic : 'Basic'
}

export const Applicable_For ={ 
  First_Assessment : 'First Assessment',
   Renewal : 'Renewal',
   Both : 'Both'
}



 
export const ApplicationParameterName={
      EstimateSpend:"Estimate Spend",
      CurrentBasicWorkflowvob : 'Current Basic Workflow for VOB',
      CurrentAdvanceWorkflowvob : 'Current Advance Workflow for TPOB',
       CurrentBasicWorkflowforRenewalvob : 'Current Basic Workflow for Renewal VOB',
      CurrentAdvanceWorkflowforRenewalvob : 'Current Advance Workflow for Renewal VOB',
      CurrentBasicWorkflowcob : 'Current Basic Workflow for COB',
      CurrentAdvanceWorkflowcob : 'Current Advance Workflow for COB',
       CurrentBasicWorkflowforRenewalcob : 'Current Basic Workflow for Renewal COB',
      CurrentAdvanceWorkflowforRenewalcob : 'Current Advance Workflow for Renewal COB',
      Masking:"Masking",
      Masking_PAN_Name:"PAN",
      Masking_TAN_Name:"TAN",
      Masking_CIN_Name:"CIN",
      Threshold :"Threshold for Outliers",
      ServiceCatalogGrpName:"Service catalog master",
      ServiceCatalogRegulatorCriticality:"Service Catalog regulator criticality",
      ServiceCatalogRegulatorMaxScoring:"Service Catalog regulator max scoring",
      applicableRiskForMaterial:"Regulatory"
}

export const WorkFlowStatusName ={
  Completed:"Completed" ,
  NotStarted:"Not Started",
  Pending:"Pending"
}

export const ModuleNames = {
    
    workflowManager : 'Workflow Manager',
    CompanyMaster : 'Company Master',
    VendorMaster : 'Third Party Master',
    AQMaster : 'Assessment Questionnaire',
    UserMaster: 'User',
    IndustryMaster: 'Industry Master',
    RoleMaster: 'Role',
    ServiceCatalog: 'ServiceCatalog'
}

export const Register_Address_tosterMSG = {
  message: "Please Add Register Address",
};

export const VendorMaster_Address_Type ={ 
  RegisteredAddress : "Registered Address",
  BusinessAddress : "Business Address"
}

export const VendorMaster_InternalVendorCode ={ 
  VendorMaster : "Third Party Master",
  InternalVendorCode : "Internal Third Party Code",
  InternalVendorCodePrefix : "Internal Third Party Code Format prefix",
  InternalVendorCodeSuffix : "Internal Third Party Code Format suffix",
}

export const thirdPrtyType = {
     vendor : "Vendor" ,
     customer : "Customer"
}

export const ContractManagemnt_InternalCode ={ 
  ContractManagement : "Contract Management",  
  ContractMangementPrefix : "Contract No Format Prefix",
  ContractMangementSuffix : "Contract No Format suffix",
}

export const CompanyMaster_Address_Type ={
  RegisteredAddress : "Registered Address"
}
export const MasterBulkUpload = {
     
     exportButtonName : 'Export Template',
     ValidationRowCount : 100
}

export const Probe_ThirdParty_API_tosterMSG = {
  Succes_message: "Successfully loaded profile details",
  Failed_message: "Failed to load the profile details, check error log",
};

export const FormIdConfig = {
    AQMasterFormId  : 17
}

export const ContentNameConfig = {
    
     vendorwelcometext : "Third Party Welcome Text",
     vendorContext : "Third Party Consent Text",
     cobQuestionSheetNote : "COB Questionnaire Template Note",
}