export const FormatDate = (dateString: string, format: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return format
    .replace("dd", day)
    .replace("mm", month)
    .replace("yyyy", year)
    .replace("hh", hours)
    .replace("mm", minutes)
    .replace("ss", seconds);
};
