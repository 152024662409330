import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {  IVHAQMColumn } from "../../models/assessmentQuestionnaireMaster.type";
import AQMMappingCOBService from "../services/assessmnetQuestionnaireMappingCOB";
export interface IAQMMappingData {
  AQMMappingdataTable: any;
  isAQMMappingLoading: boolean;
  isAddEditAQMMappingLoading: boolean;
  isAQMMappingAdded: boolean;
  isAQMMappingUpdated: boolean;
  isAQMMappingDeleted: boolean;
  AQMMappingToasterMsg: string;
}

export const initialState: IAQMMappingData = {
  AQMMappingdataTable: [],
  isAQMMappingLoading: false,
  isAddEditAQMMappingLoading: false,
  isAQMMappingAdded: false,
  isAQMMappingUpdated: false,
  isAQMMappingDeleted: false,
  AQMMappingToasterMsg: "",
};

export const getAQMMappingCOBList = createAsyncThunk(
  "getAQMMappingCOBList",
  async (requesrParams: { FormID: number }, { rejectWithValue }) => {
    try {
      const response = await AQMMappingCOBService.getAQMMappingListCOB(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAQMMappingCOB = createAsyncThunk("addAQMMappingCOB", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await AQMMappingCOBService.addAQMMappingCOB(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAQMMappingCOB = createAsyncThunk(
  "updateAQMMappingCOB",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      let reqbody = { ...requesrParams };
      const response: any = await AQMMappingCOBService.updateAQMMappingCOB(reqbody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const AQMMappingCOBDataSlice = createSlice({
  name: "assesmentQuestionnaireMappingCOB",
  initialState,
  reducers: {
    updateAQMMappingCOBAddandEdit: (state, action) => {
      state.isAQMMappingAdded = false;
      state.isAQMMappingUpdated = false;
      state.isAQMMappingDeleted = false;
      state.AQMMappingToasterMsg = '';
    },
   
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAQMMappingCOBList.pending, (state) => {
        state.isAQMMappingLoading = true;
      })
      .addCase(getAQMMappingCOBList.fulfilled, (state, action: PayloadAction<any>) => {
        state.AQMMappingdataTable = action.payload.map((user: any) => {
          user.IsActive = user.IsActive === true ? "Active" : "Inactive";
          user.IsEvidenceRequired = user.EvidenceRequired == true ? "Evidence Required" : "Evidence Not Required";
          return user;
        });
        state.isAQMMappingLoading = false;
      })
      .addCase(getAQMMappingCOBList.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
      })
      .addCase(addAQMMappingCOB.pending, (state) => {
        state.isAQMMappingLoading = true;
        state.isAddEditAQMMappingLoading = true;
      })
      .addCase(addAQMMappingCOB.fulfilled, (state, action: PayloadAction<any>) => {
        // state.AQMMappingdataTable = [...state.AQMMappingdataTable , ...action.payload  ]
        state.isAQMMappingAdded = true;
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
        state.AQMMappingToasterMsg = action.payload.Message;
      })
      .addCase(addAQMMappingCOB.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
      })
      .addCase(updateAQMMappingCOB.pending, (state) => {
        state.isAQMMappingLoading = true;
        state.isAddEditAQMMappingLoading = true;
      })
      .addCase(updateAQMMappingCOB.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAQMMappingUpdated = true;
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
        state.AQMMappingToasterMsg = action.payload.Message;
      })
      .addCase(updateAQMMappingCOB.rejected, (state, action: PayloadAction<any>) => {
        state.isAQMMappingLoading = false;
        state.isAddEditAQMMappingLoading = false;
      })
      

  },
});

export const { updateAQMMappingCOBAddandEdit } = AQMMappingCOBDataSlice.actions;
export default AQMMappingCOBDataSlice.reducer;
