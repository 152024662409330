import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography, Grid } from "@mui/material";
import { CustomSquareStepper } from "../../common/dynamicSteper/CustomSquareStepper";
import { CustomStatusStepper } from "../../common/dynamicSteper/CustomStatusStepper";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { WorkFlowHistorySteper } from "../workFlowHistory/WorkFlowHistorySteper";
import { getWorkFlowHistoryView, updateWorkFlowHistoryView } from "../../store/slices/workFlowHistoryData";
import { RootState, useAppDispatchThunk } from "../../store/store";
import { useSelector } from "react-redux";



export interface VendorHistoryProps {
    view?: boolean;
    closePopUp?: () => void;
    edit?: boolean;
    lovID?: any;
    isEditData?: any;
}

export const VendorHistory: React.FC<VendorHistoryProps> = ({
    view, closePopUp, edit, lovID, isEditData
}) => {

    const dispatch = useAppDispatchThunk();
    const [OpenStatusStepper, setOpenStatusStepper] = useState<boolean>(false);
    const [WorkFlowViewData, setWorkFlowViewData] = useState<any[]>([]);
    const [HeaderData, setHeaderData] = useState('');


    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );
    const { WorkFlowHistoryTable, WorkFlowHistoryViewData, ToasterMsg, isLoading } = useSelector(
        (state: RootState) => state.WorkFlowHistoryData
    );


    useEffect(() => {
        dispatch(
            getWorkFlowHistoryView({
                Workflow_Instanceid: isEditData?.Workflow_Instance_Id || isEditData?.Workflow_InstanceId,
            })
        );
    }, []);

    useEffect(() => {
        console.log(WorkFlowHistoryViewData, "WorkFlowHistoryView");

        setWorkFlowViewData(WorkFlowHistoryViewData?.Process);

    }, [WorkFlowHistoryViewData]);

    const main_steps = WorkFlowViewData?.map((item: any) => {
        return item.Process_Name;
    });    

    const Complete_data = WorkFlowViewData?.filter((item: any) => {
        return item.Process_Status === "Completed";
    });

    const Active_step_Count = Complete_data?.length - 1
    const Formate_Complete_data = Complete_data?.map((item: any) => {
        return item.Process_Name;
    });

    const Complete_steps = main_steps?.reduce((acc, step, index) => {
        if (Formate_Complete_data?.includes(step)) {
            acc.push(index);
        }
        return acc;
    }, [] as number[]);

    console.log(isEditData, main_steps, Complete_data, Active_step_Count, "isEditData");


    // const steps = 10;
    const steps = main_steps?.length;
    const activeStep = Active_step_Count;
    // const completed = [0, 1, 2, 5];
    const completed = Complete_steps;
    // const pending = [3, 4];
    const pending: number[] | undefined = []


    const handleSteperOpen = () => {
        console.log("Steper open");
        if (OpenStatusStepper) {
            setOpenStatusStepper(false);
        } else {
            setOpenStatusStepper(true);
        }

    }

    return (
        <>
            <Box sx={{
                display: "flex",
                // flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "end",
            }}>
                <CustomSquareStepper steps={steps} activeStep={activeStep} completed={completed} pending={pending} handleAction={handleSteperOpen} />

            </Box>
            {/* {OpenStatusStepper && (
                <Stack spacing={6} sx={{ mt: 4 }}>

                    <CustomStatusStepper activeStep={activeStep} steps={Main_steps} errorSteps={error_steps} CompleteSteps={Complete_steps} handleAction={handleSteperClose} /> 

                </Stack>
            )} */}

            <CustomDialog
                show={OpenStatusStepper}
                onHide={() => {
                    setOpenStatusStepper(false);
                    updateWorkFlowHistoryView(false);
                }}
                maxWidth={"lg"}
                hideDivider={false}
                header={isEditData.Vendor_Name !== "" ? `${isEditData.Vendor_Name} History` : "Work Flow History"}
                contentNode={
                    <>
                        {" "}
                        <WorkFlowHistorySteper
                            view={false}
                            isEditData={isEditData}
                            closePopUp={() => {
                                setOpenStatusStepper(false);
                                updateWorkFlowHistoryView(false);
                            }}

                        />

                    </>
                }
            />
        </>
    )

}