import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { IMenu } from "../../../models/loggin.type";
import { AddIndustry } from "./AddIndustry";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { RootState, useAppDispatch } from "../../../store/store";
import { deleteIndustry, getIndustryHistory, getIndustryList, updateIndustryAddandEdit, updateVersionHistory } from "../../../store/slices/industryMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { Edit_Enable_tosterMSG, IndustryConfig, LovConfig, ModuleNames } from "../../../config/config";
import { getLovList } from "../../../store/slices/lovMasterData";
import { GetMasterEditEnable } from "../../../store/slices/masterEditEnableData";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { getNotifications } from "../../../store/slices/notification";
import { CustomSquareStepper } from "../../../common/dynamicSteper/CustomSquareStepper";
import { CustomStatusStepper } from "../../../common/dynamicSteper/CustomStatusStepper";
import HolidayMaster from "../holidayMaster/HolidayMaster";



export default function Industry() {

    const dispatch = useAppDispatch();
    const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
    const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [formDisplay, setformDisplay] = useState(false);
    const [filterTableData, setFilterTableData] = useState<any[]>([]);
    const [IndustryData, setIndustryData] = useState<any[]>([]);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [isVersionHistory, setIsVersionHistory] = useState(false);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);
    const [LableName, setLableName] = useState<any>('Industry');
    const [versionHistoryData, setVersionHistoryData] = useState<any>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [getRecordLock, setGetRecordLock] = useState<any>([]);
    const [IndustryTypelov, setIndustryTypelov] = useState<any[]>([]);
    const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
    const { EditEnableData } = useSelector((state: RootState) => state.MasterEditEnableData);


    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );

    const { lovTable, islovAdded } = useSelector(
        (state: RootState) => state.lovMasterData
    );


    const {
        industryTable,
        isindustryAdded,
        versionHistory,
        isindustryAddedFailed,
        isindustryDeleted,
        isindustryDeletedFailed,
        ToasterMsg,
        isLoading,
    } = useSelector((state: RootState) => state.indutryMasterData);


    const columns_version: any = {
        table_name: "Industry History Records",
        isAction: false,
        columns: [],
    };


    const columns: any = {
        table_name: "Industry",
        isAction: true,
        columns: [

            // {
            //     Field: "IndustryId",
            //     DisplayName: "Industry Id",
            //     FieldType: "string",
            // },
            {
                Field: "IndustryName",
                DisplayName: "Industry Name",
                FieldType: "string",
            },
            {
                Field: "IndustryTypeValues",
                DisplayName: "Type",
                FieldType: "string",
            },
            {
                Field: "IndustryParentName",
                DisplayName: "Parent",
                FieldType: "string",
            },
            {
                Field: "Parent_LevelValues",
                DisplayName: "Level",
                FieldType: "string",
            },
            {
                Field: "IsActive",
                DisplayName: "Status",
                FieldType: "string",
            },
            {
                Field: "UpdatedBy",
                DisplayName: "Modified By",
                FieldType: "string",
            },

        ],
    };

    useEffect(() => {

        setIndustryTypelov(lovTable.filter((item: any) => item.Name === LovConfig.Industry_Type) || []);

    }, [lovTable]);


    useEffect(() => {
        dispatch(
            getIndustryList({
                FormID: 2,
            })
        );
        dispatch(getLovList({ Name: "" }));
    }, []);

    useEffect(() => {
        console.log(industryTable, "dataTable");

        setIndustryData(industryTable);
    }, [industryTable]);


    useEffect(() => {

        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData)
        }
    }, [currentPagePermissionData]);

    useEffect(() => {
        if (versionHistory.industryVHeader.length > 0 && versionHistory.industryVHeader.length > 0) {
            if (versionHistory.industryVHeader && versionHistory.industryVHeader.length > 0) {
                setIsVersionHistory(true);
                let re_columns = {
                    ...columns_version,
                    columns: versionHistory.industryVHeader,
                };
                setVersionHistoryData(re_columns);
                console.log(versionHistory, "versionHistory ");

            }
        }
    }, [versionHistory]);

    const recordLockGet = (id: any) => {
        const recordLockData = {
            RecordId: id,
            MenuId: permssionPage?.MenuId,
            ModuleName: "Industry",
        };
        return recordLockData;
    };

    const handleAddRecordLock = (id: number) => {
        const recordLockData = {
            RecordId: id,
            UserId: userLoginResponse?.UserId || 0,
            CreatedBy: userLoginResponse?.UserName || "",
            MenuId: permssionPage?.MenuId,
            ModuleName: "Industry",
            Activity: "edit",
        };
        return recordLockData;
    };
    const handleRecordLockCheck = async (recordId: number) => {
        const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
        if (GetRecordLock.fulfilled.match(resultAction)) {
            const recordLockData = resultAction.payload;
            if (recordLockData && recordLockData.length > 0) {
                setIsEditing(true);
                dispatch(updateLoader(false));
                console.log(recordLock, "recordLock");
                dispatch(
                    updateToaster({
                        isToaster: true,
                        toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
                        isTosterFailed: true,
                    })
                );
                return false;
            }
        }
        return true;
    };
    const recordLockUpdate = () => {
        if (recordLock && recordLock.length > 0) {
            return [
                {
                    RecordlockId: recordLock[0].RecordlockId,
                    RecordId: recordLock[0].RecordId,
                    IsActive: false,
                },
            ];
        }
        return [];
    };
    useEffect(() => {
        if (!isEdit) {
            const data = recordLockUpdate();
            if (data.length > 0) {
                dispatch(UpdateRecordLock(data.pop()));
            }
        }
    }, [isEdit]);


    const handleOpen = async (data: any, Option: string) => {
        if (Option === "edit") {
            setOption(Option);
            setIsEditData(data);
            dispatch(updateLoader(true));

            const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.IndustryId, FormID: 8 });
            const Edit_data: any = response;
            console.log(Edit_data?.data, "Edit_data");

            const canEdit = await handleRecordLockCheck(data?.IndustryId);
            if (Edit_data?.data === true) {
                if (canEdit) {
                    const dispatchData = handleAddRecordLock(data?.IndustryId);
                    await dispatch(AddRecordLock(dispatchData));
                    //setIsEditOpen(true);
                    const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.IndustryId)));
                    if (GetRecordLock.fulfilled.match(resultAction)) {
                        const recordLockData = resultAction.payload;
                        console.log(recordLockData, resultAction, "recordLockData");
                        setGetRecordLock(recordLockData);
                    }
                    dispatch(updateLoader(false));
                    setIsEdit(true);
                    // Set timeout to close the popup after 2.5 minutes
                    //   setTimeout(() => {
                    //     setIsEdit(false);
                    //     const data = recordLockUpdate();
                    //     if (data.length > 0) {
                    //       dispatch(UpdateRecordLock(data.pop()));
                    //     }
                    //   }, 150000);
                }
            } else {

                dispatch(updateLoader(false));
                dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

            }


        } else if (Option === "view") {
            console.log(data, Option, "Option");

            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);
        } else if (Option === "history") {

            setOption(Option);
            dispatch(
                getIndustryHistory({
                    IndustryId: data.IndustryId,
                    formID: 8,
                    // Approved_By: ,
                })
            );
            // setIsVersionHistory(true);

        } else if (Option === "delete") {

            const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.IndustryId, FormID: 8 });
            const Edit_data: any = response;

            if (Edit_data?.data === true) {
                setOption(Option);
                setIsDeleteData(data);
            } else {
                dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));
            }
        }

    };

    useEffect(() => {
        if (isindustryAdded) {
            dispatch(updateIndustryAddandEdit(false));
            setLableName('Industry')
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
            dispatch(
                getIndustryList({
                    FormID: 2,
                })
            );
            if (userLoginResponse) {
                dispatch(getNotifications(userLoginResponse?.UserId));
            }
        }
        else if (isindustryAddedFailed) {
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg }));

            dispatch(updateIndustryAddandEdit(false));
            dispatch(
                getIndustryList({
                    FormID: 2,
                })
            );
        }
        else if (isindustryDeleted) {
            setIsDeleteData(null);
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
            setTimeout(() => {
                dispatch(updateIndustryAddandEdit(false));
            }, 1000);
            dispatch(
                getIndustryList({
                    FormID: 2,
                })
            );
        }
        else if (isindustryDeletedFailed) {
            dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));

            dispatch(updateIndustryAddandEdit(false));
            dispatch(
                getIndustryList({
                    FormID: 2,
                })
            );
        }
    }, [isindustryAdded, isindustryDeleted, isindustryAddedFailed, isindustryDeletedFailed]);
    console.log(permssionPage, "permssionPage");


    const deleteDepartmentList = () => {
        console.log(isDeleteData);

        let finededIndType = IndustryTypelov.find(
            (item) => item.Options === IndustryConfig?.Industry && isDeleteData.IndustryType == item.LovId
        );
        let JsonDataForDisplay: any = {
            "Industry Name": isDeleteData.IndustryName,
            "Industry Type": isDeleteData.IndustryTypeValues,
            "Parent": isDeleteData.IndustryParentName,
            Level: isDeleteData.Parent_LevelValues,
            "Status": isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
            "Modified By": user?.UserName,
        }

        let formData = {
            Is_Active: isDeleteData.IsActive === "Active" ? false : true,
            Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
            IndustryId: isDeleteData.IndustryId,
            IndustryName: isDeleteData.IndustryName,
            UserId: userLoginResponse?.UserId,
            MenuId: currentPagePermissionData?.MenuId,
            Form_Id: 8,
            ModuleName: finededIndType ? ModuleNames.IndustryMaster : "Sub Industry Master",
            CreatedBy: userLoginResponse?.UserName,
            DataForDisplay: JSON.stringify(JsonDataForDisplay),
        };
        dispatch(
            deleteIndustry((formData))
        );
    };

    useEffect(() => {
        const handleTabClose = async (event: BeforeUnloadEvent) => {
            const data = recordLockUpdate();
            console.log(data, "dataLock");
            if (data.length > 0) {
                event.preventDefault();
                event.returnValue = "";
                try {
                    await dispatch(UpdateRecordLock(data.pop())).unwrap();
                } catch (error) {
                    console.error("Failed to update record lock:", error);
                }
            }
        };

        window.addEventListener("beforeunload", handleTabClose);
        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    // backgroundColor: "#ffff",
                    // backgroundColor: "background.default",
                    // px: 3,
                    // py: 4,
                    // borderRadius: "10px",
                }}
            >
                <Stack spacing={2}>

                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {/* <Box sx={{ display: "flex", width: "25%", }} >
                            <CustomSelect
                                name={"listName"}
                                valueKey={"id"}
                                optionLabelKey="lable"
                                options={location_Data}
                                value={formData.listName}
                                onChange={handleChange}
                                required={true}
                                label={"Select List Name"}
                            />
                        </Box> */}
                        <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            {currentPagePermissionData?.MenuName}
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>

                        </Box>

                    </Box>

                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <MaterialDynamicGrid
                                data={columns}
                                rows={IndustryData}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={true}
                                showhistory={true}
                                showDelete={permssionPage?.IsDelete === 1}
                                showView={permssionPage?.IsView === 1}
                                showEdit={permssionPage?.IsEdit === 1}
                                showExports={permssionPage?.IsExport === 1}
                                pageName="Industry"
                                userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0} />
                        </Box>

                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={addForm}
                onHide={() => { setAddForm(false); setLableName('Industry') }}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={`Add ${LableName}`}
                contentNode={
                    // <></>
                    <AddIndustry
                        view={false}
                        closeIndustryPopUp={() => {
                            setAddForm(false);
                            setLableName('Industry')
                        }}
                        onchangelable={(value: any) => setLableName(value)}
                    />
                }
            />

            <CustomDialog
                show={isEdit}
                onHide={() => {
                    // if (Option === "history") {
                    //   dispatch(updateVersionHistory([]));
                    // }
                    setIsEdit(false);
                    setLableName('Industry')
                }}
                maxWidth={"xs"}
                minHeight={"50vh"}
                header={Option === "view" ? `View ${LableName}` : `Update ${LableName}`}
                contentNode={
                    <>
                        {Option === "edit" && (
                            <AddIndustry
                                view={false}
                                closeIndustryPopUp={() => {
                                    setIsEdit(false);
                                    setLableName('Industry')
                                }}
                                edit={isEdit}
                                onchangelable={(value: any) => setLableName(value)}
                                isEditData={IsEditData}
                            />
                            // <></>
                        )}
                        {Option === "view" && (
                            <AddIndustry
                                view={true}
                                closeIndustryPopUp={() => {
                                    setIsEdit(false);
                                    setLableName('Industry')
                                }}
                                isEditData={IsEditData}
                                onchangelable={(value: any) => setLableName(value)}
                            />
                            // <></>
                        )}

                    </>
                }
            />

            <CustomDialog
                show={isVersionHistory}
                onHide={() => {
                    setIsVersionHistory(false);
                    dispatch(updateVersionHistory([]));
                }}
                maxWidth={"lg"}
                header={"Industry Version History"}
                contentNode={
                    <> <MaterialDynamicGrid
                        data={versionHistoryData}
                        rows={versionHistory.industryRecords}
                        handleAction={handleOpen}
                        enableZoomIcon={false}
                        showhistory={false}
                        showDelete={permssionPage?.IsDelete === 1}
                        showView={permssionPage?.IsView === 1}
                        showEdit={permssionPage?.IsEdit === 1}
                        showExports={permssionPage?.IsExport === 1}
                        userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
                        pageName="Industry Version History"
                    />
                    </>
                }
            />

            <ModalPopUp
                onDeleteMethod={() => deleteDepartmentList()}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm"}
                text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Industry`}
                ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}

            />
        </>
    );

}
