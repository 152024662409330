import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IPageSettingResponse } from "../../models/pageSettings.type";
import { Page_Settings } from "./endpoints/pageSettings";

const addPageSetting = (requestBody: any): Promise<IPageSettingResponse> =>
  axiosInstance
    .post(Page_Settings.AddSettings, requestBody)
    .then((response: AxiosResponse<IPageSettingResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getPageSetting = (requestBody: any): Promise<IPageSettingResponse> =>
  axiosInstance
    .post(Page_Settings.GETPageSettings, requestBody)
    .then((response: AxiosResponse<IPageSettingResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteSaveFilter = (requestBody: any): Promise<IPageSettingResponse> =>
  axiosInstance
    .get(Page_Settings.DeleteSettings, {params: requestBody})
    .then((response: AxiosResponse<IPageSettingResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const pageSettingSerivce = {
  addPageSetting,
  getPageSetting,
  deleteSaveFilter,
};

export default pageSettingSerivce;
