import React, { useEffect, useRef, useState } from "react";
import { Box, Collapse, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { useAppDispatchThunk, RootState } from "../../../store/store";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ReviewClauses from "./ReviewClauses";
import SearchServices from "../SearchService/SearchServices";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import CustomFileUploader from "../../../common/dynamicFileUploader/CustomFileUploader";
import AddClause from "../ClauseLibrary/AddClause";
import {
  createTemplate,
  getContractType,
  getTemplateList,
  updateTemplate,
  updateAddandEdit,
  savePreviewTemplate,
  deletePreviewTemplateData,
} from "../../../store/contractManagement/slices/templateData";
import clauseService from "../../../store/contractManagement/services/clauseService";
import AddExistingClause from "./AddExistingClause";

import { useSelector } from "react-redux";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import OnlyOfficeTextEditor from "../../../common/textEditor/OnlyOfficeTextEditor";
import { deleteDocument } from "../../../store/slices/textEditorSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { asBlob } from "html-docx-js-typescript";
import { generateClauseHTML } from "../../../common/textEditor/generateClauseHTML";
import { setEditorDocsId } from "../../../store/slices/docsIdData";
import PluginStepper from "../../../common/textEditor/plugin/PluginStepper/PluginStepper";
import { updateHeaderMenu, updateMenu } from "../../../store/slices/menu";

export type ContractClause = {
  clauseType: string;
  clauseHeader: string;
  service: string;
  sme: string;
  order: number;
  clause_Id: string;
};

interface contractData {
  contract_Type_Id: number;
  contract_Template_Name: string;
  ctServiceMappings: number[];
  ctClauseMappings: number[];
  contract_Template_Id: number;
}

export interface ContractTemplateProps {
  view?: boolean;
  edit?: boolean;
  contractData?: contractData;
  onClose?: () => void;
}

const ContractTemplate = ({ view, edit, contractData, onClose }: ContractTemplateProps) => {
  // const [contractTypeOptions, setContractTypeOptions] = useState<{ id: string; name: string }[]>([]);
  const [expanded, setExpanded] = useState(true);
  const [step, setStep] = useState<number>(1);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [serviceLoaded, setServiceLoaded] = useState(false);
  const [clauses, setClauses] = useState<ContractClause[]>([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [showAddexistingClause, setAddExistingClause] = useState(false);
  const [showAddClause, setShowAddClause] = useState(false);
  const [showPreviewContract, setPreviewContract] = useState(false);
  const {
    isLoading,
    isTemplateAdded,
    isTemplateUpdated,
    ToasterMsg,
    templateToasterStatus,
    contractType: contractTypeOptions,
    previewId,
  } = useSelector((state: any) => state.contractManagementReducer?.templateData);
  const dispatch = useAppDispatchThunk();
  const { userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
  const { documentData } = useSelector((state: RootState) => contractManagementReducer.textEditor);
  const location = useLocation();
  const navigate = useNavigate();

  const tempData: any = useRef(null);

  const breadcrumb = [
    { value: 0, name: "Contract Management", link: "/contract-templates" },
    { value: 1, name: edit ? "Edit" : view ? "View" : "Add New" },
  ];

  let formData = {
    ContractType: edit || view ? contractData?.contract_Type_Id : "",
    TemplateName: edit || view ? contractData?.contract_Template_Name : "",
  };

  useEffect(() => {
    dispatch(getContractType());
    if (edit || view) {
      setStep(3);
      const serviceCategories = contractData?.ctServiceMappings.map((service: any) => service.serviceCatalog_Id);
      setSelectedServices(serviceCategories || []);
      setServiceLoaded(true);
      if (serviceCategories) {
        const clauseIds = contractData?.ctClauseMappings.map((clause: any) => clause.clause_Id);
        handleLoadContract(serviceCategories, clauseIds);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(updateLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (isTemplateAdded || isTemplateUpdated) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg, isTosterFailed: templateToasterStatus }));
      if (templateToasterStatus) {
        dispatch(updateAddandEdit(false));
      } else {
        dispatch(getTemplateList());
        handleReset();
      }
    }
  }, [isTemplateAdded, isTemplateUpdated]);

  useEffect(() => {
    if (previewId) {
      setPreviewContract(true);
    }
  }, [previewId]);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors: errorsloginform },
    control,
    watch,
    getValues,
  } = useForm<any>({
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {
    if (location.state?.formData) {
      const tempClause = location.state.clauses || [];
      if (location.state.clauseData) {
        tempClause.push({
          ...location.state.clauseData,
          order: tempClause.length + 1,
          serviceMappings: location.state.clauseData.serivceMappings,
        });
      }
      reset(location.state.formData);
      setSelectedServices(location.state.selectedServices);
      // setClauses(tempClause);
      setStep(3);
      setServiceLoaded(true);
    }
  }, [location.state, reset]);
  // console.log(selectedServices, "selectedService");

  const handleGenerateTemplate = () => {
    if (step >= 2) {
      if (watch("ContractType") === tempData.current) {
        return;
      } else {
        setStep(2);
        setServiceLoaded(false);
        setClauses([]);
        setSelectedServices([]);
      }
    } else setStep(2);
  };

  const handleLoadContract = async (services = selectedServices, filterClause: number[] = []) => {
    // console.log(selectedServices, "selectedServicesselectedServices");
    dispatch(updateLoader(true));
    try {
      const data = await clauseService.getClauseByService(services);
      let clauseList = data.clauseList;
      if (filterClause.length) {
        clauseList = clauseList.filter((clause: any) => filterClause.includes(clause.clause_Id));
      }
      dispatch(updateLoader(false));
      setClauses(clauseList?.map((clause: any, index: number) => ({ ...clause, order: index + 1 })));
      setStep(3);
    } catch (error) {
      dispatch(updateLoader(false));
    }
  };

  const handleReset = () => {
    setStep(1);
    reset(formData);
    setSelectedServices([]);
    setClauses([]);
    dispatch(updateAddandEdit(false));
    onClose && onClose();
    if (location?.pathname?.includes("/addTemplate")) {
      navigate("/contract-templates");
    }
  };

  const updateServices = (updatedservices: any[]) => {
    if (!view) {
      setSelectedServices(updatedservices);
    }
  };
  // console.log(edit, "Edit Template");
  const handleSaveTemplate = () => {
    const formData = getValues();
    const reqPayload: any = {
      contractTemplateName: formData.TemplateName,
      contractTypeId: formData.ContractType,
      serviceCategories: selectedServices,
      clauses: clauses.map((clause) => clause.clause_Id),
      userId: userLoginResponse?.UserId,
      moduleName: "Contract Template",
      activity: edit ? "Edit" : "Create",
      createdBy: userLoginResponse?.UserName      
    };
    if (edit) {
      reqPayload.contractTemplateId = contractData?.contract_Template_Id;
      dispatch(updateTemplate(reqPayload));
      return;
    }
    dispatch(createTemplate(reqPayload));
  };

  const handleAddNewClause = (clause: any) => {
    setClauses([clause, ...clauses].map((e: any, index: number) => ({ ...e, order: index + 1 })));
    setShowAddClause(false);
  };

  const handleAddClause = (data: any) => {
    const clauseIds = clauses.map((clause: any) => clause.clause_Id);
    const addedClauses = data.filter((newClause: any) => !clauseIds.includes(newClause.clause_Id));
    setClauses([...addedClauses, ...clauses].map((e: any, index: number) => ({ ...e, order: index + 1 })));
    setAddExistingClause(false);
  };

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader: any = new FileReader();
      reader.onloadend = () => resolve(reader.result?.split("base64,")[1]);
      reader.readAsDataURL(blob);
    });
  }

  const [clauseData, setClauseData] = useState<any>();
  const [serviceCategories, setServiceCategories] = useState<any>();
  const [clauseIds, setClauseIds] = useState<any>();
  useEffect(() => {
    if (contractData) {
      setServiceCategories(contractData?.ctServiceMappings.map((service: any) => service.serviceCatalog_Id));
      setClauseIds(contractData?.ctClauseMappings.map((clause: any) => clause.clause_Id));
    }
  }, [contractData]);

  const formatPreviewContract = async () => {
    const handleLoadContract = async (services: any, filterClause: number[] = []) => {
      dispatch(updateLoader(true));
      try {
        setClauseData({ serviceCategories: services, clauseIds: filterClause });
      } catch (error) {
        dispatch(updateLoader(false));
        console.error("Error loading contract:", error);
      } finally {
        dispatch(updateLoader(false));
      }
    };

    handleLoadContract(serviceCategories, clauseIds);
    dispatch(setEditorDocsId(contractData?.contract_Template_Id));

    let clauseDataUpdate = `<div>${generateClauseHTML(clauses)}</div>`;
    localStorage.setItem("ClauseData", JSON.stringify({ SMEReviewMapping: clauses, TaggingRisk: clauses }));
    //Blob Conversion
    const data = await asBlob(clauseDataUpdate);
    const base64Content = await blobToBase64(data);
    //API Call
    dispatch(savePreviewTemplate({ text: base64Content, updated_By: userLoginResponse?.UserName }));
    setPreviewContract(true);
    dispatch(updateMenu(false));
    dispatch(updateHeaderMenu(false));
  };

  // console.log(window, "window~plugin~Data");
  localStorage.setItem("USER_ROLE_KEY", "SO");

  const handleDeletePreview = async (fileName: any) => {
    if (!fileName) return;

    const req = {
      filename: fileName,
    };

    try {
      const response = await dispatch(deleteDocument(req)).unwrap();
      return true;
    } catch (error) {
      console.error("Config not found in response.");
    }
  };

  const handleClosePreview = () => {
    setPreviewContract(false);
    handleDeletePreview(documentData?.document?.title);
    dispatch(deletePreviewTemplateData(previewId));
    dispatch(updateMenu(true));
    dispatch(updateHeaderMenu(true));
  };

  const handleClickAddClause = () => {
    const formData = watch();
    navigate("/addClause", {
      state: {
        formData,
        clauses,
        selectedServices,
        targetPath: "/addTemplate",
      },
    });
  };
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  const dynamicFileName = `Preview_Contract_${getCurrentDateTime()}`;
  return (
    <Box>
      {!showPreviewContract && !previewId ? (
        <Box sx={{ width: "100%", px: 2 }}>
          <Box pt={2}>
            <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
              <CustomBreadcrumbs list={breadcrumb} />
            </Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography variant="h6" color={"text.primary"} fontWeight={700}>
                {`${edit ? "Edit" : view ? "View" : "Add New"} Contract Template`}
              </Typography>
            </Stack>
            <Divider sx={{ my: 4, position: "relative", right: "2rem", width: "104%" }} variant="fullWidth" />
          </Box>
          <Box>
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Controller
                    name="ContractType"
                    control={control}
                    rules={{
                      required: "Contract Type is Required",
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"ContractType"}
                        valueKey={"contractType_Id"}
                        optionLabelKey="contractType_Name"
                        options={contractTypeOptions}
                        required={true}
                        readOnly={view}
                        label={"Contract Type"}
                        value={field.value}
                        onChange={(e) => {
                          tempData.current = watch("ContractType");
                          field.onChange(e);
                        }}
                        error={Boolean(errorsloginform.ContractType)}
                        helperText={errorsloginform.ContractType && errorsloginform.ContractType.message?.toString()}
                        disabled={view}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Controller
                    name="TemplateName"
                    control={control}
                    defaultValue={"" as any}
                    rules={{
                      required: "Contract Template Name is Required",
                      validate: {
                        maxLength: (value) => {
                          if (value === undefined) return "Value is required";
                          return value.length <= 100 || "Maximum 100 characters allowed";
                        },
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        name={"TemplateName"}
                        label={"Contract Template Name"}
                        value={field.value}
                        onChange={field.onChange}
                        required={true}
                        error={Boolean(errorsloginform.TemplateName)}
                        helperText={errorsloginform.TemplateName && errorsloginform.TemplateName.message?.toString()}
                        disabled={view}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <CustomButton
                      variant="outlined"
                      name={"Upload Template"}
                      onClick={() => setOpenUploadModal(true)}
                      disabled={true}
                    />
                    <CustomButton
                      variant="contained"
                      disabled={watch("ContractType") === "" || watch("TemplateName") === "" || view}
                      name={"Generate Template"}
                      onClick={handleGenerateTemplate}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            {step > 1 && (
              <>
                <Divider sx={{ my: 4 }} />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="body1" color={"text.primary"} fontWeight={600}>
                    Service Mapping
                  </Typography>
                  <IconButton onClick={() => setExpanded(!expanded)} size="small">
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Stack>
                <Collapse in={expanded}>
                  <SearchServices
                    updateSelectedServices={updateServices}
                    selectedServices={selectedServices}
                    showToolTip={true}
                    loaderName="Load Services"
                    clickLoadFunction={() => {
                      setServiceLoaded(true);
                    }}
                    view={view}
                  />
                  <Stack direction="row" justifyContent="end">
                    {serviceLoaded && (
                      <CustomButton
                        variant="outlined"
                        color="secondary"
                        name={"Load Clauses"}
                        onClick={() => handleLoadContract()}
                        disabled={view}
                      />
                    )}
                  </Stack>
                </Collapse>
              </>
            )}
          </Box>
          {step > 2 && (
            <>
              <Divider sx={{ my: 4 }} />
              <Box>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body1" color={"text.primary"} fontWeight={600}>
                    Review Clauses
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <CustomButton
                      variant="outlined"
                      color="secondary"
                      name={"Create New Clause"}
                      onClick={() => handleClickAddClause()}
                      disabled={view}
                    />
                    <CustomButton
                      variant="outlined"
                      disabled={view}
                      color="secondary"
                      name={"Add Existing Clause"}
                      onClick={() => setAddExistingClause(true)}
                    />

                    <CustomButton
                      variant="outlined"
                      color="secondary"
                      // disabled={true}
                      name={"Preview Contract"}
                      onClick={() => formatPreviewContract()}
                    />
                  </Stack>
                </Stack>
                <Box sx={{ mt: 2, width: "100%" }}>
                  <ReviewClauses data={clauses} setData={setClauses} view={view} />
                </Box>
              </Box>
              <Stack direction="row" spacing={2} justifyContent="end" pt={2}>
                <CustomButton variant="outlined" name={"Discard"} onClick={handleReset} />
                {/* <CustomButton variant="outlined" disabled name={"Save Draft"} onClick={() => placeHolder()} /> */}
                {!view && (
                  <CustomButton
                    variant="contained"
                    disabled={clauses.length === 0}
                    name={edit ? "Update" : "Save"}
                    onClick={handleSaveTemplate}
                  />
                )}
              </Stack>
            </>
          )}
          {showAddClause && (
            <CustomDialog
              show={showAddClause}
              onHide={() => setShowAddClause(false)}
              maxWidth={"lg"}
              minHeight={"50vh"}
              header={`Create New Clause`}
              style={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    overflowY: "hidden",
                  },
                },
              }}
              contentNode={
                <>
                  <AddClause isDisableBreadCrums={true} onSaveClause={handleAddNewClause} />
                </>
              }
            />
          )}
          {openUploadModal && (
            <CustomDialog
              show={openUploadModal}
              onHide={() => setOpenUploadModal(false)}
              maxWidth={"lg"}
              minHeight={"50vh"}
              header={"Upload Contract Template"}
              style={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    overflowY: "hidden",
                  },
                },
              }}
              contentNode={
                <CustomFileUploader
                  acceptFormats={[".csv"]}
                  maxFileSize={1000000}
                  onFileUpload={() => {}}
                  title="Contract Template"
                />
              }
            />
          )}
          {showAddexistingClause && (
            <CustomDialog
              show={showAddexistingClause}
              onHide={() => setAddExistingClause(false)}
              maxWidth={"lg"}
              minHeight={"50vh"}
              header={"Add existing clause"}
              style={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    overflowY: "hidden",
                  },
                },
              }}
              contentNode={<AddExistingClause onClose={() => setAddExistingClause(false)} onAdd={handleAddClause} />}
            />
          )}
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <CustomButton name="Back" variant="outlined" onClick={handleClosePreview} />
          </Box>
          {/* {showPreviewContract && (
        <CustomDialog
          show={showPreviewContract && previewId}
          onHide={handleClosePreview}
          maxWidth={"lg"}
          minHeight={"50vh"}
          header={"Preview Contract"}
          style={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                overflowY: "hidden",
              },
            },
          }}
          contentNode={
            <>
            </>
            }
            />
            )} */}
          {showPreviewContract && previewId && (
            <OnlyOfficeTextEditor templateId={previewId} fileName={dynamicFileName} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ContractTemplate;
