import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRiskRatingMaster } from "./AddRiskRatingMaster";
import { deleteRiskRate, getRiskRateHistory, getRiskRateList, updateRiskRateAddandEdit, updateVersionHistory } from "../../../store/slices/riskRateMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { Edit_Enable_tosterMSG } from "../../../config/config";
import masterEditEnableService from "../../../store/services/masterEditEnableService";
import { getNotifications } from "../../../store/slices/notification";

export default function RiskRatingMaster() {
  const dispatch = useAppDispatch();
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [RiskFormData, setRiskFormData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isDeleteData, setIsDeleteData] = useState<any>(null);

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );

  const { RiskRateData,
    isLoading,
    isRiskRateAdded,
    versionHistory,
    isRiskRatetHistory,
    isRiskRateDeleted,
    ToasterMsg
  } = useSelector(
    (state: RootState) => state.RiskRateData
  );

  const columns_version: any = {
    table_name: "Risk History Records",
    isAction: false,
    columns: [],
  };

  useEffect(() => {
    setRiskFormData(RiskRateData);
  }, [RiskRateData]);

  const columns: any = {
    table_name: "Risk Rating",
    isAction: true,
    columns: [

      {
        Field: "RiskCategory",
        DisplayName: "Risk Category",
        FieldType: "string",
      },
      {
        Field: "Weightage",
        DisplayName: "Weightage",
        FieldType: "number",
      },
      {
        Field: "IsActive",
        DisplayName: "Status",
        FieldType: "string",
      },
      {
        Field: "Updated_By",
        DisplayName: "Modified By",
        FieldType: "string",
      },
    ],
  };
  useEffect(() => {
    callMasterData()
  }, []);

  const callMasterData = () => {
    dispatch(
      getRiskRateList({
        FormID: 2,
      })
    );
  }

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    if (versionHistory.RiskRateVHeader.length > 0 && versionHistory.RiskRateVHeader.length > 0) {
      if (versionHistory.RiskRateVHeader && versionHistory.RiskRateVHeader.length > 0) {
        setIsVersionHistory(true);
        let re_columns = {
          ...columns_version,
          columns: versionHistory.RiskRateVHeader,
        };
        setVersionHistoryData(re_columns);
        console.log(versionHistory, re_columns, "versionHistory ");
      }
    }
  }, [versionHistory]);

  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {
      console.log(data, Option, "Option");
      dispatch(updateLoader(true));
      setOption(Option);
      setIsEditData(data);

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.RiskCategory_Id, FormID: 24 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {

        dispatch(updateLoader(false));
        setIsEdit(true);

      } else {

        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }

      // setUserId(data.UserId);
    } else if (Option === "view") {
      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      setOption(Option);
      // setIsVersionHistory(true);
      dispatch(
        getRiskRateHistory({
          riskCategory_Id: data.RiskCategory_Id,
          formID: 24,
          // Approved_By: ,
        })
      );
    } else if (Option === "delete") {
      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.RiskCategory_Id, FormID: 24 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        setOption(Option);
        setIsDeleteData(data);
      } else {

        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }
    }
  };

  useEffect(() => {
    if (isRiskRateAdded) {
      dispatch(updateRiskRateAddandEdit(false));
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));
      callMasterData();
      if (userLoginResponse) {
        dispatch(getNotifications(userLoginResponse?.UserId));
      }
    } else if (isRiskRateDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: ToasterMsg.Message, isTosterFailed: ToasterMsg.Status === "false" ? true : false }));

      dispatch(updateRiskRateAddandEdit(false));

      callMasterData();
    }
  }, [isRiskRateAdded, isRiskRateDeleted]);

  const deleteRiskList = () => {
    console.log(isDeleteData);

    let JsonDataForDisplay: any = {
      "Risk Category": isDeleteData.RiskCategory,
      "Weightage" : isDeleteData.Weightage,
      "Status": isDeleteData.IsActive === "Active" ? "Inactive" : "Active",
      "Modified By": user?.UserName,
    }

    let formData: any = {};

    formData.UserId = userLoginResponse?.UserId;
    formData.RiskCategory_Id = isDeleteData?.RiskCategory_Id;
    formData.RiskCategory = isDeleteData?.RiskCategory;
    formData.IsActive = isDeleteData?.IsActive === "Active" ? false : true;
    formData.Activity = isDeleteData?.IsActive === "Active" ? "inactive" : "active";
    // formData.MenuId = currentPagePermissionData?.MenuId;
    formData.Form_Id = 24;
    formData.ModuleName = currentPagePermissionData?.MenuName;
    formData.Updated_By = userLoginResponse?.UserName;
    formData.DataForDisplay = JSON.stringify(JsonDataForDisplay);

    console.log(formData);

    dispatch(
      deleteRiskRate((formData))
    );
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              {currentPagePermissionData?.MenuName}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={RiskFormData}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={true}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Risk Rating"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={"Add Risk Rating"}
        contentNode={
          // <></>
          <AddRiskRatingMaster
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"xs"}
        minHeight={"50vh"}
        header={Option === "view" ? "View Risk Rating" : "Update Risk Rating"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddRiskRatingMaster
                view={false}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddRiskRatingMaster
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
              // <></>
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          dispatch(updateVersionHistory([]));
        }}
        maxWidth={"lg"}
        header={"Risk Category Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.RiskRateRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              pageName="Risk History Records"
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
            />
          </>
        }
      />
      <ModalPopUp
        onDeleteMethod={() => deleteRiskList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"
          } the selected Risk Category?`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}
      />
    </>
  );
}
