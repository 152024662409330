import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import VendorSignOffService from "../services/vendorSignOffService";
export interface IVendorSignOffData {
  VendorSignOffdataTable: any;
  isVendorSignOffLoading: boolean;
  isAddEditVendorSignOffLoading: boolean;
  isVendorSignOffAdded: boolean;
  VendorSignOffToasterMsg: string;
}

export const initialState: IVendorSignOffData = {
  VendorSignOffdataTable: [],
  isVendorSignOffLoading: false,
  isAddEditVendorSignOffLoading: false,
  isVendorSignOffAdded: false,
  VendorSignOffToasterMsg: "",
};

export const getVendorSignOffbyId = createAsyncThunk(
  "getVendorSignOffbyId ",
  async (requesrParams: { vendor_Id: any , project_Detail_Id : any }, { rejectWithValue }) => {
    try {
      const response = await VendorSignOffService.getVendorList (requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addVendorSignOff = createAsyncThunk("addVendorSignOff", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response: any = await VendorSignOffService.addVendor(requesrParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});



export const vendorSignOffDataSlice = createSlice({
  name: "vendorSignOff",
  initialState,
  reducers: {

    updateVendorSignOff:  (state, action)=>{            
      state.isVendorSignOffAdded = false ;
    
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendorSignOffbyId .pending, (state) => {
        state.isVendorSignOffLoading = true;
      })
      .addCase(getVendorSignOffbyId .fulfilled, (state, action: PayloadAction<any>) => {
        state.VendorSignOffdataTable = action.payload
        state.isVendorSignOffLoading = false;
      })
      .addCase(getVendorSignOffbyId .rejected, (state, action: PayloadAction<any>) => {
        state.isVendorSignOffLoading = false;
      })
      .addCase(addVendorSignOff.pending, (state) => {
        state.isVendorSignOffLoading = true;
        state.isAddEditVendorSignOffLoading = true;
      })
      .addCase(addVendorSignOff.fulfilled, (state, action: PayloadAction<any>) => {
        // state.VendorSignOffdataTable = [...state.VendorSignOffdataTable , ...action.payload  ]
        state.isVendorSignOffAdded = true;
        state.isVendorSignOffLoading = false;
        state.isAddEditVendorSignOffLoading = false;
        state.VendorSignOffToasterMsg = action.payload.Message;
      })
      .addCase(addVendorSignOff.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorSignOffLoading = false;
        state.isAddEditVendorSignOffLoading = false;
      })
      

  },
});

export const { updateVendorSignOff } = vendorSignOffDataSlice.actions;
export default vendorSignOffDataSlice.reducer;
