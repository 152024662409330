import { createSlice } from "@reduxjs/toolkit";

export interface TabDetails {
  active: number;
}

export const initialState: TabDetails = {
  active: 0,
};

export const tabsSlice = createSlice({
  name: "Activetab",
  initialState,
  reducers: {
    setTabs: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setTabs } = tabsSlice.actions;
export default tabsSlice.reducer;
