import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { TOASTER_URL } from "./endpoints/Toaster";
import { IAddToasterResponse,  IToasterResponse } from "../../models/authorize.type";
import { axiosInstance } from "./config/axiosConfig";


const getToaster = (): Promise<IToasterResponse> =>
  axiosInstance
    .get(TOASTER_URL.GetToaster)
    .then((response: AxiosResponse<IToasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    }); 


const addToaster = (requestBody: any): Promise<IAddToasterResponse> =>
    
  axiosInstance
    .post(TOASTER_URL.AddToaster, requestBody)
    .then((response: AxiosResponse<IAddToasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateToaster = (requestBody: any): Promise<IAddToasterResponse> =>
  axiosInstance
    .post(TOASTER_URL.UpdateToaster, requestBody)
    .then((response: AxiosResponse<IAddToasterResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

    const deleteToaster = (requestBody: any): Promise<IAddToasterResponse> =>
      axiosInstance
        .post(TOASTER_URL.deleteToaster, requestBody)
        .then((response: AxiosResponse<IAddToasterResponse>) => {
          if (response.status === 200) {
            return response.data;
          }
          throw new Error("Error getting Data" + response);
        })
        .catch((error: AxiosError | string) => {
          throw error;
        });
    

const emailTemplateService = {
    getToaster,
    addToaster,
    updateToaster,
    deleteToaster
};

export default emailTemplateService;
