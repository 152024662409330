import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import { AddVendorConsentForm } from "../../consentForms/AddVendorConsentForm";
import { AddContentMaster } from "./AddContentMaster";
import { VendorConsentWithdrawalForm } from "../../vendorWithdrawal/VendorConsentWithdrawalForm";
import { deletecontentMaster , getContentForm , getContentHistory, getContentList, updateContentAddandEdit, updateVersionHistory } from "../../../store/slices/contentMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import { ContentNameConfig, Edit_Enable_tosterMSG } from "../../../config/config";
import masterEditEnableService from "../../../store/services/masterEditEnableService";

let columns: any = {
  table_name: "Content",
  isAction: true,
  columns: [
    {
      Field: "Content_Purpose",
      FieldType: "string",
      DisplayName: "Content Purpose",
    },

    {
      Field: "Content_Text",
      FieldType: "string",
      DisplayName: "Content Text",
    },
    {
      Field: "IsActive",
      FieldType: "string",
      DisplayName: "Status",
    },
    {
      Field: "Updated_By",
      DisplayName: "Modified By",
      FieldType: "string",
    },
  ],
};

const ContentMaster = () => {
  const dispatch = useAppDispatch();

  const columns_version: any = {
    table_name: "Content Record",
    isAction: false,
    columns: [],
  };

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector((state: RootState) => state.loggerData);
  const { vendorConentPurposes, vendorConentDataTable, vendorConsentToasterMsg, versionHistory, isVendorConsentLoading, isVendorConsentAdded, isVendorConsentUpdated, isVendorConsentDeleted } = useSelector((state: RootState) => state.contentData);
  const [HeaderColumn, setHeaderColumn] = useState(columns);
  const [HistoryColumn, setHistoryColumn] = useState([]);
  const [vendorConsentData, setVendorConsentData] = useState<any[]>([]);
  const [isEditDate, setIsEditDate] = useState<{ id?: string }>({});
  const [isEdit, setIsEdit] = useState(false);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [Option, setOption] = useState("");
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [addForm, setAddForm] = useState<any>(false);
  const [permssionPage, setPermissionForpage] = useState<any>();
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditDataForm, setisEditDataForm] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  useEffect(() => {
    setVendorConsentData(vendorConentDataTable);
  }, [vendorConentDataTable]);

  useEffect(() => {
    callPAgeLoadData();
  }, []);

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    console.log(vendorConentPurposes);
    let EditObject = { Welcometext: '', ContestText: '' }

    vendorConentPurposes.map((item: any) => {
      if (item.Content_Purpose === ContentNameConfig.vendorContext) {
        EditObject.Welcometext = item.Content_Text
      }
      if (item.Content_Purpose === ContentNameConfig.vendorContext) {
        EditObject.ContestText = item.Content_Text
      }
    })
    setisEditDataForm(EditObject)

  }, [vendorConentPurposes])

  useEffect(() => {
    console.log(versionHistory, "versionHistory");

    if (versionHistory.VendorConsentVHeader && versionHistory.VendorConsentVHeader.length > 0) {
      setIsVersionHistory(true);
      let re_columns = {
        ...columns_version,
        columns: versionHistory.VendorConsentVHeader,
      };
      setVersionHistoryData(re_columns);
    }
  }, [versionHistory]);

  const callPAgeLoadData = () => {
    dispatch(
      getContentList({
        FormID: 2,
      })
    );

    const consentPurposesArray = ['vendorwelcometext', 'vendorconsenttext'];

    // Construct the request body
    const queryString = consentPurposesArray.reduce((acc, purpose, index) => {
      acc[`consentPurposes[${index}]`] = purpose;
      return acc;
    }, {} as Record<string, string>);
    dispatch(
      getContentForm(queryString)
    );

  }

  useEffect(() => {
    if (isVendorConsentAdded === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorConsentToasterMsg }));
      dispatch(updateContentAddandEdit(false));
      callPAgeLoadData();
    }

    if (isVendorConsentUpdated === true) {
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorConsentToasterMsg }));
      dispatch(updateContentAddandEdit(false));
      callPAgeLoadData();
    }
    if (isVendorConsentDeleted === true) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true, toasterMsg: vendorConsentToasterMsg }));
      dispatch(updateContentAddandEdit(false));
      callPAgeLoadData();
    }
  }, [isVendorConsentAdded, isVendorConsentUpdated, isVendorConsentDeleted]);

  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permssionPage?.MenuId,
      ModuleName: "Content Master",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Content Master",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        setIsEditing(true);
        dispatch(updateLoader(false));
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      return [
        {
          RecordlockId: recordLock[0].RecordlockId,
          RecordId: recordLock[0].RecordId,
          IsActive: false,
        },
      ];
    }
    return [];
  };
  useEffect(() => {
    if (!isEdit) {
      const data = recordLockUpdate();
      if (data.length > 0) {
        dispatch(UpdateRecordLock(data.pop()));
      }
    }
  }, [isEdit]);

  const handleOpen = async (data: any, option: string) => {
    console.log(data, option, "option");
    if (option === "delete") {
      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Content_Id, FormID: 15 });
      const Edit_data: any = response;

      if (Edit_data?.data === true) {
        setIsDeleteData(data);
      } else {

        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }
    } else if (option === "history") {
      dispatch(
        getContentHistory({
          contentId: data.Content_Id,
          formID: 15,
          // Approved_By: ,
        })
      );
    } else if (option === "edit") {
      setOption(option);
      setIsEditDate(data);
      dispatch(updateLoader(true));

      const response = await masterEditEnableService.GetMasterEditEnable({ ItemID: data.Content_Id, FormID: 15 });
      const Edit_data: any = response;

      const canEdit = await handleRecordLockCheck(data?.Content_Id);

      if (Edit_data?.data === true || true) {
        if (canEdit || true) {
          const dispatchData = handleAddRecordLock(data?.Content_Id);
          await dispatch(AddRecordLock(dispatchData));
          //setIsEditOpen(true);
          const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.Content_Id)));
          if (GetRecordLock.fulfilled.match(resultAction)) {
            const recordLockData = resultAction.payload;
            setGetRecordLock(recordLockData);
          }
          dispatch(updateLoader(false));
          setIsEdit(true);

        }
      } else {

        dispatch(updateLoader(false));
        dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

      }
    } else {
      setIsEdit(true);
      setOption(option);
      setIsEditDate(data);
    }
  };
  const setAddFormcloseoropen = (data: any) => {
    setAddForm(data);
  };

  const deleteconsentList = () => {

    let JsonDataForDisplay: any = {
      "Content Purpose": isDeleteData.Content_Purpose,
      "Content Text" : isDeleteData.Content_Text,
      "Status": isDeleteData.IsActive === "Active" ? "InActive" : "Active",
      "Modified By": user?.UserName,
    }

    dispatch(
      deletecontentMaster({
        ContentId: isDeleteData.Content_Id,
        Content_Purpose: isDeleteData.Content_Purpose,
        CreatedBy: user?.UserName,
        IsActive: isDeleteData.IsActive === "Active" ? false : true,
        Activity: isDeleteData.IsActive === "Active" ? "inactive" : "active",
        DataForDisplay: JSON.stringify(JsonDataForDisplay),
        ModuleName: "Content Master",
        MenuId: currentPagePermissionData?.MenuId,
        Form_Id: 15,
        UserId: userLoginResponse?.UserId,
      })
    );
  };

  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(data, "dataLock");
      if (data.length > 0) {
        event.preventDefault();
        event.returnValue = "";
        try {
          await dispatch(UpdateRecordLock(data.pop())).unwrap();
        } catch (error) {
          console.error("Failed to update record lock:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              Content Master
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <MaterialDynamicGrid
              data={HeaderColumn}
              rows={vendorConsentData}
              handleAction={handleOpen}
              handleAdd={() => setAddFormcloseoropen(true)}
              enableZoomIcon={true}
              showDelete={permssionPage?.IsDelete === 1}
              showhistory={true}
              showAdd={permssionPage?.IsAdd === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Contest Master"
            />
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          dispatch(updateVersionHistory([]));
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={"Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={versionHistory.VendorConsentRecords}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Department Version History"
            />
          </>
        }
      />

      <CustomDialog
        show={addForm}
        onHide={() => setAddFormcloseoropen(false)}
        maxWidth={"md"}
        header={`Add Content`}
        contentNode={
          <>
            {" "}
            <AddContentMaster
              onClose={() => setAddFormcloseoropen(false)}
              ModuleId={permssionPage?.MenuId}
            />{" "}
          </>
        }
      />

     

      <ModalPopUp
        onDeleteMethod={() => deleteconsentList()}
        isDelete={true}
        onHide={() => setIsDeleteData(null)}
        show={isDeleteData ? true : false}
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.IsActive === "Active" ? "deactivate" : "activate"} the selected Consent`}
        ActionName={isDeleteData?.IsActive === "Active" ? "Inactive" : "Active"}

      />

      <CustomDialog
        show={isEdit}
        onHide={() => setIsEdit(false)}
        maxWidth={"md"}
        header={`Edit Content`}
        contentNode={
          <>
            {" "}
            <AddContentMaster
              view={Option === "view" ? true : false}
              edit={true}
              onClose={() => setIsEdit(false)}
              isEditData={isEditDate}
              ModuleId={permssionPage?.MenuId}
            />{" "}
          </>
        }
      />
    </>
  );
};

export default ContentMaster;
