import {createSlice} from '@reduxjs/toolkit';
import { stat } from 'fs';
import { act } from 'react-dom/test-utils';


export interface IAppLoader{    
    isLoading:boolean;
    isProgressLoading: boolean;
    isToaster:boolean;
    toasterMsg:string;
    isTosterFailed: boolean;
}

export const initialState: IAppLoader={    
    isLoading: false,  
    isProgressLoading: false,
    isToaster:false,
    isTosterFailed:false,
    toasterMsg:"",
}

export const loaderSlice = createSlice({
    name: 'appLoader',
    initialState,
    reducers:{
        updateLoader:(state, action)=>{            
            state.isLoading = action.payload;
        },
        updateProgressLoader: (state, action) => {
            state.isProgressLoading = action.payload;
        },
        updateToaster:(state ,action)=>{
            state.toasterMsg = action.payload.toasterMsg;
            console.log(action.payload)
            state.isToaster =action.payload.isToaster;
            state.isTosterFailed =action.payload.isTosterFailed? true : false;
        }
        
    },
    
});

export const {updateLoader,updateProgressLoader,updateToaster } = loaderSlice.actions;
 export default loaderSlice.reducer;