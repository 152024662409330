import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import workFlowService from "../services/workFlowService";
import customFormFieldsService from "../services/customFormFieldsService";
import { IFormConfigurationList } from "../../models/customFields.type";
import { IWorkflowData } from "../../models/workFlow.type";

export interface IWorkflow {
  // userName: string;
  isLoading: boolean;
  workFlowTypeOption: any[];
  workFlowProcessNext: any[];
  worFlowProcessType: any[];
  formConfiguration: IFormConfigurationList[];
  workFlowsData: IWorkflowData[];
  // WorkflowToasterMsg: string;
  WorkflowToasterMsg: { Message: string; Status: string };
  isWorkFlowCreated: boolean;
  isWorkFlowDeleted: boolean;
  editWorkFlowData: any;
}

export const initialState: IWorkflow = {
  isLoading: false,
  workFlowTypeOption: [],
  workFlowProcessNext: [],
  worFlowProcessType: [],
  formConfiguration: [],
  workFlowsData: [],
  // WorkflowToasterMsg: "",
  WorkflowToasterMsg: { Message: "", Status: "" },
  isWorkFlowCreated: false,
  isWorkFlowDeleted: false,
  editWorkFlowData: null,
};

export const getListOfValues = createAsyncThunk("listOfValues", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await workFlowService.getListOfValues(requesrParams);
    const { data } = response;
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListOfValuesofNext = createAsyncThunk(
  "getListOfValuesofNext",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await workFlowService.getListOfValues(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListOfValuesProcessType = createAsyncThunk(
  "listOfValuesProcessType",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await workFlowService.getListOfValues(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllFormsConfiguration = createAsyncThunk(
  "getFormConfiguration",
  async (requestParams, { rejectWithValue }) => {
    try {
      const response = await customFormFieldsService.getAllWorkFlowFormConfiguration();
      console.log(response, "Workflow form");

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createWorkFlow = createAsyncThunk("createWorkFlow", async (postRequest: any, { rejectWithValue }) => {
  try {
    const response = await workFlowService.creatrWorkFlow(postRequest);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateWorkFlow = createAsyncThunk("updateWorkFlow", async (postRequest: any, { rejectWithValue }) => {
  try {
    const response = await workFlowService.updateWorkFlow(postRequest);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getWorkList = createAsyncThunk("getWorkList", async (postRequest, { rejectWithValue }) => {
  try {
    const response = await workFlowService.getListOfWorkFlows();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getWorkflowInstanceById = createAsyncThunk(
  "getWorkflowInstanceById",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await workFlowService.getWorkflowInstanceById(requesrParams);
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWorkflowbyId = createAsyncThunk("deleteworkflowbyId", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await workFlowService.deleteworkflowbyId(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const fetchWorkFlowByCode = createAsyncThunk(
  "fetchWorkFlowByCode",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await workFlowService.fetchWorkFlowByCode(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const workFlowSlice = createSlice({
  name: "workFlow",
  initialState,
  reducers: {
    updateWorkFlowAddandEdit: (state, action) => {
      state.isWorkFlowCreated = false;
      state.isWorkFlowDeleted = false;
      // state.isVendorConsentUpdated = false;
      // state.isVendorConsentDeleted = false;
      ///********/
      // state.WorkflowToasterMsg = "";
      if (!state.WorkflowToasterMsg) {
        state.WorkflowToasterMsg = { Message: "", Status: "" };
      }
      state.WorkflowToasterMsg.Message = "" ;
      state.WorkflowToasterMsg.Status = "";
    },
    setWorkFlowData: (state, action) => {
      state.editWorkFlowData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListOfValues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListOfValues.fulfilled, (state, action: PayloadAction<any>) => {
        state.workFlowTypeOption = action.payload;
        state.isLoading = false;
      })
      .addCase(getListOfValues.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getListOfValuesofNext.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListOfValuesofNext.fulfilled, (state, action: PayloadAction<any>) => {
        state.workFlowProcessNext = action.payload;
        state.isLoading = false;
      })
      .addCase(getListOfValuesofNext.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getListOfValuesProcessType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListOfValuesProcessType.fulfilled, (state, action: PayloadAction<any>) => {
        state.worFlowProcessType = action.payload;
        state.isLoading = false;
      })
      .addCase(getListOfValuesProcessType.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getAllFormsConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormsConfiguration.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.formConfiguration = action.payload;
      })
      .addCase(getAllFormsConfiguration.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(createWorkFlow.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWorkFlow.fulfilled, (state, action: PayloadAction<any>) => {
        state.isWorkFlowCreated = true;
        // state.WorkflowToasterMsg = "WorkFlow Created SuccessFully";
        // state.isLoading = false;
        state.WorkflowToasterMsg.Message = action.payload?.Message;
        state.WorkflowToasterMsg.Status = action.payload?.Status;
      })
      .addCase(createWorkFlow.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getWorkList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkList.fulfilled, (state, action: PayloadAction<any>) => {
        state.workFlowsData = action.payload.map((workFlow: IWorkflowData) => {
          workFlow.IsActive = workFlow.IsActive ? "Active" : "Inactive";
          return workFlow;
        });
        state.isLoading = false;
      })
      .addCase(getWorkList.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateWorkFlow.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWorkFlow.fulfilled, (state, action: PayloadAction<any>) => {
        state.isWorkFlowCreated = true;
        // state.WorkflowToasterMsg = "WorkFlow Updated SuccessFully";
        // state.isLoading = false;
        state.WorkflowToasterMsg.Message = action.payload?.Message;
        state.WorkflowToasterMsg.Status = action.payload?.Status;
      })
      .addCase(updateWorkFlow.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(deleteWorkflowbyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteWorkflowbyId.fulfilled, (state, action: PayloadAction<any>) => {
        state.isWorkFlowDeleted = true;
        state.isLoading = false;
        state.WorkflowToasterMsg.Message = action.payload?.Message;
      })
      .addCase(deleteWorkflowbyId.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchWorkFlowByCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWorkFlowByCode.fulfilled, (state, action: PayloadAction<any>) => {
        state.isWorkFlowDeleted = true;
        state.isLoading = false;
        // state.WorkflowToasterMsg.Message = action.payload?.Message;
      })
      .addCase(fetchWorkFlowByCode.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateWorkFlowAddandEdit, setWorkFlowData } = workFlowSlice.actions;
export default workFlowSlice.reducer;
