import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SignzyService from "../services/signzyService";

export interface IsignzyData {
  SignzyData: any;
  isLoading: boolean;
}

export const initialState: IsignzyData = {
  SignzyData: [],
  isLoading: false,
};

export const SimpleSearchByCin = createAsyncThunk(
  "SimpleSearchByCin",
  async (requesrParams: any, { rejectWithValue }) => {
    try {
      const response = await SignzyService.SimpleSearchByCin(requesrParams);
      return response.result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const GSTSearch = createAsyncThunk("GSTSearch", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await SignzyService.GSTSearch(requesrParams);
    return response.result;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const GSTDetails = createAsyncThunk("GSTDetails", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await SignzyService.GSTDetails(requesrParams);
    return response.result;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const PanToGst = createAsyncThunk("PanToGst", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await SignzyService.PanToGst(requesrParams);
    return response.result;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const PantoCin = createAsyncThunk("PantoCin", async (requesrParams: any, { rejectWithValue }) => {
  try {
    const response = await SignzyService.PantoCin(requesrParams);
    return response.result;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const signzySlice = createSlice({
  name: "signzy",
  initialState,
  reducers: {
    updateSignzyAddandEdit: (state, action) => {
      state.isLoading = false;
    },
    clearSignzyData: (state) => {
      state.SignzyData = [];
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SimpleSearchByCin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SimpleSearchByCin.fulfilled, (state, action: PayloadAction<any>) => {
        state.SignzyData = action.payload;
        state.isLoading = false;
      })
      .addCase(SimpleSearchByCin.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GSTSearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GSTSearch.fulfilled, (state, action: PayloadAction<any>) => {
        state.SignzyData = action.payload;
        state.isLoading = false;
      })
      .addCase(GSTSearch.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GSTDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GSTDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.SignzyData = action.payload;
        state.isLoading = false;
      })
      .addCase(GSTDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(PanToGst.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PanToGst.fulfilled, (state, action: PayloadAction<any>) => {
        state.SignzyData = action.payload;
        state.isLoading = false;
      })
      .addCase(PanToGst.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(PantoCin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PantoCin.fulfilled, (state, action: PayloadAction<any>) => {
        state.SignzyData = action.payload;
        state.isLoading = false;
      })
      .addCase(PantoCin.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateSignzyAddandEdit,clearSignzyData } = signzySlice.actions;
export default signzySlice.reducer;
