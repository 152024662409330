import { Box, Chip, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface PageMainHeadingProps {
  title : string;
 
}

const PageInnerHeading : React.FC<PageMainHeadingProps> = (props) => {
  const {
    title ="",
  } = props;

  return (
    <>
    <Box sx={{color : '#6a6a6a' }}  key={`${title}_tileof_inner_page`} >

            <Typography  variant="overline" gutterBottom sx={{ textAlign: 'left' , color : '#6a6a6a' , display: 'block' , textTransform:'uppercase' , fontSize:'13px' }}>
        {title}
      </Typography>
    </Box>
      
    
    </>
  );
};

export default PageInnerHeading;
