import { Box } from "@mui/material";
import AddExistingClause from "./AddExistingClause";
const ViewContractTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "scroll"
      }}
    >
      <AddExistingClause pluginWindow={true} />
    </Box>
  );
};

export default ViewContractTemplate;
