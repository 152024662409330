import React, { useState, useEffect } from "react";
import CustomCheckBox from "../dynamicInputs/CustomCheckBox";

interface SelectDeselectProps {
  isAllSelected: (selected: boolean) => void;
  indeterminate: boolean;
  value?: boolean;
  readOnly? : boolean
}

const SelectDeselect: React.FC<SelectDeselectProps> = ({ isAllSelected,indeterminate, value , readOnly }) => {
  const [allSelected, setAllSelected] = useState<boolean|undefined>(value);
  useEffect(() => {
    if(value === true) setAllSelected(true);
    else  setAllSelected(false);
  }, [value])

  return (
    <>
    <CustomCheckBox
      isStyle={true}
      readOnly={readOnly}
      // alignItems="center"
      indeterminate={indeterminate} 
      onChange={() => isAllSelected(!value)}
      value={allSelected}
    />
    </>
  );
};

export default SelectDeselect;
