import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IVendorConsent, IVHVendorConsentColumn } from "../../models/vendorConsentMaster.type";
import { chkDate } from "../../utils/constants";
import VendorConsentService from "../services/consentFormService";
export interface IVendorConsentData {
    vendorConsentWithdrawList: any;
  isVendorConsentLoading: boolean;
  isVendorConsentFormAdded: boolean;
  isVendorConsentWithdraw: boolean;
  isVendorConsentWithstatus: boolean;
  vendorConsentToasterMsg: string;
}

export const initialState: IVendorConsentData = {
    vendorConsentWithdrawList :[],
  isVendorConsentLoading: false,
  isVendorConsentFormAdded: false,
  isVendorConsentWithdraw: false,
  isVendorConsentWithstatus: false,
  vendorConsentToasterMsg: "",
};




export const AddVendorConsentForms = createAsyncThunk("AddVendorConsentForms", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };
    const response: any = await VendorConsentService.addVendorConsentForm(reqbody);
    return response.data; 
        
  } catch (error) {
    return rejectWithValue(error);
  }
});


export const AddWithdrawConsentForm = createAsyncThunk("AddWithdrawConsentForm", async (requesrParams: any, { rejectWithValue }) => {
  try {
    let reqbody = { ...requesrParams };

    const response: any = await VendorConsentService.addWithdrawConsentForm(requesrParams);
    return response.data;    
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getWithdrawConsentList = createAsyncThunk("getWithdrawConsentList", async (postRequest , { rejectWithValue }) => {
    try {
    
  
      const response: any = await VendorConsentService.getConsentFormList();
      return response.data;    
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const vendorConsentDataSlice = createSlice({
  name: "vendorConsentDataSlice",
  initialState,
  reducers: {
    
    updateVendorConsentAddandEdit: (state, action) => {

      state.isVendorConsentFormAdded = false ;
      state.isVendorConsentWithdraw = false ;
      state.isVendorConsentWithstatus = false ;
      state.vendorConsentToasterMsg = "";
    },
    
  },
  extraReducers: (builder) => {
    builder
      
    .addCase(getWithdrawConsentList.pending, (state) => {
        // state.isVendorConsentLoading = true;
      })
      .addCase(getWithdrawConsentList.fulfilled, (state, action: PayloadAction<any>) => {
       
        state.vendorConsentWithdrawList = action.payload;
        state.isVendorConsentLoading = false;
      })
      .addCase(getWithdrawConsentList.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })
      .addCase(AddVendorConsentForms.fulfilled, (state, action: PayloadAction<any>) => {

        state.isVendorConsentFormAdded = true;
        state.isVendorConsentLoading = false;
        state.vendorConsentToasterMsg = action.payload.Message;
      })
      .addCase(AddVendorConsentForms.rejected, (state, action: PayloadAction<any>) => {
        
        state.isVendorConsentLoading = false;
      }).addCase(AddWithdrawConsentForm.pending, (state) => {
        state.isVendorConsentLoading = true;
      })
      .addCase(AddWithdrawConsentForm.fulfilled, (state, action: PayloadAction<any>) => {
        state.isVendorConsentWithdraw = true;
        state.isVendorConsentLoading = false;
        state.vendorConsentToasterMsg = action.payload?.toasterMessage?.Message;
        state.isVendorConsentWithstatus = action.payload?.ResponseModal.IsSuccess;
      })
      .addCase(AddWithdrawConsentForm.rejected, (state, action: PayloadAction<any>) => {
        state.isVendorConsentLoading = false;
      })

  },
});

export const { updateVendorConsentAddandEdit } = vendorConsentDataSlice.actions;
export default vendorConsentDataSlice.reducer;
