import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react"
import CustomTextField from "../../../../common/dynamicInputs/CustomTextField";
import { Controller } from "react-hook-form";
import CustomAutoComplete from "../../../../common/dynamicInputs/CustomAutoComplete";
import CustomSelect from "../../../../common/dynamicInputs/CustomSelect";
import CustomToogleSwitch from "../../../../common/dynamicInputs/CustomToogleSwitch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

export const RegulatoryDetails: React.FC<any> = ({ isEditData, edit, view, control, errorsVendorDetailsform, watch, setValue }) => {
  const [RegionalData, setRegionalData] = useState<any[]>([]);
  const [regulatorData, setRegulatorData] = useState<any[]>([]);
  const [jurisdiction, setJurisdiction] = useState<any[]>([]);
  const [companyConstitutionlov, setCompanyConstitutionlov] = useState<any[]>([]);

  const { RegionalTable } = useSelector((state: RootState) => state.RegionalSettingsData);
  const { locationTable } = useSelector((state: RootState) => state.locationMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { thirdPartyData } = useSelector((state: RootState) => state.thirdPartyData);

  const IsRegulatedwatch = watch("IsRegulated");
  const Regulatorwatch = watch("Regulator_Id");

  console.log(Regulatorwatch, "Regulatorwatch");


  useEffect(() => {
    const filteredRegulatorData = thirdPartyData.filter((item: any) => item.IsActive === "Active" && item.ThirdPartyTypeValue === "Regulator");
    setRegulatorData(filteredRegulatorData);
  }, [thirdPartyData]);

  useEffect(() => {
    const filteredRegionalData = RegionalTable.filter((item: any) => item.LocationName === "India");
    setRegionalData(filteredRegionalData.filter((item: any) => item.IsActive === "Active"));
  }, [RegionalTable]);

  useEffect(() => {
    if (locationTable.length > 0) {
      setJurisdiction(locationTable.filter((item: any) => item.LocationTypeValue === "City") || []);
    }
  }, [locationTable])

  useEffect(() => {
    setCompanyConstitutionlov(lovTable.filter((item: any) => item.Name === "Company Constitution") || []);
  }, [lovTable]);

  const convertString = (str: any) => {
    return str
      .split("")
      .map((char: any) => {
        if (!isNaN(char) && char !== " ") {
          return "9";
        } else {
          return "X";
        }
      })
      .join("");
  };

  const OthersOption = {
    ThirdPartyId: 0,
    ThirdPartyName: "Others",

  };
  return (
    <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
      <Grid container spacing={2}>

        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="IsListed"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <CustomToogleSwitch
                label="Is Third Party a listed entity?"
                value={field.value}
                onChange={field.onChange}
                required={true}
                readOnly={view}
                error={Boolean(errorsVendorDetailsform.IsListed)}
                helperText={errorsVendorDetailsform?.IsListed?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="IsRegulated"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <CustomToogleSwitch
                label="Is Regulated"
                value={field.value}
                // onChange={field.onChange}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("Regulator_Id", "")
                }}
                error={Boolean(errorsVendorDetailsform.IsRegulated)}
                required={true}
                readOnly={view}
                helperText={
                  errorsVendorDetailsform?.IsRegulated?.message?.toString()
                }
              />
            )}
          />
        </Grid>

        {IsRegulatedwatch && <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="Regulator_Id"
            control={control}
            defaultValue={''}
            rules={{ required: "Regulator is required" }}
            render={({ field }) => (
              <CustomSelect
                name={"Regulator_Id"}
                valueKey={"ThirdPartyId"}
                optionLabelKey="ThirdPartyName"
                options={regulatorData}
                readOnly={view}
                required={IsRegulatedwatch}
                label={"Regulator Name"}
                showAll={OthersOption}
                showAllOption={true}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsVendorDetailsform.Regulator_Id)}
                helperText={errorsVendorDetailsform.Regulator_Id?.message?.toString()}
              />
            )}
          />
        </Grid>}

        {Regulatorwatch === 0 && IsRegulatedwatch ? <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="Others_Regulator"
            control={control}
            defaultValue={''}
            rules={{
              required: "Other Regulator is required" ,
              validate: {
                noLeadingSpaces: (value: string | undefined) => {
                  if (!value) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
                },
                
                maxLength: (value) => {
                  if (!value) return;
                  return value.length <= 100 || "Maximum 100 characters allowed";
                }
              }
            }}
            render={({ field }) => (
              <CustomTextField
                name={"Others_Regulator"}
                required={true}
                readOnly={view}
                label={"Other Regulator Name"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errorsVendorDetailsform.Others_Regulator)}
                helperText={
                  errorsVendorDetailsform.Others_Regulator?.message?.toString()
                }
              />
            )}
          />
        </Grid> : <> </>}

      </Grid>
    </Box>
  )
}