import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { WORKFLOWHISTORY_URL } from "./endpoints/workFlowHistory";
import { IWorkFlowHistoryResponse, IWorkFlowHistoryViewResponse } from "../../models/workFlowHistory.type";

const getWorkFlowHistoryList = (reqparams: any): Promise<IWorkFlowHistoryResponse> =>
  axiosInstance
    .get(WORKFLOWHISTORY_URL.GetWorkflowHistory, { params: reqparams })
    .then((response: AxiosResponse<IWorkFlowHistoryResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });


const getWorkFlowHistoryView = (requestBody: any): Promise<IWorkFlowHistoryViewResponse> =>
  axiosInstance
    .get(WORKFLOWHISTORY_URL.GetWorkflowHistoryView, { params: requestBody })
    .then((response: AxiosResponse<IWorkFlowHistoryViewResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });



const WorkFlowHistoryService = {
    getWorkFlowHistoryList,
  
    getWorkFlowHistoryView,
  
};

export default WorkFlowHistoryService;
