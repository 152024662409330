import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IVendorDetailsResponse, IVendorDetails, IVHColumnRes } from "../../models/vendorDetails.type";
import { VENDOR_DETAILS_URL } from "./endpoints/vendorDetails";

const getVendorDetailsList = (): Promise<IVendorDetailsResponse> =>
  axiosInstance
    .get(VENDOR_DETAILS_URL.GetAllVendorDetails)
    .then((response: AxiosResponse<IVendorDetailsResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVendorDetailsById = (reqparams: any): Promise<IVendorDetailsResponse> =>
  axiosInstance
    .get(VENDOR_DETAILS_URL.GetVendorDetailsById, { params: reqparams })
    .then((response: AxiosResponse<IVendorDetailsResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Service Catalog data: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateVendorDetails = (requestBody: IVendorDetails): Promise<any> =>
  axiosInstance
    .post(VENDOR_DETAILS_URL.EditVendorDetails, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error updating Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addVendorDetails = (requestBody: IVendorDetails): Promise<any> =>
  axiosInstance
    .post(VENDOR_DETAILS_URL.AddVendorDetails, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error adding Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteVendorDetails = (requestBody: any): Promise<any> =>
  axiosInstance
    .post(VENDOR_DETAILS_URL.DeleteVendorDetails, requestBody)
    .then((response: AxiosResponse<any>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error deleting Service Catalog: " + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const getVersionHistoryVendorDetails = (requestBody: any): Promise<IVHColumnRes> =>
  axiosInstance
    .get(VENDOR_DETAILS_URL.VersionHistory, { params: requestBody })
    .then((response: AxiosResponse<IVHColumnRes>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const vendorDetailsService = {
  getVendorDetailsList,
  getVendorDetailsById,
  updateVendorDetails,
  addVendorDetails,
  deleteVendorDetails,
  getVersionHistoryVendorDetails,
};

export default vendorDetailsService;
