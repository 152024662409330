import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatch } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRiskScaleMaster } from "./AddRiskScaleMaster";



export default function RiskScaleMaster() {

    const dispatch = useAppDispatch();
    const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
    const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
    const [addForm, setAddForm] = useState(false);
    const [Option, setOption] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [IsEditData, setIsEditData] = useState<any[]>([]);
    const [isVersionHistory, setIsVersionHistory] = useState(false);
    const [isDeleteData, setIsDeleteData] = useState<any>(null);



    const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
        (state: RootState) => state.loggerData
    );



    let Table_Data = [
        { id: "1", Module: "Service Catalog", From: "0", To: "4", Risk: "Low", IsActive: "Active", },
        { id: "2", Module: "Service Catalog", From: "5", To: "9", Risk: "Medium", IsActive: "Active", },
        { id: "3", Module: "Service Catalog", From: "10", To: "20", Risk: "High", IsActive: "Active", },
        { id: "4", Module: "Vendor Assessment", From: "0", To: "4", Risk: "Low", IsActive: "Active", },
       
    ];

    const columns: any = {
        table_name: "Risk Scale",
        isAction: true,
        columns: [

            {
                Field: "Module",
                DisplayName: "Module Name",
                FieldType: "string",
            },
            {
                Field: "From",
                DisplayName: "From",
                FieldType: "string",
            },
            {
                Field: "To",
                DisplayName: "To",
                FieldType: "string",
            },
            {
                Field: "Risk",
                DisplayName: "Risk",
                FieldType: "string",
            },
           
            {
                Field: "IsActive",
                DisplayName: "Status",
                FieldType: "string",
            },

        ],
    };
    

    useEffect(() => {

        if (currentPagePermissionData !== null) {
            setPermissionForpage(currentPagePermissionData)
        }
    }, [currentPagePermissionData]);

    const handleOpen = (data: any, Option: string) => {
        if (Option === "edit") {
            console.log(data, Option, "Option");

            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);

            // setUserId(data.UserId);
        } else if (Option === "view") {

            setOption(Option);
            setIsEditData(data);
            setIsEdit(true);

        } else if (Option === "history") {

            setOption(Option);
            setIsVersionHistory(true);

        } else if (Option === "delete") {
            setOption(Option);
            setIsDeleteData(data);
        }
    };

   

    const deleteRiskList = () => {
        console.log(isDeleteData);
        let formData = { ...isDeleteData };
        // setLoader(true);
        formData.UserId = userLoginResponse?.UserId;
        formData.Activity = "Delete";
        formData.MenuId = 15;
        formData.Form_Id = 7;
        formData.ModuleName = "Location";
        formData.CreatedBy = userLoginResponse?.UserName;
        console.log(formData);

        // dispatch(
        //     deleteLocation((formData))
        // );
       
    };


    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    // backgroundColor: "#ffff",
                    // backgroundColor: "background.default",
                    // px: 3,
                    // py: 4,
                    // borderRadius: "10px",
                }}
            >
                <Stack spacing={2}>

                    <Box
                        sx={{
                            display: "flex",
                            // flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                       
                        <Typography variant="h6" color={"text.primary"}>
                            {" "}
                            Configure Risk Scale 
                        </Typography>

                        <Box sx={{ display: "flex", flexDirection: "column" }}>

                        </Box>

                    </Box>

                    <Box>


                        <Box sx={{ display: "flex", flexDirection: "column" }}>


                            <MaterialDynamicGrid
                                data={columns}
                                rows={Table_Data}
                                handleAction={handleOpen}
                                enableZoomIcon={true}
                                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                                handleAdd={() => setAddForm(true)}
                                showAdd={true}
                                showhistory={false}
                                showDelete={permssionPage?.IsDelete === 1}
                                showView={permssionPage?.IsView === 1}
                                showEdit={permssionPage?.IsEdit === 1}
                                showExports={permssionPage?.IsExport === 1}
                                pageName="Risk Scale"
                                userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0} />
                        </Box>

                    </Box>
                </Stack>
            </Box>

            <CustomDialog
                show={addForm}
                onHide={() => setAddForm(false)}
                maxWidth={"lg"}
                minHeight={"50vh"}
                header={"Add Risk Scale"}
                contentNode={
                    // <></>
                    <AddRiskScaleMaster
                        view={false}
                        closePopUp={() => {
                            setAddForm(false);
                        }}
                    />
                }
            />

            <CustomDialog
                show={isEdit}
                onHide={() => {
                    // if (Option === "history") {
                    //   dispatch(updateVersionHistory([]));
                    // }
                    setIsEdit(false);
                }}
                maxWidth={"lg"}
                minHeight={"50vh"}
                header={Option === "view" ? "View Risk Scale" : "Update Risk Scale"}
                contentNode={
                    <>
                        {Option === "edit" && (
                            <AddRiskScaleMaster
                                view={false}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                                edit={isEdit}
                                isEditData={IsEditData}
                            />
                            // <></>
                        )}
                        {Option === "view" && (
                            <AddRiskScaleMaster
                                view={true}
                                closePopUp={() => {
                                    setIsEdit(false);
                                }}
                                isEditData={IsEditData}
                            />
                            // <></>
                        )}

                    </>
                }
            />

            <CustomDialog
                show={isVersionHistory}
                onHide={() => {
                    setIsVersionHistory(false);
                }}
                maxWidth={"lg"}
                header={"Risk Scale Version History"}
                contentNode={
                    <> <MaterialDynamicGrid
                        data={columns}
                        rows={Table_Data}
                        handleAction={handleOpen}
                        enableZoomIcon={false}
                        showhistory={false}
                        showDelete={permssionPage?.IsDelete === 1}
                        showView={permssionPage?.IsView === 1}
                        showEdit={permssionPage?.IsEdit === 1}
                        showExports={permssionPage?.IsExport === 1}
                        userId={(userLoginResponse && userLoginResponse?.UserId) ? userLoginResponse?.UserId : 0}
                        pageName="Risk Scale Version History"
                    />
                    </>
                }
            />
            <ModalPopUp
                onDeleteMethod={() => deleteRiskList()}
                isDelete={true}
                onHide={() => setIsDeleteData(null)}
                show={isDeleteData ? true : false}
                header={"Confirm"}
                text={"Are you sure you want to deactivate the selected Risk Scale"}
            />
        </>
    );

}