import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CustomTabs from "../../../dynamicTabs/CustomTabs";
import CustomButton from "../../../dynamicButton/CustomButton";
import { useAppDispatchThunk } from "../../../../store/store";
import { setTabs } from "../../../../store/slices/tabsData";
import Deviation from "./Deviation";
import ReviewSection from "./ReviewSection";
import { PLUGIN_STEPPER } from "../../../../utils/constants";

interface ISOReview {
  formData: any;
  handleSelectChange: any;
}

const SOReview: React.FC<ISOReview> = ({ formData, handleSelectChange }) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [toBeReviewedItems, setToBeReviewedItems] = useState<any[]>([]);
  const [acceptedItems, setAcceptedItems] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const dispatch = useAppDispatchThunk();

  useEffect(() => {
    dispatch(setTabs(0));
  }, []);
  useEffect(() => {
    if (Array.isArray(formData)) {
      setToBeReviewedItems(formData);
    } else {
      console.warn("formData is not an array:", formData);
      setToBeReviewedItems([]);
    }
  }, [formData]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleClause = (item: any, index: number, type: "toBeReviewed" | "accepted") => {
    if (type === "toBeReviewed") {
      setToBeReviewedItems((prev) => prev.filter((_, i) => i !== index));
      setAcceptedItems((prev) => [...prev, { ...item, isSelected: true }]);
    } else {
      setAcceptedItems((prev) => prev.filter((_, i) => i !== index));
      setToBeReviewedItems((prev) => [...prev, { ...item, isSelected: false }]);
    }
  };

  const handleAcceptAll = () => {
    const accepted = toBeReviewedItems.map((item) => ({ ...item, isSelected: true }));
    setAcceptedItems((prev) => [...prev, ...accepted]);
    setToBeReviewedItems([]);
  };

  const handleTabNavigation = (_: any, index: number) => {
    dispatch(setTabs(index));
    setActiveTab(index);
  };

  // Data for tabs and Deviation component
  const DOData = [
    {
      DOHeading: "Finance",
      DOClauses: [
        { title: "Payment", isSelected: false },
        { title: "Billing", isSelected: true },
        { title: "Taxation", isSelected: true },
      ],
    },
    {
      DOHeading: "Operational",
      DOClauses: [
        { title: "Leave License", isSelected: false },
        { title: "Scope of Agreement", isSelected: false },
        { title: "Clause 3", isSelected: false },
      ],
    },
    {
      DOHeading: "Legal",
      DOClauses: [
        { title: "Clause 1", isSelected: false },
        { title: "Clause 2", isSelected: false },
        { title: "Clause 3", isSelected: false },
      ],
    },
  ];

  const acceptedCount = acceptedItems.length;
  // const toBeReviewedCount = toBeReviewedItems.length;
  const toBeReviewedCount = Array.isArray(formData) ? formData.length : [];
  const totalDeviationClauses = DOData.reduce((acc, section) => acc + section.DOClauses.length, 0);
  const [selectedDeviationClauses, setSelectedDeviationClauses] = useState<number>(
    DOData.reduce((acc, section) => acc + section.DOClauses.filter((clause) => clause.isSelected).length, 0)
  );

  const tapSteps = [
    { name: `Redline (${acceptedCount}/${toBeReviewedCount})` },
    { name: `Deviation (${selectedDeviationClauses}/${totalDeviationClauses})` },
  ];
  const handleSubmit = () => {
    handleSelectChange(PLUGIN_STEPPER.DO);
  };
  return (
    <Box sx={{ maxHeight: "calc(100vh - 250px)", px: 2, py: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Typography variant="h6" fontWeight="bold">
          SO Review
        </Typography>
      </Box>
      <Stack direction="column" spacing={2}>
        <CustomTabs steps={tapSteps} handleTabNavigation={handleTabNavigation} />
        <Box sx={{ maxHeight: "calc(100vh - 255px)", overflow: "auto", pr: 1 }}>
          {activeTab === 0 && (
            <>
              <ReviewSection
                expanded={expanded}
                sectionType="toBeReviewed"
                items={toBeReviewedItems}
                onToggle={handleToggleClause}
                onExpand={handleChange}
                onAcceptAll={handleAcceptAll}
              />
              <ReviewSection
                expanded={expanded}
                sectionType="accepted"
                items={acceptedItems}
                onToggle={handleToggleClause}
                onExpand={handleChange}
              />
            </>
          )}

          {activeTab === 1 && (
            <Deviation
              DOData={DOData}
              setSelectedDeviationClauses={setSelectedDeviationClauses}
              selectedDeviationClauses={selectedDeviationClauses}
            />
          )}
        </Box>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "space-between", borderTop: "1px solid #ddd", pt: 1 }}>
        <CustomButton
          variant="outlined"
          onClick={() => {
            console.log("Send Back to SME");
            handleSelectChange(PLUGIN_STEPPER.SME);
          }}
          name="Send Back to SME"
        />
        {activeTab === 0 && (
          <CustomButton
          variant="contained"
            onClick={() => {
              let index = 1;
              dispatch(setTabs(index));
              setActiveTab(index);
            }}
            name="Next"
          />
        )}
        {activeTab === 1 && <CustomButton variant="contained" onClick={handleSubmit} name="Submit" />}
      </Box>
    </Box>
  );
};

export default SOReview;
