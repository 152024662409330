import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import changePasswordService from '../services/changePasswordService';

export interface IAuthDetails{
   // userName: string;    
    isLoading: boolean;
    sentMailtoForgotPassword: boolean;
    changePassworUpdated: boolean;
    changePassWordMessage: string;
    changePasswordUpdateStatus: boolean;
    
}

export const initialState: IAuthDetails={
    //userName:"",
    changePassworUpdated: false,
    sentMailtoForgotPassword: false,
    isLoading: false,
    changePassWordMessage: "",
    changePasswordUpdateStatus: false
}

export const postChangePassword = createAsyncThunk('changePassword', async(requesrParams:any, {rejectWithValue})=>{
    try{
        const response= await changePasswordService.postChangePassword(requesrParams);
        const {data} =response;
        return data
    }catch(error){
        return rejectWithValue(error)
    }
})

export const forgotChangePassword = createAsyncThunk('forgotPassword', async(requesrParams:any, {rejectWithValue})=>{
    try{
        const response= await changePasswordService.forgotPassword(requesrParams);
        const {data} =response;
        return data
    }catch(error){
        return rejectWithValue(error)
    }
})

export const changePasswordSlice = createSlice({
    name: 'changePasword',
    initialState,
    reducers:{
        updatePassword:(state, action)=>{            
            state.changePassworUpdated = action.payload;
        },
        updateForgotPassword:(state, action)=>{            
            state.sentMailtoForgotPassword = action.payload;
        },
        resetPassword:(state,action)=>{
            state.changePassworUpdated= false;
            state.sentMailtoForgotPassword= false;
            state.isLoading= false;
            state.changePassWordMessage= "";
            state.changePasswordUpdateStatus= false;
        }
    },  
    extraReducers: (builder)=>{
        builder.addCase(postChangePassword.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(postChangePassword.fulfilled, (state, action: PayloadAction<any>)=>{
            state.changePassworUpdated = true;
            state.changePasswordUpdateStatus = action.payload.Status;
            if(action.payload.Status){
                state.changePassWordMessage = action.payload.Message;
            }else{
                state.changePassWordMessage = action.payload.Description;
            }            
            state.isLoading = false;
        })
        .addCase(postChangePassword.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false;
            state.changePassworUpdated = false;
            //state.isAuthenticated= true;
        })   
        .addCase(forgotChangePassword.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(forgotChangePassword.fulfilled, (state, action: PayloadAction<any>)=>{
            state.sentMailtoForgotPassword = true;
            state.isLoading = false;
        })
        .addCase(forgotChangePassword.rejected, (state, action: PayloadAction<any>)=>{            
            state.isLoading = false;
            state.sentMailtoForgotPassword = false;
            //state.isAuthenticated= true;
        })       
    }
});

export const {updatePassword,updateForgotPassword,resetPassword} = changePasswordSlice.actions;
export default changePasswordSlice.reducer;