import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AddContractCostApproval } from "./AddContractCostApproval";
import { IMenu } from "../../../models/loggin.type";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import MaterialDynamicGrid from "../../../common/dynamicDataGrid/MaterialDynamicGrid";
import CustomDialog from "../../../common/dynamicDialog/CustomDialog";
import {getallCostApprovalMetricList,createCostApprovalMetric,
  updateCostApprovalMetric,
  activeInactiveCostApprovalMetric,
  updateCostApprovalMetricAddandEdit} from '../../../store/contractManagement/slices/costApprovalMetricSlice'
import { getLovList } from "../../../store/slices/lovMasterData";
import { updateLoader, updateToaster } from "../../../store/slices/loader";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../../store/slices/recordLock";
import costApprovalMetricService from "../../../store/contractManagement/services/costApprovalMetricService";
import { Edit_Enable_tosterMSG } from "../../../config/config";
import { ACTIVE, INACTIVE } from "../../../utils/constants";


export interface RegionalSettingsProps {
  handlePrevious?: () => void;
  handleNext?: () => void;
}

export const ContractCostApproval: React.FC<RegionalSettingsProps> = ({ handlePrevious, handleNext }) => {
  const dispatch = useAppDispatchThunk();
  

    const contractManagementReducer: any = useSelector((state: RootState) => state?.contractManagementReducer);
    const { costApprovalMetric, CostApprovalMetricData, versionHistory, isCostApprovalMetricDataAdded,
      isCostApprovalMetricDataUpdated, 
      isCostApprovalMetricDataDeleted,
      CostApprovalMetricToasterStatus, ToasterMsg } :any = contractManagementReducer.costApprovalMetric;


  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [currencyFormData, setCurrencyFormData] = useState<any>([]);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState<any>(null);
  const [isDeleteClause, setIsDeleteClause] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);
  const { ParentLocationTable } = useSelector((state: RootState) => state.locationMasterData);

console.log(currencyFormData)
  const columns_version: any = {
    table_name: "Contract Cost Approval Metrics",
    isAction: false,
    columns: [],
  };

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );


  const columns: any = {
    table_name: "Cost Approval Metrics",
    isAction: true,
    columns: [
      {
        Field: "from_Amount",
        DisplayName: "From Amount",
        FieldType: "string",
      },
      {
        Field: "to_Amount",
        DisplayName: "To Amount",
        FieldType: "string",
      },
      {
        Field: "approver_Name",
        DisplayName: "Approver  Name",
        FieldType: "string",
      },
      {
        Field: "approver_Email",
        DisplayName: "Approver Email",
        FieldType: "string",
      },
      {
        Field: "approver_Designation",
        DisplayName: "Approver Designation ",
        FieldType: "string",
      },
    {
        Field: "status",
        DisplayName: "Status",
        FieldType: "string",
      },
      
  ]};

  

  useEffect(() => {
    setCurrencyFormData(CostApprovalMetricData);
  }, [CostApprovalMetricData]);



//console.log(CostApprovalMetricData, "Jayakumar");
  useEffect(() => {
    callMasterData();
  }, []);

  const callMasterData = () => {
    dispatch(getallCostApprovalMetricList({ FormID: 0 }));
  };


  const handleOpen = async (data: any, option: string) => {
    const updatedData = CostApprovalMetricData?.find((i: any) => i.costApproval_Metrics_Id === data.costApproval_Metrics_Id);
    if (!updatedData) return;
    setOption(option);
    console.log(data)

    switch (option) {
      case "delete":
        setIsDeleteData(updatedData);
        setIsDeleteClause(true);
        break;
      // case "history":
      //   setIsVersionHistory(true);
      //   setIsEditData(updatedData);
      //   dispatch(getDepartmentHistory({ departmentId: data.ClauseTypeId, formID: 7 }));
      //   break;
      case "view":
        setIsEditData(updatedData);
        setIsEdit(true);
        break;
      case "edit":
        setIsEditData(updatedData);
        dispatch(updateLoader(true));
        const response = { data: true };
        if (response.data) {
          dispatch(updateLoader(false));
          setIsEdit(true);
        } else {
          dispatch(updateLoader(false));
        }
        break;
      default:
        setIsEdit(true);
        break;
    }
  };
console.log(isCostApprovalMetricDataAdded,isCostApprovalMetricDataDeleted,isCostApprovalMetricDataUpdated,"SlicesResponses")
  useEffect(() => {
    if (isCostApprovalMetricDataAdded) {
      dispatch(updateCostApprovalMetricAddandEdit(false));
      dispatch(updateToaster({ isToaster: true,
        toasterMsg: ToasterMsg?.Message,
        isTosterFailed: ToasterMsg?.Status === "false" ? true : false, }));
      if( ToasterMsg?.Status !== "false"){
        setAddForm(false);
        callMasterData();

      }
        
      
      dispatch(updateLoader(false));
    
    } else if (isCostApprovalMetricDataUpdated) {
      dispatch(updateCostApprovalMetricAddandEdit(false));
      dispatch(updateToaster({ isToaster: true,
        toasterMsg: ToasterMsg?.Message,
        isTosterFailed: ToasterMsg?.Status === "false" ? true : false,}));
      setIsEdit(false);
      dispatch(updateLoader(false));
      
      callMasterData();
    } else if (isCostApprovalMetricDataDeleted) {
      setIsDeleteData(null);
      dispatch(updateToaster({ isToaster: true,
                  toasterMsg: ToasterMsg?.Message,
                  isTosterFailed: ToasterMsg?.Status === "false" ? true : false, }));
      setTimeout(() => {
        dispatch(updateCostApprovalMetricAddandEdit(false));
      }, 1000);
      setIsDeleteClause(false)
      callMasterData();
    }
  }, [isCostApprovalMetricDataUpdated, isCostApprovalMetricDataAdded, isCostApprovalMetricDataDeleted]);

  const handleStatusChange = () => {
    
      const updatedStatus = isDeleteData.isActive === ACTIVE ? false : true;

      // Prepare the formData for activation/deactivation
      const formData = {
        costApproval_Metrics_Id: isDeleteData.costApproval_Metrics_Id,
        from_Amount: isDeleteData.from_Amount,
        to_Amount: isDeleteData.to_Amount,
        approver_Name: isDeleteData.approver_Name,
        approver_Email: isDeleteData.approver_Email,
        approver_Designation: isDeleteData.approver_Designation,
        isActive: updatedStatus,
        activity: updatedStatus ? ACTIVE : INACTIVE,
        UpdatedBy: user?.UserName,
        CreatedBy: user?.UserName,
        ModuleName: "Cost Approval Metrics",
        actionTaken: updatedStatus  ? ACTIVE : INACTIVE,
        MenuId: currentPagePermissionData?.MenuId,
        Form_Id: 13,
        UserId: userLoginResponse?.UserId,
        
      };

      dispatch(activeInactiveCostApprovalMetric(formData));
      

 
      console.log(formData); 
     
    
};
  
  // useEffect(() => {
  //   const handleFunc = () => {
  //     if (isCostApprovalMetricDataAdded || isCostApprovalMetricDataUpdated || isCostApprovalMetricDataDeleted) {
  //       dispatch(updateCostApprovalMetric(false));
  //       dispatch(
  //         updateToaster({
  //           isToaster: true,
  //           toasterMsg: ToasterMsg?.Message,
  //           isTosterFailed: ToasterMsg?.Status === "false" ? true : false,
  //         })
  //       );
  //       callMasterData();
  //       setIsDeleteClause(false);
  //     }
  //   };
  //   handleFunc();
  // }, [isCostApprovalMetricDataUpdated, isCostApprovalMetricDataAdded, isCostApprovalMetricDataDeleted]);
 
 

  // useEffect(() => {
  //   if (currentPagePermissionData !== null) {
  //     setPermissionForpage(currentPagePermissionData);
  //   }
  // }, [currentPagePermissionData]);

  // useEffect(() => {
  //   if (versionHistory.CurrencyVHeader.length > 0 && versionHistory.CurrencyVHeader.length > 0) {
  //     if (versionHistory.CurrencyVHeader && versionHistory.CurrencyVHeader.length > 0) {
  //       setIsVersionHistory(true);
  //       let re_columns = {
  //         ...columns_version,
  //         columns: versionHistory.CurrencyVHeader,
  //       };
  //       setVersionHistoryData(re_columns);
  //       console.log(versionHistory, "versionHistory ");
  //     }
  //   }
  // }, [versionHistory]);

  // const recordLockGet = (id: any) => {
  //   const recordLockData = {
  //     RecordId: id,
  //     MenuId: permssionPage?.MenuId,
  //     ModuleName: "Location",
  //   };
  //   return recordLockData;
  // };

  // const handleAddRecordLock = (id: number) => {
  //   const recordLockData = {
  //     RecordId: id,
  //     UserId: userLoginResponse?.UserId || 0,
  //     CreatedBy: userLoginResponse?.UserName || "",
  //     MenuId: permssionPage?.MenuId,
  //     ModuleName: "Location",
  //     Activity: "edit",
  //   };
  //   return recordLockData;
  // };
  // const handleRecordLockCheck = async (recordId: number) => {
  //   const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
  //   if (GetRecordLock.fulfilled.match(resultAction)) {
  //     const recordLockData = resultAction.payload;
  //     if (recordLockData && recordLockData.length > 0) {
  //       dispatch(updateLoader(false));
  //       setIsEditing(true);
  //       console.log(recordLock, resultAction.payload, "recordLock");
  //       dispatch(
  //         updateToaster({
  //           isToaster: true,
  //           toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
  //           isTosterFailed: true,
  //         })
  //       );
  //       return false;
  //     }
  //   }
  //   return true;
  // };
  // const recordLockUpdate = () => {
  //   if (recordLock && recordLock.length > 0) {
  //     return [
  //       {
  //         RecordlockId: recordLock[0].RecordlockId,
  //         RecordId: recordLock[0].RecordId,
  //         IsActive: false,
  //       },
  //     ];
  //   }
  //   return [];
  // };
  // useEffect(() => {
  //   if (!isEdit) {
  //     const data = recordLockUpdate();
  //     if (data.length > 0) {
  //       dispatch(UpdateRecordLock(data.pop()));
  //     }
  //   }
  // }, [isEdit]);

  // const handleOpen = async (data: any, Option: string) => {
  //   if (Option === "edit") {
  //     console.log(data, Option, "Option");

  //     setIsEditData(data);
  //      setIsEdit(true);
  //     setOption(Option);
  //    dispatch(updateLoader(true));

  //     const response = await costApprovalMetricService.getallCostApprovalMetricList({ ItemID: data.costApproval_Metrics_Id, FormID: 13 });
  //     const Edit_data: any = response;

  //     const canEdit = await handleRecordLockCheck(data?.costApproval_Metrics_Id);
  //     if (Edit_data?.data === true) {
  //       if (canEdit) {
  //         const dispatchData = handleAddRecordLock(data?.costApproval_Metrics_Id);
  //         await dispatch(AddRecordLock(dispatchData));
  //         //setIsEditOpen(true);
  //         const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.costApproval_Metrics_Id)));
  //         if (GetRecordLock.fulfilled.match(resultAction)) {
  //           const recordLockData = resultAction.payload;
  //           console.log(recordLockData, resultAction, isEditing, "recordLockData");
  //           setGetRecordLock(recordLockData);
  //         }
  //         dispatch(updateLoader(false));
  //         setIsEdit(true);

  //       }
  //     } 
      
  //     else {

  //       dispatch(updateLoader(false));
  //       dispatch(updateToaster({ isToaster: true, toasterMsg: Edit_Enable_tosterMSG.message, isTosterFailed: true }));

  //     }

  //    // setUserId(data.UserId);
  //    } 
  //   else if (Option === "view") {
  //     setOption(Option);
  //     setIsEditData(data);
  //     setIsEdit(true);
  //   } 
    
  //   else if (Option === "history") {
  //     setOption(Option);
  //   //   dispatch(
  //   //     getCurrencyHistory({
  //   //       CurrencyId: data.CurrencyId,
  //   //       formID: 13,
  //   //       // Approved_By: ,
  //   //     })
  //   //   );
  //     setIsVersionHistory(true);
  //   } 
  //   else if (Option === "delete") {
      
  //     setOption(Option);
  //       setIsDeleteData(data);

     
  //  }
  // };
  // useEffect(() => {
  //   const handleTabClose = async (event: BeforeUnloadEvent) => {
  //     const data = recordLockUpdate();
  //     console.log(data, "dataLock");
  //     if (data.length > 0) {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       try {
  //         await dispatch(UpdateRecordLock(data.pop())).unwrap();
  //       } catch (error) {
  //         console.error("Failed to update record lock:", error);
  //       }
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleTabClose);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  // }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
             Cost Approval Metrics
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={currencyFormData}
                isActivekey="status"
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={true}
                showhistory={false}
                showDelete={true}
                showView={true}
                showEdit={true}
                showExports={true}
                pageName="Cost Approval Metrics"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"xl"}
        minHeight={""}
        header={"Add Cost Approval Metrics"}
        contentNode={
          // <></>
          <AddContractCostApproval
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"xl"}
        minHeight={"50vh"}
        header={Option === "view" ? "View Cost Approval" : "Update Cost Approval"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddContractCostApproval
                view={false}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddContractCostApproval
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
              // <></>
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
          //dispatch(updateVersionHistory([]));
        }}
        maxWidth={"xl"}
        header={"Add Cost Approval "}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={versionHistoryData}
              rows={currencyFormData}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showDelete={true}
              showhistory={false}
              showView={false}
              showEdit={false}
              showExports={true}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Sub Department Version History"
            />
          </>
        }
      />
      
      <ModalPopUp
      onDeleteMethod={handleStatusChange} 
        //onDeleteMethod={() => Currency()}
        isDelete
        onHide={() => setIsDeleteClause(false)}
        show={isDeleteClause  }
        header={"Confirm"}
        text={`Are you sure you want to ${isDeleteData?.isActive === ACTIVE ? "Deactivate" : "Activate"} the selected Cost Approval`}
        ActionName={isDeleteData?.isActive === ACTIVE ? INACTIVE : ACTIVE}
        
      />

      
  
    </>

    
  );
};
