import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  ListItemButton,
  Typography,
} from "@mui/material";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import { textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import { useTheme } from "@mui/material/styles";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import CustomChip from "../../../common/dynamicChip/CustomChip";
import CustomSvg from "../../../common/CustomSvg";
import { useSelector } from "react-redux";
import { LovConfig } from "../../../config/config";
import { getServiceCatalogList } from "../../../store/slices/serviceCatalog";
import { getLovList } from "../../../store/slices/lovMasterData";
import { CommonTooltip } from "../../../common/CommonTooltip/CommonTooltip";
import { updateLoader } from "../../../store/slices/loader";

export interface SearchServicesProps {
  updateSelectedServices: any;
  selectedServices: any[];
  loaderName?: string;
  clickLoadFunction?: any;
  showToolTip?: boolean;
  showContent?:string;
  reset?:boolean;
  updateDiscard?:any;
  view?:boolean;
}

const serviceTypeAllOption = { LovId: "All", Options: "All" };
const serviceOwnerAllOption = { ServiceOwnerName: "All", ServiceOwnerId: "All" };

const SearchServices: React.FC<SearchServicesProps> = ({updateSelectedServices, selectedServices=[], loaderName="Load Service Catalog", clickLoadFunction= null, showToolTip=false, showContent="", reset=false, updateDiscard, view=false}) => {
  const dispatch = useAppDispatchThunk();
  const [loader, setLoader] = useState<boolean>(false);
  const theme = useTheme();

  const [serviceType, setServiceType] = useState<any[]>([]);
  const [serviceOwner, setServiceOwner] = useState<any[]>([]);

  const [selectedServiceType, setSelectedServiceType] = useState<any>("");
  const [selectedServiceOwner, setSelectedServiceOwner] = useState<any>("");

  const [AssesmentType, SetAssesmentType] = React.useState<any[]>([]);

  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);

  const [availableServices, setAvailableServices] = useState<any>([]);
  const [allservices, setAllservices] = useState<any>([]);

  const [serachServicestext, setSerachServicestext] = useState<string>(""); 

  useEffect(() => {
    const result = lovTable.filter((item: any) => item.Name === "Service Type") || [];
    result.push(serviceTypeAllOption);
    setServiceType(result);
    //setSelectedServiceType("All");
    const filteredAssessment_Type = lovTable.filter((item: any) => item.Name === LovConfig.Assessment_Type) || [];
    SetAssesmentType(filteredAssessment_Type)
  }, [lovTable]);

  useEffect(()=>{
      if(reset){
        setSelectedServiceType("");
        setSelectedServiceOwner("");
        setAvailableServices([]);
        setSerachServicestext("");
        updateDiscard(false);
      }
  },[reset])

  useEffect(() => {
    if (selectedServiceType) {
      const result = Object.values(
        ActiveServiceCatlogdata.reduce((acc, obj: any) => {
          if (obj.ServiceType === selectedServiceType || selectedServiceType === "All") {
            return { ...acc, [obj.ServiceOwnerId]: obj };
          } else return acc;
        }, {})
      );
      if (result.length > 1) result.unshift(serviceOwnerAllOption);
      setServiceOwner(result);
      setSelectedServiceOwner("");
    }
  }, [selectedServiceType]);

  const getServices = () => {
    if(clickLoadFunction && clickLoadFunction!== null){
      clickLoadFunction()
    }
    if (selectedServiceType && selectedServiceOwner) {
      const result = ActiveServiceCatlogdata.filter(
        (item) =>
          (item.ServiceType === selectedServiceType || selectedServiceType === "All") &&
          (item.ServiceOwnerId === selectedServiceOwner || selectedServiceOwner === "All")
      );
      console.log(result)
      setAvailableServices(result);
      setAllservices(result)
    } 
    //else setUnmappedService([]);
  };

  const handleToggle = (value: any) => {
    const currentIndex = selectedServices.findIndex((item) => item === value.ServiceCatalogId);
    const newChecked = [...selectedServices];
    if (currentIndex === -1) {
      newChecked.push(value.ServiceCatalogId);
    } else {
      newChecked.splice(currentIndex, 1);
    }    
    updateSelectedServices(newChecked);
  };

  useEffect(()=>{
    if(ActiveServiceCatlogdata.length>0){
        dispatch(updateLoader(false));
    }
  },[ActiveServiceCatlogdata])

  useEffect(() => {
    dispatch(getLovList({ Name: "" }));
    dispatch(getServiceCatalogList());
  },[])
  return (
    <Box
      sx={{
        mt: 1,
        width: "100%",
        py: 2,
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <CustomSelect
            name={"Service Type"}
            valueKey={"LovId"}
            optionLabelKey="Options"
            options={serviceType}
            key={`${selectedServiceType}_id`}
            //disabled={view}
            label={"Service Type"}
            value={selectedServiceType}
            onChange={(id) => setSelectedServiceType(id)}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
        >
          <CustomSelect
            label={"Service Group"}
            name={"Service Group"}
            valueKey={"ServiceOwnerId"}
            optionLabelKey="ServiceOwnerName"
            options={serviceOwner}
            key={`${selectedServiceOwner}_id`}
            value={selectedServiceOwner}
            onChange={(id) => setSelectedServiceOwner(id)}
          />
        </Grid>
        <Grid
          item
          xs={textFieldWidth.xs}
          sm={textFieldWidth.sm}
          md={textFieldWidth.md}
          lg={textFieldWidth.lg}
          xl={textFieldWidth.xl}
          sx={{
            justifyContent: "left",
            display: "flex",
            paddingTop: "18px",
          }}
        >
          <CustomButton
            type="reset"
            name={loaderName}
            disabled={selectedServiceType && selectedServiceOwner ? false : true}
            onClick={() => {
              getServices();
            }}
          />
          {showToolTip ? (
            <Box sx={{ pl: 1, mt: "-7px" }}>
              <CommonTooltip placement={"top"} arrow={true} content={showContent} />
            </Box>
          ) : null}
        </Grid>
        {serachServicestext.length > 0 || availableServices.length > 0 ? (
          <React.Fragment>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
            >
              <CustomTextField
                name={"Search Services"}
                //required={true}
                //readOnly={view}
                label={"Search Service"}
                value={serachServicestext}
                onChange={(value) => {
                  setSerachServicestext(value);
                  setAvailableServices(
                    allservices.filter((data: any) => data.ServiceName.toLowerCase().includes(value.toLowerCase()))
                  );
                }}
              />
            </Grid>
            <Grid
              item
              xs={textFieldWidth.xs}
              sm={textFieldWidth.sm}
              md={textFieldWidth.md}
              lg={textFieldWidth.lg}
              xl={textFieldWidth.xl}
              sx={{
                justifyContent: "left",
                display: "flex",
                paddingTop: "18px",
              }}
            >
              <CustomChip
                label="Clear All"
                color="primary"
                onClick={() => {
                  updateSelectedServices([]);
                }}
                icon={<CustomSvg color={"#ffff"} size="20px" name="clearAll_icon" />}
              />
            </Grid>{" "}
          </React.Fragment>
        ) : null}
      </Grid>
      {ActiveServiceCatlogdata.length > 0 && selectedServices.length > 0 ? (
        
          <Grid container spacing={2}>
            <Grid
              item
              xs={textFieldWidth.xs}
              sx={{
                justifyContent: "left",
                display: "flex",
                paddingTop: "18px",
                columnGap: "10px",
                flexWrap: "wrap",
                rowGap: "10px"
              }}
            >
              {ActiveServiceCatlogdata.length > 0 &&
                selectedServices.length > 0 &&
                ActiveServiceCatlogdata.filter((serviceItem: any) => {
                  let index = selectedServices.findIndex((item) => item === serviceItem.ServiceCatalogId);
                  return index > -1;
                }).map((filteredServiceItem: any) => {
                  return (
                    <CustomChip
                      label={`${filteredServiceItem.ServiceName} - ${filteredServiceItem.Summary}`}
                      variant="outlined"
                      color="primary"
                      onDelete={
                        view
                          ? null
                          : () => {
                              handleToggle(filteredServiceItem);
                            }
                      }
                    />
                  );
                })}
            </Grid>
          </Grid>
        
      ) : null}
      {availableServices && availableServices.length > 0 ? (
        <Grid
          container
          spacing={2}
          sx={{
            maxHeight: "210px",
            //minHeight: "100px",
            overflowY: "scroll",
            //scrollbarWidth: "none",
          }}
        >
          {availableServices && availableServices.length > 0
            ? availableServices.map((serviceItem: any, index: number) => {
                return (
                  <Grid
                    item
                    xs={textFieldWidth.md}
                    sx={{
                      display: "flex",
                    }}
                  >
                    <ListItemButton
                      key={serviceItem.ServiceName}
                      role="listitem"
                      onClick={() => {
                        handleToggle(serviceItem);
                      }}
                      disabled={view}
                      sx={{
                        justifyContent: "flex-start",
                        wordBreak: "break-word",
                        pl: 0,
                      }}
                    >
                      <CustomCheckBox
                        value={selectedServices.filter((item) => item === serviceItem.ServiceCatalogId).length > 0}
                        Width={"15%"}
                        readOnly={view}
                      />
                      <Typography variant="subtitle2">
                        {serviceItem.Engage === "single"
                          ? `${serviceItem.ServiceName} - ${serviceItem.Summary} *`
                          : `${serviceItem.ServiceName} - ${serviceItem.Summary}`}
                      </Typography>
                    </ListItemButton>
                  </Grid>
                );
              })
            : null}
        </Grid>
      ) : null}
    </Box>
  );
};

export default SearchServices;
