import { Box, Stack, Typography, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CustomSelect from "../../common/dynamicInputs/CustomSelect";
import CustomDatePicker from "../../common/dynamicInputs/CustomDatePicker";
import CustomButton from "../../common/dynamicButton/CustomButton";
import MaterialDynamicGrid from "../../common/dynamicDataGrid/MaterialDynamicGrid";
import { RootState, useAppDispatch } from "../../store/store";
import CustomDialog from "../../common/dynamicDialog/CustomDialog";
import { AddSubDepartmentMaster } from "./AddSubDeparment";
import { IMenu } from "../../models/loggin.type";
import { AddRecordLock, GetRecordLock, UpdateRecordLock } from "../../store/slices/recordLock";
import { updateToaster } from "../../store/slices/loader";

export default function SubDepartmentMaster() {
  const [showCustomColumnForm, toggleCustomColumnForm] = useState(false);
  const [permssionPage, setPermissionForpage] = useState<IMenu | null>(null);
  const [addForm, setAddForm] = useState(false);
  const [Option, setOption] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [formDisplay, setformDisplay] = useState(false);
  const [filterTableData, setFilterTableData] = useState<any[]>([]);
  const [IsEditData, setIsEditData] = useState<any[]>([]);
  const [isVersionHistory, setIsVersionHistory] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [getRecordLock, setGetRecordLock] = useState<any>([]);
  const { recordLock, isRecordLockAdded, isRecordLockFetched } = useSelector((state: RootState) => state.recordLock);

  const { user, menuData, userLoginResponse, currentPagePermissionData } = useSelector(
    (state: RootState) => state.loggerData
  );
  const dispatch = useAppDispatch();

  let Table_Data = [
    {
      id: "1",
      Code: "001",
      FunctionName: "HR",
      SubDepartmentName: " Recruitment and Talent Acquisition",
      Status: "Active",
    },
    {
      id: "2",
      Code: "002",
      FunctionName: "Finance",
      SubDepartmentName: " Accounting and Financial Reporting",
      Status: "Active",
    },
    { id: "3", Code: "003", FunctionName: "Development", SubDepartmentName: " Business Development", Status: "Active" },
    { id: "4", Code: "004", FunctionName: "HR", SubDepartmentName: " Compensation and Benefits", Status: "Active" },
    { id: "5", Code: "005", FunctionName: "Finance", SubDepartmentName: " Taxation and Compliance", Status: "Active" },
  ];

  const columns: any = {
    table_name: "Sub Department Master",
    isAction: true,
    columns: [
      // {
      //     Field: "Code",
      //     DisplayName: "Code",
      //     FieldType: "string",
      // },

      {
        Field: "FunctionName",
        DisplayName: "Function Name",
        FieldType: "string",
      },

      {
        Field: "SubDepartmentName",
        DisplayName: "Sub Department Name",
        FieldType: "string",
      },

      {
        Field: "Status",
        DisplayName: "Status",
        FieldType: "string",
      },
    ],
  };

  const recordLockGet = (id: any) => {
    const recordLockData = {
      RecordId: id,
      MenuId: permssionPage?.MenuId,
      ModuleName: "Location",
    };
    return recordLockData;
  };

  const handleAddRecordLock = (id: number) => {
    const recordLockData = {
      RecordId: id,
      UserId: userLoginResponse?.UserId || 0,
      CreatedBy: userLoginResponse?.UserName || "",
      MenuId: permssionPage?.MenuId,
      ModuleName: "Location",
      Activity: "edit",
    };
    return recordLockData;
  };
  const handleRecordLockCheck = async (recordId: number) => {
    const resultAction = await dispatch(GetRecordLock(recordLockGet(recordId)));
    if (GetRecordLock.fulfilled.match(resultAction)) {
      const recordLockData = resultAction.payload;
      if (recordLockData && recordLockData.length > 0) {
        setIsEditing(true);
        console.log(recordLock, resultAction.payload, "recordLock");
        dispatch(
          updateToaster({
            isToaster: true,
            toasterMsg: `${resultAction.payload[0]?.CreatedBy} is Editing`,
            isTosterFailed: true,
          })
        );
        return false;
      }
    }
    return true;
  };
  const recordLockUpdate = () => {
    if (recordLock && recordLock.length > 0) {
      return [
        {
          RecordlockId: recordLock[0].RecordlockId,
          RecordId: recordLock[0].RecordId,
          IsActive: false,
        },
      ];
    }
    return [];
  };
  useEffect(() => {
    if (!isEdit) {
      const data = recordLockUpdate();
      if (data.length > 0) {
        dispatch(UpdateRecordLock(data.pop()));
      }
    }
  }, [isEdit]);

  const handleOpen = async (data: any, Option: string) => {
    if (Option === "edit") {
      console.log(data, Option, "Option");

      //   setOption(Option);
      //   setIsEditData(data);
      //   setIsEdit(true);
      setIsEditData(data);
      // setIsEdit(true);
      setOption(Option);
      const canEdit = await handleRecordLockCheck(data?.id);
      if (canEdit) {
        const dispatchData = handleAddRecordLock(data?.id);
        await dispatch(AddRecordLock(dispatchData));
        //setIsEditOpen(true);
        const resultAction = await dispatch(GetRecordLock(recordLockGet(data?.id)));
        if (GetRecordLock.fulfilled.match(resultAction)) {
          const recordLockData = resultAction.payload;
          console.log(recordLockData, resultAction, isEditing, "recordLockData");
          setGetRecordLock(recordLockData);
        }

        setIsEdit(true);
        // Set timeout to close the popup after 2.5 minutes
        setTimeout(() => {
          setIsEdit(false);
          const data = recordLockUpdate();
          if (data.length > 0) {
            dispatch(UpdateRecordLock(data.pop()));
          }
        }, 150000);
      }

      // setUserId(data.UserId);
    } else if (Option === "view") {
      console.log(data, Option, "Option");

      setOption(Option);
      setIsEditData(data);
      setIsEdit(true);
    } else if (Option === "history") {
      console.log(data, Option, "Option");

      setOption(Option);
      setIsVersionHistory(true);
    }
  };

  useEffect(() => {
    if (currentPagePermissionData !== null) {
      setPermissionForpage(currentPagePermissionData);
    }
  }, [currentPagePermissionData]);

  useEffect(() => {
    const handleTabClose = async (event: BeforeUnloadEvent) => {
      const data = recordLockUpdate();
      console.log(data, "dataLock");
      if (data.length > 0) {
        event.preventDefault();
        event.returnValue = "";
        try {
          await dispatch(UpdateRecordLock(data.pop())).unwrap();
        } catch (error) {
          console.error("Failed to update record lock:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          // backgroundColor: "#ffff",
          // backgroundColor: "background.default",
          // px: 3,
          // py: 4,
          // borderRadius: "10px",
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Box sx={{ display: "flex", width: "25%", }} >
                            <CustomSelect
                                name={"listName"}
                                valueKey={"id"}
                                optionLabelKey="lable"
                                options={location_Data}
                                value={formData.listName}
                                onChange={handleChange}
                                required={true}
                                label={"Select List Name"}
                            />
                        </Box> */}
            <Typography variant="h6" color={"text.primary"}>
              {" "}
              Configure Sub Department Master
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column" }}></Box>
          </Box>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MaterialDynamicGrid
                data={columns}
                rows={Table_Data}
                handleAction={handleOpen}
                enableZoomIcon={true}
                // showDelete={permssionPage?.IsDelete === 1 ? true : false}
                handleAdd={() => setAddForm(true)}
                showAdd={permssionPage?.IsAdd === 1}
                showhistory={false}
                showDelete={permssionPage?.IsDelete === 1}
                showView={permssionPage?.IsView === 1}
                showEdit={permssionPage?.IsEdit === 1}
                showExports={permssionPage?.IsExport === 1}
                pageName="Sub Department Master"
                userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              />
            </Box>
          </Box>
        </Stack>
      </Box>

      <CustomDialog
        show={addForm}
        onHide={() => setAddForm(false)}
        maxWidth={"md"}
        minHeight={"50vh"}
        header={"Add Sub Department"}
        contentNode={
          // <></>
          <AddSubDepartmentMaster
            view={false}
            closePopUp={() => {
              setAddForm(false);
            }}
          />
        }
      />

      <CustomDialog
        show={isEdit}
        onHide={() => {
          // if (Option === "history") {
          //   dispatch(updateVersionHistory([]));
          // }
          setIsEdit(false);
        }}
        maxWidth={"md"}
        minHeight={"50vh"}
        header={Option === "view" ? "View Sub Department" : "Update Sub Department"}
        contentNode={
          <>
            {Option === "edit" && (
              <AddSubDepartmentMaster
                view={false}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                edit={isEdit}
                isEditData={IsEditData}
              />
              // <></>
            )}
            {Option === "view" && (
              <AddSubDepartmentMaster
                view={true}
                closePopUp={() => {
                  setIsEdit(false);
                }}
                isEditData={IsEditData}
              />
            )}
          </>
        }
      />

      <CustomDialog
        show={isVersionHistory}
        onHide={() => {
          setIsVersionHistory(false);
        }}
        maxWidth={"lg"}
        header={"Sub Department Version History"}
        contentNode={
          <>
            {" "}
            <MaterialDynamicGrid
              data={columns}
              rows={Table_Data}
              handleAction={handleOpen}
              enableZoomIcon={false}
              showhistory={false}
              showDelete={permssionPage?.IsDelete === 1}
              showView={permssionPage?.IsView === 1}
              showEdit={permssionPage?.IsEdit === 1}
              showExports={permssionPage?.IsExport === 1}
              userId={userLoginResponse && userLoginResponse?.UserId ? userLoginResponse?.UserId : 0}
              pageName="Sub Department Version History"
            />
          </>
        }
      />
    </>
  );
}
