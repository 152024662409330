import { Box, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomBreadcrumbs from "../../../common/dynamicBreadCrumbs/CustomBreadCrumbs";
import { ACTIVE, addWorkingDays, EstimateSpend, getDateOrConvertSameTime, textFieldWidth } from "../../../utils/constants";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import CustomNumberField from "../../../common/dynamicInputs/CustomNumberField";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomDatePicker from "../../../common/dynamicInputs/CustomDatePicker";
import CustomMultiSelect from "../../../common/dynamicInputs/CustomMultiSelect";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import { ProcessDetails } from "./ProcessDetails";
import { getListOfValues, getWorkList } from "../../../store/slices/workFlowSlice";
import userData, { getUsersList } from "../../../store/slices/userData";
import { addRolesAndResponsibility } from "../../../store/slices/rolesAndResponsiblity";
import rolesAndResponsibilityService from "../../../store/services/rolesAndResponsibilityService";
import { getCurrencyList } from "../../../store/slices/currencyData";
import { ApplicationParameterName, Assessment_Type, ToasterActivityConfig } from "../../../config/config";
import { getHolidayList } from "../../../store/slices/holidayMasterData";
import ModalPopUp from "../../../common/modalComponent/ModalPopUp";
export interface RolesandResponsibilityProps {
  view?: boolean;
  isViewOnly?: boolean;
  isEditData?: any;
  renewal?: boolean;
  // setIsEdit?:boolean;
  isEdited?: any;
  closePopUp?: () => void;
  onchangeLable?: any;
  isAdded?: any;
  masterId?: string;
  onClose?: () => void;
  isDraftData?: any;
  prePageName?: any;
  ModuleId?: number | undefined;
  isFromVendormaster?: boolean
}

interface FormData {
  Assessment_Questionaire_Mapping_Id?: any;
  Project_Name?: any;
  Project_Description?: any;
  Project_Start_Date?: any;
  Project_End_Date?: any;
  Estimate_Spend?: any;
  Currency_Id?: any;
  Workflow_Id?: any;
  Vendor_Name?: any;
  Tat?: any;
  Start_Date?: any;
  Target_Date?: any;
}

interface ValidationErrors {
  [key: string]: string;
}

interface WorkflowContextProps {
  workflowProcess: any[];
  validationErrors: ValidationErrors;
  handleInputChange: (index: number, field: string, value: any) => void;
}

export const ProcessDetailContext = createContext<WorkflowContextProps | undefined>(undefined);

export const RolesandResponsibility: React.FC<RolesandResponsibilityProps> = ({
  view,
  isEditData,
  renewal,
  isFromVendormaster,
  onClose,
  prePageName,
  isViewOnly,
}) => {
  const { applicationParameterData } = useSelector((state: RootState) => state.applicationParameter);
  const { HolidayTable } = useSelector((state: RootState) => state.HolidayMasterData);
  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { workFlowsData } = useSelector((state: RootState) => state.workFlow);
  const { user, userLoginResponse } = useSelector((state: RootState) => state.loggerData);
  const { allUsers } = useSelector((state: RootState) => state.userData);
  const dispatch = useAppDispatch();
  const { CurrencyData } = useSelector((state: RootState) => state.CurrencyData);
  const [workflowProcess, setWorkflowProcess] = useState<any[]>([]);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [projectNameWarning, setProjectNameWarning] = useState("");
  const [invalidWorkflowWarning, setInvalidWorkflowWarning] = useState("");
  const [getCurrency, setCurrency] = useState<any[]>([]);
  const [HolidayData, setHolidayData] = useState<any[]>([]);
  const [vendorList, setvendorList] = useState<any[]>([]);
  const [workFlowList, setworkFlowList] = useState<any[]>([]);
  const [ischangeForm, setischangeForm] = useState<boolean>(false);
  const [defaultEstimateSpend, setDefaultEstimateSpend] = useState<any>(1);
  const [ApplicationParameterData, setApplicationParamaterData] = useState<any[]>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [IsCancel, setIsCancel] = useState<boolean>(false);


  useEffect(() => {
    dispatch(getWorkList());
    dispatch(
      getUsersList({
        FormID: 2,
      })
    );
    callMasterData();
    dispatch(
      getHolidayList({
        FormID: 2,
      })
    );
  }, []);
  console.log(isEditData, 'isEditData');

  useEffect(() => {
    try {
      if (Array.isArray(applicationParameterData)) {
        const filteredRegionalData = applicationParameterData.filter(
          (item: any) => item.Parameter_Name === ApplicationParameterName.EstimateSpend
        );
        setApplicationParamaterData(filteredRegionalData.filter((item: any) => item.IsActive === ACTIVE));
      } else {
        console.error("Invalid data: applicationParameterData is not an array");
      }
    } catch (error) {
      console.error("An error occurred while filtering the data:", error);
    }
  }, [applicationParameterData]);

  useEffect(() => {
    if (HolidayTable && HolidayTable.length > 0) {
      const ActiveHolidayList = HolidayTable.filter((item: any) => item.IsActive === "Active");
      console.log(ActiveHolidayList, "ActiveHolidayList");

      setHolidayData(ActiveHolidayList);
    }
  }, [HolidayTable]);

  useEffect(() => {
    if (CurrencyData && CurrencyData.length > 0) {
      const ActiveCurrencies = CurrencyData.filter((item: any) => item.IsActive === ACTIVE);
      const MergedCurrencies = ActiveCurrencies.map((item: any) => ({
        ...item,
        mergedCurrency: `${item.CurrencyCode} (${item.Symbol}) `,
      }));
      setCurrency(MergedCurrencies);
    }
  }, [CurrencyData]);

  const callMasterData = () => {
    dispatch(getCurrencyList({ FormID: 0 }));
  };

  let formData = {
    Project_Name: isEditData ? isEditData?.Project_Name : "",
    Project_Description: isEditData ? isEditData?.Project_Description : "",
    Project_Start_Date: isEditData
      ? renewal
        ? isEditData?.Project_End_Date
          ? dayjs(isEditData?.Project_End_Date).add(1, "day")
          : null
        : isEditData?.Project_Start_Date
          ? dayjs(isEditData?.Project_Start_Date)
          : null
      : null,
    Project_End_Date: isEditData
      ? renewal
        ? null
        : isEditData?.Project_End_Date
          ? dayjs(isEditData?.Project_End_Date)
          : null
      : null,
    Estimate_Spend: isEditData ? isEditData?.Estimated_Spend ? isEditData?.Estimated_Spend : '' : '',
    Currency_Id: isEditData ? isEditData?.Currency_Id : '',
    Workflow_Id: renewal ? "" : isEditData ? (isViewOnly ? isEditData?.Workflow_Id : isEditData?.Id) : "",
    Vendor_Name: isEditData ? isEditData?.Vendor_Name : "",
    Vendor_Id: isEditData ? isEditData?.Vendor_Id : 0,
    Tat: isEditData ? (isEditData?.TAT ? isEditData?.TAT : "") : "",
    Start_Date: (isEditData && !renewal) ? dayjs(isEditData?.Workflow_Start_Date) : dayjs(),
    Target_Date: (isEditData && !renewal) ? (isEditData?.Workflow_End_Date ? dayjs(isEditData?.Workflow_End_Date) : null) : null,
    Renewal_Project_Id: renewal ? (isEditData && isEditData?.Project_Detail_Id) ? isEditData?.Project_Detail_Id : 0 : 0,
  };
  // const [FormDatas,setFormDatas]=useState<formData[]>([]);
  // setFormDatas()
  const {
    handleSubmit: handleAQMMappingForm,
    reset,
    setValue,
    formState: { errors: errorrRolesandResponsibility, isDirty },
    control,
    watch,
    getValues,
    trigger,
  } = useForm<FormData>({
    defaultValues: formData,
    mode: "onChange",
  });

  useEffect(() => {
    if (ApplicationParameterData.length > 0 && !isViewOnly) {
      const lastParameterValue = ApplicationParameterData[ApplicationParameterData.length - 1]?.Parameter_Value;
      setValue("Estimate_Spend", lastParameterValue);
      trigger("Estimate_Spend");
      setDefaultEstimateSpend(lastParameterValue);
      setIsChanged(true);
    }
  }, [ApplicationParameterData]);
  const setWorkflowSteps = (Allsteps: any) => {
    try {
      let parsedWorkflowProcess = Allsteps;
      if (Array.isArray(parsedWorkflowProcess)) {
        parsedWorkflowProcess = parsedWorkflowProcess.reduce((acc: any, item: any) => {
          if (item.IsActive)
            acc.push({
              ...item,
              Process_Name: item?.ProcessName,
              DepartmentName: item?.DepartmentId
                ? item?.DepartmentId?.split(",").map((ditem: any) => parseInt(ditem, 10))
                : [],
              Role: item?.RoleId,
              PrimaryUser: [],
              SecondaryUser: [],
              EscalateTo: [],
            });
          return acc;
        }, []);
      }
      setWorkflowProcess(parsedWorkflowProcess);
    } catch (error) {
      console.error("Error parsing WorkflowProcessAsJson:", error);
    }
  };

  const SetSelected = (assessmentTypeValue: any) => {


    const assessmenettypeWorkflow: any =
      assessmentTypeValue == Assessment_Type.Advanced
        ? ApplicationParameterName.CurrentAdvanceWorkflowforRenewalvob
        : ApplicationParameterName.CurrentBasicWorkflowforRenewalvob;

    if (assessmenettypeWorkflow) {
      // find workflow code
      let finedWorkflowCode: any = applicationParameterData.find(
        (applicationParamitem) => applicationParamitem.Parameter_Name === assessmenettypeWorkflow
      )?.Parameter_Value;
      console.log(finedWorkflowCode, "worflowCode 1");
      // find workflow used workflow code
      let finedWorkflow: any =
        workFlowList && finedWorkflowCode
          ? workFlowList?.find(
            (workflowitem) => workflowitem.WorkflowCode == finedWorkflowCode && workflowitem.IsActive == "Active"
          )
          : "";
      console.log(finedWorkflow, 'finedWorkflow');

      if (finedWorkflow && finedWorkflow.WorkflowProcessAsJson) {
        setInvalidWorkflowWarning("");
        setValue("Workflow_Id", finedWorkflow?.Id);
        let parsedWorkflowProcess = JSON.parse(finedWorkflow.WorkflowProcessAsJson);
        setWorkflowSteps(parsedWorkflowProcess);
      } else {
        setWorkflowProcess([]);
        if (!finedWorkflow) {
          setValue("Workflow_Id", "");
          setInvalidWorkflowWarning("Existing Workflow is Inactive Please select valid Workflow");
        }
      }
    };
  }
  useEffect(() => {
    if (isEditData) {
      if (isViewOnly && isEditData.WorkflowProcessInstance) {
        let parsedWorkflowProcess = JSON.parse(isEditData.WorkflowProcessInstance);
        if (parsedWorkflowProcess) {
          const updatedDataArray = parsedWorkflowProcess?.map((item: any) => ({
            ...item,
            ProcessName: item?.Process_Name,
            PrimaryUser: item.Primary_User ? item.Primary_User.split(",").map(Number) : [],
            SecondaryUser: item.Secondary_User ? item.Secondary_User.split(",").map(Number) : [],
            EscalateTo: item.Escalate_To ? item.Escalate_To.split(",").map(Number) : [],
            DepartmentName: item.Department ? item.Department.split(",").map(Number) : [],
            RoleId: item.Role ? item.Role : "",
          }));
          console.log(updatedDataArray, "parsedWorkflowProcessPreview");
          setWorkflowProcess(updatedDataArray);
        }
      } else {
        try {
          if (renewal) {
            if (isEditData?.Workflow_Id) {
              SetSelected(isEditData?.Workflow_Id);
            }
          } else {
            if(isEditData.WorkflowProcessAsJson){
              let parsedWorkflowProcess = JSON.parse(isEditData.WorkflowProcessAsJson);
            setWorkflowSteps(parsedWorkflowProcess);
            }else{
              SetSelected(isEditData?.Workflow_Id);
            }
           
          }
        } catch (error) {
          console.error("Error parsing WorkflowProcessAsJson:", error);
        }
      }
    }
  }, [isEditData, renewal, workFlowList, isViewOnly]);



  const projectStartDate = watch("Project_Start_Date");
  const startDate = watch("Start_Date");
  const tat = watch("Tat");
  const targetDate = watch("Target_Date");

  const biggerDatecheck = () => {
    const targetDateValue = watch("Target_Date");
    const projectStartDateValue = watch("Project_Start_Date");
    if (targetDateValue > projectStartDate) {
      return targetDateValue.add(1, 'day');
    } else {
      return projectStartDateValue;
    }
  };

  useEffect(() => {
    if (startDate && tat && !isNaN(tat) && !view) {
      const endDate = addWorkingDays(startDate, tat, HolidayData).toDate();
      setValue("Target_Date", dayjs(endDate));
    }
  }, [startDate, setValue, view, HolidayData]);

  useEffect(() => {
    if (startDate && !view) {
      if (tat && tat <= 90 * workflowProcess.length) {
        const endDate = addWorkingDays(startDate, tat, HolidayData).toDate();
        setValue("Target_Date", dayjs(endDate));
      }
    } else {
    }
  }, [tat, setValue, view, HolidayData]);

  const closeForm = (value: any) => {
    if (onClose && value === 0) {
      // if (isDirty) {
      //   // setchangeMaking(true);
      // } else {
      onClose();
      // }
    }
  };

  useEffect(() => {
    if (workFlowsData.length > 0) {
      let ActiveWorkflowList = workFlowsData.filter((item: any) => item?.IsActive === "Active");
      setworkFlowList(ActiveWorkflowList);
    }
  }, [workFlowsData]);

  const handleSubmitBoth = () => {
    validateAllFields();
    handleAQMMappingForm(handleSubmit)();
  };

  const handleSubmit = (data: any) => {
    if (validateAllFields()) {
      let worflowStartDate: any = getDateOrConvertSameTime(data.Start_Date?.$d, 'current');
      let filterDate: any[] = [];
      HolidayData.forEach((date: any) => {
        if (dayjs(data.Start_Date) <= dayjs(date?.Holiday_Date) && dayjs(data.Target_Date) >= dayjs(date?.Holiday_Date)) {
          filterDate.push(date)
        }
      })
      const transformedWorkflowProcesses = workflowProcess.map((process: any, index: number) => {
        let findThisstepEndDate: any = addWorkingDays(worflowStartDate, process.TAT, HolidayData).toDate();
        worflowStartDate = findThisstepEndDate;
        return {
          Id: 0,
          WorkflowId: data.Workflow_Id,
          ProcessSeqno: process.ProcessSeqno,
          ProcessName: process.ProcessName,
          ProcessType: process.ProcessType,
          ProcessId: process.ProcessId,
          ProcessNextStep: process.Process_NextStep,
          ConditionTobeChecked: process.ConditionTobeChecked ? process.ConditionTobeChecked : null,
          IfYes_GoTo: process.IfYes_GoTo ? process.IfYes_GoTo : null,
          IfNo_GoTo: process.IfNo_GoTo ? process.IfNo_GoTo : null,
          IsActive: true,
          Updated_By: user?.UserName || "",
          RoleId: process.RoleId,
          DepartmentId: process.DepartmentName?.toString() || "",
          TAT: process.TAT,
          PrimaryUser: process.PrimaryUser?.toString() || "",
          SecondaryUser: process.SecondaryUser?.toString() || "",
          EscalateTo: process?.EscalateTo?.toString() || "",
          Exit_Status: process?.Exit_Status,
          Branching: process?.Branching,
          expiry_date: getDateOrConvertSameTime(findThisstepEndDate, 'current')
        }
      });


      // Transform data
      const payload = {
        projectDetails: {
          Project_Detail_Id: 0,
          IsRenewal: renewal ? true : false,
          Project_Name: data.Project_Name,
          InstanceName: data.Project_Name,
          InstanceType: "VOB",
          Project_Description: data.Project_Description,
          Project_Start_Date: getDateOrConvertSameTime(data.Project_Start_Date?.$d, "start"),
          Project_End_Date: getDateOrConvertSameTime(data.Project_End_Date?.$d, "end"),
          Estimated_Spend: parseInt(data.Estimate_Spend, 10),
          Currency_Id: data?.Currency_Id,
          Renewal_Project_Id: data?.Renewal_Project_Id,
          WorkflowId: data.Workflow_Id,
          Vendor_Id: data?.Vendor_Id || 0,
          Vendor_Name: data?.Vendor_Name || "",
          Workflow_Start_Date: getDateOrConvertSameTime(data.Start_Date?.$d, 'current'),
          TAT: parseInt(data.Tat, 10),
          Workflow_End_Date: getDateOrConvertSameTime(data.Target_Date?.$d, 'current'),
          IsActive: true,
          Status: 0,
          Updated_By: user?.UserName || "",
          Updated_On: getDateOrConvertSameTime(),
          Approved_By: user?.UserName || "",
          Approved_On: getDateOrConvertSameTime(),
          workflowProcessInstance: transformedWorkflowProcesses,
        },
        UserId: userLoginResponse ? userLoginResponse.UserId : 0,
        ModuleName: "Roles and Responsibility",
        Activity: ToasterActivityConfig.create,
      };
      console.log(payload, "rr Payload", filterDate);
      dispatch(addRolesAndResponsibility(payload));
    } else {
      // Show validation errors
      console.log("Validation errors:", validationErrors);
    }
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    const newWorkflowProcess = [...workflowProcess];
    newWorkflowProcess[index][field] = value;

    if (field === "DepartmentName") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "RoleId") {
      newWorkflowProcess[index].PrimaryUser = [];
      newWorkflowProcess[index].SecondaryUser = [];
      newWorkflowProcess[index].EscalateTo = [];
    }
    if (field === "PrimaryUser") {
      let SecoundaryUserData = newWorkflowProcess[index].SecondaryUser;
      if (SecoundaryUserData && SecoundaryUserData?.length > 0) {
        const filteredSecondaryUsers = SecoundaryUserData.filter((user: any) => !value.includes(user));
        newWorkflowProcess[index].SecondaryUser = filteredSecondaryUsers;
      }
    }
    if (field === "PrimaryUser" || field === "SecondaryUser") {
      const escalateToSet = new Set();

      const usersList = [...newWorkflowProcess[index].SecondaryUser, ...newWorkflowProcess[index].PrimaryUser];

      usersList.forEach((userId: any) => {
        const managerId = allUsers.find((user: any) => user.UserId === userId)?.ManagerId;
        if (managerId) {
          escalateToSet.add(managerId);
        }
      });

      newWorkflowProcess[index].EscalateTo = Array.from(escalateToSet);
    }

    setValue(
      "Tat",
      newWorkflowProcess.reduce((n, { TAT, IsActive }) => (IsActive ? n + Number(TAT) : n), 0)
    );
    setischangeForm(true);
    setWorkflowProcess(newWorkflowProcess);
    validateField(index, field, value);
  };

  const Validatedepartmentuser = (selDepartments: any, selPrimaryusers: any) => {
    if (!selDepartments || !selPrimaryusers) return false;

    try {
      return selDepartments.some((dep_item: any) => {
        return selPrimaryusers.every((user_item: any) => {
          const userData = allUsers.find((user: any) => user.UserId === user_item);
          return userData?.DepartmentId !== dep_item;
        });
      });
    } catch {
      return true;
    }
  };

  const validateField = (index: number, field: string, value: any) => {
    let errors: { [key: string]: string } = { ...validationErrors };

    switch (field) {
      case "RoleId":
        if (!value) {
          errors[`${index}-RoleId`] = "Role is required";
        } else {
          delete errors[`${index}-RoleId`];
        }
        break;

      case "TAT":
        if (!value || isNaN(value)) {
          errors[`${index}-TAT`] = "Estimated Time is required";
        } else {
          if (value && value <= 0) {
            errors[`${index}-TAT`] = "Please enter valid Estimated Time";
          } else if (value && value > 90) {
            errors[`${index}-TAT`] = "Please enter below 90 days";
          } else {
            delete errors[`${index}-TAT`];
          }
        }
        break;
      case "PrimaryUser":
        if (!value || value?.length === 0) {
          errors[`${index}-PrimaryUser`] = "At least one primary user is required";
        } else {
          const CheckWorkflowProcess = [...workflowProcess];
          const department = CheckWorkflowProcess[index]?.DepartmentName || [];
          if (value && Validatedepartmentuser(department, value)) {
            errors[`${index}-PrimaryUser`] = "At least one primary user in selected department";
          } else {
            delete errors[`${index}-PrimaryUser`];
          }
        }
        break;
      // case "SecondaryUser":
      //   if (!value || value.length === 0) {
      //     errors[`${index}-SecondaryUser`] = "At least one secondary user is required";
      //   } else {
      //     delete errors[`${index}-SecondaryUser`];
      //   }
      //   break;
      // case "EscalateTo":
      //   if (!value) {
      //     errors[`${index}-EscalateTo`] = "At least one escalate to user is required";
      //   } else {
      //     delete errors[`${index}-EscalateTo`];
      //   }
      //   break;
      default:
        break;
    }

    setValidationErrors(errors);
    return errors;
  };

  const validateAllFields = () => {
    let isValid = true;
    let allErrors: { [key: string]: string } = {};

    workflowProcess.forEach((item, index) => {
      const roleErrors = validateField(index, "RoleId", item.RoleId);
      const departmentErrors = validateField(index, "DepartmentName", item.DepartmentName);
      const tatErrors = validateField(index, "TAT", item.TAT);
      const primaryUserErrors = validateField(index, "PrimaryUser", item.PrimaryUser);
      // const secondaryUserErrors = validateField(index, "SecondaryUser", item.SecondaryUser);
      // const escalateToErrors = validateField(index, "EscalateTo", item.EscalateTo);

      allErrors = {
        ...allErrors,
        ...roleErrors,
        ...departmentErrors,
        ...tatErrors,
        ...primaryUserErrors,
      };
    });

    setValidationErrors(allErrors);

    if (Object.keys(allErrors).length > 0 || workflowProcess?.length === 0) {
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <Box component="main" sx={{ height: "auto", width: "100%", overflow: "hidden", position: "relative" }}>
        {!isViewOnly ? (
          <Box sx={{ display: "flex", justifyContent: "left", mb: 2 }}>
            <CustomBreadcrumbs
              list={[
                { value: 0, name: prePageName ? prePageName : "Roles and Responsiblity" },
                { value: 1, name: `${renewal ? "Renewal" : view ? "View" : "Add New"}` },
              ]}
              onchange={closeForm}
            />
          </Box>
        ) : (
          <></>
        )}

        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <Typography variant="h6" color={"text.primary"}>
              {`${renewal ? "Renewal" : view ? "View" : "Add New"} Roles and Responsibility`}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                border: "1px solid #F7F7F7",
                padding: 2,
              }}
            >
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Project_Name"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Project / Service Name is Required",
                      validate: {
                        maxLength: (value) => {
                          if (value === undefined) return "Project Name is Required";
                          return value.length <= 100 || "Maximum 100 characters allowed";
                        },
                      },
                    }}
                    render={({ field }) => (
                      <CustomTextField
                        label={"Project / Service Name"}
                        name={"Project Name"}
                        value={field.value}
                        readOnly={view}
                        required={true}
                        onChange={field.onChange}
                        onBlur={async (e) => {
                          if (e.target.value) {
                            const res = await rolesAndResponsibilityService.getIsProjectExists({
                              projectName: e.target.value,
                            });
                            if (res.data) setProjectNameWarning("Project already exists, do you want to continue?");
                            else setProjectNameWarning("");
                          } else setProjectNameWarning("");
                        }}
                        error={Boolean(errorrRolesandResponsibility.Project_Name || projectNameWarning)}
                        helperText={
                          (errorrRolesandResponsibility.Project_Name &&
                            errorrRolesandResponsibility.Project_Name.message?.toString()) ||
                          projectNameWarning
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Project_Description"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Project Description is Required",
                      validate: {
                        maxLength: (value) => {
                          if (value === undefined || value.trim() === "") return "Project Description is Required";
                          return value.length <= 250 || "Maximum 250 characters allowed";
                        },
                      },
                    }}
                    render={({ field }) => (
                      <CustomMultilineTextField
                        label={"Project Description"}
                        value={field.value}
                        rows={4}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Project_Description)}
                        helperText={
                          errorrRolesandResponsibility.Project_Description &&
                          errorrRolesandResponsibility.Project_Description.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Project_Start_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "Service Start Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={(renewal && isEditData)
                          ? dayjs(isEditData?.Project_End_Date).add(1, "day") : dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Service Start Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Project_Start_Date)}
                        helperText={
                          errorrRolesandResponsibility.Project_Start_Date &&
                          errorrRolesandResponsibility.Project_Start_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Project_End_Date"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "Service End Date is Required",
                      validate: {
                        startDateValidation: (value) => {
                          if (projectStartDate)
                            return (
                              dayjs(value) >= dayjs(projectStartDate) ||
                              "Service End Date cannot be lesser than Project Start Date ."
                            );
                        },
                        dateValidation: (value) => {
                          if (targetDate)
                            return (
                              dayjs(value) >= dayjs(targetDate) ||
                              "Service duration cannot be shorter than the assessment duration."
                            );
                        },
                      },
                    }}
                    render={({ field }) => (
                      <CustomDatePicker
                        minDate={dayjs(biggerDatecheck())}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"Service End Date"}
                        value={field.value ? dayjs(field.value) : null}
                        required={true}
                        readOnly={view}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Project_End_Date)}
                        helperText={
                          errorrRolesandResponsibility.Project_End_Date &&
                          errorrRolesandResponsibility.Project_End_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Box sx={{ display: "flex", gap: 0.4 }}>

                    <Box sx={{ width: "48%", paddingRight: "10px" }}>
                      <Controller
                        name="Currency_Id"
                        control={control}
                        rules={{
                          required: "currency is required",
                        }}
                        render={({ field }) => (
                          <CustomSelect
                            name={"Currency_Id"}
                            valueKey={"CurrencyId"}
                            optionLabelKey="mergedCurrency"
                            options={getCurrency || []}
                            value={field.value}
                            onChange={field.onChange}
                            readOnly={view}
                            required={true}
                            label={"Currency"}
                            error={Boolean(errorrRolesandResponsibility.Currency_Id)}
                            helperText={
                              errorrRolesandResponsibility.Currency_Id &&
                              errorrRolesandResponsibility.Currency_Id.message?.toString()
                            }
                          />
                        )}
                      />
                    </Box>
                    <Controller
                      name="Estimate_Spend"
                      defaultValue={''}
                      control={control}
                      rules={{
                        required: "Estimated Spend is required",
                        validate: {
                          validNumber: (value) => {
                            const number = parseInt(value, 10);
                            const maxDigits = 15;

                            if (isNaN(number)) {
                              return "Please enter a valid number for Estimated Spend";
                            }

                            // Validate number of digits
                            if (value.length > maxDigits) {
                              return `Estimated Spend should be ${maxDigits} digits or less`;
                            }
                            // Validate number of digits
                            if (value < defaultEstimateSpend) {
                              return `Estimated Spend should be greater than or equal to ${defaultEstimateSpend} `;
                            }

                            return true;
                          },
                        },
                      }}
                      render={({ field }) => (
                        <>

                          <CustomNumberField
                            label={"Estimate Spend"}
                            name={"Estimate Spend"}
                            readOnly={view}
                            required={true}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              setIsChanged(true);
                            }}
                            error={Boolean(errorrRolesandResponsibility.Estimate_Spend)}
                            helperText={
                              errorrRolesandResponsibility.Estimate_Spend &&
                              errorrRolesandResponsibility.Estimate_Spend.message?.toString()
                            }
                          />
                        </>
                      )}
                    />
                  </Box>
                </Grid>
                {(renewal || isViewOnly || isFromVendormaster) && (
                  <Grid
                    item
                    xs={textFieldWidth.xs}
                    sm={textFieldWidth.sm}
                    md={textFieldWidth.md}
                    lg={textFieldWidth.lg}
                    xl={textFieldWidth.xl}
                  >
                    <Controller
                      name="Vendor_Name"
                      control={control}
                      defaultValue={""}
                      rules={{ required: "Third Party is Required" }}
                      render={({ field }) => (
                        <CustomTextField
                          label={"Third Party"}
                          name={"Third Party"}
                          value={field.value}
                          readOnly={true}
                          required={true}
                          onChange={field.onChange}
                          error={Boolean(errorrRolesandResponsibility.Vendor_Name)}
                          helperText={
                            errorrRolesandResponsibility.Vendor_Name &&
                            errorrRolesandResponsibility.Vendor_Name.message?.toString()
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  {/* <Controller
                    name="Workflow_Id"
                    control={control}
                    defaultValue={""}
                    rules={{ required: "WorkFlow is Required" }}
                    render={({ field }) => (
                      <CustomSelect
                        name={"Service Group"}
                        valueKey={"Id"}
                        optionLabelKey="WorkflowName"
                        options={workFlowList}
                        readOnly={!renewal}
                        required={true}
                        label={"WorkFlow"}
                        value={field.value}
                        onChange={(e) => SetSelected(e)}
                        error={Boolean(errorrRolesandResponsibility.Workflow_Id || invalidWorkflowWarning)}
                        helperText={
                          (errorrRolesandResponsibility.Workflow_Id &&
                            errorrRolesandResponsibility.Workflow_Id.message?.toString()) ||
                          invalidWorkflowWarning
                        }
                      />
                    )}
                  /> */}
                  {!renewal ? (
                    <Controller
                      name="Workflow_Id"
                      control={control}
                      defaultValue={""}
                      rules={{ required: "WorkFlow is Required" }}
                      render={({ field }) => {
                        const selectedWorkflow = workFlowList.find((workflow) => workflow.Id === field.value) || "";
                        return (
                          <CustomTextField
                            label={"WorkFlow"}
                            name={"Workflow_Id"}
                            value={selectedWorkflow ? selectedWorkflow.WorkflowName : ""}
                            readOnly={!renewal || view}
                            required={true}
                            onChange={(e) => {
                              const selected = workFlowList.find(
                                (workflow) => workflow.WorkflowName === e.target.value
                              );
                              field.onChange(selected ? selected.Id : "");
                            }}
                            error={Boolean(errorrRolesandResponsibility.Workflow_Id || invalidWorkflowWarning)}
                            helperText={
                              (errorrRolesandResponsibility.Workflow_Id &&
                                errorrRolesandResponsibility.Workflow_Id.message?.toString()) ||
                              invalidWorkflowWarning
                            }
                          />
                        );
                      }}
                    />
                  ) : (
                    <Controller
                      name="Workflow_Id"
                      control={control}
                      defaultValue={""}
                      rules={{ required: "WorkFlow is Required" }}
                      render={({ field }) => (
                        <CustomSelect
                          name={"Service Group"}
                          valueKey={"Id"}
                          optionLabelKey="WorkflowName"
                          options={workFlowList}
                          readOnly={!renewal || view}
                          required={true}
                          label={"WorkFlow"}
                          value={field.value}
                          onChange={(e) => SetSelected(e)}
                          error={Boolean(errorrRolesandResponsibility.Workflow_Id || invalidWorkflowWarning)}
                          helperText={
                            (errorrRolesandResponsibility.Workflow_Id &&
                              errorrRolesandResponsibility.Workflow_Id.message?.toString()) ||
                            invalidWorkflowWarning
                          }
                        />
                      )}
                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Start_Date"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "WorkFlow Start Date is Required" }}
                    render={({ field }) => (
                      <CustomDatePicker
                        // minDate={dayjs()}
                        // maxDate={dayjs().add(100, "year").toDate()}
                        // label={"WorkFlow Start Date"}
                        // value={field.value ? dayjs(field.value) : null}
                        // required={true}
                        // onChange={field.onChange}
                        // error={Boolean(errorrRolesandResponsibility.Start_Date)}
                        // helperText={
                        //   errorrRolesandResponsibility.Start_Date &&
                        //   errorrRolesandResponsibility.Start_Date.message?.toString()
                        // }
                        minDate={dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"WorkFlow Start Date"}
                        value={field.value}
                        readOnly={view}
                        required={true}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Start_Date)}
                        helperText={
                          errorrRolesandResponsibility.Start_Date &&
                          errorrRolesandResponsibility.Start_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name={`Tat`}
                    control={control}
                    defaultValue={0}
                    rules={{
                      required: "Estimated Time is Required",
                      validate: (data: number | undefined) => {
                        if (data === undefined || isNaN(data)) {
                          return "Estimated Time is Required";
                        }
                        const number = parseInt(data.toString(), 10);
                        let valuesFrom = 1;
                        let valuesTo = 60;
                        const numberFrom = parseInt(valuesFrom?.toString() ?? "", 10);
                        const numberTo = parseInt(valuesTo?.toString() ?? "", 10);

                        // // Check if valuesFrom and valuesTo are valid numbers
                        // if (isNaN(numberFrom) || isNaN(numberTo)) {
                        //   return "Please enter valid numbers for the scoring range.";
                        // }

                        // // Check if it's a valid number and falls within the specified range
                        // if (isNaN(number) || number < numberFrom || number > numberTo) {
                        //   return `Please enter a number between ${numberFrom} and ${numberTo}`;
                        // }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <CustomNumberField
                        name={"Tat"}
                        required={true}
                        readOnly={true}
                        label={"Estimated Time (In Days)"}
                        value={field.value}
                        onChange={field.onChange}
                        error={Boolean(errorrRolesandResponsibility.Tat)}
                        helperText={
                          errorrRolesandResponsibility.Tat && errorrRolesandResponsibility.Tat.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={textFieldWidth.xs}
                  sm={textFieldWidth.sm}
                  md={textFieldWidth.md}
                  lg={textFieldWidth.lg}
                  xl={textFieldWidth.xl}
                >
                  <Controller
                    name="Target_Date"
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: "WorkFlow End Date is Required",
                      // validate: (value) => {
                      //   if (startDate && value) {
                      //     return (
                      //       dayjs(value).isAfter(dayjs(startDate)) ||
                      //       "Workflow End Date must be greater than Workflow Start Date"
                      //     );
                      //   }
                      //   return true;
                      // },
                    }}
                    render={({ field }) => (
                      <CustomDatePicker
                        // minDate={dayjs()}
                        //maxDate={dayjs().add(100, "year").toDate()}
                        minDate={startDate ? dayjs(startDate).toDate() : dayjs()}
                        maxDate={dayjs().add(100, "year").toDate()}
                        label={"WorkFlow End Date"}
                        value={field.value}
                        required={true}
                        readOnly={true}
                        onChange={(e) => {
                          field.onChange(e);
                          // setValue("Tat", "");
                          // trigger("Tat");
                        }}
                        error={Boolean(errorrRolesandResponsibility.Target_Date)}
                        helperText={
                          errorrRolesandResponsibility.Target_Date &&
                          errorrRolesandResponsibility.Target_Date.message?.toString()
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                border: "1px solid #F7F7F7",
                padding: 2,
              }}
            >
              <Box></Box>
              {/* -------------------- tab1 ---------------- */}
              <ProcessDetailContext.Provider value={{ workflowProcess, validationErrors, handleInputChange }}>
                <ProcessDetails view={view} />
              </ProcessDetailContext.Provider>
            </Box>
          </Box>
          {!isViewOnly && (
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "right",
                mt: 2,
              }}
            >
              {!view ? (
                !renewal ? (
                  <>
                    <CustomButton
                      type="reset"
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        // if (onClose) {
                        //   onClose();
                        // }
                        setIsCancel(true);
                      }}
                    />

                    {/* <CustomButton type="reset" name={"Save as Draft"} onClick={() => handleSubmitDraft()} /> */}
                    {false && (
                      <CustomButton
                        type="reset"
                        variant="contained"
                        name={"Save as Draft"}
                        disabled={!isDirty}
                        onClick={() => {
                          console.log(getValues());
                        }}
                      />
                    )}
                    <CustomButton
                      type="submit"
                      name={"Submit"}
                      disabled={!isDirty && !ischangeForm}
                      onClick={() => handleSubmitBoth()}
                    />
                  </>
                ) : (
                  <>
                    <CustomButton
                      variant="outlined"
                      name={"Cancel"}
                      onClick={() => {
                        // if (onClose) {
                        //   onClose();
                        // }
                        setIsCancel(true);
                      }}
                    />
                    <CustomButton
                      type="submit"
                      name={"Submit"}
                      disabled={!isDirty && !ischangeForm}
                      onClick={() => handleSubmitBoth()}
                    />
                  </>
                )
              ) : (
                <CustomButton
                  variant="outlined"
                  name={"Cancel"}
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                  }}
                />
              )}
            </Box>
          )}
        </Stack>
      </Box>

      <ModalPopUp
        onDeleteMethod={() => onClose && onClose()}
        isDelete={true}
        onHide={() => setIsCancel(false)}
        show={IsCancel}
        header={"Confirm"}
        text={`Are you sure to cancel`}
        ActionName={"Yes"}
      />
    </>
  );
};
