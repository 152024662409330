import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "./config/axiosConfig";
import { IAddDepartmentResponse, IDepartmentResponse, IVHColumnRes, Idepartment } from "../../models/department.type";
import { DEPARTMENT_URL } from "./endpoints/department";
import { SAVEASDRAFT_URL } from "./endpoints/saveAsDraft";

const getAllSaveAsDraftList = (reqparams: any): Promise<IDepartmentResponse> =>
  axiosInstance
    .get(SAVEASDRAFT_URL.getAllDraft, {
      params: reqparams,
    })
    .then((response: AxiosResponse<IDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });
const getSaveAsDraftList = (reqparams: any): Promise<IDepartmentResponse> =>
  axiosInstance
    .get(SAVEASDRAFT_URL.getDraft, {
      params: reqparams,
    })
    .then((response: AxiosResponse<IDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const updateSaveAsDraft = (requestBody: any): Promise<IAddDepartmentResponse> =>
  axiosInstance
    .post(SAVEASDRAFT_URL.updateDraft, requestBody)
    .then((response: AxiosResponse<IAddDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const addSaveAsDraft = (requestBody: any): Promise<IAddDepartmentResponse> =>
  axiosInstance
    .post(SAVEASDRAFT_URL.addDraft, requestBody)
    .then((response: AxiosResponse<IAddDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const deleteSaveAsDraft = (requestBody: any): Promise<IAddDepartmentResponse> =>
  axiosInstance
    .post(SAVEASDRAFT_URL.deleteDraft, requestBody)
    .then((response: AxiosResponse<IAddDepartmentResponse>) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error("Error getting Data" + response);
    })
    .catch((error: AxiosError | string) => {
      throw error;
    });

const saveAsDraftService = {
  getAllSaveAsDraftList,
  getSaveAsDraftList,
  updateSaveAsDraft,
  addSaveAsDraft,
  deleteSaveAsDraft,
};

export default saveAsDraftService;
