import { Box, Button, Divider, Grid, ListItemButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelect from "../../../common/dynamicInputs/CustomSelect";
import CustomAutoComplete from "../../../common/dynamicInputs/CustomAutoComplete";
import CustomButton from "../../../common/dynamicButton/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatchThunk } from "../../../store/store";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import CustomCheckBox from "../../../common/dynamicInputs/CustomCheckBox";
import { setTabs } from "../../../store/slices/tabsData";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { LovConfig, serviceCriticality } from "../../../config/config";
import CustomMultilineTextField from "../../../common/dynamicInputs/CustomMultilineTextField";
import CustomTextField from "../../../common/dynamicInputs/CustomTextField";
import SearchServices from "../../contractManagement/SearchService/SearchServices";
import { getAQMMappingByVendorID } from "../../../store/slices/assesmentQuestionnaireMapping";

const serviceTypeAllOption = { LovId: "All", Options: "All" };
const serviceOwnerAllOption = { ServiceOwnerName: "All", ServiceOwnerId: "All" };



export const ServiceCatalogMapping: React.FC<any> = ({ view, edit, isEditData, isWorkFlow, isAqmResponse }) => {
  const dispatch = useAppDispatchThunk();

  const [serviceType, setServiceType] = useState<any[]>([]);
  const [serviceOwner, setServiceOwner] = useState<any[]>([]);

  const [selectedServiceType, setSelectedServiceType] = useState<any>([]);
  const [selectedServiceOwner, setSelectedServiceOwner] = useState<any>();

  const [unmappedService, setUnmappedService] = React.useState<any[]>([]);
  const [checked, setChecked] = React.useState<any[]>([]);
  const [AssesmentType, SetAssesmentType] = React.useState<any[]>([]);

  const [searchedServiceName, setSearchedServiceName] = React.useState<any>();
  const [criticality, setCriticality] = React.useState<string | null>(null);
  const [showSingleVendorWarning, setShowSingleVendorWarning] = React.useState("");
  const [ShowUnSelectServiceWarning, setShowUnSelectServiceWarning] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

  const { lovTable } = useSelector((state: RootState) => state.lovMasterData);
  const { ActiveServiceCatlogdata } = useSelector((state: RootState) => state.serviceCatalog);
  const { dataTable } = useSelector((state: RootState) => state.departmentData);
  const { AQMMappingByVendordata } = useSelector((state: RootState) => state.aQMMappingData);

  console.log(ActiveServiceCatlogdata, "ActiveServiceCatlogdata");
  console.log(AQMMappingByVendordata, "AQMMappingByVendordata");
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const { replace } = useFieldArray({
    control,
    name: "serviceMapping",
    rules: { required: "Atleast one Service Mapping is required" },
  });

  const serviceMappingValues: any[] = getValues("serviceMapping");

  const existingserviceCatalogIds = AQMMappingByVendordata?.map((item: any) => item?.ServiceName != null ? (JSON.parse(item?.ServiceName)) : []);

  const mergedUniqueIds = Array.from(new Set(existingserviceCatalogIds?.flat()));

  useEffect(() => {
    const materialService = serviceMappingValues.find((item) => item.Summary === serviceCriticality.material);
    if (materialService) setCriticality(serviceCriticality.material);
    else {
      const criticalService = serviceMappingValues.find((item) => item.Summary === serviceCriticality.critical);
      if (criticalService) setCriticality(serviceCriticality.critical);
      else setCriticality(null);
    }
    const serviceCatalogIds = serviceMappingValues.map((item: any) => item.ServiceCatalogId);
    setUnmappedService(serviceCatalogIds);
    // setUnmappedService(serviceMappingValues)
  }, [serviceMappingValues]);

  useEffect(() => {
    const result = lovTable.filter((item: any) => item.Name === "Service Type") || [];
    result.push(serviceTypeAllOption);
    setServiceType(result);
    setSelectedServiceType("All");
    const filteredAssessment_Type = lovTable.filter((item: any) => item.Name === LovConfig.Assessment_Type) || [];
    SetAssesmentType(filteredAssessment_Type)
  }, [lovTable]);

  useEffect(() => {
    if (selectedServiceType) {
      const result = Object.values(
        ActiveServiceCatlogdata.reduce((acc, obj: any) => {
          if (obj.ServiceType === selectedServiceType || selectedServiceType === "All") {
            return { ...acc, [obj.ServiceOwnerId]: obj };
          } else return acc;
        }, {})
      );
      if (result.length > 1) result.unshift(serviceOwnerAllOption);
      setServiceOwner(result);
      setSelectedServiceOwner("");
    }
  }, [selectedServiceType]);

  useEffect(() => {
    if (isEditData && isEditData.VendorServiceMappings) {
      const serviceCatalogIds = isEditData?.VendorServiceMappings.map((item: any) => item.ServiceCatalogId);

      // replace(isEditData?.VendorServiceMappings);
      setUnmappedService(serviceCatalogIds);
    }
  }, [isEditData]);

  const handleSingleVendorWarning = (services: any) => {
    const result = services.filter((item: any) => item?.Engage === "single" && item?.IsMapped);
    if (result.length > 0)
      setShowSingleVendorWarning(
        result.length === services.length
          ? "This service is already mapped to an existing Third Party."
          : "Some services are already mapped to an existing Third Party."
      );
    else setShowSingleVendorWarning("");
  };
  const handleToggle = (value: any) => () => {
    const currentIndex = checked.findIndex((item) => item.ServiceCatalogId === value.ServiceCatalogId);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    handleSingleVendorWarning(newChecked);
  };

  useEffect(() => {
    if (isEditData) {
      const uniqueUnmappedService = Array.from(new Set(unmappedService));
      const allIdsMatch = mergedUniqueIds?.every((id) => uniqueUnmappedService?.includes(id));
      const noExtraIds = mergedUniqueIds?.length === uniqueUnmappedService?.length;
  
      const result = allIdsMatch && noExtraIds;
      setIsReadOnly(result);
  
      console.log(mergedUniqueIds,uniqueUnmappedService,allIdsMatch,noExtraIds, result, "Are mergedUniqueIds and unmappedService the same?");
    } else {
      setIsReadOnly(false);

    }
  }, [isEditData, mergedUniqueIds, unmappedService, isReadOnly]);


  const updateServices = (updatedservices: any[]) => {
    if (isEditData) {

      const missingIds = mergedUniqueIds?.filter((id: any) => !updatedservices?.includes(id));

      console.log(existingserviceCatalogIds, mergedUniqueIds, missingIds, "existingserviceCatalogIds");

      if (missingIds.length > 0) {
        console.error(`Error: The following services are missing: ${missingIds.join(', ')}`);
        setShowUnSelectServiceWarning(true);
        return;
      } else {
        setUnmappedService(updatedservices)
        // replace(updatedservices);
        let FilterSelectedServiceData = ActiveServiceCatlogdata.filter((item) =>
          updatedservices.includes(item.ServiceCatalogId)
        );
        handleSingleVendorWarning(FilterSelectedServiceData);
        replace(FilterSelectedServiceData);
        setShowUnSelectServiceWarning(false);

        console.log(updatedservices, FilterSelectedServiceData, "FilterSelectedServiceData");
      }

    } else {

      setUnmappedService(updatedservices)
      // replace(updatedservices);
      let FilterSelectedServiceData = ActiveServiceCatlogdata.filter((item) =>
        updatedservices.includes(item.ServiceCatalogId)
      );
      handleSingleVendorWarning(FilterSelectedServiceData);
      replace(FilterSelectedServiceData);
      setShowUnSelectServiceWarning(false);

      console.log(updatedservices, FilterSelectedServiceData, "FilterSelectedServiceData");
    }

  }


  console.log(unmappedService, serviceMappingValues, "maped");


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

          <Controller
            name="Scope_Of_Service"
            control={control}
            defaultValue={''}
            rules={{ required: "Scope of Service is required" }}
            render={({ field }) => (
              <CustomMultilineTextField
                label={"Scope Of Service"}
                value={field.value}
                onChange={field.onChange}
                required
                readOnly={view}
                error={Boolean(errors.Scope_Of_Service)}
                helperText={errors?.Scope_Of_Service?.message?.toString()}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Controller
            name="Exclusion"
            control={control}
            defaultValue={""}
            rules={{
              validate: {
                noLeadingSpaces: (value: string | undefined) => {
                  if (!value) return;
                  return !/^\s/.test(value) || "Leading spaces are not allowed";
                },
                maxLength: (value) => {
                  if (!value) return;
                  return value.length <= 100 || "Maximum 100 characters allowed";
                }
              }
            }}
            render={({ field }) => (
              <CustomTextField
                name={"Exclusion"}
                readOnly={view}
                label={"Exclusion"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errors.Exclusion)}
                helperText={errors.Exclusion?.message?.toString()}
              />
            )}
          />
        </Grid>
      </Grid>
      <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SearchServices updateSelectedServices={updateServices} selectedServices={unmappedService} view={view} />
        </Grid>
      </Grid>

      {serviceMappingValues.length === 0 && errors.serviceMapping?.root?.message && (
        <Typography variant="subtitle1" sx={{ color: "#D70000", textAlign: "left", fontSize: "15px" }}>
          {`Error : ${errors.serviceMapping?.root?.message as any}`}
        </Typography>
      )}
      <Typography variant="subtitle1" sx={{ color: "#D70000", textAlign: "left", fontSize: "15px" }}>
        Notes :
        <ul style={{ listStyleType: "disc" }}>
          <li>Services marked with a star (*) indicate single - Third Party.</li>
          {criticality && (
            <li>{`The Third Party is mapped to one or more ${criticality} Services, hence you are advised to take advanced Third Party assessment.`}</li>
          )}
          {showSingleVendorWarning && <li>{showSingleVendorWarning}</li>}
          {ShowUnSelectServiceWarning &&
            <li>The service you are attempting to remove is currently within its assessment validity period and therefore cannot be removed at this moment.</li>}
        </ul>
      </Typography>




      <Divider sx={{ border: '1px solid', color: "#e0e0e3" }} />
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="Service_Owner"
            control={control}
            defaultValue={''}
            rules={{ required: "Vendor Onboarding Owner is required" }}
            render={({ field }) => (
              <CustomSelect
                name="Service_Owner"
                valueKey="DepartmentId"
                optionLabelKey="DepartmentName"
                options={dataTable}
                readOnly={view}
                required={true}
                label={"Third Party Onboarding Owner"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errors.Service_Owner)}
                helperText={errors.Service_Owner?.message?.toString()}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <Controller
            name="Assessment_Type"
            control={control}
            defaultValue={""}
            rules={{ required: "Assessment Type is required" }}
            render={({ field }) => (
              <CustomSelect
                // addField={true}
                // onAddClick={() => {
                //   onAddType("Add Assessment Type", "Assessment_Type", "Assessment Type");
                // }}
                name={"Assessment_Type"}
                valueKey={"LovId"}
                optionLabelKey="Options"
                options={AssesmentType}
                readOnly={view}
                required={true}
                label={"Assessment Type"}
                value={field.value}
                onChange={field.onChange}
                error={Boolean(errors.Assessment_Type)}
                helperText={
                  errors.Assessment_Type && errors.Assessment_Type.message?.toString()
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          {!isWorkFlow && !isAqmResponse && <Box sx={{ pt: 1 }}>
            <Controller
              name="isTriggerWorkflow"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <CustomCheckBox
                  label="Initiate Workflow"
                  isStyle={true}
                  helperText={isReadOnly ? "No New service has been added, Hence cannot trigger the workflow" : ""}
                  readOnly={view || isReadOnly}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>}

        </Grid>
      </Grid>


    </>
  );
};
